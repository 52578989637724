import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import {
  Chip,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Avatar from '@mui/material/Avatar'

import colors from '../../../../constants/colors'
import { getInitials } from '../../../../helpers/UtilityHelper'

const styles = makeStyles((theme) => ({
  chipComponent: {
    backgroundColor: colors.lightestGrey,
  },
  dataItem: {
    minWidth: '120px',
    margin: useTheme().spacing(1),
  },
  expansionPanel: {
    paddingTop: useTheme().spacing(1),
    paddingBottom: useTheme().spacing(1),
    marginTop: useTheme().spacing(1),
    marginBottom: useTheme().spacing(1),
  },
  title: {
    fontSize: 32,
  },
  cardContent: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  avatar: {
    backgroundColor: colors.lightYellow,
    width: '26px',
    height: '26px',
    fontSize: '14px',
    color: colors.contrastText,
    marginLeft: '4px',
  },
  label: {
    color: colors.midGrey,
    fontSize: 11,
  },
}))

const PublisherDetailCard = ({ classes = {}, publisher = {} }) => {
  const { file_name = '', published_at = Number(0), user_id = '' } = publisher
  return (
    <Accordion
      defaultExpanded={true}
      className={classes.expansionPanel}
      elevation={1}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <div className={classes.title}>Publisher Details</div>
      </AccordionSummary>
      <AccordionDetails>
        <div className={classes.cardContent}>
          <div className={classes.dataItem}>
            <Typography className={classes.label}>File Name</Typography>
            <Typography>{file_name}</Typography>
          </div>

          {published_at && (
            <div className={classes.dataItem}>
              <Typography className={classes.label}>Upload Date</Typography>
              <Typography>
                {moment(published_at).format('MMM D, YYYY h:mm:ss a')}
              </Typography>
            </div>
          )}
          {user_id && (
            <div className={classes.dataItem}>
              <Typography className={classes.label}>Uploaded By</Typography>
              <Chip
                avatar={
                  <Avatar aria-label="Profile" className={classes.avatar}>
                    {getInitials(user_id)}
                  </Avatar>
                }
                label={user_id}
                className={classes.chipComponent}
              />
            </div>
          )}
        </div>
      </AccordionDetails>
    </Accordion>
  )
}

PublisherDetailCard.propTypes = {
  classes: PropTypes.object,
  publisher: PropTypes.object,
}

const MyComponent = (props) => {
  const classes = styles()
  return <PublisherDetailCard {...props} classes={classes} />
}

export default MyComponent
