import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { isEmpty } from 'lodash'
import { withStyles } from '@mui/styles'
import { Avatar, ClickAwayListener } from '@mui/material'
import { Edit, Delete } from '@mui/icons-material'

import MentionBox from './MentionBox'
import { praxis } from '../../config/themeConfig'
import { formatAnnotationDate } from '../../helpers/dateHelper'
import { parseNameString } from '../../helpers/annotationHelper'
import {
  updateComment,
  deleteComment,
  createNewComment,
} from '../../store/annotation/actions'
import {
  selectIsActiveReview,
  selectIsCurrentTaskRole,
} from '../../store/annotation/selectors'
import { TaskRoles } from '../../constants/annotation'

const styles = {
  root: {
    marginLeft: '35px',
    marginTop: '5px',
  },
  avatar: {
    backgroundColor: praxis.themePrimary,
    height: '30px',
    width: '30px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  name: {
    marginLeft: '10px',
    fontSize: '12px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  initials: {
    fontSize: '12px',
  },
  charCounter: {
    margin: '5px',
    textAlign: 'end',
    fontSize: '10px',
  },
  button: {
    fontSize: '15px',
    margin: '4px 16px 0 9px',
    color: praxis.lightGrey,
    cursor: 'pointer',
    '&:hover': {
      color: praxis.lighterBlue,
    },
  },
  emptySpace: {
    fontSize: '15px',
    margin: '4px 16px 0 9px',
  },
  buttonDelete: {
    fontSize: '15px',
    marginLeft: '4px',
    color: praxis.lightGrey,
    cursor: 'pointer',
    '&:hover': {
      color: praxis.lighterBlue,
    },
  },
  buttonDeleteConf: {
    fontSize: '15px',
    marginLeft: '4px',
    color: praxis.bostonRed,
    cursor: 'pointer',
  },
  actionButtons: {
    display: 'flex',
    flexDirection: 'column',
    width: '30px',
  },
  fadedGrey: {
    color: '#888888',
  },
}

export const ReplyBox = ({
  reply = {},
  classes = {},
  isSecondary = false,
  deleteComment = () => {},
  auth = {},
  isProjectAdmin = false,
  isActiveReview = false,
  isEditing = false,
  replyTextField = '',
  editTextField = () => {},
  handleChangeEditingReply = () => {},
}) => {
  const { lanId = '' } = auth
  const {
    updated_date,
    created_by_name = '.',
    created_by = '',
    comment_text = '',
    comment_id = '',
  } = reply
  const isSameUser = lanId === created_by
  const {
    firstName = '',
    lastName = '',
    initials = '',
  } = parseNameString(created_by_name)
  const formattedDate = formatAnnotationDate(updated_date)

  const [isConfDelete, setIsConfDelete] = useState(false)
  return (
    <div className={classes.root}>
      {!isEmpty(reply) && (
        <div className={classes.header}>
          <Avatar aria-label="Avatar Image" className={classes.avatar}>
            <span className={classes.initials}>{initials}</span>
          </Avatar>
          <div className={classes.name}>
            <div style={{ display: 'inline-flex' }}>
              <div>
                {firstName} {lastName}
              </div>
              {isSameUser && (isActiveReview || isProjectAdmin) && (
                <ClickAwayListener onClickAway={() => setIsConfDelete(false)}>
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={
                      isConfDelete
                        ? () =>
                            deleteComment({
                              commentId: comment_id,
                              isSecondary,
                            })
                        : () => {
                            setIsConfDelete(true)
                            setTimeout(() => {
                              setIsConfDelete(false)
                            }, 6000)
                          }
                    }
                  >
                    <Delete
                      className={
                        isConfDelete
                          ? classes.buttonDeleteConf
                          : classes.buttonDelete
                      }
                    />
                    {isConfDelete && (
                      <span
                        style={{
                          color: '#CC0000',
                          fontSize: '10px',
                          float: 'right',
                          marginTop: '3px',
                        }}
                      >
                        DELETE ?
                      </span>
                    )}
                  </div>
                </ClickAwayListener>
              )}
            </div>
            <span className={classes.fadedGrey}>{formattedDate}</span>
          </div>
        </div>
      )}
      {!isEditing ? (
        <div style={{ display: 'inline-flex', marginBottom: '6px' }}>
          {isSameUser && (isActiveReview || isProjectAdmin) ? (
            <Edit
              className={classes.button}
              onClick={() =>
                isEditing
                  ? handleChangeEditingReply()
                  : handleChangeEditingReply(reply)
              }
            />
          ) : (
            <div className={classes.emptySpace}></div>
          )}
          <MentionBox
            disabled
            text={comment_text}
            onTextChanged={() => {}}
            styles={{ maxWidth: '140px', marginTop: '4px' }}
          />
        </div>
      ) : (
        <React.Fragment>
          <MentionBox
            text={replyTextField}
            placeholder="Reply Here..."
            onTextChanged={editTextField}
          />
          <div className={classes.charCounter}>
            Chars: {replyTextField.length}/999
          </div>
        </React.Fragment>
      )}
    </div>
  )
}

ReplyBox.propTypes = {
  parentCommentId: PropTypes.string,
  reply: PropTypes.object,
  classes: PropTypes.object,
  isSecondary: PropTypes.bool,
  closeNewReply: PropTypes.func,
  deleteComment: PropTypes.func,
  updateComment: PropTypes.func,
  createNewComment: PropTypes.func,
  isProjectAdmin: PropTypes.bool,
  isActiveReview: PropTypes.bool,
  isEditing: PropTypes.bool,
  replyTextField: PropTypes.string,
  editTextField: PropTypes.func,
  handleChangeEditingReply: PropTypes.func,
  auth: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
}

const mapStateToProps = (state) => {
  const { auth = {} } = state
  const isActiveReview = selectIsActiveReview()(state)
  const isProjectAdmin = selectIsCurrentTaskRole(TaskRoles.admin)(state)
  return {
    auth,
    isActiveReview,
    isProjectAdmin,
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createNewComment,
      updateComment,
      deleteComment,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ReplyBox))
