import { PROJECT_API_CALL_FAIL, GET_PROJECT_TYPES } from './actionType'

export const projectTypeState = {
  projectTypes: [],
  projectTypeCount: 0,
}

export default function projectsReducer(state = projectTypeState, action = {}) {
  switch (action.type) {
    case PROJECT_API_CALL_FAIL:
      return {
        ...state,
        apiError: action.error,
      }
    case GET_PROJECT_TYPES: {
      const { data: projectTypes = [] } = action
      return {
        projectTypes: projectTypes,
        projectTypeCount: projectTypes.length,
      }
    }
    default:
      return state
  }
}
