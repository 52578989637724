import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import {
  FormControl,
  Input,
  InputLabel,
  Chip,
  InputAdornment,
} from '@mui/material'

import CollapsibleCard from '../../components/CustomCards/CollapsibleCard'
import CustomAvatar from '../../components/Custom/CustomAvatar'
import { convertUTCtoLocal, formatCSTDate } from '../../helpers/dateHelper'
import {
  METADATA_CARD_SHOW_BUTTON_TEXT,
  METADATA_CARD_HIDE_BUTTON_TEXT,
} from '../../constants/metadata'
import {
  getFromBrowserStorage,
  separateFilenameExtension,
} from '../../helpers/UtilityHelper'

export class AssetDetailsCard extends React.Component {
  constructor(props) {
    super(props)
    const { assetDetails = {}, metadataParentTimestamp = 0 } = props
    this.state = {
      ...assetDetails,
      metadataParentTimestamp,
      unsavedChanges: false,
      disableSave: false,
    }
  }

  componentDidUpdate(prevProps) {
    const { assetDetails = {}, metadataParentTimestamp = 0 } = this.props

    if (prevProps.metadataParentTimestamp !== metadataParentTimestamp) {
      this.setState({
        ...assetDetails,
        metadataParentTimestamp,
        unsavedChanges: false,
        disableSave: false,
      })
    }
  }

  fileRenameHandler = (extension) => (event) => {
    const fileName = event.target.value || ''
    const disableSave = !fileName
    const fullFilename = `${fileName}${extension}`
    this.setState(
      {
        fileName: fullFilename,
        unsavedChanges: true,
        disableSave: disableSave,
      },
      this.updateStateCB
    )
    this.props.isMetadataFormEdited(true)
  }

  discardChanges = () => {
    // discard the changes
    const { assetDetails = {} } = this.props
    this.setState({
      ...assetDetails,
      unsavedChanges: false,
      disableSave: false,
    })
  }

  updateStateCB = () => {
    const { updateParentState = () => {}, isMetadataFormEdited = () => {} } =
      this.props
    const {
      fileName,
      fileSize,
      pixelDimensions,
      resolution,
      uploadDate,
      uploadedBy,
      creator,
      revisionNumber,
      unsavedChanges,
    } = this.state
    const updatedAssetDetails = {
      assetDetails: {
        fileName,
        fileSize,
        pixelDimensions,
        resolution,
        uploadDate,
        uploadedBy,
        creator,
        revisionNumber,
      },
    }
    // Update parent state
    updateParentState(updatedAssetDetails)
    isMetadataFormEdited(unsavedChanges)
  }

  saveChanges = () => {
    // construct the needed data and trigger the parent's save method
    const {
      fileName,
      fileSize,
      pixelDimensions,
      resolution,
      uploadDate,
      uploadedBy,
      creator,
      revisionNumber,
    } = this.state
    const { saveMetadata = () => {} } = this.props
    const updatedAssetDetails = {
      assetDetails: {
        fileName,
        fileSize,
        pixelDimensions,
        resolution,
        uploadDate,
        uploadedBy,
        creator,
        revisionNumber,
      },
    }
    saveMetadata(updatedAssetDetails)
  }

  render() {
    const {
      classes = {},
      getStorageIdentifier = () => {},
      collapseCallback = () => {},
      editPermissions = {},
    } = this.props
    const assetDetailsTitle = 'Asset Details'
    const assetDetailsIdentifier = getStorageIdentifier(assetDetailsTitle)
    const assetDetailsCollapse =
      getFromBrowserStorage(assetDetailsIdentifier) === 'true'
    const {
      fileName: fullFilename = '',
      uploadedBy = '',
      creator = '',
      revisionNumber = '',
      fileSize = '',
      pixelDimensions = '',
      resolution = '',
      uploadDate = '',
      create_date = '',
      person_shown = [],
      disableSave,
    } = this.state
    const uploadDateFormatted = convertUTCtoLocal(uploadDate, 'lll') || ''
    const originalCreationDateFormatted = create_date
      ? convertUTCtoLocal(create_date, 'll')
      : ''
    const { canRename } = editPermissions
    const { fileName = '', fileExt = '' } =
      separateFilenameExtension(fullFilename)
    const fileSizeMb = fileSize ? `${fileSize} MB` : ''
    return (
      <CollapsibleCard
        cardTitle={assetDetailsTitle}
        hideButtonText={METADATA_CARD_HIDE_BUTTON_TEXT}
        showButtonText={METADATA_CARD_SHOW_BUTTON_TEXT}
        defaultCollapse={assetDetailsCollapse}
        collapseCallback={collapseCallback}
        isActionMode={false}
        disablePrimaryButton={disableSave}
        primaryButtonText="SAVE CHANGES"
        secondaryButtonText="CANCEL CHANGES"
        primaryButtonHandler={this.saveChanges}
        secondaryButtonHandler={this.discardChanges}
      >
        <div className={classes.metadataCardContent}>
          {canRename && (
            <FormControl
              variant="standard"
              className={cx(classes.formControl, classes.fullWidth)}
            >
              <InputLabel htmlFor="adFileName" shrink>
                File Name
              </InputLabel>
              <Input
                id="adFileName"
                onChange={this.fileRenameHandler(fileExt)}
                value={fileName}
                fullWidth
                multiline
                endAdornment={
                  <InputAdornment position="end">{fileExt}</InputAdornment>
                }
                inputProps={{ 'data-cy': 'fileName' }}
              />
            </FormControl>
          )}
          {!canRename && (
            <FormControl
              variant="standard"
              className={cx(classes.formControl, classes.fullWidth)}
              disabled
            >
              <InputLabel htmlFor="adFileName">File Name</InputLabel>
              <Input id="adFileName" value={fullFilename} fullWidth multiline />
            </FormControl>
          )}
          {uploadDateFormatted && (
            <FormControl variant="standard" className={classes.formControl}>
              <InputLabel htmlFor="adUploadDate">Upload Date</InputLabel>
              <Input
                id="adUploadDate"
                value={uploadDateFormatted}
                disableUnderline
              />
            </FormControl>
          )}
          {uploadedBy && (
            <FormControl variant="standard" className={classes.formControl}>
              <InputLabel htmlFor="adUploadedBy" shrink>
                Uploaded By
              </InputLabel>
              <div className={classes.formStaticWrap}>
                <Chip
                  avatar={
                    <CustomAvatar userDetails={{ displayName: uploadedBy }} />
                  }
                  id="adUploadedBy"
                  label={uploadedBy}
                  className={classes.chipComponent}
                />
              </div>
            </FormControl>
          )}
          {revisionNumber && (
            <FormControl variant="standard" className={classes.formControl}>
              <InputLabel htmlFor="adRevisionNumber">
                Revision Number
              </InputLabel>
              <Input
                id="adRevisionNumber"
                value={revisionNumber}
                disableUnderline
              />
            </FormControl>
          )}
          {fileSizeMb && (
            <FormControl variant="standard" className={classes.formControl}>
              <InputLabel htmlFor="adFileSize">File Size</InputLabel>
              <Input id="adFileSize" value={fileSizeMb} disableUnderline />
            </FormControl>
          )}
          {pixelDimensions && (
            <FormControl variant="standard" className={classes.formControl}>
              <InputLabel htmlFor="adPixelDimensions">
                Pixel Dimensions
              </InputLabel>
              <Input
                id="adPixelDimensions"
                value={pixelDimensions}
                disableUnderline
              />
            </FormControl>
          )}
          {resolution && (
            <FormControl variant="standard" className={classes.formControl}>
              <InputLabel htmlFor="adResolution">Resolution</InputLabel>
              <Input id="adResolution" value={resolution} disableUnderline />
            </FormControl>
          )}
          <FormControl variant="standard" className={classes.formControl}>
            <InputLabel htmlFor="adCreator" shrink>
              Creator
            </InputLabel>
            <div className={classes.formStaticWrap}>
              <Chip
                avatar={
                  <CustomAvatar
                    userDetails={{ displayName: creator || 'Not Provided' }}
                  />
                }
                id="adCreator"
                label={creator || 'Not Provided'}
                className={classes.chipComponent}
              />
            </div>
          </FormControl>
          <FormControl variant="standard" className={classes.formControl}>
            <InputLabel htmlFor="ogCreation">Original Creation Date</InputLabel>
            <Input
              id="ogCreation"
              value={originalCreationDateFormatted || 'Not Provided'}
              disableUnderline
            />
          </FormControl>
          <FormControl variant="standard" className={classes.formControl}>
            <InputLabel htmlFor="modelAgency">Model Name/Agency</InputLabel>
            <Input
              id="modelAgency"
              value={
                (person_shown?.length && person_shown.join(', ')) ||
                'Not Provided'
              }
              disableUnderline
            />
          </FormControl>
        </div>
      </CollapsibleCard>
    )
  }
}

AssetDetailsCard.propTypes = {
  classes: PropTypes.object,
  assetDetails: PropTypes.object,
  editPermissions: PropTypes.object,
  metadataParentTimestamp: PropTypes.number,
  collapseCallback: PropTypes.func,
  getStorageIdentifier: PropTypes.func,
  saveMetadata: PropTypes.func,
  isMetadataFormEdited: PropTypes.func,
  saveAlertActiontype: PropTypes.bool,
  setSaveAlertAction: PropTypes.func,
  updateParentState: PropTypes.func,
}
