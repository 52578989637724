import axios from 'axios'
import { isEmpty } from 'lodash'
import {
  GET_TASK_ASSETS,
  GET_TASK_USERS,
  GET_ASSET_COMMENTS,
  GET_SECONDARY_ASSET_COMMENTS,
  CREATE_COMMENT,
  UPDATE_COMMENT,
  DELETE_COMMENT,
  STORE_CURRENT_MARKUP,
  CLEAR_CURRENT_MARKUP,
  SET_PRIMARY_ASSET,
  SET_SECONDARY_ASSET,
  TOGGLE_COMPARISON_VIEW,
  FETCH_ASSET_VERSIONS,
  FETCH_SECONDARY_ASSET_VERSIONS,
  SET_PRIMARY_VERSION,
  SET_SECONDARY_VERSION,
  SET_PRIMARY_PAGE_NUMBER,
  SET_SECONDARY_PAGE_NUMBER,
  SET_VIEWED_USERS,
  RESET_ASSETS,
  RESET_PRIMARY_COMMENTS,
  RESET_SECONDARY_COMMENTS,
  SET_TASK_STATUS,
  GET_ALL_COMMENT_INFO,
  SET_PRIMARY_ASSET_REVISION_HISTORY,
  SET_SECONDARY_ASSET_REVISION_HISTORY,
  FROM_COMPARISON_TO_SINGLE,
  RESOLVE_COMMENT,
} from './actionTypes'
import {
  selectCurrentMarkup,
  selectTaskName,
  selectProjectUUID,
  selectJobId,
  selectPageNumber,
  selectMasterId,
  selectRevisionNumber,
  selectTaskId,
  selectAssetVersionId,
  combineAllAssetIds,
  selectRevisionHistory,
} from './selectors'
import { getAssetVersions } from '../../helpers/annotationHelper'
import { TASK_STATUS_NEW } from '../../constants/tasks'
import apiConfig from '../../config/apiConfig'
import { cleanString } from '../../helpers/stringHelper'

const KEY = apiConfig.key
const HOST = apiConfig.assethub.host

export const getTaskAssets =
  ({ projectId = '', jobId = '' }, callBack = () => {}) =>
  (dispatch) => {
    const getAssetsURL = `${apiConfig.annotation.taskPreviewUrl}/projects/${projectId}/review_tasks/${jobId}?key=${KEY}`
    const promise = axios.get(getAssetsURL)

    dispatch({
      type: GET_TASK_ASSETS,
      payload: promise,
    }).then(async () => {
      callBack()
      return dispatch(getTaskUsers(jobId))
    })
  }

export const getAllCommentInfo = () => (dispatch, getState) => {
  const arrayOfIds = combineAllAssetIds()(getState())
  const commentsUrl = `${apiConfig.annotation.commentsUrl}/page_comments`
  const promise = axios.post(commentsUrl, arrayOfIds)

  return dispatch({
    type: GET_ALL_COMMENT_INFO,
    payload: promise,
  })
}

export const setTaskStatus =
  (taskStatus = TASK_STATUS_NEW) =>
  (dispatch, getState) => {
    const state = getState()
    const taskId = selectTaskId(state)
    const updateTaskStatusURL = `${apiConfig.assethub.serviceHostMarket}/task_status?key=${KEY}`
    const payload = {
      task_id: taskId,
      task_status_id: taskStatus,
    }
    const promise = axios.put(updateTaskStatusURL, payload)

    if (taskStatus && taskId) {
      return dispatch({
        type: SET_TASK_STATUS,
        payload: promise,
      })
    }
  }

export const getTaskUsers =
  (jobId = '') =>
  (dispatch) => {
    const getTaskUsersUrl = `${apiConfig.assethub.serviceHostMarket}/task_users/${jobId}?key=${KEY}`
    const promise = axios.get(getTaskUsersUrl)

    return dispatch({
      type: GET_TASK_USERS,
      payload: promise,
    })
  }

export const getCommentsByAssetIdAndRevision =
  (isSecondary = false, versionNumber = 0) =>
  async (dispatch, getState) => {
    const state = getState()
    const jobId = selectJobId(state)
    const pageNumber = selectPageNumber(isSecondary)(state)
    const assetMasterId = selectMasterId(isSecondary)(state)
    let revisionNumber = selectRevisionNumber(isSecondary)(state)
    const assetRevisionHistory = selectRevisionHistory(isSecondary)(state)
    if (assetRevisionHistory[assetMasterId]) {
      revisionNumber = assetRevisionHistory[assetMasterId].versionNumber
    }
    revisionNumber = versionNumber !== 0 ? versionNumber : revisionNumber
    const getCommentsURL = `${apiConfig.annotation.commentsUrl}/${jobId}/${assetMasterId}/${revisionNumber}/${pageNumber}`
    if (assetMasterId && revisionNumber && jobId) {
      const promise = axios.get(getCommentsURL)
      const type = !isSecondary
        ? GET_ASSET_COMMENTS
        : GET_SECONDARY_ASSET_COMMENTS
      await dispatch(getAllCommentInfo())
      return dispatch({
        type,
        payload: promise,
      })
    }
  }

export const createNewComment =
  ({
    comment_text = '',
    number = 0,
    isSecondary = false,
    toAddress = [],
    parentCommentId = '',
    asset_name = '',
  }) =>
  async (dispatch, getState) => {
    const state = getState()
    const { geometry } = selectCurrentMarkup(state)
    const jobId = selectJobId(state)
    const assetId = selectAssetVersionId(isSecondary)(state)
    const assetMasterId = selectMasterId(isSecondary)(state)
    const revisionNumber = selectRevisionNumber(isSecondary)(state)
    const pageNumber = selectPageNumber(isSecondary)(state)
    const newMarkupData = isEmpty(geometry)
      ? {}
      : { geometry, data: { id: number } }
    const UUID = selectProjectUUID(state)
    const taskLaunchUrl = `${HOST}/annotator/${UUID}/${jobId}/assetUniqueId/${assetMasterId}/${revisionNumber}/${pageNumber}`
    let commentType = 'INLINE'
    if (parentCommentId !== '') {
      commentType = 'REPLIED'
    } else if (isEmpty(geometry)) {
      commentType = 'GLOBAL'
    }

    const payload = {
      comment_details: {
        comment_text: cleanString(comment_text),
        asset_id: assetId,
        asset_master_id: assetMasterId,
        asset_version: revisionNumber,
        comment_type: commentType,
        geometry: JSON.stringify(newMarkupData),
        page_number: selectPageNumber(isSecondary)(state),
        parent_comment_id: parentCommentId,
      },
      to_address: toAddress,
      task_name: selectTaskName(state),
      task_launch_url: taskLaunchUrl,
      task_group_id: jobId,
      asset_name: asset_name,
    }
    const createCommentURL = apiConfig.annotation.commentsUrl
    const promise = axios.post(createCommentURL, payload)

    await dispatch({ type: CREATE_COMMENT, payload: promise })
    return await dispatch(getCommentsByAssetIdAndRevision(isSecondary))
  }

export const updateComment =
  ({
    comment_id = '',
    comment_text = '',
    geometry = '',
    isSecondary = false,
    toAddress = [],
    status = '',
    parentCommentId = '',
    asset_name = '',
  }) =>
  async (dispatch, getState) => {
    const state = getState()
    const jobId = selectJobId(state)
    const assetId = selectAssetVersionId(isSecondary)(state)
    const assetMasterId = selectMasterId(isSecondary)(state)
    const revisionNumber = selectRevisionNumber(isSecondary)(state)
    const UUID = selectProjectUUID(state)
    const taskLaunchUrl = `${HOST}/annotator/${UUID}/${jobId}`
    const payload = {
      comment_details: {
        asset_id: assetId,
        asset_master_id: assetMasterId,
        asset_version: revisionNumber,
        comment_text: cleanString(comment_text),
        geometry,
        status,
        parent_comment_id: parentCommentId,
      },
      to_address: toAddress,
      task_name: selectTaskName(state),
      task_launch_url: taskLaunchUrl,
      task_group_id: jobId,
      asset_name: asset_name,
    }
    const updateComentURL = `${apiConfig.annotation.commentsUrl}/${comment_id}`
    const promise = axios.put(updateComentURL, payload)

    await dispatch({ type: UPDATE_COMMENT, payload: promise })
    return await dispatch(getCommentsByAssetIdAndRevision(isSecondary))
  }

export const deleteComment =
  ({ commentId = '', isSecondary = false }) =>
  async (dispatch) => {
    const deleteComentURL = `${apiConfig.annotation.commentsUrl}/${commentId}`
    const promise = axios.delete(deleteComentURL)

    await dispatch({
      type: DELETE_COMMENT,
      payload: promise,
    })
    return await dispatch(getCommentsByAssetIdAndRevision(isSecondary))
  }

export const resolveComment =
  ({ commentId = '', isResolved = false, isSecondary = false, userId = '' }) =>
  async (dispatch) => {
    const resolveCommentURL = `${apiConfig.annotation.commentsUrl}/comment_status_update/${commentId}`
    const payload = {
      is_resolved: isResolved,
      resolved_by: userId,
    }
    const promise = axios.put(resolveCommentURL, payload)

    await dispatch({
      type: RESOLVE_COMMENT,
      payload: promise,
    })
    return await dispatch(getCommentsByAssetIdAndRevision(isSecondary))
  }

export const fetchAssetVersions =
  ({ assetMasterId = '', isSecondary = false }) =>
  (dispatch) => {
    const assetDataUrl = `${apiConfig.metadata.assetMetadataUrl}${assetMasterId}/versions`
    const promise = axios.get(assetDataUrl)
    const type = !isSecondary
      ? FETCH_ASSET_VERSIONS
      : FETCH_SECONDARY_ASSET_VERSIONS

    if (!!assetMasterId) {
      return dispatch({
        type,
        payload: promise,
      })
    }
  }

export const setViewedUsers =
  (isSecondary = false) =>
  async (dispatch, getState) => {
    const state = getState()
    const jobId = selectJobId(state)
    const assetId = selectAssetVersionId(isSecondary)(state)
    const assetMasterId = selectMasterId(isSecondary)(state)
    const viewedUsersUrl = `${apiConfig.annotation.commentsUrl}/users?key=${KEY}`
    const payload = {
      group_id: jobId,
      asset_id: assetId,
      asset_master_id: assetMasterId,
    }

    if (assetId && assetMasterId && jobId) {
      const promise = axios.post(viewedUsersUrl, payload)

      await dispatch({ type: SET_VIEWED_USERS, payload: promise })
    }
  }

export const storeCurrentMarkup = (markup) => ({
  type: STORE_CURRENT_MARKUP,
  payload: markup,
})

export const clearCurrentMarkup = () => ({
  type: CLEAR_CURRENT_MARKUP,
})

export const toggleComparisonView = (bool = false) => ({
  type: TOGGLE_COMPARISON_VIEW,
  payload: bool,
})

export const resetAssets = () => ({
  type: RESET_ASSETS,
})

export const resetPrimaryComments = () => ({
  type: RESET_PRIMARY_COMMENTS,
})

export const resetSecondaryComments = () => ({
  type: RESET_SECONDARY_COMMENTS,
})

// PRIMARY FUNCTIONS
export const setPrimaryAsset =
  (
    asset = {},
    isAssetIdTakenFromUrl = false,
    versionNumber = 0,
    pageNum = 1,
    callBackFun = () => {}
  ) =>
  async (dispatch, getState) => {
    dispatch(resetPrimaryComments())
    const { asset_master_id = '' } = asset
    const state = getState()
    const assetVersions = getAssetVersions(asset)
    const assetRevisionHistory = selectRevisionHistory(false)(state)
    let revisionNumber = [...assetVersions].pop()
    if (assetRevisionHistory[asset_master_id]) {
      revisionNumber = assetRevisionHistory[asset_master_id].versionNumber
    }
    revisionNumber =
      isAssetIdTakenFromUrl === true ? versionNumber : revisionNumber

    if (!!asset_master_id || isAssetIdTakenFromUrl) {
      await dispatch(setPrimaryVersionNumber(parseInt(revisionNumber)))
      await dispatch(setPrimaryPageNumber(parseInt(pageNum)))
      await dispatch(
        fetchAssetVersions({
          assetMasterId: asset_master_id,
          isSecondary: false,
        })
      )
      await dispatch({ type: SET_PRIMARY_ASSET, payload: asset })
      await dispatch(
        getCommentsByAssetIdAndRevision(false, parseInt(versionNumber))
      )
      callBackFun()
    }
  }

export const setPrimaryPageNumber =
  (pageNumber = 1) =>
  async (dispatch) => {
    await dispatch({
      type: SET_PRIMARY_PAGE_NUMBER,
      payload: pageNumber,
    })
    return await dispatch(setViewedUsers())
  }

export const setPrimaryVersionNumber = (
  versionNumber = 1,
  revisionChanged = false
) => ({
  type: SET_PRIMARY_VERSION,
  payload: { versionNumber, revisionChanged },
})

// SECONDARY FUNCTIONS
export const setSecondaryAsset =
  (asset = {}) =>
  async (dispatch, getState) => {
    dispatch(resetSecondaryComments())
    const { asset_master_id = '' } = asset
    const state = getState()
    const assetVersions = getAssetVersions(asset)
    const assetRevisionHistory = selectRevisionHistory(true)(state)
    let revisionNumber = [...assetVersions].pop()
    if (assetRevisionHistory[asset_master_id]) {
      revisionNumber = assetRevisionHistory[asset_master_id].versionNumber
    }
    if (!!asset_master_id) {
      await dispatch(setSecondaryVersionNumber(revisionNumber))
      await dispatch(setSecondaryPageNumber(1))
      await dispatch(
        fetchAssetVersions({
          assetMasterId: asset_master_id,
          isSecondary: true,
        })
      )
      await dispatch({ type: SET_SECONDARY_ASSET, payload: asset })
      await dispatch(getCommentsByAssetIdAndRevision(true))
    }
  }

export const setSecondaryPageNumber =
  (pageNumber = 1) =>
  async (dispatch) => {
    await dispatch({
      type: SET_SECONDARY_PAGE_NUMBER,
      payload: pageNumber,
    })
    return await dispatch(setViewedUsers(true))
  }

export const setSecondaryVersionNumber = (
  versionNumber = 1,
  revisionChanged = false
) => ({
  type: SET_SECONDARY_VERSION,
  payload: { versionNumber, revisionChanged },
})

export const setPrimaryAssetRevisionChangeHistory = (
  assetMasterId = '',
  versionNumber = 0,
  thumbnailPath = ''
) => ({
  type: SET_PRIMARY_ASSET_REVISION_HISTORY,
  payload: { assetMasterId, versionNumber, thumbnailPath },
})
export const setSecondaryAssetRevisionChangeHistory = (
  assetMasterId = '',
  versionNumber = 0,
  thumbnailPath = ''
) => ({
  type: SET_SECONDARY_ASSET_REVISION_HISTORY,
  payload: { assetMasterId, versionNumber, thumbnailPath },
})

export const handleComparisonToSingle = () => ({
  type: FROM_COMPARISON_TO_SINGLE,
})
