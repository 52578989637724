export const TASK_API_CALL_INNITIATED = 'TASK_API_CALL_INNITIATED'
export const TASK_API_CALL_SUCCESS = 'TASK_API_CALL_SUCCESS'
export const TASK_API_CALL_FAIL = 'TASK_API_CALL_FAIL'
export const EXPAND_TASK = 'TASK_DETAIL__API_CALL_FAIL'
export const COLLAPSE_TASK = 'COLLAPSE_TASK'
export const EXPAND_TASK_NOTIFICATIONS = 'EXPAND_TASK_NOTIFICATIONS'
export const COLLAPSE_TASK_NOTIFICATIONS = 'COLLAPSE_TASK_NOTIFICATIONS'
export const EXPAND_TASK_SUCCESS = 'EXPAND_TASK_SUCCESS'
export const EXPAND_TASK_FAIL = 'EXPAND_TASK_FAIL'
export const OPEN_MODAL_TASK = 'OPEN_MODAL_TASK'
export const CLOSE_MODAL_TASK = 'CLOSE_MODAL_TASK'
export const TASK_INFO_TASK_API_CALL_INNITIATED =
  'TASK_INFO_TASK_API_CALL_INNITIATED'
export const TASK_INFO_API_CALL_SUCCESS = 'TASK_INFO_API_CALL_SUCCESS'
export const TASK_INFO_API_CALL_FAIL = 'TASK_INFO_API_CALL_FAIL'
export const EXPAND_GROUP = 'EXPAND_GROUP'
export const EXPAND_GROUP_SUCCESS = 'EXPAND_GROUP_SUCCESS'
export const EXPAND_GROUP_FAIL = 'EXPAND_GROUP_FAIL'
export const COLLAPSE_GROUP = 'COLLAPSE_GROUP'
export const CREATE_TASK_API_CALL_INNITIATED = 'CREATE_TASK_API_CALL_INNITIATED'
export const TASK_CREATE_API_CALL_SUCCESS = 'TASK_CREATE_API_CALL_SUCCESS'
export const TASK_CREATE_API_CALL_FAIL = 'TASK_CREATE_API_CALL_FAIL'

export const CREATE_PROJECT_TASK = 'CREATE_PROJECT_TASK'
export const CREATE_PROJECT_TASK_FULFILLED = 'CREATE_PROJECT_TASK_FULFILLED'
export const CREATE_PROJECT_TASK_PENDING = 'CREATE_PROJECT_TASK_PENDING'
export const CREATE_PROJECT_TASK_REJECTED = 'CREATE_PROJECT_TASK_REJECTED'

export const EDIT_PROJECT_TASK = 'EDIT_PROJECT_TASK'
export const EDIT_PROJECT_TASK_FULFILLED = 'EDIT_PROJECT_TASK_FULFILLED'
export const EDIT_PROJECT_TASK_PENDING = 'EDIT_PROJECT_TASK_PENDING'
export const EDIT_PROJECT_TASK_REJECTED = 'EDIT_PROJECT_TASK_REJECTED'
export const RESET_TO_INIT_STATE = 'RESET_TO_INIT_STATE'

export const DOWNLOAD_TASK_REPORT = 'DOWNLOAD_TASK_REPORT'
export const DOWNLOAD_TASK_REPORT_PENDING = 'DOWNLOAD_TASK_REPORT_PENDING'
export const DOWNLOAD_TASK_REPORT_FULFILLED = 'DOWNLOAD_TASK_REPORT_FULFILLED'
export const DOWNLOAD_TASK_REPORT_REJECTED = 'DOWNLOAD_TASK_REPORT_REJECTED'

export const OPEN_SNACKBAR = 'OPEN_SNACKBAR'
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR'
