import axios from 'axios'
import {
  DISPLAY_ERROR_EVENT,
  RECEIVE_ASSETS_SUCCESS,
  CHANGE_CURRENT_ASSETS_PAGE,
  CHANGE_DEFAULT_ASSET_PAGE_SIZE,
  REMOVE_FROM_PENDING_LIST,
  SELECT_ASSET_EVENT,
  SET_SORT_DIRECTION,
  SET_SORT_FIELD,
  DOWNLOAD_START_ASSETS,
  DOWNLOAD_FINISH_ASSETS,
  CHANGE_ASSET_FILTER,
  ADD_IMAGE_TO_PENDING_LIST,
  UPDATE_IMAGE_TO_PENDING_LIST,
} from './actionType'
import { showNotification } from '../notification/actionCreator'
import {
  addImageToChannelPendingList,
  updateImageToChannelPendingList,
  removePendingChannelFiles,
  getChannelAssets,
} from '../channelUpload/channelsActionCreator'
import apiConfig from '../../config/apiConfig'
import { selectUserEmail, selectUserId } from '../auth/selector'
import firefly from '../../analytics/firefly'
import { makePublishUploadFileData } from './assetPublishSelector'

const { publishUpload = {}, key: apiConfigKey = '' } = apiConfig
const {
  REACT_APP_DAP_PROCESSOR_BASE_URL = '',
  REACT_APP_CHUNKSIZE: chunkSize = Number(5242880),
  REACT_APP_NO_CHUNK_MAX = Number(5),
  REACT_APP_DAP_API_BASE_URL: baseUrl = '',
  publishUploadApi = '',
  uploadStartApi = '',
  uploadChunkApi = '',
  uploadEndApi = '',
} = publishUpload

export function addImageToPendingList(pendingFile) {
  return {
    type: ADD_IMAGE_TO_PENDING_LIST,
    payload: {
      pendingFile: pendingFile,
    },
  }
}

export function updateImageToPendingList(pendingFile) {
  return {
    type: UPDATE_IMAGE_TO_PENDING_LIST,
    payload: {
      pendingFile: pendingFile,
    },
  }
}

export function changeDefaultAssetPageSize(defaultAssetPageSize) {
  return {
    type: CHANGE_DEFAULT_ASSET_PAGE_SIZE,
    payload: {
      defaultAssetPageSize: defaultAssetPageSize,
    },
  }
}
export function changeCurrentAssetsPage(currentPage) {
  return {
    type: CHANGE_CURRENT_ASSETS_PAGE,
    payload: {
      currentPage: currentPage,
    },
  }
}

export function removePendingFiles(files) {
  return {
    type: REMOVE_FROM_PENDING_LIST,
    payload: {
      files,
    },
  }
}

export function displayErrorEvent(errorMessage) {
  return {
    type: DISPLAY_ERROR_EVENT,
    payload: {
      errorMessage: errorMessage,
    },
  }
}

export function receiveAssetsSuccess(assets) {
  const {
    total_elements = Number(0),
    total_pages = Number(0),
    content = '',
  } = assets
  return {
    type: RECEIVE_ASSETS_SUCCESS,
    payload: {
      assetsCount: total_elements,
      totalAssetsPages: total_pages,
      publishAssets: content,
    },
  }
}

export function handleSelectAsset(data) {
  const { selectedAssets = {} } = data
  return {
    type: SELECT_ASSET_EVENT,
    payload: {
      selectedAssets: selectedAssets,
    },
  }
}

export function setSortDirection(value) {
  return {
    type: SET_SORT_DIRECTION,
    payload: value,
  }
}

export function setSortField(value) {
  return {
    type: SET_SORT_FIELD,
    payload: value,
  }
}

export function downloadStartAssets() {
  return {
    type: DOWNLOAD_START_ASSETS,
  }
}

export function downloadFinishAssets() {
  return {
    type: DOWNLOAD_FINISH_ASSETS,
  }
}

export function changeAssetFilter(filter) {
  return {
    type: CHANGE_ASSET_FILTER,
    payload: filter,
  }
}
export function downloadAssets(ids = []) {
  const fileDownload = require('js-file-download')
  let payload = ids.map(({ job_id: jobId = '' }) => jobId)
  return (dispatch) => {
    dispatch(downloadStartAssets())
    axios
      .post(
        `${REACT_APP_DAP_PROCESSOR_BASE_URL}/publish_processor/v1/jobs/download_selected_publish_asset_data`,
        payload,
        {
          responseType: 'arraybuffer',
          headers: {
            Accept: '*/*',
            'Content-Type': 'application/json',
          },
        }
      )
      .then((res) => {
        fileDownload(
          res.data,
          `published_assets_${new Date().toISOString()}.xlsx`
        )
        dispatch(downloadFinishAssets())
      })
      .catch((error) => {
        dispatch(
          showNotification(true, `Error while downloading xls file`, 'error')
        )
        dispatch(downloadFinishAssets())
      })
  }
}

export function requestAssetsEvent({
  pageNumber = Number(0),
  pageSize = Number(10),
  sortDirection = 'DESC',
  sortBy = 'CREATED_AT',
  assetFilter = '',
}) {
  return (dispatch, getState) => {
    const state = getState() || {}
    const userEmail = selectUserEmail()(state)
    axios
      .get(
        `${REACT_APP_DAP_PROCESSOR_BASE_URL}/publish_processor/v1/jobs?page=${pageNumber}&size=${pageSize}&sort_by=${sortBy}&sort_direction=${sortDirection}&created_by=${userEmail}&original_file_name=${assetFilter}`
      )
      .then((res) => {
        const { data = {} } = res
        const { content = '' } = data
        dispatch(receiveAssetsSuccess(data))
        dispatch(removePendingFiles(content))
      })
      .catch((error) => {
        dispatch(
          showNotification(true, `Error while requesting assets`, 'error')
        )
      })
  }
}

export function publishAsset(currentFile = {}, selectedChannelId = '') {
  return (dispatch) => {
    /* Reload assets before next upload */
    if (selectedChannelId) {
      dispatch(
        getChannelAssets({
          channelId: selectedChannelId,
        })
      )
    } else {
      dispatch(requestAssetsEvent({}))
    }
    if (currentFile.size > parseInt(chunkSize)) {
      dispatch(initiateChunkUpload(currentFile, selectedChannelId))
    } else {
      dispatch(initiateSimpleUpload(currentFile, 1, selectedChannelId))
    }
  }
}

function initiateSimpleUpload(
  currentFile = {},
  count = Number(0),
  selectedChannelId = ''
) {
  const uploadApi = generateUploadApi(publishUploadApi, selectedChannelId)
  const fileId = Math.random()
  const { name = '' } = currentFile
  return (dispatch, getState) => {
    const state = getState() || {}
    const { auth = {} } = state
    const { email = '' } = auth
    let formdata = new window.FormData()
    formdata.append('file', currentFile)
    if (selectedChannelId) {
      dispatch(
        addImageToChannelPendingList({
          id: fileId,
          job_id: fileId,
          original_file_name: name,
          created_at: new Date(),
          created_by: email,
          status: 'NEW',
          asset_channel_id: selectedChannelId,
        })
      )
    } else {
      dispatch(
        addImageToPendingList({
          id: fileId,
          job_id: fileId,
          original_file_name: name,
          created_at: new Date(),
          created_by: email,
          status: 'NEW',
        })
      )
    }
    /* Add to temporary list */
    axios
      .post(uploadApi, formdata)
      .then((res) => {
        /* Update temporary list */
        const { data = {} } = res
        const { job_id = '' } = data
        if (selectedChannelId) {
          dispatch(
            updateImageToChannelPendingList({
              id: fileId,
              job_id: job_id,
              original_file_name: name,
              created_at: new Date(),
              created_by: email,
              status: 'UPLOADED',
              asset_channel_id: selectedChannelId,
            })
          )
        } else {
          dispatch(
            updateImageToPendingList({
              id: fileId,
              job_id: job_id,
              original_file_name: name,
              created_at: new Date(),
              created_by: email,
              status: 'UPLOADED',
            })
          )
        }
        firefly.trackUpload(
          'publish_upload',
          'SUCCESS',
          name,
          makePublishUploadFileData(currentFile),
          selectUserId()(state)
        )
      })
      .catch((error) => {
        dispatch(
          showNotification(true, 'Error while uploading assets', 'error')
        )
        if (selectedChannelId) {
          dispatch(removePendingChannelFiles([{ job_id: fileId }]))
        } else {
          dispatch(removePendingFiles([{ job_id: fileId }]))
        }
        firefly.trackUpload(
          'publish_upload',
          'ERROR',
          name,
          makePublishUploadFileData(currentFile),
          selectUserId()(state)
        )
      })
  }
}

function initiateChunkUpload(currentFile = {}, selectedChannelId = '') {
  const uploadApi = generateUploadApi(uploadStartApi, selectedChannelId)
  const { type = '', name = '', size = Number(0) } = currentFile
  return (dispatch, getState) => {
    const state = getState() || {}
    const { auth = {} } = state
    const { email = '' } = auth
    axios
      .post(uploadApi, {
        chunk_size: chunkSize,
        content_type: type,
      })
      .then((res) => {
        /* Add to temporary list */
        const { data = {} } = res
        const { publish_id = '', publish_folder_path = '' } = data
        if (selectedChannelId) {
          dispatch(
            addImageToChannelPendingList({
              id: publish_id,
              job_id: publish_id,
              original_file_name: name,
              created_by: email,
              created_at: new Date(),
              status: 'NEW',
              asset_channel_id: selectedChannelId,
            })
          )
        } else {
          dispatch(
            addImageToPendingList({
              id: publish_id,
              job_id: publish_id,
              original_file_name: name,
              created_by: email,
              created_at: new Date(),
              status: 'NEW',
            })
          )
        }
        /* Start Uploading file */
        dispatch(
          performChunkUpload(
            {
              email: email,
              publish_id: publish_id,
              original_file_name: name,
              publish_folder_path: publish_folder_path,
              file_data: currentFile,
              content_type: type,
              chunk_tags: [],
              upload_config: {
                chunk_size: chunkSize,
                size_of_file: size,
                number_of_chunks: Math.ceil(size / parseInt(chunkSize)),
                current_chunk: 0,
                retry: 0,
              },
            },
            selectedChannelId
          )
        )
      })
      .catch((error) => {
        dispatch(
          showNotification(
            true,
            'Error while initiating chunk asset upload',
            'error'
          )
        )
      })
  }
}

function setConcurrentPromiseUpload(totalChunks = Number(5), list = []) {
  let tail = list.splice(totalChunks)
  let head = [...list]
  let resolved = []
  let processed = 0
  return new Promise((resolve, reject) => {
    head.forEach((x) => {
      let res = x()
      resolved.push(res)
      res
        .then((y) => {
          runNext()
          return y
        })
        .catch((e) => {
          reject(e)
        })
    })
    function runNext() {
      if (processed === tail.length) {
        resolve(Promise.all(resolved))
      } else {
        resolved.push(
          tail[processed]().then((x) => {
            runNext()
            return x
          })
        )
        processed++
      }
    }
  })
}

function performChunkUpload(request = {}, selectedChannelId = '') {
  return (dispatch) => {
    const uploadApi = generateUploadApi(uploadChunkApi, selectedChannelId)
    const {
      publish_id = '',
      publish_folder_path = '',
      upload_config = {},
      file_data = {},
      original_file_name = '',
      email = '',
    } = request
    const { chunk_size = Number(0), number_of_chunks = Number(0) } =
      upload_config
    try {
      let promisesArray = []
      for (let index = 1; index <= number_of_chunks; index++) {
        const chunkedFormData = new FormData()
        const chunk = file_data.slice(
          (index - 1) * chunk_size,
          index * chunk_size
        )
        chunkedFormData.append(
          'chunkPublishRequest',
          new window.Blob(
            [
              JSON.stringify({
                publish_id: publish_id,
                publish_folder_path: publish_folder_path,
                chunk_number: index,
              }),
            ],
            {
              type: 'application/json',
            }
          )
        )
        chunkedFormData.append('file', chunk)
        promisesArray.push(
          () =>
            new Promise((resolve) =>
              axios
                .post(uploadApi, chunkedFormData)
                .then((res) => {
                  const { data = {} } = res
                  const { publish_chunk_id = '', chunk_number = Number(0) } =
                    data
                  request.chunk_tags.push({
                    publish_chunk_id: publish_chunk_id,
                    chunk_number: chunk_number,
                  })
                  resolve()
                })
                .catch((error) => {
                  dispatch(
                    showNotification(
                      true,
                      'Error while chunking assets',
                      'error'
                    )
                  )
                })
            )
        )
        if (selectedChannelId) {
          dispatch(
            updateImageToChannelPendingList({
              id: publish_id,
              job_id: publish_id,
              original_file_name: original_file_name,
              created_at: new Date(),
              created_by: email,
              status: 'UPLOADING',
              asset_channel_id: selectedChannelId,
            })
          )
        } else {
          dispatch(
            updateImageToPendingList({
              id: publish_id,
              job_id: publish_id,
              original_file_name: original_file_name,
              created_at: new Date(),
              created_by: email,
              status: 'UPLOADING',
            })
          )
        }
      }
      setConcurrentPromiseUpload(REACT_APP_NO_CHUNK_MAX, promisesArray).then(
        (res) => {
          dispatch(performEndChunck(request, selectedChannelId))
        }
      )
    } catch (error) {
      dispatch(
        showNotification(true, 'Error while uploading chunk assets', 'error')
      )
      if (selectedChannelId) {
        dispatch(removePendingChannelFiles([{ job_id: publish_id }]))
      } else {
        dispatch(removePendingFiles([{ job_id: publish_id }]))
      }
    }
  }
}

function performEndChunck(request = {}, selectedChannelId = '') {
  const uploadApi = generateUploadApi(uploadEndApi, selectedChannelId)
  const {
    original_file_name = '',
    upload_config = {},
    chunk_tags = '',
    publish_id = '',
    content_type = '',
    publish_folder_path = '',
    email = '',
  } = request
  let metadataInfo = ''
  if (selectedChannelId) {
    metadataInfo = JSON.stringify({
      ah_channel_id: selectedChannelId,
    })
  }
  const { chunk_size = Number(0), size_of_file = Number(0) } = upload_config
  let requestBody = {
    metadata: metadataInfo,
    file_name: original_file_name,
    chunk_size: chunk_size,
    chunk_tags: chunk_tags,
    publish_id: publish_id,
    content_type: content_type,
    total_file_size: size_of_file,
    publish_folder_path: publish_folder_path,
  }
  return async (dispatch, getState) => {
    const state = getState() || {}
    await axios
      .post(uploadApi, requestBody)
      .then((res) => {
        const { data = {} } = res
        const { job_id = '' } = data

        if (selectedChannelId) {
          dispatch(
            updateImageToChannelPendingList({
              id: publish_id,
              job_id: job_id,
              original_file_name: original_file_name,
              created_by: email,
              created_at: new Date(),
              status: 'UPLOAD COMPLETE',
              asset_channel_id: selectedChannelId,
            })
          )
        } else {
          dispatch(
            updateImageToPendingList({
              id: publish_id,
              job_id: job_id,
              original_file_name: original_file_name,
              created_by: email,
              created_at: new Date(),
              status: 'UPLOAD COMPLETE',
            })
          )
        }
        firefly.trackUpload(
          'publish_upload',
          'SUCCESS',
          original_file_name,
          '',
          selectUserId()(state)
        )
      })
      .catch((error) => {
        dispatch(
          showNotification(
            true,
            'Error while completing assets upload',
            'error'
          )
        )
        if (selectedChannelId) {
          dispatch(removePendingChannelFiles([{ job_id: publish_id }]))
        } else {
          dispatch(removePendingFiles([{ job_id: publish_id }]))
        }
        firefly.trackUpload(
          'publish_upload',
          'ERROR',
          original_file_name,
          '',
          selectUserId()(state)
        )
      })
  }
}

function generateUploadApi(requestApi = '', selectedChannelId = '') {
  let finalUploadApi = ''
  const channelMetadata = {
    ah_channel_id: selectedChannelId,
  }
  const apiKeyParam = `?key=${apiConfigKey}`
  const channelApiParams = `&metadata=${encodeURIComponent(
    JSON.stringify(channelMetadata)
  )}`
  if (selectedChannelId) {
    finalUploadApi = `${baseUrl}${requestApi}${apiKeyParam}${channelApiParams}`
  } else {
    finalUploadApi = `${baseUrl}${requestApi}${apiKeyParam}`
  }
  return finalUploadApi
}
