import * as React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Navigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { isEmpty } from 'lodash'
/**Material Components */
import InputAdornment from '@mui/material/InputAdornment'
import MenuItem from '@mui/material/MenuItem'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'
import Avatar from '@mui/material/Avatar'
import TablePagination from '@mui/material/TablePagination'
/**Icons */
import { FilterHdr } from '@mui/icons-material'
import Search from '@mui/icons-material/Search'
/**Firefly */
import firefly from '../../../analytics/firefly'
/**Components */
import withRouter from '../../../containers/Router/WithRouter'
import HeaderTitle from '../../../components/Header/HeaderTitle'
import BulkActionBar from '../../../components/Search/BulkActionBar'
import MetadataPreview from '../../Metadata'
import BoardData from './BoardData'
import BoardMember from './BoardMember'
import RemoveAssetsDailog from './RemoveAssetsDialog'
import BoardAssetList from './BoardAssetList'
import DeleteBoardDialog from './DeleteBoardDialog'
import ConfirmationDialog from './ConfirmationDialog'
import EditBoardUserForm from './EditBoardUserForm'
import PageSizeSelector from '../../../components/Search/PageSizeSelector'
import CreateBoardForm from '../CreateBoardForm'
import DeleteAssetsDailog from '../../../components/Search/DeleteAssetsDailog'
import CustomCard from '../../../components/Common/Card/CustomCard'
/**Helpers */
import { startDownload } from '../../../helpers/bulkDownloadHelper'
import { getDeleteAssetNotificationMessage } from '../../../helpers/MetadataHelper'
import {
  getBoardAdminUsersInfo,
  sortingHandleFunction,
} from '../../../helpers/BoardsHelper'
import {
  generateBreadcrumb,
  filterByField,
} from '../../../helpers/UtilityHelper'
import {
  formatBreadcrumb,
  getPreviewAssetMetadata,
  removeAsset,
  isPageSelected,
} from './helper'
/**Selectors */
import {
  selectBoard,
  selectBoardAssets,
  boardsIsFetching,
  selectIsCreateBoardModalOpen,
  selectIsRedirectToBoardList,
} from '../../../store/boards/selector'
import {
  selectIsFetchAssetsInProgress,
  selectBoardsAssetMetadata,
  selectDownloadAssetInProgress,
  selectDeleteAssetInProgress,
  selectIsMetadataDownloadInProgress,
} from '../../../store/search/selector'
import {
  makeSelectAuthData,
  selectIsAdGroupSuperAdmin,
  selectIsLibrarian,
  selectUserEmail,
  selectUserId,
} from '../../../store/auth/selector'
import {
  selectIsToggleRightSidenav,
  selectRightSideNavComponent,
} from '../../../store/layout/selector'
/**Action creators */
import {
  boardRemoveAssets,
  closeModal,
  deleteBoard,
  fetchBoard,
  fetchBoardUsersDetails,
  deleteSpecificUserFromBoard,
  updateAccessToBoardMember,
  openModal,
  setBoardDownloadAssets,
  updateBoard,
} from '../../../store/boards/actions'
import {
  fetchBoardAssetsMetadata,
  fetchMetadatadetails,
  setDownloadAssetInProgress,
  setDeleteAssetInProgress,
  updateDownloadAssets,
} from '../../../store/search/actions'
import { toggleRightSidenav } from '../../../store/layout/actionCreator'
import {
  clearAssetRevisions,
  deleteRevisions,
} from '../../../store/metadata/actionCreator'
/**Helpers/utility methods */
import {
  BOARD_USER_REMOVE_ADMIN_MESSAGE,
  BOARD_USER_REMOVE_FINAL_ADMIN_MESSAGE,
  BOARD_USER_SELF_REMOVE_MESSAGE,
  BOARD_USER_SELF_REMOVE_NO_ADMIN_MESSAGE,
  SORT_OPTIONS_ASSETS,
} from '../../../constants/boards'

/**Styles */
const styles = makeStyles((theme) => ({
  boardDashboard: {
    padding: useTheme().spacing(),
  },
  root: {
    marginTop: useTheme().spacing(),
  },
  cardContent: {
    display: 'flex',
    color: '#212121',
    fontSize: '14px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonProgress: {
    color: '#2196f3',
  },
  textField: {
    marginTop: 0,
  },
  noAssets: {
    marginTop: 20,
    minHeight: 200,
    width: '90%',
    padding: useTheme().spacing(2),
    textAlign: 'center',
    color: useTheme().palette.text.secondary,
  },
  listActionBar: {
    padding: useTheme().spacing(2),
    alignItems: 'center',
  },
  pageSizeSelector: {
    fontSize: '14px',
    color: '#666',
  },
  displayFlex: {
    display: 'flex',
  },
  boardsAssetsSection: {
    overflow: 'scroll',
    maxHeight: '70vh',
  },
}))

const createSortByOptionsAsset = () =>
  Object.keys(SORT_OPTIONS_ASSETS).map((key) => (
    <MenuItem key={key} value={key}>
      {SORT_OPTIONS_ASSETS[key]}
    </MenuItem>
  ))

class BoardDashboard extends React.Component {
  constructor(props) {
    super(props)
    this.assetsRef = React.createRef()
    this.state = {
      rowsPerPage: 21,
      page: 0,
      filterText: '',
      showAssetMetadata: false,
      previewingAssets: [],
      currentPreviewingAssetIndex: 0,
      isDownloadInitiated: false,
      downloadAssets: {},
      downloadAssetType: '',
      checkAll: false,
      displayRemoveAssetsConfirmationDialog: false,
      deleteBoardModalOpen: false,
      openBoardForm: false,
      userListBoard: [],
      openConfirmationDialog: false,
      isRemoveUser: false,
      boardUserId: '',
      roleId: '',
      confirmationDialogContentMsg: '',
      deleteBoardEL: null,
      displayDeleteAssetConfirmationModal: false,
      sortByOption: 'added_date',
    }
  }

  componentDidMount() {
    const {
      fetchBoard = () => {},
      setBoardDownloadAssets = () => {},
      router: { params: { boardId = '' } = {} } = {},
    } = this.props
    fetchBoard(boardId)
    this.getUserListBoard(boardId)
    setBoardDownloadAssets({})
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      downloadInProgress: prevDownloadInProgress,
      deleteAssetInProgress: prevDeleteAssetInProgress,
    } = prevProps
    const {
      deleteAssetInProgress = false,
      downloadInProgress,
      fetchBoard,
      router: { params: { boardId = '' } } = {},
    } = this.props

    if (prevDownloadInProgress && !downloadInProgress) {
      this.setState({ isDownloadInitiated: false, downloadAssets: {} })
    }

    if (prevDeleteAssetInProgress && !deleteAssetInProgress) {
      this.setState(
        {
          isDeleteInitiated: false,
          downloadAssets: {},
          displayDeleteAssetConfirmationModal: false,
        },
        () => {
          fetchBoard(boardId)
        }
      )
    }
  }

  getUserListBoard = (boardId) => {
    const { fetchBoardUsersDetails = () => {} } = this.props
    fetchBoardUsersDetails(boardId, (boardUsersList) => {
      this.setState({ userListBoard: boardUsersList })
    })
  }
  handlePagination = (event, page) => {
    const { board = {}, assets = {} } = this.props
    const { filterText, downloadAssets, rowsPerPage } = this.state
    const checkAll = isPageSelected(
      board,
      assets,
      page,
      rowsPerPage,
      filterText,
      downloadAssets
    )
    this.assetsRef.current.scrollTo(0, 0)
    this.setState({ page: page, checkAll })
  }

  handleFilter = (event) => {
    this.setState({ filterText: event.target.value })
  }

  searchAssetMetadata = (assetList = [], index) => {
    const { fetchBoardAssetsMetadata = () => {} } = this.props
    const { page, rowsPerPage } = this.state
    const assetListIndex = page === 0 ? index : rowsPerPage * page + index

    const filters = {
      assetId: assetList?.map((asset) => asset.asset_id),
    }
    // fetch all assets from search
    // fetchBoardAssetsMetadata({
    //   filters,
    //   pageNumber: 0,
    //   size: assetList.length,
    // })
    this.setState({
      showAssetMetadata: true,
      previewingAssets: assetList,
      currentPreviewingAssetIndex: assetListIndex,
    })
  }

  nextAsset = () => {
    const { previewingAssets, currentPreviewingAssetIndex } = this.state
    const { clearAssetRevisions } = this.props
    clearAssetRevisions()
    let nextAssetIndex = currentPreviewingAssetIndex + 1
    if (nextAssetIndex === previewingAssets.length) {
      // get first page
      nextAssetIndex = 0
    }

    this.setState({ currentPreviewingAssetIndex: nextAssetIndex })
  }

  previousAsset = () => {
    const { previewingAssets, currentPreviewingAssetIndex } = this.state
    const { clearAssetRevisions } = this.props
    clearAssetRevisions()
    let previousAssetIndex = currentPreviewingAssetIndex - 1
    if (previousAssetIndex === -1) {
      // get last page
      previousAssetIndex = previewingAssets.length - 1
    }

    this.setState({ currentPreviewingAssetIndex: previousAssetIndex })
  }
  callSetBoardDownloadAssets = () => {
    const { setBoardDownloadAssets = () => {} } = this.props
    const { downloadAssets = {} } = this.state
    setBoardDownloadAssets(downloadAssets)
  }
  onSelection = (checked, ref, asset) => {
    const { render_urls = {} } = asset
    const { downloadAssets = {} } = this.state
    const { id = '', href = '', title = '', dataset = {} } = ref.current || {}
    const { importdate = '' } = dataset
    const assetPresentInDownloadAssets = Object.keys(downloadAssets).find(
      (assetId) => assetId === id
    )

    if (checked && !assetPresentInDownloadAssets) {
      const filePath = href?.substring(0, href?.indexOf('?'))
      // if the current asset is not already selected add it to downloadAssets
      const downloadAsset = {
        [id]: {
          assetId: id,
          fileName: title,
          filePath,
          importDate: importdate,
          render_urls,
        },
      }

      this.setState(
        (prevState) => ({
          downloadAssets: { ...prevState.downloadAssets, ...downloadAsset },
        }),
        this.callSetBoardDownloadAssets
      )
    } else {
      // remove from downloadAssets
      this.setState(
        (prevState) => ({
          downloadAssets: removeAsset(id, prevState?.downloadAssets),
        }),
        this.callSetBoardDownloadAssets
      )
    }
  }

  onSelectAll = (assetList = []) => {
    const { page, rowsPerPage, downloadAssets, checkAll } = this.state
    const { setBoardDownloadAssets = () => {} } = this.props
    const startIndex = rowsPerPage * page
    const endIndex = startIndex + rowsPerPage
    const selectedAssetsMap = {}
    let updatedDownloadAssets = {}
    const selectedAssets = assetList
      .slice(startIndex, endIndex)
      .map(
        ({
          asset_id = '',
          file_name = '',
          asset_preview_path = '',
          import_date = '',
          render_urls = {},
        }) => ({
          assetId: asset_id,
          fileName: file_name,
          filePath: asset_preview_path,
          importDate: import_date,
          render_urls,
        })
      )

    // format assets to add or remove to downloadAssets
    selectedAssets.forEach(
      (asset) => (selectedAssetsMap[asset.assetId] = { ...asset })
    )

    if (!checkAll) {
      updatedDownloadAssets = {
        ...downloadAssets,
        ...selectedAssetsMap,
      }
    } else {
      updatedDownloadAssets = {
        ...downloadAssets,
      }
      // remove selected assets from downloadAssets
      for (let assetId in selectedAssetsMap) {
        if (
          Object.entries(updatedDownloadAssets).length > 0 &&
          Object.keys(updatedDownloadAssets).includes(assetId)
        ) {
          delete updatedDownloadAssets[assetId]
        }
      }
    }

    this.setState((state) => ({
      checkAll: !state.checkAll,
      downloadAssets: updatedDownloadAssets,
    }))
    setBoardDownloadAssets(updatedDownloadAssets)
  }

  onDownloadClicked = (event) => {
    const { downloadAssets = {}, page } = this.state
    const { dispatch, setDownloadAssetInProgress, userId } = this.props
    const downloadedAssets = []

    const downloadAssetType = event.currentTarget.id
    this.setState({
      isDownloadInitiated: true,
      downloadAssetType,
      checkAll: false,
    })

    // get asset names
    for (let asset in downloadAssets) {
      if (downloadAssetType.asset) {
        downloadedAssets.push(asset.fileName || '')
      }
    }

    startDownload(
      { ...downloadAssets },
      dispatch,
      downloadAssetType,
      userId,
      page
    )
    setDownloadAssetInProgress(true)
  }

  onRemoveAssets = (filteredAssets) => {
    const {
      board: { board_id = '' } = {},
      auth: { lanId = '' },
      boardRemoveAssets = () => {},
    } = this.props
    const { downloadAssets } = this.state
    const assetIds = []
    for (let asset in downloadAssets) {
      let filteredAsset = filteredAssets.find(
        (filteredAsset) => filteredAsset.asset_id === asset
      )
      if (filteredAsset) {
        assetIds.push(filteredAsset.asset_uuid)
      }
    }

    boardRemoveAssets(assetIds, board_id, lanId)
    this.setState({
      downloadAssets: {},
      displayRemoveAssetsConfirmationDialog: false,
    })
  }

  toggleConfirmationModal = () => {
    const { displayRemoveAssetsConfirmationDialog } = this.state
    this.setState({
      displayRemoveAssetsConfirmationDialog:
        !displayRemoveAssetsConfirmationDialog,
    })
  }

  onPageSizeChange = (event) => {
    const { rowsPerPage } = this.state
    const pageSize = event?.target?.value
    if (pageSize !== rowsPerPage) {
      this.setState({ rowsPerPage: pageSize })
    }
  }

  deleteBoard = (boardId = '') => {
    const { auth = {}, deleteBoard = () => {} } = this.props
    const { lanId = '' } = auth
    deleteBoard({ boardId, lanId })
  }

  closeBoardsFrom = () => {
    this.setState({ openBoardForm: false })
  }

  handleRefreshBoardMember = () => {
    this.getUserListBoard(this.props.router.params.boardId)
  }

  removeSpecicificUserFromBoard = (data = {}) => {
    const {
      board_user_id: boardUserId = '',
      login_id: loginId = '',
      list_id: listId = '',
    } = data
    const { userId = '' } = this.props
    const { userListBoard = [] } = this.state
    const { isBoardAdmin, isOnlyAdmin } = getBoardAdminUsersInfo(
      userListBoard,
      userId,
      loginId
    )
    if (isBoardAdmin) {
      const displayMessage = isOnlyAdmin
        ? BOARD_USER_SELF_REMOVE_NO_ADMIN_MESSAGE
        : BOARD_USER_SELF_REMOVE_MESSAGE
      this.setState({
        openConfirmationDialog: true,
        isRemoveUser: true,
        boardUserId,
        listId,
        confirmationDialogContentMsg: displayMessage,
      })
    } else {
      this.deleteUserFromBoard(boardUserId, listId)
    }
    this.setState({ deleteBoardEL: null })
  }

  deleteUserFromBoard = (boardUserId = '', listId = '') => {
    const {
      deleteSpecificUserFromBoard,
      router = {},
      auth = {},
      board = {},
    } = this.props
    const { lanId = '' } = auth
    const { board_id = '' } = board
    const { isRemoveUser } = this.state
    deleteSpecificUserFromBoard(boardUserId, listId, lanId, board_id, () => {
      if (isRemoveUser) {
        router.navigate('/boards')
      } else {
        this.getUserListBoard(this.props.router.params.boardId)
      }
    })
  }

  handleConfirmationDialog = () => {
    const { isRemoveUser, boardUserId, roleId, listId } = this.state
    const { updateAccessToBoardMember, router = {} } = this.props
    if (isRemoveUser) {
      this.deleteUserFromBoard(boardUserId, listId)
      this.setState({ listId: '' })
    } else {
      updateAccessToBoardMember(boardUserId, roleId, listId, () => {
        this.setState(
          {
            openBoardForm: false,
            openConfirmationDialog: false,
            isRemoveUser: false,
            boardUserId: '',
            roleId: '',
            listId: '',
          },
          () => {
            router.navigate('/boards')
          }
        )
      })
    }
  }

  updateAccessBoardMember = (
    boardUserId = '',
    roleId = '',
    loginId = '',
    listId = ''
  ) => {
    const { userId = '' } = this.props
    const { userListBoard = [] } = this.state
    const { isBoardAdmin, isOnlyAdmin } = getBoardAdminUsersInfo(
      userListBoard,
      userId,
      loginId
    )
    if (isBoardAdmin) {
      const displayMessage = isOnlyAdmin
        ? BOARD_USER_REMOVE_FINAL_ADMIN_MESSAGE
        : BOARD_USER_REMOVE_ADMIN_MESSAGE
      this.setState({
        openConfirmationDialog: true,
        isRemoveUser: false,
        boardUserId,
        roleId,
        listId,
        confirmationDialogContentMsg: displayMessage,
      })
    } else {
      this.updateBoardMemberAccess(boardUserId, roleId, listId)
    }
  }

  updateBoardMemberAccess = (boardUserId = '', roleId = '', listId = '') => {
    const {
      updateAccessToBoardMember,
      router: { params: { boardId = '' } } = {},
    } = this.props
    updateAccessToBoardMember(boardUserId, roleId, listId, () => {
      this.getUserListBoard(boardId)
    })
  }

  openUpdateBoardForm = () => {
    const { board = {}, openModal } = this.props
    const { board_name = '', board_id = '', ai_eligible = false } = board
    openModal('isCreateBoardModalOpen', { board_id, board_name, isEdit: true, ai_eligible })
    this.setState({ deleteBoardEL: null })
  }

  closeCreateBoardsForm = () => {
    const { closeModal = () => {} } = this.props
    closeModal('isCreateBoardModalOpen')
  }

  openBoardMenu = (event) => {
    this.setState({ deleteBoardEL: event.currentTarget })
  }

  closeMenu = () => {
    this.setState({ deleteBoardEL: null })
  }

  downloadAssets = (asset) => {
    const { page = 0 } = this.state
    const { auth = {} } = this.props
    const { lanId = '' } = auth

    delete asset.fileUrl
    const assets = [asset]
    firefly.trackDownloads(assets, page, lanId)
  }

  assetCardDownload = (asset = {}) => {
    const { page = 0 } = this.state
    const { auth = {} } = this.props
    const { lanId = '' } = auth
    const {
      metadata: { file_metadata: { file_name = '' } } = {},
      asset_id = '',
      storage_url = '',
    } = asset

    const downloadAsset = {
      assetId: asset_id,
      fileName: file_name,
      filePath: storage_url,
    }

    const assets = [downloadAsset]
    firefly.trackDownloads(assets, page, lanId)
  }

  toggleDeleteAssetsConfirmation = (dispalyModal = true) => {
    this.setState({ displayDeleteAssetConfirmationModal: dispalyModal })
  }

  onDeleteAssets = (filteredAssets = []) => {
    const { downloadAssets = {} } = this.state
    const { deleteRevisions, setDeleteAssetInProgress } = this.props
    const deleteAssetIds = []
    if (!isEmpty(downloadAssets)) {
      this.setState({
        isDeleteInitiated: true,
        displayDeleteAssetConfirmationModal: false,
      })
      for (let asset in downloadAssets) {
        let filteredAsset = filteredAssets.find(
          (filteredAsset) => filteredAsset?.asset_id === asset
        )
        if (filteredAsset) {
          deleteAssetIds?.push(filteredAsset?.asset_id)
        }
      }
      const assetIds = {}
      if (deleteAssetIds?.length > 0) {
        assetIds['asset_uoi_ids'] = deleteAssetIds
      }
      if (Object.keys(assetIds)?.length > 0) {
        setDeleteAssetInProgress(true)
        deleteRevisions(
          assetIds,
          getDeleteAssetNotificationMessage(
            Object.values(downloadAssets)?.length
          )
        )
      }
    }
  }

  downloadMetadata = (e) => {
    const downloadAssetType = e.currentTarget.id
    let { downloadAssets } = this.state
    let { fetchMetadatadetails } = this.props
    this.setState({ downloadAssetType })
    let selected_file_ids = Object.values(downloadAssets)?.map(
      (asset) => asset?.assetId
    )
    fetchMetadatadetails(selected_file_ids)
  }

  onSortByChange = (event) => {
    const sortType = event.target.value
    if (sortType) {
      this.setState({ sortByOption: sortType })
    }
  }

  renderCreateBoard = () => {
    const { isCreateBoardModalOpen = false } = this.props
    return isCreateBoardModalOpen ? (
      <CreateBoardForm
        className="createBoardForm"
        open={isCreateBoardModalOpen}
        onClose={this.closeCreateBoardsForm}
      />
    ) : (
      ''
    )
  }
  renderEditBoardUsersForm = () => {
    const { board: { board_name = '', board_id = '' } = {} } = this.props
    const { openBoardForm, userListBoard } = this.state

    return openBoardForm ? (
      <EditBoardUserForm
        open={openBoardForm}
        onClose={this.closeBoardsFrom}
        formData={userListBoard}
        boardName={board_name}
        boardId={board_id}
        handleRefreshBoardMember={this.handleRefreshBoardMember}
        removeSpecicificUserFromBoard={this.removeSpecicificUserFromBoard}
        updateAccessBoardMember={this.updateAccessBoardMember}
      />
    ) : (
      ''
    )
  }

  renderConfirmationDialog = () => {
    const { board: { board_name = '' } = {} } = this.props
    const { openConfirmationDialog, confirmationDialogContentMsg } = this.state
    return openConfirmationDialog ? (
      <ConfirmationDialog
        open={openConfirmationDialog}
        dialogTitleText={board_name}
        dialogContentMsg={confirmationDialogContentMsg}
        close={() =>
          this.setState({
            openConfirmationDialog: false,
            isRemoveUser: false,
            boardUserId: '',
            roleId: '',
            confirmationDialogContentMsg: '',
          })
        }
        handleConfirm={this.handleConfirmationDialog}
      />
    ) : (
      ''
    )
  }

  renderDeleteBoardsDialog = () => {
    const { board = {} } = this.props
    const { deleteBoardModalOpen } = this.state
    return deleteBoardModalOpen ? (
      <DeleteBoardDialog
        open={deleteBoardModalOpen}
        board={board}
        close={() => this.setState({ deleteBoardModalOpen: false })}
        deleteBoard={this.deleteBoard}
      />
    ) : (
      ''
    )
  }

  closeBulkActionBar = () => {
    this.setState({ downloadAssets: {} })
  }

  getFilteredAssets = () => {
    const { assets = {}, board: { board_id = '' } = {} } = this.props
    const { sortByOption = 'added_date', filterText = '' } = this.state
    const assetList = assets[board_id] || []
    const sortedAssetList = sortingHandleFunction(assetList, sortByOption)
    return filterByField(sortedAssetList, 'file_name', filterText)
  }
  renderRemoveAssetDialog = () => {
    const { displayRemoveAssetsConfirmationDialog, downloadAssets = {} } =
      this.state
    const selectedAssetCount = Object.keys(downloadAssets)?.length
    const filteredAssets = this.getFilteredAssets()

    return displayRemoveAssetsConfirmationDialog ? (
      <RemoveAssetsDailog
        noOfSelectedAssets={selectedAssetCount}
        removeAssets={() => this.onRemoveAssets(filteredAssets)}
        toggleConfirmationModal={this.toggleConfirmationModal}
        dialogOpen={displayRemoveAssetsConfirmationDialog}
      />
    ) : (
      ''
    )
  }

  renderDeleteAssetsDailog = () => {
    const { displayDeleteAssetConfirmationModal = false, downloadAssets = {} } =
      this.state
    const selectedAssetCount = Object.keys(downloadAssets)?.length

    const filteredAssets = this.getFilteredAssets()
    return displayDeleteAssetConfirmationModal ? (
      <DeleteAssetsDailog
        noOfSelectedAssets={selectedAssetCount}
        deleteAssets={() => this.onDeleteAssets(filteredAssets)}
        toggleConfirmationModal={this.toggleDeleteAssetsConfirmation}
        dialogOpen={displayDeleteAssetConfirmationModal}
      />
    ) : (
      ''
    )
  }

  renderBulkActionBar = () => {
    const {
      isToggleRightSidenav,
      toggleRightSidenav = () => {},
      rightSideNavComponent,
      auth: { lanId = '' },
      userId = '',
      isAdminOrLibrarian = false,
      isMetadataDownloadInitiated = false,
    } = this.props
    const {
      downloadAssets = {},
      isDownloadInitiated,
      downloadAssetType,
      userListBoard,
    } = this.state
    const selectedAssetCount = Object.keys(downloadAssets)?.length
    const { isBoardAdmin } = getBoardAdminUsersInfo(
      userListBoard,
      userId,
      lanId
    )
    return selectedAssetCount > 0 ? (
      <BulkActionBar
        closeBulkActionBar={this.closeBulkActionBar}
        selectedAssets={downloadAssets}
        noOfSelectedItems={selectedAssetCount}
        onDownloadClicked={this.onDownloadClicked}
        toggleRightSidenav={toggleRightSidenav}
        isToggleRightSidenav={isToggleRightSidenav}
        rightSideNavComponent={Boolean(rightSideNavComponent)}
        isDownloadInitiated={isDownloadInitiated}
        downloadAssetType={downloadAssetType}
        enableBoardsDialog={true}
        enableDeleteAsset={isBoardAdmin && isAdminOrLibrarian}
        isAdminOrLibrarian={isAdminOrLibrarian}
        enableRemoveAssetsFromBoard={true}
        onRemoveAssets={this.toggleConfirmationModal}
        onDeleteAssets={this.toggleDeleteAssetsConfirmation}
        onDownloadMetadata={this.downloadMetadata}
        isMetadataDownloadInitiated={isMetadataDownloadInitiated}
        onClearSelection={this.onClearSelection}
      />
    ) : (
      ''
    )
  }
  renderMetadataPreview = () => {
    const {
      assetMetadataMap = {},
      board: { board_name = '' } = {},
      isFetchingAssetMetadata,
    } = this.props
    const {
      showAssetMetadata,
      currentPreviewingAssetIndex,
      previewingAssets = [],
    } = this.state
    const selectedAssetId =
      previewingAssets[currentPreviewingAssetIndex]?.asset_id
    return (
      <MetadataPreview
        selectedAssetId={selectedAssetId}
        isFetching={isFetchingAssetMetadata}
        showPreview={showAssetMetadata}
        closePreview={() => this.setState({ showAssetMetadata: false })}
        downloadAsset={this.downloadAssets}
        nextAsset={this.nextAsset}
        prevAsset={this.previousAsset}
        consumer={board_name || 'Board dashboard'}
      />
    )
  }

  renderBoardsAssets = () => {
    const { classes = {} } = this.props
    const { downloadAssets = {}, rowsPerPage, page } = this.state
    const selectedItems = Object.keys(downloadAssets)

    const filteredAssets = this.getFilteredAssets()
    return filteredAssets ? (
      <BoardAssetList
        page={page}
        rowsPerPage={rowsPerPage}
        assetList={filteredAssets}
        searchAssetMetadata={this.searchAssetMetadata}
        onSelection={this.onSelection}
        selectedItems={selectedItems}
        downloadAsset={this.assetCardDownload}
      />
    ) : (
      <Grid item xs className={classes.noAssets}>
        <p>This board has no assets.</p>
      </Grid>
    )
  }

  onClearSelection = () => {
    this.setState((state) => ({
      checkAll: false,
      downloadAssets: {},
    }))
  }

  renderSelectOrDeselectBtn = () => {
    const { checkAll } = this.state

    const filteredAssets = this.getFilteredAssets()
    const btnLabel = checkAll ? 'Deselect All' : 'Select All'
    const dataCyLabel = checkAll ? 'boardsDeselectAll' : 'boardsSelectAll'
    return filteredAssets.length > 0 ? (
      <Grid item xs={6} lg={4} md={4}>
        {
          <Button
            onClick={() => this.onSelectAll(filteredAssets)}
            data-cy={dataCyLabel}
          >
            {btnLabel}
          </Button>
        }
      </Grid>
    ) : (
      ''
    )
  }

  renderTablePagination = () => {
    const { rowsPerPage, page } = this.state

    const filteredAssets = this.getFilteredAssets()
    const isPageSelectorRequired = filteredAssets.length > rowsPerPage

    return isPageSelectorRequired ? (
      <TablePagination
        component="div"
        data-cy="tablePagination"
        testid="tablePagination"
        count={filteredAssets.length}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[]}
        page={page}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
          'data-cy': 'prevPage',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
          'data-cy': 'nextPage',
        }}
        onPageChange={this.handlePagination}
      />
    ) : (
      ''
    )
  }

  renderPageSizeSelector = () => {
    const { rowsPerPage } = this.state

    const filteredAssets = this.getFilteredAssets()
    const isPageSelectorRequired = filteredAssets.length > rowsPerPage
    return isPageSelectorRequired ? (
      <PageSizeSelector
        pageSize={rowsPerPage}
        onPageSizeChange={this.onPageSizeChange}
      />
    ) : (
      ''
    )
  }

  updateBoardAi = () =>{
    const {
      board: {
        board_id = '',
        board_name = '',
        ai_eligible = false,
      } = {},
      updateBoard
    } = this.props
    updateBoard({board_id,board_name, ai_eligible : !ai_eligible})
  }

  renderBoardsData = () => {
    const {
      board: {
        board_id = '',
        board_name = '',
        created_by = '',
        created_date = '',
        updated_by = '',
        updated_date = '',
        shared_user_count = 0,
        ai_eligible,
        admin = false,
      } = {},
    } = this.props
    const { deleteBoardEL } = this.state
    const filteredAssets = this.getFilteredAssets()
    return (
        <BoardData
        aiEligible={ai_eligible}
        boardName={board_name}
        createdBy={created_by}
        createdDate={created_date}
        sharedUserCount={shared_user_count}
        updatedBy={updated_by}
        updatedDate={updated_date}
        assetCount={filteredAssets.length}
        isBoardAdmin={admin}
        boardId = {board_id}
        updateBoard = {this.updateBoardAi}
        toggleDeleteBoardModal={(toggleValue = false) =>
          this.setState({ deleteBoardModalOpen: toggleValue })
        }
        openUpdateBoardForm={this.openUpdateBoardForm}
        deleteBoardEL={deleteBoardEL}
        openBoardMenu={this.openBoardMenu}
        closeMenu={this.closeMenu}
      />
          )
  }

  renderBoardMembers = () => {
    const { board: { admin = false } = {} } = this.props
    const { userListBoard } = this.state
    return (
      <BoardMember
        userListBoard={userListBoard}
        isAdmin={admin}
        toggleEditBoardModal={() => this.setState({ openBoardForm: true })}
      />
    )
  }

  renderAssetsCardContent = () => {
    const { isFetching, classes = {} } = this.props
    const { sortByOption = 'added_date' } = this.state
    return isFetching ? (
      <CircularProgress size={50} style={styles.buttonProgress} />
    ) : (
      <Grid
        container
        direction={'row'}
        className={classes.root}
        justifyContent="space-between"
      >
        <Grid item xs={6} lg={4} md={4}>
          <TextField
            id="searchFilterText"
            type="search"
            placeholder="Asset Name"
            onChange={this.handleFilter}
            className={classes.textField}
            variant="outlined"
            size={'small'}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            style={{
              width: '90%',
              margin: '16px 10px 0px 18px',
            }}
            fullWidth
          />
        </Grid>
        <Grid
          item
          xs={6}
          lg={4}
          md={4}
          style={{
            marginTop: '16px',
          }}
        >
          <TextField
            select
            variant="outlined"
            onChange={this.onSortByChange}
            value={sortByOption}
            size={'small'}
            InputProps={{
              classes: {
                root: classes.displayFlex,
              },
            }}
            style={{ display: 'flex' }}
          >
            {createSortByOptionsAsset()}
          </TextField>
        </Grid>
        <Grid
          container
          direction={'row'}
          className={classes.listActionBar}
          justifyContent="flex-end"
        >
          {
            /**Render Select/Deselect all button */
            this.renderSelectOrDeselectBtn()
          }
          <Grid item xs={6} lg={4} md={4} className={classes.pageSizeSelector}>
            {
              /**Page Size selector */
              this.renderPageSizeSelector()
            }
          </Grid>
          <Grid item xs={6} lg={4} md={4}>
            {
              /**Table Pagination */
              this.renderTablePagination()
            }
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.boardsAssetsSection}
          ref={this.assetsRef}
        >
          {
            /**Render Boards Assets */
            this.renderBoardsAssets()
          }
        </Grid>
        <Grid item xs={12}>
          {
            /**Table Pagination */
            this.renderTablePagination()
          }
        </Grid>
      </Grid>
    )
  }

  render() {
    const {
      classes = {},
      // isFetching,
      board = {},
      isRedirectToBoardList = false,
    } = this.props
    // const { sortByOption = 'added_date' } = this.state
    const breadCrumb = formatBreadcrumb(board) || []

    const cardConfig = {
      cardHeader: {
        headerAvatar: (
          <Avatar aria-label="Assets">
            <FilterHdr />
          </Avatar>
        ),
        title: 'Assets',
        subheader: '',
        identifier: '',
      },
      displayFooter: false,
    }

    return isRedirectToBoardList ? (
      <Navigate
        to={{
          pathname: '/boards',
          state: { referrer: '/board' },
        }}
        replace
      />
    ) : (
      <div>
        <HeaderTitle title={generateBreadcrumb(breadCrumb)} icon="Boards" />
        <Helmet>
          <title>{generateBreadcrumb(breadCrumb, true)}</title>
        </Helmet>
        <div className={classes.boardDashboard}>
          <Grid container spacing={1}>
            <Grid item sm={4} xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {this.renderBoardsData()}
                </Grid>
                <Grid item xs={12}>
                  {this.renderBoardMembers()}
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={8} xs={12}>
              <CustomCard
                cardConfig={cardConfig}
                cardContent={this.renderAssetsCardContent()}
                minHeight={800}
              />
            </Grid>
          </Grid>
        </div>
        {this.renderMetadataPreview()}
        {this.renderBulkActionBar()}
        {this.renderDeleteAssetsDailog()}
        {this.renderRemoveAssetDialog()}
        {this.renderDeleteBoardsDialog()}
        {this.renderEditBoardUsersForm()}
        {this.renderConfirmationDialog()}
        {this.renderCreateBoard()}
      </div>
    )
  }
}

BoardDashboard.propTypes = {
  classes: PropTypes.object,
  fetchBoard: PropTypes.func.isRequired,
  board: PropTypes.object,
  assets: PropTypes.object,
  isFetching: PropTypes.bool,
  fetchBoardAssetsMetadata: PropTypes.func,
  assetMetadataMap: PropTypes.object,
  isFetchingAssetMetadata: PropTypes.bool,
  isToggleRightSidenav: PropTypes.bool,
  dispatch: PropTypes.func,
  setDownloadAssetInProgress: PropTypes.func,
  toggleRightSidenav: PropTypes.func,
  rightSideNavComponent: PropTypes.string,
  deleteAssetInProgress: PropTypes.bool,
  downloadInProgress: PropTypes.bool,
  updateDownloadAssets: PropTypes.func,
  boardRemoveAssets: PropTypes.func,
  userId: PropTypes.string,
  clearAssetRevisions: PropTypes.func,
  setBoardDownloadAssets: PropTypes.func,
  setDeleteAssetInProgress: PropTypes.func,
  closeModal: PropTypes.func,
  deleteBoard: PropTypes.func,
  deleteRevisions: PropTypes.func,
  openModal: PropTypes.func,
  isCreateBoardModalOpen: PropTypes.bool,
  isRedirectToBoardList: PropTypes.bool,
  fetchBoardUsersDetails: PropTypes.func,
  deleteSpecificUserFromBoard: PropTypes.func,
  updateAccessToBoardMember: PropTypes.func,
  downloadAssets: PropTypes.object,
  auth: PropTypes.object,
  isAdminOrLibrarian: PropTypes.bool,
  updateBoard: PropTypes.func,
  fetchMetadatadetails: PropTypes.func,
  isMetadataDownloadInitiated: PropTypes.bool,
}

const mapStateToProps = (state = {}) => ({
  board: selectBoard()(state),
  assets: selectBoardAssets()(state),
  isFetching: boardsIsFetching()(state),
  isFetchingAssetMetadata: selectIsFetchAssetsInProgress()(state),
  assetMetadataMap: selectBoardsAssetMetadata()(state),
  isToggleRightSidenav: selectIsToggleRightSidenav()(state),
  rightSideNavComponent: selectRightSideNavComponent()(state),
  email: selectUserEmail()(state),
  downloadInProgress: selectDownloadAssetInProgress()(state),
  deleteAssetInProgress: selectDeleteAssetInProgress()(state),
  userId: selectUserId()(state),
  isRedirectToBoardList: selectIsRedirectToBoardList()(state),
  isCreateBoardModalOpen: selectIsCreateBoardModalOpen()(state),
  auth: makeSelectAuthData()(state),
  isAdminOrLibrarian:
    selectIsAdGroupSuperAdmin()(state) || selectIsLibrarian()(state),
  isMetadataDownloadInitiated: selectIsMetadataDownloadInProgress()(state),
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchBoard,
      fetchBoardAssetsMetadata,
      setDownloadAssetInProgress,
      toggleRightSidenav,
      updateDownloadAssets,
      boardRemoveAssets,
      dispatch,
      clearAssetRevisions,
      closeModal,
      deleteBoard,
      deleteRevisions,
      openModal,
      setBoardDownloadAssets,
      setDeleteAssetInProgress,
      fetchBoardUsersDetails,
      deleteSpecificUserFromBoard,
      fetchMetadatadetails,
      updateBoard,
      updateAccessToBoardMember,
    },
    dispatch
  )

const MyComponent = (props) => {
  const classes = styles()
  return <BoardDashboard {...props} classes={classes} />
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MyComponent))
