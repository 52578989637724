import {
  SET_SORT_BY,
  SET_ASSETS,
  SET_IS_FETCHING_ASSETS,
  SET_CURRENT_PAGE,
  SET_PAGE_SIZE,
  SET_TOTAL_RESULTS,
  OPEN_MODAL,
  CLOSE_MODAL,
  SET_FACETS,
  RESET_FILTERS,
  SET_FILTER_VALUE,
  SET_PARTIAL_FILTER_VALUE,
  ON_START_OVER,
  SET_CURRENT_ASSET_ID,
} from './actionTypes'
import { DEFAULT_SORT_BY_OPTION } from '../../constants/publishConstants'

export const initialState = {
  assets: [],
  errors: {},
  isFetchingAssets: false,
  sortBy: DEFAULT_SORT_BY_OPTION,
  currentAssetId: '',
  pageSize: 21,
  currentPage: Number(0),
  totalResults: Number(0),
  isModalOpen: false,
  facets: {},
  filters: {},
  partialFilters: {},
}

export default function publish(state = initialState, action = {}) {
  const { payload = {} } = action
  const { partialFilters = {}, filters = {} } = initialState
  switch (action.type) {
    case SET_SORT_BY:
      return {
        ...state,
        sortBy: payload,
      }
    case SET_CURRENT_ASSET_ID:
      return {
        ...state,
        currentAssetId: payload,
      }
    case SET_ASSETS:
      return {
        ...state,
        assets: payload,
      }
    case SET_IS_FETCHING_ASSETS:
      return {
        ...state,
        isFetchingAssets: payload,
      }
    case SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: payload,
      }
    case SET_PAGE_SIZE:
      return {
        ...state,
        pageSize: payload,
      }
    case SET_TOTAL_RESULTS:
      return {
        ...state,
        totalResults: payload,
      }
    case OPEN_MODAL:
      return {
        ...state,
        isModalOpen: true,
      }
    case CLOSE_MODAL:
      return {
        ...state,
        isModalOpen: false,
      }
    case SET_FACETS:
      return {
        ...state,
        facets: payload,
      }
    case RESET_FILTERS:
      return {
        ...state,
        filters: filters,
        currentPage: Number(0),
      }
    case ON_START_OVER:
      return {
        ...state,
        filters: filters,
        partialFilters: partialFilters,
        currentPage: Number(0),
      }
    case SET_FILTER_VALUE: {
      // Setting default date values as null to avoid issues.
      let { dateFrom = '', dateTo = '' } = payload
      if (dateFrom === null) payload['dateFrom'] = null
      if (dateTo === null) payload['dateTo'] = null

      return {
        ...state,
        currentPage: Number(0),
        filters: {
          ...state.filters,
          ...payload,
        },
      }
    }
    case SET_PARTIAL_FILTER_VALUE:
      return {
        ...state,
        partialFilters: {
          ...state.partialFilters,
          ...payload,
        },
        currentPage: Number(0),
      }
    default:
      return state
  }
}
