import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { times, get, find, isEmpty } from 'lodash'
import { withStyles } from '@mui/styles'
import { MenuItem, Select, IconButton } from '@mui/material'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { SmallBadge, StyledBadge } from './Badges'
import apiConfig from '../../config/apiConfig'
import { assetPropType } from '../../constants/annotation'
import {
  setPrimaryVersionNumber,
  setSecondaryVersionNumber,
  setPrimaryPageNumber,
  setSecondaryPageNumber,
  setSecondaryAssetRevisionChangeHistory,
  setPrimaryAssetRevisionChangeHistory,
} from '../../store/annotation/actions'
import {
  selectJobId,
  selectNumberOfPagesByAssetId,
} from '../../store/annotation/selectors'
import { isAssetMIMETypePDF } from '../../store/annotation/helper'
import { getAssetVersions } from '../../helpers/annotationHelper'
import { appendQueryParams } from '../../helpers/UrlHelper'
import { getThumbnailToPreviewUrl } from '../../helpers/stringHelper'

const KEY = apiConfig.key

const styles = {
  image: {
    width: '250px',
    height: 'auto',
    margin: '0 auto',
  },
  assetName: {
    margin: '5px auto',
    wordBreak: 'break-all',
  },
  assetCard: {
    padding: '10px',
    margin: '0 16px 16px 16px',
    textAlign: 'left',
  },
  selectionBar: {
    display: 'flex',
    height: '40px',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 0,
  },
  pdfPages: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',
    maxHeight: '180px',
    overflowY: 'scroll',
  },
  pdfPage: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: '10px',
  },
  pdfPageImage: {
    height: '50px',
    width: '50px',
    padding: '10px',
  },
}

export class AssetCard extends React.Component {
  state = {
    showPdfPages: false,
    noOfPdfPages: 1,
    noOfVersions: 1,
    currentPage: 1,
    currentVersion: 1,
    currentPrimaryVersionPages: 0,
    currentSecondaryVersionPages: 0,
    isFirstCall: true,
  }

  async componentDidMount() {
    await this.getNumberOfPages()
    await this.getNumberOfVersions()
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      this.state.isFirstCall &&
      this.props.assetUnqIdUrl !== '' &&
      this.props.primaryAsset.asset_master_id === this.props.assetUnqIdUrl &&
      prevProps.primaryVersion.versionNumber ===
        this.props.primaryVersion.versionNumber
    ) {
      if (this.props.asset.asset_master_id === this.props.assetUnqIdUrl) {
        this.setState(
          {
            currentVersion: parseInt(this.props.primaryVersion.versionNumber),
            isFirstCall: false,
            showPdfPages: true,
            currentPrimaryVersionPages: isAssetMIMETypePDF(this.props.asset)
              ? this.props.primaryAssetVersions.find(
                  (x) =>
                    x.asset_version ===
                    parseInt(this.props.primaryVersion.versionNumber)
                ).page_count
              : 1,
          },
          () => {
            const tempPage = this.props.primaryPageNumber - 1
            const idOfEl = this.props.assetUnqIdUrl + '' + tempPage
            if (document.getElementById(idOfEl) !== null) {
              document.getElementById(idOfEl).scrollIntoView()
            }
          }
        )
      }
    } else if (prevState.currentVersion !== this.state.currentVersion) {
      this.getNumberOfPages()
    }
  }

  getNumberOfPages = async () => {
    const {
      asset = {},
      isSecondary = false,
      numberOfPages = undefined,
      secondaryAsset = {},
      primaryAsset = {},
      primaryAssetVersions = [],
      secondaryAssetVersions = [],
    } = this.props
    const { currentPrimaryVersionPages, currentSecondaryVersionPages } =
      this.state
    const { render_urls: { preview_asset_url = '' } = {} } = asset || {}
    if (isAssetMIMETypePDF(asset)) {
      if (numberOfPages !== 0 && !numberOfPages) {
        let renderUrl = preview_asset_url ?? ''
        if (
          primaryAsset.asset_id === asset.asset_id ||
          secondaryAsset.asset_id === asset.asset_id
        ) {
          if (!isSecondary) {
            const primaryAssetVersion = primaryAssetVersions.find(
              (x) => x.asset_version === this.state.currentVersion
            )
            const {
              render_urls: {
                preview_asset_url: primaryPreview_asset_url = '',
              } = {},
            } = primaryAssetVersion || {}
            renderUrl = primaryPreview_asset_url ?? ''
          } else {
            const secondaryAssetVersion = secondaryAssetVersions.find(
              (x) => x.asset_version === this.state.currentVersion
            )
            const {
              render_urls: {
                preview_asset_url: secPreview_asset_url = '',
              } = {},
            } = secondaryAssetVersion || {}
            renderUrl = secPreview_asset_url ?? ''
          }
        }
        const renderUrlWithParams = appendQueryParams(renderUrl, {
          render_pdf: true,
        })
        return axios
          .get(`${renderUrlWithParams}`)
          .then((value) => this.setState({ noOfPdfPages: value }))
      }

      let currentAssetPages = numberOfPages
      if (currentPrimaryVersionPages) {
        currentAssetPages = currentPrimaryVersionPages
      }

      if (currentSecondaryVersionPages) {
        currentAssetPages = currentSecondaryVersionPages
      }
      return this.setState({ noOfPdfPages: currentAssetPages })
    }
    return null
  }

  getNumberOfVersions = async () => {
    const {
      asset = {},
      primaryVersion = 1,
      secondaryVersion = 1,
      primaryAsset = {},
      secondaryAsset = {},
    } = this.props
    const assetVersionNumbers = getAssetVersions(asset)
    const assetId = asset.asset_id
    const isPrimaryAsset = primaryAsset.asset_id === assetId
    const isSecondaryAsset = secondaryAsset.asset_id === assetId
    if (isPrimaryAsset) {
      this.setState({
        noOfVersions: assetVersionNumbers,
        currentVersion: primaryVersion.versionNumber,
      })
    } else if (isSecondaryAsset) {
      this.setState({
        noOfVersions: assetVersionNumbers,
        currentVersion: secondaryVersion.versionNumber,
      })
    } else {
      this.setState({
        noOfVersions: assetVersionNumbers,
        currentVersion: Math.max(...assetVersionNumbers),
      })
    }
  }

  handleRevisionNumberChange = async (e) => {
    const version = e.target.value
    const {
      setPrimaryVersionNumber = () => {},
      setSecondaryVersionNumber = () => {},
      isSecondary = false,
      setPrimaryAssetRevisionChangeHistory = () => {},
      setSecondaryAssetRevisionChangeHistory = () => {},
      asset = {},
      primaryAsset = {},
      secondaryAsset = {},
      primaryAssetVersions = [],
      secondaryAssetVersions = [],
      removeAssetUnqIdUrl = () => {},
      setRevisionNumberInurl = () => {},
    } = this.props
    // get revision thumbnail url
    let currentAsset
    if (isSecondary) {
      currentAsset = find(secondaryAssetVersions, (asset) => {
        if (
          asset.asset_version === version &&
          asset.asset_master_id === secondaryAsset.asset_master_id
        ) {
          return asset
        }
      })
      this.setState({ currentSecondaryVersionPages: currentAsset.page_count })
    } else {
      setRevisionNumberInurl(version)
      removeAssetUnqIdUrl()
      currentAsset = find(primaryAssetVersions, (asset) => {
        if (
          asset.asset_version === version &&
          asset.asset_master_id === primaryAsset.asset_master_id
        ) {
          return asset
        }
      })
      this.setState({
        currentPrimaryVersionPages: currentAsset && currentAsset.page_count,
      })
    }

    const thumbnailUrl = currentAsset ? currentAsset.asset_thumbnail_path : ''
    if (isSecondary && secondaryAsset.asset_id === asset.asset_id) {
      setSecondaryVersionNumber(version, true)
    }
    if (!isSecondary && primaryAsset.asset_id === asset.asset_id) {
      setPrimaryVersionNumber(version, true)
    }
    this.setState({ currentVersion: version })
    isSecondary
      ? setSecondaryAssetRevisionChangeHistory(
          asset.asset_master_id,
          version,
          thumbnailUrl
        )
      : setPrimaryAssetRevisionChangeHistory(
          asset.asset_master_id,
          version,
          thumbnailUrl
        )
  }

  handleChangePageNumber = (newPageNumber = 1) => {
    const {
      setPrimaryPageNumber = () => {},
      setSecondaryPageNumber = () => {},
      isSecondary = false,
    } = this.props
    if (isSecondary) {
      setSecondaryPageNumber(newPageNumber)
    } else {
      setPrimaryPageNumber(newPageNumber)
      this.props.removeAssetUnqIdUrl()
      this.props.setPageNumberInUrl(newPageNumber, this.state.currentVersion)
    }
    this.setState({ currentPage: newPageNumber })
  }

  setAssetAndPage = (newPageNumber = 1) => {
    const { asset, setCurrentAsset } = this.props
    const { asset_version = 1 } = asset
    setCurrentAsset(asset, asset_version, newPageNumber)
    this.handleChangePageNumber(newPageNumber)
  }

  render() {
    const {
      asset = {},
      setCurrentAsset = () => {},
      primaryAsset = {},
      secondaryAsset = {},
      isSecondary = false,
      classes = {},
      primaryPageNumber = 1,
      secondaryPageNumber = 1,
      primaryAssetVersions = [],
      secondaryAssetVersions = [],
      forwaredRef = '',
      allCommentInfo = {},
      primaryAssetRevisionHistory = {},
      secondaryAssetRevisionHistory = {},
    } = this.props
    const {
      showPdfPages,
      noOfPdfPages,
      noOfVersions,
      currentPage,
      currentVersion,
    } = this.state
    let pdfPages = noOfPdfPages
    let assetId = asset.asset_id
    const assetMasterId =
      this.props.assetUnqIdUrl && this.props.assetUnqIdUrl !== ''
        ? this.props.assetUnqIdUrl
        : asset.asset_master_id
    let isCurrentAsset, pageNumber
    if (!isSecondary) {
      isCurrentAsset = primaryAsset.asset_id === assetId
      pageNumber = primaryPageNumber
      if (currentVersion != asset.asset_version) {
        const primaryAssetVersion = primaryAssetVersions.find(
          (x) => x.asset_version === currentVersion
        )
        if (!isEmpty(primaryAssetVersions) && primaryAssetVersion) {
          pdfPages = primaryAssetVersion.page_count
        }
      }
    } else {
      isCurrentAsset = secondaryAsset.asset_id === assetId
      pageNumber = secondaryPageNumber
      if (currentVersion != asset.asset_version) {
        if (isEmpty(secondaryAssetVersions)) {
          const secondaryAssetVersion = primaryAssetVersions.find(
            (x) => x.asset_version === currentVersion
          )
          if (!isEmpty(primaryAssetVersions) && secondaryAssetVersion) {
            pdfPages = secondaryAssetVersion.page_count
          }
        }
      }
    }
    const {
      render_urls: { preview_asset_url = '', thumbnail_asset_url = '' } = {},
    } = asset || {}
    let renderUrl = preview_asset_url ?? ''
    let thumbNailPath = thumbnail_asset_url ?? ''
    let totalNumberOfComments = get(
      allCommentInfo,
      `[${assetId}].totalNumberOfComments`,
      0
    )
    let pageComments = get(allCommentInfo, `[${assetId}].pageComments`, {})
    if (isCurrentAsset) {
      if (!isSecondary) {
        const primaryAssetVersion =
          primaryAssetVersions.find(
            (x) => x.asset_version === this.state.currentVersion
          ) || {}
        const {
          render_urls: {
            preview_asset_url: primaryPreview_asset_url = '',
            thumbnail_asset_url: primaryThumbnail_asset_url = '',
          } = {},
        } = primaryAssetVersion || {}
        renderUrl = primaryPreview_asset_url ?? ''
        totalNumberOfComments = get(
          allCommentInfo,
          `[${primaryAssetVersion.asset_id}].totalNumberOfComments`,
          0
        )
        pageComments = get(
          allCommentInfo,
          `[${primaryAssetVersion.asset_id}].pageComments`,
          {}
        )
        thumbNailPath = primaryThumbnail_asset_url ?? ''
      } else {
        const secondaryAssetVersion =
          secondaryAssetVersions.find(
            (x) => x.asset_version === this.state.currentVersion
          ) || {}
        const {
          render_urls: {
            preview_asset_url: secPreview_asset_url = '',
            thumbnail_asset_url: secThumbnail_asset_url = '',
          } = {},
        } = secondaryAssetVersion || {}
        renderUrl = secPreview_asset_url ?? ''
        totalNumberOfComments = get(
          allCommentInfo,
          `[${secondaryAssetVersion.asset_id}].totalNumberOfComments`,
          0
        )
        pageComments = get(
          allCommentInfo,
          `[${secondaryAssetVersion.asset_id}].pageComments`,
          {}
        )
        thumbNailPath = secThumbnail_asset_url ?? ''
      }
    } else {
      // set correct thumbnail image for non selected assets
      if (isSecondary && secondaryAssetRevisionHistory[assetMasterId]) {
        if (
          secondaryAssetRevisionHistory[assetMasterId].assetMasterId ===
          assetMasterId
        ) {
          const currentSelectedRevision =
            secondaryAssetRevisionHistory[assetMasterId]
          const {
            render_urls: {
              preview_asset_url: curPreview_asset_url = '',
              thumbnail_asset_url: curThumbnail_asset_url = '',
            } = {},
          } = currentSelectedRevision || {}
          thumbNailPath = curThumbnail_asset_url ?? ''
        }
      } else if (primaryAssetRevisionHistory[assetMasterId]) {
        if (
          primaryAssetRevisionHistory[assetMasterId].assetMasterId ===
          assetMasterId
        ) {
          const currentSelectedRevision =
            primaryAssetRevisionHistory[assetMasterId]
          const {
            render_urls: {
              preview_asset_url: curPreview_asset_url = '',
              thumbnail_asset_url: curThumbnail_asset_url = '',
            } = {},
          } = currentSelectedRevision || {}
          thumbNailPath = curThumbnail_asset_url ?? ''
        }
      }
    }
    const newThumbnailPath = getThumbnailToPreviewUrl(thumbNailPath)
    return (
      <div
        data-cy={isCurrentAsset ? 'currentAsset' : ''}
        className={classes.assetCard}
        style={{ backgroundColor: isCurrentAsset ? '#DDDDDD' : '#EFEFEF' }}
        ref={forwaredRef}
      >
        <div
          data-cy="asset"
          style={{ textAlign: 'center', cursor: 'pointer' }}
          onClick={() => {
            setCurrentAsset(asset, currentVersion, currentPage)
          }}
        >
          <h4 className={classes.assetName}>{asset.asset_name}</h4>
          <StyledBadge badgeContent={totalNumberOfComments}>
            <img
              className={classes.image}
              src={
                noOfPdfPages > 1 || pdfPages > 1
                  ? `${renderUrl}&key=${KEY}&render_pdf=true&asset_page=${
                      isCurrentAsset ? pageNumber : 1
                    }`
                  : `${newThumbnailPath}&key=${KEY}`
              }
            />
          </StyledBadge>
        </div>
        <span className={classes.selectionBar}>
          {!isCurrentAsset ? (
            <span>Revision: {currentVersion}</span>
          ) : (
            <div>
              {noOfVersions.length > 1 ? (
                <Select
                  variant="standard"
                  style={{ marginLeft: '10px' }}
                  value={currentVersion}
                  onChange={this.handleRevisionNumberChange}
                  inputProps={{
                    name: 'revision-number',
                    id: 'revision-number',
                  }}
                >
                  {noOfVersions.map((ele) => (
                    <MenuItem key={ele} value={ele}>
                      Revision: {ele}
                    </MenuItem>
                  ))}
                </Select>
              ) : (
                <span>Revision: 1</span>
              )}
            </div>
          )}
          {pdfPages > 1 && (
            <span>
              {isCurrentAsset ? primaryPageNumber : currentPage} of {pdfPages}
              {showPdfPages ? (
                <IconButton
                  onClick={() => this.setState({ showPdfPages: false })}
                  size="large"
                >
                  <KeyboardArrowUp />
                </IconButton>
              ) : (
                <IconButton
                  onClick={() => this.setState({ showPdfPages: true })}
                  size="large"
                >
                  <KeyboardArrowDown />
                </IconButton>
              )}
            </span>
          )}
        </span>
        {pdfPages > 1 && showPdfPages && (
          <div className={classes.pdfPages}>
            {times(pdfPages, (idx) => {
              const comments = pageComments[idx + 1] || 0
              return (
                <SmallBadge
                  id={assetMasterId + idx}
                  key={idx + 1}
                  badgeContent={comments}
                >
                  <div
                    onClick={() =>
                      isCurrentAsset
                        ? this.handleChangePageNumber(idx + 1)
                        : this.setAssetAndPage(idx + 1)
                    }
                    className={classes.pdfPage}
                  >
                    <img
                      style={{
                        backgroundColor:
                          pageNumber === idx + 1 && isCurrentAsset
                            ? 'grey'
                            : 'white',
                      }}
                      className={classes.pdfPageImage}
                      src={`${renderUrl}&key=${KEY}&render_pdf=true&asset_page=${
                        idx + 1
                      }`}
                    />
                    <p style={{ margin: 0 }}>{idx + 1}</p>
                  </div>
                </SmallBadge>
              )
            })}
          </div>
        )}
      </div>
    )
  }
}

AssetCard.propTypes = {
  setCurrentAsset: PropTypes.func,
  classes: PropTypes.object,
  idx: PropTypes.number,
  asset: assetPropType,
  primaryAsset: assetPropType,
  secondaryAsset: assetPropType,
  isSecondary: PropTypes.bool,
  setPrimaryVersionNumber: PropTypes.func,
  setSecondaryVersionNumber: PropTypes.func,
  setPrimaryPageNumber: PropTypes.func,
  setSecondaryPageNumber: PropTypes.func,
  primaryPageNumber: PropTypes.number,
  secondaryPageNumber: PropTypes.number,
  secondaryAssetVersions: PropTypes.arrayOf(assetPropType),
  primaryAssetVersions: PropTypes.arrayOf(assetPropType),
  jobId: PropTypes.string,
  forwaredRef: PropTypes.any,
  allCommentInfo: PropTypes.object,
  numberOfPages: PropTypes.number,
  primaryVersion: PropTypes.object,
  secondaryVersion: PropTypes.object,
  setSecondaryAssetRevisionChangeHistory: PropTypes.func,
  setPrimaryAssetRevisionChangeHistory: PropTypes.func,
  primaryAssetRevisionHistory: PropTypes.object,
  secondaryAssetRevisionHistory: PropTypes.object,
  assetUnqIdUrl: PropTypes.string,
  removeAssetUnqIdUrl: PropTypes.func,
  setRevisionNumberInurl: PropTypes.func,
  setPageNumberInUrl: PropTypes.func,
}

const mapStateToProps = (state, ownProps) => {
  const { annotation } = state
  const {
    primaryPageNumber,
    secondaryPageNumber,
    primaryAssetVersions,
    primaryVersion,
    secondaryAssetVersions,
    secondaryVersion,
    allCommentInfo,
    primaryAssetRevisionHistory,
    secondaryAssetRevisionHistory,
  } = annotation

  const jobId = selectJobId(state)
  const assetId = get(ownProps, 'asset.asset_id', '')
  const numberOfPages = selectNumberOfPagesByAssetId(assetId)(state)

  return {
    primaryPageNumber,
    secondaryPageNumber,
    primaryAssetVersions,
    secondaryAssetVersions,
    jobId,
    numberOfPages,
    allCommentInfo,
    primaryVersion,
    secondaryVersion,
    primaryAssetRevisionHistory,
    secondaryAssetRevisionHistory,
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setPrimaryVersionNumber,
      setSecondaryVersionNumber,
      setPrimaryPageNumber,
      setSecondaryPageNumber,
      setSecondaryAssetRevisionChangeHistory,
      setPrimaryAssetRevisionChangeHistory,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AssetCard))
