import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { connect } from 'react-redux'
import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import CircularProgress from '@mui/material/CircularProgress'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import Slide from '@mui/material/Slide'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import * as moment from 'moment-timezone'
import apiConfig from '../../config/apiConfig'
import { showNotification } from '../../store/notification/actionCreator'

function Transition(props) {
  return <Slide direction="up" {...props} />
}

const styles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    boxShadow: 'none',
    paddingRight: 10,
    paddingLeft: 15,
  },
  flex: {
    flex: 1,
  },
  iframeContainer: {
    height: 'calc(100% - 72px)',
  },
  hide: {
    display: 'none !important',
  },
  group: {
    margin: `${useTheme().spacing()}px 0`,
  },
  radioButton: {
    alignItems: 'flex-start',
  },
  radioHeading: {
    marginTop: 14,
    marginBottom: 4,
  },
  confirmHeader: {
    position: 'relative',
    boxShadow: 'none',
    backgroundColor: '#188295',
    paddingRight: 10,
    paddingLeft: 15,
  },
  confirmContent: {
    paddingBottom: 0,
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: 'calc(100% - 72px)',
    width: '100%',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    margin: useTheme().spacing(),
    position: 'relative',
  },
}))

class TaskReview extends React.Component {
  constructor(props) {
    super(props)
    let statusCode = 1002
    if (props.selectedTask.status === 'In Progress') {
      statusCode = 1002
    } else if (props.selectedTask.status === 'Completed') {
      statusCode = 1003
    }
    this.state = {
      iframeLoaded: false,
      openConfirm: false,
      value: statusCode,
      saving: false,
    }
    this.handleClose = this.handleClose.bind(this)
    this.closeConfirm = this.closeConfirm.bind(this)
    this.save = this.save.bind(this)
  }

  handleChange = (event) => {
    this.setState({ value: parseInt(event.target.value) })
  }

  handleClose() {
    const {
      selectedTask: { role_name = '' },
    } = this.props
    if (this.state.iframeLoaded && role_name !== 'Observer') {
      this.setState({ openConfirm: true })
    } else {
      this.props.close()
    }
  }

  closeConfirm() {
    // this.props.close()
    this.setState({ openConfirm: false })
  }

  save() {
    let $this = this
    let data = {
      task_id: this.props.selectedTask.task_id,
      task_status_id: this.state.value,
    }
    $this.setState({ saving: true })
    axios
      .put(
        `${apiConfig.assethub.serviceHostMarket}/task_status?key=${apiConfig.key}`,
        data
      )
      .then(() => {
        $this.setState({ saving: false })
        this.props.close(true)
      })
      .catch(() => {
        $this.setState({ saving: false })
      })
  }

  componentDidMount() {
    let $this = this
    window.addEventListener('message', function (e) {
      if (e.data === 'Loaded') {
        $this.setState({ iframeLoaded: true })
      } else {
        $this.handleClose()
      }
    })
    const { embeddedExperienceTimeout = 20000 } = apiConfig
    const { selectedTask } = this.props
    setTimeout(() => {
      if (!$this.state.iframeLoaded) {
        const errorMsg =
          'There was a problem opening the review.<br /><a href="' +
          selectedTask.review_url +
          '" style="color:#fff" onclick="window.open(\'' +
          selectedTask.review_url +
          "', '_blank'); return false;\">Click here</a> to open the review in another window."
        $this.props.showNotification(true, errorMsg, 'error')
        $this.props.close()
      }
    }, embeddedExperienceTimeout)
  }

  render() {
    const { classes, open, selectedTask, projectName } = this.props
    const { iframeLoaded, openConfirm, saving } = this.state
    const taskDueDate = moment
      .tz(selectedTask.due_date, 'America/chicago')
      .format('M/DD/YY')
    const taskDueTime = moment
      .tz(selectedTask.due_date, 'America/chicago')
      .format('h:mm A')
    const label1 = (
      <div>
        <h3 className={classes.radioHeading}>In Progress</h3>
        <div>
          Indicate you likely have more changes to make.
          <br />
          You can continue to make changes until <br />
          {taskDueDate} on {taskDueTime}
        </div>
      </div>
    )
    const label2 = (
      <div>
        <h3 className={classes.radioHeading}>Completed</h3>
        <div>
          Indicate you are likely done making changes.
          <br />
          However, you <strong>can make changes</strong> until <br />
          {taskDueDate} on {taskDueTime}
        </div>
      </div>
    )

    return (
      <div>
        <Dialog
          fullScreen
          open={open}
          onClose={this.handleClose}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar disableGutters>
              <Typography variant="h6" color="inherit" className={classes.flex}>
                {projectName}
              </Typography>
              <IconButton
                color="inherit"
                onClick={this.handleClose}
                aria-label="Close"
                size="large"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <div
            className={
              classes.iframeContainer +
              ' ' +
              (!iframeLoaded ? classes.hide : '')
            }
          >
            <iframe
              id="opentextIframe"
              src={selectedTask.review_url + '&isIframeRequired=yes'}
              width="100%"
              height="100%"
            ></iframe>
          </div>
          <div
            className={
              classes.loaderContainer + ' ' + (iframeLoaded ? classes.hide : '')
            }
          >
            <CircularProgress />
            <p>Opening review...</p>
          </div>
        </Dialog>
        <Dialog
          open={openConfirm}
          disableEscapeKeyDown
          maxWidth="400"
          aria-labelledby="confirmation-dialog-title"
        >
          <AppBar className={classes.confirmHeader}>
            <Toolbar disableGutters>
              <Typography variant="h6" color="inherit" className={classes.flex}>
                Set Review Status
              </Typography>
              <IconButton
                color="inherit"
                onClick={this.closeConfirm}
                aria-label="Close"
                size="large"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <DialogContent className={classes.confirmContent}>
            <FormControl
              variant="standard"
              component="fieldset"
              required
              className={classes.formControl}
            >
              <RadioGroup
                aria-label="Gender"
                name="gender1"
                className={classes.group}
                value={this.state.value}
                onChange={this.handleChange}
              >
                <FormControlLabel
                  value={1002}
                  control={<Radio />}
                  label={label1}
                  className={classes.radioButton}
                />
                <FormControlLabel
                  value={1003}
                  control={<Radio />}
                  label={label2}
                  className={classes.radioButton}
                />
              </RadioGroup>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <div className={classes.wrapper}>
              <Button
                onClick={this.save}
                color="secondary"
                variant="contained"
                disabled={saving}
              >
                Save & Close
              </Button>
              {saving && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

TaskReview.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedTask: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  showNotification: PropTypes.func,
  projectName: PropTypes.string,
}

const mapDispatchToProps = {
  showNotification,
}

const MyComponent = (props) => {
  const classes = styles()
  return <TaskReview {...props} classes={classes} />
}

export default connect(null, mapDispatchToProps)(MyComponent)
