import { useState } from 'react'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import BulkAssetReviewForm from './BulkAssetReviewForm'
import ImageCarousel from '../../components/ImagePreview/ImageCarousel'
import { getSequenceNumFromAssetTestId } from '../helpers/genaiTestHelper'

const useStyles = makeStyles((theme) => ({
  largeViewAppBar: {
    position: 'relative',
    backgroundColor: 'black',
  },
  largeViewContainerClass: {
    display: 'flex',
  },
  largeViewImgContainerClass: {
    width: '50vw',
  },
  largeViewReviewFieldClass: {
    width: '30%',
    display: 'flex',
    flexDirection: 'column',
  },
  showNumber: {
    justifyContent: 'center',
    display: 'flex',
  },
  lightBoxWrapper: {
    height: '95vh',
    width: `70%`,
    backgroundColor: '#484848',
    overflow: 'scroll',
    boxShadow: useTheme().shadows[5],
    padding: 0,
    textAlign: 'left',
    position: 'relative',
    transition: useTheme().transitions.create(['margin', 'width'], {
      easing: useTheme().transitions.easing.sharp,
      duration: useTheme().transitions.duration.leavingScreen,
    }),
    display: 'block',
    marginLeft: 0,
  },
}))

function BulkReviewLargeView({
  setViewLargeMode = () => {},
  bulkTestReviewData = {},
  filteredAssetList = [],
}) {
  const classes = useStyles()
  const [selectedAssetIndex, setSelectedAssetIndex] = useState(0)
  const { test_name = '' } = bulkTestReviewData

  const onClickNextAsset = () => {
    if (selectedAssetIndex < filteredAssetList.length - 1) {
      setSelectedAssetIndex(selectedAssetIndex + 1)
    }
  }

  const onClickPrevAsset = () => {
    if (selectedAssetIndex > 0) {
      setSelectedAssetIndex(selectedAssetIndex - 1)
    }
  }

  const onReviewSuccessInLargeView = () => {
    if (selectedAssetIndex < filteredAssetList.length - 1) {
      setSelectedAssetIndex(selectedAssetIndex + 1)
    } else {
      setViewLargeMode(false)
    }
  }

  const selectedAssetDetails = filteredAssetList?.[selectedAssetIndex] || {}
  const { asset_test_id = '', asset_url = '' } = selectedAssetDetails

  return (
    <Grid>
      <AppBar className={classes.largeViewAppBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => setViewLargeMode(false)}
            aria-label="close"
          >
            <ArrowBackIcon />
          </IconButton>
          <div>
            Back to Test - {test_name} {' | '} Asset No {' : '}{' '}
            {getSequenceNumFromAssetTestId(asset_test_id)}
          </div>
        </Toolbar>
      </AppBar>
      <Grid container className={classes.largeViewContainerClass}>
        <Grid container className={classes.lightBoxWrapper}>
          <Grid item>
            <ImageCarousel
              imageUrl={asset_url}
              NextAsset={onClickNextAsset}
              PrevAsset={onClickPrevAsset}
              isSidebarOpen={true}
              sidebarWidth={520}
              isArrowShow={filteredAssetList.length ? true : false}
            />
          </Grid>
        </Grid>
        <Grid item container className={classes.largeViewReviewFieldClass}>
          <BulkAssetReviewForm
            isBulkViewMode={true}
            bulkTestReviewData={bulkTestReviewData}
            selectedAssetDetails={selectedAssetDetails}
            selectedAssets={[asset_test_id]}
            onReviewSuccessCallback={onReviewSuccessInLargeView}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default BulkReviewLargeView
