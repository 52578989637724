import {
  SET_METADATA_HEADERS,
  SET_CLASSIFICATION_DATA,
  SET_CLASSIFICATION_HIERARCHY,
  SET_CLASSIFICATION_SELECTION,
  RESET_ASSETTYPE_SELECTION,
} from './actionTypes'

export const classificationInitialState = {
  metadataTypes: [],
  classificationData: [],
  classificationTypes: [],
  selectedClassification: '',
  selectedClassificationId: '',
  selectedAssetName: '',
  selectedDescription: '',
  selectedFolderPath: '',
}

export default function assetClassificationReducer(
  state = classificationInitialState,
  action = {}
) {
  const { payload } = action
  switch (action.type) {
    case SET_METADATA_HEADERS:
      return {
        ...state,
        metadataTypes: payload,
      }
    case SET_CLASSIFICATION_DATA:
      return {
        ...state,
        classificationData: payload,
      }
    case SET_CLASSIFICATION_HIERARCHY:
      return {
        ...state,
        classificationTypes: payload,
      }
    case SET_CLASSIFICATION_SELECTION: {
      const {
        selectedClassification = '',
        selectedAssetName = '',
        selectedClassificationId = '',
        selectedDescription = '',
        selectedFolderPath = '',
      } = payload
      return {
        ...state,
        selectedClassification,
        selectedAssetName,
        selectedClassificationId,
        selectedDescription,
        selectedFolderPath,
      }
    }
    case RESET_ASSETTYPE_SELECTION:
      return {
        ...state,
        selectedClassificationId: '',
        selectedClassification: '',
        selectedAssetName: '',
        selectedFolderPath: '',
        selectedDescription: '',
      }
    default:
      return state
  }
}
