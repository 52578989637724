import * as React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Fab from '@mui/material/Fab'
import Tooltip from '@mui/material/Tooltip'
import CircularProgress from '@mui/material/CircularProgress'
import AddIcon from '@mui/icons-material/Add'
import GroupIcon from '@mui/icons-material/Group'
import { withEnv } from '@praxis/component-runtime-env'
import withRouter from '../../containers/Router/WithRouter'
import HeaderTitle from '../Header/HeaderTitle'
import GroupsList from './GroupsList'
import Filters from './Filters'
import AddGroupForm from './AddGroupForm'
import {
  fetchGroups,
  openModal,
  closeModal,
} from './../../store/groups/actionCreator'
import { selectMemberOf } from '../../store/auth/selector'
import { isAuthorizedToPage } from '../../helpers/UtilityHelper'

const styles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gap: useTheme().spacing(3),
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  button: {
    margin: useTheme().spacing(),
    float: 'right',
    backgroundColor: '#188295',
    '&:hover': {
      backgroundColor: '#002171',
    },
    [useTheme().breakpoints.only('xs')]: {
      top: 'auto',
      right: 20,
      bottom: 20,
      left: 'auto',
      position: 'fixed',
      zIndex: 1,
    },
  },
  root: {
    flexGrow: 1,
  },
  resultsText: {
    color: '#c0c0c0',
    margin: 0,
    padding: '0 !important',
  },
  tabContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10,
    fontSize: 12,
  },
  activeTabButton: {
    backgroundColor: '#cb0000',
    color: '#fff !important',
    '&:hover': {
      backgroundColor: 'rgba(204,0,0,.8)!important',
    },
  },
  tabButton: {
    width: 182,
    fontWeight: 400,
    color: '#c00',
    borderColor: '#c00',
    '&:hover': {
      backgroundColor: 'rgba(239,166,166,.2)',
    },
  },
  leftIcon: {
    marginRight: useTheme().spacing(),
  },
  boxDiv: {
    marginTop: 20,
    minHeight: 200,
    padding: useTheme().spacing(2),
    textAlign: 'center',
    color: useTheme().palette.text.secondary,
    boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.2)',
  },
}))

class GroupsPage extends React.Component {
  state = {
    loading: true,
    activeTabIndex: 0,
  }

  constructor(props) {
    super(props)
    this.openBoardsFrom = this.openBoardsFrom.bind(this)
    this.closeBoardsFrom = this.closeBoardsFrom.bind(this)
  }

  componentDidMount() {
    const { location = {} } = this.props
    const { state = {} } = location
    const { referrer = '' } = this.state
    if (referrer !== '/group') {
      this.props.fetchGroups()
      setTimeout(() => this.setState({ loading: false }), 1500)
    }
  }

  openBoardsFrom() {
    this.props.openModal('groupsForm')
  }

  closeBoardsFrom() {
    this.props.closeModal('groupsForm')
  }

  render() {
    const {
      classes,
      headerTitle,
      history,
      groups,
      memberOf = [],
      env = {},
      router = {},
    } = this.props
    const { REACT_APP_ROLES_CONFIG = {} } = env
    const isAuthorizedUser = isAuthorizedToPage(
      'groups',
      memberOf,
      REACT_APP_ROLES_CONFIG
    )
    const { activeTabIndex } = this.state
    return (
      <div className={classes.projectsPage}>
        <HeaderTitle title="Groups" icon="Group" />
        <Helmet>
          <title>{headerTitle}</title>
        </Helmet>
        <div className={classes.root}>
          <Grid container justifyContent="center" spacing={1}>
            <Grid item xs={false} sm={2}>
              {}
            </Grid>
            <Grid item xs={12} sm={8}>
              {/* <Tabs value={activeTabIndex} onChange={this.handleTabChange} centered> */}
              {/* <Tab label="My Personal Groups" icon={<LanguageIcon />} /> */}
              {/* <Tab label="Shared Group" icon={<GroupIcon />} /> */}
              {/* </Tabs> */}
            </Grid>
            <Grid item xs={2}>
              {isAuthorizedUser && (
                <Tooltip id="tooltip-icon" title="Create Group">
                  <Fab
                    onClick={() => this.openBoardsFrom()}
                    data-cy="createGroupButton"
                    color="primary"
                    aria-label="add"
                    className={classes.button}
                  >
                    <div className="icon-default">
                      <AddIcon />
                    </div>
                    <div className="icon-hover">
                      <GroupIcon />
                    </div>
                  </Fab>
                </Tooltip>
              )}
            </Grid>
            <Grid container justifyContent="center" spacing={1}>
              {groups.groupCount >= 1 && (
                <Grid item className={classes.resultsText}>
                  Create a new group to manage access to projects, reviews, and
                  boards for multiple users
                </Grid>
              )}
            </Grid>
          </Grid>
          {activeTabIndex === 0 && (
            <div>
              <Grid container justifyContent="center" spacing={1}>
                <Grid item xs={2} />
                <Grid item xs={8}>
                  <Filters tabActive={activeTabIndex} />
                </Grid>
                <Grid item xs={2} />
              </Grid>
              {groups.groupsLoading ? (
                <CircularProgress
                  size={50}
                  className={classes.buttonProgress}
                />
              ) : groups.apiError ? (
                <Grid container spacing={3}>
                  <Grid item xs={12} className={classes.boxDiv}>
                    <p>
                      An error occurred while retrieving group data. Please
                      contact support if this issue persists.
                    </p>
                  </Grid>
                </Grid>
              ) : groups.groupCount >= 1 ? (
                <GroupsList
                  activeTabIndex={activeTabIndex}
                  history={history}
                  tabActive={activeTabIndex === 0}
                  router={router}
                />
              ) : (
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={3}
                >
                  <Grid item xs={12}>
                    <p className={classes.boxDiv}>sorry, no results found.</p>
                  </Grid>
                </Grid>
              )}
            </div>
          )}
          {activeTabIndex === 1 && (
            <div>
              <Grid container justifyContent="center" spacing={1}>
                <Grid item xs={2} />
                <Grid item xs={8}>
                  <Filters tabActive={activeTabIndex} />
                </Grid>
                <Grid item xs={2} />
              </Grid>
              {groups.shareListLoading ? (
                <CircularProgress
                  size={50}
                  className={classes.buttonProgress}
                />
              ) : groups.apiError ? (
                <Grid container spacing={3}>
                  <Grid item xs={12} className={classes.boxDiv}>
                    <p>
                      An error occurred while retrieving group data. Please
                      contact support if this issue persists.
                    </p>
                  </Grid>
                </Grid>
              ) : groups.groupShareCount >= 1 ? (
                <GroupsList
                  activeTabIndex={activeTabIndex}
                  history={history}
                  tabActive={activeTabIndex === 1}
                  router={router}
                />
              ) : (
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <p className={classes.boxDiv}>
                      There are no group collaborators created for this group.
                    </p>
                  </Grid>
                </Grid>
              )}
            </div>
          )}
          {this.props.groups.modal.groupsForm && (
            <AddGroupForm
              open={this.props.groups.modal.groupsForm}
              onClose={this.closeBoardsFrom}
            />
          )}
        </div>
      </div>
    )
  }
}

GroupsPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  classes: PropTypes.object.isRequired,
  groups: PropTypes.object.isRequired,
  fetchGroups: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  headerTitle: PropTypes.string,
  auth: PropTypes.object,
  location: PropTypes.object,
}

const mapStateToProps = (state) => {
  const { layout } = state
  const { headerTitle } = layout
  const { groups } = state
  const lanId = state.auth.lanId
  const auth = state.auth
  return {
    groups: groups,
    headerTitle: headerTitle,
    lanId: lanId,
    auth: auth,
    memberOf: selectMemberOf()(state),
  }
}

const MyComponent = (props) => {
  const classes = styles()
  return <GroupsPage {...props} classes={classes} />
}

export default connect(mapStateToProps, {
  fetchGroups,
  openModal,
  closeModal,
})(withEnv()(withRouter(MyComponent)))
