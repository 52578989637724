import React, { useEffect, useState } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { isEmpty } from 'lodash'
import HeaderTitle from '../Header/HeaderTitle'
import { getDeleteAssetNotificationMessage } from '../../helpers/MetadataHelper'
// import { useStore } from 'ahsearch/ahSearchStore'
import { toggleRightSidenav } from '../../store/layout/actionCreator'
import { fetchMetadatadetails } from '../../store/search/actions'
import { SearchUI } from '../MicroFrontends'
import SearchPage from '../../betasearch/pages/Search/SearchPage'
import BulkActionBar from '../Search/BulkActionBar'
import DeleteAssetsDailog from '../Search/DeleteAssetsDailog'
import { deleteRevisions } from '../../store/metadata/actionCreator'
import { startDownloadFile } from '../../helpers/bulkDownloadHelper'
import {
  BETA_ANALYTICS_DELIMITER,
  BetaAnalytics,
  trackBetaSearchAnalytics,
} from '../../analytics/betaSearch'

export const NewSearch = (props = {}) => {
  const dispatch = useDispatch()
  const [downloadAssetType, setDownloadAssetType] = useState('')
  const [dispDelAssetConfModal, setDispDelAssetConfModal] = useState(false)
  const [isDeleteInitiated, setIsDeleteInitiated] = useState(false)
  const [isDownloadInitiated, setIsDownloadInitiated] = useState(false)
  const {
    isToggleRightSidenav,
    rightSideNavComponent,
    selectedItems,
    isMetadataDownloadInitiated,
    isAdGroupSuperAdmin,
    isLibrarian,
    isAdminOrLibrarian,
    downloadInProgress,
    userId,
    email,
    page,
  } = useSelector(
    ({
      layout: { isToggleRightSidenav, rightSideNavComponent },
      newSearch: { selectedItems = [], page = Number(0) } = {},
      assetsSearch: { isMetadataDownloadInitiated, downloadInProgress },
      auth: { isAdGroupSuperAdmin, isLibrarian, lanId: userId, email } = {},
    }) => ({
      isToggleRightSidenav,
      rightSideNavComponent,
      selectedItems,
      isMetadataDownloadInitiated,
      isAdGroupSuperAdmin,
      isLibrarian,
      isAdminOrLibrarian: isAdGroupSuperAdmin || isLibrarian,
      downloadInProgress,
      userId,
      email,
      page,
    }),
    shallowEqual,
  )

  useEffect(() => {
    if (downloadAssetType === 'DOWNLOAD METADATA') {
      let selected_file_ids = Object.values(selectedItems).map(
        (asset) => asset.assetId,
      )
      dispatch(fetchMetadatadetails(selected_file_ids, bulkActionSuccessCB))
      trackBetaSearchAnalytics({
        auth: { lanId: userId, email },
        trackingData: {
          eventName: BetaAnalytics.event.DOWNLOAD_METADATA,
          eventType: BetaAnalytics.event.ASSET_USAGE,
          eventData: {
            key: 'asset_ids',
            value: selected_file_ids.join(`${BETA_ANALYTICS_DELIMITER}`),
          },
        },
      })
    }
    return function cleanup() {
      setDownloadAssetType('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadAssetType])

  const bulkActionSuccessCB = () => {
    dispatch({
      type: 'search/saveSelectedItems',
      payload: { selectedItems: [] },
    })
  }

  const dispatchToggleSidenav = (sideNavComponent, contentChanged) => {
    dispatch(toggleRightSidenav(sideNavComponent, contentChanged))
  }

  const downloadMetadata = (e) => {
    const downloadAssetType = e.currentTarget.id
    setDownloadAssetType(downloadAssetType)
  }

  const toggleDeleteAssetsConfirmation = (dispalyModal = true) => {
    setDispDelAssetConfModal(dispalyModal)
  }

  const onDeleteAssets = () => {
    const deleteMasterIds = []
    const deleteAssetIds = []
    const assetIds = {}
    if (!isEmpty(selectedItems)) {
      setIsDeleteInitiated(true)
      setDispDelAssetConfModal(false)
      Object.values(selectedItems).forEach((asset) => {
        if (asset.assetMasterId) {
          deleteMasterIds.push(asset.assetMasterId)
        } else {
          deleteAssetIds.push(asset.assetId)
        }
      })
      if (deleteMasterIds.length > 0) {
        assetIds['asset_master_uoi_ids'] = deleteMasterIds
      }
      if (deleteAssetIds.length > 0) {
        assetIds['asset_uoi_ids'] = deleteAssetIds
      }

      if (Object.keys(assetIds).length > 0) {
        dispatch(
          deleteRevisions(
            assetIds,
            getDeleteAssetNotificationMessage(
              Object.values(selectedItems).length,
            ),
            '',
            {},
            bulkActionSuccessCB,
          ),
        )
        trackBetaSearchAnalytics({
          auth: { lanId: userId, email },
          trackingData: {
            eventName: BetaAnalytics.event.DELETE_ASSET,
            eventType: BetaAnalytics.event.ASSET_USAGE,
            eventData: {
              payload: assetIds,
            },
          },
        })
      }
    }
  }

  const onDownloadClicked = (e) => {
    const downloadAssetType = e.currentTarget.id
    startDownloadFile({
      selectedAssets: [...selectedItems],
      dispatch,
      downloadAssetType,
      auth: {
        lanId: userId,
        email,
      },
      page,
      location: 'betaSearch',
      embededQueryParams: true,
    })
  }

  return (
    <div>
      <HeaderTitle title="Beta Search" icon="Search" />
      <SearchPage />
      {selectedItems?.length > 0 && (
        <BulkActionBar
          selectedAssets={selectedItems}
          noOfSelectedItems={selectedItems?.length}
          onDownloadClicked={onDownloadClicked}
          isDownloadInitiated={downloadInProgress}
          toggleRightSidenav={dispatchToggleSidenav}
          isToggleRightSidenav={isToggleRightSidenav}
          rightSideNavComponent={rightSideNavComponent}
          downloadAssetType={downloadAssetType}
          isAdminOrLibrarian={isAdminOrLibrarian}
          onDeleteAssets={toggleDeleteAssetsConfirmation}
          enableDeleteAsset={isAdminOrLibrarian}
          onDownloadMetadata={downloadMetadata}
          isMetadataDownloadInitiated={isMetadataDownloadInitiated}
          onClearSelection={bulkActionSuccessCB}
        />
      )}
      {dispDelAssetConfModal && (
        <DeleteAssetsDailog
          noOfSelectedAssets={selectedItems?.length}
          deleteAssets={onDeleteAssets}
          toggleConfirmationModal={toggleDeleteAssetsConfirmation}
          dialogOpen={dispDelAssetConfModal}
        />
      )}
    </div>
  )
}

export default React.memo(NewSearch)
