import axios from 'axios'

import {
  SET_METADATA_HEADERS,
  SET_CLASSIFICATION_DATA,
  SET_CLASSIFICATION_HIERARCHY,
  SET_CLASSIFICATION_SELECTION,
  RESET_ASSETTYPE_SELECTION,
} from './actionTypes'
import apiConfig from '../../config/apiConfig'
import { showNotification } from '../notification/actionCreator'

const { classification = {} } = apiConfig
const {
  getMetadataTypesUrl = '',
  getAssetClassificationUrl = '',
  getAssetClassicationHierarchy = '',
} = classification
export function setMetadataHeaders(payload) {
  return {
    type: SET_METADATA_HEADERS,
    payload,
  }
}

export function setClassificationData(payload) {
  return {
    type: SET_CLASSIFICATION_DATA,
    payload,
  }
}

export function setClassificationHierarchy(payload) {
  return {
    type: SET_CLASSIFICATION_HIERARCHY,
    payload,
  }
}

export function setClassificationSelection(payload) {
  return {
    type: SET_CLASSIFICATION_SELECTION,
    payload,
  }
}

export function resetAssetSelection() {
  return {
    type: RESET_ASSETTYPE_SELECTION,
  }
}

export function getClassificationMetadataHeaders() {
  return (dispatch) => {
    axios
      .get(`${getMetadataTypesUrl}`)
      .then((res) => {
        const { data = [] } = res
        dispatch(setMetadataHeaders(data))
      })
      .catch((error, data) => {
        dispatch(
          showNotification(
            true,
            `Error while requesting classification headers`,
            'error'
          )
        )
      })
  }
}

export function getAllClassificationData() {
  return (dispatch) => {
    axios
      .get(`${getAssetClassificationUrl}`)
      .then((res) => {
        const { data = [] } = res
        dispatch(setClassificationData(data))
      })
      .catch((error, data) => {
        dispatch(
          showNotification(
            true,
            `Error while requesting all classification data`,
            'error'
          )
        )
      })
  }
}

export function getAssetClassificationHierarchy(fetchType = '') {
  return (dispatch) => {
    const hierarchyUrl = fetchType ? `${getAssetClassicationHierarchy}?fetch_type=${fetchType}`:  `${getAssetClassicationHierarchy}`
    axios
      .get(hierarchyUrl)
      .then((res) => {
        const { data = [] } = res
        dispatch(setClassificationHierarchy(data))
      })
      .catch((error, data) => {
        dispatch(
          showNotification(
            true,
            `Error while requesting classification types`,
            'error'
          )
        )
      })
  }
}
