import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import { praxis } from '../../config/themeConfig'

const styles = {
  root: {
    position: 'absolute',
    border: '3px solid #5471D2',
    outline: '2px solid white',
  },
  number: {
    width: '25px',
    height: '25px',
    color: 'white',
    display: 'flex',
    position: 'absolute',
    borderRadius: '50%',
    backgroundColor: praxis.lighterBlue,
    alignItems: 'center',
    justifyContent: 'center',
    border: '2px solid white',
  },
}

export const Box = ({
  geometry = {},
  style = {},
  number = 0,
  angle = 0,
  classes = {},
}) => {
  let correctedStyles = {}
  if (angle === 0) {
    correctedStyles = {
      top: '-15px',
      left: '-15px',
      transform: `rotate(${0}deg)`,
    }
  } else if (angle === 90) {
    correctedStyles = {
      bottom: '-15px',
      left: '-15px',
      transform: `rotate(${270}deg)`,
    }
  } else if (angle === 180) {
    correctedStyles = {
      bottom: '-15px',
      right: '-15px',
      transform: `rotate(${180}deg)`,
    }
  } else if (angle === 270) {
    correctedStyles = {
      top: '-15px',
      right: '-15px',
      transform: `rotate(${90}deg)`,
    }
  }

  return (
    <div
      data-cy="imageMarkupBox"
      className={classes.root}
      style={{
        ...style,
        left: `${geometry.x}%`,
        top: `${geometry.y}%`,
        height: `${geometry.height}%`,
        width: `${geometry.width}%`,
      }}
    >
      <div className={classes.number} style={correctedStyles}>
        {number}
      </div>
    </div>
  )
}

Box.propTypes = {
  geometry: PropTypes.object,
  style: PropTypes.object,
  number: PropTypes.number,
  angle: PropTypes.number,
  classes: PropTypes.object,
}

export default withStyles(styles)(Box)
