import {
  GROUPS_API_CALL_INNITIATED,
  GROUPS_API_CALL_SUCCESS,
  GROUPS_API_CALL_FAIL,
  GROUPS_FILTER,
  GROUPS_SORT,
  GROUP_CREATE,
  GROUP_CREATE_SUCCESS,
  GROUP_CREATE_FAIL,
  GROUP_EDIT,
  GROUP_EDIT_FAIL,
  GROUP_EDIT_SUCCESS,
  OPEN_MODAL,
  CLOSE_MODAL,
  GROUP_PEOPLE_LIST,
  GROUP_PEOPLE_LIST_SUCCESS,
  GROUP_PEOPLE_LIST_FAIL,
  EXPAND_GROUP,
  EXPAND_GROUP_SUCCESS,
  EXPAND_GROUP_FAIL,
  COLLAPSE_GROUP,
  UPDATE_GROUPS,
} from './actionType'

export const groupsState = {
  groupList: [],
  groupCount: 0,
  groupPeopleList: {},
  collaboratorsList: [],
  loading: {
    createGroupLoading: false,
    groupsLoading: false,
    peopleListLoading: false,
    expandGroupLoading: {},
    createColabratorGroupLoading: false,
  },
  modal: {
    groupsForm: false,
    collaboratorsForm: false,
  },
  toast: {
    message: '',
    open: false,
  },
}

export default function groups(state = groupsState, action = {}) {
  let loading
  let modal
  switch (action.type) {
    case OPEN_MODAL:
      modal = {
        ...state.modal,
        [action.modalName]: true,
      }
      return {
        ...state,
        modal,
      }
    case CLOSE_MODAL:
      modal = {
        ...state.modal,
        [action.modalName]: false,
      }
      return {
        ...state,
        modal,
      }
    case GROUP_CREATE:
      loading = {
        ...state.loading,
        createGroupLoading: true,
      }
      return {
        ...state,
        loading,
      }
    case GROUP_CREATE_SUCCESS:
      loading = {
        ...state.loading,
        createGroupLoading: false,
      }
      return {
        ...state,
        loading,
      }
    case GROUP_CREATE_FAIL:
      loading = {
        ...state.loading,
        createGroupLoading: false,
      }
      return {
        ...state,
        loading,
      }

    case GROUP_EDIT:
      loading = {
        ...state.loading,
        createGroupLoading: true,
      }
      return {
        ...state,
        loading,
      }
    case GROUP_EDIT_SUCCESS:
      loading = {
        ...state.loading,
        createGroupLoading: false,
      }
      return {
        ...state,
        loading,
      }
    case GROUP_EDIT_FAIL:
      loading = {
        ...state.loading,
        createGroupLoading: false,
      }
      return {
        ...state,
        loading,
      }
    //
    case GROUP_PEOPLE_LIST:
      loading = {
        ...state.loading,
        peopleListLoading: true,
      }
      return {
        ...state,
        loading,
        groupPeopleList: {},
      }
    case GROUP_PEOPLE_LIST_SUCCESS: {
      loading = {
        ...state.loading,
        peopleListLoading: false,
      }
      let groupPeopleList = action.data
      return {
        ...state,
        loading,
        groupPeopleList,
      }
    }
    case GROUP_PEOPLE_LIST_FAIL:
      loading = {
        ...state.loading,
        peopleListLoading: false,
      }
      return {
        ...state,
        loading,
      }
    case GROUPS_API_CALL_INNITIATED:
      return {
        ...state,
        groupsLoading: true,
      }
    case GROUPS_API_CALL_SUCCESS:
      return {
        ...state,
        groupList: action.data,
        groupCount: action.data.length,
        groupsLoading: false,
      }
    case GROUPS_API_CALL_FAIL:
      return {
        ...state,
        apiError: action.error,
        groupsLoading: false,
      }

    case UPDATE_GROUPS: {
      const { groups } = action
      return {
        ...state,
        groupList: groups,
      }
    }
    case GROUPS_FILTER: {
      let filterResults = []
      let projCount = 0
      filterResults = [...state.groupList].map(function (obj) {
        let newObj = { ...obj }
        if (
          newObj.list_name &&
          newObj.list_name.toLowerCase().indexOf(action.data.toLowerCase()) !==
            -1
        ) {
          newObj.show = true
          projCount++
        } else {
          newObj.show = false
        }
        return newObj
      })
      return { ...state, groupList: filterResults, groupCount: projCount }
    }
    case GROUPS_SORT: {
      let sortResults = []
      switch (action.data) {
        case '-create_date':
          sortResults = [...state.groupList].sort(function (a, b) {
            let date1 = new Date(a.create_date).getTime()
            let date2 = new Date(b.create_date).getTime()
            return date1 > date2 ? -1 : 1
          })
          break
        case 'create_date':
          sortResults = [...state.groupList].sort(function (a, b) {
            let date1 = new Date(b.create_date).getTime()
            let date2 = new Date(a.create_date).getTime()
            return date1 < date2 ? -1 : 1
          })
          break
        case 'list_count':
          sortResults = [...state.groupList].sort(function (a, b) {
            let count1 = new Date(a.list_count)
            let count2 = new Date(b.list_count)
            return count1 < count2 ? -1 : 1
          })
          break
        default:
          sortResults = [...state.groupList].sort(function (a, b) {
            let nameA = new Date(a.create_date).getTime()
            let nameB = new Date(b.create_date).getTime()
            return nameA > nameB ? -1 : 1
          })
          break
      }
      return { ...state, groupList: sortResults }
    }
    case EXPAND_GROUP:
      loading = {
        ...state.loading,
        expandGroupLoading: {
          [action.groupId]: true,
        },
      }
      return {
        ...state,
        loading,
      }
    case EXPAND_GROUP_SUCCESS: {
      loading = {
        ...state.loading,
        expandGroupLoading: {
          [action.groupId]: false,
        },
      }
      state.collaboratorsList.every(function (element, index) {
        if (element.group_id === action.groupId) {
          let colab = { ...state.collaboratorsList[index] }
          colab['userList'] = action.data.user
          colab['expanded'] = true
          state.collaboratorsList[index] = colab
          return false
        } else {
          return true
        }
      })
      return {
        ...state,
        loading,
      }
    }
    case EXPAND_GROUP_FAIL:
      loading = {
        ...state.loading,
        expandGroupLoading: {
          [action.groupId]: false,
        },
      }
      return {
        ...state,
        loading,
      }
    case COLLAPSE_GROUP: {
      let collaboratorsList = [...state.collaboratorsList]
      collaboratorsList.every(function (element, index) {
        if (element.group_id === action.groupId) {
          let colab = { ...state.collaboratorsList[index] }
          colab['userList'] = []
          colab['expanded'] = false
          collaboratorsList[index] = colab
          return false
        } else {
          return true
        }
      })
      return {
        ...state,
        collaboratorsList,
      }
    }
    default:
      return state
  }
}
