import axios from 'axios'

import { TM_SUCCESS, TM_FAILURE, FETCH_TEAM_MEMBERS_CALL } from './actionType'
import apiConfig from '../../config/apiConfig'

const KEY = apiConfig.key

export function getTmList(name, taskType = '') {
  let TM_URL = `${apiConfig.userDetails.usersList}?name_like=${name}&key=${KEY}`
  if (taskType) {
    TM_URL = `${TM_URL}&fetch_type=${taskType}`
  }
  return function (dispatch) {
    return axios
      .get(TM_URL)
      .then((response) => {
        dispatch(tmListSuccess(response))
      })
      .catch((e) => {
        dispatch(
          tmListError({
            id: 'tmListError',
            data: e,
          })
        )
      })
  }
}

export function tmListSuccess(response) {
  return {
    type: TM_SUCCESS,
    payload: response,
  }
}

export function tmListError(error) {
  return {
    type: TM_FAILURE,
    payload: error,
  }
}

export const fetchTeamMemberList =
  (name = '') =>
  (dispatch) => {
    const promise = axios.get(
      `${apiConfig.userDetails.usersList}?name_like=${name}&key=${KEY}`
    )
    return dispatch({
      type: FETCH_TEAM_MEMBERS_CALL,
      payload: promise,
    })
  }
