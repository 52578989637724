import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { isEqual, isEmpty, get } from 'lodash'
import { withStyles } from '@mui/styles'
import { Grid, Button, Tooltip, IconButton } from '@mui/material'
import {
  Comment,
  InsertPhoto,
  RotateLeft,
  ZoomIn,
  ZoomOut,
} from '@mui/icons-material'
import {
  AssetsSidebar,
  CommentSidebar,
  ImageAnnotator,
  RouteLeavingGuard,
  Toolbar,
  ToolSelector,
  ViewedBySidebar,
} from '../../components/Annotation'
import {
  getTaskAssets,
  getCommentsByAssetIdAndRevision,
  setPrimaryAsset,
  toggleComparisonView,
  handleComparisonToSingle,
  setTaskStatus,
} from '../../store/annotation/actions'
import {
  assetPropType,
  commentPropType,
  TaskRoles,
  DEFAULT_SCALE,
} from '../../constants/annotation'
import {
  getHighestIdNumber,
  getAnnotationFromComment,
} from '../../helpers/annotationHelper'
import {
  selectAssetVersion,
  selectIsActiveReview,
  selectIsCurrentTaskRole,
  selectTaskStatus,
} from '../../store/annotation/selectors'
import { TASK_STATUS_NEW } from '../../constants/tasks'
import { praxis } from '../../config/themeConfig'
import { updateUserPreferences } from '../../store/preferences/actionCreator'

const styles = {
  homePage: {
    textAlign: 'left',
    height: '95%',
    margin: '-8px auto 0 auto',
  },
  sideButton: {
    borderRadius: '0',
    width: '50%',
    height: '50px',
    backgroundColor: '#EFEFEF',
    color: praxis.themePrimary,
  },
  innerButton: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  leftSidebar: {
    position: 'absolute',
    width: '320px',
  },
  rightSidebar: {
    position: 'absolute',
    width: '320px',
    marginTop: '-8px',
    right: 0,
  },
  content: {
    width: '100%',
    height: '100%',
    overflowY: 'scroll',
    overflowX: 'hidden',
    backgroundColor: '#EFEFEF',
  },
  body: {
    backgroundColor: '#484848',
    padding: 0,
    overflow: 'scroll',
  },
  shade: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    height: '100%',
    position: 'absolute',
    zIndex: 1000,
  },
  zoomTextDiv: {
    display: 'flex',
    zIndex: 5,
    // position: 'absolute',
    backgroundColor: '#EFEFEF',
    height: '40px',
    width: '45px',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid rgb(141, 141, 141)',
    fontSize: '14px',
    fontWeight: '500',
  },
  zoomControlsDiv: {
    // top: '171px',
    // left: '91px',
    display: 'flex',
    zIndex: 5,
    position: 'absolute',
    flexDirection: 'column',
    backgroundColor: 'rgb(141, 141, 141)',
    border: '1px solid rgb(141, 141, 141)',
  },
  zoomControls: {
    height: '40px',
    width: '45px',
    // borderBottom: '1px solid rgb(141, 141, 141)',
    padding: 0,
    borderRadius: 0,
    minWidth: '45px !important',
    color: 'black',
    // boxShadow: 'rgb(0 0 0 / 17%) 0px -23px 25px 0px inset, rgb(0 0 0 / 15%) 0px -36px 30px 0px inset, rgb(0 0 0 / 10%) 0px -79px 40px 0px inset, rgb(0 0 0 / 6%) 0px 2px 1px, rgb(0 0 0 / 9%) 0px 4px 2px, rgb(0 0 0 / 9%) 0px 8px 4px, rgb(0 0 0 / 9%) 0px 16px 8px, rgb(0 0 0 / 9%) 0px 32px 16px',
  },
  zoomControlsStartIcon: {
    margin: 'unset !important',
  },
}

const angles = [0, 90, 180, 270]

// eslint-disable-next-line react/display-name
const ImageAnnotatorNew = React.forwardRef((props, ref) => (
  <ImageAnnotator {...props} zoomPerRef={ref} />
))

export class ComparisonContainer extends React.Component {
  constructor(props) {
    super(props)
    const { taskStatus = TASK_STATUS_NEW } = props
    // this.assetRefDictionary
    // this.secondaryAssetRefDictionary
    this.state = {
      showAssets: true,
      currentTool: 'cursor',
      angle: 0,
      scale: DEFAULT_SCALE,
      width: 0,
      height: 0,
      annotations: [],
      secondaryAnnotations: [],
      hideAnnotationIds: [],
      hideSecondaryAnnoationIds: [],
      currentlySelected: 'primary',
      primaryShowViewedBy: false,
      secondaryShowViewedBy: false,
      taskStatus,
      zoomAnnotatorProps: {},
    }

    this.zoomPerRefPrime = React.createRef()
    this.zoomPerRefSec = React.createRef()

    this.currentImgRefPrime = React.createRef()
    this.currentImgRefSec = React.createRef()
  }

  componentDidMount() {
    const {
      getTaskAssets = () => {},
      match = {},
      router = {},
      comments = [],
      secondaryComments = [],
      annotation_zoom = 'fit-to-screen',
      params = {},
    } = this.props
    // this.setState({ scale: annotation_zoom })
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
    window.addEventListener('beforeunload', this.beforeUnload)
    this.intervalId = setInterval(this.autoRefreshComments, 30000)
    getTaskAssets(params)

    if (!!comments) {
      const annotations = this.getAnnotationsFromComments([...comments])
      this.setState({ annotations })
    }

    if (!!secondaryComments) {
      const secondaryAnnotations = this.getAnnotationsFromComments([
        ...secondaryComments,
      ])
      this.setState({ secondaryAnnotations })
    }

    this.assetRefDictionary = this.props.assets.reduce((dict, asset) => {
      dict[asset.asset_master_id] = React.createRef()
      return dict
    }, {})
    this.secondaryAssetRefDictionary = this.props.assets.reduce(
      (dict, asset) => {
        dict[asset.asset_master_id] = React.createRef()
        return dict
      },
      {}
    )
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
    window.removeEventListener('beforeunload', this.beforeUnload)
    clearInterval(this.intervalId)
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      setPrimaryAsset = () => {},
      primaryAsset = {},
      assets = [],
      comments = [],
      secondaryAsset = {},
      secondaryComments = [],
      getTaskAssets = () => {},
      match = {},
      router = {},
      primaryVersion = 1,
      secondaryVersion,
      taskStatus,
      primaryPageNumber = 1,
      annotation_zoom,
      params = {},
    } = this.props
    if (prevProps.annotation_zoom !== annotation_zoom) {
      this.setState({ scale: annotation_zoom })
    }
    if (!isEqual(prevProps.assets, assets)) {
      if (assets.length > 0) {
        setPrimaryAsset(primaryAsset, false, primaryVersion, primaryPageNumber)
      }
      this.assetRefDictionary = assets.reduce((dict, asset) => {
        dict[asset.asset_master_id] = React.createRef()
        return dict
      }, {})
      this.secondaryAssetRefDictionary = assets.reduce((dict, asset) => {
        dict[asset.asset_master_id] = React.createRef()
        return dict
      }, {})
    }

    if (
      !isEmpty(primaryAsset) &&
      !isEmpty(prevProps.primaryAsset) &&
      !isEqual(prevProps.primaryAsset, primaryAsset) &&
      isEqual(
        prevProps.primaryAsset.asset_master_id,
        primaryAsset.asset_master_id
      ) &&
      primaryVersion.revisionChanged
    ) {
      this.refreshComments()
      this.setState({
        currentTool: 'cursor',
        hideAnnotationIds: [],
      })
    }

    if (
      !isEmpty(secondaryAsset) &&
      !isEmpty(prevProps.secondaryAsset) &&
      !isEqual(prevProps.secondaryAsset, secondaryAsset) &&
      isEqual(
        prevProps.secondaryAsset.asset_master_id,
        secondaryAsset.asset_master_id
      ) &&
      secondaryVersion.revisionChanged
    ) {
      this.refreshComments(true)
      this.setState({
        currentTool: 'cursor',
        hideSecondaryAnnoationIds: [],
      })
    }

    if (!isEqual(prevProps.comments, comments)) {
      const annotations = this.getAnnotationsFromComments([...comments])
      this.setState({ annotations })
    }

    if (prevProps.primaryPageNumber !== this.props.primaryPageNumber) {
      this.props.getCommentsByAssetIdAndRevision()
    }

    if (!isEqual(prevProps.secondaryComments, secondaryComments)) {
      const secondaryAnnotations = this.getAnnotationsFromComments([
        ...secondaryComments,
      ])
      this.setState({ secondaryAnnotations })
    }

    if (prevProps.secondaryPageNumber !== this.props.secondaryPageNumber) {
      this.props.getCommentsByAssetIdAndRevision(true)
    }

    if (!prevState.showAssets && this.state.showAssets) {
      getTaskAssets(params)
      if (
        !!get(
          this.assetRefDictionary,
          `[${primaryAsset.asset_master_id}].current`
        )
      ) {
        this.assetRefDictionary[
          primaryAsset.asset_master_id
        ].current.scrollIntoView({
          behavior: 'instant',
          block: 'start',
        })
      }
      if (
        !!get(
          this.secondaryAssetRefDictionary,
          `[${secondaryAsset.asset_master_id}].current`
        )
      ) {
        this.secondaryAssetRefDictionary[
          secondaryAsset.asset_master_id
        ].current.scrollIntoView({
          behavior: 'instant',
          block: 'start',
        })
      }
    }

    if (!isEqual(prevProps.taskStatus, taskStatus)) {
      this.setState({ taskStatus })
    }
  }

  beforeUnload = (e) => {
    e.preventDefault()
    return (e.returnValue = 'new thing')
  }

  refreshComments = (isSecondary = false) => {
    const { getCommentsByAssetIdAndRevision = () => {} } = this.props
    getCommentsByAssetIdAndRevision(isSecondary)
  }

  autoRefreshComments = () => {
    const { secondaryAsset = {} } = this.props
    this.refreshComments()
    if (!isEmpty(secondaryAsset)) {
      this.refreshComments(true)
    }
  }

  updateWindowDimensions = () =>
    this.setState({ width: window.innerWidth, height: window.innerHeight })

  onShowHideAssets = (bool = true) => this.setState({ showAssets: bool })

  onToolSelect = (name = 'cursor') => this.setState({ currentTool: name })

  onChangeAngle = (action = '') => {
    const { angle } = this.state
    if (action === 'left') {
      if (angle > 0) {
        this.setState((state) => ({ angle: state.angle - 1 }))
      } else {
        this.setState({ angle: 3 })
      }
    } else if (action === 'right') {
      if (angle < 3) {
        this.setState((state) => ({ angle: state.angle + 1 }))
      } else {
        this.setState({ angle: 0 })
      }
    }
  }

  onChangeScale = (event) => {
    const {
      updateUserPreferences = () => {},
      view = '',
      userPreferences = {},
    } = this.props
    const { preferences = {} } = userPreferences
    const updatedPreferences = { ...preferences }
    updatedPreferences.view = view
    updatedPreferences.annotation_zoom = event.target.value
    updateUserPreferences({
      ...userPreferences,
      preferences: updatedPreferences,
    })
  }

  onChangeTaskStatus = (event) => {
    this.setState({ taskStatus: event.target.value })
    this.props.setTaskStatus(event.target.value)
  }

  onSubmitAnnotation = () => {
    if (this.state.currentTool !== 'cursor') {
      this.setState({ showAssets: false })
    }
  }

  getAnnotationsFromComments = (comments = []) =>
    comments.reduce((results, comment) => {
      const annotation = getAnnotationFromComment(comment)
      if (!isEmpty(annotation)) {
        results.push(annotation)
      }
      return results
    }, [])

  toggleHideAnnotation = (id = 0, isSecondary = false) => {
    const { hideAnnotationIds, hideSecondaryAnnoationIds } = this.state
    if (isSecondary) {
      if (hideSecondaryAnnoationIds.includes(id)) {
        const updatedIdsArray = hideSecondaryAnnoationIds.filter(
          (x) => x !== id
        )
        this.setState({ hideSecondaryAnnoationIds: updatedIdsArray })
      } else {
        this.setState((state) => ({
          hideSecondaryAnnoationIds: [...state.hideSecondaryAnnoationIds, id],
        }))
      }
    } else {
      if (hideAnnotationIds.includes(id)) {
        const updatedIdsArray = hideAnnotationIds.filter((x) => x !== id)
        this.setState({ hideAnnotationIds: updatedIdsArray })
      } else {
        this.setState((state) => ({
          hideAnnotationIds: [...state.hideAnnotationIds, id],
        }))
      }
    }
  }

  toggleHideAllAnnotation = () => {
    const {
      hideAnnotationIds,
      hideSecondaryAnnoationIds,
      annotations,
      secondaryAnnotations,
    } = this.state
    if (
      hideAnnotationIds.length !== 0 ||
      hideSecondaryAnnoationIds.length !== 0
    ) {
      this.setState({ hideAnnotationIds: [], hideSecondaryAnnoationIds: [] })
    } else {
      const allPrimaryAnnoationIds = annotations.map((x) => x.data.id)
      const allSecondaryAnnotationIds = secondaryAnnotations.map(
        (x) => x.data.id
      )
      this.setState({
        hideAnnotationIds: allPrimaryAnnoationIds,
        hideSecondaryAnnoationIds: allSecondaryAnnotationIds,
      })
    }
  }

  toggleCurrentlySelected = (selected = 'primary') =>
    this.setState({ currentlySelected: selected })

  handleToggleSplitView = () => {
    const {
      toggleComparisonView = () => {},
      handleComparisonToSingle = () => {},
    } = this.props
    toggleComparisonView(false)
    handleComparisonToSingle()
  }

  getImageControlHandlers = (imageType, handlersList = {}) => {
    const { zoomAnnotatorProps = {} } = this.state
    this.setState({
      zoomAnnotatorProps: Object.assign({}, zoomAnnotatorProps, {
        [imageType]: handlersList,
      }),
    })
  }

  render() {
    const {
      classes = {},
      assets = [],
      history = {},
      primaryAsset = {},
      secondaryAsset = {},
      match = {},
      isActiveReview = false,
      isProjectAdmin = false,
      isReviewer = false,
      router = {},
    } = this.props
    const { params = {} } = router
    const {
      showAssets,
      currentTool,
      scale,
      width,
      height,
      annotations,
      hideAnnotationIds,
      angle,
      currentlySelected,
      secondaryAnnotations,
      primaryShowViewedBy,
      secondaryShowViewedBy,
      hideSecondaryAnnoationIds,
      taskStatus = TASK_STATUS_NEW,
      zoomAnnotatorProps = {},
    } = this.state
    const filteredAnnotations = annotations.filter(
      (x) => !hideAnnotationIds.includes(x.data.id)
    )
    const numberOfMarkups = getHighestIdNumber(annotations)
    const secondaryFilteredAnnotations = secondaryAnnotations.filter(
      (x) => !hideSecondaryAnnoationIds.includes(x.data.id)
    )
    const secondaryNumberOfMarkups = getHighestIdNumber(secondaryAnnotations)
    let tempScale = scale === DEFAULT_SCALE ? DEFAULT_SCALE : scale + '%'
    const { primeImage = {}, secImage = {} } = zoomAnnotatorProps
    const {
      resetTransform = () => {},
      zoomIn = () => {},
      zoomOut = () => {},
    } = primeImage
    const {
      resetTransform: secImageResetTransform = () => {},
      zoomIn: secZoomIn = () => {},
      zoomOut: secZoomOut = () => {},
    } = secImage
    return (
      <div style={{ display: 'flex' }}>
        <div className={classes.leftSidebar} style={{ height: height - 114 }}>
          <Button
            data-cy="sideBySideAssets"
            className={classes.sideButton}
            onClick={() => this.onShowHideAssets(true)}
            style={{
              borderBottom: showAssets
                ? '3px solid ' + praxis.themePrimary
                : null,
            }}
          >
            <div className={classes.innerButton}>
              <InsertPhoto /> Assets
            </div>
          </Button>
          <Button
            data-cy="sideBySideComments"
            className={classes.sideButton}
            onClick={() => this.onShowHideAssets(false)}
            style={{
              borderBottom: !showAssets
                ? '3px solid ' + praxis.themePrimary
                : null,
            }}
          >
            <div className={classes.innerButton}>
              <Comment /> Comments
            </div>
          </Button>
          <div className={classes.content}>
            {showAssets && (
              <div>
                <AssetsSidebar
                  assets={assets}
                  refDict={this.assetRefDictionary}
                  history={history}
                  match={match}
                  params={params}
                  router={router}
                />
              </div>
            )}
            {!showAssets && !primaryShowViewedBy && (
              <CommentSidebar
                currentAsset={primaryAsset}
                onToolSelect={this.onToolSelect}
                numberOfMarkups={numberOfMarkups}
                toggleHideAnnotation={this.toggleHideAnnotation}
                hideAnnotationIds={hideAnnotationIds}
                isSelected={currentlySelected === 'primary'}
                isProjectAdmin={isProjectAdmin}
                isActiveReview={isActiveReview}
                isReviewer={isReviewer}
                toggleShowViewedBy={() =>
                  this.setState({ primaryShowViewedBy: true })
                }
              />
            )}
            {!showAssets && primaryShowViewedBy && (
              <ViewedBySidebar
                onToggleShowViewed={() =>
                  this.setState({ primaryShowViewedBy: false })
                }
                onUnmount={() => this.setState({ primaryShowViewedBy: false })}
              />
            )}
          </div>
        </div>
        {/* <div className={classes.shade} style={{ width: width - 312, display: showShade ? 'block' : 'none' }} /> */}
        <Grid
          container
          spacing={2}
          className={classes.homePage}
          style={{ width: width - 716 }}
        >
          <Toolbar
            onChangeAngle={this.onChangeAngle}
            onChangeScale={this.onChangeScale}
            scale={scale}
            toggleHideAllAnnotation={this.toggleHideAllAnnotation}
            hideAnnotationIds={hideAnnotationIds}
            toggleSplitView={this.handleToggleSplitView}
            backToTasksPage={() =>
              router.navigate(`/project/${params.projectId}/tasks/list`)
            }
            taskStatus={taskStatus}
            onChangeTaskStatus={this.onChangeTaskStatus}
          />
          <Grid
            item
            xs={6}
            className={classes.body}
            style={{
              height: height - 114,
              border:
                currentlySelected === 'primary' ? '5px dashed yellow' : 'none',
              justifyContent: 'center',
            }}
            onClick={() => this.toggleCurrentlySelected('primary')}
          >
            <div className={classes.zoomControlsDiv}>
              <div className={classes.zoomTextDiv} ref={this.zoomPerRefPrime}>
                zoom text
              </div>
              <Tooltip title="ZoomIn" placement="right">
                <IconButton
                  className={classes.zoomControls}
                  onClick={(event) => {
                    event.stopPropagation()
                    zoomIn(event)
                  }}
                  size="large"
                >
                  <ZoomIn fontSize="medium" />
                </IconButton>
              </Tooltip>
              <Tooltip title="ZoomOut" placement="right">
                <IconButton
                  className={classes.zoomControls}
                  onClick={(event) => {
                    event.stopPropagation()
                    zoomOut(event)
                  }}
                  size="large"
                >
                  <ZoomOut fontSize="medium" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Reset" placement="right">
                <IconButton
                  className={classes.zoomControls}
                  onClick={(event) => {
                    event.stopPropagation()
                    resetTransform()
                  }}
                  size="large"
                >
                  <RotateLeft fontSize="medium" />
                </IconButton>
              </Tooltip>
            </div>

            <ImageAnnotatorNew
              onSubmitAnnotation={this.onSubmitAnnotation}
              annotations={filteredAnnotations}
              currentTool={currentTool}
              numberOfMarkups={numberOfMarkups}
              angle={angles[angle]}
              isSelected={currentlySelected === 'primary'}
              containerHeight={height - 114}
              containerWidth={width - 1170}
              ref={this.zoomPerRefPrime}
              getImageControlHandlers={(handlersList) => {
                this.getImageControlHandlers('primeImage', handlersList)
              }}
              scale={'fit-content'}
            />
          </Grid>
          <Grid
            item
            xs={6}
            className={classes.body}
            style={{
              height: height - 114,
              border:
                currentlySelected === 'secondary'
                  ? '5px dashed yellow'
                  : 'none',
              justifyContent: 'center',
            }}
            onClick={() => this.toggleCurrentlySelected('secondary')}
          >
            {isEmpty(secondaryAsset) && <div>Please select a second asset</div>}
            {!isEmpty(secondaryAsset) && (
              <div className={classes.zoomControlsDiv}>
                <div className={classes.zoomTextDiv} ref={this.zoomPerRefSec}>
                  zoom text
                </div>
                <Tooltip title="ZoomIn" placement="right">
                  <IconButton
                    className={classes.zoomControls}
                    onClick={(event) => {
                      event.stopPropagation()
                      secZoomIn(event)
                    }}
                    size="large"
                  >
                    <ZoomIn fontSize="medium" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="ZoomOut" placement="right">
                  <IconButton
                    className={classes.zoomControls}
                    onClick={(event) => {
                      event.stopPropagation()
                      secZoomOut(event)
                    }}
                    size="large"
                  >
                    <ZoomOut fontSize="medium" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Reset" placement="right">
                  <IconButton
                    className={classes.zoomControls}
                    onClick={(event) => {
                      event.stopPropagation()
                      secImageResetTransform()
                    }}
                    size="large"
                  >
                    <RotateLeft fontSize="medium" />
                  </IconButton>
                </Tooltip>
              </div>
            )}
            {!isEmpty(secondaryAsset) && (
              <ImageAnnotatorNew
                onSubmitAnnotation={this.onSubmitAnnotation}
                annotations={secondaryFilteredAnnotations}
                currentTool={currentTool}
                numberOfMarkups={secondaryNumberOfMarkups}
                angle={angles[angle]}
                isSecondary
                isSelected={currentlySelected === 'secondary'}
                containerHeight={height - 114}
                containerWidth={width - 1170}
                ref={this.zoomPerRefSec}
                getImageControlHandlers={(handlersList = {}) => {
                  this.getImageControlHandlers('secImage', handlersList)
                }}
                scale={'fit-content'}
              />
            )}
          </Grid>
          {(isProjectAdmin || (isReviewer && isActiveReview)) && (
            <ToolSelector
              currentTool={currentTool}
              onToolSelect={this.onToolSelect}
            />
          )}
        </Grid>

        <div className={classes.rightSidebar} style={{ height: height - 114 }}>
          <Button
            className={classes.sideButton}
            onClick={() => this.onShowHideAssets(true)}
            style={{
              borderBottom: showAssets
                ? '3px solid ' + praxis.themePrimary
                : null,
            }}
          >
            <div className={classes.innerButton}>
              <InsertPhoto /> Assets
            </div>
          </Button>
          <Button
            className={classes.sideButton}
            onClick={() => this.onShowHideAssets(false)}
            style={{
              borderBottom: !showAssets
                ? '3px solid ' + praxis.themePrimary
                : null,
            }}
          >
            <div className={classes.innerButton}>
              <Comment /> Comments
            </div>
          </Button>
          <div className={classes.content}>
            {showAssets && (
              <div>
                <AssetsSidebar
                  assets={assets}
                  history={history}
                  match={match}
                  params={params}
                  router={router}
                  isSecondary
                  refDict={this.secondaryAssetRefDictionary}
                />
              </div>
            )}
            {!showAssets && !secondaryShowViewedBy && (
              <CommentSidebar
                currentAsset={secondaryAsset}
                onToolSelect={this.onToolSelect}
                numberOfMarkups={secondaryNumberOfMarkups}
                toggleHideAnnotation={this.toggleHideAnnotation}
                hideAnnotationIds={hideSecondaryAnnoationIds}
                isSelected={currentlySelected === 'secondary'}
                isProjectAdmin={isProjectAdmin}
                isActiveReview={isActiveReview}
                isReviewer={isReviewer}
                toggleShowViewedBy={() =>
                  this.setState({ secondaryShowViewedBy: true })
                }
                isSecondary
              />
            )}
            {!showAssets && secondaryShowViewedBy && (
              <ViewedBySidebar
                onToggleShowViewed={() =>
                  this.setState({ secondaryShowViewedBy: false })
                }
                onUnmount={() =>
                  this.setState({ secondaryShowViewedBy: false })
                }
                isSecondary
              />
            )}
          </div>
        </div>
        <RouteLeavingGuard history={history} router={router} />
      </div>
    )
  }
}

ComparisonContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  assets: PropTypes.array,
  getTaskAssets: PropTypes.func,
  updateUserPreferences: PropTypes.func,
  comments: PropTypes.arrayOf(commentPropType),
  isActiveReview: PropTypes.bool,
  isProjectAdmin: PropTypes.bool,
  isReviewer: PropTypes.bool,
  getCommentsByAssetIdAndRevision: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      jobId: PropTypes.string,
    }),
  }),
  primaryAsset: assetPropType,
  secondaryAsset: assetPropType,
  setPrimaryAsset: PropTypes.func,
  toggleComparisonView: PropTypes.func,
  secondaryComments: PropTypes.arrayOf(commentPropType),
  primaryVersion: PropTypes.object,
  secondaryVersion: PropTypes.object,
  handleComparisonToSingle: PropTypes.func,
  setTaskStatus: PropTypes.func,
  taskStatus: PropTypes.number,
  primaryPageNumber: PropTypes.number,
  secondaryPageNumber: PropTypes.number,
}

const mapStateToProps = (state = {}) => {
  const { annotation, userPreferences } = state
  const {
    assets,
    comments,
    secondaryComments,
    primaryVersion,
    secondaryVersion,
    primaryPageNumber,
    secondaryPageNumber,
  } = annotation
  const isActiveReview = selectIsActiveReview()(state)
  const isProjectAdmin = selectIsCurrentTaskRole(TaskRoles.admin)(state)
  const isReviewer = selectIsCurrentTaskRole(TaskRoles.reviewer)(state)
  const primaryAsset = selectAssetVersion()(state)
  const secondaryAsset = selectAssetVersion(true)(state)
  const taskStatus = selectTaskStatus()(state)
  const { preferences = {} } = userPreferences
  const { view = '', annotation_zoom = 'fit-to-screen' } = preferences
  return {
    assets,
    comments,
    secondaryComments,
    primaryAsset,
    secondaryAsset,
    isActiveReview,
    isProjectAdmin,
    isReviewer,
    primaryVersion,
    secondaryVersion,
    taskStatus,
    primaryPageNumber,
    secondaryPageNumber,
    userPreferences,
    view,
    annotation_zoom,
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getTaskAssets,
      getCommentsByAssetIdAndRevision,
      setPrimaryAsset,
      setTaskStatus,
      toggleComparisonView,
      handleComparisonToSingle,
      updateUserPreferences,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ComparisonContainer))
