export const notesColumn = [
  {
    id: 'notes',
    label: 'Notes',
    field: 'notes',
  },
]

export const withoutClassificationArray = [
  { classification_name: 'Upload without classification' },
]

export const classificationBasicColumns = [
  {
    id: 'primary_classification',
    label: 'Primary Classification',
    field: 'primary_classification',
  },
  {
    id: 'secondary_classification',
    label: 'Secondary Classification',
    field: 'secondary_classification',
  },
  {
    id: 'asset_type',
    label: 'Asset Type',
    field: 'asset_type',
  },
  {
    id: 'classification_code',
    label: 'Classification Code',
    field: 'classification_code',
  },
  {
    id: 'lifecycle_expiration',
    label: 'Lifecycle Expiration',
    field: 'lifecycle.expire_after',
  },
  {
    id: 'lifecycle_strategy',
    label: 'Lifecycle Strategy',
    field: 'lifecycle.strategy',
  },
]

export const classificationTableConfig = {
  headerCheckBoxRequired: false,
  checkBoxRequired: false,
  enableTableSearch: false,
  rowsPerPageOptionsList: [10, 25, 50],
  defaultRowsPerPage: 50,
  defaultOrderBy: 'asset_type',
  defaultOrder: 'asc',
  showPagination: true,
  enableHorizontalScroll: true,
  styleTableRows: true,
  tableHeaderRowHeight: '75px',
  tableRowHeight: '40px',
  rowIdentifier: 'classification_id',
}
