export const TEXT_ERROR_EMBEDDED_EXPERIENCE_LOAD =
  'Asset Hub is experiencing a problem.<br /> Please clear the browser cache and try again.'
export const TEXT_ERROR_DEFAULT =
  'Asset Hub is experiencing a problem.<br /> Please try again.'
export const TEXT_ERROR_UNAUTHORIZED = 'You’re not authorized to do that.'
export const TEXT_ERROR_FORM_VALIDATION = 'Please enter the required fields.'
export const TEXT_ERROR_FIELD_VALIDATION =
  'Please enter valid character for the required fields.'
export const TEXT_ERROR_SUPPORT_FORM =
  'Unable to submit help form.<br /> Please try again.'
export const TEXT_ERROR_SUPPORT_FORM_ATTACHMENT =
  'Unable to submit the attachment.<br /> Please try again.'

export const TEXT_SUCCESS_SUPPORT_FORM =
  "Thank you! Your feedback has been submitted<br /> and we'll get back to you soon."
export const TEXT_SUCCESS_PROJECT_UPDATE = 'Project updated successfully!'

// Groups
export const TEXT_ERROR_COLLABORATORS_ALREADY_EXIST =
  'Few Collaborators have already been added to this list.'
export const TEXT_SUCCESS_GROUP_ADD_USER =
  'User(s) added successfully to the group.'
export const TEXT_SUCCESS_GROUP_DELETE_USER =
  'User has been deleted from the group.'
export const TEXT_SUCCESS_GROUP_CREATED = 'Group created successfully!'
export const TEXT_SUCCESS_GROUP_UPDATED = 'Group updated successfully!'

// CGI TRACKER
export const TEXT_SUCCESS_TCIN_ADD = 'TCINs added successfully to Project!'
export const TEXT_CLIPBOARD_COPIED = 'copied to clipboard'

// FIELD VALIDATIONS
export const TEXT_SPECIAL_CHARS_FORM_VALID =
  'The following characters are not supported: < > %'

// Edit project error
export const EDIT_PROJECT_ID_EXIST_ERROR_MESSAGE =
  'has already created a project with this Project ID'

//Default Enabled Fields for Edit
export const DEFAULT_ENABLED_FIELDS_FOR_EDIT = [
  'channel',
  'campaign',
  'due_date',
  'project_admins',
  'project_name',
  'project_id',
]
