import GroupIcon from '@mui/icons-material/Group'
import HomeIcon from '@mui/icons-material/Home'
import SearchIcon from '@mui/icons-material/Search'
import SavedSearchIcon from '@mui/icons-material/SavedSearch'
import ProjectIcon from '@mui/icons-material/DeviceHub'
import HelpIcon from '@mui/icons-material/Help'
import PublishIcon from '@mui/icons-material/Publish'
import AccountTreeIcon from '@mui/icons-material/AccountTree'
import { ViewAgenda } from '@mui/icons-material'
import UsageRightsIcon from '../images/Usage_Rights.svg?url'
import { SINGLE_PLAYGROUND_VIEW_AI } from '../constants/routes'

export const sideNavConfig = [
  {
    name: 'home',
    label: 'Home',
    redirectionUrl: '/',
    iconName: HomeIcon,
  },
  {
    name: 'search',
    label: 'Search',
    redirectionUrl: '/search',
    iconName: SearchIcon,
  },
  {
    name: 'betaSearch',
    label: 'Beta Search',
    redirectionUrl: '/betaSearch',
    iconName: SavedSearchIcon,
  },
  {
    name: 'groups',
    label: 'Groups',
    redirectionUrl: '/groups',
    iconName: GroupIcon,
  },
  {
    name: 'projects',
    label: 'Projects',
    redirectionUrl: '/projects',
    iconName: ProjectIcon,
  },
  {
    name: 'boards',
    label: 'Boards',
    redirectionUrl: '/boards',
    iconName: ViewAgenda,
  },
  {
    name: 'usageRights',
    label: 'Usage Rights',
    redirectionUrl: '/usageRights',
    renderCustomImage: true,
    iconName: UsageRightsIcon,
  },
  {
    name: 'ai_imagetotext',
    label: 'AI Prompt Playground',
    redirectionUrl: SINGLE_PLAYGROUND_VIEW_AI,
    renderCustomText: true,
    iconName: 'AI',
  },
  {
    name: 'classification',
    label: 'Asset Classification',
    redirectionUrl: '/classification',
    iconName: AccountTreeIcon,
  },
  {
    name: 'publish',
    label: 'Publishing',
    redirectionUrl: '/publishing',
    iconName: PublishIcon,
  },
  {
    name: 'help',
    label: 'Help',
    redirectionUrl: '/help',
    iconName: HelpIcon,
    activeClass: 'helpActiveLink',
    menuItemClass: 'menuItem',
  },
]
