import { Button, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import CheckboxGroup from '../../../components/CustomCheckbox/CheckboxGroup'
import CustomButtonGroup from '../../../components/CustomButtonGroup'
import { FILTER_FILE_TYPE_KEY } from '../../../constants/search'
import {
  removeFilterFromPayload,
  updateSearchPayload,
} from '../../../store/search/searchSlice'
import { mapFacetDataToCheckboxConfig } from '../../../mappers/searchMapper'
import { useSearchContext } from '../SearchContext'
import {
  BETA_ANALYTICS_DELIMITER,
  BetaAnalytics,
} from '../../../../analytics/betaSearch'

const useStyles = makeStyles({
  fileTypeContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  fileList: {
    padding: '15px',
    gap: '15px',
    height: '50vh',
    overflow: 'scroll',
    display: 'flex',
  },
})

function FileTypeFilter({
  parentClasses = {},
  closePopOver = () => {},
  availableFacets = {},
  selectedFilterValues = [],
}) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { trackAnalyticsData = () => {} } = useSearchContext()
  const [clearAllItems, setClearAllItems] = useState(false)
  const [selectedFilters, setSelectedFilters] = useState({})
  const [shouldUpdateParent, setShouldUpdateParent] = useState(false)
  const { records: fileTypeList = [] } = availableFacets

  const fileConfig = mapFacetDataToCheckboxConfig(fileTypeList)

  const onClickApply = () => {
    setShouldUpdateParent(true)
    if (Object.values(selectedFilters).flat(1).length) {
      let fileTypeFilters = {}
      fileTypeFilters[FILTER_FILE_TYPE_KEY] =
        Object.values(selectedFilters).flat(1)
      dispatch(updateSearchPayload({ filters: fileTypeFilters }))
    } else {
      dispatch(removeFilterFromPayload([FILTER_FILE_TYPE_KEY]))
    }
    closePopOver()
    trackAnalyticsData({
      eventType: BetaAnalytics.event.ADD_FILTER,
      eventName: BetaAnalytics.event.FILTER_FILE_TYPE,
      eventData: {
        key: FILTER_FILE_TYPE_KEY,
        value:
          Object.values(selectedFilters)
            ?.flat(1)
            ?.join(`${BETA_ANALYTICS_DELIMITER}`) || '',
      },
    })
  }

  const updateParentState = ({ filterKey = '', selectedValues = [] }) => {
    selectedFilters[filterKey] = selectedValues
    setSelectedFilters(selectedFilters)
    setClearAllItems(false)
  }

  const onClickClear = () => {
    setClearAllItems(true)
  }

  return (
    <Grid container className={classes.fileTypeContainer}>
      <Grid item className={classes.fileList}>
        {(Object.keys(fileConfig) || []).map((fileConfigKey = '') => (
          <CheckboxGroup
            parentCheckboxLabel={fileConfigKey}
            parentCheckboxValue={fileConfigKey}
            parentCheckboxSelected={false}
            childItems={fileConfig[fileConfigKey]}
            shouldUpdateParent={shouldUpdateParent}
            selectedFilterValues={selectedFilterValues}
            clearAllItems={clearAllItems}
            updateParentState={updateParentState}
          />
        ))}
      </Grid>
      <Grid item>
        <CustomButtonGroup
          onClickClear={onClickClear}
          onClickApply={onClickApply}
        />
      </Grid>
    </Grid>
  )
}

export default FileTypeFilter
