import React from 'react'
import { makeStyles } from '@mui/styles'
import { Grid } from '@mui/material'
import { visionFocusAreas } from '../../config/homePageConfig'

const useStyles = makeStyles({
  visionContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '20px',
    marginLeft: '-2%',
  },
  visionHeading: {
    fontSize: '60px',
    padding: '20px',
  },
  visionDescription: {
    fontSize: '24px',
    fontWeight: 300,
    padding: '0% 10% 0% 15%',
    lineHeight: '36px',
  },
  focusHeading: {
    fontSize: '30px',
    padding: '20px',
    justifyContent: 'inherit',
    background: '#fcf9fb',
  },
  visionAreaContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '2% 2% 2% 6%',
  },
  visionAreas: {
    width: '30%',
    display: 'flex',
    gap: '30px',
    padding: '15px 15px 40px',
  },
  visionIcon: {
    background: '#f1c3c4',
    borderRadius: '6px',
    width: '30%',
    height: '90%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  visionAreaTitle: {
    fontWeight: 'initial',
    fontSize: '23px',
  },
  visionAreaContent: {
    fontWeight: '300',
    fontSize: '16px',
    paddingTop: '15px',
  },
})

function AssethubVisionPage() {
  const classes = useStyles()
  return (
    <Grid item container className={classes.visionContainer}>
      <Grid item className={classes.visionHeading}>
        Vision
      </Grid>
      <Grid item className={classes.visionDescription}>
        We will become the source of truth for 2D digital assets by enabling
        creative storytelling through our Digital Asset Management platform and
        enterprise services across tools and channels, ensuring they are
        up-to-date, they are on-brand, and they are used in the ways intended.
      </Grid>
      <Grid item container className={classes.focusHeading}>
        <Grid item>Areas of Focus</Grid>
        <Grid item>
          <div className={classes.visionAreaContainer}>
            {visionFocusAreas.map((focusArea, index) => (
              <div className={classes.visionAreas} key={index}>
                <div className={classes.visionIcon}>{focusArea.image}</div>
                <div>
                  <div className={classes.visionAreaTitle}>
                    {focusArea.title}
                  </div>
                  <div className={classes.visionAreaContent}>
                    {focusArea.content}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AssethubVisionPage
