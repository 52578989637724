import { useState, useEffect } from 'react'
import { isEmpty } from 'lodash'
import { useSelector } from 'react-redux'
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material'
import CollapsibleCard from '../../components/Custom/CollapsibleCard'
import BrandAutoComplete from '../../components/Custom/BrandAutocomplete'
import {
  DISPLAY_BRAND_CLASSIFICATION_TYPES,
  METADATA_CARD_HIDE_BUTTON_TEXT,
  METADATA_CARD_SHOW_BUTTON_TEXT,
} from '../../constants/metadata'
import { useMetadataContext } from './MetadataContext'

function AssetClassificationCard({ containerClasses = {} }) {
  const {
    assetMetadata = {},
    isEditMode = false,
    setIsMetadataUnsaved = () => {},
    updateMetadataInBulk = () => {},
  } = useMetadataContext()
  const { metadata: { asset_classification = {}, item_info = {} } = {} } =
    assetMetadata || {}
  const {
    asset_type = '',
    classification_id = '',
    secondary_classification = '',
  } = asset_classification || {}

  const { assetCatalog = {} } = useSelector((state) => state.newMetadata)
  const { assetClassification = [] } = assetCatalog
  const [secondaryClassification, setSecondaryClassification] = useState(
    secondary_classification,
  )
  const [assetType, setAssetType] = useState(asset_type)

  const selectedClassification = assetClassification.find(
    (classificationData) =>
      classificationData.classification_name === secondaryClassification,
  )

  const selectedAssetType = selectedClassification?.['asset_types']?.find(
    (assetData) => assetData.name === assetType,
  )

  const displayBrandDropdown =
    DISPLAY_BRAND_CLASSIFICATION_TYPES.indexOf(secondaryClassification) !== -1

  useEffect(() => {
    const { classification_id: selectedClassificationId = '' } =
      selectedAssetType || {}
    if (selectedClassificationId !== classification_id) {
      setIsMetadataUnsaved(true)
      let updateParentStatePayload = {
        classification_id: selectedClassificationId,
      }
      if (!displayBrandDropdown) {
        updateParentStatePayload['user_defined_brand_name'] = null
      }
      selectedClassificationId && updateMetadataInBulk(updateParentStatePayload)
    } else {
      setIsMetadataUnsaved(false)
    }
  }, [selectedAssetType, displayBrandDropdown])

  return (
    <CollapsibleCard
      cardTitle={'Asset Classifications'}
      hideButtonText={METADATA_CARD_HIDE_BUTTON_TEXT}
      showButtonText={METADATA_CARD_SHOW_BUTTON_TEXT}
      defaultCollapse={true}
      containerClasses={containerClasses}
    >
      {!isEmpty(asset_classification) || isEditMode ? (
        <Grid container className={containerClasses.assetGrid}>
          <Grid item>
            <FormControl>
              <InputLabel id="classification">Classification</InputLabel>
              <Select
                autoWidth
                className={containerClasses.selectWidth}
                labelId="secondary_classification_label"
                id="secondary_classification"
                value={secondaryClassification}
                label="Classification"
                input={<OutlinedInput label="Classification" />}
                onChange={(event) =>
                  setSecondaryClassification(event.target.value)
                }
                disabled={!isEditMode}
              >
                {assetClassification.map((classificationData) => {
                  const { classification_name = '' } = classificationData
                  return (
                    <MenuItem value={classification_name}>
                      {classification_name}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl>
              <InputLabel id="asset_type">Asset Type</InputLabel>
              <Select
                autoWidth
                className={containerClasses.selectWidth}
                labelId="asset_type_label"
                id="asset_type"
                value={assetType}
                label="Asset Type"
                input={<OutlinedInput label="Asset Type" />}
                onChange={(event) => setAssetType(event.target.value)}
                disabled={!isEditMode}
              >
                {selectedClassification?.['asset_types'].map(
                  (selectedAssetType) => {
                    const { name = '' } = selectedAssetType
                    return <MenuItem value={name}>{name}</MenuItem>
                  },
                )}
              </Select>
            </FormControl>
          </Grid>
          {displayBrandDropdown && (
            <Grid item>
              <BrandAutoComplete
                autoCompleteLabel="Brand"
                productInformation={item_info ?? []}
              />
            </Grid>
          )}
        </Grid>
      ) : (
        <div className={containerClasses.noResults}>No Details Available</div>
      )}
    </CollapsibleCard>
  )
}

export default AssetClassificationCard
