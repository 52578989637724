import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles, makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'

const styles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
    width: 40,
    height: 40,
    fontFamily: useTheme().typography.fontFamily,
    fontSize: useTheme().typography.pxToRem(20),
    borderRadius: '50%',
    overflow: 'hidden',
    userSelect: 'none',
  },
  colorDefault: {
    color: useTheme().palette.background.default,
    backgroundColor:
      useTheme().palette.mode === 'light'
        ? useTheme().palette.grey[400]
        : useTheme().palette.grey[600],
  },
  img: {
    width: '100%',
    height: '100%',
    textAlign: 'center',
    // Handle non-square image. The property isn't supported by IE11.
    objectFit: 'cover',
  },
  imgStyle: {
    display: 'flex',
    position: 'relative',
    overflow: 'hidden',
    flexShrink: 0,
    alignItems: 'center',
    userSelect: 'none',
    borderRadius: '50%',
    justifyContent: 'center',
    width: '26px',
    color: '#FFF',
    height: '26px',
    fontSize: '0.8rem',
    marginRight: '-4px',
    marginLeft: '3px',
    backgroundColor: '#FFE57F',
  },
}))

function Avatar(props) {
  const {
    alt,
    children: childrenProp,
    childrenClassName: childrenClassNameProp,
    classes,
    className: classNameProp,
    component: Component,
    imgProps,
    sizes,
    src,
    srcSet,
    ...other
  } = props

  const replaceImg = function (event) {
    event.persist()
    let parentEle = event.target.parentElement
    if (parentEle) {
      parentEle.setAttribute('class', classes.imgStyle)
      if (parentEle.lastChild) {
        parentEle.removeChild(parentEle.lastChild)
      }
      let div = document.createElement('div')
      div.innerHTML = event.target.alt
      parentEle.appendChild(div)
    }
  }
  const className = classNames(
    classes.root,
    {
      [classes.colorDefault]: childrenProp && !src && !srcSet,
    },
    classNameProp
  )
  let children = null

  if (childrenProp) {
    if (
      childrenClassNameProp &&
      typeof childrenProp !== 'string' &&
      React.isValidElement(childrenProp)
    ) {
      const childrenClassName = classNames(
        childrenClassNameProp,
        childrenProp.props.className
      )
      children = React.cloneElement(childrenProp, {
        className: childrenClassName,
      })
    } else {
      children = null
    }
  } else if (src || srcSet) {
    children = (
      <img
        alt={alt}
        src={src}
        onError={(e) => {
          replaceImg(e)
        }}
        srcSet={srcSet}
        sizes={sizes}
        className={classes.img}
        {...imgProps}
      />
    )
  }

  return children ? (
    <Component className={className} {...other}>
      {children || null}
    </Component>
  ) : null
}

Avatar.propTypes = {
  /**
   * Used in combination with `src` or `srcSet` to
   * provide an alt attribute for the rendered `img` element.
   */
  alt: PropTypes.string,
  /**
   * Used to render icon or text elements inside the Avatar.
   * `src` and `alt` props will not be used and no `img` will
   * be rendered by default.
   *
   * This can be an element, or just a string.
   */
  children: PropTypes.node,
  /**
   * @ignore
   * The className of the child element.
   * Used by Chip and ListItemIcon to style the Avatar icon.
   */
  childrenClassName: PropTypes.string,
  /**
   * Override or extend the styles applied to the component.
   * See [CSS API](#css-api) below for more details.
   */
  classes: PropTypes.object.isRequired,
  /**
   * @ignore
   */
  className: PropTypes.string,
  /**
   * The component used for the root node.
   * Either a string to use a DOM element or a component.
   */
  component: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
  /**
   * Attributes applied to the `img` element when the component
   * is used to display an image.
   */
  imgProps: PropTypes.object,
  /**
   * The `sizes` attribute for the `img` element.
   */
  sizes: PropTypes.string,
  /**
   * The `src` attribute for the `img` element.
   */
  src: PropTypes.string,
  /**
   * The `srcSet` attribute for the `img` element.
   */
  srcSet: PropTypes.string,
}

Avatar.defaultProps = {
  component: 'div',
}

const MyComponent = (props) => {
  const classes = styles()
  return <Avatar {...props} classes={classes} />
}

// export default withStyles(styles, { name: 'MuiAvatar' })(Avatar)

export default MyComponent
