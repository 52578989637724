import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import LockIcon from '@mui/icons-material/Lock'
import HeaderTitle from '../Header/HeaderTitle'

const mainDivStyle = {
  textAlign: 'center',
  marginTop: 150,
}
const LockIconStyle = {
  width: 45,
  height: 45,
  color: '#BDBDBD',
}
const noAccessTextStyle = {
  fontSize: 21,
  color: '#333333',
  margin: '24px 0px',
}

const noAccessSubText1Style = {
  fontSize: 16,
  color: '#002171',
}

const noAccessSubText2Style = {
  fontSize: 16,
  color: '#333333',
}

class NoAccessPage extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    const {
      headerTitle = '',
      headerIcon = '',
      projectInitiator = '',
    } = this.props
    const isDashboardOrTask = /(project|tasks|annotator)/.test(
      window.location.pathname
    )
    return (
      <div>
        <HeaderTitle title={headerTitle} icon={headerIcon} />
        <Helmet title={headerTitle} />
        <div style={mainDivStyle}>
          <LockIcon style={LockIconStyle} />
          <div style={noAccessTextStyle}>No Access</div>
          {isDashboardOrTask ? (
            <div style={noAccessSubText1Style}>
              Please reach out to the Project Manager {projectInitiator} to gain
              access to this Project/Task.
            </div>
          ) : (
            <div style={noAccessSubText2Style}>
              You do not have access to this page.
            </div>
          )}
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  const { layout, auth } = state
  const { headerTitle, headerIcon } = layout
  const { projectInitiator } = auth
  return {
    headerTitle,
    headerIcon,
    projectInitiator,
  }
}
NoAccessPage.defaultProps = {
  headerTitle: '',
  headerIcon: '',
  projectInitiator: '',
}

NoAccessPage.propTypes = {
  headerTitle: PropTypes.string,
  headerIcon: PropTypes.string,
  projectInitiator: PropTypes.string,
}

export default connect(mapStateToProps)(NoAccessPage)
