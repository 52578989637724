import { makeStyles } from '@mui/styles'
import { Grid } from '@mui/material'
import { useSelector } from 'react-redux'

import { horizontalFiltersConfig } from '../../../constants/search'
import { updateFilterCount } from '../../../utils/search'

import CustomFilterBox from './CustomFilterBox'
import ReviewTemplateMenu from './ReviewTemplateMenu'

const useStyles = makeStyles({
  horizontalFilters: { display: 'flex', gap: '16px', flexWrap: 'wrap' },
})

function HorizontalFilters() {
  const classes = useStyles()
  const { filters = {}, isAdmin } = useSelector((state) => ({
    filters: state?.newSearch?.filters || {},
    isAdmin: state?.auth?.isAdGroupSuperAdmin || false,
  }))

  const configWithUpdatedFilterCount = updateFilterCount(
    horizontalFiltersConfig,
    filters,
  )
  return (
    <Grid container className={classes.horizontalFilters}>
      {isAdmin ? (
        <Grid item>
          <ReviewTemplateMenu />
        </Grid>
      ) : (
        ''
      )}
      <Grid item className={classes.horizontalFilters}>
        {configWithUpdatedFilterCount
          .filter((filters) => filters.show)
          .sort((a, b) => a.order - b.order)
          .map((filterConfig) => (
            <CustomFilterBox filterConfig={filterConfig} />
          ))}
      </Grid>
    </Grid>
  )
}

export default HorizontalFilters
