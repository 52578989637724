export const GROUPS_API_CALL_INNITIATED = 'GROUPS_API_CALL_INNITIATED'
export const GROUPS_API_CALL_SUCCESS = 'GROUPS_API_CALL_SUCCESS'
export const GROUPS_API_CALL_FAIL = 'GROUPS_API_CALL_FAIL'
export const GROUPS_FILTER = 'GROUPS_FILTER'
export const GROUPS_SORT = 'GROUPS_SORT'
export const GROUP_CREATE = 'GROUP_CREATE'
export const GROUP_CREATE_SUCCESS = 'GROUP_CREATE_SUCCESS'
export const GROUP_CREATE_FAIL = 'GROUP_CREATE_FAIL'
export const GROUP_EDIT = 'GROUP_EDIT'
export const GROUP_EDIT_SUCCESS = 'GROUP_EDIT_SUCCESS'
export const GROUP_EDIT_FAIL = 'GROUP_EDIT_FAIL'
export const OPEN_MODAL = 'OPEN_MODAL'
export const CLOSE_MODAL = 'CLOSE_MODAL'
export const GROUP_PEOPLE_LIST = 'GROUP_PEOPLE_LIST'
export const GROUP_PEOPLE_LIST_SUCCESS = 'GROUP_PEOPLE_LIST_SUCCESS'
export const GROUP_PEOPLE_LIST_FAIL = 'GROUP_PEOPLE_LIST_FAIL'
export const EXPAND_GROUP = 'EXPAND_GROUP'
export const EXPAND_GROUP_SUCCESS = 'EXPAND_GROUP_SUCCESS'
export const EXPAND_GROUP_FAIL = 'EXPAND_GROUP_FAIL'
export const COLLAPSE_GROUP = 'COLLAPSE_GROUP'
export const COLLABORATORS_FILTER = 'COLLABORATORS_FILTER'
export const UPDATE_GROUPS = 'UPDATE_GROUPS'
