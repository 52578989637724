import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@mui/styles'
import { Grid, Button, Select, MenuItem } from '@mui/material'
import { GetApp, ArrowBack, ChevronLeft } from '@mui/icons-material'
import { praxis } from '../../config/themeConfig'

const scales = {
  'Fit to screen': 0,
  '50%': 0.5,
  '100%': 1,
  '150%': 1.5,
  '200%': 2,
  '250%': 2.5,
  '300%': 3,
  '350%': 3.5,
  '400%': 4,
  '600%': 6,
  '800%': 8,
}
const styles = {
  imageBoxHeader: {
    height: '50px',
    backgroundColor: '#212121',
    position: 'fixed',
    width: '100%',
    marginLeft: 0,
    zIndex: 1,
  },
  rightSideContent: {
    display: 'inline',
    position: 'absolute',
    right: '20px',
  },
  downloadImage: {
    textDecoration: 'none',
  },
  buttonStyle: {
    color: '#FFFFFF',
  },
  buttonTextStyle: {
    paddingLeft: '10px',
    textTransform: 'uppercase',
  },
  buttonExpandStyle: {
    color: '#FFFFFF',
    lineHeight: 'unset',
  },
  buttonExpandTextStyle: {
    marginLeft: '-5px',
  },
  buttonExpandIcon: {
    fontSize: '30px',
    height: '30px',
  },
  zoomSelector: {
    color: praxis.contrastText,
    borderBottom: '1px solid ' + praxis.contrastText,
    width: '120px',
    '&:focus': {
      backgroundColor: 'rgba(0,0,0,0.05) !important',
    },
  },
  zoomSelectorUnderline: {
    marginLeft: '16px',
    '&:after': {
      borderBottom: '1px solid ' + praxis.contrastText + ' !important',
    },
  },
  whiteText: {
    color: praxis.contrastText,
  },
}

const ImageContainerHeader = ({
  classes = {},
  closeButtonText = 'BACK',
  closeButtonHandler = () => {},
  downloadUrl = '',
  downloadButtonText = 'DOWNLOAD',
  downloadImageHandler = () => {},
  openDrawerText = 'OPEN',
  openDrawerHandler = () => {},
  isSidebarOpen = false,
  sideBarWidth = 650,
  scale = 0,
  onChangeScale = () => {},
  isVideo = false,
}) => (
  <Grid
    item
    xs={12}
    className={classes.imageBoxHeader}
    style={{ width: isSidebarOpen ? `calc(100% - ${sideBarWidth}px)` : '100%' }}
  >
    <Button
      data-cy="backToProject"
      className={classes.buttonStyle}
      onClick={closeButtonHandler}
    >
      <ArrowBack />
      <span className={classes.buttonTextStyle}>{closeButtonText}</span>
    </Button>
    <div className={classes.rightSideContent}>
      {/* {!isVideo && (
        <Select
          variant="standard"
          value={scale}
          onChange={onChangeScale}
          classes={{
            icon: classes.whiteText,
            root: classes.zoomSelector,
          }}
          className={classes.zoomSelectorUnderline}
        >
          {Object.keys(scales).map((key) => (
            <MenuItem key={scales[key]} value={scales[key]}>
              {key}
            </MenuItem>
          ))}
        </Select>
      )} */}
      <a href={downloadUrl} className={classes.downloadImage} target="_blank">
        <Button
          className={classes.buttonStyle}
          onClick={() => downloadImageHandler()}
        >
          <GetApp />
          <span className={classes.buttonTextStyle}>{downloadButtonText}</span>
        </Button>
      </a>
      {!isSidebarOpen && (
        <Button
          data-cy="openDrawerButton"
          className={classes.buttonExpandStyle}
          onClick={openDrawerHandler}
        >
          <span className={classes.buttonExpandIcon}>
            <ChevronLeft fontSize="inherit" />
          </span>
          <span className={classes.buttonExpandTextStyle}>
            {openDrawerText}
          </span>
        </Button>
      )}
    </div>
  </Grid>
)

ImageContainerHeader.propTypes = {
  classes: PropTypes.object,
  closeButtonText: PropTypes.string,
  closeButtonHandler: PropTypes.func,
  downloadUrl: PropTypes.string,
  downloadButtonText: PropTypes.string,
  downloadImageHandler: PropTypes.func,
  openDrawerText: PropTypes.string,
  openDrawerHandler: PropTypes.func,
  isSidebarOpen: PropTypes.bool,
  scale: PropTypes.number,
  onChangeScale: PropTypes.func,
  isVideo: PropTypes.bool,
  sideBarWidth: PropTypes.number,
}

export default withStyles(styles)(ImageContainerHeader)
