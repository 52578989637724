import * as React from 'react'
import { makeStyles } from '@mui/styles'
import TablePagination from '@mui/material/TablePagination'

const styles = makeStyles((theme) => ({
  root: {
    alignItems: 'baseline !important',
  },
  inputEl: {
    border: '1px solid #78787f',
    borderRadius: '4px',
  },
  actions: {
    margin: '0 !important',
  },
}))
const CustomPagination = ({
  page = Number(1),
  rowsPerPage = Number(10),
  totalCount = Number(1),
  handlePageChange = () => {},
  handleRowsPerPageChange = () => {},
  rowsPerPageOptions = [10, 25, 50, 100],
}) => {
  const classes = styles()
  const [curPage, setCurPage] = React.useState(page)
  const [curRowsPerPage, setCurRowsPerPage] = React.useState(rowsPerPage)
  const handleChangePage = (event, newPage) => {
    setCurPage(newPage)
    handlePageChange(newPage)
  }

  React.useEffect(() => {
    setCurPage(page)
  }, [page])

  React.useEffect(() => {
    setCurRowsPerPage(rowsPerPage)
  }, [rowsPerPage])

  const handleChangeRowsPerPage = (event) => {
    setCurRowsPerPage(parseInt(event.target.value, Number(10)))
    setCurPage(0)
    handleRowsPerPageChange(parseInt(event.target.value, Number(10)))
  }

  return (
    <TablePagination
      component="div"
      count={totalCount}
      labelRowsPerPage={'Assets per page:'}
      page={curPage}
      onPageChange={handleChangePage}
      rowsPerPage={curRowsPerPage}
      rowsPerPageOptions={rowsPerPageOptions}
      onRowsPerPageChange={handleChangeRowsPerPage}
      classes={{
        toolbar: classes.root,
        input: classes.inputEl,
        actions: classes.actions,
      }}
    />
  )
}

export default CustomPagination
