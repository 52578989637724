import React from 'react'
import Carousel from 'react-material-ui-carousel'
import { makeStyles } from '@mui/styles'
import CarouselItem from './CarouselItem'
import { carouselPageList } from '../../config/homePageConfig'

const useStyles = makeStyles({
  carouselContainer: {
    paddingTop: '5%',
    paddingLeft: '20%',
  },
})

function HomeCarousel() {
  const classes = useStyles()
  return (
    <Carousel
      autoPlay={true}
      indicatorContainerProps={{
        style: {
          paddingTop: '20px',
          marginLeft: '-10%',
          textAlign: 'center',
        },
      }}
      activeIndicatorIconButtonProps={{
        style: {
          color: '#CC0000',
        },
      }}
      indicatorIconButtonProps={{
        style: {
          color: '#f1c3c4',
        },
      }}
      animation={'fade'}
      indicators={true}
      duration="500"
      navButtonsAlwaysInvisible={true}
      cycleNavigation={true}
      fullHeightHover={true}
      swipe={false}
      className={classes.carouselContainer}
    >
      {carouselPageList.map((item, i) => (
        <CarouselItem key={i} item={item} />
      ))}
    </Carousel>
  )
}

export default HomeCarousel
