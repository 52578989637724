import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import SingleAssetContainer from './SingleAssetContainer'
import ComparisonContainer from './ComparisonContainer'
import HeaderTitle from '../../components/Header/HeaderTitle'
import { generateBreadcrumb } from '../../helpers/stringHelper'
import { setTaskStatus, resetAssets } from '../../store/annotation/actions'
import withRouter from '../../containers/Router/WithRouter'

export class AnnotatorHomePage extends React.PureComponent {
  componentDidMount() {
    const { resetAssets = () => {} } = this.props
    resetAssets()
  }

  render() {
    const {
      isComparisonView = false,
      match = {},
      history = {},
      router = {},
      projectId = '',
      projectName = '',
      jobName = '',
    } = this.props
    const { params = {} } = router
    const breadcrumbs = [
      { path: '/projects', title: 'Projects' },
      { path: `/project/${projectId}/dashboard`, title: projectName },
      { path: `/project/${projectId}/tasks/list`, title: jobName },
    ]
    const newHeaderTitle = generateBreadcrumb(breadcrumbs)
    return (
      <React.Fragment>
        <HeaderTitle title={newHeaderTitle} />
        {isComparisonView ? (
          <ComparisonContainer params={params} history={history} />
        ) : (
          <SingleAssetContainer params={params} history={history} />
        )}
      </React.Fragment>
    )
  }
}

AnnotatorHomePage.propTypes = {
  isComparisonView: PropTypes.bool,
  setTaskStatus: PropTypes.func,
  projectId: PropTypes.string,
  projectName: PropTypes.string,
  jobName: PropTypes.string,
  resetAssets: PropTypes.func,
}

const mapStateToProps = (state = {}) => {
  const { annotation } = state
  const {
    isComparisonView,
    project_uuid,
    project_name,
    task_info = {},
  } = annotation
  const { job_name = '' } = task_info
  return {
    isComparisonView,
    projectId: project_uuid,
    projectName: project_name,
    jobName: job_name,
  }
}

export default connect(mapStateToProps, { setTaskStatus, resetAssets })(
  withRouter(AnnotatorHomePage)
)
