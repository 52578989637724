import React, { Fragment, useEffect } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withStyles } from '@mui/styles'
import { Grid, CircularProgress, IconButton } from '@mui/material'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'

import NoThumbnail from '../../../../images/NoThumbnail.svg?url'
import { appendQueryParams } from '../../../../helpers/publishHelper'
import {
  getCurrentAssetInfo,
  getIsFetchingAssets,
} from '../../../../store/publish/selectors'
import { prevAsset, nextAsset } from '../../../../store/publish/actions'
import { NO_THUMBNAIL_TYPES } from '../../../../constants/publishConstants'

const styles = {
  imageBox: {
    height: '95vh',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#484848',
    display: 'flex',
    alignItems: 'center',
  },
  loadingImage: {
    justifyContent: 'center',
    padding: 0,
  },
  showImage: {
    justifyContent: 'space-between',
  },
  ArrowButton: {
    color: '#FFFFFF',
    background: 'rgba(0, 0, 0, 0.5)',
    backgroundImage: 'none',
    height: '60px',
    width: '60px',
    margin: '10px',
    fontSize: '36px',
  },
}

const ImageCarousel = ({
  classes = {},
  asset = {},
  isFetching = false,
  prevAsset = () => {},
  nextAsset = () => {},
  history = {},
  router = {},
}) => {
  const { publishers = [], preview_url = '' } = asset
  const lastPublisher = Object.assign({}, publishers[publishers.length - 1])
  const { file_extension = 'jpg' } = lastPublisher
  return (
    <Fragment>
      {isFetching ? (
        <Grid
          className={cx(classes.imageBox, classes.loadingImage)}
          item
          xs={12}
        >
          <CircularProgress size={40} />
        </Grid>
      ) : (
        <Grid
          className={cx(classes.imageBox, classes.showImage)}
          style={{
            backgroundImage: `url(${appendQueryParams(
              NO_THUMBNAIL_TYPES.includes(file_extension)
                ? NoThumbnail
                : preview_url,
              {
                key: process.env.REACT_APP_DAP_KEY,
                width: 1000,
                height: 1000,
              }
            )})`,
          }}
          item
          xs={12}
        >
          <IconButton
            className={classes.ArrowButton}
            onClick={() => prevAsset(router)}
            size="large"
          >
            <ChevronLeft fontSize="inherit" />
          </IconButton>
          <IconButton
            className={classes.ArrowButton}
            onClick={() => nextAsset(router)}
            size="large"
          >
            <ChevronRight fontSize="inherit" />
          </IconButton>
        </Grid>
      )}
    </Fragment>
  )
}

ImageCarousel.propTypes = {
  classes: PropTypes.object.isRequired,
  asset: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  nextAsset: PropTypes.func.isRequired,
  prevAsset: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  asset: getCurrentAssetInfo(state),
  isFetching: getIsFetchingAssets(state),
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ prevAsset, nextAsset }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ImageCarousel))
