import { useState, useEffect, startTransition } from 'react'
import { isEmpty, difference, uniq } from 'lodash'
import {
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material'
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Error as ErrorIcon,
} from '@mui/icons-material'

import {
  ALLOWED_ACTION_KEYS,
  METADATA_CARD_HIDE_BUTTON_TEXT,
  METADATA_CARD_SHOW_BUTTON_TEXT,
  METADATA_LOOKUP_ERROR,
  METADATA_LOOKUP_PRODUCT_ERROR,
  METADATA_LOOKUP_TEXT,
} from '../../constants/metadata'
import CollapsibleCard from '../../components/Custom/CollapsibleCard'
import { fetchProductDetailsApi } from '../../store/apiService'

import { useMetadataContext } from './MetadataContext'

import {
  tcinRegex,
  dpciWithHyphenRegex,
  dpciWithoutHyphenRegex,
} from '../../../helpers/Regexes'

function ProductInfoCard({ containerClasses = {} }) {
  const {
    assetMetadata = {},
    isEditMode = false,
    setIsMetadataUnsaved = () => {},
    updateMetadata = () => {},
  } = useMetadataContext()

  const { metadata: { item_info = [] } = {} } = assetMetadata

  const [productInformation, setProductInformation] = useState(item_info ?? [])

  useEffect(() => {
    const modifiedTcins =
      productInformation
        ?.map((a) => a?.tcin)
        ?.filter((obj) => !isEmpty(obj) && obj.match(tcinRegex)) || []
    const existingTcins =
      item_info?.map((a) => a?.tcin)?.filter((obj) => !isEmpty(obj)) || []
    const changedTcins = difference(modifiedTcins, existingTcins)
    if (changedTcins.length) {
      setIsMetadataUnsaved(true)
      modifiedTcins && updateMetadata('tcins', modifiedTcins)
    } else if (existingTcins?.length !== modifiedTcins?.length) {
      setIsMetadataUnsaved(true)
      modifiedTcins && updateMetadata('tcins', modifiedTcins)
    } else if (
      existingTcins?.length === modifiedTcins?.length &&
      uniq(modifiedTcins).length !== modifiedTcins.length
    ) {
      setIsMetadataUnsaved(true)
      modifiedTcins && updateMetadata('tcins', uniq(modifiedTcins))
    } else {
      setIsMetadataUnsaved(false)
    }
  }, [productInformation])

  const addProductRow = () => {
    const emptyRow = {
      dpci: '',
      tcin: '',
      brandName: '',
      productDescription: '',
    }
    setProductInformation(() => {
      const productInfoTemp = [...productInformation]
      productInfoTemp.unshift(emptyRow)
      return productInfoTemp
    })
  }

  const deleteProductRow = (identifier) => () => {
    setProductInformation(() => {
      const productInfoTemp = [...productInformation]
      productInfoTemp.splice(identifier, 1)
      return productInfoTemp
    })
  }

  const handleChangeProductRow = (fieldName, identifier) => (event) => {
    // handle changes on the DPCI or TCIN input field
    const inputValue = event.target.value || ''
    setProductInformation(() => {
      let updatedRow = {}
      const productInfoTemp = [...productInformation]
      updatedRow[fieldName] = inputValue
      if (inputValue === '') {
        updatedRow['isError'] = false
        updatedRow['isSystemError'] = false
      }
      productInfoTemp[identifier] = Object.assign(
        {},
        { ...productInfoTemp[identifier] },
        updatedRow,
      )
      return productInfoTemp
    })
  }

  const handleFetchProductData = (fieldName, identifier) => async (event) => {
    if (ALLOWED_ACTION_KEYS.indexOf(event.keyCode) !== -1) {
      const inputValue = event.target.value || ''
      if (inputValue) {
        let keyword = ''
        if (fieldName === 'tcin') {
          keyword = inputValue.match(tcinRegex)
        } else if (fieldName === 'dpci') {
          keyword =
            inputValue.match(dpciWithHyphenRegex) ||
            inputValue.match(dpciWithoutHyphenRegex)
        }
        if (keyword) {
          toggleProductLoader(identifier, true)
          const response = await fetchProductDetailsApi(fieldName, keyword)
          if (response) {
            const { data = {} } = response
            appendProductInformation(data, identifier)
          }
        } else {
          setProductInformation(() => {
            const productInfoTemp = [...productInformation]
            productInfoTemp[identifier]['isError'] = true
            return productInfoTemp
          })
        }
      }
    }
    return false
  }

  const toggleProductLoader = (identifier, value = false) => {
    setProductInformation(() => {
      const productInfoTemp = [...productInformation]
      productInfoTemp[identifier]['showProgress'] = value
      return productInfoTemp
    })
  }

  const appendProductInformation = (productData = {}, identifier = '') => {
    let updatedRow = {}
    if (!isEmpty(productData)) {
      const {
        dpci = '',
        product_brand: { brand = '' } = {},
        product_description = {},
        barcode = '',
        tcin = '',
      } = productData
      updatedRow = {
        dpci,
        tcin,
        brand,
        product_description,
        barcode,
        showProgress: false,
        isError: false,
        isSystemError: false,
      }
    } else {
      updatedRow = {
        showProgress: false,
        isError: true,
        isSystemError: false,
      }
    }
    startTransition(() => {
      setIsMetadataUnsaved(true)
      setProductInformation(() => {
        const productInfoTemp = [...productInformation]
        productInfoTemp[identifier] = Object.assign(
          {},
          { ...productInfoTemp[identifier] },
          updatedRow,
        )
        return productInfoTemp
      })
    })
  }

  return (
    <CollapsibleCard
      cardTitle={'Product Information'}
      hideButtonText={METADATA_CARD_HIDE_BUTTON_TEXT}
      showButtonText={METADATA_CARD_SHOW_BUTTON_TEXT}
      defaultCollapse={true}
    >
      <div className={containerClasses.metadataCardActionWrapper}>
        {isEditMode && (
          <IconButton
            data-cy="addProduct"
            onClick={addProductRow}
            aria-label="Add Row"
            disabled={!isEditMode}
            size="large"
          >
            <AddIcon />
          </IconButton>
        )}
      </div>
      <Table className={containerClasses.metadataTable}>
        <TableBody>
          {productInformation?.length > 0 &&
            productInformation.map((productInfoItem, key) => (
              <TableRow key={key} className={containerClasses.metadataTableRow}>
                <TableCell className={containerClasses.metadataTableCell}>
                  <div className={containerClasses.metadataTableCellAction}>
                    {isEditMode && (
                      <>
                        {productInfoItem.showProgress && (
                          <CircularProgress
                            className={containerClasses.progressSpinner}
                          />
                        )}
                        {!productInfoItem.showProgress &&
                          !productInfoItem.isError &&
                          !productInfoItem.isSystemError && (
                            <div
                              data-cy="deleteProduct"
                              className={
                                containerClasses.metadataTableCellActionBtn +
                                ' actionBtnHover'
                              }
                              aria-label="Delete Row"
                              onClick={deleteProductRow(key)}
                            >
                              <DeleteIcon color="inherit" />
                            </div>
                          )}
                        {!productInfoItem.showProgress &&
                          (productInfoItem.isError ||
                            productInfoItem.isSystemError) && (
                            <span className="error">
                              <ErrorIcon />
                            </span>
                          )}
                      </>
                    )}
                  </div>
                </TableCell>
                <TableCell className={containerClasses.metadataTableCell}>
                  <FormControl
                    variant="standard"
                    className={containerClasses.formControl}
                    disabled={!isEditMode}
                    error={
                      productInfoItem.isError || productInfoItem.isSystemError
                    }
                  >
                    <InputLabel htmlFor={`urTcin${key}`}>TCIN</InputLabel>
                    <Input
                      id={`urTcin${key}`}
                      onChange={handleChangeProductRow('tcin', key)}
                      onKeyDown={handleFetchProductData('tcin', key)}
                      value={productInfoItem.tcin}
                      inputProps={{ 'data-cy': 'tcinInput' }}
                    />
                    {isEditMode && !productInfoItem.isError && (
                      <FormHelperText>{METADATA_LOOKUP_TEXT}</FormHelperText>
                    )}
                  </FormControl>
                </TableCell>
                <TableCell className={containerClasses.metadataTableCell}>
                  <FormControl
                    variant="standard"
                    className={containerClasses.formControl}
                    disabled={!isEditMode}
                    error={
                      productInfoItem.isError || productInfoItem.isSystemError
                    }
                  >
                    <InputLabel htmlFor={`urDpci${key}`}>DPCI</InputLabel>
                    <Input
                      id={`urDpci${key}`}
                      onChange={handleChangeProductRow('dpci', key)}
                      onKeyDown={handleFetchProductData('dpci', key)}
                      value={productInfoItem.dpci}
                      inputProps={{ 'data-cy': 'dcpiInput' }}
                    />
                    {isEditMode && !productInfoItem.isError && (
                      <FormHelperText>{METADATA_LOOKUP_TEXT}</FormHelperText>
                    )}
                    {productInfoItem.isError && (
                      <FormHelperText className={containerClasses.errorStyle}>
                        {METADATA_LOOKUP_PRODUCT_ERROR}
                      </FormHelperText>
                    )}
                    {productInfoItem.isSystemError && (
                      <FormHelperText className={containerClasses.errorStyle}>
                        {METADATA_LOOKUP_ERROR}
                      </FormHelperText>
                    )}
                  </FormControl>
                </TableCell>
                <TableCell className={containerClasses.metadataTableCell}>
                  <FormControl
                    variant="standard"
                    className={containerClasses.formControl}
                    disabled
                  >
                    <InputLabel htmlFor={`urBrandName${key}`} shrink>
                      Brand Name
                    </InputLabel>
                    <div className={containerClasses.formStaticWrap}>
                      <span
                        data-cy="productBrandName"
                        id={`urBrandName${key}`}
                        className={containerClasses.formStaticText}
                      >
                        {productInfoItem.brand}
                      </span>
                    </div>
                  </FormControl>
                </TableCell>
                <TableCell className={containerClasses.metadataTableCell}>
                  <FormControl
                    variant="standard"
                    className={containerClasses.formControl}
                    disabled
                  >
                    <InputLabel htmlFor={`urProductDescription${key}`} shrink>
                      Product Description
                    </InputLabel>
                    <div className={containerClasses.formStaticWrap}>
                      <span
                        data-cy="productDescription"
                        id={`urProductDescription${key}`}
                        className={containerClasses.formStaticText}
                      >
                        {productInfoItem.product_description?.title || ''}
                      </span>
                    </div>
                  </FormControl>
                  <FormControl
                    variant="standard"
                    className={containerClasses.formControl}
                    disabled
                  >
                    <InputLabel htmlFor={`urBarcode${key}`} shrink>
                      Barcode
                    </InputLabel>
                    <div className={containerClasses.formStaticWrap}>
                      <span
                        data-cy="productBarcode"
                        id={`urBarcode${key}`}
                        className={containerClasses.formStaticText}
                      >
                        {productInfoItem?.barcode}
                      </span>
                    </div>
                  </FormControl>
                </TableCell>
              </TableRow>
            ))}
          {productInformation?.length === 0 && (
            <TableRow>
              <TableCell>
                <div className={containerClasses.noResults}>
                  No details available
                </div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </CollapsibleCard>
  )
}

export default ProductInfoCard
