import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@mui/styles'
import { IconButton } from '@mui/material'
import InputBase from '@mui/material/InputBase'
import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import { Apps } from '@mui/icons-material'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'

import AssetCard from './AssetCard'
import AssetListItem from './AssetListItem'
import { assetPropType } from '../../constants/annotation'
import {
  setPrimaryAsset,
  setSecondaryAsset,
  fetchAssetVersions,
} from '../../store/annotation/actions'
import { caseInsensitiveIncludes } from '../../helpers/stringHelper'
import withRouter from '../../containers/Router/WithRouter'

const buttonBlue = '#188295'
const buttonGrey = '#EFEFEF'
const gray = '#666666'

const styles = {
  root: {
    paddingLeft: '16px',
  },
  assetsAndButtons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  fieldAndButtons: {
    display: 'flex',
    justifyContent: 'space-around',
    padding: '0 10px',
    margin: '16px 0',
  },
  button: {
    borderRadius: '2px',
    border: `1px solid ${buttonBlue}`,
    padding: 0,
    margin: '16px 16px 16px 0',
  },
  assetListItem: {
    padding: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0 16px 16px 16px',
    cursor: 'pointer',
  },
  input: {
    marginLeft: 8,
    flex: 1,
    marginTop: 4,
  },

  iconButton: {
    color: `${gray}`,
    fontSize: 14,
    padding: '4px 4px 4px 14px',
    borderRadius: 0,
    float: 'right',
    margin: '4px',
    '&:hover': {
      background: 'none',
    },
  },
  inputWrapper: {
    border: '2px solid #a5a0a0',
    display: 'inline-block',
    boxSizing: 'border-box',
    borderRadius: 3,
    background: 'none',
    width: '90%',
    margin: '8px 16px 16px 16px',
    borderRadius: '4px',
    background: 'white',
  },
  focussed: {
    border: `1px solid ${gray}`,
  },

  iconCloseOrSearch: { color: `${gray}` },
}

export const AssetsSidebar = ({
  assets = [],
  classes = {},
  setPrimaryAsset = () => {},
  setSecondaryAsset = () => {},
  primaryAsset = {},
  secondaryAsset = {},
  isSecondary = false,
  refDict = {},
  history = {},
  match = {},
  router = {},
  assetUnqIdUrl = '',
  removeAssetUnqIdUrl = () => {},
  fetchAssetVersions = () => {},
  setToFitToScreen = () => {},
}) => {
  const { navigate = () => {}, params = {} } = router
  const [isListView, setIsListView] = useState(false)
  const [filterField, setFilterField] = useState('')
  const handleClickOnListAsset = (asset) => {
    navigate(
      `/annotator/${params.projectId}/${params.jobId}/assetUniqueId/${asset.asset_master_id}/${asset.asset_version}/1`
    )
    setPrimaryAsset(asset, true, asset.asset_version, 1, () => {
      fetchAssetVersions({
        assetMasterId: asset.asset_master_id,
        isSecondary: false,
      })
    })
    removeAssetUnqIdUrl()
    setToFitToScreen()
  }

  const handleClickOnAssetCard = (
    assetItem,
    currentVersion = 1,
    pageNum = 1
  ) => {
    if (assetUnqIdUrl !== assetItem.asset_master_id) {
      navigate(
        `/annotator/${params.projectId}/${params.jobId}/assetUniqueId/${assetItem.asset_master_id}/${currentVersion}/1`
      )
      setPrimaryAsset(assetItem, true, parseInt(currentVersion), pageNum)
      removeAssetUnqIdUrl()
    }
  }

  const getIdAndSetData = () => {
    const pathName = window.location.pathname
    const stringArr = pathName.split('/')
    let assetUnqId = ''
    if (stringArr.length > 7) {
      assetUnqId = stringArr[5] // extracting asset master id from URL
    }
    return assetUnqId
  }

  const handleSetCurrentAsset = (
    assetItem,
    currentVersion = 1,
    pageNum = 1
  ) => {
    const { asset_master_id: assetMasterId = null } = assetItem
    const assetIdFromUrl = getIdAndSetData()
    if (assetMasterId !== assetIdFromUrl) {
      handleClickOnAssetCard(assetItem, currentVersion, pageNum)
      setToFitToScreen()
    }
  }

  return (
    <div>
      <div className={classes.assetsAndButtons}>
        <h3 className={classes.root}>{assets.length} Assets</h3>
        <div>
          <IconButton
            className={classes.button}
            onClick={() => setIsListView(true)}
            style={{ backgroundColor: !isListView ? buttonGrey : buttonBlue }}
            size="large"
          >
            <FormatListBulletedIcon
              style={{
                color: isListView ? buttonGrey : buttonBlue,
                width: '22px',
                height: '22px',
              }}
            />
          </IconButton>
          <IconButton
            className={classes.button}
            onClick={() => setIsListView(false)}
            style={{ backgroundColor: isListView ? buttonGrey : buttonBlue }}
            size="large"
          >
            <Apps
              style={{
                color: !isListView ? buttonGrey : buttonBlue,
                width: '22px',
                height: '22px',
              }}
            />
          </IconButton>
        </div>
      </div>
      {
        <div className={classes.inputWrapper}>
          <InputBase
            className={classes.input}
            placeholder="Search"
            value={filterField}
            onChange={(ev) => {
              setFilterField(ev.target.value)
            }}
          />
          <IconButton
            onClick={() => {
              setFilterField('')
            }}
            className={classes.iconButton}
            aria-label="Search"
            size="large"
          >
            {' '}
            {filterField !== '' ? (
              <CloseIcon className={classes.iconCloseOrSearch} />
            ) : (
              <SearchIcon className={classes.iconCloseOrSearch} />
            )}
          </IconButton>
        </div>
      }
      {isListView ? (
        <div style={{ textAlign: 'left' }}>
          {assets
            .filter((x) => caseInsensitiveIncludes(x.asset_name, filterField))
            .map((asset) => (
              <AssetListItem
                forwaredRef={refDict[asset.asset_master_id]}
                key={asset.asset_master_id}
                asset={asset}
                primaryAsset={primaryAsset}
                secondaryAsset={secondaryAsset}
                classes={classes}
                isSecondary={isSecondary}
                versionNumber={1}
                setCurrentAsset={
                  !isSecondary
                    ? () => {
                        handleClickOnListAsset(asset)
                      }
                    : () => {
                        setSecondaryAsset(asset)
                        setToFitToScreen()
                      }
                }
              />
            ))}
        </div>
      ) : (
        <div style={{ textAlign: 'center' }}>
          {assets
            .filter((x) => caseInsensitiveIncludes(x.asset_name, filterField))
            .map((asset) => (
              <AssetCard
                forwaredRef={refDict[asset.asset_master_id]}
                key={asset.asset_master_id}
                asset={asset}
                primaryAsset={primaryAsset}
                secondaryAsset={secondaryAsset}
                isSecondary={isSecondary}
                assetUnqIdUrl={assetUnqIdUrl}
                removeAssetUnqIdUrl={removeAssetUnqIdUrl}
                setCurrentAsset={
                  !isSecondary
                    ? (assetItem, currentVersion, pageNum) => {
                        handleSetCurrentAsset(
                          assetItem,
                          currentVersion,
                          pageNum
                        )
                      }
                    : () => {
                        setSecondaryAsset(asset)
                        setToFitToScreen()
                      }
                }
                setRevisionNumberInurl={(revNum) => {
                  navigate(
                    `/annotator/${params.projectId}/${params.jobId}/assetUniqueId/${asset.asset_master_id}/${revNum}/1`
                  )
                }}
                setPageNumberInUrl={(pageNum, currVersion) => {
                  navigate(
                    `/annotator/${params.projectId}/${params.jobId}/assetUniqueId/${asset.asset_master_id}/${currVersion}/${pageNum}`
                  )
                }}
              />
            ))}
        </div>
      )}
    </div>
  )
}

AssetsSidebar.propTypes = {
  classes: PropTypes.object,
  setCurrentAsset: PropTypes.func,
  assets: PropTypes.arrayOf(assetPropType),
  primaryAsset: assetPropType,
  secondaryAsset: assetPropType,
  setPrimaryAsset: PropTypes.func,
  setSecondaryAsset: PropTypes.func,
  isSecondary: PropTypes.bool,
  refDict: PropTypes.arrayOf(PropTypes.object),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  assetUnqIdUrl: PropTypes.string,
  removeAssetUnqIdUrl: PropTypes.func,
  fetchAssetVersions: PropTypes.func,
  setToFitToScreen: PropTypes.func,
}

const mapStateToProps = (state = {}) => {
  const { annotation = {} } = state
  const { primaryAsset, secondaryAsset } = annotation

  return {
    primaryAsset,
    secondaryAsset,
    annotation,
  }
}

export default connect(mapStateToProps, {
  setPrimaryAsset,
  setSecondaryAsset,
  fetchAssetVersions,
})(withStyles(styles)(withRouter(AssetsSidebar)))
