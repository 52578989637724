import React from 'react'
import ReactDOM from 'react-dom/client'
import { unstable_HistoryRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import 'typeface-roboto'
import { EnvProvider } from '@praxis/component-runtime-env'
import store from './store/configureStore'
import './styles/index.css'
import App from './components/App'
import apiConfig from './config/apiConfig'
import history from './history'

const rootEl = document.getElementById('root')
// TODO: Consider removing this someday or find a less dirty way of debugging in production.
window.__STORE__ = store

const root = ReactDOM.createRoot(document.getElementById('ahroot'))
root.render(
  <Provider store={store}>
    <EnvProvider
      commonConfig={apiConfig}
      configPath={
        process.env.REACT_APP_ENV === 'dev'
          ? '/config.json'
          : '/app-environment'
      }
    >
      <Router history={history}>
        <App />
      </Router>
    </EnvProvider>
  </Provider>
)

if (module.hot) {
  module.hot.accept('./components/App', () => {
    const NextApp = require('./components/App').default

    const root = ReactDOM.createRoot(document.getElementById('ahroot'))
    root.render(
      <Provider store={store}>
        <NextApp />
      </Provider>
    )
  })
}
