import * as React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { Delete } from '@mui/icons-material'

const styles = makeStyles((theme) => ({
  button: {
    fontSize: '12px',
    color: 'white',
    backgroundColor: useTheme().palette.primary.bostonRed,
  },
  cancelButton: {
    fontSize: '12px',
    color: 'blue',
    marginLeft: '10px',
    backgroundColor: useTheme().palette.primary.lightestGrey,
    '&:hover': {
      backgroundColor: useTheme().palette.primary.lightestGrey,
    },
  },
}))

export const DeleteBoardDialog = ({
  classes = {},
  open = false,
  board = {},
  close = () => {},
  deleteBoard = () => {},
}) => (
  <Dialog
    open={open}
    onClose={() => close()}
    scroll="paper"
    aria-labelledby="scroll-dialog-title"
    fullWidth
  >
    <DialogTitle id="alert-dialog-title">
      Delete Board: {board.board_name}
    </DialogTitle>
    <DialogContent style={{ width: 520 }}>
      All references to this board will be removed.
    </DialogContent>
    <DialogActions>
      <div>
        <Button
          data-cy="deleteBoardButton"
          variant="contained"
          className={classes.button}
          onClick={() => deleteBoard(board.board_id)}
        >
          <Delete />
          Delete Board
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => close()}
          className={classes.cancelButton}
        >
          Cancel
        </Button>
      </div>
    </DialogActions>
  </Dialog>
)

DeleteBoardDialog.propTypes = {
  deleteBoard: PropTypes.func,
  classes: PropTypes.object,
  open: PropTypes.bool,
  board: PropTypes.shape({
    board_id: PropTypes.string,
    board_name: PropTypes.string,
  }),
  close: PropTypes.func,
}

const MyComponent = (props) => {
  const classes = styles()
  return <DeleteBoardDialog {...props} classes={classes} />
}

export default MyComponent
