import { makeStyles } from '@mui/styles'
import cx from 'classnames'
import { Button, Drawer } from '@mui/material'
import { ChevronRight } from '@mui/icons-material'

const useStyles = makeStyles(() => ({
  sidebar: {
    position: 'absolute',
    padding: '0 8px',
    right: '0',
    height: '100%',
    overflowY: 'auto',
    backgroundColor: '#EFEFEF',
  },
  sidebarHeader: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonStyle: {
    color: '#0D46A0',
  },
  buttonTextStyle: {
    marginRight: '-5px',
  },
  buttonIcon: {
    color: '#484848',
    fontSize: '36px',
    height: '34px',
  },
}))

const ImageSidebar = ({
  closeButtonText = 'HIDE',
  closeHandler = () => {},
  customStyles = {},
  isOpen = false,
  children,
}) => {
  const classes = useStyles()
  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="right"
      open={isOpen}
      classes={{
        paper: cx(classes.sidebar, customStyles),
      }}
    >
      <div className={classes.sidebarHeader}>
        <Button className={classes.buttonStyle} onClick={closeHandler}>
          <span className={classes.buttonTextStyle}>{closeButtonText}</span>
          <span className={classes.buttonIcon}>
            <ChevronRight fontSize="inherit" />
          </span>
        </Button>
      </div>
      {children}
    </Drawer>
  )
}

export default ImageSidebar
