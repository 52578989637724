import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { connect } from 'react-redux'
import { withStyles } from '@mui/styles'
import { Button, Avatar } from '@mui/material'
import { Visibility, KeyboardArrowLeft } from '@mui/icons-material'
import {
  selectJobId,
  selectAssetVersion,
} from '../../store/annotation/selectors'
import apiConfig from '../../config/apiConfig'
import {
  getUniqueViewedUsers,
  parseNameString,
} from '../../helpers/annotationHelper'
import { praxis } from '../../config/themeConfig'

const KEY = apiConfig.key

const styles = {
  button: {
    margin: '24px 10px',
  },
  avatar: {
    backgroundColor: praxis.themePrimary,
    height: '25px',
    width: '25px',
  },
  initials: {
    fontSize: '12px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  flexControl: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  text: {
    marginLeft: '10px',
    fontSize: '12px',
  },
  icon: {
    height: '16px',
    width: '16px',
  },
}

export const ViewedBySidebar = ({
  onToggleShowViewed = () => {},
  classes = {},
  viewedUsersUrl = '',
  onUnmount = () => {},
}) => {
  const [viewedUsers, setViewedUsers] = useState([])

  useEffect(() => {
    async function fetchViewedUsers() {
      const res = await axios.get(viewedUsersUrl)
      const uniqueUsers = getUniqueViewedUsers(res.data)
      setViewedUsers(uniqueUsers)
    }

    fetchViewedUsers()

    return function cleanup() {
      onUnmount()
    }
  }, [])

  const users = viewedUsers.map((userName = '.') => parseNameString(userName))

  return (
    <div>
      <Button className={classes.button} onClick={onToggleShowViewed}>
        <KeyboardArrowLeft /> Back To Comments
      </Button>
      <div className={classes.flexControl}>
        <p>Viewed By</p>
        <p>Status</p>
      </div>
      {users.map((user, i) => (
        <div
          key={i}
          className={classes.flexControl}
          style={{
            backgroundColor: '#F7F7F7',
            padding: '16px',
            marginBottom: '16px',
          }}
        >
          <div className={classes.header}>
            <Avatar aria-label="Avatar Image" className={classes.avatar}>
              <span className={classes.initials}>{user.initials}</span>
            </Avatar>
            <span className={classes.text}>{user.displayName}</span>
          </div>
          <div className={classes.flexControl}>
            <Visibility className={classes.icon} />{' '}
            <span className={classes.text}>Viewed</span>
          </div>
        </div>
      ))}
    </div>
  )
}

ViewedBySidebar.propTypes = {
  onToggleShowViewed: PropTypes.func,
  viewedUsersUrl: PropTypes.string,
  classes: PropTypes.object,
  isSecondary: PropTypes.bool,
  onUnmount: PropTypes.func,
}

const mapStateToProps = (state = {}, props) => {
  const jobId = selectJobId(state)
  const assetId = selectAssetVersion(props.isSecondary)(state).asset_id
  const viewedUsersUrl = `${apiConfig.annotation.taskPreviewUrl}/users/${jobId}/${assetId}?key=${KEY}`

  return {
    viewedUsersUrl,
  }
}

export default connect(mapStateToProps)(withStyles(styles)(ViewedBySidebar))
