import React from 'react'
import axios from 'axios'
import queryString from 'query-string'
import { debounce } from 'lodash'
import AutocompleteFormField from './AutocompleteFormField'
import apiConfig from '../../config/apiConfig'

const KEY = apiConfig.key
const BRANDS_URL = apiConfig.projects.brandsUrl

export class BrandsFormField extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      brandSuggestions: [],
    }
    this.debouncedSearch = debounce(this.brandSearch, 500)
  }

  brandSearch = async (value) => {
    const query = queryString.stringify({
      key: KEY,
      brand_name: value,
    })
    const promise = await axios.get(`${BRANDS_URL}?${query}`)
    const res = await promise.data
    const brandSuggestions = res.brand.map(({ brand_name = '' }) => brand_name)
    this.setState({ brandSuggestions })
  }

  handleInputChange = (event) => {
    this.debouncedSearch(event.target.value)
  }

  render() {
    const { brandSuggestions } = this.state
    return (
      <AutocompleteFormField
        suggestions={brandSuggestions}
        optionalOnInputChange={this.handleInputChange}
        {...this.props}
      />
    )
  }
}

export default BrandsFormField
