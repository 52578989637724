import { AD_SUPER_ADMIN, AD_LIBRARIAN } from '../../constants/adGroups'

export const checkIsSuperAdmin = (memberOf = []) =>
  memberOf.length > 0 && memberOf.indexOf(AD_SUPER_ADMIN) > -1

export const checkIsLibrarian = (memberOf = []) =>
  memberOf.length > 0 && memberOf.indexOf(AD_LIBRARIAN) > -1

export const formatUserInfo = (sessionInfo = {}) => {
  const { identityToken: xIdToken = '', userInfo = {} } = sessionInfo || {}
  const {
    accessToken = '',
    company = '',
    email = '',
    firstName = '',
    fullName = '',
    lanId = '',
    lastName = '',
    memberOf = [],
    title = '',
    displayname = '',
  } = userInfo
  const isAdGroupSuperAdmin = checkIsSuperAdmin(memberOf)
  const isLibrarian = checkIsLibrarian(memberOf)
  return {
    email,
    firstName,
    fullName,
    lanId,
    lastName,
    memberOf,
    title,
    company,
    accessToken,
    xIdToken,
    isAdGroupSuperAdmin,
    isLibrarian,
    displayName: displayname ? displayname : `${firstName}.${lastName}`,
  }
}
