import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { withStyles } from '@mui/styles'
import { Card, CardContent, CardActions, Avatar, Button } from '@mui/material'
import { Comment } from '@mui/icons-material'

import MentionBox from './MentionBox'
import { createNewComment } from '../../store/annotation/actions'
import { getEmailsFromText } from '../../helpers/annotationHelper'
import { validateStringMinMax } from '../../helpers/stringHelper'
import { praxis } from '../../config/themeConfig'

const styles = {
  avatar: {
    backgroundColor: praxis.themePrimary,
    height: '30px',
    width: '30px',
  },
  initials: {
    fontSize: '12px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  name: {
    marginLeft: '10px',
    fontSize: '12px',
  },
  textField: {
    border: '1px solid #333333',
    padding: '10px',
    width: '230px',
    marginTop: '10px',
    resize: 'none',
    fontFamily: 'Roboto',
    fontSize: '12px',
    height: '60px',
  },
  spacing: {
    display: 'flex',
    justifyContent: 'center',
  },
  marker: {
    width: '35px',
    height: '35px',
    color: 'white',
    top: '17px',
    left: '45%',
    display: 'flex',
    position: 'relative',
    borderRadius: '50%',
    backgroundColor: praxis.lighterBlue,
    alignItems: 'center',
    justifyContent: 'center',
    border: '2px solid white',
  },
  charCounter: {
    margin: '5px',
    textAlign: 'end',
    fontSize: '10px',
  },
}

export class NewCommentBox extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      textField: '',
    }
  }

  editTextField = (event) => {
    this.setState({ textField: event.target.value })
  }

  submitNewComment = () => {
    const {
      createNewComment = () => {},
      closeNewComment = () => {},
      numberOfMarkups = 0,
      isSecondary = false,
      assetInfo = {},
    } = this.props
    const { textField } = this.state

    createNewComment({
      comment_text: textField,
      number: numberOfMarkups + 1,
      isSecondary,
      toAddress: getEmailsFromText(textField),
      asset_name: assetInfo.asset_name,
    })
    this.setState({ textField: '' })
    closeNewComment()
  }

  render() {
    const {
      auth = {},
      classes = {},
      closeNewComment = () => {},
      isMarkupComment = false,
      numberOfMarkups = 0,
    } = this.props
    const { textField } = this.state
    const { firstName, lastName } = auth
    const initials = `${firstName[0]}${lastName[0]}`
    return (
      <Fragment>
        {isMarkupComment && (
          <div className={classes.marker}>{numberOfMarkups + 1}</div>
        )}
        <Card style={{ marginBottom: '10px' }}>
          <CardContent style={{ padding: '8px 8px 0 8px' }}>
            <div className={classes.header}>
              <Avatar aria-label="Avatar Image" className={classes.avatar}>
                <span className={classes.initials}>{initials}</span>
              </Avatar>
              <span className={classes.name}>
                {firstName} {lastName}
              </span>
            </div>
            <MentionBox text={textField} onTextChanged={this.editTextField} />
            <div className={classes.charCounter}>
              Chars: {textField.length}/999
            </div>
          </CardContent>
          <CardActions className={classes.spacing}>
            <Button onClick={closeNewComment}>CANCEL</Button>
            <Button
              data-cy="addCommentButton"
              variant="contained"
              color="secondary"
              type="submit"
              onClick={this.submitNewComment}
              disabled={!validateStringMinMax(1, 999)(textField)}
            >
              <Comment />
              <span style={{ marginLeft: '8px' }}>ADD COMMENT</span>
            </Button>
          </CardActions>
        </Card>
      </Fragment>
    )
  }
}

NewCommentBox.propTypes = {
  auth: PropTypes.object,
  classes: PropTypes.object,
  assetInfo: PropTypes.object,
  createNewComment: PropTypes.func,
  closeNewComment: PropTypes.func,
  numberOfMarkups: PropTypes.number,
  isMarkupComment: PropTypes.bool,
  isSecondary: PropTypes.bool,
}

const mapStateToProps = (state) => {
  const { auth } = state
  return {
    auth,
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createNewComment,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(NewCommentBox))
