import React, { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import PropTypes from 'prop-types'

import { withStyles } from '@mui/styles'
import {
  GetApp,
  Image,
  ViewAgenda,
  Create,
  Delete,
  AddTask,
  ToggleOff,
} from '@mui/icons-material'
import { Button, IconButton, Tooltip } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import PhotoAlbumIcon from '@mui/icons-material/PhotoAlbum'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { isEmpty } from 'lodash'

import {
  ADD_METADATA,
  ADD_METADATA_TITLE,
  BOARDS_TITLE,
  CLOSE_BOARD_WINDOW,
  CLOSE_METADATA,
  DOWNLOAD_JPEG_VERSION,
  DOWNLOAD_ORIGINAL_VERSION,
  OPEN_BOARD_WINDOW,
  SET_AS_PROJECT_COVER_IMAGE,
} from '../../constants/search'
import apiConfig from '../../config/apiConfig'
import { showNotification } from '../../store/notification/actionCreator'
import { appendQueryParams } from '../../helpers/UrlHelper'
import {
  BetaAnalytics,
  trackBetaSearchAnalytics,
} from '../../analytics/betaSearch'

const styles = {
  bulkActionBar: {
    minHeight: '50px',
    backgroundColor: '#178295',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    color: 'white',
    paddingLeft: '33px',
    zIndex: 1199,
  },
  buttonProgress: {
    color: 'white',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  iconButtonClass: {
    fontSize: '11px',
    color: 'white',
    backgroundColor: '#188295',
    '&:hover': {
      backgroundColor: '#115E6C',
    },
  },
  bulkActionBarDiv: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  clearSelectionBut: {
    fontSize: '11px',
    color: '#188295',
    border: '1px solid #188295',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: '#115E6C',
    },
    float: 'left',
  },
  paddingLeft: {
    paddingLeft: '4px',
  },
  assetSelectedDiv: {
    display: 'flex',
    alignItems: 'center',
  },
  assetSelectedText: {
    fontSize: '14px',
    padding: '12px',
    float: 'left',
    marginLeft: '54px',
  },
  verticalLine: {
    borderLeft: '1px solid #55a9a9',
    display: 'inline',
  },
  bulkActionBarToggle: {
    width: '18px',
  },
}

export const BulkActionBar = ({
  classes = {},
  downloadAssetType = DOWNLOAD_ORIGINAL_VERSION,
  isDownloadInitiated,
  selectedAssets = {},
  noOfSelectedItems,
  onDeleteAssets,
  onStatusChange,
  isWipSelected,
  onRemoveAssets,
  onDownloadClicked,
  onDownloadMetadata,
  toggleRightSidenav,
  isToggleRightSidenav,
  rightSideNavComponent,
  enableBoardsDialog = true,
  enableRemoveAssetsFromBoard = false,
  enableSetCoverImage = false,
  isAdminOrLibrarian,
  enableDeleteAsset,
  isMetadataDownloadInitiated,
  closeBulkActionBar = () => {},
  handleSetProjectCoverImg = () => {},
  onClearSelection = () => {},
}) => {
  const dispatch = useDispatch()

  const authData = useSelector((state = {}) => state.auth) || {}
  const { displayName = '' } = authData
  const onEnableGptTesting = () => {
    if (!isEmpty(selectedAssets) && Object.values(selectedAssets)?.length) {
      let assetPayload = Object.values(selectedAssets).map((asset) => ({
        asset_id: asset?.assetId,
        file_name: asset?.fileName,
        url: appendQueryParams(
          asset?.render_urls?.optimized_asset_url ??
            asset?.render_urls?.preview_asset_url,
          { key: apiConfig.key },
        ),
      }))
      axios
        .post(
          `${apiConfig.genai.addAssetToGenAI}?user_id=${displayName}`,
          assetPayload,
        )
        .then((response) => {
          closeBulkActionBar()
          dispatch(
            showNotification(
              true,
              'Asset(s) Added to AI Testing Module',
              'success',
            ),
          )
          trackBetaSearchAnalytics({
            auth: authData,
            trackingData: {
              eventName: BetaAnalytics.event.AI_ENABLED,
              eventType: BetaAnalytics.event.ASSET_USAGE,
              eventData: {
                key: 'asset_details',
                value: JSON.stringify(assetPayload),
              },
            },
          })
        })
    }
  }
  return (
    <div className={classes.bulkActionBar} style={styles.bulkActionBarDiv}>
      <div style={styles.assetSelectedDiv}>
        {noOfSelectedItems > 1 ? (
          <span style={styles.assetSelectedText}>
            {' '}
            <span style={{ fontSize: '24px' }}>{noOfSelectedItems}</span> Assets
            selected{' '}
          </span>
        ) : (
          <span style={styles.assetSelectedText}>
            <span style={{ fontSize: '24px' }}>{noOfSelectedItems}</span> Asset
            selected
          </span>
        )}
        <Tooltip title="Clear Selection" placement="top">
          <Button
            variant={'outlined'}
            data-cy="clearSelectionButton"
            onClick={onClearSelection}
            startIcon={<HighlightOffIcon />}
            style={styles.clearSelectionBut}
          >
            Clear All
          </Button>
        </Tooltip>
      </div>
      <div>
        {isAdminOrLibrarian && (
          <Fragment>
            <IconButton
              id="ENABLE FOR GTP TESTING"
              className="remove-assets"
              data-cy="enableGptButton"
              onClick={() => onEnableGptTesting()}
              style={styles.iconButtonClass}
              variant="contained"
              size="large"
            >
              <AddTask style={{ width: '18px' }} />
              <span style={styles.paddingLeft}>ADD TO GPT TESTING</span>
            </IconButton>
            <div style={styles.verticalLine}></div>
          </Fragment>
        )}
        {enableDeleteAsset && (
          <Fragment>
            <IconButton
              className="delete-assets"
              data-cy="deleteAssetButton"
              onClick={onDeleteAssets}
              style={styles.iconButtonClass}
              id="DELETE ASSET(S)"
              variant="contained"
              size="large"
            >
              <Delete style={{ width: '18px' }} />
              <span style={styles.paddingLeft}>DELETE ASSET(S)</span>
            </IconButton>
          </Fragment>
        )}
        {isWipSelected && (
          <Fragment>
            <IconButton
              variant="contained"
              onClick={onStatusChange}
              size="large"
              style={styles.iconButtonClass}
            >
              <ToggleOff style={styles.bulkActionBarToggle} />
              <span style={styles.paddingLeft}>APPROVE ASSET(S)</span>
            </IconButton>
            <div style={styles.verticalLine}></div>
          </Fragment>
        )}
        {enableSetCoverImage && noOfSelectedItems === 1 && (
          <Fragment>
            <IconButton
              id={SET_AS_PROJECT_COVER_IMAGE}
              className="set-project-cover-img"
              data-cy="setCoverImage"
              onClick={handleSetProjectCoverImg}
              style={styles.iconButtonClass}
              variant="contained"
              size="large"
            >
              <PhotoAlbumIcon style={{ width: '18px' }} />
              <span style={styles.paddingLeft}>
                {SET_AS_PROJECT_COVER_IMAGE}
              </span>
            </IconButton>
            <div style={styles.verticalLine}></div>
          </Fragment>
        )}
        {enableRemoveAssetsFromBoard && (
          <Fragment>
            <IconButton
              id="REMOVE ASSET(S)"
              className="remove-assets"
              data-cy="removeAssetButton"
              onClick={onRemoveAssets}
              style={styles.iconButtonClass}
              variant="contained"
              size="large"
            >
              <Delete style={{ width: '18px' }} />
              <span style={styles.paddingLeft}>REMOVE ASSET(S) FROM BOARD</span>
            </IconButton>
            <div style={styles.verticalLine}></div>
          </Fragment>
        )}
        {enableBoardsDialog && (
          <Fragment>
            <IconButton
              className="boards-window"
              data-cy="openCloseBoardModal"
              onClick={() =>
                toggleRightSidenav(
                  BOARDS_TITLE,
                  isToggleRightSidenav &&
                    rightSideNavComponent !== BOARDS_TITLE,
                )
              }
              style={styles.iconButtonClass}
              id={
                isToggleRightSidenav && rightSideNavComponent === BOARDS_TITLE
                  ? CLOSE_BOARD_WINDOW
                  : OPEN_BOARD_WINDOW
              }
              variant="contained"
              size="large"
            >
              <ViewAgenda style={{ width: '18px' }} />
              <span style={styles.paddingLeft}>
                {isToggleRightSidenav && rightSideNavComponent === BOARDS_TITLE
                  ? CLOSE_BOARD_WINDOW
                  : OPEN_BOARD_WINDOW}
              </span>
            </IconButton>
            <div style={styles.verticalLine}></div>
          </Fragment>
        )}
        {isAdminOrLibrarian && (
          <Fragment>
            <IconButton
              className="bulk-edit-window"
              data-cy="addCloseMetadata"
              id={
                isToggleRightSidenav &&
                rightSideNavComponent === ADD_METADATA_TITLE
                  ? CLOSE_METADATA
                  : ADD_METADATA
              }
              onClick={() =>
                toggleRightSidenav(
                  ADD_METADATA_TITLE,
                  isToggleRightSidenav &&
                    rightSideNavComponent !== ADD_METADATA_TITLE,
                )
              }
              style={styles.iconButtonClass}
              variant="contained"
              size="large"
            >
              <Create style={{ width: '18px' }} />
              <span style={styles.paddingLeft}>
                {isToggleRightSidenav &&
                rightSideNavComponent === ADD_METADATA_TITLE
                  ? CLOSE_METADATA
                  : ADD_METADATA}
              </span>
            </IconButton>
            <div style={styles.verticalLine}></div>
          </Fragment>
        )}
        {isAdminOrLibrarian && (
          <Fragment>
            <IconButton
              className="download-metadata"
              onClick={onDownloadMetadata}
              id="DOWNLOAD METADATA"
              style={styles.iconButtonClass}
              variant="contained"
              size="large"
            >
              <GetApp style={{ width: '18px' }} />
              <span style={styles.paddingLeft}>DOWNLOAD METADATA</span>
              {isMetadataDownloadInitiated &&
                downloadAssetType === 'DOWNLOAD METADATA' && (
                  <CircularProgress size={24} style={styles.buttonProgress} />
                )}
            </IconButton>
            <div style={styles.verticalLine}></div>
          </Fragment>
        )}
        <IconButton
          className="download-jpeg-version"
          data-cy="downloadJpegButton"
          onClick={onDownloadClicked}
          style={styles.iconButtonClass}
          id={DOWNLOAD_JPEG_VERSION}
          variant="contained"
          size="large"
        >
          <Image style={{ width: '18px' }} />
          <span style={styles.paddingLeft}>{DOWNLOAD_JPEG_VERSION}</span>
          {isDownloadInitiated &&
            downloadAssetType === DOWNLOAD_JPEG_VERSION && (
              <CircularProgress size={24} style={styles.buttonProgress} />
            )}
        </IconButton>
        <div style={styles.verticalLine}></div>
        <IconButton
          className="download-original-version"
          data-cy="downloadOriginalButton"
          onClick={onDownloadClicked}
          style={styles.iconButtonClass}
          id={DOWNLOAD_ORIGINAL_VERSION}
          variant="contained"
          size="large"
        >
          <GetApp style={{ width: '18px' }} />
          {DOWNLOAD_ORIGINAL_VERSION}
          {isDownloadInitiated &&
            downloadAssetType === DOWNLOAD_ORIGINAL_VERSION && (
              <CircularProgress size={18} style={styles.buttonProgress} />
            )}
        </IconButton>
      </div>
    </div>
  )
}

BulkActionBar.propTypes = {
  classes: PropTypes.object,
  downloadAssetType: PropTypes.string,
  isDownloadInitiated: PropTypes.bool,
  onDownloadClicked: PropTypes.func,
  onStatusChange: PropTypes.func,
  isWipSelected: PropTypes.bool,
  onDeleteAssets: PropTypes.func,
  onRemoveAssets: PropTypes.func,
  toggleRightSidenav: PropTypes.func,
  isToggleRightSidenav: PropTypes.bool,
  rightSideNavComponent: PropTypes.string,
  noOfSelectedItems: PropTypes.number,
  isAdminOrLibrarian: PropTypes.bool,
  enableBoardsDialog: PropTypes.bool,
  enableDeleteAsset: PropTypes.bool,
  enableRemoveAssetsFromBoard: PropTypes.bool,
  onDownloadMetadata: PropTypes.func,
  isMetadataDownloadInitiated: PropTypes.bool,
  handleSetProjectCoverImg: PropTypes.func,
  enableSetCoverImage: PropTypes.bool,
  onClearSelection: PropTypes.func,
}

export default withStyles(styles)(BulkActionBar)
