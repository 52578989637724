import React from 'react'

import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import Fab from '@mui/material/Fab'
import MoreIcon from '@mui/icons-material/MoreHoriz'

import imageFallback from '../../images/ic_account_circle.png'

const styles = {
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'hidden',
    width: '100%',
    marginTop: 10,
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  participantIcon: {
    marginRight: '10px',
    height: '48px',
    width: '48px',
    borderRadius: '40px',
  },
  fab: {
    position: 'absolute',
    backgroundColor: '#FC3',
    color: '#FFF',
    boxShadow: 'none',
    height: 48,
    width: 48,
    left: '8%',
    fontSize: 16,
  },
  fabMore: {
    height: 48,
    width: 48,
    position: 'absolute',
    backgroundColor: '#EFEFEF',
    color: '#8D8D8D',
    boxShadow: 'none',
  },
}
const limitLength = (str, length) => str.substring(0, length)

const formAssetList = (assetList) => {
  let formedList = !assetList ? [] : [...assetList]
  let arrayLength = assetList.length <= 7 ? assetList.length : 7

  let newArray = []
  for (let i = 0; i < arrayLength; i++) {
    if (formedList[i]) {
      let newUserObj = { ...formedList[i] }
      let initialArr = newUserObj.display_name.split('.')
      newUserObj['initial'] =
        initialArr.length > 1
          ? limitLength(initialArr[0], 1) + limitLength(initialArr[1], 1)
          : limitLength(initialArr[0], 1)
      newArray.push(newUserObj)
    } else {
      newArray.push(imageFallback)
    }
  }
  return newArray
}

const AssetList = (obj) => {
  let userImage = formAssetList(obj.assets)
  let users = obj.assets
  return (
    <div style={styles.root}>
      <ImageList style={styles.gridList} cols={8}>
        {userImage.map((list, key) => (
          <ImageListItem
            style={{ width: 60, height: 60 }}
            key={key}
            data-cy="groupUser"
          >
            <Fab style={styles.fab}>{list.initial}</Fab>
          </ImageListItem>
        ))}
        {users.length > 6 && (
          <ImageListItem style={{ width: 60, height: 65 }}>
            <Fab style={styles.fabMore}>
              <MoreIcon />
            </Fab>
          </ImageListItem>
        )}
      </ImageList>
    </div>
  )
}
export default AssetList
