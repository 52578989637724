import { separateFilenameExtension } from './UtilityHelper'

export const getPage = (list, page, pageSize) => {
  let elementPage
  // handle first page
  if (page === 0) {
    elementPage = list.slice(0, pageSize)
  }
  const pageStart = page * pageSize
  // handle last page
  if (pageStart + pageSize > list.length) {
    elementPage = list.slice(pageStart)
  } else {
    elementPage = list.slice(pageStart, pageStart + pageSize)
  }

  return elementPage
}

export const formatAssetData = (assets) => {
  let restructuredAssets = []

  assets.forEach((asset) => {
    let restructuredAsset = {}
    restructuredAsset = {
      asset_id: asset.asset_id,
      render_urls: asset?.render_urls ?? {},
      uploaded_date: asset.import_date,
      metadata: {
        file_metadata: {
          file_name: asset.file_name,
          file_type: separateFilenameExtension(asset.file_name)
            ?.fileExt?.substring(1)
            ?.toUpperCase(),
        },
        usage_info: asset?.usage_info,
        creative_usage_instructions: asset?.creative_usage_instructions,
      },
    }
    restructuredAssets.push(restructuredAsset)
  })
  return restructuredAssets
}

export const isBoardNameExist = (boards = [], newBoardName = '') =>
  boards.findIndex((board = {}) => board.board_name === newBoardName) > -1

export const getBoardAdminUsersInfo = (
  userListBoard = [],
  loginUserId = '',
  updatedUserLoginId = ''
) => {
  const boardAdminUsers = userListBoard.filter(
    (boardUser = {}) => boardUser.role_id === 40
  )
  const loggedInAdminUser = boardAdminUsers.find(
    (boardUser = {}) => boardUser.login_id === updatedUserLoginId
  )
  const isBoardAdmin =
    loginUserId === updatedUserLoginId && loggedInAdminUser ? true : false
  const isOnlyAdmin =
    isBoardAdmin && boardAdminUsers.length === 1 ? true : false
  return {
    isBoardAdmin,
    isOnlyAdmin,
  }
}

export const sortingHandleFunction = (nodesArray, sortByOption) => {
  let assetList = nodesArray.map((a) => ({ ...a }))
  if (sortByOption === 'asset_name') {
    assetList.sort((item1, item2) => {
      let textA = item1.file_name.toUpperCase()
      let textB = item2.file_name.toUpperCase()
      return textA < textB ? -1 : textA > textB ? 1 : 0
    })
  } else if (sortByOption === 'asset_name_asc') {
    assetList.sort((item1, item2) => {
      let textA = item1.file_name.toUpperCase()
      let textB = item2.file_name.toUpperCase()
      return textA > textB ? -1 : textA < textB ? 1 : 0
    })
  } else if (sortByOption === 'added_date') {
    assetList.sort((item1, item2) => {
      let date1 = new Date(item1.created_date)
      let date2 = new Date(item2.created_date)
      return date2 - date1
    })
  } else if (sortByOption === 'added_date_asc') {
    assetList.sort((item1, item2) => {
      let date1 = new Date(item1.created_date)
      let date2 = new Date(item2.created_date)
      return date1 - date2
    })
  } else if (sortByOption === 'asset_created') {
    assetList.sort((item1, item2) => {
      let date1 = new Date(item1.import_date)
      let date2 = new Date(item2.import_date)
      return date2 - date1
    })
  } else if (sortByOption === 'asset_created_asc') {
    assetList.sort((item1, item2) => {
      let date1 = new Date(item1.import_date)
      let date2 = new Date(item2.import_date)
      return date1 - date2
    })
  }
  return assetList
}
