import React, { useEffect, useState, startTransition } from 'react'
import { makeStyles } from '@mui/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { Button, Checkbox, Chip, Collapse, IconButton } from '@mui/material'
import ReplayIcon from '@mui/icons-material/Replay'
import CheckIcon from '@mui/icons-material/Check'
import ErrorIcon from '@mui/icons-material/Error'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import {
  getAssetStatus,
  retryProjectAssets,
} from '../../../../services/retryService'
import {
  getRetryRequestPayload,
  mergeLastUpdatedTimeToList,
  mergeFileNameToResponseList,
} from '../../../../helpers/ProjectsHelper'
import { convertUTCtoLocal } from '../../../../helpers/dateHelper'
import {
  ERROR_MESSAGE_LABEL,
  LAST_RETRIED_AT_LABEL,
  NO_ASSETS_FOUND_FOR_RETRY,
  NO_STATUS_FOUND_TEXT,
  RETRY_FAILED,
  RETRY_INITIATED,
  RETRY_IN_PROGRESS,
} from '../../../../constants/projects'

const useStyles = makeStyles({
  retryContainer: {
    padding: '20px',
  },
  retryLabel: {
    fontSize: '24px',
  },
  retryPreHead: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '50px',
  },
  retryMessage: {
    fontSize: '24px',
    color: 'red',
  },
  retryListGrid: {
    padding: '0px 0px 10px',
  },
  retryTableBody: {
    maxHeight: '500vh',
    overflow: 'scroll',
  },
  iconCol: {
    maxWidth: '5px',
    minWidth: '5px',
    border: 'none',
  },
  iconColHead: {
    maxWidth: '5px',
    minWidth: '5px',
  },
  fileName: {
    maxWidth: '120px',
    minWidth: '120px',
    wordWrap: 'break-word',
    border: 'none',
  },
  statusColHead: {
    maxWidth: '75px',
    minWidth: '75px',
    wordWrap: 'break-word',
  },
  statusCol: {
    maxWidth: '75px',
    minWidth: '75px',
    alignItems: 'center',
    wordWrap: 'break-word',
    border: 'none',
  },
  errorCol: {
    maxWidth: '75px',
    minWidth: '75px',
    wordWrap: 'break-word',
    border: 'none',
  },
  checkIcon: {
    color: 'white',
    backgroundColor: 'green',
    borderRadius: '22px',
  },
  retryDisableStyle: {
    color: 'red',
  },
  errorIcon: {
    color: 'white',
    backgroundColor: 'red',
    borderRadius: '22px',
  },
  retryCol: {
    maxWidth: '20px',
    border: 'none',
  },
  retryIcon: {
    color: 'red',
    cursor: 'pointer',
  },
  retryPad: {
    paddingLeft: '5px',
  },
  retryButtonDiv: {
    float: 'right',
    padding: '20px 0px',
  },
  legendGrid: {
    display: 'flex',
    justifyContent: 'end',
    gap: '20px',
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
  },
  footerGrid: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})

function RetryAssetView({
  allProjectAssets = [],
  parentClasses = {},
  projectName = '',
}) {
  const classes = useStyles()
  const unprocessedAssets = allProjectAssets.filter(
    (assetDetails) => assetDetails.status !== 'COMPLETE',
  )
  const [assetStatusList, setAssetStatusList] = useState([])
  const [selected, setSelected] = useState([])
  const [retryStateMessage, setRetryStateMessage] = useState('')

  const fetchAndUpdateAssetStatus = async (assetIds) => {
    const assetStatusPayload = {
      asset_ids: assetIds,
    }
    const response = await getAssetStatus(assetStatusPayload)
    const { data = [] } = response
    const finalisedLIST = mergeFileNameToResponseList(data, unprocessedAssets)
    setAssetStatusList(finalisedLIST)
  }

  useEffect(() => {
    if (unprocessedAssets?.length) {
      const unProcessedAssetIds = unprocessedAssets.map(
        (assetDetails) => assetDetails.asset_uoi_id,
      )
      fetchAndUpdateAssetStatus(unProcessedAssetIds)
    }
  }, [])

  const getAssetStatusIcon = (status = '') => {
    switch (status) {
      case 'INDEXED':
        return <CheckIcon className={classes.checkIcon} />
      default:
        return <ErrorIcon className={classes.errorIcon} />
    }
  }

  const handleSingleChange = (event, id) => {
    const selectedIndex = selected.indexOf(id)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      )
    }
    setSelected(newSelected)
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const selectList = [...assetStatusList]
      const filteredList = selectList.filter(
        (assetDetails = {}) => assetDetails.allowRetry,
      )
      setSelected(filteredList)
      return
    }
    setSelected([])
  }

  const onClickMultiRetry = () => {
    callRetryAssetService(selected)
  }

  const onClickSingleRetry = (singleAssetPayload) => {
    callRetryAssetService([singleAssetPayload])
  }

  const callRetryAssetService = async (selected = []) => {
    setRetryStateMessage(RETRY_INITIATED)
    const retryReqPayload = getRetryRequestPayload(selected)
    try {
      const response = await retryProjectAssets(retryReqPayload)
      if (response) {
        const { data: responseData = [] } = response
        const tempList = [...assetStatusList]
        const newUpdatedList = mergeLastUpdatedTimeToList(
          tempList,
          responseData,
        )
        startTransition(() => {
          setSelected([])
          setAssetStatusList(newUpdatedList)
          setRetryStateMessage(RETRY_IN_PROGRESS)
        })
      }
    } catch (error) {
      setRetryStateMessage(RETRY_FAILED)
    }
  }

  const RetryAssetErrorRow = ({
    open = false,
    updated_at = '',
    stepList = {},
  }) => {
    const {
      UPLOAD = {},
      FILE_PROCESSING = {},
      EXTRACT_FILE_METADATA = {},
      ITEM_PROCESSING = {},
      IMAGE_PROCESSING_GOOGLE = {},
      IMAGE_PROCESSING_TARGET = {},
      ASSETHUB_API = {},
    } = stepList

    return (
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <TableCell padding="checkbox" className={classes.iconCol}></TableCell>
          <TableCell className={classes.fileName}>
            {updated_at ? (
              <>
                <strong>{LAST_RETRIED_AT_LABEL}</strong>
                <div>{convertUTCtoLocal(updated_at)}</div>
              </>
            ) : (
              ''
            )}
          </TableCell>
          <TableCell className={classes.errorCol}>
            {UPLOAD?.['error_message'] ? (
              <>
                <strong>{ERROR_MESSAGE_LABEL}</strong>
                <div>{UPLOAD?.['error_message']}</div>
              </>
            ) : (
              ''
            )}
          </TableCell>
          <TableCell className={classes.errorCol}>
            {FILE_PROCESSING?.['error_message'] ? (
              <>
                <strong>{ERROR_MESSAGE_LABEL}</strong>
                <div>{FILE_PROCESSING?.['error_message']}</div>
              </>
            ) : (
              ''
            )}
          </TableCell>
          <TableCell className={classes.errorCol}>
            {EXTRACT_FILE_METADATA?.['error_message'] ? (
              <>
                <strong>{ERROR_MESSAGE_LABEL}</strong>
                <div>{EXTRACT_FILE_METADATA?.['error_message']}</div>
              </>
            ) : (
              ''
            )}
          </TableCell>
          <TableCell className={classes.errorCol}>
            {ITEM_PROCESSING?.['error_message'] ? (
              <>
                <strong>{ERROR_MESSAGE_LABEL}</strong>
                <div>{ITEM_PROCESSING?.['error_message']}</div>
              </>
            ) : (
              ''
            )}
          </TableCell>
          <TableCell className={classes.errorCol}>
            {IMAGE_PROCESSING_GOOGLE?.['error_message'] ? (
              <>
                <strong>{ERROR_MESSAGE_LABEL}</strong>
                <div>{IMAGE_PROCESSING_GOOGLE?.['error_message']}</div>
              </>
            ) : (
              ''
            )}
          </TableCell>
          <TableCell className={classes.errorCol}>
            {IMAGE_PROCESSING_TARGET?.['error_message'] ? (
              <>
                <strong>{ERROR_MESSAGE_LABEL}</strong>
                <div>{IMAGE_PROCESSING_TARGET?.['error_message']}</div>
              </>
            ) : (
              ''
            )}
          </TableCell>
          <TableCell className={classes.errorCol}>
            {ASSETHUB_API?.['error_message'] ? (
              <>
                <strong>{ERROR_MESSAGE_LABEL}</strong>
                <div>{ASSETHUB_API?.['error_message']}</div>
              </>
            ) : (
              ''
            )}
          </TableCell>
          <TableCell className={classes.retryCol}></TableCell>
        </Collapse>
      </TableCell>
    )
  }

  const getNAChip = () => <Chip label={NO_STATUS_FOUND_TEXT} />

  const RetryAssetRow = ({ assetDetails = {} }) => {
    const {
      asset_id = '',
      asset_name = '',
      stepList = {},
      updated_at = '',
      allowRetry = true,
      retryDisableText = '',
    } = assetDetails
    const {
      UPLOAD = {},
      FILE_PROCESSING = {},
      EXTRACT_FILE_METADATA = {},
      ITEM_PROCESSING = {},
      IMAGE_PROCESSING_GOOGLE = {},
      IMAGE_PROCESSING_TARGET = {},
      ASSETHUB_API = {},
    } = stepList
    const [open, setOpen] = useState(false)
    return (
      <>
        <TableRow key={asset_id}>
          <TableCell padding="checkbox" className={classes.iconCol}>
            <span>
              {allowRetry ? (
                <Checkbox
                  color="primary"
                  inputProps={{
                    'aria-label': 'select all assets',
                  }}
                  onChange={(event) => handleSingleChange(event, assetDetails)}
                  checked={selected.indexOf(assetDetails) !== -1}
                />
              ) : (
                ''
              )}
              <IconButton
                aria-label="expand assets"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </span>
          </TableCell>
          <TableCell className={classes.fileName}>{asset_name}</TableCell>
          <TableCell className={classes.statusCol}>
            {UPLOAD?.['status']
              ? getAssetStatusIcon(UPLOAD?.['status'])
              : getNAChip()}
          </TableCell>
          <TableCell className={classes.statusCol}>
            {FILE_PROCESSING?.['status']
              ? getAssetStatusIcon(FILE_PROCESSING?.['status'])
              : getNAChip()}
          </TableCell>
          <TableCell className={classes.statusCol}>
            {EXTRACT_FILE_METADATA?.['status']
              ? getAssetStatusIcon(EXTRACT_FILE_METADATA?.['status'])
              : getNAChip()}
          </TableCell>
          <TableCell className={classes.statusCol}>
            {ITEM_PROCESSING?.['status']
              ? getAssetStatusIcon(ITEM_PROCESSING?.['status'])
              : getNAChip()}
          </TableCell>
          <TableCell className={classes.statusCol}>
            {IMAGE_PROCESSING_GOOGLE?.['status']
              ? getAssetStatusIcon(IMAGE_PROCESSING_GOOGLE?.['status'])
              : getNAChip()}
          </TableCell>
          <TableCell className={classes.statusCol}>
            {IMAGE_PROCESSING_TARGET?.['status']
              ? getAssetStatusIcon(IMAGE_PROCESSING_TARGET?.['status'])
              : getNAChip()}
          </TableCell>
          <TableCell className={classes.statusCol}>
            {ASSETHUB_API?.['status']
              ? getAssetStatusIcon(ASSETHUB_API?.['status'])
              : getNAChip()}
          </TableCell>
          <TableCell className={classes.retryCol}>
            {allowRetry ? (
              <ReplayIcon
                className={classes.retryIcon}
                onClick={() => onClickSingleRetry(assetDetails)}
              />
            ) : (
              <div className={classes.retryDisableStyle}>
                {retryDisableText}
              </div>
            )}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
            <RetryAssetErrorRow
              open={open}
              stepList={stepList}
              updated_at={updated_at}
            />
          </TableCell>
        </TableRow>
      </>
    )
  }

  const RetryAssetHeader = () => (
    <TableRow>
      <TableCell padding="checkbox" className={classes.iconColHead}>
        <Checkbox
          color="primary"
          inputProps={{
            'aria-label': 'select all assets',
          }}
          indeterminate={
            selected?.length > 0 && selected?.length < assetStatusList.length
          }
          checked={
            assetStatusList.length > 0 &&
            selected?.length === assetStatusList.length
          }
          onChange={(event) => handleSelectAllClick(event)}
        />
      </TableCell>
      <TableCell className={classes.fileNameHead}>FILE NAME</TableCell>
      <TableCell className={classes.statusColHead}>UPLOAD</TableCell>
      <TableCell className={classes.statusColHead}>FILE GENERATION</TableCell>
      <TableCell className={classes.statusColHead}>FILE METADATA</TableCell>
      <TableCell className={classes.statusColHead}>ITEM</TableCell>
      <TableCell className={classes.statusColHead}>GOOGLE VISION</TableCell>
      <TableCell className={classes.statusColHead}>TARGET VISION</TableCell>
      <TableCell className={classes.statusColHead}>PROJECTS</TableCell>
      <TableCell className={classes.retryColHead}>RETRY</TableCell>
    </TableRow>
  )

  return (
    <div className={classes.retryContainer}>
      <div className={classes.retryLabel}>Project: {projectName}</div>
      <div>
        {assetStatusList.length ? (
          <>
            <div className={classes.retryPreHead}>
              <div>{assetStatusList.length} Unprocessed Assets</div>
              <div className={classes.retryButtonDiv}>
                <Button
                  variant="contained"
                  color="error"
                  className={parentClasses.debugButton}
                  onClick={() => onClickMultiRetry()}
                  disabled={!selected.length}
                >
                  Retry Selected Assets
                  <span className={classes.retryPad}>
                    <ReplayIcon />
                  </span>
                </Button>
              </div>
            </div>
            <div className={classes.retryListGrid}>
              <TableContainer component={Paper} sx={{ maxHeight: '80vh' }}>
                <Table sx={{ minWidth: 650 }} stickyHeader>
                  <TableHead>
                    <RetryAssetHeader />
                  </TableHead>
                  <TableBody className={classes.retryTableBody}>
                    {assetStatusList.map((assetDetails = {}) => (
                      <RetryAssetRow assetDetails={assetDetails} />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div className={classes.footerGrid}>
              *Assets without checkboxes are not eligible for retry
            </div>
            <div className={classes.footerGrid}>
              <div className={classes.retryMessage}>{retryStateMessage}</div>
              <div className={classes.legendGrid}>
                <div className={classes.legendItem}>
                  <ReplayIcon className={classes.retryIcon} />{' '}
                  <span>RETRY</span>
                </div>
                <div className={classes.legendItem}>
                  <CheckIcon className={classes.checkIcon} />{' '}
                  <span>PROCESSED</span>
                </div>
                <div className={classes.legendItem}>
                  <ErrorIcon className={classes.errorIcon} />{' '}
                  <span>NOT PROCESSED</span>
                </div>
                <div className={classes.legendItem}>
                  <Chip label={NO_STATUS_FOUND_TEXT}/><span>NOT APPLICABLE</span>
                </div>
              </div>
            </div>
          </>
        ) : (
          NO_ASSETS_FOUND_FOR_RETRY
        )}
      </div>
    </div>
  )
}

export default RetryAssetView
