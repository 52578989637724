import React, { Fragment } from 'react'
import {
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { LibraryBooks } from '@mui/icons-material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { SEARCH_TEMPLATE_DEFAULT_VALUE } from '../../constants/search'

const styles = makeStyles((theme) => ({
  panelRoot: {
    marginLeft: '3px',
    marginBottom: 0,
  },
  panelHeader: {
    backgroundColor: '#F5F5F5',
    height: '53px',
  },
  libraryBooksIcon: {
    backgroundColor: 'white',
    borderRadius: '50%',
    padding: '3px',
    height: '25px',
    width: '25px',
    color: useTheme().palette.primary.mediumGrey,
    margin: '10px',
  },
  controlsLabel: {
    fontSize: '16px',
    fontWeight: 400,
    color: useTheme().palette.primary.midGrey,
  },
  formControl: {
    margin: '5px 10px',
    minWidth: '90%',
    backgroundColor: 'white',
    padding: '10px',
    borderRadius: '5px',
    '& label': {
      marginLeft: '12px',
      color: '#4D4F5C',
      fontSize: '13px',
    },
  },
  '.MuiInput-underline': {
    '&::before': {
      content: 'none !important',
    },
  },
  reviewBox: {
    backgroundColor: '#F5F5F5',
  },
  filterInputRoot: {
    padding: '10px 0px',
    width: 'auto',
  },
  expansionPanelRoot: {
    backgroundColor: 'unset',
    boxShadow: 'unset',
    '&:before': {
      backgroundColor: 'unset',
    },
  },
  expansionPanelSummary: {
    color: '#4D4F5C',
    fontSize: '14px',
    margin: '7px',
    backgroundColor: 'white',
    borderRadius: 5,
    minHeight: '55px !important',
    marginTop: 15,
  },
  expansionPanelSummaryExpanded: {
    backgroundColor: '#e5e5e5',
  },
  expandMore: {
    color: '#4D4F5C',
    fontWeight: 700,
  },
  expansionPanelDetails: {
    padding: '0 0 0 5px',
    display: 'flex',
    flexDirection: 'column',
  },
}))

export class LibrarianPanel extends React.Component {
  render() {
    const {
      classes = {},
      searchTemplates = {},
      templateId = SEARCH_TEMPLATE_DEFAULT_VALUE,
      onSelectionChange,
    } = this.props
    return (
      <Grid
        container
        direction={'row'}
        spacing={0}
        className={classes.panelRoot}
      >
        <Grid item xs={12} lg={12} md={12} className={classes.reviewBox}>
          <Accordion
            defaultExpanded={true}
            classes={{
              root: classes.expansionPanelRoot,
              expanded: classes.expansionPanelExpanded,
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              classes={{
                root: classes.expansionPanelSummary,
                expanded: classes.expansionPanelSummaryExpanded,
              }}
            >
              LIBRARIAN REVIEW
            </AccordionSummary>
            <AccordionDetails
              classes={{
                root: classes.expansionPanelDetails,
              }}
            >
              <FormControl variant="standard" className={classes.formControl}>
                <InputLabel id="librarian-panel">Review Type</InputLabel>
                <Select
                  variant="standard"
                  data-cy="librarianPanel"
                  labelId="librarian-panel"
                  id="librarian-panel-select"
                  value={templateId}
                  onChange={(e) => onSelectionChange(e.target.value)}
                  style={{ marginTop: '5px' }}
                >
                  {Object.keys(searchTemplates).map((key) => (
                    <MenuItem data-cy="librarianPanelMenuItem" value={key}>
                      {searchTemplates[key]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    )
  }
}
LibrarianPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  searchTemplates: PropTypes.object.isRequired,
  onSelectionChange: PropTypes.func.isRequired,
  templateId: PropTypes.string.isRequired,
}

const MyComponent = (props) => {
  const classes = styles()
  return <LibrarianPanel {...props} classes={classes} />
}

export default MyComponent
