import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import { Grid, CircularProgress } from '@mui/material'

import {
  getTotalSearchResults,
  getIsFetchingAssets,
  getAssets,
} from '../../../../store/publish/selectors'
import { fetchInitialAssets } from '../../../../store/publish/actions'
import { selectUserId } from '../../../../store/auth/selector'

import AssetCard from './AssetCard'
import PageSelector from './PageSelector'

const styles = makeStyles((theme) => ({
  noResult: {
    color: useTheme().palette.primary.midGrey,
    fontSize: 21,
    margin: '10px auto',
    fontWeight: 500,
  },
  progress: {
    margin: '100px auto',
  },
  resultCount: {
    fontWeight: 700,
  },
}))

const NoResults = ({ classes = {} }) => (
  <Grid
    container
    spacing={2}
    direction={'row'}
    justifyContent={'center'}
    alignItems={'center'}
  >
    <Grid item xs={12} style={{ textAlign: 'left' }}>
      <h1 className={classes.noResult}>No Results Found</h1>
    </Grid>
  </Grid>
)

const Loading = ({ classes = {} }) => (
  <Grid
    container
    spacing={4}
    direction={'row'}
    justifyContent={'center'}
    alignItems={'center'}
  >
    <Grid item xs={12} style={{ textAlign: 'center' }}>
      <CircularProgress className={classes.progress} />
    </Grid>
  </Grid>
)

const SearchResults = ({
  classes = {},
  totalResults = Number(0),
  searchAssets = [],
  isFetchingAssets = false,
  fetchInitialAssets = () => {},
  history = {},
  router = {},
  lanId = '',
}) => {
  useEffect(() => {
    fetchInitialAssets()
  }, [fetchInitialAssets])

  if (!isFetchingAssets && !totalResults) {
    return <NoResults classes={classes} />
  }

  return (
    <>
      {isFetchingAssets ? (
        <Loading classes={classes} />
      ) : (
        <Grid>
          <Grid container spacing={2}>
            {searchAssets.map((result, index) => (
              <Grid item key={index}>
                <AssetCard
                  asset={result}
                  history={history}
                  lanId={lanId}
                  router={router}
                />
              </Grid>
            ))}
          </Grid>
          <Grid
            container
            spacing={2}
            direction={'row'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Grid item>
              <PageSelector />
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  )
}

SearchResults.propTypes = {
  classes: PropTypes.object.isRequired,
  totalResults: PropTypes.number.isRequired,
  searchAssets: PropTypes.array.isRequired,
  isFetchingAssets: PropTypes.bool.isRequired,
  fetchInitialAssets: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  lanId: PropTypes.string.isRequired,
}

const mapStateToProps = (state) => ({
  totalResults: getTotalSearchResults(state),
  isFetchingAssets: getIsFetchingAssets(state),
  searchAssets: getAssets(state),
  lanId: selectUserId()(state),
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchInitialAssets }, dispatch)

const MyComponent = (props) => {
  const classes = styles()
  return <SearchResults {...props} classes={classes} />
}

export default connect(mapStateToProps, mapDispatchToProps)(MyComponent)
