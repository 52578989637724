import axios from 'axios'
import {
  REQUEST_CHANNEL,
  RECEIVE_CHANNEL,
  CHANGE_SELECTED_TEAM,
  SET_MEMBERS_COUNT,
  SET_ASSETS_COUNT,
  SET_CHANNEL_MEMBERS,
  SET_CHANNEL_ASSETS,
  SET_CHANNEL_SORT_FIELD,
  SET_CHANNEL_ASSET_FILTER,
  SELECT_CHANNEL_ASSET_EVENT,
  SET_CHANNEL_SORT_DIRECTION,
  CHANGE_CHANNEL_ASSETS_PAGE,
  CHANGE_CHANNEL_ASSET_PAGE_SIZE,
  RESET_CHANNEL_ASSET_PARAMS,
  REMOVE_FROM_CHANNEL_PENDING_LIST,
  UPDATE_IMAGE_TO_CHANNEL_PENDING_LIST,
  ADD_IMAGE_TO_CHANNEL_PENDING_LIST,
} from './channelsActionType'
import apiConfig from '../../config/apiConfig'
import { showNotification } from '../notification/actionCreator'

const { channelPublish = {}, publishUpload = {} } = apiConfig
const { baseUrl = '', channelUrl = '' } = channelPublish

const { REACT_APP_DAP_PROCESSOR_BASE_URL: jobsBaseUrl = '' } = publishUpload

export function requestChannel() {
  return {
    type: REQUEST_CHANNEL,
  }
}

export function receiveChannel(channels) {
  return {
    type: RECEIVE_CHANNEL,
    payload: {
      channels: channels,
    },
  }
}

export function requestChannelsEvent() {
  return (dispatch) => {
    dispatch(requestChannel())
    axios
      .get(`${baseUrl}`, {
        data: {},
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
        },
      })
      .then((res) => {
        const { data = [] } = res
        dispatch(receiveChannel(data))
      })
      .catch((error, data) => {
        dispatch(
          showNotification(true, `Error while requesting channels`, 'error')
        )
      })
  }
}

export function getChannelAssets({
  channelId = '',
  pageNumber = Number(0),
  pageSize = Number(10),
  sortDirection = 'DESC',
  sortBy = 'CREATED_AT',
  assetFilter = '',
}) {
  return (dispatch) => {
    axios
      .get(
        `${jobsBaseUrl}/publish_processor/v1/jobs/channel/${channelId}?page=${pageNumber}&size=${pageSize}&sort_by=${sortBy}&sort_direction=${sortDirection}&original_file_name=${assetFilter}`,
        {
          data: {},
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then((response) => {
        const { data = [] } = response
        dispatch(setChannelAssets(data))
        dispatch(removePendingChannelFiles(data))
      })
      .catch((error) => {
        dispatch(
          showNotification(
            true,
            `Error while requesting channel assets`,
            'error'
          )
        )
      })
  }
}

export function getChannelMembers(channelId = '') {
  return (dispatch) => {
    axios
      .get(`${baseUrl}${channelId}/users`, {
        data: {},
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
        },
      })
      .then((response) => {
        const { data = [] } = response
        dispatch(setChannelMembers(data))
      })
      .catch((error) => {
        dispatch(
          showNotification(
            true,
            `Error while requesting channel members`,
            'error'
          )
        )
      })
  }
}

export function getChannelDetails(channelId = '') {
  return (dispatch) => {
    axios
      .get(`${baseUrl}${channelId}/`, {
        data: {},
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
        },
      })
      .then((response) => {
        const { data = {} } = response
        const { user_count = Number(0), asset_count = Number(0) } = data
        dispatch(setMembersCount(user_count))
        dispatch(setAssetsCount(asset_count))
      })
      .catch((error) => {
        dispatch(
          showNotification(
            true,
            `Error while requesting channel members`,
            'error'
          )
        )
      })
  }
}

export function getSelectedChannelDetails({
  channelId = '',
  pageNumber = Number(0),
  pageSize = Number(10),
  sortDirection = 'DESC',
  sortBy = 'CREATED_AT',
  assetFilter = '',
}) {
  return (dispatch) => {
    dispatch(getChannelDetails(channelId))
    dispatch(getChannelMembers(channelId))
    dispatch(
      getChannelAssets({
        channelId,
        pageNumber,
        pageSize,
        sortDirection,
        sortBy,
        assetFilter,
      })
    )
  }
}

export function changeSelectedChannel(selectedChannel) {
  return {
    type: CHANGE_SELECTED_TEAM,
    payload: {
      selectedChannel: selectedChannel,
    },
  }
}

export function setMembersCount(membersCount) {
  return {
    type: SET_MEMBERS_COUNT,
    payload: {
      membersCount: membersCount,
    },
  }
}

export function setAssetsCount(assetsCount) {
  return {
    type: SET_ASSETS_COUNT,
    payload: {
      assetsCount: assetsCount,
    },
  }
}

export function setChannelMembers(members = []) {
  return {
    type: SET_CHANNEL_MEMBERS,
    payload: {
      members: members,
    },
  }
}

export function setChannelAssets(assets = []) {
  return {
    type: SET_CHANNEL_ASSETS,
    payload: {
      assets: assets,
    },
  }
}

export function setChannelSortField(value) {
  return {
    type: SET_CHANNEL_SORT_FIELD,
    payload: value,
  }
}

export function setChannelSortDirection(value) {
  return {
    type: SET_CHANNEL_SORT_DIRECTION,
    payload: value,
  }
}

export function changeChannelAssetFilter(filter) {
  return {
    type: SET_CHANNEL_ASSET_FILTER,
    payload: filter,
  }
}

export function handleChannelAssetSelect(data) {
  const { selectedAssets = [] } = data
  return {
    type: SELECT_CHANNEL_ASSET_EVENT,
    payload: {
      selectedAssets: selectedAssets,
    },
  }
}

export function changeChannelAssetsPage(currentPage) {
  return {
    type: CHANGE_CHANNEL_ASSETS_PAGE,
    payload: {
      currentPage: currentPage,
    },
  }
}

export function changeChannelAssetsPageSize(defaultAssetPageSize) {
  return {
    type: CHANGE_CHANNEL_ASSET_PAGE_SIZE,
    payload: {
      defaultAssetPageSize: defaultAssetPageSize,
    },
  }
}

export function resetChannelAssetParams() {
  return {
    type: RESET_CHANNEL_ASSET_PARAMS,
  }
}

export function addImageToChannelPendingList(pendingFile = {}) {
  return {
    type: ADD_IMAGE_TO_CHANNEL_PENDING_LIST,
    payload: {
      pendingFile: pendingFile,
    },
  }
}

export function updateImageToChannelPendingList(pendingFile = {}) {
  return {
    type: UPDATE_IMAGE_TO_CHANNEL_PENDING_LIST,
    payload: {
      pendingFile: pendingFile,
    },
  }
}

export function removePendingChannelFiles(files = []) {
  return {
    type: REMOVE_FROM_CHANNEL_PENDING_LIST,
    payload: {
      files,
    },
  }
}

export function downloadChannelAssets(channelId = '', ids = []) {
  const fileDownload = require('js-file-download')
  let payload = ids.map(({ channel_asset_uuid: assetId = '' }) => assetId)
  return (dispatch) => {
    axios
      .post(`${channelUrl}/${channelId}/assets/download`, payload, {
        responseType: 'arraybuffer',
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        fileDownload(
          res.data,
          `channel_publish_assets_${new Date().toISOString()}.xlsx`
        )
      })
      .catch((error) => {
        dispatch(
          showNotification(true, `Error while downloading xls file`, 'error')
        )
      })
  }
}
