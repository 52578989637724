import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import { makeStyles, withStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import Divider from '@mui/material/Divider'
import { connect, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Select } from '@mui/material'
import { withEnv } from '@praxis/component-runtime-env'
import { withAuth } from '@praxis/component-auth'
import { praxis } from '../../config/themeConfig'
import { saveRole } from '../../store/header/actionCreator'
import apiConfig from '../../config/apiConfig'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    marginRight: '10px',
  },
  paper: {
    marginRight: useTheme().spacing(2),
  },
  menuItem: {
    // width: '264px',
    // height: '50px',
    fontSize: '14px!important',
    overflow: 'hidden',
    flexShrink: '1',
    wordWrap: 'normal',
    whiteSpace: 'normal',
    color: praxis.themePrimary,
  },
  dropdownIcon: {
    paddingRight: '10px',
    color: '#BCBCCB',
  },
  company: {
    fontSize: '12px',
  },
  dropdownButton: {
    textTransform: 'none!important',
    fontSize: '15px',
    color: praxis.themePrimary,
  },
  dropdown: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 2px 10px #00000027',
  },
  darkText: {
    color: 'black',
  },
  statusSelector: {
    color: 'black',
    fontSize: '14px',
  },
}))

function Dropdown(props) {
  const classes = useStyles()
  const { env = {}, company = '', user = '', auth: authProps = {} } = props
  const { logout = () => {} } = authProps
  const { REACT_APP_TEST_ROLES = [] } = env
  const [open, setOpen] = React.useState(false)
  const [selectedRole, setSelectedRole] = useState('')
  const anchorRef = React.useRef(null)

  const role = useSelector((state = {}) => state.headerReduce?.role)
  const auth = useSelector((state = {}) => state.auth) || {}

  const { memberOf = [] } = auth

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  useEffect(() => {
    if (!role) {
      setSelectedRole('APP-OAUTH2-ASSETHUB-ADMIN')
    } else {
      setSelectedRole(role)
    }
  }, [role])

  const onChangeRoles = (e) => {
    const { saveRole = () => {} } = props
    const value = e.target.value
    setSelectedRole(value)
    saveRole(value)
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  return (
    <div className={classes.root}>
      <div>
        <Button
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          className={classes.dropdownButton}
        >
          {user}
          <ExpandMoreIcon />
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          placement="bottom-end"
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
              className={classes.dropdown}
            >
              <Paper>
                <ClickAwayListener onClickAway={() => {}}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem
                      className={classes.menuItem}
                      onClick={handleClose}
                    >
                      <AccountCircleIcon className={classes.dropdownIcon} />
                      <div className={classes.name}>
                        {user}
                        <br />
                        {!company?.includes('Target') && (
                          <div className={classes.company}>{company}</div>
                        )}
                      </div>
                    </MenuItem>
                    <Divider />
                    {memberOf.length &&
                    memberOf.includes('APP-OAUTH2-ASSETHUB-ADMIN') &&
                    process.env.APP_ENV !== 'prod' ? (
                      <MenuItem className={classes.menuItem}>
                        {REACT_APP_TEST_ROLES.length > 0 ? (
                          <div>
                            <Select
                              variant="standard"
                              data-cy="role"
                              value={selectedRole}
                              onChange={onChangeRoles}
                              classes={{
                                icon: classes.darkText,
                                root: classes.statusSelector,
                              }}
                            >
                              {REACT_APP_TEST_ROLES.map((entitlement) => (
                                <MenuItem
                                  data-cy={entitlement}
                                  key={entitlement}
                                  value={entitlement}
                                >
                                  {entitlement}
                                </MenuItem>
                              ))}
                            </Select>
                          </div>
                        ) : null}
                      </MenuItem>
                    ) : null}
                    <Divider />
                    <MenuItem
                      className={classes.menuItem}
                      onClick={() => logout()}
                    >
                      <ExitToAppIcon className={classes.dropdownIcon} />
                      Logout
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveRole,
    },
    dispatch
  )

export default connect(
  null,
  mapDispatchToProps
)(withAuth()(withEnv()(Dropdown)))
