import React from 'react'
import { withStyles } from '@mui/styles'
import {
  Button,
  Input,
  IconButton,
  Tooltip,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableFooter,
  TablePagination,
  Paper,
  Typography,
  Fab,
  CircularProgress,
  Checkbox,
  TableContainer,
} from '@mui/material'
import Clear from '@mui/icons-material/Clear'
import GetApp from '@mui/icons-material/GetApp'
import SearchIcon from '@mui/icons-material/Search'
import CopyToClipboard from 'react-copy-to-clipboard'
import Link from '@mui/icons-material/Link'
import CheckIcon from '@mui/icons-material/Check'
import { green } from '@mui/material/colors'
import PublishAssetDetailHeader from './PublishAssetDetailHeader'
import generatingThumbnail from '../../../images/GeneratingThumbnail.svg?url'
import { wrapWord, addDefaultSrc } from '../helpers/AssetPublishHelpers'
import { formatCSTDate } from '../../../helpers/dateHelper'

const styles = (theme) => ({
  assetDetailWrapper: {
    maxWidth: '100%',
    overflowX: 'auto',
  },
  searchWrapper: {
    paddingLeft: '5px',
  },
  filterInput: {
    width: '95%',
  },
  numberSelected: {
    marginLeft: '10px',
    fontSize: '1rem',
    fontFamily: 'NewTargetica, Helvetica, Arial, sans-serif',
    fontWeight: 400,
    lineHeight: 1.75,
    color: 'rgba(0, 0, 0, 0.54)',
  },
  footerBorder: {
    border: '1px solid #e4e7e7',
    marginRight: '-2px',
  },
  channelTable: {
    paddingRight: '5px',
  },
  table: {
    overflowWrap: 'anywhere',
    border: '1px solid #e0e0e0',
    borderBottom: 'none',
    minHeight: 460,
    maxHeight: 460,
  },
  tableCell: {
    padding: '4px 0px',
  },
  fabProgress: {
    color: green[500],
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  selectedAssetActions: {
    marginLeft: '-5px',
    padding: '10px 5px 10px 0px',
  },
  downloadButton: {
    backgroundColor: '#188295',
    paddingBottom: '5px',
    float: 'right',
    '&:hover': {
      backgroundColor: '#115E6C',
    },
  },
})

function PublishAssetDetail({
  classes = {},
  assetFilter = '',
  numSelected = Number(0),
  pendingFiles = [],
  channelAssets = [],
  currentAssetPage = Number(0),
  assetsCount = Number(0),
  selectedChannelId = '',
  rowCount = Number(10),
  sortDirection = 'DESC',
  sortField = 'CREATED_AT',
  channelAssetsLength = Number(0),
  changeChannelAssetFilter = () => {},
  searchFilter = () => {},
  downloadSelectedAssets = () => {},
  getChannelAssets = () => {},
  handleSelectAllClick = () => {},
  handleClick = () => {},
  isSelected = () => {},
  changePage = () => {},
  changeRowsPerPage = () => {},
  setChannelSortDirection = () => {},
  setChannelSortField = () => {},
  copyAssetUrl = () => {},
}) {
  const onKeyUpInChannelSearchText = (e = {}) => {
    const { key: eventKey = '' } = e
    if (eventKey) {
      if (
        eventKey === 'Enter' ||
        (eventKey === 'Backspace' && assetFilter === '')
      ) {
        getChannelAssets({
          channelId: selectedChannelId,
          pageNumber: 0,
          pageSize: rowCount,
          sortDirection,
          sortBy: sortField,
          assetFilter,
        })
      }
    }
  }

  const onClearChannelSearchFilter = () => {
    changeChannelAssetFilter('')
    getChannelAssets({
      channelId: selectedChannelId,
      pageNumber: 0,
      pageSize: rowCount,
      sortDirection,
      sortBy: sortField,
      assetFilter: '',
    })
  }

  return (
    <div className={classes.assetDetailWrapper}>
      <div className={classes.searchWrapper}>
        <Input
          name="asset_filter"
          onChange={(e) => changeChannelAssetFilter(e.target.value)}
          rows="1"
          value={assetFilter}
          placeholder="File Name Filter"
          className={classes.filterInput}
          onKeyUp={(e) => {
            onKeyUpInChannelSearchText(e)
          }}
          endAdornment={
            assetFilter === '' ? null : (
              <Tooltip title="Clear Filter">
                <IconButton
                  onClick={() => {
                    onClearChannelSearchFilter()
                  }}
                  size="large"
                >
                  <Clear />
                </IconButton>
              </Tooltip>
            )
          }
        />
        <Tooltip title="Search" className={classes.searchIcon}>
          <IconButton onClick={searchFilter} size="large">
            <SearchIcon />
          </IconButton>
        </Tooltip>
      </div>
      <div className={classes.selectedAssetActions}>
        <span className={classes.numberSelected}>
          {numSelected} Selected Assets
        </span>
        {
          <Button
            onClick={downloadSelectedAssets}
            variant="contained"
            color="secondary"
            type="submit"
            disabled={numSelected < 1}
            className={classes.downloadButton}
          >
            <GetApp />
            Download Data(.xls)
          </Button>
        }
      </div>
      <Paper
        sx={{ width: '100%', overflow: 'hidden' }}
        className={classes.channelTable}
      >
        <TableContainer sx={{ maxHeight: 460 }} className={classes.table}>
          <Table stickyHeader aria-label="sticky channel table">
            <PublishAssetDetailHeader
              handleSelectAllClick={handleSelectAllClick}
              numSelected={numSelected}
              rowCount={rowCount}
              channelAssetsLength={channelAssetsLength}
              assetFilter={assetFilter}
              sortDirection={sortDirection}
              sortField={sortField}
              getChannelAssets={getChannelAssets}
              setChannelSortDirection={setChannelSortDirection}
              setChannelSortField={setChannelSortField}
              pathname=""
              selectedChannelId={selectedChannelId}
            ></PublishAssetDetailHeader>
            <TableBody>
              {pendingFiles &&
                pendingFiles.map((row = {}) => {
                  const {
                    job_id = '',
                    status = '',
                    original_file_name = '',
                    created_at = '',
                    created_by = '',
                  } = row
                  const createdDate = formatCSTDate(created_at, 'll') || ''
                  return (
                    <TableRow key={job_id}>
                      <TableCell
                        padding="checkbox"
                        className={classes.tableCell}
                      >
                        <Checkbox
                          checked={isSelected(row)}
                          color="primary"
                          onClick={(event) => handleClick(event, row)}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.tableCell}
                      >
                        {['NEW', 'UPLOADING'].indexOf(status) !== -1 ? (
                          <CircularProgress
                            size={56}
                            className={classes.fabProgress}
                          />
                        ) : (
                          <Fab
                            color="primary"
                            className={classes.buttonSuccess}
                          >
                            <CheckIcon />
                          </Fab>
                        )}
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        component="th"
                        scope="row"
                      >
                        {original_file_name && (
                          <Tooltip title={original_file_name}>
                            <Typography className={classes.fileName}>
                              {wrapWord(original_file_name)}
                            </Typography>
                          </Tooltip>
                        )}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <Link />
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {status}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {created_by}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {createdDate}
                      </TableCell>
                    </TableRow>
                  )
                })}
              {channelAssets.length === 0 ? (
                <TableRow>
                  <TableCell
                    align="center"
                    colSpan={7}
                    className={classes.tableCell}
                  >
                    <Typography className={classes.label}>
                      <strong>No Results</strong>
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                channelAssets.map((row = {}) => {
                  const findIsSelected = isSelected(row)
                  const {
                    job_id = '',
                    status = '',
                    projected_url = '',
                    original_file_name = '',
                    created_at = Number(0),
                    created_by = '',
                  } = row
                  const createdDate = formatCSTDate(created_at, 'll') || ''
                  return (
                    <TableRow key={job_id}>
                      <TableCell
                        padding="checkbox"
                        className={classes.tableCell}
                      >
                        <Checkbox
                          checked={findIsSelected}
                          color="primary"
                          onClick={(event) => handleClick(event, row)}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.tableCell}
                      >
                        {status === 'COMPLETE' ? (
                          <img
                            alt="Thumbnail"
                            onError={addDefaultSrc}
                            src={`${projected_url.replace(
                              'is/content',
                              'is/image'
                            )}?hei=150&wid=150`}
                            height="56px"
                            width="56px"
                          />
                        ) : (
                          <img
                            alt="Thumbnail"
                            src={`${generatingThumbnail}?hei=150&wid=150`}
                            height="56px"
                            width="56px"
                          />
                        )}
                      </TableCell>
                      <TableCell
                        component="th"
                        size="small"
                        scope="row"
                        className={classes.tableCell}
                      >
                        <Tooltip title={original_file_name}>
                          <Typography noWrap>
                            {wrapWord(original_file_name)}
                          </Typography>
                        </Tooltip>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <Tooltip title={`${projected_url}`}>
                          <div>
                            <CopyToClipboard
                              text={`${projected_url}`}
                              onCopy={() => copyAssetUrl(row)}
                            >
                              <Link style={{ cursor: 'pointer' }} />
                            </CopyToClipboard>
                          </div>
                        </Tooltip>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {status}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {created_by}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {createdDate}
                      </TableCell>
                    </TableRow>
                  )
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          className={classes.footerBorder}
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={assetsCount}
          rowsPerPage={parseInt(rowCount)}
          page={currentAssetPage}
          SelectProps={{
            native: true,
          }}
          onPageChange={(event, newpage) => changePage(event, newpage)}
          onRowsPerPageChange={(event) => changeRowsPerPage(event)}
        />
      </Paper>
    </div>
  )
}

export default withStyles(styles)(PublishAssetDetail)
