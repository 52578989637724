import Moment from 'moment-timezone'
import apiConfig from '../../../../config/apiConfig'
import { convertUTCToLocalTime } from '../../../../helpers/dateHelper'
import {
  RETRY_IN_PROGRESS,
  RETRY_LIMIT_EXCEEDED,
  RETRY_MAX_COUNT,
  RETRY_TIME_LIMIT,
} from '../../../../constants/projects'
import { isEmptyObject } from '../../../../helpers/UtilityHelper'

const getChunkNumbers = (totalNumberOfChunks = 0) => {
  let chunkNumbers = []
  for (let chunkNumber = 0; chunkNumber < totalNumberOfChunks; chunkNumber++) {
    chunkNumbers.push(chunkNumber)
  }
  return chunkNumbers
}

const validateAssetRetry = (updated_at = '', stepList = {}) => {
  let allowRetry = true
  let retryDisableText = ''
  if (updated_at) {
    const lastUpdatedTime = convertUTCToLocalTime(updated_at).valueOf()
    const currentTime = new Date().getTime()
    if (lastUpdatedTime + RETRY_TIME_LIMIT > currentTime) {
      allowRetry = false
      retryDisableText = RETRY_IN_PROGRESS
    }
  }
  if (!isEmptyObject(stepList)) {
    for (const step in stepList) {
      if (
        stepList[step]?.['retry_count'] &&
        stepList[step]?.['retry_count'] === RETRY_MAX_COUNT
      ) {
        allowRetry = false
        retryDisableText = RETRY_LIMIT_EXCEEDED
      }
    }
  }
  return { allowRetry, retryDisableText }
}

const getCurrentUserLocation = () => {
  let currentLocation = Moment.tz.guess() === 'Asia/Calcutta' ? 'India' : 'MPLS'
  let userType = apiConfig.externalUser ? 'EXTERNAL' : 'INTERNAL'
  return userType + ' - ' + currentLocation
}

const makeUploadFileTaggingData = (file = {}) => {
  const uploadFileData = `fileName:${file.name}, uploadId:${file.uploadId}, chunkNumber:${file.currentChunk}, chunkSize:${file.chunkSize}, projectUUID:${file.project_uuid}, completedChunks:${file.completedChunks}`
  return uploadFileData
}

const getProcessedWipCount = (selectedAssets = {}) => {
  let wipCount = 0
  if (!isEmptyObject(selectedAssets)) {
    const assets = Object.values(selectedAssets)
    for (const asset of assets) {
      if (asset !== undefined && asset.status === 'COMPLETE') {
        wipCount++
      }
    }
  }
  return wipCount
}

export {
  getCurrentUserLocation,
  getChunkNumbers,
  makeUploadFileTaggingData,
  getProcessedWipCount,
  validateAssetRetry,
}