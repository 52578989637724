import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import { IconButton } from '@mui/material'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'

import {
  getNumberOfPages,
  getCurrentPage,
} from '../../../../store/publish/selectors'
import {
  navigatePreviousPage,
  navigateNextPage,
  fetchAssets,
} from '../../../../store/publish/actions'

const styles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '15px',
    fontSize: '13px',
    fontWeight: '400px',
  },
  formControl: {
    margin: useTheme().spacing(1),
  },
}))

const PageSelector = ({
  classes = {},
  currentPage = Number(0),
  navigatePreviousPage = () => {},
  navigateNextPage = () => {},
  numberOfPages = Number(0),
}) => (
  <div className={classes.root}>
    <IconButton onClick={() => navigatePreviousPage()} size="large">
      <KeyboardArrowLeft />
    </IconButton>
    <span data-cy="pageSelector">
      {' '}
      {`Page ${currentPage + 1} of ${numberOfPages}`}
    </span>
    <IconButton onClick={() => navigateNextPage()} size="large">
      <KeyboardArrowRight />
    </IconButton>
  </div>
)

PageSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  currentPage: PropTypes.number.isRequired,
  navigatePreviousPage: PropTypes.func.isRequired,
  navigateNextPage: PropTypes.func.isRequired,
  numberOfPages: PropTypes.number.isRequired,
}

const mapStateToProps = (state) => ({
  numberOfPages: getNumberOfPages(state),
  currentPage: getCurrentPage(state),
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { navigatePreviousPage, navigateNextPage, fetchAssets },
    dispatch
  )

const MyComponent = (props) => {
  const classes = styles()
  return <PageSelector {...props} classes={classes} />
}

export default connect(mapStateToProps, mapDispatchToProps)(MyComponent)
