import React from 'react'
import PropTypes from 'prop-types'
import { Formik, Field } from 'formik'
import { Button, DialogActions, Grid } from '@mui/material'
import { find, isEmpty } from 'lodash'
import {
  DateFormField,
  SelectFormField,
  TextFormField,
} from '../../FormikFormFields'
import { sortMyArray } from '../../../helpers/UtilityHelper'
import { FULL_FORM_PROJECT_TYPES } from '../../../constants/projectTask'
import {
  validateIsRequired,
  isDisabled,
} from '../../../helpers/ProjectFormHelpers'

export const ProjectTypeForm = ({
  onSubmit = () => {},
  projectTypes = [],
  projectInitiator = {},
  classes = {},
}) => {
  const unsortedMenuList = projectTypes.map((x = {}) => ({
    text: x.projectType,
    value: x.projectType,
    order: x.projectTypeSequence,
  }))

  const sortedMenuList = sortMyArray(unsortedMenuList, 'value', 'asc')

  const validate = (value = '') => {
    // If selected project type is full form project type then go directly to full form
    if (FULL_FORM_PROJECT_TYPES.indexOf(value) > -1) {
      onSubmit({ projectType: value })
    }
  }

  return (
    <Formik initialValues={{}} onSubmit={onSubmit}>
      {(formikProps) => {
        const {
          handleSubmit = () => {},
          errors = {},
          values = {},
        } = formikProps
        const currentProjectType = find(projectTypes, {
          projectType: values.projectType,
        })
        return (
          <form onSubmit={handleSubmit}>
            <Grid container className={classes.projectTypeContainer}>
              <Grid item>
                <Field
                  data-cy="projectType"
                  label="Project Type"
                  name="projectType"
                  component={SelectFormField}
                  isRequired
                  menuItems={sortedMenuList}
                  validate={validate}
                  className={classes.projectField}
                />
              </Grid>
              <Grid item>
                {values.projectType &&
                  currentProjectType &&
                  (currentProjectType.metadataType === 'date' ? (
                    <Field
                      name="projectId"
                      label={'Run Date'}
                      component={(props) => <DateFormField {...props} />}
                      isRequired
                      className={classes.projectField}
                    />
                  ) : (
                    <Field
                      name="projectId"
                      label={currentProjectType.displayText}
                      component={TextFormField}
                      required="true"
                      className={classes.projectField}
                      validate={validateIsRequired(
                        true,
                        currentProjectType.displayText,
                      )}
                    />
                  ))}
              </Grid>
              <Grid item>
                {errors.name && <div id="feedback">{errors.name}</div>}
                {!isEmpty(projectInitiator) && (
                  <p>
                    This project already exists. Pleast contact
                    <strong> {projectInitiator['first_name']} </strong>
                    <strong>{projectInitiator['last_name']}</strong>
                  </p>
                )}
              </Grid>
            </Grid>
            {/* Disable button if less than 2 values, means initial form is not filled out. */}
            <DialogActions className={classes.dialogActions}>
              <Button
                disabled={isDisabled(values)}
                data-cy="createProjectContinueButton"
                variant="contained"
                color="secondary"
                type="submit"
              >
                Continue
              </Button>
            </DialogActions>
          </form>
        )
      }}
    </Formik>
  )
}

ProjectTypeForm.propTypes = {
  onSubmit: PropTypes.func,
  projectTypes: PropTypes.arrayOf(PropTypes.object),
  projectInitiator: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
  }),
}

export default ProjectTypeForm
