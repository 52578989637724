import React from 'react'
import PropTypes from 'prop-types'
import GroupIcon from '@mui/icons-material/Group'
import FaceIcon from '@mui/icons-material/Face'

import ReviewerSvg from '../../../images/Reviewer.svg?url'
import UploaderIcon from '../../../images/upload.svg?url'
import {
  ADMIN_USER_ROLE_NAME,
  REVIEWER_ROLE_NAME,
  UPLOADER_ROLE_NAME,
} from '../../../constants/projectTask'

export const UserRoleIcon = ({ classes = {}, roleName = '' }) => {
  const role = roleName.toLowerCase()
  return role === ADMIN_USER_ROLE_NAME ? (
    <div data-cy="taskUserAdmin" className={classes.roleEl}>
      <GroupIcon className={classes.userIcon} /> Admin
    </div>
  ) : role === REVIEWER_ROLE_NAME ? (
    <div data-cy="taskUserReviewer" className={classes.roleEl}>
      <img alt="Reviewer" src={ReviewerSvg} /> Reviewer{' '}
    </div>
  ) : role === UPLOADER_ROLE_NAME ? (
    <div data-cy="taskUserUploader" className={classes.roleEl}>
      <img alt="Uploader" src={UploaderIcon} /> Uploader{' '}
    </div>
  ) : (
    <div data-cy="taskUserObserver" className={classes.roleEl}>
      <FaceIcon className={classes.userIcon} /> Observer{' '}
    </div>
  )
}

UserRoleIcon.propTypes = {
  classes: PropTypes.object,
  roleName: PropTypes.string,
  styles: PropTypes.object,
}
