import { UPDATE_TABLE_CONTEXT } from './actionTypes'

export const tableInitialState = {
  tableContextPayload: {},
}

export default function tableContextReducer(
  state = tableInitialState,
  action = {}
) {
  const { payload } = action
  switch (action.type) {
    case UPDATE_TABLE_CONTEXT:
      return {
        ...state,
        tableContextPayload: payload,
      }
    default:
      return state
  }
}
