export const PROJECT_API_CALL_INNITIATED = 'PROJECT_API_CALL_INNITIATED'
export const PROJECT_API_CALL_SUCCESS = 'PROJECT_API_CALL_SUCCESS'
export const PROJECT_API_CALL_FAIL = 'PROJECT_API_CALL_FAIL'
export const PROJECT_PAGE_API_CALL_INNITIATED =
  'PROJECT_PAGE_API_CALL_INNITIATED'
export const PROJECT_PAGE_API_CALL_SUCCESS = 'PROJECT_PAGE_API_CALL_SUCCESS'
export const PROJECT_PAGE_API_CALL_FAIL = 'PROJECT_PAGE_API_CALL_FAIL'
export const PROJECTS_FILTER = 'PROJECTS_FILTER'
export const PROJECTS_SORT = 'PROJECTS_SORT'
export const CREATE_PROJECT = 'CREATE_PROJECT'
export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS'
export const CREATE_PROJECT_FAILURE = 'CREATE_PROJECT_FAILURE'
export const GET_PROJECT_TYPES = 'GET_PROJECT_TYPES'
export const SUBMIT_FORM = 'SUBMIT_FORM'
export const GET_PROJECT_DETAILS_INIT = 'GET_PROJECT_DETAILS_INIT'
export const GET_PROJECT_DETAILS = 'GET_PROJECT_DETAILS'
export const GET_ASSET_UPLOAD_STATUS = 'GET_ASSET_UPLOAD_STATUS'
export const PROJECT_OPEN_MODAL = 'PROJECT_OPEN_MODAL'
export const PROJECT_CLOSE_MODAL = 'PROJECT_CLOSE_MODAL'
export const EDIT_PROJECT = 'EDIT_PROJECT'
export const EDIT_PROJECT_SUCCESS = 'EDIT_PROJECT_SUCCESS'
export const EDIT_PROJECT_FAIL = 'EDIT_PROJECT_FAIL'
export const GET_BRANDS = 'GET_BRANDS'
export const GET_BRANDS_FULFILLED = 'GET_BRANDS_FULFILLED'
export const GET_BRANDS_PENDING = 'GET_BRANDS_PENDING'
export const GET_BRANDS_REJECTED = 'GET_BRANDS_REJECTED'
export const FETCH_PROJECT_DETAILS_ONEDIT = 'FETCH_PROJECT_DETAILS_ONEDIT'
export const FETCH_PROJECT_DETAILS_ONEDIT_FULFILLED =
  'FETCH_PROJECT_DETAILS_ONEDIT_FULFILLED'
export const FETCH_PROJECT_DETAILS_ONEDIT_PENDING =
  'FETCH_PROJECT_DETAILS_ONEDIT_PENDING'
export const FETCH_PROJECT_DETAILS_ONEDIT_REJECTED =
  'FETCH_PROJECT_DETAILS_ONEDIT_REJECTED'
export const UPDATE_PROJECT_ERROR = 'UPDATE_PROJECT_ERROR'
export const UPDATE_PAGINATION = 'UPDATE_PAGINATION'
export const UPDATE_CREATIVE_ASSET_STATUS = 'UPDATE_CREATIVE_ASSET_STATUS'
export const GET_ALL_PROJECT_ASSETS = 'GET_ALL_PROJECT_ASSETS'
export const SET_DEFAULT_PAGINATION = 'SET_DEFAULT_PAGINATION'
export const UPDATE_ASSET_LISTS = 'UPDATE_ASSET_LISTS'
export const RESET_PROJECT_DATA = 'RESET_PROJECT_DATA'
export const SET_PROJECT_COVER_IMAGE = 'SET_PROJECT_COVER_IMAGE'
export const SET_COVER_IMAGE_LOADING = 'SET_COVER_IMAGE_LOADING'
export const UPDATE_PROJECT_LIST_PAGINATION = 'UPDATE_PROJECT_LIST_PAGINATION'
export const SET_SELECTED_ASSETS = 'SET_SELECTED_ASSETS'

export const GET_PROJECT_TASKS = 'GET_PROJECT_TASKS'
export const GET_PROJECT_TASKS_FULFILLED = 'GET_PROJECT_TASKS_FULFILLED'
export const GET_PROJECT_TASKS_PENDING = 'GET_PROJECT_TASKS_PENDING'
export const GET_PROJECT_TASKS_REJECTED = 'GET_PROJECT_TASKS_REJECTED'
