import {
  CLOSE_SIDE_NAV,
  OPEN_SIDE_NAV,
  SET_HEADER_TITLE,
  TOGGLE_RIGHT_SIDENAV,
  SET_SAVE_ALERT_ACTIONTYPE,
} from './actionType'

export const initialState = {
  sideNavIsOpen: false,
  isToggleRightSidenav: false,
  rightSideNavComponent: '',
  headerTitle: '',
  leftMenus: ['aaa', 'bbb'],
  saveAlertActiontype: '',
}

export default function layoutReducer(state = initialState, action = {}) {
  switch (action.type) {
    case OPEN_SIDE_NAV: {
      const newState = {
        ...state,
        sideNavIsOpen: true,
      }
      return newState
    }
    case CLOSE_SIDE_NAV: {
      const newState = {
        ...state,
        sideNavIsOpen: false,
      }
      return newState
    }
    case SET_HEADER_TITLE: {
      const { payload } = action
      const { headerTitle, headerIcon } = payload
      const newState = {
        ...state,
        headerTitle: headerTitle,
        headerIcon: headerIcon,
      }
      return newState
    }
    case TOGGLE_RIGHT_SIDENAV: {
      const { payload } = action
      const { rightSideNavComponent, contentChanged } = payload
      const newState = {
        ...state,
        isToggleRightSidenav: !contentChanged
          ? !state.isToggleRightSidenav
          : state.isToggleRightSidenav,
        rightSideNavComponent: rightSideNavComponent,
      }
      return newState
    }
    case SET_SAVE_ALERT_ACTIONTYPE: {
      const { saveAlertActionType } = action
      const newState = {
        ...state,
        saveAlertActionType: saveAlertActionType,
      }
      return newState
    }
    default:
      return state
  }
}
