import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import PublishAssetDetail from './PublishAssetDetail'
import { showNotification } from '../../../store/notification/actionCreator'
import {
  getChannelAssets,
  setChannelSortField,
  changeChannelAssetFilter,
  handleChannelAssetSelect,
  setChannelSortDirection,
  changeChannelAssetsPage,
  changeChannelAssetsPageSize,
} from '../../../store/channelUpload/channelsActionCreator'
import { downloadAssets } from '../../../store/publishUpload/action'
import firefly from '../../../analytics/firefly'
import { selectUserId } from '../../../store/auth/selector'

function PublishAssetDetailContainer({
  selectedChannel = {},
  defaultAssetPageSize = 10,
  assetsCount = Number(0),
  currentAssetPage = Number(0),
  sortDirection = 'DESC',
  sortField = 'CREATED_AT',
  assetFilter = '',
  selectedAssets = [],
  channelAssets = [],
  channelPendingFiles = [],
  lanId = '',
  changeChannelAssetsPage = () => {},
  getChannelAssets = () => {},
  changeChannelAssetsPageSize = () => {},
  changeChannelAssetFilter = () => {},
  handleChannelAssetSelect = () => {},
  downloadAssets = () => {},
  setChannelSortDirection = () => {},
  setChannelSortField = () => {},
  showNotification = () => {},
}) {
  const { channel_id: selectedChannelId = '' } = selectedChannel

  const pendingFiles = channelPendingFiles.filter((file) => {
    const { asset_channel_id = '' } = file
    return asset_channel_id === selectedChannelId
  })

  const changePageHandler = (event, newpage) => {
    changeChannelAssetsPage(newpage)
    getChannelAssets({
      channelId: selectedChannelId,
      pageNumber: newpage,
      pageSize: defaultAssetPageSize,
      sortDirection,
      sortBy: sortField,
      assetFilter,
    })
  }

  const changeRowsPerPageHandler = (event) => {
    const { target = {} } = event
    const { value: eventValue = '' } = target
    changeChannelAssetsPageSize(eventValue)
    getChannelAssets({
      channelId: selectedChannelId,
      pageNumber: 0,
      pageSize: eventValue,
      sortDirection,
      sortBy: sortField,
      assetFilter,
    })
  }

  const handleClick = (event, checkedAsset) => {
    const selectedIndex = selectedAssets.indexOf(checkedAsset)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedAssets, checkedAsset)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedAssets.slice(1))
    } else if (selectedIndex === selectedAssets.length - 1) {
      newSelected = newSelected.concat(selectedAssets.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedAssets.slice(0, selectedIndex),
        selectedAssets.slice(selectedIndex + 1)
      )
    }
    handleChannelAssetSelect({
      selectedAssets: newSelected,
    })
  }

  const isSelected = (checkedAsset) => {
    const { job_id: checkedAssetId } = checkedAsset
    return (
      selectedAssets
        .map((item) => {
          const { job_id: itemId = '' } = item
          return itemId
        })
        .filter((item) => item === checkedAssetId).length > 0
    )
  }

  const handleSelectAllClick = (event, checked) => {
    let newSelected = []
    if (checked) {
      newSelected = channelAssets.concat(pendingFiles)
    }
    handleChannelAssetSelect({
      selectedAssets: newSelected,
    })
  }

  const downloadSelectedAssets = () => {
    firefly.trackPublishAssetCardDownloads(
      'channel_publish_files_download',
      selectedAssets,
      lanId
    )
    downloadAssets(selectedAssets)
  }

  const copyAssetUrl = (data = {}) => {
    firefly.trackPublishAssetCopy(data, lanId)
    showNotification(
      true,
      'Channel publish asset URL copied to clipboard ',
      'success'
    )
  }

  const searchFilter = () => {
    getChannelAssets({
      channelId: selectedChannelId,
      pageNumber: 0,
      pageSize: defaultAssetPageSize,
      sortDirection,
      sortBy: sortField,
      assetFilter,
    })
  }
  return (
    <PublishAssetDetail
      rowCount={defaultAssetPageSize}
      assetFilter={assetFilter}
      channelAssets={channelAssets}
      pendingFiles={pendingFiles}
      currentAssetPage={currentAssetPage}
      channelAssetsLength={
        channelAssets && pendingFiles
          ? channelAssets.length + pendingFiles.length
          : Number(0)
      }
      numSelected={selectedAssets ? selectedAssets.length : Number(0)}
      defaultAssetPageSize={defaultAssetPageSize}
      sortDirection={sortDirection}
      sortField={sortField}
      assetsCount={assetsCount}
      selectedChannelId={selectedChannelId}
      changeChannelAssetFilter={changeChannelAssetFilter}
      searchFilter={searchFilter}
      getChannelAssets={getChannelAssets}
      downloadSelectedAssets={downloadSelectedAssets}
      handleSelectAllClick={handleSelectAllClick}
      handleClick={handleClick}
      isSelected={isSelected}
      changePage={changePageHandler}
      changeRowsPerPage={changeRowsPerPageHandler}
      setChannelSortDirection={setChannelSortDirection}
      setChannelSortField={setChannelSortField}
      copyAssetUrl={copyAssetUrl}
    ></PublishAssetDetail>
  )
}

PublishAssetDetailContainer.propTypes = {
  classes: PropTypes.object,
  name: PropTypes.string,
  rowsPerPage: PropTypes.number,
  page: PropTypes.number,
}

const mapStateToProps = (state = {}) => {
  const { channelPublish = {} } = state
  const {
    selectedChannel = {},
    channelAssets = [],
    assetsCount = Number(0),
    sortField = 'CREATED_AT',
    assetFilter = '',
    selectedAssets = [],
    sortDirection = 'DESC',
    defaultAssetPageSize = Number(10),
    currentAssetPage = Number(0),
    channelPendingFiles = [],
  } = channelPublish
  return {
    selectedChannel,
    channelAssets,
    channelPendingFiles,
    defaultAssetPageSize,
    currentAssetPage,
    assetsCount,
    selectedAssets,
    sortDirection,
    sortField,
    assetFilter,
    lanId: selectUserId()(state),
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeChannelAssetFilter,
      handleChannelAssetSelect,
      downloadAssets,
      setChannelSortDirection,
      setChannelSortField,
      getChannelAssets,
      showNotification,
      changeChannelAssetsPage,
      changeChannelAssetsPageSize,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PublishAssetDetailContainer)
