import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import CopyToClipboard from 'react-copy-to-clipboard'
import queryString from 'query-string'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withStyles } from '@mui/styles'
import {
  Card,
  CardMedia,
  Grid,
  IconButton,
  Typography,
  Divider,
} from '@mui/material'
import { GetApp, Link } from '@mui/icons-material'

import { setCurrentAssetId, openModal } from '../../../../store/publish/actions'
import { showNotification } from '../../../../store/notification/actionCreator'
import { downloadAsset } from '../../../../helpers/publishHelper'
import NoThumbnail from '../../../../images/NoThumbnail.svg?url'
import { NO_THUMBNAIL_TYPES } from '../../../../constants/publishConstants'
import firefly from '../../../../analytics/firefly'

const styles = (theme) => ({
  card: {
    width: '285px',
    height: '385px',
    borderRadius: '8px',
    position: 'relative',
    boxShadow: '#ececec 0px 0px 5px',
    border: '1px solid #f4f0f0',
    marginRight: 10,
    marginBottom: 20,
  },
  media: {
    height: '295px',
    width: '295px',
    backgroundSize: 'contain',
    margin: '0 auto',
    cursor: 'pointer',
  },
  fileName: {
    padding: '0 10px',
    overflowWrap: 'break-word',
    wordBreak: 'break-all',
    hyphens: 'auto',
    fontSize: 12,
    lineHeight: '16px',
    textAlign: 'left',
    cursor: 'pointer',
    color: '#0D46A0',
    overflow: 'hidden',
    maxWidth: '230px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    paddingBottom: 4,
  },
  fileTypeIcon: {
    height: '40px',
    width: '40px',
    borderRadius: '50%',
    cursor: 'pointer',
  },
  fileInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: 16,
    marginRight: 16,
  },
  infoSection: {
    padding: 8,
    alignItems: 'center',
    height: 50,
    fontSize: 12,
  },
  uploadSection: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  uploadDateSection: {
    padding: '0 10px',
  },
})

const AssetCard = ({
  classes = {},
  asset = {},
  openModal = () => {},
  setCurrentAssetId = () => {},
  router = {},
  lanId = '',
  showNotification = () => {},
}) => {
  const {
    asset_id = '',
    asset_url = '',
    publishers = [],
    preview_url = '',
  } = asset
  const lastPublisher = Object.assign({}, publishers[publishers.length - 1])
  const {
    file_name = '',
    file_extension = 'jpg',
    published_at = Number(0),
  } = lastPublisher
  const openAssetModal = () => {
    openModal()
    setCurrentAssetId(asset_id)
    router.navigate(`/publishing/${asset_id}`)
  }
  const onDownloadClicked = (e) => {
    e.preventDefault()
    firefly.trackPublishAssetCardDownloads(
      'publish_asset_card_download',
      asset,
      lanId
    )
    downloadAsset(file_name, asset_url, file_extension)
  }

  const copyAssetLink = () => {
    firefly.trackPublishAssetCopy(asset, lanId)
    showNotification(true, 'Publish asset URL copied to clipboard ', 'success')
  }

  return (
    <Card raised className={classes.card}>
      <Grid className={classes.infoSection}>
        <Typography className={classes.fileName} onClick={openAssetModal}>
          {file_name}
        </Typography>
        <div
          data-cy="assetCardUploadDate"
          className={classes.uploadDateSection}
        >
          {published_at
            ? `Upload Date: ${moment(published_at).format(
                'MMM D, YYYY h:mm a'
              )}`
            : null}
        </div>
      </Grid>

      <Divider />

      {NO_THUMBNAIL_TYPES.includes(file_extension) ? (
        <img
          onClick={openAssetModal}
          className={classes.media}
          src={NoThumbnail}
          alt="No Thumbnail Generated"
        />
      ) : (
        <CardMedia
          onClick={openAssetModal}
          className={classes.media}
          image={`${preview_url}?${queryString.stringify({
            key: process.env.REACT_APP_DAP_KEY,
            width: 500,
            height: 500,
            make_square: true,
          })}`}
          title={file_name}
        />
      )}

      <Divider />

      <Grid className={classes.fileInfo}>
        <Grid className={classes.uploadSection}>
          <IconButton title={`https:${asset_url}`} size="large">
            <CopyToClipboard
              text={`https:${asset_url}`}
              onCopy={() => copyAssetLink()}
            >
              <Link />
            </CopyToClipboard>
          </IconButton>
          <IconButton onClick={onDownloadClicked} size="large">
            <GetApp />
          </IconButton>
        </Grid>
      </Grid>
    </Card>
  )
}

AssetCard.propTypes = {
  classes: PropTypes.object.isRequired,
  asset: PropTypes.object.isRequired,
  openModal: PropTypes.func.isRequired,
  setCurrentAssetId: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  lanId: PropTypes.string.isRequired,
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      openModal,
      setCurrentAssetId,
      showNotification,
    },
    dispatch
  )

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(AssetCard))
