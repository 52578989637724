import React from 'react'
import { makeStyles } from '@mui/styles'
import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'
import CardContent from '@mui/material/CardContent'

import { isEmpty } from 'lodash'
import { grey as checkIconColor } from '@mui/material/colors'
import CustomCardHeader from './CustomCardHeader'
import CustomCardFooter from './CustomCardFooter'
import { praxis } from '../../../config/themeConfig'

const boarderColor = checkIconColor[300]
const useStyles = makeStyles({
  card: {
    border: '1px solid ' + boarderColor,
    margin: '5px',
    display: 'flex',
    flexFlow: 'column wrap',
    // flexGrow: 1,
  },
  cardHeaderLinked: {
    color: praxis.primaryBlue,
    backgroundColor: praxis.lightestGrey,
    cursor: 'pointer',
    borderBottom: '1px solid ' + boarderColor,
  },
  colorTextSecondary: {
    color: '#484848',
    fontWeight: '300',
    fontSize: 13,
  },
  cardHeader: {
    backgroundColor: praxis.lightestGrey,
  },
  colorTextHeaader: {
    color: praxis.primaryBlue,
    fontSize: 18,
  },
  actionIconText: {
    fontSize: '14px',
    color: '#000',
  },
  rightActionsWithExpand: {
    display: 'flex',
  },
  leftActions: {
    flex: 1,
  },
  customFooterContent: {
    backgroundColor: praxis.lightestGrey,
    padding: '5px',
  },
  cardContentBottom: {
    height: 'auto',
    margin: 'auto 0 0',
    display: 'flex',
    alignItems: 'center',
  },
  footerActions: {
    color: '#212121',
    display: 'flex',
    fontSize: '14px',
    backgroundColor: praxis.lightestGrey,
    height: 'auto',
    margin: 'auto 0 0',
    justifyContent: 'space-between',
  },
  cardContent: {
    minHeight: '100px',
    padding: '10px !important',
  },
})

function CustomCard(props) {
  const classes = useStyles()
  const {
    minWidth = '500px',
    minHeight = '100px',
    width: cardWidth = 'auto',
    height: cardHeight = 'auto',
    cardContent = '',
    cardConfig = {},
  } = props

  const { cardMedia = {}, displayFooter = false, cardHeader = {} } = cardConfig
  const {
    imagePath = '',
    altText = '',
    height = '194',
    compType = 'img',
  } = cardMedia

  return (
    <Card
      sx={{
        minWidth: minWidth,
        minHeight: minHeight,
        width: cardWidth,
        height: cardHeight,
      }}
      className={classes.card}
    >
      {!isEmpty(cardHeader) && (
        <CustomCardHeader classes={classes} {...props} />
      )}
      {!isEmpty(cardMedia) && (
        <CardMedia
          component={compType}
          height={height}
          image={imagePath}
          alt={altText}
        />
      )}
      <CardContent className={classes.cardContent}>{cardContent}</CardContent>
      {displayFooter ? <CustomCardFooter classes={classes} {...props} /> : ''}
    </Card>
  )
}

export default CustomCard
