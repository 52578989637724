export const departmentRegex = /^[Dd]\d{3}$/
export const dpciWithHyphenRegex = /^\d{3}[-]\d{2}[-]\d{4}$/
export const dpciWithoutHyphenRegex = /^\d{9}$/
export const fileNameRegex = /[^\s]+(\.(jpg|png|gif|svg|pdf|psd))$/ ///^([a-zA-Z0-9\s_\\.\-\(\):&])+(.\w{3})$/
export const projectIDRegex = /^[Cc][-]\d{6}[-]\d{2}[-]\d{3}$/
export const propertyNumberRegex = /^[Tt]\d{4}$/
export const tcinRegex = /^\d{8}$/
export const fieldValidRegex = /[%<>]/g
export const metadataDRMRegex = /\d{3,4}/g
export const metadataStoreNoRegex = /\d{3,4}/g
export const emailInAStringRegex =
  /([a-zA-Z0-9._+-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi
export const downloadFilePathReplaceRegex = /https:\/\/.*\/v1/g
export const downloadAHFilePathReplaceRegex =
  /https:\/\/.*\/assethub_assets\/v1/g
export const polDomainRegex = /\.partnersonline\.com/g
export const assethubFastlyDomainRegex = /assethub\.target\.com/g
export const boardDashboardRouteRegex = /\/boards\/(.*)\/dashboard/
export const targetDomainRegex =
  /^([^/]+:)?\/{2,3}[^/]+?(\.target\.com|\.tgt)(:|\/|$)/i
