import React from 'react'
import { Paper } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  itemBackground: {
    background: '#f1c3c4',
    borderRadius: '50%',
    width: '500px',
    height: '500px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

function CarouselItem({ item = {} }) {
  const classes = useStyles()
  const {
    renderImage = false,
    renderCustomComponent = false,
    featureType = '',
    imageName = '',
  } = item
  return (
    <Paper className={classes.itemBackground}>
      {renderImage ? (
        <img src={imageName} alt="assethub" />
      ) : renderCustomComponent ? (
        <item.component featureType={featureType} />
      ) : (
        ''
      )}
    </Paper>
  )
}

export default CarouselItem
