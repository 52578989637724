import React from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import Button from '@mui/material/Button'
import Avatar from '@mui/material/Avatar'
import Paper from '@mui/material/Paper'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Card from '@mui/material/Card'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp'
import IconButton from '@mui/material/IconButton'
import Grid from '@mui/material/Grid'
import Chip from '@mui/material/Chip'
import Moment from 'moment'
import AddBoxIcon from '@mui/icons-material/AddBox'
import PeopleIcon from '@mui/icons-material/People'
import MailIcon from '@mui/icons-material/Mail'
import momentTime from 'moment-timezone'
import PropTypes from 'prop-types'
import dividerImage from '../../images/divider.png'
import {
  expandTaskNotifications,
  collapseTaskNotifications,
} from './../../store/task/actionCreator'

const styles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: useTheme().spacing(3),
    overflowX: 'auto',
  },
  avatar: {
    backgroundColor: '#FFF',
  },
  avatarIcon: {
    color: '#8D8D8D',
  },
  Label: {
    width: 70,
    paddingRight: 10,
    fontSize: 16,
    color: '#8D8D8D',
  },
  EditLabel: {
    width: 70,
    paddingRight: 10,
    fontSize: 16,
    color: '#0D46A0',
  },
  buttonBlue: {
    backgroundColor: '#EFEFEF',
    color: '#0D46A0',
    '&:hover': {
      backgroundColor: '#EFEFEF',
    },
  },
  buttonGey: {
    backgroundColor: '#FAFAFA',
    color: '#212121',
    '&:hover': {
      backgroundColor: '#FAFAFA',
    },
  },
  unSaveNotification: {
    backgroundColor: '#EFEFEF',
    color: '#6e6e6e',
    border: '1px solid #EFEFEF',
    borderBottom: 0,
    minHeight: 50,
  },
  saveNotification: {
    backgroundColor: '#EFF7EF',
    border: '1px solid #EFEFEF',
    borderBottom: 0,
    minHeight: 50,
  },
  seprate: {
    padding: '0 3px 0 9px;',
  },
  mdSubhead: {
    fontSize: 18,
    fontWeight: 400,
    color: '#212121',
  },
  notificationSave: {
    color: '#4CAF50',
    fontWeight: 400,
  },
  notificationWarning: {
    color: '#CAB350',
    fontWeight: 400,
  },
  statusIcon: {
    marginRight: 5,
    top: 6,
    position: 'relative',
  },
  emptyWrapper: {
    border: '2px dashed #c5c5c5',
    textAlign: 'center',
    margin: -5,
    marginTop: 20,
    minHeight: 200,
    color: '#8D8D8D',
  },
  divider: {
    width: '100%',
    maxWidth: '100%',
    padding: 20,
  },
  IconGey: {
    borderRadius: '50%',
    backgroundColor: '#8D8D8D',
    color: '#D6D6D6',
  },
  IconBlue: {
    borderRadius: '50%',
    backgroundColor: '#0D46A0',
  },
}))

class TaskCurrentList extends React.Component {
  constructor(props) {
    super(props)
    let sentToUsers = props.data.sentList
    let subject = props.data.subject
    this.state = {
      subject: subject,
      openNotificationForm: false,
      notifications: props.data,
      sentToUsers: sentToUsers,
    }
  }
  initial(email) {
    const name = email.substring(0, email.lastIndexOf('@'))
    const split = name.toLowerCase().split('.')
    const fname = split[0].charAt(0).toUpperCase()
    const lname = split[1]
      ? split[1].charAt(0).toUpperCase()
      : split[0].charAt(1).toUpperCase()
    return fname + lname
  }
  showCST = function (dateParam) {
    let cstTime = new Date(momentTime.tz(dateParam, 'America/chicago'))
    let scheduleTime = Moment(cstTime).format('MM/DD/YYYY HH:mm:A')
    return scheduleTime
  }
  render() {
    const {
      classes,
      notificationType,
      notificationIndex,
      loading: { toggleTaskNotifications },
    } = this.props
    return (
      <div>
        {this.props.data ? (
          <Paper style={{ boxShadow: 'none' }}>
            {[this.props.data].map((dataObj) => {
              const { body = '' } = dataObj
              let rowExpanded =
                Object.keys(toggleTaskNotifications).length > 0 &&
                toggleTaskNotifications[notificationType] &&
                toggleTaskNotifications[notificationType][notificationIndex]
                  ? toggleTaskNotifications[notificationType][notificationIndex]
                  : false
              return (
                <Card
                  spacing={1}
                  key={notificationIndex}
                  style={{ marginBottom: 10 }}
                >
                  <CardHeader
                    action={
                      <div>
                        {rowExpanded ? (
                          <IconButton
                            aria-label="Expand"
                            onClick={() => {
                              this.props.expandTaskNotifications(
                                notificationType,
                                notificationIndex
                              )
                            }}
                            size="large"
                          >
                            <KeyboardArrowUp />
                          </IconButton>
                        ) : (
                          <IconButton
                            aria-label="Collapse"
                            onClick={() => {
                              this.props.collapseTaskNotifications(
                                notificationType,
                                notificationIndex
                              )
                            }}
                            size="large"
                          >
                            {' '}
                            <KeyboardArrowDown />
                          </IconButton>
                        )}
                      </div>
                    }
                    title={
                      <div className={classes.mdSubhead}>
                        {this.props.data.notification_type ? (
                          <span> {this.props.data.notification_type} </span>
                        ) : (
                          <span> Notification</span>
                        )}{' '}
                        <span className={classes.seprate}>|</span>
                        <MailIcon className={classes.statusIcon} />
                      </div>
                    }
                    className={classes.saveNotification}
                  />
                  {rowExpanded && (
                    <Paper style={{ boxShadow: 'none' }}>
                      <CardContent
                        className={classes.cardCont}
                        style={{ backgroundColor: 'rgba(239,239,239,.25)' }}
                      >
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                          spacing={1}
                        >
                          <Grid item className={classes.Label}>
                            Send :
                          </Grid>
                          <Grid item sm style={{ marginLeft: 10 }}>
                            <span>
                              <Chip
                                label={`${this.showCST(
                                  this.props.data.time
                                )} CST`}
                                className={classes.chip}
                                variant="outlined"
                              />
                            </span>
                          </Grid>
                          <Grid item xs={12} />
                          <Grid item className={classes.Label}>
                            To :
                          </Grid>
                          <Grid item sm style={{ marginLeft: 10 }}>
                            <Chip
                              className={classes.chipName}
                              label={`${this.props.data.to_address.type} | ${this.props.data.to_address.count} people`}
                              avatar={
                                <PeopleIcon className={classes.IconGey} />
                              }
                            />
                          </Grid>
                          <Grid item xs={12} />
                          {this.props.data.cc_address && (
                            <Grid item className={classes.Label}>
                              CC :
                            </Grid>
                          )}
                          {this.props.data.cc_address && (
                            <Grid item sm style={{ marginLeft: 10 }}>
                              <Chip
                                className={classes.chipName}
                                label={this.props.data.cc_address}
                                avatar={
                                  <Avatar className={classes.IconBlue}>
                                    {this.initial(this.props.data.cc_address)}
                                  </Avatar>
                                }
                              />
                            </Grid>
                          )}
                          <Grid item xs={12} />
                          <Grid item className={classes.Label}>
                            From :
                          </Grid>
                          <Grid item sm style={{ marginLeft: 10 }}>
                            <Chip
                              className={classes.chipName}
                              label={this.props.data.from_address}
                              avatar={
                                <Avatar className={classes.IconBlue}>
                                  {this.initial(this.props.data.from_address)}
                                </Avatar>
                              }
                            />
                          </Grid>
                          <Grid item xs={12} />
                          <Grid item className={classes.Label}>
                            Subject :
                          </Grid>
                          <Grid item sm style={{ marginLeft: 3 }}>
                            {this.props.data.subject}
                          </Grid>
                          <Grid item xs={12} />
                          <Grid
                            item
                            className={classes.Label}
                            style={{ alignSelf: 'start' }}
                          >
                            Body :
                          </Grid>
                          <Grid
                            item
                            sm
                            style={{ marginLeft: 10, marginTop: -15 }}
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: body,
                              }}
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Paper>
                  )}
                </Card>
              )
            })}
          </Paper>
        ) : (
          <div className={classes.emptyWrapper}>
            <p>No notifications are scheduled to be sent for this task.</p>
            <div className={classes.divider}>
              <img
                className={classes.assetDivider}
                src={dividerImage}
                alt="no tasks"
              />
            </div>
            <Button
              onClick={() => this.openTaskFrom()}
              color="primary"
              variant="contained"
            >
              <AddBoxIcon style={{ marginRight: 10 }} /> SCHEDULE A NOTIFICATION
            </Button>
          </div>
        )}
      </div>
    )
  }
}

TaskCurrentList.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.shape({
    notification_type: PropTypes.object,
    body: PropTypes.object,
    subject: PropTypes.object,
    cc_address: PropTypes.object,
    time: PropTypes.object,
    sentList: PropTypes.object,
    from_address: PropTypes.object,
    to_address: PropTypes.shape({
      type: PropTypes.object,
      count: PropTypes.object,
    }),
  }),
  loading: PropTypes.shape({
    toggleTaskNotifications: PropTypes.objectOf({
      sent_notification: PropTypes.arrayOf(PropTypes.object),
      schedule_notification: PropTypes.arrayOf(PropTypes.object),
    }),
  }),
  notificationType: PropTypes.string,
  notificationIndex: PropTypes.number,
  expandTaskNotifications: PropTypes.func,
  collapseTaskNotifications: PropTypes.func,
}

const mapStateToProps = (state) => {
  const auth = state.auth
  return {
    auth,
  }
}

const MyComponent = (props) => {
  const classes = styles()
  return <TaskCurrentList {...props} classes={classes} />
}

export default connect(mapStateToProps, {
  expandTaskNotifications,
  collapseTaskNotifications,
})(MyComponent)
