// Get All Assets for specific Task
export const GET_TASK_ASSETS = 'GET_TASK_ASSETS'
export const GET_TASK_ASSETS_FULFILLED = 'GET_TASK_ASSETS_FULFILLED'
export const GET_TASK_ASSETS_PENDING = 'GET_TASK_ASSETS_PENDING'
export const GET_TASK_ASSETS_REJECTED = 'GET_TASK_ASSETS_REJECTED'

// Get All comment number info
export const GET_ALL_COMMENT_INFO = 'GET_ALL_COMMENT_INFO'
export const GET_ALL_COMMENT_INFO_FULFILLED = 'GET_ALL_COMMENT_INFO_FULFILLED'
export const GET_ALL_COMMENT_INFO_PENDING = 'GET_ALL_COMMENT_INFO_PENDING'
export const GET_ALL_COMMENT_INFO_REJECTED = 'GET_ALL_COMMENT_INFO_REJECTED'

// Get all task users for mentions
export const GET_TASK_USERS = 'GET_TASK_USERS'
export const GET_TASK_USERS_FULFILLED = 'GET_TASK_USERS_FULFILLED'
export const GET_TASK_USERS_PENDING = 'GET_TASK_USERS_PENDING'
export const GET_TASK_USERS_REJECTED = 'GET_TASK_USERS_REJECTED'

// Get all comments for specific Asset and revision
export const GET_ASSET_COMMENTS = 'GET_ASSET_COMMENTS'
export const GET_ASSET_COMMENTS_FULFILLED = 'GET_ASSET_COMMENTS_FULFILLED'
export const GET_ASSET_COMMENTS_PENDING = 'GET_ASSET_COMMENTS_PENDING'
export const GET_ASSET_COMMENTS_REJECTED = 'GET_ASSET_COMMENTS_REJECTED'

export const GET_SECONDARY_ASSET_COMMENTS = 'GET_SECONDARY_ASSET_COMMENTS'
export const GET_SECONDARY_ASSET_COMMENTS_FULFILLED =
  'GET_SECONDARY_ASSET_COMMENTS_FULFILLED'
export const GET_SECONDARY_ASSET_COMMENTS_PENDING =
  'GET_SECONDARY_ASSET_COMMENTS_PENDING'
export const GET_SECONDARY_ASSET_COMMENTS_REJECTED =
  'GET_SECONDARY_ASSET_COMMENTS_REJECTED'

// Get all versions for specific Asset
export const FETCH_ASSET_VERSIONS = 'FETCH_ASSET_VERSIONS'
export const FETCH_ASSET_VERSIONS_FULFILLED = 'FETCH_ASSET_VERSIONS_FULFILLED'
export const FETCH_ASSET_VERSIONS_PENDING = 'FETCH_ASSET_VERSIONS_PENDING'
export const FETCH_ASSET_VERSIONS_REJECTED = 'FETCH_ASSET_VERSIONS_REJECTED'

export const FETCH_SECONDARY_ASSET_VERSIONS = 'FETCH_SECONDARY_ASSET_VERSIONS'
export const FETCH_SECONDARY_ASSET_VERSIONS_FULFILLED =
  'FETCH_SECONDARY_ASSET_VERSIONS_FULFILLED'
export const FETCH_SECONDARY_ASSET_VERSIONS_PENDING =
  'FETCH_SECONDARY_ASSET_VERSIONS_PENDING'
export const FETCH_SECONDARY_ASSET_VERSIONS_REJECTED =
  'FETCH_SECONDARY_ASSET_VERSIONS_REJECTED'
export const RESET_ASSETS = 'RESET_ASSETS'

// Set viewed user for one asset
export const SET_VIEWED_USERS = 'SET_VIEWED_USERS'

// Create Comment
export const CREATE_COMMENT = 'CREATE_COMMENT'
export const CREATE_COMMENT_FULFILLED = 'CREATE_COMMENT_FULFILLED'
export const CREATE_COMMENT_PENDING = 'CREATE_COMMENT_PENDING'
export const CREATE_COMMENT_REJECTED = 'CREATE_COMMENT_REJECTED'

// Update Comment
export const UPDATE_COMMENT = 'UPDATE_COMMENT'
export const UPDATE_COMMENT_FULFILLED = 'UPDATE_COMMENT_FULFILLED'
export const UPDATE_COMMENT_PENDING = 'UPDATE_COMMENT_PENDING'
export const UPDATE_COMMENT_REJECTED = 'UPDATE_COMMENT_REJECTED'

// Delete Comment
export const DELETE_COMMENT = 'DELETE_COMMENT'
export const DELETE_COMMENT_FULFILLED = 'DELETE_COMMENT_FULFILLED'
export const DELETE_COMMENT_PENDING = 'DELETE_COMMENT_PENDING'
export const DELETE_COMMENT_REJECTED = 'DELETE_COMMENT_REJECTED'

// Resolve Comment
export const RESOLVE_COMMENT = 'RESOLVE_COMMENT'
export const RESOLVE_COMMENT_FULFILLED = 'RESOLVE_COMMENT_FULFILLED'
export const RESOLVE_COMMENT_PENDING = 'RESOLVE_COMMENT_PENDING'
export const RESOLVE_COMMENT_REJECTED = 'RESOLVE_COMMENT_REJECTED'

// Local Markup Actions
export const STORE_CURRENT_MARKUP = 'STORE_CURRENT_MARKUP'
export const CLEAR_CURRENT_MARKUP = 'CLEAR_CURRENT_MARKUP'

// Local Primary and Secondary Asset Actions
export const SET_PRIMARY_ASSET = 'SET_PRIMARY_ASSET'
export const SET_SECONDARY_ASSET = 'SET_SECONDARY_ASSET'
export const SET_PRIMARY_VERSION = 'SET_PRIMARY_VERSION'
export const SET_SECONDARY_VERSION = 'SET_SECONDARY_VERSION'
export const SET_PRIMARY_PAGE_NUMBER = 'SET_PRIMARY_PAGE_NUMBER'
export const SET_SECONDARY_PAGE_NUMBER = 'SET_SECONDARY_PAGE_NUMBER'
export const SET_PRIMARY_ASSET_REVISION_HISTORY =
  'SET_PRIMARY_ASSET_REVISION_HISTORY'
export const SET_SECONDARY_ASSET_REVISION_HISTORY =
  'SET_SECONDARY_ASSET_REVISION_HISTORY'

// Toggle Comparison View
export const TOGGLE_COMPARISON_VIEW = 'TOGGLE_COMPARISON_VIEW'

// SET_TASK_STATUS
export const SET_TASK_STATUS = 'SET_TASK_STATUS'
export const SET_TASK_STATUS_FULFILLED = 'SET_TASK_STATUS_FULFILLED'

export const RESET_PRIMARY_COMMENTS = 'RESET_PRIMARY_COMMENTS'
export const RESET_SECONDARY_COMMENTS = 'RESET_SECONDARY_COMMENTS'
export const FROM_COMPARISON_TO_SINGLE = 'FROM_COMPARISON_TO_SINGLE'
