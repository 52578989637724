import { useState } from 'react'
import cx from 'classnames'
import { makeStyles } from '@mui/styles'
import Avatar from '@mui/material/Avatar'
import { getInitials } from '../../helpers/UtilityHelper'
import { praxis } from '../../config/themeConfig'
import apiConfig from '../../config/apiConfig'

const styles = makeStyles(() => ({
  avatar: {
    backgroundColor: praxis.lightYellow,
    width: '26px',
    height: '26px',
    fontSize: '14px',
    color: praxis.contrastText,
    marginLeft: '4px',
    '& img': {
      width: '100%',
    },
  },
}))

const CustomAvatar = ({ customClasses = {}, userDetails = {} }) => {
  const classes = styles()
  const [hasLoaded, setHasLoaded] = useState(false)
  const { displayName = '', loginId = '' } = userDetails
  const imageUrl = loginId
    ? `${apiConfig.avatarUrl}DHC_${loginId}_MThumb.jpg`
    : ''
  const initials = getInitials(displayName)

  return (
    <Avatar
      aria-label="Profile"
      className={cx(classes.avatar, customClasses.avatar)}
    >
      {imageUrl && (
        <img
          alt=""
          style={hasLoaded ? {} : { display: 'none' }}
          src={imageUrl}
          onLoad={() => setHasLoaded(true)}
        />
      )}
      <span style={hasLoaded ? { display: 'none' } : {}}>{initials}</span>
    </Avatar>
  )
}

export default CustomAvatar
