import React from 'react'
import CardHeader from '@mui/material/CardHeader'
import withRouter from '../../../containers/Router/WithRouter'
import { renderCardActions } from './CustomCardHOC'

function CustomCardHeader({
  router = {},
  cardConfig = {},
  classes = {},
  dispatchClickAction = () => {},
}) {
  const { cardHeader = {} } = cardConfig
  const {
    location: { pathname: locationPath = '' },
  } = router
  const {
    identifier = '',
    headerAvatar = '',
    headerActions = [],
    title = '',
    subheader = '',
    headerActionContent = '',
  } = cardHeader

  const actionClickHandler = (actionName = '') => {
    dispatchClickAction('headerActions', actionName)
  }

  return (
    <CardHeader
      avatar={headerAvatar}
      action={
        headerActionContent
          ? headerActionContent
          : headerActions && headerActions.length
          ? renderCardActions(headerActions, 'right', actionClickHandler)
          : ''
      }
      title={title}
      subheader={subheader}
      className={classes.cardHeaderLinked}
      subheaderTypographyProps={{
        className: classes.colorTextSecondary,
        'data-cy': `${locationPath}_subheader`,
      }}
      titleTypographyProps={{
        className: classes.colorTextHeaader,
        'data-cy': `${locationPath}_title`,
      }}
      onClick={() => {
        dispatchClickAction('headerTitleClick', identifier)
      }}
    />
  )
}

export default withRouter(CustomCardHeader)
