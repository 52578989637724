import { withStyles } from '@mui/styles'
import { Badge } from '@mui/material'

export const StyledBadge = withStyles({
  badge: {
    background: '#4a66cc',
    color: '#FFFFFF',
    padding: '2px',
  },
})(Badge)

export const SmallBadge = withStyles({
  badge: {
    background: '#4a66cc',
    color: '#FFFFFF',
    top: '5px',
    right: '5px',
  },
})(Badge)

export default { StyledBadge, SmallBadge }
