import { isEmpty } from 'lodash'
import {
  INTERNAL_USAGE_CHANNEL_LABEL,
  OTHER_CHANNEL_LABEL,
} from '../constants/metadata'
import { formatCSTDate, getNonProductBrand } from '../utils/utils'

export const getEditableMetadata = (assetMetadata = {}) => {
  const {
    metadata: {
      file_metadata = {},
      asset_descriptions = {},
      creative_usage_instructions = {},
      asset_classification = {},
      item_info = [],
      usage_info = [],
    } = {},
    librarian_review_status = '',
  } = assetMetadata

  const { file_name = '' } = file_metadata ?? {}

  const { classification_id = '' } = asset_classification ?? {}

  const {
    shot_types = null,
    keywords = null,
    subject = null,
  } = asset_descriptions ?? {}

  const {
    do_not_use = '',
    instructions = '',
    additional_directions = '',
  } = creative_usage_instructions ?? {}

  const usageRightsData =
    usage_info?.map((a) => a.drm_id)?.filter((obj) => !isEmpty(obj)) || []
  const tcinData =
    item_info?.map((a) => a.tcin)?.filter((obj) => !isEmpty(obj)) || []
  let userDefinedBrandName = null
  if (item_info?.length) {
    const nonProductInfo = getNonProductBrand(item_info ?? [])
    const { brand = null } = nonProductInfo ?? {}
    userDefinedBrandName = brand
  }
  return {
    file_name,
    classification_id,
    creative_usage_rights: {
      do_not_use: do_not_use,
      instructions,
      additional_directions,
    },
    asset_descriptions: {
      keywords,
      shot_types,
      subject,
    },
    drm_ids: usageRightsData ?? [],
    tcins: tcinData ?? [],
    librarian_review_status,
    user_defined_brand_name: userDefinedBrandName,
  }
}

export const getUpdateMetadataPayload = (assetId = '', editedData = {}) => {
  const { drm_ids, tcins } = editedData
  const updateData = {
    ...editedData,
    tcins: {
      data: tcins,
      action_type: 'MERGE',
    },
    drm_ids: {
      data: drm_ids,
      action_type: 'MERGE',
    },
  }
  return [
    {
      asset_id: assetId,
      update_data: updateData,
    },
  ]
}

export const getUsageInfoDetailsInfo = (usageInformation = {}) => {
  const {
    available_territories = '',
    buyer = '',
    creation_date = '',
    project_id = '',
    rights_holder_type = '',
    terms = '',
  } = usageInformation
  const createdDateFormatted = formatCSTDate(creation_date, 'll') || ''
  return [
    { label: 'Buyer', value: buyer },
    { label: 'Rights holder type', value: rights_holder_type },
    { label: 'Available territories', value: available_territories },
    { label: 'Created Date', value: createdDateFormatted },
    { label: 'Project ID/Name', value: project_id },
    { label: 'Terms', value: terms },
  ]
}

export const getAvailableChannelsInfo = (
  channels = [],
  isInternalUsage = false,
  isOtherChannelUsage = false,
) => {
  if (isInternalUsage) return INTERNAL_USAGE_CHANNEL_LABEL
  else if (isOtherChannelUsage) return OTHER_CHANNEL_LABEL
  else return (channels?.[0] || {}).label || ''
}
