import React from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { get, isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import withRouter from '../../containers/Router/WithRouter'
import HeaderTitle from '../Header/HeaderTitle'
import AssetList from './AssetList'
import TaskPeopleList from './TaskPeopleList'
import TaskNotificationsList from './TaskNotificationsList'
import InfoStepper from './InfoStepper'
import {
  getTaskFullDetails,
  expandTaskGroups,
  collapseTaskGroup,
} from './../../store/task/actionCreator'
import HeaderTabs from '../ProjectsPage/project/dashboard/HeaderTabs'
import { getQueryParams } from '../../helpers/UrlHelper'
import {
  getActiveStepName,
  getCreateTaskDefaultInfo,
  getEditTaskDefaultInfo,
} from '../../helpers/TaskHelper'

const styles = {
  homePage: {
    textAlign: 'left',
  },
}

export class TaskPage extends React.Component {
  constructor(props) {
    super(props)
    const { router = {} } = props
    const queryParams = getQueryParams(router.location)
    this.state = {
      activeStep: 'asset',
      step: 0, // 0 -> step1 Default step
      isEdit: get(this.props, 'router.location.state.task.isEdit', false),
      editTaskInfo: get(
        this.props,
        'router.location.state.task.editTaskInfo',
        {}
      ),
      queryParams,
      project: {
        ...(get(this.props, 'router.location.state.task.project', {}) ||
          get(this.props, 'task.taskList.userAssets', {}) ||
          {}),
        id:
          get(this.props, 'router.location.state.task.project.projectId', '') ||
          get(this.props, 'task.taskList.userAssets.project_id', '') ||
          '',
      },
      task: {
        taskFormData: {
          taskName: get(
            this.props,
            'router.location.state.task.task.selectedTaskName',
            ''
          ),
          scheduledDate: get(
            this.props,
            'router.location.state.task.task.scheduledDate',
            ''
          ),
          scheduledTime: get(
            this.props,
            'router.location.state.task.task.scheduledTime',
            ''
          ),
          taskType: get(
            this.props,
            'router.location.state.task.task.taskTypes',
            ''
          ),
        },
        default: {
          assets: [],
          participants: [],
          notification: [],
        },
        current: {
          assets: [],
          participants: [],
          notification: [],
        },
      },
    }
    this.handleStepper = this.handleStepper.bind(this)
    this.expandTaskGroup = this.expandTaskGroup.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    const { isEdit, task = {} } = this.state
    if (
      isEdit &&
      Object.keys(nextProps.task.taskInfo).length > 0 &&
      Object.keys(task.current.assets).length === 0
    ) {
      const editTaskInfo = nextProps.task.taskInfo
      const { task: projectTask = {} } = this.props
      const taskDefaultInfo = getEditTaskDefaultInfo(editTaskInfo, projectTask)
      let task = { ...this.state.task }
      task.default = taskDefaultInfo
      this.setState({ task })
    }
  }

  handleStepper(data, type) {
    // Set the changed Data from step 0 and step 1
    // let count = type + 'Count'
    let resultAsset = []
    if (type === 'assets') {
      this.state.task.default.assets.forEach(function (value) {
        let isChecked = false
        data.every(function (asset) {
          if (asset.assetId && asset.assetId === value.asset_id) {
            isChecked = true
            return false
          }
          return true
        })
        resultAsset.push({ ...value, isChecked })
      })
    }
    this.setState({
      task: {
        ...this.state.task,
        current: {
          ...this.state.task.current,
          [type]: resultAsset.length ? resultAsset : data,
        },
      },
    })
  }

  handleChangeValue = (value) => {
    this.setState({ step: value })
  }

  componentDidMount() {
    let isEdit = get(this, 'props.router.location.state.task.isEdit', false)
    let editTaskInfo = get(
      this,
      'props.router.location.state.task.editTaskInfo',
      false
    )
    if (isEdit) {
      this.props.getTaskFullDetails(editTaskInfo.job_id, isEdit)
    }
  }

  componentWillMount() {
    const { history = {}, projectId = '', task = {}, router = {} } = this.props
    const { taskList = {} } = task

    if (isEmpty(taskList)) {
      let url
      if (projectId) {
        url = '/project/' + projectId + '/tasks/list'
      } else {
        url = '/projects'
      }
      router.navigate(url)
    } else if (!router.location.state.task.isEdit) {
      let task = { ...this.state.task }
      task.default = getCreateTaskDefaultInfo(taskList)
      this.setState({ task })
    }
  }

  expandTaskGroup(groupId, expanded, taskId) {
    if (expanded) {
      this.props.collapseTaskGroup(taskId)
    } else {
      this.props.expandTaskGroups(groupId, taskId)
    }
  }
  generateBreadcrumb(arr, title) {
    return arr
      .map((y) =>
        y.path
          ? !title
            ? '<a style="text-decoration: none" href="' +
              y.path +
              '">' +
              y.title +
              '</a>'
            : y.title
          : y.title
      )
      .join(!title ? ' <span style="color: #ccc">/</span> ' : ' / ')
  }

  render() {
    const { classes = {}, task = {}, history = {}, router = {} } = this.props
    const { queryParams = {}, step } = this.state
    const { taskType: queryParamsTaskType = '' } = queryParams
    const uploadTaskType = queryParams.taskType === 'upload'
    const activeStepName = getActiveStepName(step, uploadTaskType)
    let projectName =
      get(this.props, 'task.taskList.userAssets.project_name', '') ||
      get(this.props, 'router.location.state.project.name', '') ||
      get(this.state, 'project.name', '')
    const ttitle = !this.state.isEdit
        ? 'Project Task Create'
        : 'Project Task Edit',
      breadCrumb = projectName
        ? [
            {
              title: 'Projects',
              path: '/projects',
            },
            {
              title: projectName,
            },
          ]
        : [{ title: ttitle }]

    return (
      <div className={classes.homeBg}>
        <HeaderTitle
          title={this.generateBreadcrumb(breadCrumb)}
          icon="Project"
        />
        <Helmet>
          <title>{this.generateBreadcrumb(breadCrumb, true)}</title>
        </Helmet>
        <HeaderTabs activeTabIndex={2} id={this.props.projectId} />
        <div>
          <InfoStepper
            onChangeValue={this.handleChangeValue}
            info={this.state}
            queryParams={queryParams}
            {...this.props}
          />
          {task.loading.taskInfoLoading ? (
            <Grid
              container
              spacing={3}
              direction="column"
              alignItems="center"
              justifyContent="center"
              style={{ minHeight: '250px' }}
            >
              <Grid item xs={12}>
                <CircularProgress size={50} className={classes.gridProgress} />
              </Grid>
            </Grid>
          ) : task.taskInfo && task.taskInfo.apiError ? (
            <Grid
              container
              spacing={3}
              direction="column"
              alignItems="center"
              justifyContent="center"
              style={{ minHeight: '250px' }}
            >
              <Grid item xs={12} className={classes.boxDiv}>
                <p>
                  An error occurred while retrieving group data. Please contact
                  support if this issue persists.
                </p>
              </Grid>
            </Grid>
          ) : router.location.state ? (
            <div>
              {activeStepName === 'assets' ? (
                <AssetList
                  handleStepper={this.handleStepper.bind(this)}
                  info={this.state}
                  queryParamsTaskType={queryParamsTaskType}
                  editTaskDetails={task.taskInfo.assets}
                />
              ) : activeStepName === 'people' ? (
                <TaskPeopleList
                  handleStepper={this.handleStepper.bind(this)}
                  info={this.state}
                  onExpand={this.expandTaskGroup}
                  expandInfo={task.taskExpandInfo}
                  editTaskDetails={task.taskInfo.participants}
                  loading={task.loading}
                  uploadTaskType={uploadTaskType}
                />
              ) : activeStepName === 'notification' ? (
                <TaskNotificationsList
                  handleStepper={this.handleStepper.bind(this)}
                  info={this.state}
                  loading={task.loading}
                  editTaskDetails={task.taskInfo.notification}
                />
              ) : (
                ''
              )}
            </div>
          ) : (
            <Grid
              container
              spacing={3}
              direction="column"
              alignItems="center"
              justifyContent="center"
              style={{ minHeight: '250px' }}
            >
              <Grid item xs={12} className={classes.boxDiv}>
                <p>There are no tasks assets for this task.</p>
              </Grid>
            </Grid>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { layout } = state
  const { headerTitle } = layout
  const auth = state.auth
  const lanId = state.auth.lanId
  let { task } = state
  return {
    headerTitle: headerTitle,
    auth: auth,
    lanId: lanId,
    task: task,
  }
}
TaskPage.contextTypes = {
  router: PropTypes.object.isRequired,
}

TaskPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  classes: PropTypes.object,
  collapseTaskGroup: PropTypes.func,
  expandTaskGroups: PropTypes.func,
  getTaskFullDetails: PropTypes.func,
  headerTitle: PropTypes.string,
  history: PropTypes.shape({
    location: PropTypes.shape({
      state: PropTypes.shape({
        task: PropTypes.shape({
          editTaskInfo: PropTypes.bool,
          isEdit: PropTypes.bool,
          project: PropTypes.shape({
            name: PropTypes.string,
            type: PropTypes.string,
            projectUuid: PropTypes.string,
            projectId: PropTypes.string,
            runDate: PropTypes.instanceOf(Date),
            dueDate: PropTypes.instanceOf(Date),
            uoiId: PropTypes.string,
            reviewJobId: PropTypes.string,
          }),
          task: PropTypes.shape({
            selectedTaskName: PropTypes.string,
            scheduledDate: PropTypes.instanceOf(Date),
            scheduledTime: PropTypes.instanceOf(Date),
            taskTypes: PropTypes.object,
          }),
        }),
      }),
    }),
  }),
  projectId: PropTypes.string,
  task: PropTypes.object,
  location: PropTypes.object,
}

export default connect(mapStateToProps, {
  getTaskFullDetails,
  expandTaskGroups,
  collapseTaskGroup,
})(withStyles(styles)(withRouter(TaskPage)))
