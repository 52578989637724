import { get } from 'lodash'

export const getAssets = (state) => get(state, 'publish.assets', [])

export const getTotalSearchResults = (state) =>
  get(state, 'publish.totalResults', Number(0))

export const getSortBy = (state) => get(state, 'publish.sortBy', '')

export const getCurrentAssetId = (state) =>
  get(state, 'publish.currentAssetId', '')

export const getPageSize = (state) => get(state, 'publish.pageSize', 21)

export const getCurrentPage = (state) => get(state, 'publish.currentPage', '')

export const getNumberOfPages = (state) =>
  Math.ceil(getTotalSearchResults(state) / getPageSize(state))

export const getCurrentAssetInfo = (state) =>
  getAssets(state).find((asset) => {
    const { asset_id = '' } = asset
    return asset_id === getCurrentAssetId(state)
  })

export const getCurrentAssetIndex = (state) =>
  getAssets(state).findIndex((asset) => {
    const { asset_id = '' } = asset
    return asset_id === getCurrentAssetId(state)
  })

export const getIsFetchingAssets = (state) =>
  get(state, 'publish.isFetchingAssets', false)

export const getIsModalOpen = (state) =>
  get(state, 'publish.isModalOpen', false)

export const getFacets = (state) => get(state, 'publish.facets', {})

export const getFilters = (state) => get(state, 'publish.filters', {})

export const getPartialFilters = (state) =>
  get(state, 'publish.partialFilters', {})
