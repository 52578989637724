import React from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment/moment'

import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import Fab from '@mui/material/Fab'
import Grid from '@mui/material/Grid'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'

import imageFallback from '../../images/ic_account_circle.png'
import { ADMIN_ROLE_ID } from '../../constants/userRoles'
import { getDisplayName } from '../../mappers/userMapper'

const styles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: useTheme().spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  fab: {
    backgroundColor: '#FC3',
    color: '#FFF',
    boxShadow: 'none',
    height: '40px',
    width: '40px',
  },
  participantIcon: {
    marginRight: 10,
    height: 40,
    width: 40,
    borderRadius: 40,
    position: 'relative',
    display: 'inline-block',
  },
  rowImage: {
    minWidth: 60,
    height: 65,
    position: 'relative',
  },
  name: {
    position: 'absolute',
    marginTop: 20,
  },
  tableWrapper: {
    overflowX: 'auto',
    width: '100%',
  },
  userEmail: {
    paddingTop: '10px',
  },
}))

class GroupPeopleList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      orderBy: 'first_name',
      order: 'asc',
    }
  }

  desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }

  getSorting() {
    return this.state.order === 'desc'
      ? (a, b) => this.desc(a, b, this.state.orderBy)
      : (a, b) => -this.desc(a, b, this.state.orderBy)
  }

  handleRequestSort(property) {
    const orderBy = property
    let order = 'desc'

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc'
    }

    this.setState({ order, orderBy })
  }

  render() {
    const { classes, peopleList } = this.props
    const { order, orderBy } = this.state
    return (
      <div className={classes.tableWrapper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '40%' }}>
                <TableSortLabel
                  active={orderBy === 'first_name'}
                  direction={order}
                  onClick={() => this.handleRequestSort('first_name')}
                >
                  Name
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'added_date'}
                  direction={order}
                  onClick={() => this.handleRequestSort('added_date')}
                >
                  Date Added
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'added_by'}
                  direction={order}
                  onClick={() => this.handleRequestSort('added_by')}
                >
                  Added By
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'role_id'}
                  direction={order}
                  onClick={() => this.handleRequestSort('role_id')}
                >
                  Role
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {peopleList.sort(this.getSorting()).map((user = {}, index) => {
              const {
                display_name = '',
                email_address = '',
                organization = '',
                group_id = '',
                added_date = '',
                added_by = '',
                role_id = '',
              } = user
              return (
                <TableRow key={index} data-cy="groupMemberList">
                  <TableCell className={classes.rowImage}>
                    <Grid container>
                      <Grid item xs={1} md={2}>
                        <Fab className={classes.fab}>
                          {display_name
                            ? display_name.split('.').length > 1
                              ? display_name.split('.')[0].substring(0, 1) +
                                display_name.split('.')[1].substring(0, 1)
                              : display_name.split('.')[0].substring(0, 1)
                            : group_id
                            ? display_name.substring(0, 2)
                            : ''}
                        </Fab>
                      </Grid>
                      {getDisplayName(email_address, organization, classes)}
                    </Grid>
                  </TableCell>
                  <TableCell>
                    {Moment(added_date).format('MMM D, YYYY h:mm a')}
                  </TableCell>
                  <TableCell>{added_by}</TableCell>
                  <TableCell>
                    {role_id === ADMIN_ROLE_ID ? 'Admin' : 'Group Member'}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </div>
    )
  }
}

GroupPeopleList.propTypes = {
  classes: PropTypes.object.isRequired,
  peopleList: PropTypes.array.isRequired,
}

const MyComponent = (props) => {
  const classes = styles()
  return <GroupPeopleList {...props} classes={classes} />
}

export default MyComponent
