import axios from 'axios'
import { get } from 'lodash'
import cloneDeep from 'lodash/cloneDeep'
import {
  SET_SORT_BY,
  SET_CURRENT_ASSET_ID,
  SET_ASSETS,
  SET_IS_FETCHING_ASSETS,
  SET_PAGE_SIZE,
  SET_CURRENT_PAGE,
  SET_TOTAL_RESULTS,
  OPEN_MODAL,
  CLOSE_MODAL,
  SET_FACETS,
  RESET_FILTERS,
  SET_FILTER_VALUE,
  SET_PARTIAL_FILTER_VALUE,
  ON_START_OVER,
} from './actionTypes'
import {
  getNumberOfPages,
  getPageSize,
  getCurrentPage,
  getSortBy,
  getCurrentAssetIndex,
  getAssets,
  getFilters,
  getPartialFilters,
} from './selectors'
import { SORT_BY_OPTIONS, FACET_PATHS } from '../../constants/publishConstants'
import apiConfig from '../../config/apiConfig'

const { publish = {} } = apiConfig
const { baseUrl = '' } = publish

export const setSortBy = (payload) => ({
  type: SET_SORT_BY,
  payload,
})

export const setCurrentAssetId = (payload) => ({
  type: SET_CURRENT_ASSET_ID,
  payload,
})

export const setAssets = (payload) => ({
  type: SET_ASSETS,
  payload,
})

export const setIsFetchingAssets = (payload) => ({
  type: SET_IS_FETCHING_ASSETS,
  payload,
})

export const setPageSize = (payload) => ({
  type: SET_PAGE_SIZE,
  payload,
})

export const setCurrentPage = (payload) => ({
  type: SET_CURRENT_PAGE,
  payload,
})

export const setTotalResults = (payload) => ({
  type: SET_TOTAL_RESULTS,
  payload,
})

export const openModal = () => ({ type: OPEN_MODAL })

export const closeModal = () => ({ type: CLOSE_MODAL })

export const setFacets = (payload) => ({
  type: SET_FACETS,
  payload,
})

export const resetFilters = () => ({
  type: RESET_FILTERS,
})

export const onStartOver = () => ({
  type: ON_START_OVER,
})

export const setFilterValue = (payload) => ({
  type: SET_FILTER_VALUE,
  payload,
})

export const setPartialFilterValue = (payload) => ({
  type: SET_PARTIAL_FILTER_VALUE,
  payload,
})

export const prevAsset =
  (router = {}) =>
  async (dispatch, getState) => {
    const state = getState() || {}
    const currentAssetIndex = getCurrentAssetIndex(state)
    const assets = getAssets(state)

    if (currentAssetIndex > 0) {
      const newIndex = currentAssetIndex - 1
      const assetDetails = Object.assign({}, assets[newIndex])
      const { asset_id = '' } = assetDetails
      await dispatch(setCurrentAssetId(asset_id))
      router.navigate(`/publishing/${asset_id}`, { replace: true })
    } else if (currentAssetIndex === 0 && getCurrentPage(state) > 0) {
      await dispatch(navigatePreviousPage())
      const newIndex = getPageSize(state) - 1
      const assetDetails = Object.assign({}, assets[newIndex])
      const { asset_id = '' } = assetDetails
      await dispatch(setCurrentAssetId(asset_id))
      router.navigate(`/publishing/${asset_id}`, { replace: true })
    }
  }

export const nextAsset =
  (router = {}) =>
  async (dispatch, getState) => {
    const state = getState()
    const numberOfPages = getNumberOfPages(state)
    const currentPage = getCurrentPage(state)
    const currentAssetIndex = getCurrentAssetIndex(state)
    const assets = getAssets(state)

    if (currentAssetIndex < assets.length - 1) {
      const newIndex = currentAssetIndex + 1
      const assetDetails = Object.assign({}, assets[newIndex])
      const { asset_id = '' } = assetDetails
      await dispatch(setCurrentAssetId(asset_id))
      router.navigate(`/publishing/${asset_id}`, { replace: true })
    } else if (
      currentAssetIndex >= assets.length - 1 &&
      currentPage < numberOfPages - 1
    ) {
      const assetDetails = Object.assign({}, assets[0])
      const { asset_id = '' } = assetDetails
      await dispatch(navigateNextPage())
      await dispatch(setCurrentAssetId(asset_id))
      router.navigate(`/publishing/${asset_id}`, { replace: true })
    }
  }

export const navigatePreviousPage = () => (dispatch, getState) => {
  const state = getState() || {}
  const currentPage = getCurrentPage(state)

  if (currentPage > 0) {
    dispatch(setCurrentPage(currentPage - 1))
    dispatch(fetchAssets())
  }
}
export const navigateNextPage = () => (dispatch, getState) => {
  const state = getState() || {}
  const currentPage = getCurrentPage(state)

  if (currentPage < getNumberOfPages(state) - 1) {
    dispatch(setCurrentPage(currentPage + 1))
    dispatch(fetchAssets())
  }
}

export const fetchInitialAssets = () => async (dispatch, getState) => {
  // dispatch(resetFilters())
  const appState = getState() || {}
  const currFilters = getFilters(appState)
  const { dateFrom = '', dateTo = '' } = currFilters
  if (!dateFrom && !dateTo) {
    const assetResults = await dispatch(fetchAssets())
    const facets = get(assetResults, 'data.facets', {})
    dispatch(setFacets(facets))
  }
}

export const fetchAssets = () => async (dispatch, getState) => {
  dispatch(setIsFetchingAssets(true))

  const state = cloneDeep(getState())
  const pageNumber = getCurrentPage(state) || Number(0)
  const size = getPageSize(state)
  const sortBy = getSortBy(state)
  const partial_filters = getPartialFilters(state)

  let {
    dateFrom = new Date(0),
    dateTo = new Date(),
    ...include_filters
  } = getFilters(state)

  // we want the date filter to be inclusive of the "to" date
  dateTo.setDate(dateTo.getDate() + 1)

  const publishSearchURL = `${baseUrl}?page=${pageNumber}&size=${size}`

  try {
    const results = await axios.post(
      publishSearchURL,
      {
        query_text: '*',
        exclude_fields: [''],
        strict_search: true,
        partial_filters,
        include_filters,
        exclude_filters: {
          active: ['false'],
        },
        exists_filters: {},
        range_filters: {
          'publishers.published_at': {
            from: dateFrom.getTime(),
            to: dateTo.getTime(),
          },
        },
        facets: {
          asset_type: 'asset_type',
          'publishers.email': 'publishers.email',
          'publishers.file_extension': 'publishers.file_extension',
          'publishers.user_id': 'publishers.user_id',
          'metadata.content_type': 'metadata.content_type',
        },
        sort_fields: get(SORT_BY_OPTIONS, sortBy, {}),
      },
      { headers: { 'Content-Type': 'application/json' } }
    )

    const assets = get(results, 'data.search', []).map((item) => {
      const { asset = '' } = item
      return asset
    })
    const totalResults = get(results, 'data.total_results', Number(0))
    const facets = get(results, 'data.facets', {})
    dispatch(setAssets(assets))
    dispatch(setFacets(facets))
    dispatch(setTotalResults(totalResults))
    dispatch(setIsFetchingAssets(false))
    return results
  } catch (error) {
    dispatch(setIsFetchingAssets(false))
  }
}
