export const FETCH_USER_BOARDS = 'FETCH_USER_BOARDS'
export const FETCH_USER_BOARDS_FULFILLED = 'FETCH_USER_BOARDS_FULFILLED'
export const FETCH_USER_BOARDS_PENDING = 'FETCH_USER_BOARDS_PENDING'
export const FETCH_USER_BOARDS_REJECTED = 'FETCH_USER_BOARDS_REJECTED'

export const FETCH_BOARD = 'FETCH_BOARD'
export const FETCH_BOARD_FULFILLED = 'FETCH_BOARD_FULFILLED'
export const FETCH_BOARD_PENDING = 'FETCH_BOARD_PENDING'
export const FETCH_BOARD_REJECTED = 'FETCH_BOARD_REJECTED'
export const FETCH_USER_BOARD_PENDING = 'FETCH_USER_BOARD_PENDING'
export const FETCH_USER_BOARD_REJECTED = 'FETCH_USER_BOARD_REJECTED'

export const EXPAND_BOARD_GROUP = 'EXPAND_BOARD_GROUP'
export const EXPAND_BOARD_GROUP_FULFILLED = 'EXPAND_BOARD_GROUP_FULFILLED'
export const EXPAND_BOARD_GROUP_PENDING = 'EXPAND_BOARD_GROUP_PENDING'
export const EXPAND_BOARD_GROUP_REJECTED = 'EXPAND_BOARD_GROUP_REJECTED'
export const COLLAPSE_GROUP = 'COLLAPSE_GROUP'

export const CREATE_BOARD = 'CREATE_BOARD'
export const CREATE_BOARD_FULFILLED = 'CREATE_BOARD_FULFILLED'
export const CREATE_BOARD_PENDING = 'CREATE_BOARD_PENDING'
export const CREATE_BOARD_REJECTED = 'CREATE_BOARD_REJECTED'

export const DELETE_BOARD = 'DELETE_BOARD'
export const DELETE_BOARD_FULFILLED = 'DELETE_BOARD_FULFILLED'
export const DELETE_BOARD_PENDING = 'DELETE_BOARD_PENDING'
export const DELETE_BOARD_REJECTED = 'DELETE_BOARD_REJECTED'

export const BOARD_FETCH_ASSETS = 'BOARD_FETCH_ASSETS'
export const BOARD_FETCH_ASSETS_FULFILLED = 'BOARD_FETCH_ASSETS_FULFILLED'
export const BOARD_FETCH_ASSETS_PENDING = 'BOARD_FETCH_ASSETS_PENDING'
export const BOARD_FETCH_ASSETS_REJECTED = 'BOARD_FETCH_ASSETS_REJECTED'

export const BOARD_ADD_ASSETS = 'BOARD_ADD_ASSETS'
export const BOARD_ADD_ASSETS_FULFILLED = 'BOARD_ADD_ASSETS_FULFILLED'
export const BOARD_ADD_ASSETS_PENDING = 'BOARD_ADD_ASSETS_PENDING'
export const BOARD_ADD_ASSETS_REJECTED = 'BOARD_ADD_ASSETS_REJECTED'

export const BOARD_REMOVE_ASSETS = 'BOARD_DELETE_ASSETS'
export const BOARD_REMOVE_ASSETS_FULFILLED = 'BOARD_DELETE_ASSETS_FULFILLED'
export const BOARD_REMOVE_ASSETS_PENDING = 'BOARD_DELETE_ASSETS_PENDING'
export const BOARD_REMOVE_ASSETS_REJECTED = 'BOARD_DELETE_ASSETS_REJECTED'

export const BOARDS_FILTER = 'BOARDS_FILTER'
export const BOARDS_SORT = 'BOARDS_SORT'

export const GET_SELECTED_ASSETS = 'GET_SELECTED_ASSETS'
export const TOGGLE_BOARDS_DIALOG = 'TOGGLE_BOARDS_DIALOG'
export const OPEN_MODAL = 'OPEN_MODEL'
export const CLOSE_MODAL = 'CLOSE_MODAL'
export const GET_PROJECT_TYPES = 'GET_PROJECT_TYPES'
export const GET_PROJECT_TYPES_FULFILLED = 'GET_PROJECT_TYPES_FULFILLED'
export const GET_PROJECT_TYPES_PENDING = 'GET_PROJECT_TYPES_PENDING'
export const GET_PROJECT_TYPES_REJECTED = 'GET_PROJECT_TYPES_REJECTED'

export const DELETE_BOARD_USER = 'DELETE_BOARD_USER'
export const DELETE_BOARD_USER_PENDING = 'DELETE_BOARD_USER_PENDING'
export const DELETE_BOARD_USER_REJECTED = 'DELETE_BOARD_USER_REJECTED'

export const UPDATE_BOARD = 'UPDATE_BOARD'
export const UPDATE_BOARD_FULFILLED = 'UPDATE_BOARD_FULFILLED'
export const UPDATE_BOARD_PENDING = 'UPDATE_BOARD_PENDING'
export const UPDATE_BOARD_REJECTED = 'UPDATE_BOARD_REJECTED'

export const SET_BOARD_DOWNLOAD_ASSETS = 'SET_BOARD_DOWNLOAD_ASSETS'

export const TOGGLE_REDIRECT_BOARD_LIST = 'TOGGLE_REDIRECT_BOARD_LIST'

export const FETCH_USER_LIST_BOARD = 'FETCH_USER_LIST_BOARD'

export const ADD_USERS_TO_BOARD = 'ADD_USERS_TO_BOARD'

export const DELETE_SPECIFIC_USER_FROM_BOARD = 'DELETE_SPECIFIC_USER_FROM_BOARD'

export const UPDATE_ACCESS_TO_BOARD_MEMBER = 'UPDATE_ACCESS_TO_BOARD_MEMBER'
