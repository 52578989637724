import React from 'react'
import PropTypes from 'prop-types'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material'
import { withStyles } from '@mui/styles'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const StyledExpansionPanel = withStyles({
  root: {
    border: '1px solid rgba(0,0,0,.125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  },
  expanded: {
    margin: 'auto',
  },
})(Accordion)

const CustomExpansionPanel = ({
  containerClasses = {},
  cardTitle = '',
  isExpanded = false,
  fullWidthContent = false,
  collapseHandler = () => {},
  children,
}) => (
  <StyledExpansionPanel
    onChange={collapseHandler}
    expanded={isExpanded}
    TransitionProps={{ classes: { entered: containerClasses.collapseEntered } }}
  >
    <AccordionSummary expandIcon={<ExpandMoreIcon />} identifier={cardTitle}>
      <Typography className={containerClasses.panelHeading}>
        {cardTitle}
      </Typography>
    </AccordionSummary>
    <AccordionDetails
      className={
        fullWidthContent
          ? containerClasses.panelContentsFullWidth
          : containerClasses.panelContents
      }
    >
      {children}
    </AccordionDetails>
  </StyledExpansionPanel>
)

CustomExpansionPanel.propTypes = {
  containerClasses: PropTypes.object,
  cardTitle: PropTypes.string,
  isExpanded: PropTypes.bool,
  fullWidthContent: PropTypes.bool,
  collapseHandler: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default CustomExpansionPanel
