import queryString from 'query-string'
import { camelCase } from 'lodash'
import { assethubFastlyDomainRegex, polDomainRegex } from './Regexes'
import apiConfig from '../config/apiConfig'

export const getParameterFromUrl = function (name, url, noDecode) {
  const regex = new RegExp('[\\?&]' + name + '=([^&#]*)')
  const results = url ? regex.exec(url) : regex.exec(window.location.search)

  let returnValue
  if (results === null) {
    returnValue = ''
  } else if (!noDecode) {
    returnValue = decodeURIComponent(results[1].replace(/\+/g, ' '))
  } else {
    returnValue = results[1]
  }

  return returnValue
}

export const getQueryParams = (location = {}) => {
  const { search = '' } = location
  const queryParamsIndex = search.indexOf('?')
  const params =
    queryParamsIndex !== -1
      ? queryString.parse(search.substr(queryParamsIndex))
      : {}
  return params
}

export const appendQueryParams = (url = '', params = {}) => {
  if (url === null) {
    return ''
  }
  const { key = '' } = apiConfig
  const paramsObj = Object.assign({}, params, { key })
  const delimiter = url.indexOf('?') === -1 ? '?' : '&'
  return `${url}${delimiter}${queryString.stringify(paramsObj)}`
}

export const isNonAssetHubAPI = (requestUrl) => {
  const nonAssetHubAPIRegex = new RegExp(
    '\\/locations\\/v3\\/public(:?\\/|\\?)'
  )
  return nonAssetHubAPIRegex.test(requestUrl)
}

export const formatPaginationQueryParams = (pagination) => {
  let params = ''
  params += `page=${pagination.page}&size=${pagination.size}`
  if (pagination.sort) {
    // spring paging reqires camelcase field name for sorting
    params += `&sort=${camelCase(pagination.sort.field)},${
      pagination.sort.direction
    }`
  }
  return params
}

export const isPOLDomain = (hostName = '') => {
  const polDomain = hostName.match(polDomainRegex)
  return polDomain != null ? true : false
}

export const isAssethubFastlyDomain = (hostName = '') => {
  const fastlyDomain = hostName.match(assethubFastlyDomainRegex)
  return fastlyDomain !== null ? true : false
}

export const redirectToHelpPage = (pathname = '') => {
  const currentTitle = pathname.split('/')[1]
  if (!currentTitle && currentTitle !== 'help') {
    window.location.href = '/help'
  }
}
