import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
} from '@mui/material'

class SaveAlertDialog extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    const { isConfirmDialoagOpen, onDontSave, onSave } = this.props
    return (
      <Dialog
        open={isConfirmDialoagOpen}
        onClose={onDontSave}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {'Unsaved Changes'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you want to save the metadata you have added?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onDontSave} color="primary">
            DON'T SAVE
          </Button>
          <Button onClick={onSave} color="primary" autoFocus>
            SAVE
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

SaveAlertDialog.defaultProps = {
  isConfirmDialoagOpen: false,
  onDontSave: () => {},
  onSave: () => {},
}

SaveAlertDialog.propTypes = {
  isConfirmDialoagOpen: PropTypes.bool,
  onDontSave: PropTypes.func,
  onSave: PropTypes.func,
}

export default SaveAlertDialog
