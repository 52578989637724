import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'

import { IconButton } from '@mui/material'
import { CropSquare } from '@mui/icons-material'
import Cursor from '../../images/cursor.svg?url'

const styles = {
  root: {
    position: 'absolute',
    right: '350px',
    top: '130px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#EFEFEF',
    zIndex: 5,
  },
  selectorButton: {
    borderRadius: 0,
    height: '36px',
    width: '36px',
    padding: 0,
  },
}

export const ToolSelector = (props) => {
  const { classes = {}, currentTool = '', onToolSelect = () => {} } = props
  return (
    <div className={classes.root}>
      <IconButton
        className={classes.selectorButton}
        onClick={() => onToolSelect('cursor')}
        style={{
          backgroundColor: currentTool === 'cursor' ? '#8D8D8D' : 'inherit',
        }}
        size="large"
      >
        <img src={Cursor} style={{ height: '16px' }} />
      </IconButton>
      <IconButton
        data-cy="squareToolSelector"
        className={classes.selectorButton}
        onClick={() => onToolSelect('square')}
        style={{
          backgroundColor: currentTool === 'square' ? '#8D8D8D' : 'inherit',
        }}
        size="large"
      >
        <CropSquare />
      </IconButton>
      {/* <IconButton 
        className={classes.selectorButton} 
        onClick={() => onToolSelect('text')}
        style={{ backgroundColor: currentTool === 'text' ? '#8D8D8D' : 'inherit' }}
      >
        <FormatItalic />
      </IconButton> */}
    </div>
  )
}

ToolSelector.propTypes = {
  classes: PropTypes.object,
  currentTool: PropTypes.string,
  onToolSelect: PropTypes.func,
}

export default withStyles(styles)(ToolSelector)
