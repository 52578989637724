import {
  SIGN_IN_SUCCESS,
  PROMPT_NO_ACCESS,
  CLOSE_NO_ACCESS,
} from './actionTypes'
import { formatUserInfo } from './helper'

export const initialState = {
  isLoading: true,
  email: '',
  firstName: '',
  lanId: '',
  lastName: '',
  memberOf: [],
  accessToken: '',
  accessibleModules: [],
  isAdGroupSuperAdmin: false,
  isLibrarian: false,
  noAccessModalShown: false,
  xIdToken: '',
  company: '',
  title: '',
  projectInitiator: '',
  displayName: '',
}

export default function authReducer(state = initialState, action = {}) {
  const { type, payload = {} } = action
  switch (type) {
    case SIGN_IN_SUCCESS:
      return {
        ...state,
        ...formatUserInfo(payload),
        noAccessModalShown: false,
        isLoading: false,
      }
    case PROMPT_NO_ACCESS:
      return {
        ...state,
        noAccessModalShown: true,
        projectInitiator: payload,
      }
    case CLOSE_NO_ACCESS:
      return {
        ...state,
        noAccessModalShown: false,
      }
    default:
      return state
  }
}
