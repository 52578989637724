import PropTypes from 'prop-types'

export const assetPropType = PropTypes.shape({
  asset_content_type: PropTypes.string,
  asset_id: PropTypes.string,
  asset_import_date: PropTypes.string,
  asset_imported_by: PropTypes.string,
  asset_master_id: PropTypes.string,
  asset_name: PropTypes.string,
  asset_original_path: PropTypes.string,
  asset_preview_path: PropTypes.string,
  asset_thumbnail_path: PropTypes.string,
  asset_uoi_id: PropTypes.string,
  asset_version: PropTypes.number,
  comments_count: PropTypes.number,
  creative_asset_status: PropTypes.string,
  is_latest: PropTypes.bool,
  latest: PropTypes.bool,
  mime_type: PropTypes.string,
  render_url: PropTypes.string,
})

export const commentPropType = PropTypes.shape({
  comment_id: PropTypes.string,
  asset_id: PropTypes.string,
  asset_master_id: PropTypes.string,
  asset_version: PropTypes.number,
  comment_type: PropTypes.string,
  comment_text: PropTypes.string,
  geometry: PropTypes.string,
  created_by: PropTypes.string,
  updated_date: PropTypes.string,
  updated_by: PropTypes.string,
  parent_comment_id: PropTypes.string,
  replied_comments: PropTypes.array,
  is_active: PropTypes.bool,
})

export const globalMarkupPattern = /@+\[+[\w\d\s|\.]+\]+\(+[\w\d\s|\.|@]+\)/gi
export const singleMarkupPattern = /@+\[+[\w\d\s|\.]+\]+\(+[\w\d\s|\.|@]+\)/i
export const wordPattern = /\[+[\w\d\s\.]+\]/gi
export const emailPattern = /\(+[\w\d\s\.\-@]+\)/gi
export const bracketPattern = /[\[\]]/gi
export const parenthesesPattern = /[\(\)]/gi

export const commentClassifications = {
  NEW: 'NEW',
  HOLD: 'HOLD',
  IGNORE: 'IGNORE',
  PROCEED: 'PROCEED',
}

export const TaskRoles = {
  admin: 'Admin',
  reviewer: 'Reviewer',
  observer: 'Observer',
}

export const DEFAULT_SCALE = 'fit-to-screen'
