import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
  FormControl,
  InputLabel,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import DeleteIcon from '@mui/icons-material/Delete'

import CollapsibleCard from '../../components/CustomCards/CollapsibleCard'
import RemoteImageLoader from '../../components/RemoteImageLoader'

import {
  fetchRevisions,
  deleteRevisions,
} from '../../store/metadata/actionCreator'
import { selectProjectUuid } from '../../store/projects/selector'

import { convertUTCtoLocal, formatCSTDate } from '../../helpers/dateHelper'
import { getFromBrowserStorage } from '../../helpers/UtilityHelper'
import { appendQueryParams } from '../../helpers/UrlHelper'

import apiConfig from '../../config/apiConfig'
import {
  METADATA_CARD_SHOW_BUTTON_TEXT,
  METADATA_CARD_HIDE_BUTTON_TEXT,
  METADATA_REVISION_DELETE_TEXT,
  METADATA_REVISION_DELETE_TITLE,
} from '../../constants/metadata'

const styles = makeStyles((theme) => ({
  sectionWrapper: {
    '&:hover': {
      backgroundColor: useTheme().palette.primary.lightestGrey,
      '& .actionBtnHover': {
        visibility: 'visible',
      },
    },
  },
  thumbnailWrapper: {
    textAlign: 'center',
    padding: '10px 0',
  },
  actionWrapper: {
    paddingRight: '10px',
    paddingLeft: '10px',
  },
  actionBtn: {
    visibility: 'hidden',
  },
  formControl: {
    minWidth: '200px',
    margin: useTheme().spacing(1),
    '& label': {
      color: useTheme().palette.primary.lightGrey + ' !important',
    },
  },
  formStaticText: {
    fontSize: '1rem',
  },
  dialogTitle: {
    color: useTheme().palette.primary.themePrimary + ' !important',
    '& h2': {
      color: useTheme().palette.primary.themePrimary + ' !important',
    },
  },
  deleteButton: {
    margin: useTheme().spacing(),
    backgroundColor: useTheme().palette.primary.bostonRed,
    color: useTheme().palette.primary.contrastText,
    '&:hover': {
      backgroundColor: useTheme().palette.primary.main,
    },
  },
  cancelButton: {
    margin: useTheme().spacing(),
    color: useTheme().palette.primary.primaryBlue,
  },
  leftIcon: {
    marginRight: useTheme().spacing(),
  },
}))

const thumbnailStyle = {
  verticalAlign: 'middle',
  maxHeight: '200px',
  maxWidth: '200px',
}

export class RevisionsCard extends React.Component {
  constructor(props) {
    super(props)
    const { assetMasterId = '', fetchRevisions = () => {} } = props
    if (assetMasterId) {
      fetchRevisions(assetMasterId)
    }
    this.state = {
      showConfirmation: false,
      selectedAssetId: '',
    }
  }

  componentDidUpdate(prevProps) {
    const {
      metadataParentTimestamp = 0,
      assetMasterId = '',
      fetchRevisions = () => {},
    } = this.props
    if (
      prevProps.metadataParentTimestamp !== metadataParentTimestamp &&
      assetMasterId &&
      prevProps.assetMasterId !== assetMasterId
    ) {
      fetchRevisions(assetMasterId)
    }
  }

  openConfirmation =
    (assetId = '') =>
    () => {
      this.setState({
        showConfirmation: true,
        selectedAssetId: assetId,
      })
    }

  closeConfirmation = () => {
    this.setState({
      showConfirmation: false,
      selectedAssetId: '',
    })
  }

  deleteRevision = () => {
    const {
      deleteRevisions = () => {},
      closePreview = () => {},
      projectId = '',
    } = this.props
    const { selectedAssetId = '' } = this.state
    if (selectedAssetId) {
      const assetUoiIds = []
      assetUoiIds.push(selectedAssetId)
      const payload = {
        asset_uoi_ids: assetUoiIds,
      }
      deleteRevisions(payload, '', projectId)
      closePreview()
    }
  }

  render() {
    const {
      classes = {},
      containerClasses = {},
      getStorageIdentifier = () => {},
      collapseCallback = () => {},
      assetRevisions = [],
      editPermissions = {},
    } = this.props
    const cardTitle = 'Revisions'
    const cardIdentifier = getStorageIdentifier(cardTitle)
    const cardCollapse = getFromBrowserStorage(cardIdentifier) === 'true'
    const { key = '', enterpriseUploads = {} } = apiConfig
    const imageQueryParams = {
      // key,
      // width: 200,
      // height: 200,
      make_square: true,
    }
    const { showConfirmation = false } = this.state
    const { canDelete } = editPermissions
    return (
      <CollapsibleCard
        cardTitle={cardTitle}
        hideButtonText={METADATA_CARD_HIDE_BUTTON_TEXT}
        showButtonText={METADATA_CARD_SHOW_BUTTON_TEXT}
        defaultCollapse={cardCollapse}
        collapseCallback={collapseCallback}
        fullWidthContent
      >
        <div className={containerClasses.metadataCardContent}>
          <Table className={containerClasses.metadataTable}>
            <TableBody>
              {assetRevisions.map((assetRevision, key) => {
                const {
                  asset_id: assetId = '',
                  uploaded_date: importDate = '',
                  metadata: metadataInfo = {},
                  revision_number: revisionNumber = '',
                  storage_url: storageUrl = '',
                  is_latest_revision: isLatestRevision = false,
                  render_urls: { thumbnail_asset_url = '' } = {},
                } = assetRevision
                const { file_metadata: { file_name: fileName = '' } = {} } =
                  metadataInfo || {}
                const importDateFormatted =
                  convertUTCtoLocal(importDate, 'lll') || ''
                return (
                  <TableRow key={key} className={classes.sectionWrapper}>
                    <TableCell className={classes.actionWrapper}>
                      {canDelete && isLatestRevision && (
                        <div
                          className={classes.actionBtn + ' actionBtnHover'}
                          aria-label={METADATA_REVISION_DELETE_TITLE}
                          onClick={this.openConfirmation(assetId)}
                        >
                          <DeleteIcon />
                        </div>
                      )}
                    </TableCell>
                    <TableCell className={classes.thumbnailWrapper}>
                      <RemoteImageLoader
                        Src={thumbnail_asset_url}
                        AltText={fileName}
                        imgStyle={thumbnailStyle}
                      />
                    </TableCell>
                    <TableCell>
                      {fileName && (
                        <FormControl
                          variant="standard"
                          className={classes.formControl}
                          disabled
                        >
                          <InputLabel htmlFor={`revFileName${key}`} shrink>
                            File Name
                          </InputLabel>
                          <div className={containerClasses.formStaticWrap}>
                            <span
                              id={`revFileName${key}`}
                              className={classes.formStaticText}
                            >
                              {fileName}
                            </span>
                          </div>
                        </FormControl>
                      )}
                      {importDateFormatted && (
                        <FormControl
                          variant="standard"
                          className={classes.formControl}
                          disabled
                        >
                          <InputLabel htmlFor={`revImportDate${key}`} shrink>
                            Upload Date
                          </InputLabel>
                          <div className={containerClasses.formStaticWrap}>
                            <span
                              id={`revImportDate${key}`}
                              className={classes.formStaticText}
                            >
                              {importDateFormatted}
                            </span>
                          </div>
                        </FormControl>
                      )}
                      {revisionNumber && (
                        <FormControl
                          variant="standard"
                          className={classes.formControl}
                          disabled
                        >
                          <InputLabel
                            htmlFor={`revRevisionNumber${key}`}
                            shrink
                          >
                            Revision Number
                          </InputLabel>
                          <div className={containerClasses.formStaticWrap}>
                            <span
                              id={`revRevisionNumber${key}`}
                              className={classes.formStaticText}
                            >
                              {revisionNumber}
                            </span>
                          </div>
                        </FormControl>
                      )}
                    </TableCell>
                  </TableRow>
                )
              })}
              {assetRevisions.length === 0 && (
                <TableRow>
                  <TableCell>
                    <div className={containerClasses.noResults}>
                      No details available
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {showConfirmation && (
            <Dialog open={showConfirmation} onClose={this.closeConfirmation}>
              <DialogTitle
                id="alert-dialog-title"
                className={classes.dialogTitle}
              >
                {METADATA_REVISION_DELETE_TITLE}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {METADATA_REVISION_DELETE_TEXT}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={this.deleteRevision}
                  variant="contained"
                  className={classes.deleteButton}
                >
                  <DeleteIcon className={classes.leftIcon} />{' '}
                  {METADATA_REVISION_DELETE_TITLE}
                </Button>
                <Button
                  onClick={this.closeConfirmation}
                  variant="contained"
                  className={classes.cancelButton}
                >
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </div>
      </CollapsibleCard>
    )
  }
}

RevisionsCard.propTypes = {
  containerClasses: PropTypes.object,
  classes: PropTypes.object,
  assetId: PropTypes.string,
  assetMasterId: PropTypes.string,
  metadataParentTimestamp: PropTypes.number,
  assetRevisions: PropTypes.array,
  fetchRevisions: PropTypes.func,
  deleteRevisions: PropTypes.func,
  collapseCallback: PropTypes.func,
  getStorageIdentifier: PropTypes.func,
  editPermissions: PropTypes.object,
  closePreview: PropTypes.func,
  projectId: PropTypes.string,
}

const mapStateToProps = (state = {}) => {
  const { metadata = {} } = state
  const { assetRevisions = [], isError } = metadata
  return {
    assetRevisions,
    isError,
    projectId: selectProjectUuid()(state),
  }
}

const mapDispatchToProps = {
  fetchRevisions,
  deleteRevisions,
}

const MyComponent = (props) => {
  const classes = styles()
  return <RevisionsCard {...props} classes={classes} />
}

export default connect(mapStateToProps, mapDispatchToProps)(MyComponent)
