import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Grid } from '@mui/material'
import MenuOpenIcon from '@mui/icons-material/MenuOpen'
import { withStyles, makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { selectUserId } from '../../../store/auth/selector'
import { FACET_PATHS } from '../../../constants/publishConstants'
import { formatNumberToString } from '../../../helpers/stringHelper'
import {
  getFilters,
  getIsFetchingAssets,
  getFacets,
  getPartialFilters,
  getTotalSearchResults,
} from '../../../store/publish/selectors'

import {
  onStartOver,
  setFilterValue,
  setPartialFilterValue,
  fetchAssets,
  resetFilters,
  openModal,
  setCurrentAssetId,
} from '../../../store/publish/actions'

import PreviewModal from './PreviewModal'
import SearchResults from './SearchResults'
import Filters from './Filters'
import TextFilter from './Filters/TextFilter'
import DisplayControls from './SearchResults/DisplayControls'

const styles = makeStyles((theme) => ({
  root: {
    marginLeft: 12,
    flexGrow: 1,
    paddingTop: '10px',
  },
  textFilter: {
    paddingLeft: '22px !important',
    paddingTop: '20px !important',
    paddingBottom: '20px',
  },
  middleBar: {
    borderTopStyle: 'solid',
    borderBottomStyle: 'solid',
    borderWidth: '1px',
    borderColor: '#F5F5F5',
    height: '70px',
    backgroundColor: 'white',
  },
  middleBarSection: {
    width: '50%',
  },
  resultsCount: {
    fontSize: '13px',
    color: useTheme().palette.primary.midGrey,
    margin: '8px',
  },
  resultsBox: {
    paddingRight: '0px !important',
    paddingLeft: '15px !important',
  },
  showFilterGrid: {
    padding: '0px !important',
  },
  showFilterButton: {
    height: '69px',
    width: '201px',
    border: 'none',
    backgroundColor: '#F5F5F5',
    outline: 'none',
    cursor: 'pointer',
    fontSize: 14,
  },
  showFilterText: {
    color: '#383838',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '25px',
  },
  filters: {
    top: '179px',
    height: '80vh',
    overflow: 'scroll',
    position: 'sticky',
    background: '#f5f5f5',
    marginLeft: '-12px',
    paddingLeft: '5px',
  },
  displayFilters: {
    background: '#f5f5f5',
    padding: '0 11px',
  },
}))

function PublishSearch({
  classes = {},
  fetchAssets = () => {},
  onStartOver = () => {},
  partialFilters = {},
  setPartialFilterValue = () => {},
  totalResults = {},
  history = {},
  router = {},
  filters = {},
  setFilterValue = () => {},
  resetFilters = () => {},
  openModal = () => {},
  setCurrentAssetId = () => {},
  lanId = '',
}) {
  const { FILE_NAME = {} } = FACET_PATHS
  const { path = '' } = FILE_NAME
  const [filterTextValue = ''] = partialFilters[path] || []
  const [filter, setFilter] = useState({
    filtersVisible: true,
  })

  const filterClick = () => {
    setFilter({
      ...filter,
      filtersVisible: !filter.filtersVisible,
    })
  }

  useEffect(() => {
    const currentId = location.pathname.split('/')[2]
    if (currentId) {
      fetchAssets()
      setCurrentAssetId(currentId)
      openModal()
    }
  }, [])

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} className={classes.textFilter}>
          <TextFilter
            path={path}
            placeholder={`I'm looking for...`}
            value={filterTextValue}
            setPartialFilterValue={setPartialFilterValue}
            fetchAssets={fetchAssets}
            onStartOver={onStartOver}
            filters={filters}
            setFilterValue={setFilterValue}
            resetFilters={resetFilters}
            lanId={lanId}
          />
        </Grid>

        {totalResults > 0 && (
          <Grid
            item
            xs={12}
            className={classes.middleBar}
            container
            direction={'row'}
          >
            <Grid
              container
              direction={'row'}
              spacing={2}
              alignItems={'center'}
              justifyContent={'flex-start'}
              className={classes.middleBarSection}
            >
              <Grid item className={classes.showFilterGrid}>
                {!filter.filtersVisible ? (
                  <button
                    className={classes.showFilterButton}
                    onClick={filterClick}
                  >
                    <div className={classes.showFilterText}>
                      SHOW FILTERS{' '}
                      <MenuOpenIcon style={{ transform: 'scaleX(-1)' }} />
                    </div>
                  </button>
                ) : (
                  <button
                    className={classes.showFilterButton}
                    onClick={filterClick}
                  >
                    <div className={classes.showFilterText}>
                      HIDE FILTERS{' '}
                      <MenuOpenIcon style={{ paddingLeft: '10px' }} />
                    </div>
                  </button>
                )}
              </Grid>
              <Grid item className={classes.resultsBox}>
                <div className={classes.resultsCount}>
                  {formatNumberToString(totalResults)} Results
                </div>
              </Grid>
            </Grid>
            <DisplayControls />
          </Grid>
        )}

        {filter.filtersVisible && (
          <Grid item xs={3} className={classes.displayFilters}>
            <Filters />
          </Grid>
        )}

        <Grid item xs={filter.filtersVisible ? 9 : 12}>
          <SearchResults history={history} router={router} />
        </Grid>
      </Grid>

      <PreviewModal history={history} router={router} />
    </div>
  )
}

PublishSearch.propTypes = {
  classes: PropTypes.object.isRequired,
  fetchAssets: PropTypes.func,
  onStartOver: PropTypes.func,
  partialFilters: PropTypes.object,
  setPartialFilterValue: PropTypes.func,
  totalResults: PropTypes.number,
  history: PropTypes.object,
  filters: PropTypes.object,
  setFilterValue: PropTypes.func,
  lanId: PropTypes.string.isRequired,
}

const mapStateToProps = (state) => ({
  filters: getFilters(state),
  partialFilters: getPartialFilters(state),
  facets: getFacets(state),
  isFetchingAssets: getIsFetchingAssets(state),
  totalResults: getTotalSearchResults(state),
  lanId: selectUserId()(state),
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onStartOver,
      fetchAssets,
      setFilterValue,
      setPartialFilterValue,
      resetFilters,
      openModal,
      setCurrentAssetId,
    },
    dispatch
  )

const MyComponent = (props) => {
  const classes = styles()
  return <PublishSearch {...props} classes={classes} />
}

export default connect(mapStateToProps, mapDispatchToProps)(MyComponent)
