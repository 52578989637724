import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Grid, MenuItem, Select } from '@mui/material'
import { withStyles, makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'

import {
  SORT_OPTIONS,
  DEFAULT_SORT_BY_OPTION,
} from '../../../../constants/publishConstants'
import { getSortBy } from '../../../../store/publish/selectors'
import { setSortBy, fetchAssets } from '../../../../store/publish/actions'

import PageSelector from './PageSelector'
import PageSizeSelector from './PageSizeSelector'

const styles = makeStyles((theme) => ({
  sortByLabel: {
    marginRight: 5,
  },
  floatingLabelFocusStyle: {
    color: useTheme().palette.primary.midGrey + ' !important',
  },
  selectFilter: {
    height: '40px',
    fontWeight: '400',
    fontSize: '13px',
    marginRight: '10px',
  },
  sortGrid: {
    width: '50%',
  },
}))

const DisplayControls = ({
  classes = {},
  sortBy = '',
  setSortBy = () => {},
  fetchAssets = () => {},
}) => {
  const onSortChange = (event) => {
    setSortBy(event.target.value || DEFAULT_SORT_BY_OPTION)
    fetchAssets()
  }

  return (
    <Grid
      container
      className={classes.sortGrid}
      direction={'row'}
      alignItems={'center'}
      justifyContent={'flex-end'}
    >
      <Grid item>
        <Grid
          container
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Select
            variant="outlined"
            name="sortBy"
            id="sortBy"
            placeholder="Sort By"
            value={sortBy}
            onChange={onSortChange}
            fullWidth
            margin="dense"
            className={classes.selectFilter}
          >
            {Object.keys(SORT_OPTIONS).map((sortKey) => (
              <MenuItem key={sortKey} value={sortKey}>
                {SORT_OPTIONS[sortKey]}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
      <Grid item>
        <PageSizeSelector />
      </Grid>
      <Grid item>
        <PageSelector />
      </Grid>
    </Grid>
  )
}

DisplayControls.propTypes = {
  classes: PropTypes.object.isRequired,
  sortBy: PropTypes.string.isRequired,
  setSortBy: PropTypes.func.isRequired,
  fetchAssets: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  sortBy: getSortBy(state),
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setSortBy,
      fetchAssets,
    },
    dispatch
  )

const MyComponent = (props) => {
  const classes = styles()
  return <DisplayControls {...props} classes={classes} />
}

export default connect(mapStateToProps, mapDispatchToProps)(MyComponent)
