import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ReactHtmlParser from 'react-html-parser'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { makeStyles, withStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import HelpIcon from '@mui/icons-material/Announcement'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import Tooltip from '@mui/material/Tooltip'
import BackupIcon from '@mui/icons-material/Backup'
import { ViewAgenda } from '@mui/icons-material'
import { withEnv } from '@praxis/component-runtime-env'
import { withAuth } from '@praxis/component-auth'
import Divider from '@mui/material/Divider'
import LaunchIcon from '@mui/icons-material/Launch'
import withRouter from '../../containers/Router/WithRouter'
import { isAuthorizedToPage } from '../../helpers/UtilityHelper'
import Feedback from '../Feedback/Feedback'
import { toggleUploadManager } from '../../store/uploads/actionCreator'
import { toggleRightSidenav } from '../../store/layout/actionCreator'

import {
  selectUserEmail,
  selectIsAuthorized,
  selectUserId,
  selectMemberOf,
  selectUserCompany,
} from '../../store/auth/selector'
import {
  selectIsToggleRightSidenav,
  selectRightSideNavComponent,
} from '../../store/layout/selector'
import { BOARDS_TITLE } from '../../constants/search'
import Dropdown from './Dropdown'
import { praxis } from '../../config/themeConfig'
import { appBarStyles } from '../SecondaryNav'
import {
  SINGLE_PLAYGROUND_VIEW_AI,
  BULK_TESTS_VIEW_AI,
  REFERENCE_LINK
} from '../../constants/routes'

const genAiButton = {
  fontFamily: 'Roboto',
  width: '110px',
  backgroundColor: '#FFFFFF',
  color: '#178295',
}

const styles = makeStyles((theme) => ({
  header: {
    height: 50,
    [`${useTheme().breakpoints.up('xs')} and (orientation: landscape)`]: {
      height: 48,
    },
    [useTheme().breakpoints.up('sm')]: {
      height: 50,
    },
  },
  appBar: {
    ...appBarStyles,
    zIndex: 1,
    position: 'relative',
    paddingLeft: '15px',
    backgroundColor: 'white!important',
    boxShadow: '0px 2px 6px #0000000F',
  },
  appBarHelp: {
    ...appBarStyles,
    zIndex: 9,
    position: 'relative',
    paddingLeft: '15px',
    backgroundColor: 'white!important',
    boxShadow: '0px 2px 6px #0000000F',
  },
  button: {
    color: useTheme().palette.primary.contrastText,
  },
  headerBlock: {
    minHeight: 64,
    fontSize: 13,
    marginLeft: 50,
  },
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  flex: {
    flex: 1,
    marginLeft: 10,
    fontSize: '20px',
    color: praxis.themePrimary,
    '&:focus, &:hover, &:visited, &:link, &:active': {
      textDecoration: 'none !important',
    },
  },
  menuButton: {
    marginLeft: -12,
  },
  uploadManagerActive: {
    backgroundColor: '#43425D',
    padding: '20px 16px',
    borderRadius: 0,
    '&:hover': {
      backgroundColor: '#43425D !important',
    },
  },
  navButtonActive: {
    backgroundColor: useTheme().palette.primary.themePrimary,
    padding: '20px 16px',
    borderRadius: 0,
    '&:hover': {
      backgroundColor: useTheme().palette.primary.themePrimary + ' !important',
    },
  },
  uploadManager: {
    padding: '20px 16px',
    borderRadius: 0,
  },
  headerIcon: {
    color: '#BCBCCB',
    height: '22px',
  },
  divider: {
    marginTop: '15px',
    marginBottom: '15px',
    marginRight: '10px',
  },
  buttonGroup: {
    position: 'relative',
    left: '5%',
  },
  genAiButtonStyleActive: {
    genAiButton,
    '&:disabled': genAiButton,
  },
  genAiButtonStyleInactive: {
    fontFamily: 'Roboto',
    width: '110px',
    backgroundColor: '#CCCCCC',
    color: '#888888',
    '&:disabled': genAiButton,
  },
  referenceStyleButton: {
    marginLeft: '100px',
    color: '#178295',
    '&:hover': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
  },
}))

export class Header extends React.Component {
  constructor() {
    super()
    this.addFeedback = this.addFeedback.bind(this)
    this.renderaddFeedback = this.renderaddFeedback.bind(this)
    this.onClose = this.onClose.bind(this)

    this.state = { isModalOpen: false }
  }
  addFeedback() {
    this.setState({ isModalOpen: true })
  }
  onClose() {
    this.setState({ isModalOpen: false })
  }

  renderaddFeedback() {
    if (!this.state.isModalOpen) {
      return null
    }
    return (
      <Feedback
        onClose={this.onClose}
        modalOpen={this.state.isModalOpen}
        projectId={this.state.projectId}
      />
    )
  }

  componentDidUpdate() {
    Array.from(
      document.getElementById('breadCrumb').querySelectorAll('a[href]'),
    ).forEach((node) => {
      node.addEventListener('click', (e) => {
        e.preventDefault()
        this.props.router?.navigate(e.target.getAttribute('href'))
      })
    })
  }

  toggleUploadManager() {
    this.props.toggleUploadManager()
  }
  toggleGenAiView() {
    this.props.router?.location.pathname === BULK_TESTS_VIEW_AI
      ? this.props.router?.navigate(SINGLE_PLAYGROUND_VIEW_AI)
      : this.props.router?.navigate(BULK_TESTS_VIEW_AI)
  }

  render() {
    const {
      classes,
      title,
      icon,
      menuAction,
      showFeedBack,
      uploads = {},
      isToggleRightSidenav,
      rightSideNavComponent,
      toggleRightSidenav = () => {},
      user,
      signOut,
      role = '',
      env = {},
      auth = {},
    } = this.props
    const { REACT_APP_ROLES_CONFIG = {} } = env
    const { showUploadManager = false } = uploads
    const {
      company = '',
      email = '',
      noAccessModalShown = false,
      memberOf: memberOfCopy = [],
      displayName = '',
    } = user
    const { isAuthorized = () => {} } = auth
    const memberOf = role ? [role] : memberOfCopy
    const { projects = [], boards = [] } = REACT_APP_ROLES_CONFIG

    const { ai_imagetotext_bulk = [] } = REACT_APP_ROLES_CONFIG

    const singleViewRoute =
      this.props.router?.location.pathname == SINGLE_PLAYGROUND_VIEW_AI

    const bulkViewRoute =
      this.props.router?.location.pathname == BULK_TESTS_VIEW_AI

    const genAiButtonsVisible = singleViewRoute || bulkViewRoute

    const handleReferenceUrl = (e) =>{
      e.preventDefault()
      open(REFERENCE_LINK,'_blank')
    }

    return (
      <div className={classes.root}>
        <AppBar
          position="static"
          className={
            icon === 'Help' || icon === 'Search'
              ? classes.appBarHelp
              : classes.appBar
          }
        >
          <Toolbar className={classes.headerBlock}>
            <Typography
              variant="subtitle1"
              id="breadCrumb"
              color="inherit"
              className={classes.flex}
              style={{ textDecoration: 'none !important' }}
            >
              {ReactHtmlParser(title)}
              {isAuthorized(ai_imagetotext_bulk) && genAiButtonsVisible && (
                <>
                  <ButtonGroup
                    variant="contained"
                    aria-label="Basic button group"
                    // style={{bottom:"20px",left:"15%"}}
                    className={classes.buttonGroup}
                  >
                    <Button
                      className={
                        singleViewRoute
                          ? classes.genAiButtonStyleActive
                          : classes.genAiButtonStyleInactive
                      }
                      disabled={singleViewRoute}
                      onClick={() => this.toggleGenAiView()}
                    >
                      Single
                    </Button>
                    <Button
                      className={
                        bulkViewRoute
                          ? classes.genAiButtonStyleActive
                          : classes.genAiButtonStyleInactive
                      }
                      disabled={bulkViewRoute}
                      onClick={() => this.toggleGenAiView()}
                    >
                      Bulk
                    </Button>
                  </ButtonGroup>

                  <Button
                    onClick={handleReferenceUrl}
                    style={{ color: "#178295" }}
                    className={classes.referenceStyleButton}
                  >
                    Reference
                    <LaunchIcon
                      color="#178295"
                      marginLeft="30%"
                      fontSize="small"
                    />
                  </Button>
                </>
              )}
            </Typography>
            {isAuthorized(projects) && (
              <Tooltip id="uploadManagerIcon" title="Toggle Upload Manager">
                <Button
                  id="uploadManagerBtn"
                  onClick={() => this.toggleUploadManager()}
                  color="inherit"
                  className={
                    showUploadManager
                      ? classes.uploadManagerActive
                      : classes.uploadManager
                  }
                >
                  <BackupIcon className={classes.headerIcon} />
                </Button>
              </Tooltip>
            )}

            {isAuthorized(boards) && (
              <Tooltip id="boardsDialogIcon" title="Toggle Boards Dialog">
                <Button
                  id="boardsDialogBtn"
                  onClick={() =>
                    toggleRightSidenav(
                      BOARDS_TITLE,
                      isToggleRightSidenav &&
                        rightSideNavComponent !== BOARDS_TITLE,
                    )
                  }
                  data-cy="boardsDialogButton"
                  color="inherit"
                  className={
                    isToggleRightSidenav &&
                    rightSideNavComponent === BOARDS_TITLE
                      ? classes.navButtonActive
                      : classes.uploadManager
                  }
                >
                  <ViewAgenda className={classes.headerIcon} />
                </Button>
              </Tooltip>
            )}

            {showFeedBack ? (
              <Tooltip id="tooltip-icon" title="Need Help?">
                <Button
                  id="btnFeedBack"
                  onClick={() => this.addFeedback()}
                  color="inherit"
                >
                  <HelpIcon className={classes.headerIcon} />
                </Button>
              </Tooltip>
            ) : (
              ''
            )}
            <Divider
              orientation="vertical"
              flexItem
              className={classes.divider}
            />
            <Dropdown user={displayName} company={company} />
          </Toolbar>
        </AppBar>
        {this.renderaddFeedback()}
      </div>
    )
  }
}

Header.displayName = 'Header'

Header.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  classes: PropTypes.object,
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  menuAction: PropTypes.func,
  toggleUploadManager: PropTypes.func.isRequired,
  toggleRightSidenav: PropTypes.func.isRequired,
  uploads: PropTypes.object,
  showFeedBack: PropTypes.bool,
  isToggleRightSidenav: PropTypes.bool,
  rightSideNavComponent: PropTypes.string,
  user: PropTypes.shape({
    email: PropTypes.string,
    isAuthorized: PropTypes.bool,
    noAccessModalShown: PropTypes.bool,
    lanId: PropTypes.string,
    company: PropTypes.string,
  }),
  signOut: PropTypes.func,
}

Header.contextTypes = {
  router: PropTypes.object,
}

Header.defaultProps = {
  classes: {},
  uploads: {},
  user: {
    email: undefined,
    isAuthorized: undefined,
    noAccessModalShown: false,
  },
}

const mapStateToProps = (state = {}) => ({
  uploads: state.uploads,
  user: {
    email: selectUserEmail()(state),
    isAuthorized: selectIsAuthorized()(state),
    noAccessModalShown: state.auth?.noAccessModalShown,
    lanId: selectUserId()(state),
    company: selectUserCompany()(state),
    memberOf: selectMemberOf()(state),
    displayName: state.auth?.displayName,
  },
  role: state.headerReducer?.role,
  isToggleRightSidenav: selectIsToggleRightSidenav()(state),
  rightSideNavComponent: selectRightSideNavComponent()(state),
})

const MyComponent = (props) => {
  const classes = styles()
  return <Header {...props} classes={classes} />
}

export default connect(mapStateToProps, {
  toggleUploadManager,
  toggleRightSidenav,
})(withAuth()(withEnv()(withRouter(MyComponent))))
