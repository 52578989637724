import * as React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'

import { withStyles } from '@mui/styles'
import Drawer from '@mui/material/Drawer'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import GroupIcon from '@mui/icons-material/Group'
import SearchIcon from '@mui/icons-material/Search'
import ProjectIcon from '@mui/icons-material/DeviceHub'
import { ViewAgenda } from '@mui/icons-material'

import { selectHeaderTitle } from './../../store/layout/selector'
import HeaderTitle from '../../components/Header/HeaderTitle'
import './index.css'

const drawerWidth = 240

const styles = () => ({
  blurb: {
    textAlign: 'center',
    paddingLeft: 75,
    paddingRight: 75,
  },
  content: {
    padding: '0px 24px 0px 24px',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: 'white',
    marginLeft: 70,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  grid: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  link: {
    textDecoration: 'none',
    color: 'black',
  },
  main: {
    flexGrow: 1,
    dislpay: 'flex',
  },
  root: {
    display: 'flex',
    zIndex: 0,
    position: 'relative',
  },
  sectionTitle: {
    marginTop: '15px',
  },
})

export class HelpPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: 0,
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = () => {
    let content = document.querySelectorAll('#content')
    let contentArray = [...content]
    let index = 0
    contentArray = contentArray.map(
      (element) => element.offsetTop - window.scrollY
    )

    for (let i = 0; i < contentArray.length; i++) {
      if (i === 0 && contentArray[0] > 0) {
        this.setState({ selected: 0 })
        break
      } else if (
        contentArray[i] > 0 &&
        Math.abs(100 - contentArray[i - 1]) > Math.abs(100 - contentArray[i])
      ) {
        this.setState({ selected: index })
        break
      } else {
        index += 1
      }
    }
  }

  render() {
    const { classes = {}, headerTitle = '' } = this.props
    const { selected } = this.state
    const topics = [
      'Overview',
      'Getting Started',
      'Inviting others to AssetHub',
      'Dashboard',
    ]
    const content = [
      <div>
        <p>
          AssetHub is Target’s Digital Asset Management (DAM) tool for all 2d
          assets. Our mission is to simplify the asset creation and management
          experience within Target by enabling teams to search for assets,
          download them, collaborate on projects, and communicate with internal
          and external partners throughout the asset creation process.
        </p>
        <Grid container spacing={3} className={classes.grid}>
          <Grid lg={3}>
            <Grid justifyContent="center" container xs={12}>
              <a href="/search">
                <SearchIcon className={classes.link}></SearchIcon>
              </a>
            </Grid>
            <Grid justifyContent="center" container xs={12}>
              <p className={classes.blurb}>
                Search for an asset and download it to use in your next project
              </p>
            </Grid>
          </Grid>
          <Grid lg={3}>
            <Grid justifyContent="center" container xs={12}>
              <a href="/projects">
                <ProjectIcon className={classes.link}></ProjectIcon>
              </a>
            </Grid>
            <Grid justifyContent="center" container xs={12}>
              <p className={classes.blurb}>
                Create a project to colaborate with specific users and to upload
                assets
              </p>
            </Grid>
          </Grid>
          <Grid lg={3}>
            <Grid justifyContent="center" container xs={12}>
              <a href="/boards">
                <ViewAgenda className={classes.link}></ViewAgenda>
              </a>
            </Grid>
            <Grid justifyContent="center" container xs={12}>
              <p className={classes.blurb}>
                Save a collection of assets to a board and share them with a
                group of users
              </p>
            </Grid>
          </Grid>
          <Grid lg={3}>
            <Grid justifyContent="center" container xs={12}>
              <a href="/groups">
                <GroupIcon className={classes.link}></GroupIcon>
              </a>
            </Grid>
            <Grid justifyContent="center" container xs={12}>
              <p className={classes.blurb}>
                Create groups to save you time when adding people to projects or
                boards
              </p>
            </Grid>
          </Grid>
        </Grid>
      </div>,
      <p>
        Welcome to AssetHub! You now have access to search for approved final
        assets and reuse them in your project, Create or join a project to
        collaborate with other, create a board and share it with other users.
        Check out the topics to the left to learn more.
      </p>,
      <div>
        <p>
          Would you like to invite other Team Members, contractors, or vendors
          to collaborate with you?
        </p>
        <h4>For Team Members and Contractors access:</h4>
        <p>
          1. Navigate to myaccess.target.com and request the APP-OAUTH2-ASSETHUB
          entitlement on their behalf. Once approved they will have access
          within the hour.
        </p>
        <h4>For Agencies (external users) access:</h4>
        <p>
          1. The company will need to be added to{' '}
          <a href="https://partnersonline.com/">Partners Online</a> (POL) as a
          Marketing Partner or Merchant Partner. <br />
          2. A company admin will need to be chosen to manage users for their
          company in{' '}
          <a href="https://vmm.target.com/vmm">
            Vendor Management and Maintenance
          </a>{' '}
          (VMM). <br />
          3. Each user will need to be added and set as active in VMM by the
          company admin. <br />
          4. The Target partner for the vendor will need to request AssetHub
          access for users on their behalf by reaching out to{' '}
          <a href="mailto: david.lahaye@target.com">
            david.lahaye@target.com
          </a>. <br />
          5. Once approved the users will be able to launch AssetHub from the{' '}
          <a href="https://partnersonline.com/">Partners Online</a> (POL) portal
          and begin using.
        </p>
      </div>,
      <p>Coming Soon</p>,
    ]

    return (
      <div>
        <HeaderTitle id="title" title="Help" icon="Help" />
        <Helmet>
          <title>{headerTitle}</title>
        </Helmet>
        <div className={classes.root}>
          <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <Toolbar />
            <div className={classes.drawerContainer}>
              <List>
                <MenuItem>
                  <ListItemText primary={<b>Topics</b>} />
                </MenuItem>
                <Divider />
                {topics.map((text, index) => (
                  <a href={'#' + text} className={classes.link}>
                    <MenuItem button key={text} selected={selected === index}>
                      <ListItemText primary={text} />
                    </MenuItem>
                  </a>
                ))}
              </List>
            </div>
          </Drawer>
          <main className={classes.main}>
            <Toolbar />
            {topics.map((text, index) => (
              <div>
                <div id="content" className={classes.content}>
                  <h3 id={text} className={classes.sectionTitle}>
                    {text}
                  </h3>
                  <div>{content[index]}</div>
                </div>
                <Divider />
              </div>
            ))}
          </main>
        </div>
      </div>
    )
  }
}

HelpPage.propTypes = {
  headerTitle: PropTypes.string,
  classes: PropTypes.object,
}
const mapStateToProps = (state) => ({
  headerTitle: selectHeaderTitle()(state),
})

export default connect(mapStateToProps)(withStyles(styles)(HelpPage))
