import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import cx from 'classnames'
import { Sort, Search, Add as AddIcon, Clear } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import {
  Paper,
  Drawer,
  Divider,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Button,
  TextField,
  Grid,
} from '@mui/material'

import SaveAlertDialog from './SaveAlertDialog'
import { selectUserId } from '../../store/auth/selector'
import { selectDownloadAssets } from '../../store/search/selector'
import { createBoard } from '../../store/boards/actions'

const styles = makeStyles((theme) => ({
  sidebarPaper: {
    height: '100%',
  },
  sidebar: {
    minWidth: '100%',
    backgroundColor: 'white',
    boxShadow: 'none',
    borderRadius: '4px',
    position: '-webkit-sticky',
    position: 'sticky',
    top: 0,
  },
  drawerHeader: {
    backgroundColor: useTheme().palette.primary.themePrimary + ' !important',
    textAlign: 'left',
  },
  boardsHeader: {
    backgroundColor: useTheme().palette.common.white + ' !important',
    textAlign: 'left',
  },
  dialogTitle: {
    flex: 1,
    fontSize: '20px',
    fontWeight: '450',
  },
  stickOnScroll: {
    position: '-webkit-sticky',
    position: 'sticky',
    top: 0,
  },
  addAssetToBoardButton: {
    margin: 5,
    width: '42%',
    color: '#188295',
    justifyContent: 'space-evenly',
    backgroundColor: '#ffffff',
    boxShadow: 'none',
    '&:hover': {
      color: '#188295',
      backgroundColor: '#F4F4F4',
      boxShadow: 'none',
    },
  },
  createABoardText: {
    marginTop: useTheme().spacing(2),
    marginLeft: useTheme().spacing(3),
    marginRight: useTheme().spacing(2),
    color: useTheme().palette.primary.darkBlue,
  },
  newBoardField: {
    marginTop: useTheme().spacing(2),
    marginRight: useTheme().spacing(2),
    marginLeft: useTheme().spacing(4),
    marginBottom: useTheme().spacing(2),
    border: `2px solid ${useTheme().palette.primary.darkBlue}`,
    borderRadius: 5,
    position: 'relative',
  },
  nameBoardText: {
    position: 'absolute',
    fontSize: 12,
    left: useTheme().spacing(),
    top: -useTheme().spacing(),
    border: `2px solid ${useTheme().palette.primary.contrastText}`,
    backgroundColor: useTheme().palette.primary.contrastText,
    color: useTheme().palette.primary.darkBlue,
  },
  newBoardBtnContainer: {
    padding: useTheme().spacing(),
  },
  createBoardBtn: {
    color: useTheme().palette.primary.contrastText,
    backgroundColor: useTheme().palette.primary.darkBlue,
    '&:hover': {
      color: useTheme().palette.primary.contrastText,
      backgroundColor: useTheme().palette.primary.darkBlue,
    },
    '&:disabled': {
      color: useTheme().palette.primary.contrastText,
      backgroundColor: useTheme().palette.primary.darkBlue,
      opacity: 0.6,
    },
  },
}))

export class LayoutSidebar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isConfirmDialoagOpen: false,
      createNewBoard: false,
      newBoardName: '',
      sortByOption: 'last_updated',
    }
  }

  onDialogClose = () => {
    const {
      toggleRightSidenav = () => {},
      rightSideNavComponent = '',
      isMetadataFormEdited,
    } = this.props
    const unsavedChanges = isMetadataFormEdited
    if (rightSideNavComponent === 'Add Metadata' && unsavedChanges) {
      this.setState({ isConfirmDialoagOpen: true })
    } else {
      toggleRightSidenav(rightSideNavComponent, false)
    }
  }

  onDontSave = () => {
    const { toggleRightSidenav = () => {}, rightSideNavComponent = '' } =
      this.props
    toggleRightSidenav(rightSideNavComponent, false)
    this.setState({ isConfirmDialoagOpen: false })
  }

  onSave = () => {
    const { rightSideNavComponent = '', setSaveAlertAction = () => {} } =
      this.props
    this.setState({ isConfirmDialoagOpen: false })
    setSaveAlertAction('save_' + rightSideNavComponent)
  }

  createBoard = () => {
    const { downloadAssets = {}, lanId = '' } = this.props
    const { newBoardName = '' } = this.state
    const assets = Object.values(downloadAssets).map(
      ({ assetId = '', fileName = '', filePath = '' }) => ({
        asset_id: assetId,
        file_name: fileName,
        asset_preview_path: filePath.split('?')[0],
      })
    )
    this.props.createBoard(newBoardName, assets, lanId)
    this.setState({ createNewBoard: false, newBoardName: '' })
  }

  submitSearch = (e) => {
    if (e) e.preventDefault()
    this.createBoard()
  }

  render() {
    const {
      classes = {},
      headerTitleText = '',
      customStyles = {},
      isOpen = false,
      children,
      rightSideNavComponent,
    } = this.props
    const { createNewBoard } = this.state
    const { isConfirmDialoagOpen } = this.state
    return (
      <Paper className={classes.sidebarPaper}>
        <div className={classes.stickOnScroll}>
          <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="right"
            open={isOpen}
            classes={{
              paper: cx(classes.sidebar, customStyles),
            }}
          >
            {rightSideNavComponent === 'Add Metadata' ? (
              <>
                <AppBar className={cx('dialogTitleBar', classes.drawerHeader)}>
                  <Toolbar className="dialogTitleHeight">
                    <Typography color="inherit" className={classes.dialogTitle}>
                      {headerTitleText}
                    </Typography>
                    <IconButton
                      data-cy="boardDialogClose"
                      className="dialogClose"
                      aria-label="Delete"
                      onClick={() => this.onDialogClose()}
                      size="large"
                    >
                      <Clear />
                    </IconButton>
                  </Toolbar>
                </AppBar>
                <Divider />
              </>
            ) : (
              <>
                <div>
                  <AppBar
                    className={cx('dialogTitleBar', classes.boardsHeader)}
                  >
                    <Toolbar className="dialogTitleHeight">
                      <Typography
                        color="primary"
                        className={classes.dialogTitle}
                      >
                        {headerTitleText}
                      </Typography>
                      {!createNewBoard && (
                        <Button
                          data-cy="boardsDialogAddButton"
                          onClick={() =>
                            this.setState({ createNewBoard: true })
                          }
                          variant="contained"
                          className={classes.addAssetToBoardButton}
                        >
                          <AddIcon /> Create Board
                        </Button>
                      )}
                      <IconButton
                        data-cy="boardDialogClose"
                        className="dialogClose"
                        aria-label="Delete"
                        onClick={() => this.onDialogClose()}
                        size="large"
                      >
                        <Clear color="primary" />
                      </IconButton>
                    </Toolbar>
                  </AppBar>
                </div>
                <div>
                  <Divider />
                  {createNewBoard && (
                    <>
                      <form onSubmit={this.submitSearch} method="GET">
                        <Grid container>
                          <Grid container spacing={2} direction={'column'}>
                            <Grid item className={classes.createABoardText}>
                              Create a board:
                            </Grid>
                            <Grid item className={classes.newBoardField}>
                              <div className={classes.nameBoardText}>
                                Board Name
                              </div>
                              <TextField
                                variant="standard"
                                fullWidth
                                onChange={(event) =>
                                  this.setState({
                                    newBoardName: event.target.value,
                                  })
                                }
                                InputProps={{ disableUnderline: true }}
                                inputProps={{
                                  'data-cy': 'boardsDialogNewBoardTextField',
                                }}
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            justifyContent={'flex-end'}
                            spacing={1}
                            className={classes.newBoardBtnContainer}
                          >
                            <Grid item>
                              <Button
                                onClick={() =>
                                  this.setState({ createNewBoard: false })
                                }
                              >
                                CANCEL
                              </Button>
                            </Grid>
                            <Grid item>
                              <Button
                                disabled={!this.state.newBoardName}
                                className={classes.createBoardBtn}
                                data-cy="boardsDialogCreateBoardButton"
                                type="submit"
                                onClick={this.createBoard}
                              >
                                CREATE BOARD
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </form>
                      <Divider />
                    </>
                  )}
                </div>
              </>
            )}
            <Fragment>
              <div ref="children">{children}</div>
            </Fragment>
          </Drawer>
          <div>
            <SaveAlertDialog
              isConfirmDialoagOpen={isConfirmDialoagOpen}
              onDontSave={this.onDontSave}
              onSave={this.onSave}
            />
          </div>
        </div>
      </Paper>
    )
  }
}

LayoutSidebar.propTypes = {
  classes: PropTypes.object,
  headerTitleText: PropTypes.string,
  toggleRightSidenav: PropTypes.func,
  setSaveAlertAction: PropTypes.func,
  rightSideNavComponent: PropTypes.string,
  customStyles: PropTypes.object,
  isOpen: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  isMetadataFormEdited: PropTypes.bool,
}
const mapStateToProps = (state = {}) => {
  state = Object.assign({}, state, {
    downloadAssets: selectDownloadAssets()(state),
    lanId: selectUserId()(state),
  })
  const { metadata = {} } = state
  const { isMetadataFormEdited } = metadata
  return {
    isMetadataFormEdited,
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createBoard,
    },
    dispatch
  )

const MyComponent = (props) => {
  const classes = styles()
  return <LayoutSidebar {...props} classes={classes} />
}
export default connect(mapStateToProps, mapDispatchToProps)(MyComponent)
