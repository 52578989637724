import axios from 'axios'
import { isEmpty } from 'lodash'
import {
  TOGGLE_UPLOAD_MANAGER,
  TOGGLE_PROJECT,
  UPLOAD_INITIATED,
  UPLOAD_START_CALL,
  UPLOAD_START_CALL_SUCCESS,
  UPLOAD_START_CALL_FAIL,
  CHUNK,
  CHUNK_SUCCESS,
  CHUNK_FAIL,
  UPLOAD_END_CALL,
  UPLOAD_END_CALL_FAIL,
  UPLOAD_END_CALL_SUCCESS,
  UPLOAD_JOB_STATUS_CALL,
  UPLOAD_JOB_STATUS_CALL_SUCCESS,
  UPLOAD_JOB_STATUS_CALL_FAIL,
} from './actionType'
import apiConfig from '../../config/apiConfig'
import * as uploadService from '../../services/commonUploadService'

import { getAllProjectAssets } from '../projects/actionCreator'

import { makeUploadFileTaggingData } from '../../components/ProjectsPage/project/assets/helper'

import { parseMissingChunkExceptionMessage } from '../../helpers/UploadHelper'
import firefly from '../../analytics/firefly'
import {
  constructMetadataPayload,
  updateJobStatusPayload,
} from '../../mappers/uploadMapper'

let lastFile = 0
let uploadCountMaxLimit = 15
let uploadCount = 0
export let uploadState = {}
export const injectUploadState = (state) => {
  uploadState = state
  if (state?.resetLastFile) {
    lastFile = 0
    uploadCount = 0
  }
}
// ============= Beginning of upload start Call ===============

export function uploadStartCallInit(projectUUID, fileName) {
  return {
    type: UPLOAD_START_CALL,
    projectUUID,
    fileName,
  }
}

export function uploadStartCallSuccess(projectUUID, fileName) {
  return {
    type: UPLOAD_START_CALL_SUCCESS,
    projectUUID,
    fileName,
  }
}

export function uploadStartCallFail(projectUUID, fileName) {
  return {
    type: UPLOAD_START_CALL_FAIL,
    projectUUID,
    fileName,
  }
}
// ============= End of upload start Call ===============

// ============= Beginning of chunk Call ===============

export function chunkInit(projectUUID, fileName) {
  return {
    type: CHUNK,
    projectUUID,
    fileName,
  }
}

export function chunkSuccess(projectUUID, fileName, completedChunks) {
  return {
    type: CHUNK_SUCCESS,
    projectUUID,
    fileName,
    completedChunks,
  }
}

export function chunkFail(projectUUID, fileName) {
  return {
    type: CHUNK_FAIL,
    projectUUID,
    fileName,
  }
}
// ============= End of chunk Call ===============

// ============= Beginning of chunk end call ===============

export function chunkEndCallInit(projectUUID, fileName) {
  return {
    type: UPLOAD_END_CALL,
    projectUUID,
    fileName,
  }
}

export function chunkEndCallSuccess(projectUUID, fileName) {
  return {
    type: UPLOAD_END_CALL_SUCCESS,
    projectUUID,
    fileName,
  }
}

export function chunkEndCallFail(projectUUID, fileName) {
  return {
    type: UPLOAD_END_CALL_FAIL,
    projectUUID,
    fileName,
  }
}
// ============= End of chunk end call ===============

// ============= Beginning of upload job status call ===============

export function uploadJobStatusInit(projectUUID, fileName) {
  return {
    type: UPLOAD_JOB_STATUS_CALL,
    projectUUID,
    fileName,
  }
}

export function uploadJobStatusSuccess(
  projectUUID,
  fileName,
  vaultUploadStatus
) {
  return {
    type: UPLOAD_JOB_STATUS_CALL_SUCCESS,
    projectUUID,
    fileName,
    vaultUploadStatus,
  }
}

export function uploadJobStatusFail(projectUUID, fileName) {
  return {
    type: UPLOAD_JOB_STATUS_CALL_FAIL,
    projectUUID,
    fileName,
  }
}
// ============= end of upload job status call ===============

export function initUpload(files, project) {
  return {
    type: UPLOAD_INITIATED,
    files,
    project,
  }
}

export function toggleProject(projectUUID) {
  return {
    type: TOGGLE_PROJECT,
    projectUUID,
  }
}

export function toggleUploadManager() {
  return {
    type: TOGGLE_UPLOAD_MANAGER,
  }
}

export function uploadManagerInit(files = []) {
  return (dispatch) => {
    files.forEach(function (singleFile) {
      dispatch(uploadStartCallInit(singleFile.project_uuid, singleFile.name))
    })
  }
}

export const uploadStartCall =
  (uploadFileState = {}) =>
  (dispatch) => {
    const { uploadFiles = [] } = uploadFileState
    let totalCall = uploadCount
    let uploadCountSt = uploadCount
    for (let i = lastFile; i < uploadFiles.length; i++) {
      totalCall = totalCall + uploadFiles[i].totalNumberOfChunks
      dispatch(validateFileSize(uploadFiles[i], uploadCountSt))
      lastFile++
      if (totalCall > uploadCountMaxLimit) {
        break
      }
    }
  }

export const validateFileSize = (file) => (dispatch) => {
  const fileSize = file.size
  const { isEnterpriseApi = false } = uploadState
  const { chunkUploadSize = Number(5242880) } = apiConfig
  if (isEnterpriseApi && fileSize < chunkUploadSize) {
    dispatch(initiateSimpleUpload(file))
  } else {
    dispatch(startLoop(file))
  }
}

export const initiateSimpleUpload = (file) => (dispatch, getState) => {
  const state = getState() || {}
  const { auth = {} } = state
  uploadCount = uploadCount + 1
  const { isEnterpriseApi = false } = uploadState
  dispatch(chunkEndCallInit(file.project_uuid, file.name))
  let metadataPayload = constructMetadataPayload(file, auth)
  dispatch(uploadStartCallSuccess(file.project_uuid, file.name))
  uploadService
    .simpleUploadApi(file, auth)
    .then((simpleUploadResponse) => {
      dispatch(chunkEndCallSuccess(file.project_uuid, file.name))
      const uploadData = simpleUploadResponse.data
      const jobStatusPayload = updateJobStatusPayload(
        uploadData,
        isEnterpriseApi
      )
      metadataPayload = Object.assign(metadataPayload, jobStatusPayload)
      uploadCount = uploadCount - 1
      dispatch(
        uploadJobStatusSuccess(
          file.project_uuid,
          file.name,
          jobStatusPayload.vault_upload_status
        )
      )
      if (uploadCount <= uploadCountMaxLimit) {
        dispatch(updateProjectAssets())
        uploadState.uploadSuccessCallback(uploadCount)
      }
    })
    .catch(() => {
      uploadCount = uploadCount - 1
      dispatch(uploadStartCallFail(file.project_uuid, file.name))
      dispatch(chunkEndCallFail(file.project_uuid, file.name))
      metadataPayload = Object.assign(metadataPayload, {
        vault_upload_status: 'ERROR',
      })
      if (uploadCount <= uploadCountMaxLimit) {
        uploadState?.uploadFailedCallback()
      }
    })
}

export const startLoop = (file) => (dispatch) => {
  uploadCount = uploadCount + 1
  const { isEnterpriseApi = false } = uploadState
  uploadService
    .uploadStartApi(file, isEnterpriseApi)
    .then((response) => {
      const { data = {} } = response
      const { asset_id = '', upload_id = '', job_id = '' } = data
      dispatch(uploadStartCallSuccess(file.project_uuid, file.name))
      file.uploadId = upload_id
      file.assetId = asset_id
      file.jobId = job_id
      // for retrying failed chunks returned from chunk end call
      file.failedChunkRetries = 0
      uploadCount = uploadCount - 1
      if (uploadCount <= uploadCountMaxLimit) {
        dispatch(startChunking(file, 0))
      }
    })
    .catch(() => {
      uploadCount = uploadCount - 1
      dispatch(uploadStartCallFail(file.project_uuid, file.name))
      if (uploadCount <= uploadCountMaxLimit) {
        uploadState?.uploadFailedCallback()
      }
    })
}

export const startChunking =
  (file = {}, chunkNumber) =>
  (dispatch) => {
    const remainingChunks = file.remainingChunks
    if (remainingChunks.includes(chunkNumber)) {
      for (
        let requestedChunk = chunkNumber;
        requestedChunk < remainingChunks.length;
        requestedChunk++
      ) {
        if (
          uploadCount <= uploadCountMaxLimit &&
          file.completedChunks < file.totalNumberOfChunks
        ) {
          uploadCount = uploadCount + 1
          file.currentChunk = requestedChunk
          delete file.remainingChunks[requestedChunk]
          dispatch(sendChunk(file, uploadCount))
        } else {
          break
        }
      }
    } else if (remainingChunks.length > 0) {
      file.currentChunk = remainingChunks[0]
      dispatch(startChunking(file, file.currentChunk))
    }
  }

export function sendChunk(file) {
  return (dispatch, getState) => {
    const state = getState() || {}
    const { auth = {} } = state
    const { lanId = '' } = auth

    dispatch(chunkInit(file.project_uuid, file.name))
    const { isEnterpriseApi = false } = uploadState
    uploadService
      .uploadChunkApi(file, isEnterpriseApi)
      .then((response = {}) => {
        const { data = {} } = response
        const chunkResponse = data
        file.partList.push(chunkResponse['chunk_etag'])
        file.completedChunks = file.completedChunks + 1
        uploadCount = uploadCount - 1

        dispatch(
          chunkSuccess(file.project_uuid, file.name, file.completedChunks)
        )
        if (file.completedChunks === file.totalNumberOfChunks) {
          dispatch(sendChunkEndCall(file, uploadCount))
          firefly.trackUpload(
            'upload',
            'SUCCESS',
            file.name,
            makeUploadFileTaggingData(file),
            lanId
          )
        } else {
          dispatch(startChunking(file, file.currentChunk + 1))
        }
      })
      .catch(() => {
        file.completedChunks = file.completedChunks + 1
        uploadCount = uploadCount - 1
        dispatch(chunkFail(file.project_uuid, file.name))
        firefly.trackUpload(
          'upload',
          'ERROR',
          file.name,
          makeUploadFileTaggingData(file),
          lanId
        )
      })
  }
}

export const sendChunkEndCall = (file) => (dispatch, getState) => {
  const state = getState() || {}
  const { auth = {} } = state
  dispatch(chunkEndCallInit(file.project_uuid, file.name))
  let metadataPayload = constructMetadataPayload(file, auth)
  uploadCount = uploadCount + 1
  const { isEnterpriseApi = false } = uploadState
  uploadService
    .uploadEndApi(file, isEnterpriseApi, auth)
    .then((response) => {
      dispatch(chunkEndCallSuccess(file.project_uuid, file.name))
      const res = response.data
      const jobStatusPayload = updateJobStatusPayload(res, isEnterpriseApi)
      metadataPayload = Object.assign(metadataPayload, jobStatusPayload)
      uploadCount = uploadCount - 1
      dispatch(
        uploadJobStatusSuccess(
          file.project_uuid,
          file.name,
          jobStatusPayload.vault_upload_status
        )
      )
      if (uploadCount <= uploadCountMaxLimit) {
        uploadState.uploadSuccessCallback(uploadCount)
        dispatch(updateProjectAssets())
      }
    })
    .catch((error) => {
      uploadCount = uploadCount - 1

      const message = error.response.data.message
      // parse chunk list from error message if exists
      const chunkList = parseMissingChunkExceptionMessage(message)
      if (chunkList) {
        // resend failed chunks
        file.remainingChunks = chunkList
        file.currentChunk = chunkList[0]
        file.completedChunks = file.totalNumberOfChunks - chunkList.length
        // limit end call - failed chunk to 3 retries
        file.failedChunkRetries = ++file.failedChunkRetries

        if (file.failedChunkRetries <= 3) {
          dispatch(startChunking(file, file.currentChunk, uploadCount))
        }
      }
      dispatch(chunkEndCallFail(file.project_uuid, file.name))
      metadataPayload = Object.assign(metadataPayload, {
        vault_upload_status: 'ERROR',
      })
      if (uploadCount <= uploadCountMaxLimit) {
        uploadState?.uploadFailedCallback()
      }
    })
}

export const updateProjectAssets = () => (dispatch, getState) => {
  const { projects } = getState() || {}
  const { projectData, pagination = {} } = projects
  const { project_uuid = '' } = projectData
  dispatch(getAllProjectAssets(project_uuid, pagination))
}
