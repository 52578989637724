import { createContext, useContext } from 'react'

const AssetListContext = createContext(null)

export function useAssetListContext() {
  const context = useContext(AssetListContext)
  if (!context) {
    throw new Error(
      'Partner.* component must be rendered as child of Partner component',
    )
  }
  return context
}

export default AssetListContext
