import { styled } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'

export const ExpandMore = styled((props) => {
  const { expand, ...other } = props
  return <IconButton {...other} />
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}))

export const renderCardActions = (
  cardActions = [],
  alignType = 'right',
  actionClickHandler = () => {}
) => {
  const filters = {
    left: (item) => item.alignType === alignType,
    right: (item) => item.alignType === alignType || !item.alignType,
  }
  return cardActions
    .filter((obj) => filters[alignType](obj))
    .map((actionObj = {}) => {
      const {
        actionName = '',
        actionIcon = '',
        iconLabel = '',
        iconText = '',
      } = actionObj
      return (
        <IconButton
          aria-label={iconLabel}
          onClick={() => actionClickHandler(actionName)}
        >
          {actionIcon}
          {iconText ? iconText : ''}
        </IconButton>
      )
    })
}
