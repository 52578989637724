import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@mui/styles'
import { FormControl, Select, MenuItem, Hidden } from '@mui/material'

import { SEARCH_PAGE_SIZES } from '../../constants/search'
import apiConfig from '../../config/apiConfig'

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    //marginLeft: '10px',
  },
  selectFilter: {
    height: '40px',
    fontWeight: '400',
    fontSize: '13px',
  },
}

const allowBigSearch = apiConfig.flags.ENABLE_BIG_SEARCH

export class PageSizeSelector extends React.Component {
  createMenuItems = () => {
    const pageSizes = allowBigSearch
      ? [...SEARCH_PAGE_SIZES, 500, 750, 1000]
      : SEARCH_PAGE_SIZES
    return pageSizes.map((size) => (
      <MenuItem data-cy={size} key={size} value={size}>
        {size + ' / page'}
      </MenuItem>
    ))
  }

  render() {
    const { pageSize, onPageSizeChange, classes } = this.props
    return (
      <FormControl variant="outlined" className={classes.root}>
        <Select
          variant="outlined"
          SelectDisplayProps={{ 'data-cy': 'pageSizeSelector' }}
          value={pageSize}
          onChange={onPageSizeChange}
          className={classes.selectFilter}
        >
          {this.createMenuItems()}
        </Select>
      </FormControl>
    )
  }
}

PageSizeSelector.propTypes = {
  pageSize: PropTypes.number.isRequired,
  onPageSizeChange: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(PageSizeSelector)
