import React from 'react'
import PropTypes from 'prop-types'
// import { Prompt } from 'react-router-dom'
import withRouter from '../../containers/Router/WithRouter'
import SetStatusModal from './SetStatusModal'
import UseCallbackPrompt from '../../containers/Router/UseCallbackPrompt'

export default function RouteLeavingGuard() {
  const [showPrompt, confirmNavigation, cancelNavigation] =
    UseCallbackPrompt(true)
  return (
    <React.Fragment>
      <SetStatusModal
        open={showPrompt}
        closeModal={cancelNavigation}
        onConfirm={confirmNavigation}
      />
    </React.Fragment>
  )
}
