const pdfRegex = /([a-zA-Z0-9\s_\\.\-\(\):])+(.pdf)$/i

export const isAssetMIMETypePDF = ({
  asset_content_type = '',
  mime_type = '',
  asset_name = '',
}) =>
  mime_type === 'application/pdf' ||
  asset_content_type === 'PDF' ||
  pdfRegex.test(asset_name)
