import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import withStyles from '@mui/styles/withStyles'
import Grid from '@mui/material/Grid'
import ImageList from '@mui/material/ImageList'
import ExpandIcon from '@mui/icons-material/ExpandMore'
import Button from '@mui/material/Button'
import withRouter from '../../containers/Router/WithRouter'
import GridCard from './GridCard'

const styles = {
  projectListing: {
    textAlign: 'center',
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  gridList: {
    justifyContent: 'center',
    display: 'contents',
  },
}

export class ImageGridList extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { classes, history, projects = {}, router = {} } = this.props
    const { projectList } = projects

    return (
      <div>
        <div className={classes.root}>
          <ImageList
            data-cy="projectGrid"
            rowHeight={'auto'}
            className={classes.gridList}
            cols={1}
          >
            {projectList.map((list = {}, key) => (
              <GridCard
                project={list}
                key={list.project_id + key}
                callBackEditProject={this.props.callBackEditProject}
                history={history}
                router={router}
              />
            ))}
          </ImageList>
        </div>
      </div>
    )
  }
}

ImageGridList.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  callBackEditProject: PropTypes.func,
  classes: PropTypes.object.isRequired,
  projects: PropTypes.shape({
    projectList: PropTypes.arrayOf(
      PropTypes.shape({
        show: PropTypes.bool,
      })
    ),
  }),
}

const mapStateToProps = (state) => {
  const { layout } = state
  const { headerTitle } = layout
  const { projects } = state
  return {
    projects: projects,
    headerTitle: headerTitle,
  }
}
export default connect(mapStateToProps)(
  withStyles(styles)(withRouter(ImageGridList))
)
