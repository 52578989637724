import {
  PROJECT_API_CALL_SUCCESS,
  PROJECT_API_CALL_FAIL,
  PROJECT_PAGE_API_CALL_SUCCESS,
  PROJECT_PAGE_API_CALL_FAIL,
  PROJECTS_FILTER,
  PROJECTS_SORT,
  CREATE_PROJECT_FAILURE,
  SUBMIT_FORM,
  CREATE_PROJECT_SUCCESS,
  GET_PROJECT_DETAILS_INIT,
  GET_PROJECT_DETAILS,
  GET_ASSET_UPLOAD_STATUS,
  PROJECT_OPEN_MODAL,
  PROJECT_CLOSE_MODAL,
  EDIT_PROJECT,
  EDIT_PROJECT_SUCCESS,
  EDIT_PROJECT_FAIL,
  GET_BRANDS_FULFILLED,
  GET_BRANDS_PENDING,
  GET_BRANDS_REJECTED,
  FETCH_PROJECT_DETAILS_ONEDIT_FULFILLED,
  FETCH_PROJECT_DETAILS_ONEDIT_PENDING,
  FETCH_PROJECT_DETAILS_ONEDIT_REJECTED,
  UPDATE_PROJECT_ERROR,
  SET_DEFAULT_PAGINATION,
  UPDATE_PAGINATION,
  GET_ALL_PROJECT_ASSETS,
  UPDATE_ASSET_LISTS,
  RESET_PROJECT_DATA,
  SET_PROJECT_COVER_IMAGE,
  GET_PROJECT_TASKS_FULFILLED,
  UPDATE_PROJECT_LIST_PAGINATION,
  SET_COVER_IMAGE_LOADING,
  SET_SELECTED_ASSETS,
  UPDATE_CREATIVE_ASSET_STATUS,
} from './actionType'

import {
  makeFilteredProjectList,
  makeSortedProjectList,
  getDefaultAssetPagination,
  updateTaskInfoToProjectList,
  getDefaultProjectListPagination,
} from './helpers'
import { getTaskReviewURL } from '../../helpers/TaskHelper'
import { modifyDisplayName } from '../../mappers/userMapper'

export const projectsInitialState = {
  isCoverImgLoading: false,
  projectList: [],
  projectCount: 0,
  projectListPagination: getDefaultProjectListPagination(),
  projectListloading: true,
  projectData: {},
  projectAssets: [],
  allProjectAssets: [],
  isAdmin: false,
  isUploadTaskExists: false,
  projectAdmins: '',
  projectAssetCount: 0,
  submitStatus: {
    loading: false,
    status: '',
    message: '',
  },
  modal: {
    editProject: false,
    updateErrorMessage: '',
  },
  loading: {
    editProject: false,
    projectDetails: false,
    loadAssets: false
  },
  brandList: {},
  projectDetailsForEdit: {},
  pagination: getDefaultAssetPagination(),
  creativeAssetStatus: 'All',
  selectedProjectAssets: {},
}

export default function projectsReducer(
  state = projectsInitialState,
  action = {}
) {
  let submitStatus
  let loading
  let modal
  switch (action.type) {
    case GET_BRANDS_FULFILLED: {
      const { payload: brandPayload = {} } = action
      const { data: brandList = {} } = brandPayload
      return {
        ...state,
        brandList: brandList,
      }
    }
    case GET_BRANDS_PENDING:
      return {
        ...state,
      }
    case GET_BRANDS_REJECTED:
      return {
        ...state,
      }
    case FETCH_PROJECT_DETAILS_ONEDIT_FULFILLED: {
      const { payload: projectDetailsPayload = {} } = action
      const { data: projectDetailsForEdit = [] } = projectDetailsPayload
      return {
        ...state,
        projectDetailsForEdit: projectDetailsForEdit[0],
        loading: {
          ...state.loading,
          loading: false,
          editProject: false,
        },
      }
    }
    case FETCH_PROJECT_DETAILS_ONEDIT_PENDING:
      return {
        ...state,
        loading: {
          ...state.loading,
          loading: true,
          editProject: true,
        },
      }
    case FETCH_PROJECT_DETAILS_ONEDIT_REJECTED:
      return {
        ...state,
        loading: {
          ...state.loading,
          loading: false,
          editProject: false,
        },
      }
    case PROJECT_OPEN_MODAL:
      modal = {
        ...state.modal,
        [action.modalName]: true,
      }
      return {
        ...state,
        modal,
      }
    case PROJECT_CLOSE_MODAL:
      modal = {
        ...state.modal,
        [action.modalName]: false,
        editProjectError: false,
      }
      return {
        ...state,
        modal,
      }
    case EDIT_PROJECT:
      loading = {
        ...state.loading,
        editProject: true,
      }
      submitStatus = {
        loading: true,
        status: '',
        message: '',
      }
      return {
        ...state,
        loading,
        submitStatus,
      }
    case EDIT_PROJECT_SUCCESS:
      loading = {
        loading: false,
        editProject: false,
      }
      submitStatus = {
        loading: false,
        status: 'success',
        message: 'Project updated successfully',
      }
      return {
        ...state,
        loading,
        submitStatus,
      }
    case EDIT_PROJECT_FAIL:
      loading = {
        ...state.loading,
        editProject: false,
      }
      submitStatus = {
        loading: false,
        status: 'failure',
        message: 'Project update failed',
      }
      return {
        ...state,
        loading,
        submitStatus,
      }
    case SUBMIT_FORM:
      submitStatus = {
        loading: true,
        status: '',
        message: '',
      }
      return { ...state, submitStatus }
    case CREATE_PROJECT_FAILURE: {
      let projectStatus = {
        loading: false,
        status: 'failure',
        message: 'Project creation failed',
      }
      return { ...state, submitStatus: projectStatus }
    }
    case CREATE_PROJECT_SUCCESS: {
      let projectSuccessStatus = {
        loading: false,
        status: 'success',
        message: 'Project created successfully',
      }
      return { ...state, submitStatus: projectSuccessStatus }
    }

    case PROJECT_API_CALL_SUCCESS: {
      submitStatus = {
        loading: false,
        status: '',
        message: '',
      }
      const projects = action.data
      let projectCount = 0
      if (projects.length > 0) {
        projectCount = projects[0].total_projects
      }

      return {
        ...state,
        projectList: projects,
        projectCount,
        submitStatus,
        projectListloading: false,
      }
    }
    case PROJECT_API_CALL_FAIL:
      return {
        ...state,
        apiError: action.error,
        projectListloading: false,
        loading: {
          loadAssets: false
        }
      }
    case PROJECT_PAGE_API_CALL_SUCCESS: {
      submitStatus = {
        loading: false,
        status: '',
        message: '',
      }
      const newProjectsPage = action.data
      const newProjectList = [...state.projectList].concat(newProjectsPage)

      return {
        ...state,
        projectList: newProjectList,
        submitStatus,
        projectListloading: false,
      }
    }

    case PROJECT_PAGE_API_CALL_FAIL:
      return {
        ...state,
        apiError: action.error,
        projectListloading: false,
        loading: {
          loadAssets: false
        }
      }

    case PROJECTS_FILTER: {
      let filterProjects = makeFilteredProjectList(action.data, [
        ...state.projectList,
      ])
      return {
        ...state,
        ...filterProjects,
      }
    }
    case PROJECTS_SORT: {
      let sortResults = makeSortedProjectList(action.data, [
        ...state.projectList,
      ])
      return {
        ...state,
        projectList: sortResults,
      }
    }

    case GET_ASSET_UPLOAD_STATUS: {
      const { assetList = [], assetCount = 0 } = action.payload
      return {
        ...state,
        projectAssets: assetList,
        projectAssetCount: assetCount,
      }
    }
    case GET_PROJECT_DETAILS_INIT:
      loading = {
        ...state.loading,
        projectDetails: true,
      }
      return {
        ...state,
        loading,
      }
    case GET_PROJECT_DETAILS: {
      let projAdms = []
      const projectData = action.data || {}
      let activeTasks = []
      let inActiveTasks = []
      let isUploadTaskExists = false
      const projectAdmins = (action.data && action.data.project_admins) || []
      const {
        assets = [],
        project_uuid: projectUUID = '',
        tasks = {},
        project_asset_count: projectAssetCount = 0,
      } = projectData
      const {
        admin_tasks: adminTasks = [],
        user_tasks: userTasks = [],
        is_admin: isAdmin = false,
      } = tasks || {}
      projectAdmins.forEach((element) => {
        const { email_address = '', organization = '' } = element
        projAdms.push(modifyDisplayName(email_address, organization))
      })
      adminTasks &&
        adminTasks.filter((adminTask = {}) => {
          const { is_active } = adminTask
          adminTask.review_url = getTaskReviewURL(adminTask, projectUUID)
          is_active
            ? activeTasks.push(adminTask)
            : inActiveTasks.push(adminTask)
        })
      userTasks &&
        userTasks.filter((userTask = {}) => {
          const { is_active } = userTask
          userTask.review_url = getTaskReviewURL(userTask, projectUUID)
          is_active ? activeTasks.push(userTask) : inActiveTasks.push(userTask)
        })
      isUploadTaskExists =
        ((activeTasks && activeTasks.filter((el) => el.task_type === 4)) || [])
          .length > 0
      loading = {
        ...state.loading,
        projectDetails: false,
      }
      return {
        ...state,
        projectData,
        loading,
        projectAssets: assets,
        projectAdmins: (projAdms || []).join(', ') || '',
        projectTasks: tasks,
        isAdmin: isAdmin,
        isUploadTaskExists: isUploadTaskExists,
        projectActiveTasks: activeTasks,
        projectInActiveTasks: inActiveTasks,
        projectAssetCount,
      }
    }
    case UPDATE_PROJECT_ERROR:
      modal = {
        ...state.modal,
        updateErrorMessage: action.updateErrorMessage,
      }
      return {
        ...state,
        modal,
      }

    case UPDATE_CREATIVE_ASSET_STATUS: {
      const { payload } = action
      return {
        ...state,
        creativeAssetStatus: payload,
        loading:{
          loadAssets: true
        }
      }
    }

    case UPDATE_PAGINATION: {
      const newPagination = action.data
      const { page = 0, size = 21, sort = {} } = newPagination
      const { field = 'asset_import_date', direction = 'DESC' } = sort
      return {
        ...state,
        pagination: {
          size,
          page,
          sort: {
            field,
            direction,
          },
        },
      }
    }

    case GET_ALL_PROJECT_ASSETS: {
      const { pagination = {} } = state
      const { page: pageNumber = 0, size: pageSize = 0 } = pagination
      const { data = {} } = action
      const { assets: allAssets = [], project_asset_count = 0 } = data
      // set current page assets
      const pageStartIndex = pageNumber === 0 ? 0 : pageSize * pageNumber
      const assetPage = allAssets.slice(
        pageStartIndex,
        pageStartIndex + pageSize
      )
      return {
        ...state,
        projectAssets: assetPage,
        allProjectAssets: allAssets,
        projectAssetCount: project_asset_count,
        loading: {
          loadAssets: false          
        }
      }
    }

    case UPDATE_ASSET_LISTS: {
      const { assetPage: currentPage = [], assetList: projectAssets = [] } =
        action
      return {
        ...state,
        projectAssets: currentPage,
        allProjectAssets: projectAssets,
      }
    }

    case SET_SELECTED_ASSETS: {
      return {
        ...state,
        selectedProjectAssets: action.data,
      }
    }

    case SET_DEFAULT_PAGINATION:
      return {
        ...state,
        pagination: getDefaultAssetPagination(),
      }

    case RESET_PROJECT_DATA:
      return {
        ...projectsInitialState,
      }
    case SET_COVER_IMAGE_LOADING:
      return {
        ...state,
        isCoverImgLoading: true,
      }

    case SET_PROJECT_COVER_IMAGE: {
      const coverImage = action.data || ''
      const projData = state.projectData
      const tempProjectData = { ...projData, ...{ cover_image: coverImage } }
      return {
        ...state,
        projectData: tempProjectData,
        isCoverImgLoading: false,
      }
    }

    case GET_PROJECT_TASKS_FULFILLED: {
      let modifiedProjectList = updateTaskInfoToProjectList(
        [...state.projectList],
        action.data
      )
      return {
        ...state,
        projectList: modifiedProjectList,
      }
    }

    case UPDATE_PROJECT_LIST_PAGINATION:
      return {
        ...state,
        projectListPagination: action.data,
      }

    default:
      return state
  }
}
