import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withStyles } from '@mui/styles'
import { Tooltip } from '@mui/material'
import {
  AssignmentTurnedIn,
  Block,
  PauseCircleOutline,
} from '@mui/icons-material'

import { praxis } from '../../config/themeConfig'
import { commentClassifications, TaskRoles } from '../../constants/annotation'
import { updateComment } from '../../store/annotation/actions'
import {
  selectIsActiveReview,
  selectIsCurrentTaskRole,
} from '../../store/annotation/selectors'

const styles = {
  button: {
    marginLeft: '6px',
    cursor: 'pointer',
  },
  buttons: {
    minHeight: '28px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '5px 0px 5px 55px',
  },
}

export class CardButtonRow extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      showClassifyButtons: true,
    }
  }

  onClassifyComment = (type = '') => {
    const {
      comment = {},
      updateComment = () => {},
      isProjectAdmin = false,
    } = this.props
    if (isProjectAdmin) {
      if (comment.status === type) {
        return updateComment({
          ...comment,
          status: commentClassifications.NEW,
        })
      }
      return updateComment({
        ...comment,
        status: type,
      })
    }
  }

  render() {
    const { classes = {}, comment = {}, isProjectAdmin = false } = this.props
    const { status = 'NEW' } = comment
    const { showClassifyButtons } = this.state
    const statusProceed = status === commentClassifications.PROCEED
    const statusIgnore = status === commentClassifications.IGNORE
    const statusHold = status === commentClassifications.HOLD
    return (
      <div className={classes.buttons}>
        <div>
          <Tooltip title="Proceed with Change" placement="top">
            <AssignmentTurnedIn
              onClick={() =>
                this.onClassifyComment(commentClassifications.PROCEED)
              }
              className={classes.button}
              style={{
                color: statusProceed ? 'green' : praxis.lightGrey,
                display:
                  statusProceed || (isProjectAdmin && showClassifyButtons)
                    ? 'inline'
                    : 'none',
              }}
            />
          </Tooltip>
          <Tooltip title="Disregard Change" placement="top">
            <Block
              onClick={() =>
                this.onClassifyComment(commentClassifications.IGNORE)
              }
              className={classes.button}
              style={{
                color: statusIgnore ? 'red' : praxis.lightGrey,
                display:
                  statusIgnore || (isProjectAdmin && showClassifyButtons)
                    ? 'inline'
                    : 'none',
              }}
            />
          </Tooltip>
          <Tooltip title="On Hold - Clarification" placement="top">
            <PauseCircleOutline
              onClick={() =>
                this.onClassifyComment(commentClassifications.HOLD)
              }
              className={classes.button}
              style={{
                color: statusHold ? '#999900' : praxis.lightGrey,
                display:
                  statusHold || (isProjectAdmin && showClassifyButtons)
                    ? 'inline'
                    : 'none',
              }}
            />
          </Tooltip>
        </div>
      </div>
    )
  }
}

CardButtonRow.propTypes = {
  toggleEditing: PropTypes.func,
  onDeleteClicked: PropTypes.func,
  toggleHideAnnotation: PropTypes.func,
  updateComment: PropTypes.func,
  classes: PropTypes.object,
  createdBy: PropTypes.string,
  isEditing: PropTypes.bool,
  isHidden: PropTypes.bool,
  isSecondary: PropTypes.bool,
  isActiveReview: PropTypes.bool,
  isProjectAdmin: PropTypes.bool,
  parsedMarkup: PropTypes.shape({
    data: PropTypes.shape({
      id: PropTypes.number,
    }),
  }),
  comment: PropTypes.shape({
    status: PropTypes.string,
  }),
}

const mapStateToProps = (state = {}) => {
  const isActiveReview = selectIsActiveReview()(state)
  const isProjectAdmin = selectIsCurrentTaskRole(TaskRoles.admin)(state)
  return {
    isActiveReview,
    isProjectAdmin,
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateComment,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(CardButtonRow))
