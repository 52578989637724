import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  FormControl,
  InputLabel,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import DeleteIcon from '@mui/icons-material/Delete'

import {
  METADATA_CARD_HIDE_BUTTON_TEXT,
  METADATA_CARD_SHOW_BUTTON_TEXT,
  METADATA_REVISION_DELETE_TEXT,
  METADATA_REVISION_DELETE_TITLE,
} from '../../constants/metadata'
import CollapsibleCard from '../../components/Custom/CollapsibleCard'
import { convertUTCtoLocal } from '../../utils/utils'
import {
  deleteRevisionsForAsset,
  fetchMetadataForAsset,
} from '../../store/metadata/metadataSlice'
import ImageLoader from '../../components/Custom/ImageLoader'

import { useMetadataContext } from './MetadataContext'

const styles = makeStyles((theme) => ({
  sectionWrapper: {
    '&:hover': {
      backgroundColor: useTheme().palette.primary.lightestGrey,
      '& .actionBtnHover': {
        visibility: 'visible',
      },
    },
  },
  thumbnailStyle: {
    verticalAlign: 'middle',
    maxHeight: '200px',
    maxWidth: '200px',
  },
  thumbnailWrapper: {
    textAlign: 'center',
    padding: '10px 0',
  },
  actionWrapper: {
    paddingRight: '10px',
    paddingLeft: '10px',
  },
  actionBtn: {
    visibility: 'hidden',
  },
  formControl: {
    minWidth: '200px',
    margin: useTheme().spacing(1),
    '& label': {
      color: useTheme().palette.primary.lightGrey + ' !important',
    },
  },
  formStaticText: {
    fontSize: '1rem',
  },
  dialogTitle: {
    color: useTheme().palette.primary.themePrimary + ' !important',
    '& h2': {
      color: useTheme().palette.primary.themePrimary + ' !important',
    },
  },
  deleteButton: {
    margin: useTheme().spacing(),
    backgroundColor: useTheme().palette.primary.bostonRed,
    color: useTheme().palette.primary.contrastText,
    '&:hover': {
      backgroundColor: useTheme().palette.primary.main,
    },
  },
  cancelButton: {
    margin: useTheme().spacing(),
    color: useTheme().palette.primary.primaryBlue,
  },
  leftIcon: {
    marginRight: useTheme().spacing(),
  },
}))

function RevisionsCard({ containerClasses = {} }) {
  const classes = styles()
  const dispatch = useDispatch()
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [selectedAssetId, setSelectedAssetId] = useState('')
  const { assetRevisions = [] } = useSelector((state) => state.newMetadata)
  const { isEditMode = false } = useMetadataContext()

  const openConfirmation =
    (assetId = '') =>
    () => {
      setShowConfirmation(true)
      setSelectedAssetId(assetId)
    }

  const closeConfirmation = () => {
    setShowConfirmation(false)
    setSelectedAssetId('')
  }

  const deleteRevision = () => {
    if (selectedAssetId) {
      const assetUoiIds = []
      assetUoiIds.push(selectedAssetId)
      const payload = {
        asset_uoi_ids: assetUoiIds,
      }
      dispatch(deleteRevisionsForAsset(payload)).then((response) => {
        dispatch(fetchMetadataForAsset(selectedAssetId))
      })
    }
  }
  return (
    <CollapsibleCard
      cardTitle={'Revisions'}
      hideButtonText={METADATA_CARD_HIDE_BUTTON_TEXT}
      showButtonText={METADATA_CARD_SHOW_BUTTON_TEXT}
      fullWidthContent
    >
      <div className={containerClasses.metadataCardContent}>
        <Table className={containerClasses.metadataTable}>
          <TableBody>
            {assetRevisions.map((assetRevision, key) => {
              const {
                asset_id: assetId = '',
                uploaded_date: importDate = '',
                metadata: metadataInfo = {},
                revision_number: revisionNumber = '',
                is_latest_revision: isLatestRevision = false,
                render_urls: { thumbnail_asset_url = '' } = {},
              } = assetRevision
              const { file_metadata = {} } = metadataInfo
              const { file_name: fileName = '' } = file_metadata || {}
              const importDateFormatted =
                convertUTCtoLocal(importDate, 'lll') || ''
              return (
                <TableRow key={key} className={classes.sectionWrapper}>
                  <TableCell className={classes.actionWrapper}>
                    {isEditMode && isLatestRevision && (
                      <div
                        className={classes.actionBtn + ' actionBtnHover'}
                        aria-label={METADATA_REVISION_DELETE_TITLE}
                        onClick={openConfirmation(assetId)}
                      >
                        <DeleteIcon />
                      </div>
                    )}
                  </TableCell>
                  <TableCell className={classes.thumbnailWrapper}>
                    <ImageLoader
                      imgSrc={thumbnail_asset_url}
                      altText={fileName}
                      className={classes.thumbnailStyle}
                    />
                  </TableCell>
                  <TableCell>
                    {fileName && (
                      <FormControl
                        variant="standard"
                        className={classes.formControl}
                        disabled
                      >
                        <InputLabel htmlFor={`revFileName${key}`} shrink>
                          File Name
                        </InputLabel>
                        <div className={containerClasses.formStaticWrap}>
                          <span
                            id={`revFileName${key}`}
                            className={classes.formStaticText}
                          >
                            {fileName}
                          </span>
                        </div>
                      </FormControl>
                    )}
                    {importDateFormatted && (
                      <FormControl
                        variant="standard"
                        className={classes.formControl}
                        disabled
                      >
                        <InputLabel htmlFor={`revImportDate${key}`} shrink>
                          Upload Date
                        </InputLabel>
                        <div className={containerClasses.formStaticWrap}>
                          <span
                            id={`revImportDate${key}`}
                            className={classes.formStaticText}
                          >
                            {importDateFormatted}
                          </span>
                        </div>
                      </FormControl>
                    )}
                    {revisionNumber && (
                      <FormControl
                        variant="standard"
                        className={classes.formControl}
                        disabled
                      >
                        <InputLabel htmlFor={`revRevisionNumber${key}`} shrink>
                          Revision Number
                        </InputLabel>
                        <div className={containerClasses.formStaticWrap}>
                          <span
                            id={`revRevisionNumber${key}`}
                            className={classes.formStaticText}
                          >
                            {revisionNumber}
                          </span>
                        </div>
                      </FormControl>
                    )}
                  </TableCell>
                </TableRow>
              )
            })}
            {assetRevisions.length === 0 && (
              <TableRow>
                <TableCell>
                  <div className={containerClasses.noResults}>
                    No details available
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {showConfirmation && (
          <Dialog open={showConfirmation} onClose={closeConfirmation}>
            <DialogTitle
              id="alert-dialog-title"
              className={classes.dialogTitle}
            >
              {METADATA_REVISION_DELETE_TITLE}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {METADATA_REVISION_DELETE_TEXT}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={deleteRevision}
                variant="contained"
                className={classes.deleteButton}
              >
                <DeleteIcon className={classes.leftIcon} />{' '}
                {METADATA_REVISION_DELETE_TITLE}
              </Button>
              <Button
                onClick={closeConfirmation}
                variant="contained"
                className={classes.cancelButton}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </div>
    </CollapsibleCard>
  )
}

export default RevisionsCard
