//

import * as React from 'react'

import { Link } from 'react-router-dom'
import Fab from '@mui/material/Fab'
import HomeIcon from '@mui/icons-material/Home'
import { withStyles } from '@mui/styles'
import BodyClass from './BodyClass'

const styles = {
  body: {
    background: '#ddd',
  },
  wrapper: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    left: '0',
    top: '0',
    width: '100%',
  },
  content: {
    boxSizing: 'border-box',
    margin: '0 auto',
    maxWidth: '320px',
    padding: '20px 30px',
    textAlign: 'center',
  },
  heading: {
    fontWeight: '300',
    fontSize: '34px',
    margin: '0',
    opacity: '.75',
  },
  subheading: {
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '1.5',
    margin: '1.8em 10px 2em',
    opacity: '.5',
  },
}

export function NotFound(props) {
  const { classes, route, title } = props
  return (
    <div className={classes.wrapper}>
      <BodyClass classNames={classes.body} />
      <div className={classes.content}>
        <div className={classes.heading}>Page Not Found</div>
        <div className={classes.subheading}>
          The page you requested is not available.
        </div>
        <Fab
          component={Link}
          role="link"
          to={route}
          title={title}
          aria-label={title}
          color="primary"
        >
          <HomeIcon />
        </Fab>
      </div>
    </div>
  )
}

NotFound.defaultProps = {
  route: '/',
  title: 'Home',
  classes: {},
}

export default withStyles(styles)(NotFound)
