import { createContext, useContext } from 'react'

const SearchContext = createContext(null)

export function useSearchContext() {
  const context = useContext(SearchContext)
  if (!context) {
    throw new Error(
      'Partner.* component must be rendered as child of Partner component',
    )
  }
  return context
}

export default SearchContext
