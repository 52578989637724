import React from 'react'
import queryString from 'query-string'
import { cloneDeep, invert } from 'lodash'
import moment from 'moment'
import {
  FILTER_TCIN_KEY_VALUE,
  FILTER_DPCI_KEY_VALUE,
  FILTER_PROJECT_ID_KEY_VALUE,
  FILTER_FILE_NAME_KEY_VALUE,
  FILTER_DEPARTMENT_ID_KEY_VALUE,
  FILTER_PROPERTY_NUMBER_KEY_VALUE,
  IGNORE_SEARCH_FILTER_LIST,
  PAGE_SELECTOR_DEFAULT_VALUE,
  QUERY_PARAMS_DECODE_MAP,
  QUERY_PARAMS_ENCODE_MAP,
  SEARCH_DEFAULT_FACETS,
  SEARCH_FILTER_FACET_MAP,
  FACET_UPLOAD_DATE_NAME,
  TODAY,
  YESTERDAY,
  LAST_SEVEN_DAYS,
  LAST_THIRTY_DAYS,
  DEFAULT_COLORS,
  SEARCH_TERM_FILTER_MAP,
} from '../constants/search'
import {
  departmentRegex,
  dpciWithHyphenRegex,
  dpciWithoutHyphenRegex,
  fileNameRegex,
  projectIDRegex,
  propertyNumberRegex,
  tcinRegex,
} from './Regexes'
import { capitalizeString } from './stringHelper'


const formatSelectedItems = (items = []) => {
  const newFilters = {}
  items.forEach((item) => {
    const data = item.props.data
    const key = data.label
    const value = data.detail
    if (newFilters[SEARCH_DEFAULT_FACETS[key]]) {
      newFilters[SEARCH_DEFAULT_FACETS[key]].push(value)
    } else {
      newFilters[SEARCH_DEFAULT_FACETS[key]] = [value]
    }
  })
  return newFilters
}

//Downshift package (Typeahead.js) was throwing errors, assigned this function to itemToString to fix
const formatSelectedItem = (item) => {
  if (item) {
    const data = item.props.data
    const key = data.label
    const value = data.detail
    return `${key}: ${value}`
  }
  return ''
}

const isFilterApplied = (facetName = '', appliedFilters = {}) => {
  const selectedFilter = Object.keys(appliedFilters).filter(
    (filterName) => SEARCH_FILTER_FACET_MAP[filterName] === facetName
  )
  return selectedFilter.length > 0
}

const getAppliedFilters = (appliedFilters = {}) => {
  let filteredData = {}
  const searchTermFilterMap = Object.keys(SEARCH_TERM_FILTER_MAP || {})
  const ignoreFilterMap = IGNORE_SEARCH_FILTER_LIST
  const ignoreFilterList = [...ignoreFilterMap, ...searchTermFilterMap]
  Object.keys(appliedFilters).map((prop) => {
    if (ignoreFilterList.indexOf(prop) === -1) {
      filteredData[SEARCH_FILTER_FACET_MAP[prop]] = appliedFilters[prop]
    }
  })
  return filteredData
}

const getAppliedFilterValues = (appliedFilters = {}) => {
  let filteredValues = []
  Object.keys(appliedFilters).map((prop) => {
    if (IGNORE_SEARCH_FILTER_LIST.indexOf(prop) === -1) {
      filteredValues = filteredValues.concat(appliedFilters[prop])
    }
  })
  return filteredValues
}

export const getDateLabelByType = ([startDate, endDate]) => {
  let label = `${startDate} to ${endDate}`
  const today = moment().format('MM-DD-YYYY')
  const yesterday = moment().subtract(1, 'days').format('MM-DD-YYYY')
  const last7days = moment().subtract(6, 'days').format('MM-DD-YYYY')
  const last30days = moment().subtract(29, 'days').format('MM-DD-YYYY')

  if (startDate === endDate) {
    if (startDate === today) {
      label = TODAY
    } else if (startDate === yesterday) {
      label = YESTERDAY
    }
  } else if (endDate === today) {
    if (startDate === last7days) {
      label = LAST_SEVEN_DAYS
    } else if (startDate === last30days) {
      label = LAST_THIRTY_DAYS
    }
  }
  return label
}

export const formatFilterDisplayValues = (filters = {}) => {
  const filtersValues = cloneDeep(filters)
  Object.keys(filtersValues).forEach((filterKey) => {
    switch (filterKey) {
      case FACET_UPLOAD_DATE_NAME: {
        let label = getDateLabelByType(filtersValues[filterKey])
        return (filtersValues[filterKey] = [label])
      }
      // return _filters[filterKey] = [_filters[filterKey].join(' to ')];
      default:
        return filtersValues[filterKey]
    }
  })
  return filtersValues
}

const mapSelectedSearchItems = (searchTerm = '', items = []) => {
  const searchParamObject = searchTerm ? { term: searchTerm } : {}
  let appliedFilterValues = ''
  let count = 0
  items.forEach((item) => {
    const data = item.props.data
    const key = QUERY_PARAMS_ENCODE_MAP[data.label]
    const value = data.detail
    appliedFilterValues = `${appliedFilterValues}${count > 0 ? ', ' : ''}${
      data.label
    }:${data.detail}`

    if (key && key in searchParamObject) {
      searchParamObject[key].push(value)
    } else {
      searchParamObject[key] = [value]
    }
    count++
  })
  return { searchParamObject, appliedFilterValues }
}

const mapSelectedItemsToQueryParams = (searchParamObject = {}) =>
  queryString.stringify(searchParamObject)

const mapQueryParamsToSelectedItems = (searchString = '') => {
  const params = queryString.parse(searchString)
  const newSelectedItems = []
  let searchTerm = ''
  Object.keys(params).forEach((key) => {
    if (key === 'term') {
      searchTerm = params[key]
    } else {
      if (key in QUERY_PARAMS_DECODE_MAP) {
        if (typeof params[key] === 'object') {
          params[key].forEach((item) => {
            const selectedItem = {
              label: QUERY_PARAMS_DECODE_MAP[key],
              detail: item,
            }
            newSelectedItems.push(
              <div data={selectedItem}>
                <strong>{selectedItem.label}:</strong> {selectedItem.detail}
              </div>
            )
          })
        } else {
          const selectedItem = {
            label: QUERY_PARAMS_DECODE_MAP[key],
            detail: params[key],
          }
          newSelectedItems.push(
            <div data={selectedItem}>
              <strong>{selectedItem.label}:</strong> {selectedItem.detail}
            </div>
          )
        }
      }
    }
  })
  return {
    searchTerm,
    chips: newSelectedItems.filter((x) => x),
  }
}

const getFilteredFacetValues = (filterValues = [], searchKeyword = '') =>
  filterValues.reduce((updatedFilterValues, filterValue = '') => {
    if (filterValue.toLowerCase().indexOf(searchKeyword.toLowerCase()) !== -1) {
      updatedFilterValues.push(filterValue)
    }
    return updatedFilterValues
  }, [])

const initFiltersInAppliedFilters = (initFilters = {}, appliedFilters = {}) =>
  Object.keys(initFilters).filter((element) =>
    Object.keys(appliedFilters).includes(element)
  )

const capitalizeFilterValues = (facetValues = []) =>
  facetValues.map((filterValue) => capitalizeString(filterValue))

const isSameDay = (a, b) => {
  if (!moment.isMoment(a) || !moment.isMoment(b)) return false
  // Compare least significant, most likely to change units first
  // Moment's isSame clones moment inputs and is a tad slow
  return (
    a.date() === b.date() && a.month() === b.month() && a.year() === b.year()
  )
}

const isHexValue = (hexString = '') => {
  const hexRegex =
    hexString.charAt(0) === '#'
      ? /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/
      : /^([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/
  return hexRegex.test(String(hexString))
}

const getColorFacetValue = (filterValue = '') =>
  Object.keys(DEFAULT_COLORS)[
    Object.values(DEFAULT_COLORS).indexOf((filterValue || '').toUpperCase())
  ] || filterValue

const createColorChipContent = (filterValue = '', filterName = '') => {
  const colorNamesCodes = invert(DEFAULT_COLORS)
  let colorObj = {
    background: 'transparent',
    height: '19px',
    width: '19px',
    cursor: 'pointer',
    position: 'relative',
    outline: 'none',
    borderRadius: '50%',
    transition: 'box-shadow 100ms ease 0s',
    contentVisibility: 'hidden',
  }
  colorObj['boxShadow'] =
    '#' +
    (colorNamesCodes[filterValue] || filterValue) +
    ' 0px 0px 0px 16px inset'
  return (
    <div
      style={{ display: 'flex', alignItems: 'flex-end' }}
      data={{ detail: filterValue || '', label: filterName }}
    >
      <div data-cy="filterChipFacet" title={filterValue} style={colorObj}>
        {filterName}
      </div>
      <span
        style={{ textTransform: 'upperCase', marginLeft: '2px' }}
      >{`${getColorFacetValue(filterValue)}`}</span>
    </div>
  )
}

const hexToRgb = (hex = '') => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  // eslint-disable-next-line no-param-reassign
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b
  })

  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null
}

function mapMinMax(value, oldMin, oldMax, newMin, newMax) {
  return ((newMax - newMin) * (value - oldMin)) / (oldMax - oldMin) + newMin
}

const rgbToHsv = ({ r, g, b }) => {
  // eslint-disable-next-line no-unused-expressions
  ;(r /= 255), (g /= 255), (b /= 255)

  let max = Math.max(r, g, b),
    min = Math.min(r, g, b)
  let h,
    s,
    v = max

  let d = max - min
  s = max == 0 ? 0 : d / max

  if (max == min) {
    h = 0 // achromatic
  } else {
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0)
        break
      case g:
        h = (b - r) / d + 2
        break
      case b:
        h = (r - g) / d + 4
        break
    }

    h /= 6
  }

  h = mapMinMax(h, 0, 1, 0, 360)
  h = Math.round(h)
  s = Math.round(s * 100)
  v = Math.floor(v * 100)

  return [h, s, v]
}

const hexToHSV = (hex = '') => {
  const rgbForHex = hexToRgb(hex)
  const getHSVFromRGB = rgbForHex ? rgbToHsv(rgbForHex) : null
  return getHSVFromRGB
}

export {
  capitalizeFilterValues,
  formatSelectedItems,
  formatSelectedItem,
  getFilteredFacetValues,
  getAppliedFilters,
  getAppliedFilterValues,
  isFilterApplied,
  mapSelectedItemsToQueryParams,
  mapSelectedSearchItems,
  mapQueryParamsToSelectedItems,
  initFiltersInAppliedFilters,
  isSameDay,
  isHexValue,
  createColorChipContent,
  hexToHSV,
}
