import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { withStyles, createStyles } from '@mui/styles'
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import TextField from '@mui/material/TextField'
import Datepicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import firefly from '../../../../analytics/firefly'

const styles = createStyles({
  picker: {
    width: '159px',
    fontSize: '1rem',
    height: '43px',
    padding: '0 5px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    borderRadius: 4,
    border: '1px solid #98989a91',
    textIndent: 6,
    fontWeight: 400,
  },
  label: {
    fontSize: 13,
    color: 'rgb(88, 88, 88)',
    marginBottom: 3,
  },
  expansionPanelRoot: {
    backgroundColor: 'unset !important',
    boxShadow: 'none !important',
    '&:before': {
      backgroundColor: 'unset !important',
      height: '0 !important',
    },
  },
  expansionPanelExpanded: {
    margin: 'unset !important',
    marginTop: '0 !important',
  },
  expansionPanelSummary: {
    color: '#4D4F5C !important',
    fontSize: '14px !important',
    margin: '7px 0px !important',
    backgroundColor: 'white !important',
    borderRadius: '5px !important',
    textTransform: 'uppercase !important',
    minHeight: '55px !important',
  },
  expansionPanelSummaryExpanded: {
    backgroundColor: '#e5e5e5 !important',
  },
  expansionPanelDetails: {
    padding: '0 0 0 5px !important',
    display: 'flex !important',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  customDatePicker: {
    width: 'unset',
    paddingBottom: '5px',
  },
  pickerSpace: {
    marginRight: 5,
    marginLeft: 5,
  },
})

export const DateFilter = ({
  classes = {},
  label = '',
  setFilterValue = () => {},
  filters = {},
  fetchAssets = () => {},
  lanId = '',
}) => {
  const { dateFrom = '', dateTo = '' } = filters
  useEffect(() => {
    if (dateFrom && dateTo) {
      firefly.trackPublishSearchClick(
        'publish_search',
        'filter',
        '',
        `fromDate: ${dateFrom}, toData: ${dateTo}`,
        lanId
      )
      fetchAssets()
    }
  }, [dateFrom, dateTo])

  return (
    <Accordion
      defaultExpanded={true}
      classes={{
        root: classes.expansionPanelRoot,
        expanded: classes.expansionPanelExpanded,
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        classes={{
          root: classes.expansionPanelSummary,
          expanded: classes.expansionPanelSummaryExpanded,
        }}
      >
        {label}
      </AccordionSummary>
      <AccordionDetails
        classes={{
          root: classes.expansionPanelDetails,
        }}
      >
        <DateFilterContainer
          classes={classes}
          name="dateFrom"
          label="Start Date"
          selectedValue={dateFrom}
          setFilterValue={setFilterValue}
          minDate={new Date(0)}
          maxDate={dateTo}
          fetchAssets={fetchAssets}
          filters={filters}
        />
        <div className={classes.pickerSpace}>{'to'}</div>
        <DateFilterContainer
          classes={classes}
          name="dateTo"
          label="End Date"
          selectedValue={dateTo}
          setFilterValue={setFilterValue}
          minDate={dateFrom}
          maxDate={new Date()}
          fetchAssets={fetchAssets}
          filters={filters}
        />
      </AccordionDetails>
    </Accordion>
  )
}

const DateFilterContainer = ({
  classes = {},
  label = '',
  name = '',
  setFilterValue = () => {},
  selectedValue = '',
  minDate = new Date(0),
  maxDate = new Date(),
}) => {
  const onCalenderChange = (date) => {
    setFilterValue({
      [name]: date,
    })
  }

  return (
    <Datepicker
      id={`${name}-input`}
      className={classes.picker}
      onChange={(date) => onCalenderChange(date)}
      wrapperClassName={classes.customDatePicker}
      placeholderText={label}
      todayButton="Today"
      isClearable={false}
      selected={selectedValue}
      minDate={minDate}
      maxDate={maxDate}
      renderInput={(props) => <TextField {...props} label={label} />}
    />
  )
}

DateFilter.propTypes = {
  classes: PropTypes.object.isRequired,
  fetchAssets: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  setFilterValue: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  lanId: PropTypes.string.isRequired,
}

export default withStyles(styles)(DateFilter)
