/*
👋 “Hey” from the Praxis team
This configuration file already implements our standard precommit logic.
*/

module.exports = {
  // see https://git.target.com/Praxis-Framework/create-praxis-app/tree/production/packages/precommit
  importFiles: () => {
    const r = require.context(
      '../../images/classification',
      true,
      /\.(png|jpe?g|svg|gif|mp4)$/
    )
    let images = {}
    r.keys().forEach((item, index) => {
      const folder = item.split('./')[1]
      const classificationId = folder.split('/')[0]
      if (images[classificationId]) {
        images[classificationId].push(r(item))
      } else {
        images[classificationId] = [r(item)]
      }
    })
    return images
  },
  // add or override additional commands here: https://github.com/okonet/lint-staged
}
