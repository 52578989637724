const selectIsToggleRightSidenav =
  () =>
  ({ layout = {} }) =>
    layout.isToggleRightSidenav
const selectRightSideNavComponent =
  () =>
  ({ layout = {} }) =>
    layout.rightSideNavComponent
const selectHeaderTitle =
  () =>
  ({ layout = {} }) =>
    layout.headerTitle

export {
  selectHeaderTitle,
  selectIsToggleRightSidenav,
  selectRightSideNavComponent,
}
