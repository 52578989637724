import React from 'react'
import {
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  Tooltip,
} from '@mui/material'
import { withStyles } from '@mui/styles'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { handleSetSortDirection } from '../helpers/AssetPublishHelpers'

const styles = (theme) => ({
  sort: {
    cursor: 'pointer',
    display: 'flex !important',
    alignItems: 'center',
  },
  tableCell: {
    padding: '4px 0px',
  },
  width5: {
    padding: '0px',
    borderTop: '1px solid #e0e0e0',
  },
  width10: {
    padding: '0px',
    borderTop: '1px solid #e0e0e0',
  },
  width15: {
    padding: '0px',
    borderTop: '1px solid #e0e0e0',
  },
  width20: {
    padding: '0px',
    borderTop: '1px solid #e0e0e0',
  },
})

function PublishAssetDetailHeader({
  numSelected = Number(0),
  rowCount = Number(0),
  classes = {},
  assetFilter = '',
  sortDirection = 'DESC',
  sortField = 'CREATED_AT',
  channelAssetsLength = Number(0),
  selectedChannelId = '',
  handleSelectAllClick = () => {},
  getChannelAssets = () => {},
  setChannelSortDirection = () => {},
  setChannelSortField = () => {},
}) {
  const sortColumn = (
    selectedChannelId = '',
    rowCount = Number(0),
    sortField = '',
    sortDirection = '',
    assetFilter = ''
  ) => {
    setChannelSortDirection(handleSetSortDirection(sortDirection))
    setChannelSortField(sortField)
    getChannelAssets({
      channelId: selectedChannelId,
      pageNumber: 0,
      pageSize: rowCount,
      sortDirection,
      sortBy: sortField,
      assetFilter,
    })
  }
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" className={classes.tableCell}>
          <Checkbox
            indeterminate={
              numSelected > 0 &&
              numSelected < rowCount &&
              numSelected < channelAssetsLength
            }
            checked={
              (numSelected === rowCount ||
                numSelected === channelAssetsLength) &&
              channelAssetsLength !== Number(0)
            }
            onChange={handleSelectAllClick}
            color="primary"
          />
        </TableCell>
        <TableCell padding="none" className={classes.tableCell}>
          THUMBNAIL
        </TableCell>
        <TableCell className={classes.tableCell}>
          <Tooltip title="Click to sort">
            <span
              className={classes.sort}
              role="presentation"
              onClick={() => {
                sortColumn(
                  selectedChannelId,
                  rowCount,
                  'ORIGINAL_FILE_NAME',
                  sortDirection,
                  assetFilter
                )
              }}
            >
              {`File Name`.toUpperCase()}
              {sortField === 'ORIGINAL_FILE_NAME' &&
                sortDirection === 'DESC' && <KeyboardArrowDownIcon />}
              {sortField === 'ORIGINAL_FILE_NAME' &&
                sortDirection === 'ASC' && <KeyboardArrowUpIcon />}
            </span>
          </Tooltip>
        </TableCell>
        <TableCell className={classes.tableCell}>
          {`Publish URL`.toUpperCase()}
        </TableCell>
        <TableCell className={classes.tableCell}>
          <Tooltip title="Click to sort">
            <span
              className={classes.sort}
              role="presentation"
              onClick={() => {
                sortColumn(
                  selectedChannelId,
                  rowCount,
                  'STATUS',
                  sortDirection,
                  assetFilter
                )
              }}
            >
              {`Status`.toUpperCase()}
              {sortField === 'STATUS' && sortDirection === 'DESC' && (
                <KeyboardArrowDownIcon />
              )}
              {sortField === 'STATUS' && sortDirection === 'ASC' && (
                <KeyboardArrowUpIcon />
              )}
            </span>
          </Tooltip>
        </TableCell>
        <TableCell className={classes.tableCell}>
          <Tooltip title="Click to sort">
            <span
              className={classes.sort}
              role="presentation"
              onClick={() => {
                sortColumn(
                  selectedChannelId,
                  rowCount,
                  'CREATED_BY',
                  sortDirection,
                  assetFilter
                )
              }}
            >
              {`Uploaded By`.toUpperCase()}
              {sortField === 'CREATED_BY' && sortDirection === 'DESC' && (
                <KeyboardArrowDownIcon />
              )}
              {sortField === 'CREATED_BY' && sortDirection === 'ASC' && (
                <KeyboardArrowUpIcon />
              )}
            </span>
          </Tooltip>
        </TableCell>
        <TableCell className={classes.tableCell}>
          <Tooltip title="Click to sort">
            <span
              className={classes.sort}
              role="presentation"
              onClick={() => {
                sortColumn(
                  selectedChannelId,
                  rowCount,
                  'CREATED_AT',
                  sortDirection,
                  assetFilter
                )
              }}
            >
              {`Uploaded On`.toUpperCase()}
              {sortField === 'CREATED_AT' && sortDirection === 'DESC' && (
                <KeyboardArrowDownIcon />
              )}
              {sortField === 'CREATED_AT' && sortDirection === 'ASC' && (
                <KeyboardArrowUpIcon />
              )}
            </span>
          </Tooltip>
        </TableCell>
      </TableRow>
    </TableHead>
  )
}

export default withStyles(styles)(PublishAssetDetailHeader)
