import React from 'react'
import { Card, CardMedia } from '@mui/material'

const ImageCard = ({ previewImage }) => (
  <Card
    sx={{
      display: 'flex',
      border: 'none',
      boxShadow: 'none',
      borderRadius: '0',
    }}
  >
    <CardMedia
      component="img"
      sx={{ width: 151, height: 100, objectFit: 'contain' }}
      image={previewImage}
      alt="Image used for search"
    />
  </Card>
)

export default ImageCard
