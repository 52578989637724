import * as React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { BOARD_USER_REMOVE_ADMIN_MESSAGE } from '../../../constants/boards'

const styles = makeStyles((theme) => ({
  button: {
    fontSize: '12px',
    color: 'white',
    backgroundColor: useTheme().palette.primary.bostonRed,
  },
  cancelButton: {
    fontSize: '12px',
    color: 'blue',
    marginLeft: '10px',
    backgroundColor: useTheme().palette.primary.lightestGrey,
    '&:hover': {
      backgroundColor: useTheme().palette.primary.lightestGrey,
    },
  },
}))

export const ConfirmationDialog = ({
  classes = {},
  open = false,
  dialogContentMsg = BOARD_USER_REMOVE_ADMIN_MESSAGE,
  dialogTitleText = '',
  close = () => {},
  handleConfirm = () => {},
}) => (
  <Dialog
    open={open}
    onClose={() => close()}
    scroll="paper"
    aria-labelledby="scroll-dialog-title"
    fullWidth
  >
    <DialogTitle id="alert-dialog-title">{dialogTitleText}</DialogTitle>
    <DialogContent style={{ width: 520 }}>{dialogContentMsg}</DialogContent>
    <DialogActions>
      <div>
        <Button
          variant="contained"
          className={classes.button}
          onClick={() => handleConfirm()}
        >
          Confirm
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => close()}
          className={classes.cancelButton}
        >
          Cancel
        </Button>
      </div>
    </DialogActions>
  </Dialog>
)

ConfirmationDialog.propTypes = {
  classes: PropTypes.object,
  open: PropTypes.bool,
  dialogContentMsg: PropTypes.string,
  dialogTitleText: PropTypes.string,
  close: PropTypes.func,
  handleConfirm: PropTypes.func,
}

const MyComponent = (props) => {
  const classes = styles()
  return <ConfirmationDialog {...props} classes={classes} />
}

export default MyComponent
