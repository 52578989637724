import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
} from '@mui/material'

function SaveAlertDialog({
  isConfirmDialoagOpen,
  onDontSave = () => {},
  onSave = () => {},
}) {
  return (
    <Dialog
      open={isConfirmDialoagOpen}
      onClose={onDontSave}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {'Unsaved Changes'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Do you want to save the metadata you have added?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onDontSave} color="primary">
          DON'T SAVE
        </Button>
        <Button onClick={onSave} color="primary" autoFocus>
          SAVE
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SaveAlertDialog
