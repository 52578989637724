import idx from 'idx'
import {
  CREATE_PROJECT_TASK_FULFILLED,
  CREATE_PROJECT_TASK_PENDING,
  CREATE_PROJECT_TASK_REJECTED,
  EDIT_PROJECT_TASK_FULFILLED,
  EDIT_PROJECT_TASK_PENDING,
  EDIT_PROJECT_TASK_REJECTED,
  OPEN_SNACKBAR,
  CLOSE_SNACKBAR,
  OPEN_MODAL_TASK,
  CLOSE_MODAL_TASK,
  TASK_API_CALL_INNITIATED,
  TASK_API_CALL_SUCCESS,
  TASK_API_CALL_FAIL,
  EXPAND_TASK,
  COLLAPSE_TASK,
  EXPAND_TASK_SUCCESS,
  EXPAND_TASK_FAIL,
  EXPAND_TASK_NOTIFICATIONS,
  COLLAPSE_TASK_NOTIFICATIONS,
  TASK_INFO_TASK_API_CALL_INNITIATED,
  TASK_INFO_API_CALL_SUCCESS,
  TASK_INFO_API_CALL_FAIL,
  EXPAND_GROUP,
  EXPAND_GROUP_SUCCESS,
  EXPAND_GROUP_FAIL,
  COLLAPSE_GROUP,
  RESET_TO_INIT_STATE,
  DOWNLOAD_TASK_REPORT_PENDING,
  DOWNLOAD_TASK_REPORT_FULFILLED,
  DOWNLOAD_TASK_REPORT_REJECTED,
} from './actionType'

export const initialState = {
  inProgress: false,
  taskInfo: [],
  taskList: [],
  taskCount: 0,
  taskCreate: {},
  taskExpandInfo: {},
  loading: {
    taskListLoading: false,
    expandTaskLoading: {},
    expandTaskGroupLoading: {},
    createTaskLoading: false,
    toggleTaskNotifications: {},
  },
  modal: {
    taskForm: false,
  },
  toast: {
    message: '',
    open: false,
  },
  downloadingTaskReport: '',
}

export default function task(state = initialState, action = {}) {
  let loading
  let modal
  const { payload = {}, type = '' } = action

  switch (type) {
    case OPEN_MODAL_TASK:
      modal = {
        ...state.modal,
        [action.modalName]: true,
      }
      return {
        ...state,
        modal,
      }
    case CLOSE_MODAL_TASK:
      modal = {
        ...state.modal,
        [action.modalName]: false,
      }
      return {
        ...state,
        modal,
      }
    case OPEN_SNACKBAR: {
      return {
        ...state,
        toast: {
          message: action.payload,
          open: true,
        },
      }
    }
    case CLOSE_SNACKBAR: {
      return {
        ...state,
        toast: {
          message: '',
          open: false,
        },
      }
    }

    // Task Page
    case TASK_API_CALL_INNITIATED:
      loading = {
        ...state.loading,
        taskListLoading: true,
      }
      return {
        ...state,
        loading,
      }
    case TASK_API_CALL_SUCCESS: {
      let activ = []
      let inActiv = []
      loading = {
        ...state.loading,
        taskListLoading: false,
      }
      let taskList = action.data.projectReviewTask
      let adminTask = taskList.is_admin
        ? action.data.projectReviewTask.admin_tasks
        : action.data.projectReviewTask.user_tasks
      let adminTaskCnt = adminTask.length
      let data = adminTask
      Object.keys(data).forEach((key) => {
        if (data[key].is_active) {
          activ.push(data[key])
        } else {
          inActiv.push(data[key])
        }
      })
      taskList.active = activ
      taskList.inActive = inActiv
      taskList.taskCount = adminTaskCnt
      taskList.catalog = action.data.catalog
      taskList.userAssets = action.data.projectUserAssets
      let channel = action.data.projectUserAssets.channel
      taskList.catalog = taskList.catalog[channel]
        ? taskList.catalog[channel].sort((a, b) => a.seq_num - b.seq_num)
        : ''
      return {
        ...state,
        loading,
        taskList,
        adminTaskCnt,
        taskCreate: {},
      }
    }
    case TASK_API_CALL_FAIL:
      loading = {
        ...state.loading,
        taskListLoading: false,
      }
      return {
        ...state,
        apiError: action.data,
        loading,
      }
    // Task expand
    case EXPAND_TASK:
      loading = {
        ...state.loading,
        expandTaskLoading: {
          [action.taskId]: true,
        },
      }
      return {
        ...state,
        loading,
      }
    case EXPAND_TASK_SUCCESS: {
      loading = {
        ...state.loading,
        expandTaskLoading: {
          [action.taskId]: false,
        },
      }
      let taskListExp = { ...state.taskList }
      let adminTaskExp = taskListExp.admin_tasks
        ? [...taskListExp.admin_tasks]
        : [...taskListExp.user_tasks]
      let activeExp = []
      let inActiveExp = []
      adminTaskExp.every(function (element, index) {
        if (element.job_id === action.taskId) {
          let colab = { ...adminTaskExp[index] }
          colab['details'] = action.data
          colab['expanded'] = true
          adminTaskExp[index] = colab
          return false
        } else {
          return true
        }
      })

      taskListExp.admin_tasks = adminTaskExp
      let dataExp = adminTaskExp
      Object.keys(dataExp).forEach((keys) => {
        if (dataExp[keys].is_active) {
          activeExp.push(dataExp[keys])
        } else {
          inActiveExp.push(dataExp[keys])
        }
      })
      taskListExp.active = activeExp
      taskListExp.inActive = inActiveExp
      return {
        ...state,
        taskList: taskListExp,
        loading,
      }
    }
    case EXPAND_TASK_FAIL:
      loading = {
        ...state.loading,
        expandTaskLoading: {
          [action.taskId]: false,
        },
      }
      return {
        ...state,
        loading,
      }
    case COLLAPSE_TASK: {
      let taskListCol = { ...state.taskList }
      let adminTaskCol = [...taskListCol.admin_tasks]
      let activeCol = []
      let inActiveCol = []
      adminTaskCol.every(function (element, index) {
        if (element.job_id === action.taskId) {
          let colab = { ...adminTaskCol[index] }
          colab['details'] = []
          colab['expanded'] = false
          adminTaskCol[index] = colab
          return false
        } else {
          return true
        }
      })

      taskListCol.admin_tasks = adminTaskCol
      let dataCol = adminTaskCol
      Object.keys(dataCol).forEach((keys) => {
        if (dataCol[keys].is_active) {
          activeCol.push(dataCol[keys])
        } else {
          inActiveCol.push(dataCol[keys])
        }
      })
      taskListCol.active = activeCol
      taskListCol.inActive = inActiveCol
      return {
        ...state,
        taskList: taskListCol,
      }
    }
    // End task expand
    // Task Info Page
    case TASK_INFO_TASK_API_CALL_INNITIATED:
      loading = {
        ...state.loading,
        taskInfoLoading: true,
      }
      return {
        ...state,
        loading,
      }
    case TASK_INFO_API_CALL_SUCCESS: {
      loading = {
        ...state.loading,
        taskInfoLoading: false,
      }
      let taskInfo = action.data
      // _.each(taskInfo.projectDetails.assets, function (associatedAsset, index) {
      //   _.each(taskInfo.assets, function (asset) {
      //     if (asset.asset_id && associatedAsset.asset_id && asset.asset_id === associatedAsset.asset_id) {
      //       taskInfo.projectDetails.assets[index].isChecked = true
      //     }
      //   })
      // })
      return { ...state, taskInfo: taskInfo }
    }

    case TASK_INFO_API_CALL_FAIL:
      loading = {
        ...state.loading,
        taskInfoLoading: false,
      }
      return {
        ...state,
        loading,
      }
    // End task info
    // Start Task Notifications Expand
    case EXPAND_TASK_NOTIFICATIONS:
      loading = {
        ...state.loading,
        toggleTaskNotifications: {
          [action.ntype]: {
            [action.index]: false,
          },
        },
      }
      return {
        ...state,
        loading,
      }
    // End Task Notifications Expand
    // Start Task Notifications Collapse
    case COLLAPSE_TASK_NOTIFICATIONS:
      loading = {
        ...state.loading,
        toggleTaskNotifications: {
          [action.ntype]: {
            [action.index]: true,
          },
        },
      }
      return {
        ...state,
        loading,
      }
    // End Task Notifications Collapse
    // Start task Expand group
    case EXPAND_GROUP:
      loading = {
        ...state.loading,
        expandTaskGroupLoading: {
          [action.groupId]: true,
        },
      }
      return {
        ...state,
        loading,
      }
    case EXPAND_GROUP_SUCCESS: {
      let taskExpandInfo = {}
      loading = {
        ...state.loading,
        expandTaskGroupLoading: {
          [action.groupId]: false,
        },
      }
      let result = action.data
      result.expanded = true
      taskExpandInfo[action.groupId] = result
      return {
        ...state,
        loading,
        taskExpandInfo: taskExpandInfo,
      }
    }
    case EXPAND_GROUP_FAIL:
      loading = {
        ...state.loading,
        expandTaskGroupLoading: {
          [action.groupId]: false,
        },
      }
      return {
        ...state,
        loading,
      }
    case COLLAPSE_GROUP:
      return {
        ...state,
        taskExpandInfo: {},
      }
    // End task Expand group

    case CREATE_PROJECT_TASK_PENDING:
    case EDIT_PROJECT_TASK_PENDING:
      loading = {
        ...state.loading,
        createTaskLoading: true,
      }
      return {
        ...state,
        loading,
        inProgress: true,
      }
    case CREATE_PROJECT_TASK_FULFILLED:
    case EDIT_PROJECT_TASK_FULFILLED: {
      let taskCreate = payload.data
      loading = {
        ...state.loading,
        createTaskLoading: false,
      }
      return {
        ...state,
        loading,
        taskCreate,
        inProgress: false,
      }
    }
    case CREATE_PROJECT_TASK_REJECTED:
    case EDIT_PROJECT_TASK_REJECTED: {
      const apiError = idx(payload, (_) => _.response.data)
      loading = {
        ...state.loading,
        apiError,
        createTaskLoading: false,
      }
      return {
        ...state,
        loading,
        inProgress: false,
      }
    }
    case RESET_TO_INIT_STATE:
      return {
        ...initialState,
      }
    case DOWNLOAD_TASK_REPORT_PENDING:
      return {
        ...state,
        downloadingTaskReport: payload.taskId,
      }
    case DOWNLOAD_TASK_REPORT_FULFILLED:
      return {
        ...state,
        downloadingTaskReport: '',
      }
    case DOWNLOAD_TASK_REPORT_REJECTED:
      return {
        ...state,
        downloadingTaskReport: '',
      }
    default:
      return state
  }
}
