import axios from 'axios'

import {
  GROUPS_API_CALL_INNITIATED,
  GROUPS_API_CALL_SUCCESS,
  GROUPS_API_CALL_FAIL,
  GROUPS_FILTER,
  GROUPS_SORT,
  GROUP_CREATE,
  GROUP_CREATE_FAIL,
  GROUP_CREATE_SUCCESS,
  GROUP_EDIT,
  GROUP_EDIT_FAIL,
  GROUP_EDIT_SUCCESS,
  OPEN_MODAL,
  CLOSE_MODAL,
  GROUP_PEOPLE_LIST,
  GROUP_PEOPLE_LIST_SUCCESS,
  GROUP_PEOPLE_LIST_FAIL,
  EXPAND_GROUP,
  EXPAND_GROUP_SUCCESS,
  EXPAND_GROUP_FAIL,
  COLLAPSE_GROUP,
  UPDATE_GROUPS,
} from './actionType'
import apiConfig from '../../config/apiConfig'
import { showNotification } from '../notification/actionCreator'
import {
  TEXT_SUCCESS_GROUP_CREATED,
  TEXT_SUCCESS_GROUP_UPDATED,
  TEXT_ERROR_DEFAULT,
} from '../../constants/notifications'

const KEY = apiConfig.key

export function groupsFilter(data) {
  return {
    type: GROUPS_FILTER,
    data,
  }
}

export function groupsSort(data) {
  return {
    type: GROUPS_SORT,
    data,
  }
}

export function openModal(modalName) {
  return {
    type: OPEN_MODAL,
    modalName,
  }
}

export function closeModal(modalName) {
  return {
    type: CLOSE_MODAL,
    modalName,
  }
}

export function apiCallInnitiated(data) {
  return {
    type: GROUPS_API_CALL_INNITIATED,
    data,
  }
}

export function createGroupInitiated() {
  return {
    type: GROUP_CREATE,
  }
}

export function createGroupSuccess(data) {
  return {
    type: GROUP_CREATE_SUCCESS,
    data,
  }
}

export function createGroupFail(data) {
  return {
    type: GROUP_CREATE_FAIL,
    data,
  }
}

export function editGroupInitiated() {
  return {
    type: GROUP_EDIT,
  }
}

export function editGroupSuccess(data) {
  return {
    type: GROUP_EDIT_SUCCESS,
    data,
  }
}

export function editGroupFail(data) {
  return {
    type: GROUP_EDIT_FAIL,
    data,
  }
}

export function GroupPeopleListInitiated() {
  return {
    type: GROUP_PEOPLE_LIST,
  }
}

export function GroupPeopleListSuccess(data) {
  return {
    type: GROUP_PEOPLE_LIST_SUCCESS,
    data,
  }
}

export function GroupPeopleListFail(data) {
  return {
    type: GROUP_PEOPLE_LIST_FAIL,
    data,
  }
}

export function collapseGroup(groupId) {
  return {
    type: COLLAPSE_GROUP,
    groupId,
  }
}

export function expandGroupInnitiated(groupId) {
  return {
    type: EXPAND_GROUP,
    groupId,
  }
}

export function expandGroupSuccess(data, groupId) {
  return {
    type: EXPAND_GROUP_SUCCESS,
    data,
    groupId,
  }
}

export function expandGroupFail(data, groupId) {
  return {
    type: EXPAND_GROUP_FAIL,
    data,
    groupId,
  }
}

export function apiCallSuccess(data) {
  return {
    type: GROUPS_API_CALL_SUCCESS,
    data,
  }
}

export function apiCallFail(error) {
  return {
    type: GROUPS_API_CALL_FAIL,
    error,
  }
}

export function fetchGroups() {
  return function (dispatch) {
    dispatch(
      apiCallInnitiated({
        type: GROUPS_API_CALL_INNITIATED,
        data: [],
      })
    )
    return axios
      .get(`${apiConfig.groups.groupsListUrl}?key=${KEY}`, {
        data: {},
      })
      .then((response) => {
        dispatch(apiCallSuccess(response.data))
      })
      .catch((error) => {
        dispatch(apiCallFail(error))
      })
  }
}

export const updateGroups =
  (groups = []) =>
  (dispatch) => {
    dispatch({
      type: UPDATE_GROUPS,
      groups,
    })
  }

export function fetchGroupPeople(groupId) {
  return function (dispatch) {
    dispatch(GroupPeopleListInitiated())
    return axios
      .get(`${apiConfig.groups.groupUserDetails + groupId}?key=${KEY}`, {
        data: {},
      })
      .then((response) => {
        dispatch(GroupPeopleListSuccess(response.data))
      })
      .catch((error) => {
        dispatch(GroupPeopleListFail(error))
      })
  }
}

export function createGroup(data) {
  return function (dispatch) {
    dispatch(createGroupInitiated())
    data.isActive = true
    return axios
      .post(`${apiConfig.groups.groupCreate}?key=${KEY}`, data)
      .then((response) => {
        dispatch(createGroupSuccess(response.data))
        dispatch(closeModal('groupsForm'))
        dispatch(showNotification(true, TEXT_SUCCESS_GROUP_CREATED, 'success'))
        dispatch(fetchGroups())
      })
      .catch((e) => {
        const message = e.response.data.message
          ? e.response.data.message
          : TEXT_ERROR_DEFAULT
        dispatch(createGroupFail(e))
        dispatch(showNotification(true, message, 'error'))
        dispatch(closeModal('groupsForm'))
      })
  }
}

export function editGroup(data) {
  return function (dispatch) {
    dispatch(editGroupInitiated())
    return axios
      .post(`${apiConfig.groups.groupEdit}?key=${KEY}`, data)
      .then((response) => {
        dispatch(editGroupSuccess(response.data))
        dispatch(closeModal('groupsForm'))
        dispatch(showNotification(true, TEXT_SUCCESS_GROUP_UPDATED, 'success'))
        dispatch(fetchGroupPeople(data.list_id))
      })
      .catch((e) => {
        dispatch(editGroupFail(e))
        dispatch(showNotification(true, TEXT_ERROR_DEFAULT, 'error'))
        dispatch(closeModal('groupsForm'))
      })
  }
}

export function expandGroups(groupId) {
  return function (dispatch) {
    dispatch(expandGroupInnitiated(groupId))
    return axios
      .get(`${apiConfig.groups.groupUserDetails + groupId}?key=${KEY}`, {
        data: {},
      })
      .then((response) => {
        dispatch(expandGroupSuccess(response.data, groupId))
      })
      .catch((error) => {
        dispatch(expandGroupFail(error, groupId))
      })
  }
}
