export const FACET_CHANNEL_KEY_NAME = 'Channel'
export const FACET_BRAND_KEY_NAME = 'Brand'
export const FACET_DEPARTMENT_KEY_NAME = 'Department'
export const FACET_CLASS_KEY_NAME = 'Class'
export const FACET_SUB_CLASS_KEY_NAME = 'Sub Class'
export const FACET_FILE_TYPE_KEY_NAME = 'File Type'
export const FACET_CAMPAIGN_YEAR_KEY_NAME = 'Campaign Year'
export const FACET_GROUP_KEY_NAME = 'Group'
export const FACET_SHOT_TYPE_KEY_NAME = 'Shot Type'
export const FACET_COLOR_KEY_NAME = 'Color'
export const FACET_CUSTOM_COLOR_KEY_NAME = 'Selected Color'
export const FACET_CUSTOM_HSV_COLOR_KEY_NAME = ''
export const QUERY_SEARCH_TERM_KEY_NAME = 'Search Term'
export const FACET_CAMPAIGN_DESCRIPTION_NAME = 'Campaign Description'
export const FACET_UPLOAD_DATE_NAME = 'Upload Date'
export const FACET_SUBJECT_NAME = 'Subject'
export const FACET_CONTENT_TYPE_KEY_NAME = 'Content Type'
export const FACET_LIBRARIAN_REVIEW_STATUS_KEY_NAME = 'Librarian Review Status'
export const FACET_ASSET_MISSING_METADATA_KEY_NAME =
  'Asset Classification Metadata'
export const FACET_CLASSIFICATION_ID_KEY_NAME = 'Asset Classification ID'
export const FACET_CLASSIFICATION_KEY_NAME = 'Asset Classification'
export const FACET_CLASSIFICATION_MISSING_KEY_NAME = 'Classification'
export const FACET_KEY_WORDS_KEY_NAME = 'Keywords'
export const FACET_SOURCE_KEY_NAME = 'Source'
export const FACET_MASTER_OR_DERIVATIVE_KEY_NAME = 'Asset Type'
export const FACET_PROJECT_ID_KEY_NAME = 'Project ID'
export const FACET_USAGE_DRM_ID_KEY_NAME = 'Usage ID'
export const FACET_WITH_PEOPLE_KEY_NAME = 'People'
export const FACET_WITH_ANIMALS_KEY_NAME = 'Animals'
export const FACET_WITH_FOOD_KEY_NAME = 'Food'

export const FACET_CHANNEL_KEY_VALUE = 'metadata.projectInfo.channel'
export const FACET_BRAND_KEY_VALUE = 'metadata.itemInfo.brand.nested'
export const FACET_DEPARTMENT_KEY_VALUE =
  'metadata.itemInfo.merchandiseClassification.departmentName.nested'
export const FACET_CLASS_KEY_VALUE =
  'metadata.itemInfo.merchandiseClassification.className.nested'
export const FACET_SUB_CLASS_KEY_VALUE =
  'metadata.itemInfo.merchandiseClassification.subclass.nested'
export const FACET_FILE_TYPE_KEY_VALUE = 'metadata.fileMetadata.fileType'
export const FACET_CAMPAIGN_YEAR_KEY_VALUE = 'metadata.projectInfo.campaignYear'
export const FACET_GROUP_KEY_VALUE =
  'metadata.itemInfo.merchandiseClassification.groupName.nested'
export const FACET_SHOT_TYPE_KEY_VALUE = 'metadata.assetDescriptions.shotTypes'
export const FACET_COLOR_KEY_VALUE =
  'metadata.imageData.colorBuckets.bucket.nested'
export const FACET_CUSTOM_COLOR_KEY_VALUE =
  'metadata.imageData.colors.hex.nested'
export const FACET_CUSTOM_HSV_COLOR_KEY_VALUE =
  'metadata.imageData.colors.hsv.nested'
export const FACET_CAMPAIGN_DESCRIPTION_VALUE =
  'metadata.projectInfo.campaignDescription'
export const FACET_UPLOAD_DATE_VALUE = 'uploadedDate'
export const FACET_SUBJECT_VALUE = 'metadata.assetDescriptions.subject'
export const FACET_CONTENT_TYPE_KEY_VALUE = 'metadata.fileMetadata.contentType'
export const FACET_LIBRARIAN_REVIEW_STATUS_KEY_VALUE = 'librarianReviewStatus'
export const FACET_ASSET_MISSING_METADATA_KEY_VALUE =
  'metadata.assetClassification.missingMetadata'
export const FACET_CLASSIFICATION_KEY_VALUE =
  'metadata.assetClassification.classificationId.exists'
export const FACET_CLASSIFICATION_MISSING_KEY_VALUE =
  'metadata.assetClassification.classificationId.not'
export const FACET_CLASSIFICATION_ID_KEY_VALUE =
  'metadata.assetClassification.classificationId'

export const FACET_KEY_WORDS_KEY_VALUE = 'metadata.assetDescriptions.keywords'
export const FACET_SOURCE_KEY_VALUE = 'source'
export const FACET_MASTER_OR_DERIVATIVE_KEY_VALUE = 'isDerivative'
export const FACET_PROJECT_ID_KEY_VALUE = 'metadata.projectInfo.projectId'
export const FACET_USAGE_DRM_ID_KEY_VALUE = 'metadata.usageInfo.drmId.nested'
export const FACET_WITH_PEOPLE_KEY_VALUE = 'metadata.imageData.people'
export const FACET_WITH_ANIMALS_KEY_VALUE = 'metadata.imageData.animals'
export const FACET_WITH_FOOD_KEY_VALUE = 'metadata.imageData.withFood'

export const SEARCH_DEFAULT_FACETS = {
  [FACET_CHANNEL_KEY_NAME]: FACET_CHANNEL_KEY_VALUE,
  [FACET_BRAND_KEY_NAME]: FACET_BRAND_KEY_VALUE,
  [FACET_DEPARTMENT_KEY_NAME]: FACET_DEPARTMENT_KEY_VALUE,
  [FACET_CLASS_KEY_NAME]: FACET_CLASS_KEY_VALUE,
  [FACET_SUB_CLASS_KEY_NAME]: FACET_SUB_CLASS_KEY_VALUE,
  [FACET_FILE_TYPE_KEY_NAME]: FACET_FILE_TYPE_KEY_VALUE,
  [FACET_CAMPAIGN_YEAR_KEY_NAME]: FACET_CAMPAIGN_YEAR_KEY_VALUE,
  [FACET_GROUP_KEY_NAME]: FACET_GROUP_KEY_VALUE,
  [FACET_SHOT_TYPE_KEY_NAME]: FACET_SHOT_TYPE_KEY_VALUE,
  [FACET_COLOR_KEY_NAME]: FACET_COLOR_KEY_VALUE,
  [FACET_CUSTOM_COLOR_KEY_NAME]: FACET_CUSTOM_COLOR_KEY_VALUE,
  [FACET_CUSTOM_HSV_COLOR_KEY_NAME]: FACET_CUSTOM_HSV_COLOR_KEY_VALUE,
  [FACET_CAMPAIGN_DESCRIPTION_NAME]: FACET_CAMPAIGN_DESCRIPTION_VALUE,
  [FACET_UPLOAD_DATE_NAME]: FACET_UPLOAD_DATE_VALUE,
  [FACET_SUBJECT_NAME]: FACET_SUBJECT_VALUE,
  [FACET_PROJECT_ID_KEY_NAME]: FACET_PROJECT_ID_KEY_VALUE,
  [FACET_USAGE_DRM_ID_KEY_NAME]: FACET_USAGE_DRM_ID_KEY_VALUE,
  [FACET_WITH_PEOPLE_KEY_NAME]: FACET_WITH_PEOPLE_KEY_VALUE,
  [FACET_WITH_ANIMALS_KEY_NAME]: FACET_WITH_ANIMALS_KEY_VALUE,
  [FACET_WITH_FOOD_KEY_NAME]: FACET_WITH_FOOD_KEY_VALUE,
  [FACET_ASSET_MISSING_METADATA_KEY_NAME]:
    FACET_ASSET_MISSING_METADATA_KEY_VALUE,
  [FACET_CLASSIFICATION_KEY_NAME]: FACET_CLASSIFICATION_KEY_VALUE,
  [FACET_CLASSIFICATION_ID_KEY_NAME]: FACET_CLASSIFICATION_ID_KEY_VALUE,
  [FACET_CLASSIFICATION_MISSING_KEY_NAME]:
    FACET_CLASSIFICATION_MISSING_KEY_VALUE,
  [FACET_LIBRARIAN_REVIEW_STATUS_KEY_NAME]:
    FACET_LIBRARIAN_REVIEW_STATUS_KEY_VALUE,
  [FACET_KEY_WORDS_KEY_NAME]: FACET_KEY_WORDS_KEY_VALUE,
  [FACET_CONTENT_TYPE_KEY_NAME]: FACET_CONTENT_TYPE_KEY_VALUE,
}
export const FACET_CHANNEL_SHORT_NAME = 'ch'
export const FACET_BRAND_SHORT_NAME = 'b'
export const FACET_DEPARTMENT_SHORT_NAME = 'd'
export const FACET_CLASS_SHORT_NAME = 'cl'
export const FACET_SUB_CLASS_SHORT_NAME = 'sc'
export const FACET_FILE_TYPE_SHORT_NAME = 'ft'
export const FACET_CAMPAIGN_YEAR_SHORT_NAME = 'cy'
export const FACET_GROUP_SHORT_NAME = 'g'
export const FACET_SHOT_TYPE_SHORT_NAME = 'st'
export const FACET_COLOR_SHORT_NAME = 'color'
export const FACET_CUSTOM_COLOR_SHORT_NAME = 'customcolor'
export const FACET_CUSTOM_HSV_COLOR_SHORT_NAME = ''
export const QUERY_SEARCH_TERM_SHORT_NAME = 'term'
export const FACET_CAMPAIGN_DESCRIPTION_SHORT_NAME = 'cd'
export const FACET_WITH_PEOPLE_SHORT_NAME = 'peop'
export const FACET_WITH_ANIMALS_SHORT_NAME = 'anim'
export const FACET_WITH_FOOD_SHORT_NAME = 'food'

export const QUERY_PARAMS_ENCODE_MAP = {
  [FACET_CHANNEL_KEY_NAME]: FACET_CHANNEL_SHORT_NAME,
  [FACET_BRAND_KEY_NAME]: FACET_BRAND_SHORT_NAME,
  [FACET_DEPARTMENT_KEY_NAME]: FACET_DEPARTMENT_SHORT_NAME,
  [FACET_CLASS_KEY_NAME]: FACET_CLASS_SHORT_NAME,
  [FACET_SUB_CLASS_KEY_NAME]: FACET_SUB_CLASS_SHORT_NAME,
  [FACET_FILE_TYPE_KEY_NAME]: FACET_FILE_TYPE_SHORT_NAME,
  [FACET_CAMPAIGN_YEAR_KEY_NAME]: FACET_CAMPAIGN_YEAR_SHORT_NAME,
  [FACET_GROUP_KEY_NAME]: FACET_GROUP_SHORT_NAME,
  [FACET_SHOT_TYPE_KEY_NAME]: FACET_SHOT_TYPE_SHORT_NAME,
  [FACET_COLOR_KEY_NAME]: FACET_COLOR_SHORT_NAME,
  [FACET_CUSTOM_COLOR_KEY_NAME]: FACET_CUSTOM_COLOR_SHORT_NAME,
  [FACET_CUSTOM_HSV_COLOR_KEY_NAME]: FACET_CUSTOM_HSV_COLOR_SHORT_NAME,
  [QUERY_SEARCH_TERM_KEY_NAME]: QUERY_SEARCH_TERM_SHORT_NAME,
  [FACET_CAMPAIGN_DESCRIPTION_NAME]: FACET_CAMPAIGN_DESCRIPTION_SHORT_NAME,
  [FACET_WITH_PEOPLE_KEY_NAME]: FACET_WITH_PEOPLE_SHORT_NAME,
  [FACET_WITH_ANIMALS_KEY_NAME]: FACET_WITH_ANIMALS_SHORT_NAME,
  [FACET_WITH_FOOD_KEY_NAME]: FACET_WITH_FOOD_SHORT_NAME,
}

export const QUERY_PARAMS_DECODE_MAP = {
  [FACET_CHANNEL_SHORT_NAME]: FACET_CHANNEL_KEY_NAME,
  [FACET_BRAND_SHORT_NAME]: FACET_BRAND_KEY_NAME,
  [FACET_DEPARTMENT_SHORT_NAME]: FACET_DEPARTMENT_KEY_NAME,
  [FACET_CLASS_SHORT_NAME]: FACET_CLASS_KEY_NAME,
  [FACET_SUB_CLASS_SHORT_NAME]: FACET_SUB_CLASS_KEY_NAME,
  [FACET_FILE_TYPE_SHORT_NAME]: FACET_FILE_TYPE_KEY_NAME,
  [FACET_CAMPAIGN_YEAR_SHORT_NAME]: FACET_CAMPAIGN_YEAR_KEY_NAME,
  [FACET_GROUP_SHORT_NAME]: FACET_GROUP_KEY_NAME,
  [FACET_SHOT_TYPE_SHORT_NAME]: FACET_SHOT_TYPE_KEY_NAME,
  [FACET_COLOR_SHORT_NAME]: FACET_COLOR_KEY_NAME,
  [FACET_CUSTOM_COLOR_SHORT_NAME]: FACET_CUSTOM_COLOR_KEY_NAME,
  [FACET_CUSTOM_HSV_COLOR_SHORT_NAME]: FACET_CUSTOM_HSV_COLOR_KEY_NAME,
  [QUERY_SEARCH_TERM_SHORT_NAME]: QUERY_SEARCH_TERM_KEY_NAME,
  [FACET_CAMPAIGN_DESCRIPTION_SHORT_NAME]: FACET_CAMPAIGN_DESCRIPTION_NAME,
  [FACET_WITH_PEOPLE_SHORT_NAME]: FACET_WITH_PEOPLE_KEY_NAME,
  [FACET_WITH_ANIMALS_SHORT_NAME]: FACET_WITH_ANIMALS_KEY_NAME,
  [FACET_WITH_FOOD_SHORT_NAME]: FACET_WITH_FOOD_KEY_NAME,
}

export const SEARCH_PAGE_SIZES = [21, 42, 105, 210]
export const SEARCH_TEMPLATE_DEFAULT_VALUE = ''

export const SORT_BY_OPTIONS = {
  uploadDateASC: { uploadedDate: 'asc' },
  uploadDateDESC: { uploadedDate: 'desc' },
  fileNameASC: { 'metadata.fileMetadata.fileName': 'asc' },
  fileNameDESC: { 'metadata.fileMetadata.fileName': 'desc' },
  relevance: {},
}

export const FILTER_ASSET_ID = 'assetId'
export const FILTER_TCIN_KEY_VALUE = 'metadata.itemInfo.tcin.nested'
export const FILTER_DPCI_KEY_VALUE = 'metadata.itemInfo.dpci.nested'
export const FILTER_PROJECT_ID_KEY_VALUE = 'metadata.projectInfo.projectId'
export const FILTER_FILE_NAME_KEY_VALUE = 'metadata.fileMetadata.fileName'
export const FILTER_DEPARTMENT_ID_KEY_VALUE =
  'metadata.itemInfo.merchandiseClassification.departmentId.nested'
export const FILTER_PROPERTY_NUMBER_KEY_VALUE =
  'metadata.locationInfo.propertyName.nested'
export const FILTER_STORE_LOCATION_DESCRIPION_KEY_VALUE =
  'metadata.locationInfo.storeLocationDescription.nested'

export const FILTER_ASSET_ID_NAME = 'Asset Id'
export const FILTER_TCIN_KEY_NAME = 'TCIN'
export const FILTER_DPCI_KEY_NAME = 'DPCI'
export const FILTER_FILE_NAME_KEY_NAME = 'File Name'
export const FILTER_PROJECT_ID_KEY_NAME = 'Project Id'
export const FILTER_DEPARTMENT_ID_KEY_NAME = 'Department'
export const FILTER_PROPERTY_NUMBER_KEY_NAME = 'Property Number'
export const FILTER_STORE_LOCATION_DESCRIPION_KEY_NAME =
  'Store Location Description'

export const SEARCH_TERM_FILTER_MAP = {
  [FILTER_ASSET_ID]: FILTER_ASSET_ID_NAME,
  [FILTER_TCIN_KEY_VALUE]: FILTER_TCIN_KEY_NAME,
  [FILTER_DPCI_KEY_VALUE]: FILTER_DPCI_KEY_NAME,
  [FILTER_PROJECT_ID_KEY_VALUE]: FILTER_PROJECT_ID_KEY_NAME,
  [FILTER_FILE_NAME_KEY_VALUE]: FILTER_FILE_NAME_KEY_NAME,
  [FILTER_DEPARTMENT_ID_KEY_VALUE]: FILTER_DEPARTMENT_ID_KEY_NAME,
  [FILTER_PROPERTY_NUMBER_KEY_VALUE]: FILTER_PROPERTY_NUMBER_KEY_NAME,
  [FILTER_STORE_LOCATION_DESCRIPION_KEY_VALUE]:
    FILTER_STORE_LOCATION_DESCRIPION_KEY_NAME,
}

export const SEARCH_FILTER_FACET_MAP = {
  [FACET_CHANNEL_KEY_VALUE]: FACET_CHANNEL_KEY_NAME,
  [FACET_BRAND_KEY_VALUE]: FACET_BRAND_KEY_NAME,
  [FACET_DEPARTMENT_KEY_VALUE]: FACET_DEPARTMENT_KEY_NAME,
  [FACET_CLASS_KEY_VALUE]: FACET_CLASS_KEY_NAME,
  [FACET_SUB_CLASS_KEY_VALUE]: FACET_SUB_CLASS_KEY_NAME,
  [FACET_FILE_TYPE_KEY_VALUE]: FACET_FILE_TYPE_KEY_NAME,
  [FACET_CAMPAIGN_YEAR_KEY_VALUE]: FACET_CAMPAIGN_YEAR_KEY_NAME,
  [FACET_GROUP_KEY_VALUE]: FACET_GROUP_KEY_NAME,
  [FACET_SHOT_TYPE_KEY_VALUE]: FACET_SHOT_TYPE_KEY_NAME,
  [FACET_COLOR_KEY_VALUE]: FACET_COLOR_KEY_NAME,
  [FACET_CUSTOM_COLOR_KEY_VALUE]: FACET_CUSTOM_COLOR_KEY_NAME,
  [FACET_CUSTOM_HSV_COLOR_KEY_VALUE]: FACET_CUSTOM_HSV_COLOR_KEY_NAME,
  [FACET_UPLOAD_DATE_VALUE]: FACET_UPLOAD_DATE_NAME,
  [FACET_SUBJECT_VALUE]: FACET_SUBJECT_NAME,
  [FACET_CAMPAIGN_DESCRIPTION_VALUE]: FACET_CAMPAIGN_DESCRIPTION_NAME,
  [FACET_CONTENT_TYPE_KEY_VALUE]: FACET_CONTENT_TYPE_KEY_NAME,
  [FACET_LIBRARIAN_REVIEW_STATUS_KEY_VALUE]: [
    FACET_LIBRARIAN_REVIEW_STATUS_KEY_NAME,
  ],
  [FACET_ASSET_MISSING_METADATA_KEY_VALUE]:
    FACET_ASSET_MISSING_METADATA_KEY_NAME,
  [FACET_CLASSIFICATION_KEY_VALUE]: FACET_CLASSIFICATION_KEY_NAME,
  [FACET_CLASSIFICATION_ID_KEY_VALUE]: FACET_CLASSIFICATION_ID_KEY_NAME,
  [FACET_CLASSIFICATION_MISSING_KEY_VALUE]:
    FACET_CLASSIFICATION_MISSING_KEY_NAME,
  [FACET_KEY_WORDS_KEY_VALUE]: FACET_KEY_WORDS_KEY_NAME,
  [FACET_SOURCE_KEY_VALUE]: FACET_SOURCE_KEY_NAME,
  [FACET_MASTER_OR_DERIVATIVE_KEY_VALUE]: FACET_MASTER_OR_DERIVATIVE_KEY_NAME,
  [FACET_PROJECT_ID_KEY_VALUE]: FACET_PROJECT_ID_KEY_NAME,
  [FACET_USAGE_DRM_ID_KEY_VALUE]: FACET_USAGE_DRM_ID_KEY_NAME,
  [FACET_WITH_PEOPLE_KEY_VALUE]: FACET_WITH_PEOPLE_KEY_NAME,
  [FACET_WITH_ANIMALS_KEY_VALUE]: FACET_WITH_ANIMALS_KEY_NAME,
  [FACET_WITH_FOOD_KEY_VALUE]: FACET_WITH_FOOD_KEY_NAME,
}

export const TODAY = 'Today'
export const YESTERDAY = 'Yesterday'
export const LAST_SEVEN_DAYS = 'Last 7 Days'
export const LAST_THIRTY_DAYS = 'Last 30 Days'
export const DATE_RANGE = 'DATE_RANGE'

export const UPLOAD_DATE_FILTER_MAP = {
  [DATE_RANGE]: DATE_RANGE,
}

export const IGNORE_SEARCH_FILTER_LIST = [
  'status',
  'creativeAssetStatus',
  'isLatestRevision',
  'source',
  'isConfidential',
  'metadata.imageData.colors.hsv.nested',
]

export const SORT_OPTIONS = {
  relevance: 'Sort by:  Relevance',
  uploadDateASC: 'Sort by: Upload Date | Old to New',
  uploadDateDESC: 'Sort by: Upload Date | New to Old',
  fileNameASC: 'Sort by: File Name | Ascending',
  fileNameDESC: 'Sort by: File Name | Descending',
}
export const DEFAULT_SORT_BY_OPTION = 'relevance'
export const SEARCH_BOX_PLACEHOLDER = "I'm looking for..."
export const DOWNLOAD_ORIGINAL_VERSION = 'DOWNLOAD ORIGINAL'
export const DOWNLOAD_JPEG_VERSION = 'DOWNLOAD PREVIEW'
export const OPEN_BOARD_WINDOW = 'ADD TO BOARD'
export const CLOSE_BOARD_WINDOW = 'CLOSE BOARDS WINDOW'
export const ADD_METADATA = 'ADD METADATA'
export const CLOSE_METADATA = 'CLOSE METADATA'
export const BOARDS_TITLE = 'Boards'
export const ADD_METADATA_TITLE = 'Add Metadata'
export const SET_AS_PROJECT_COVER_IMAGE = 'SET AS PROJECT COVER IMAGE'

export const campaign_assets = 'Campaign Assets'
export const packaging_keylines = 'Packaging Keylines'
export const food_photography = 'Food Photography'
export const logos_category = 'Logos'
export const store_and_HQ_photography = 'Store and HQ photography'
export const product_photography = 'Product Photography'
export const admin_basic_tagging = 'Basic Tagging'
export const admin_food_tagging = 'Food Tagging'
export const admin_logo_tagging = 'Logo Tagging'
export const admin_person_details_tagging = 'Person Details Tagging'
export const admin_ism_jpeg_tagging = '01 ISM Jpeg Tagging'
export const admin_entertainment_tagging = '02 Entertainment Tagging'
export const admin_store_tagging = 'Store Tagging'

export const RIGHT_SIDEBAR_WIDTH = {
  [BOARDS_TITLE]: '400',
  [ADD_METADATA_TITLE]: '650',
  default: '400',
}

export const DEFAULT_COLORS = {
  '#FF0000': 'Red',
  '#FF8000': 'Orange',
  '#FFFF00': 'Yellow',
  '#99FF33': 'Light Green',
  '#00CC00': 'Green',
  '#00994D': 'Green Cyan',
  '#00FFFF': 'Cyan',
  '#007FFF': 'Light Blue',
  '#0000FF': 'Blue',
  '#7F00FF': 'Violet',
  '#FF00FF': 'Magenta',
  '#FF0080': 'Pink',
  '#000000': 'Black',
  '#FFFFFF': 'White',
  '#808080': 'Grey',
}
export const DEFAULT_COLOR_NAMES = {
  '#FF0000': '#FF0000',
  '#FF8000': '#FF8000',
  '#FFFF00': '#FFFF00',
  '#99FF33': '#99FF33',
  '#00CC00': '#00CC00',
  '#00994D': '#00994D',
  '#00FFFF': '#00FFFF',
  '#007FFF': '#007FFF',
  '#0000FF': '#0000FF',
  '#7F00FF': '#7F00FF',
  '#FF00FF': '#FF00FF',
  '#FF0080': '#FF0080',
  '#000000': '#000000',
  '#FFFFFF': '#FFFFFF',
  '#808080': '#808080',
}
