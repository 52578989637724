import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@mui/styles'

import LayoutSidebar from '../Custom/LayoutSidebar'
import BoardsDialog from '../Search/BoardsDialog'
import BulkEditBar from '../../containers/Metadata/BulkEditBar'
import { selectSaveAlertActiontype } from '../../store/search/selector'

const styles = {
  dialogTitle: {
    flex: 1,
    fontSize: '20px',
    fontWeight: '300',
  },
  bulkEditSidebarStyles: {
    padding: '10px',
    textAlign: 'left',
  },
}

export const Sidebar = ({
  classes = {},
  toggleRightSidenav = () => {},
  rightSideNavComponent = '',
  isToggleRightSidenav,
  setSaveAlertAction = () => {},
  saveAlertActiontype = '',
  history = {},
  router = {},
}) => (
  <LayoutSidebar
    headerTitleText={rightSideNavComponent}
    isOpen={isToggleRightSidenav}
    toggleRightSidenav={toggleRightSidenav}
    rightSideNavComponent={rightSideNavComponent}
    setSaveAlertAction={setSaveAlertAction}
  >
    {(() => {
      switch (rightSideNavComponent) {
        case 'Boards':
          return <BoardsDialog history={history} router={router} />
        case 'Add Metadata':
          return (
            <div className={classes.bulkEditSidebarStyles}>
              <BulkEditBar
                saveAlertActiontype={saveAlertActiontype}
                setSaveAlertAction={setSaveAlertAction}
                toggleRightSidenav={toggleRightSidenav}
              />
            </div>
          )
        default:
          return null
      }
    })()}
  </LayoutSidebar>
)

Sidebar.propTypes = {
  classes: PropTypes.object,
  rightSideNavComponent: PropTypes.string,
  isToggleRightSidenav: PropTypes.bool,
  toggleRightSidenav: PropTypes.func,
  setSaveAlertAction: PropTypes.func,
  saveAlertActiontype: PropTypes.string,
}

const mapStateToProps = (state) => ({
  saveAlertActiontype: selectSaveAlertActiontype()(state),
})

export default connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(Sidebar)
)
