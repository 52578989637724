import axios from 'axios'
import apiConfig from '../config/apiConfig'

import {
  constructChunkPayload,
  constructEndPayload,
  constructEnterpriseEndPayload,
  constructStartPayload,
  constructSimpleFileUploadPayload,
} from '../mappers/uploadMapper'
import { formatPaginationQueryParams } from '../helpers/UrlHelper'

const {
  uploadKey = '',
  key = '',
  enterpriseUploads = {},
  assethub = {},
} = apiConfig
const { euChunkUploadApi = '', euSimpleUploadApi = '' } = enterpriseUploads
const { uploadsUrl = '', uploadStatusUrl = '' } = assethub

export const simpleUploadApi = (file = {}, auth = {}) => {
  const simpleUploadUrl = constructUploadUrl('simple_upload')
  const simpleFileData = constructUploadRequestPayload(
    file,
    'simple_upload',
    true,
    auth
  )
  return axios.post(simpleUploadUrl, simpleFileData)
}

export const uploadStartApi = (file = {}, isEnterpriseApi = false) => {
  const startUrl = constructUploadUrl('start', isEnterpriseApi)
  const requestPayload = constructUploadRequestPayload(
    file,
    'start',
    isEnterpriseApi
  )
  return axios.post(startUrl, requestPayload)
}

export const uploadChunkApi = (file = {}, isEnterpriseApi = false) => {
  const chunkUrl = constructUploadUrl('chunk', isEnterpriseApi)
  const requestPayload = constructUploadRequestPayload(
    file,
    'chunk',
    isEnterpriseApi
  )
  return axios.post(chunkUrl, requestPayload)
}

export const uploadEndApi = (file = {}, isEnterpriseApi = false, auth = {}) => {
  const endUrl = constructUploadUrl('end', isEnterpriseApi)
  const requestPayload = constructUploadRequestPayload(
    file,
    'end',
    isEnterpriseApi,
    auth
  )
  return axios.post(endUrl, requestPayload)
}

export const uploadJobStatusApi = (
  jobStatusPayload,
  pagination,
  projectAssetCount
) => {
  const uploadJobStatusUrl = `${uploadStatusUrl}job_status?key=${key}&${formatPaginationQueryParams(
    {
      ...pagination,
      size: projectAssetCount + 1,
    }
  )}`
  return axios.post(uploadJobStatusUrl, jobStatusPayload)
}

export const constructUploadRequestPayload = (
  file = {},
  endpoint = '',
  isEnterpriseApi = false,
  auth = {}
) => {
  let data = {}
  switch (endpoint) {
    case 'simple_upload':
      return constructSimpleFileUploadPayload(file, auth)
    case 'start':
      return constructStartPayload(file, isEnterpriseApi)
    case 'chunk':
      return constructChunkPayload(file, isEnterpriseApi)
    case 'end':
      return isEnterpriseApi
        ? constructEnterpriseEndPayload(file, auth)
        : constructEndPayload(file, auth)
    default:
      return data
  }
}

export const constructUploadUrl = (endpoint = '', isEnterpriseApi = false) => {
  switch (endpoint) {
    case 'simple_upload':
      return `${euSimpleUploadApi}?key=${uploadKey}`
    case 'start':
      return isEnterpriseApi
        ? `${euChunkUploadApi}/start?key=${uploadKey}`
        : `${uploadsUrl}chunk_uploads/start?key=${uploadKey}`
    case 'chunk':
      return isEnterpriseApi
        ? `${euChunkUploadApi}?key=${uploadKey}`
        : `${uploadsUrl}chunk_uploads?key=${uploadKey}`
    case 'end':
      return isEnterpriseApi
        ? `${euChunkUploadApi}/end?key=${uploadKey}`
        : `${uploadsUrl}chunk_uploads/end?key=${uploadKey}`
    default:
      return ''
  }
}
