import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withStyles } from '@mui/styles'
import { Grid, Button } from '@mui/material'
import { selectUserId } from '../../../../store/auth/selector'

import {
  resetFilters,
  setFilterValue,
  setPartialFilterValue,
  fetchAssets,
  onStartOver,
} from '../../../../store/publish/actions'
import { FACET_PATHS } from '../../../../constants/publishConstants'
import {
  getFilters,
  getIsFetchingAssets,
  getFacets,
  getPartialFilters,
} from '../../../../store/publish/selectors'

import DateFilter from './DateFilter'
import MultiFilter from './MultiFilter'

const styles = {
  root: {
    background: '#f5f5f5',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  searchButton: {
    backgroundColor: '#2962ff',
    color: 'white',
    fontSize: 13,
  },
  divider: {
    border: '0.5px solid black',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  datesWrapper: {
    marginTop: '16px',
    marginBottom: '10px',
  },
  datePickers: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  dateHeader: {
    color: 'black',
    marginBottom: 4,
  },
  clearButton: {
    marginTop: 15,
    textAlign: 'end',
  },
}

const FiltersContainer = ({
  classes = {},
  resetFilters = () => {},
  fetchAssets = () => {},
  filters = {},
  setFilterValue = () => {},
  facets = {},
  isFetchingAssets = false,
  lanId = '',
}) => {
  const [resetToggle, setResetToggle] = useState(false)
  const {
    EMAIL = {},
    FILE_EXTENSION = {},
    USER_ID = {},
    CONTENT_TYPE = {},
    ASSET_TYPE = {},
  } = FACET_PATHS
  const { path: emailPath = '', label: emailLabel = '' } = EMAIL
  const { path: filePath = '', label: fileLabel = '' } = FILE_EXTENSION
  const { path: userPath = '', label: userLabel = '' } = USER_ID
  const { path: contentPath = '', label: contentLabel = '' } = CONTENT_TYPE
  const { path: assetPath = '', label: assetLabel = '' } = ASSET_TYPE

  const onClearAppliedFilters = () => {
    setResetToggle(!resetToggle)
    resetFilters()
    fetchAssets()
  }

  return (
    <Grid className={classes.root}>
      <Grid item xs={12} className={classes.clearButton}>
        <Button
          color="primary"
          data-cy="clearFiltersButton"
          onClick={onClearAppliedFilters}
        >
          CLEAR ALL
        </Button>
      </Grid>

      <DateFilter
        filters={filters}
        label={'Uploaded Date'}
        setFilterValue={setFilterValue}
        fetchAssets={fetchAssets}
        lanId={lanId}
      />

      <MultiFilter
        path={emailPath}
        label={emailLabel}
        facets={facets[emailPath]}
        selectedValues={filters[emailPath]}
        isFetchingAssets={isFetchingAssets}
        setFilterValue={setFilterValue}
        resetToggle={resetToggle}
        fetchAssets={fetchAssets}
        lanId={lanId}
      />
      <MultiFilter
        path={filePath}
        label={fileLabel}
        facets={facets[filePath]}
        selectedValues={filters[filePath]}
        isFetchingAssets={isFetchingAssets}
        setFilterValue={setFilterValue}
        resetToggle={resetToggle}
        fetchAssets={fetchAssets}
        lanId={lanId}
      />
      <MultiFilter
        path={userPath}
        label={userLabel}
        facets={facets[userPath]}
        selectedValues={filters[userPath]}
        isFetchingAssets={isFetchingAssets}
        setFilterValue={setFilterValue}
        resetToggle={resetToggle}
        fetchAssets={fetchAssets}
        lanId={lanId}
      />
      <MultiFilter
        path={contentPath}
        label={contentLabel}
        facets={facets[contentPath]}
        selectedValues={filters[contentPath]}
        isFetchingAssets={isFetchingAssets}
        setFilterValue={setFilterValue}
        resetToggle={resetToggle}
        fetchAssets={fetchAssets}
        lanId={lanId}
      />
      <MultiFilter
        path={assetPath}
        label={assetLabel}
        facets={facets[assetPath]}
        selectedValues={filters[assetPath]}
        isFetchingAssets={isFetchingAssets}
        setFilterValue={setFilterValue}
        resetToggle={resetToggle}
        fetchAssets={fetchAssets}
        lanId={lanId}
      />
    </Grid>
  )
}

FiltersContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  resetFilters: PropTypes.func.isRequired,
  fetchAssets: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  setFilterValue: PropTypes.func.isRequired,
  facets: PropTypes.object.isRequired,
  isFetchingAssets: PropTypes.bool.isRequired,
  lanId: PropTypes.string.isRequired,
}

const mapStateToProps = (state) => ({
  filters: getFilters(state),
  partialFilters: getPartialFilters(state),
  facets: getFacets(state),
  isFetchingAssets: getIsFetchingAssets(state),
  lanId: selectUserId()(state),
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      resetFilters,
      fetchAssets,
      setFilterValue,
      setPartialFilterValue,
      onStartOver,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(FiltersContainer))
