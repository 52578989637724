// TASK STATUS CODES
export const TASK_STATUS_NEW = '1001'
export const TASK_STATUS_IN_PROGRESS = '1002'
export const TASK_STATUS_COMPLETE = '1003'

export default {
  TASK_STATUS_IN_PROGRESS,
  TASK_STATUS_COMPLETE,
  TASK_STATUS_NEW,
}
