import React, { useState } from 'react'
import { useSelector } from 'react-redux'
// Libraries
import { get } from 'lodash'
import Moment from 'moment-timezone'
import {
  Card,
  CardHeader,
  CardContent,
  Button,
  Typography,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  LinearProgress,
} from '@mui/material'
// Icons
import {
  MoreVert,
  DateRange,
  OpenInBrowser,
  OpenInNew,
  CropSquare,
  Done,
  Close,
} from '@mui/icons-material'
import TasksListIcon from '@mui/icons-material/List'
import Tooltip from '@mui/material/Tooltip'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import CircularProgress from '@mui/material/CircularProgress'
import EditIcon from '@mui/icons-material/Edit'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
// Core files
import withRouter from '../../../../containers/Router/WithRouter'
import Anchor from '../../../Custom/A'
import CustomCard from '../../../Common/Card/CustomCard'
// constants
import { UPLOAD_TASK_TYPE_ID } from '../../../../constants/projectTask'
// images
import dividerImage from '../../../../images/divider.png'

const TasksListWidget = ({
  classes = {},
  isAuthorizedUser = false,
  router = {},
  projectId = '',
}) => {
  const {
    projectActiveTasks,
    projectInActiveTasks,
    projectData = {},
    isAdmin = false,
    loading = {},
  } = useSelector((state) => state.projects)
  const { projectDetails: loadingProjectDetails = {} } = loading

  const [createTaskEL, setCreateTaskEl] = useState(null)

  const openCreateTask = () => {
    setCreateTaskEl(null)
    const { params = {}, navigate = () => {} } = router
    const projectIdentifier = params.projectId ? params.projectId : projectId
    const projObj = {
      dueDate: get(projectData, 'due_date', ''),
      name: get(projectData, 'project_name', ''),
      projectId: get(projectData, 'project_id', ''),
      projectUuid: get(projectData, 'project_uuid', ''),
      reviewJobId: get(projectData, 'review_job_id', ''),
      runDate: get(projectData, 'run_date', ''),
      type: get(projectData, 'project_type', ''),
      uoiId: get(projectData, 'uoi_id', ''),
    }
    navigate(`/project/${projectIdentifier}/tasks/list`, {
      state: { project: projObj, eventTrigger: 'dashboard' },
    })
  }
  const renderCardHeaderActions = () =>
    isAdmin &&
    isAuthorizedUser && (
      <div>
        <IconButton
          aria-label="More"
          aria-owns={open ? 'long-menu' : null}
          aria-haspopup="true"
          data-cy="taskMenuButton"
          onClick={(event) => {
            setCreateTaskEl(event.currentTarget)
          }}
          size="large"
        >
          <MoreVert />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={createTaskEL}
          open={Boolean(createTaskEL)}
          onClose={() => setCreateTaskEl(null)}
        >
          <MenuItem
            onClick={() => {
              openCreateTask()
            }}
          >
            <EditIcon className={classes.editIcon} data-cy="createTaskButton" />{' '}
            CREATE A TASK
          </MenuItem>
        </Menu>
      </div>
    )
  const renderCardContent = () =>
    loadingProjectDetails ? (
      <div className={classes.loader}>
        <CircularProgress />
      </div>
    ) : (
      <>
        {(projectActiveTasks && projectActiveTasks.length > 0) ||
        (projectInActiveTasks && projectInActiveTasks.length > 0) ? (
          <div
            className={classes.cardContent}
            style={{
              height: '56.5vh',
              overflow: 'auto',
              minHeight: '56.5vh',
            }}
          >
            {projectActiveTasks && projectActiveTasks.length > 0 && (
              <Typography
                color="textSecondary"
                variant="body1"
                style={{ margin: '10px 0px' }}
              >
                Active Tasks
              </Typography>
            )}
            <div className={classes.demo}>
              <List>
                {projectActiveTasks &&
                  projectActiveTasks.length > 0 &&
                  projectActiveTasks.map((tasks) => (
                    <ListItem
                      data-cy="activeTask"
                      key={tasks.task_id}
                      style={{
                        backgroundColor: `${
                          isAdmin &&
                          tasks.is_active &&
                          tasks.status === 'Completed'
                            ? '#ffeeec'
                            : '#eef7ee'
                        }`,
                        fontSize: '12px',
                        border: '1px solid #EFEFEF',
                        padding: '2px 5px',
                        borderBottom: 'none',
                        maxHeight: '100%',
                        minHeight: '40px',
                      }}
                    >
                      <ListItemText
                        className={classes.LIText}
                        secondary={`${tasks.job_name}`}
                        disableTypography
                      />
                      <ListItemSecondaryAction
                        className={classes.LISecondaryAction}
                      >
                        {!isAdmin &&
                          tasks.is_active &&
                          tasks.status !== 'Completed' && (
                            <Tooltip title={`Not Started`}>
                              <IconButton
                                edge="end"
                                className={classes.TaskIconButton}
                                style={{ msGridColumn: '1' }}
                                aria-label={`Not Started`}
                                size="large"
                              >
                                <CropSquare />
                              </IconButton>
                            </Tooltip>
                          )}
                        {!isAdmin &&
                          !tasks.is_active &&
                          tasks.status !== 'Completed' && (
                            <Tooltip title={`Close`}>
                              <IconButton
                                edge="end"
                                className={classes.TaskIconButton}
                                style={{ msGridColumn: '2' }}
                                aria-label={`Close`}
                                size="large"
                              >
                                <Close />
                              </IconButton>
                            </Tooltip>
                          )}
                        {!isAdmin && tasks.status === 'Completed' && (
                          <Tooltip
                            title={`Completed ${Moment(tasks.due_date).format(
                              'MM/DD/YYYY h:mm:ss a'
                            )}`}
                          >
                            <IconButton
                              edge="end"
                              className={classes.TaskIconButton}
                              style={{ msGridColumn: '3' }}
                              aria-label={`Completed ${Moment(
                                tasks.due_date
                              ).format('MM/DD/YYYY h:mm:ss a')}`}
                              size="large"
                            >
                              <Done />
                            </IconButton>
                          </Tooltip>
                        )}
                        {tasks.task_type !== UPLOAD_TASK_TYPE_ID && tasks.job_completed_percent >= 0 && (
                          <Tooltip
                            title={`${tasks.completed_tasks} / ${tasks.total_tasks} Complete`}
                          >
                            <LinearProgress
                              variant="determinate"
                              value={
                                tasks.job_completed_percent
                                  ? tasks.job_completed_percent
                                  : Number(0)
                              }
                              style={{ height: 14, msGridColumn: '4' }}
                            />
                          </Tooltip>
                        )}
                        {tasks.task_type !== UPLOAD_TASK_TYPE_ID && tasks.job_completed_percent >= 0
                          ? `${tasks.job_completed_percent + '%'}`
                          : ''}
                        <Tooltip
                          title={`ACTIVE Due ${Moment(tasks.due_date).format(
                            'MM/DD/YYYY h:mm:ss a'
                          )}`}
                        >
                          <IconButton
                            edge="end"
                            className={classes.TaskIconButton}
                            style={{ top: '-4px', msGridColumn: '5' }}
                            aria-label={`ACTIVE Due ${Moment(
                              tasks.due_date
                            ).format('MM/DD/YYYY h:mm:ss a')}`}
                            size="large"
                          >
                            <DateRange />
                          </IconButton>
                        </Tooltip>
                        {tasks.task_type !== UPLOAD_TASK_TYPE_ID && (
                          <Tooltip title={`Open Review`}>
                            <IconButton
                              edge="end"
                              className={classes.TaskIconButton}
                              style={{ msGridColumn: '6' }}
                              aria-label={`Open Review`}
                              component={Anchor}
                              href={tasks.review_url}
                              target="_blank"
                              size="large"
                            >
                              <OpenInBrowser />
                            </IconButton>
                          </Tooltip>
                        )}
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
              </List>
            </div>
            {projectInActiveTasks && projectInActiveTasks.length > 0 && (
              <Typography
                color="textSecondary"
                variant="body1"
                style={{ margin: '10px 0px' }}
              >
                Inactive Tasks
              </Typography>
            )}
            <div className={classes.demo}>
              <List>
                {projectInActiveTasks &&
                  projectInActiveTasks.length > 0 &&
                  projectInActiveTasks.map((tasks) => (
                    <ListItem
                      data-cy="inactiveTask"
                      key={tasks.task_id}
                      style={{
                        backgroundColor:
                          'rgba(239, 239, 239, 0.247058823529412)',
                        fontSize: '12px',
                        border: '1px solid #EFEFEF',
                        padding: '2px 5px',
                        borderBottom: 'none',
                        maxHeight: '100%',
                        minHeight: '40px',
                      }}
                    >
                      <ListItemText
                        className={classes.LIText}
                        secondary={tasks.job_name}
                        disableTypography
                      />
                      <ListItemSecondaryAction
                        className={classes.LISecondaryAction}
                      >
                        {!isAdmin &&
                          tasks.is_active &&
                          tasks.status !== 'Completed' && (
                            <Tooltip title={`Not Started`}>
                              <IconButton
                                edge="end"
                                className={classes.TaskIconButton}
                                style={{ msGridColumn: '1' }}
                                aria-label={`Not Started`}
                                size="large"
                              >
                                <CropSquare />
                              </IconButton>
                            </Tooltip>
                          )}
                        {!isAdmin &&
                          !tasks.is_active &&
                          tasks.status !== 'Completed' && (
                            <Tooltip title={`Close`}>
                              <IconButton
                                edge="end"
                                className={classes.TaskIconButton}
                                style={{ msGridColumn: '2' }}
                                aria-label={`Close`}
                                size="large"
                              >
                                <Close />
                              </IconButton>
                            </Tooltip>
                          )}
                        {!isAdmin && tasks.status === 'Completed' && (
                          <Tooltip
                            title={`Completed ${Moment(tasks.due_date).format(
                              'MM/DD/YYYY h:mm:ss a'
                            )}`}
                          >
                            <IconButton
                              edge="end"
                              className={classes.TaskIconButton}
                              style={{ msGridColumn: '3' }}
                              aria-label={`Completed ${Moment(
                                tasks.due_date
                              ).format('MM/DD/YYYY h:mm:ss a')}`}
                              size="large"
                            >
                              <Done />
                            </IconButton>
                          </Tooltip>
                        )}
                        {tasks.task_type !== UPLOAD_TASK_TYPE_ID && tasks.job_completed_percent >= 0 && (
                          <Tooltip
                            title={`${tasks.completed_tasks} / ${tasks.total_tasks} Complete`}
                          >
                            <LinearProgress
                              variant="determinate"
                              value={
                                tasks.job_completed_percent
                                  ? tasks.job_completed_percent
                                  : 1
                              }
                              style={{ height: 14, msGridColumn: '4' }}
                            />
                          </Tooltip>
                        )}
                        {tasks.task_type !== UPLOAD_TASK_TYPE_ID && tasks.job_completed_percent >= 0
                          ? `${tasks.job_completed_percent + '%'}`
                          : ''}
                        <Tooltip
                          title={`INACTIVE Due ${Moment(tasks.due_date).format(
                            'MM/DD/YYYY h:mm:ss a'
                          )}`}
                        >
                          <IconButton
                            edge="end"
                            className={classes.TaskIconButton}
                            style={{ top: '-4px', msGridColumn: '5' }}
                            aria-label={`INACTIVE Due ${Moment(
                              tasks.due_date
                            ).format('MM/DD/YYYY h:mm:ss a')}`}
                            size="large"
                          >
                            <DateRange />
                          </IconButton>
                        </Tooltip>
                        {tasks.task_type !== UPLOAD_TASK_TYPE_ID && (
                          <Tooltip title={`Open Review`}>
                            <IconButton
                              edge="end"
                              className={classes.TaskIconButton}
                              style={{ msGridColumn: '6' }}
                              aria-label={`Open Review`}
                              component={Anchor}
                              href={tasks.review_url}
                              target="_blank"
                              size="large"
                            >
                              <OpenInBrowser />
                            </IconButton>
                          </Tooltip>
                        )}
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
              </List>
            </div>
          </div>
        ) : (
          <div
            style={{
              minHeight: '100px',
              textAlign: 'center',
              WebkitJustifyContent: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography color="textSecondary" variant="caption">
              No tasks created on this Project
            </Typography>
            <img
              className={classes.assetDivider}
              src={dividerImage}
              alt="no tasks"
            />
            {isAuthorizedUser && (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => openCreateTask()}
                className={classes.button}
                style={{ textTransform: 'none' }}
              >
                <OpenInNew className={classes.rightIcon} />
                <Typography variant="button" className={classes.whiteColor}>
                  {' '}
                  Create A Task
                </Typography>
              </Button>
            )}
          </div>
        )}
      </>
    )
  const cardConfig = {
    cardHeader: {
      headerAvatar: (
        <Avatar aria-label="Recipe">
          <TasksListIcon />
        </Avatar>
      ),
      headerActionContent: renderCardHeaderActions(),
      title: 'Tasks',
      subheader:
        (projectActiveTasks || []).length +
        ' Active | ' +
        (projectInActiveTasks || []).length +
        ' Inactive',
      identifier: '',
    },
    displayFooter: false,
  }
  return (
    <>
      <CustomCard
        data-cy="tasksCard"
        cardConfig={cardConfig}
        cardContent={renderCardContent()}
      />
    </>
  )
}

export default withRouter(TasksListWidget)
