import axios from 'axios'

import {
  CREATE_RIGHTS_HOLDER_TYPE,
  GET_USAGE_RIGHTS_DATALIST,
  GET_USAGE_RIGHTS_SUMMARY,
  CREATE_NEW_RECORD_USAGE_RIGHTS,
  GET_USAGE_RIGHTS_DRMID_DATA,
  CREATE_RIGHTS_HOLDER_NAME,
  CREATE_NEW_BUYER,
} from './actionTypes'

import apiConfig from '../../config/apiConfig'

const getUsageRightsDataList =
  (
    usageRightsParams = {},
    callBackSuccess = () => {},
    callBackError = () => {}
  ) =>
  (dispatch) => {
    const {
      searchText = '',
      page = Number(0),
      rowsPerPage = Number(50),
    } = usageRightsParams
    const url = `${apiConfig.usageRights.getUsageRightsData}?page=${page}&per_page=${rowsPerPage}&q=${searchText}`
    const promise = axios.get(url)
    return dispatch({
      type: GET_USAGE_RIGHTS_DATALIST,
      payload: promise,
    })
      .then((response) => {
        callBackSuccess(
          response.value.data && response.value.data !== null
            ? response.value.data
            : []
        )
      })
      .catch((error) => {
        callBackError(error)
      })
  }

const getUsageRightsSummary =
  (callBackSuccess = () => {}, callBackError = () => {}) =>
  (dispatch) => {
    const url = `${apiConfig.usageRights.getUsageRightsSummary}`
    const promise = axios.get(url)
    return dispatch({
      type: GET_USAGE_RIGHTS_SUMMARY,
      payload: promise,
    })
      .then((response) => {
        callBackSuccess(
          response.value.data && response.value.data !== null
            ? response.value.data
            : {}
        )
      })
      .catch((error) => {
        callBackError(error)
      })
  }

const addNewOptionUsageRightsType =
  (option, callBackSuccess = () => {}, callBackError = () => {}) =>
  (dispatch) => {
    const url = `${apiConfig.usageRights.createHolderTypeUrl}`
    const promise = axios.post(url, { holder_type: option })
    return dispatch({
      type: CREATE_RIGHTS_HOLDER_TYPE,
      payload: promise,
    })
      .then((response) => {
        callBackSuccess(
          response.value.data && response.value.data !== null
            ? response.value.data
            : ''
        )
      })
      .catch((error) => {
        callBackError(error)
      })
  }

const addNewBuyer =
  (option, callBackSuccess = () => {}, callBackError = () => {}) =>
  (dispatch) => {
    const url = `${apiConfig.usageRights.addNewBuyer}`
    const promise = axios.post(url, { buyer_email: option })
    return dispatch({
      type: CREATE_NEW_BUYER,
      payload: promise,
    })
      .then((response) => {
        callBackSuccess(
          response.value.data && response.value.data !== null
            ? response.value.data
            : []
        )
      })
      .catch((error) => {
        callBackError(error)
      })
  }

const addNewOptionUsageRightsName =
  (holderName = '', callBackSuccess = () => {}, callBackError = () => {}) =>
  (dispatch) => {
    const holderNameUrl = apiConfig.usageRights.createHolderNameUrl
    const promise = axios.post(holderNameUrl, { holder_name: holderName })
    return dispatch({
      type: CREATE_RIGHTS_HOLDER_NAME,
      payload: promise,
    })
      .then((response) => {
        callBackSuccess(
          response.value.data && response.value.data !== null
            ? response.value.data
            : []
        )
      })
      .catch((error) => {
        callBackError(error)
      })
  }

const createRightsHolderType =
  (holder_type = '') =>
  (dispatch) => {
    const usageRightsUrl = `${apiConfig.usageRights.createHolderTypeUrl}`
    const promise = axios.post(usageRightsUrl, { holder_type })
    return dispatch({
      type: CREATE_RIGHTS_HOLDER_TYPE,
      payload: promise,
    })
  }

const createNewRecordUsageRights =
  (payload, callBackSuccess = () => {}, callBackError = () => {}) =>
  (dispatch) => {
    const createUrl = `${apiConfig.usageRights.getUsageRightsData}`
    const promise = axios.post(createUrl, payload)
    return dispatch({
      type: CREATE_NEW_RECORD_USAGE_RIGHTS,
      payload: promise,
    })
      .then((response) => {
        callBackSuccess(
          response.value.data && response.value.data !== null
            ? response.value.data
            : {}
        )
      })
      .catch((error) => {
        callBackError(error)
      })
  }

const getUsageRightsOldData =
  (drmId, callBackSuccess = () => {}, callBackError = () => {}) =>
  (dispatch) => {
    const getDetailsUrl = `${apiConfig.usageRights.getUsageRightsData}/${drmId}`
    const promise = axios.get(getDetailsUrl)
    return dispatch({
      type: GET_USAGE_RIGHTS_DRMID_DATA,
      payload: promise,
    })
      .then((response) => {
        callBackSuccess(
          response.value.data && response.value.data !== null
            ? response.value.data
            : []
        )
      })
      .catch((error) => {
        callBackError(error)
      })
  }

export {
  getUsageRightsDataList,
  getUsageRightsSummary,
  addNewOptionUsageRightsType,
  createRightsHolderType,
  createNewRecordUsageRights,
  getUsageRightsOldData,
  addNewOptionUsageRightsName,
  addNewBuyer,
}
