import React, { useState } from 'react'
import { CirclePicker } from 'react-color'
import { useDispatch } from 'react-redux'
import {
  Button,
  Chip,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined'
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined'

import {
  createColorChipContent,
  getKeyByValue,
  hexToHSV,
  isHexValue,
} from '../../../utils/search'
import {
  DEFAULT_COLORS,
  FILTER_COLOR_BUCKET_KEY,
  FILTER_COLOR_HEX_KEY,
  FILTER_COLOR_HSV_KEY,
} from '../../../constants/search'
import { replaceFilters } from '../../../store/search/searchSlice'
import { updateUserPreference } from '../../../store/preference/userPreferenceSlice'
import CustomButtonGroup from '../../../components/CustomButtonGroup'

import { useSearchContext } from '../../Search/SearchContext'
import { BetaAnalytics } from '../../../../analytics/betaSearch'

const useStyles = makeStyles({
  colorFilterContainer: {
    padding: '15px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#f5f5f5',
  },
  colorPicker: {
    paddingTop: '10px',
    display: 'flex',
    flexWrap: 'wrap',
  },
  colorGridItem: {
    paddingTop: '10px',
  },
  actionGroup: {
    width: 'inherit',
    padding: '10px',
    display: 'flex',
    gap: '10px',
    justifyContent: 'flex-end',
  },
})

function ColorFilter({ closePopOver = () => {}, availableFilters = {} }) {
  const { userPreferences = {}, trackAnalyticsData = () => {} } =
    useSearchContext()
  const { colors = [] } = userPreferences
  const filteredBucketValue =
    availableFilters?.[FILTER_COLOR_BUCKET_KEY]?.[0] || ''
  const bucketHexValue = filteredBucketValue
    ? getKeyByValue(DEFAULT_COLORS, filteredBucketValue)
    : ''
  const filteredHexValue = availableFilters?.[FILTER_COLOR_HEX_KEY]?.[0] || ''
  const preSelectedValue = bucketHexValue ? bucketHexValue : filteredHexValue
  const dispatch = useDispatch()
  const classes = useStyles()
  const [searchColor, setSearchColor] = useState(bucketHexValue ?? '')
  const [selectedColor, setSelectedColor] = useState(preSelectedValue)
  const [colorInputValue, setColorInputValue] = useState(
    colors.includes(filteredHexValue) ? '' : filteredHexValue.slice(1),
  )

  const handleColorChange = (colorObj = '') => {
    const { hex: hexValue = '' } = colorObj
    setSearchColor(hexValue)
    setSelectedColor(hexValue)
  }

  const handleDelete = (deletedColor = '') => {
    const renderingColors = colors
      .map((colors) => (colors.includes('#') ? colors : `#${colors}`))
      .filter((obj) => obj !== deletedColor)
    dispatch(
      updateUserPreference({ ...userPreferences, colors: renderingColors }),
    )
  }

  const handleChipClick = (colorValue) => {
    setSelectedColor(colorValue)
  }

  const createSavedColors = (color, index) => {
    const item = createColorChipContent(color)
    return (
      <Chip
        key={index}
        tabIndex={-1}
        label={item}
        color={selectedColor === color ? 'info' : 'default'}
        className={classes.chip}
        onDelete={() => {
          handleDelete(color)
        }}
        variant={selectedColor === color ? '' : 'outlined'}
        size="medium"
        clickable={true}
        onClick={() => {
          handleChipClick(color)
        }}
      />
    )
  }

  const onClickClear = () => {
    setSelectedColor('')
    setColorInputValue('')
  }

  const onClickApply = () => {
    let colorUpdatedFilters = { ...availableFilters }
    let analyticsFilterData = {}
    let appliedColor = selectedColor
    if (!appliedColor.includes('#')) {
      appliedColor = `#${appliedColor}`
    }
    if (selectedColor === searchColor) {
      const filterTextValue = DEFAULT_COLORS[selectedColor?.toUpperCase()]
      colorUpdatedFilters[FILTER_COLOR_BUCKET_KEY] = [filterTextValue]
      colorUpdatedFilters[FILTER_COLOR_HEX_KEY] &&
        delete colorUpdatedFilters[FILTER_COLOR_HEX_KEY]
      colorUpdatedFilters[FILTER_COLOR_HSV_KEY] &&
        delete colorUpdatedFilters[FILTER_COLOR_HSV_KEY]

      analyticsFilterData[FILTER_COLOR_BUCKET_KEY] = filterTextValue
    } else {
      colorUpdatedFilters[FILTER_COLOR_HEX_KEY] = [selectedColor]
      colorUpdatedFilters[FILTER_COLOR_HSV_KEY] = hexToHSV(selectedColor)
      colorUpdatedFilters[FILTER_COLOR_BUCKET_KEY] &&
        delete colorUpdatedFilters[FILTER_COLOR_BUCKET_KEY]

      analyticsFilterData[FILTER_COLOR_HEX_KEY] = selectedColor
      analyticsFilterData[FILTER_COLOR_HSV_KEY] =
        hexToHSV(selectedColor)?.join(',') || ''
    }
    dispatch(replaceFilters({ filters: colorUpdatedFilters }))
    closePopOver()
    trackAnalyticsData({
      eventType: BetaAnalytics.event.ADD_FILTER,
      eventName: BetaAnalytics.event.FILTER_COLOR,
      eventData: analyticsFilterData,
    })
  }

  const savePreferenceColor = () => {
    const updatedColors = [...colors, colorInputValue]
    dispatch(
      updateUserPreference({ ...userPreferences, colors: updatedColors }),
    )
    setColorInputValue('')
  }

  const validSelColor = isHexValue(colorInputValue)

  const colorPickerAdor = {
    content: '',
    display: 'block',
    width: '20px',
    height: '21px',
    background: colorInputValue
      ? '#' + colorInputValue
      : 'conic-gradient(red,#ff0,lime,aqua,blue,#f0f,red)',
    borderRadius: '50%',
    marginRight: '0.5rem',
    boxShadow: 'inset 0 0 1px 1px rgb(0 0 0 / 10%)',
    contentVisibility: 'hidden',
  }

  return (
    <Grid container className={classes.colorFilterContainer}>
      {colors.length ? (
        <Grid item>
          <Grid item className={classes.colorGridItem}>
            Saved Colors
          </Grid>
          <Grid item className={classes.colorGridItem}>
            {colors.map((color, index) =>
              createSavedColors(
                color.includes('#') ? color : `#${color}`,
                index,
              ),
            )}
          </Grid>
        </Grid>
      ) : (
        ''
      )}
      <Grid item className={classes.colorGridItem}>
        Default Colors
      </Grid>
      <Grid item className={classes.colorPicker}>
        <CirclePicker
          width="282px"
          colors={Object.keys(DEFAULT_COLORS)}
          circleSize={30}
          circleSpacing={15}
          color={searchColor || '#111'}
          onChange={handleColorChange}
        />
      </Grid>
      <Grid item className={classes.colorGridItem}>
        Custom Color
      </Grid>
      <Grid item className={classes.colorGridItem}>
        <TextField
          id="color-filter-name"
          placeholder={'Enter hex'}
          value={colorInputValue}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <span style={colorPickerAdor}></span>#
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  type="button"
                  aria-label="search"
                  onClick={savePreferenceColor}
                  // disabled={validSelColor ? false : true}
                  size="large"
                >
                  {validSelColor ? (
                    <Tooltip title="Add to Saved Colors">
                      <FavoriteOutlinedIcon />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Enter Valid Hex Value to Save Color">
                      <FavoriteBorderOutlinedIcon />
                    </Tooltip>
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          inputProps={{
            maxLength: 6,
          }}
          // error={
          //   !isEmpty(colorInputValue)
          //     ? validSelColor
          //       ? 'Error Valid Hex Value'
          //       : 'Invalid Hex'
          //     : ''
          // }
          variant="outlined"
          onChange={(event) => {
            setColorInputValue(event.target.value)
            setSelectedColor(`#${event.target.value}`)
          }}
        />
      </Grid>
      <Grid item>
        <CustomButtonGroup
          hideClearButton={true}
          onClickClear={onClickClear}
          onClickApply={onClickApply}
          isApplyDisabled={
            filteredHexValue !== selectedColor && !isHexValue(selectedColor)
          }
        />
      </Grid>
    </Grid>
  )
}

export default ColorFilter
