import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import Downshift from 'downshift'
import keycode from 'keycode'
import axios from 'axios'
import classNames from 'classnames'
import { debounce, get, findIndex, isEqual } from 'lodash'
import { Chip, TextField, MenuItem, Paper, LinearProgress } from '@mui/material'
import { Cancel } from '@mui/icons-material'
import { withStyles } from '@mui/styles'
import IconWrapper from '../IconWrapper/IconWrapper'
import apiConfig from '../../config/apiConfig'
import { FormikFieldPropTypes } from '../../constants/projects'
import { extractEmails } from '../../helpers/stringHelper'
import { praxis } from '../../config/themeConfig'
import { FIELD_PROJECT_ADMINS } from '../../constants/projectTask'

const KEY = apiConfig.key

const styles = {
  fixMargins: {
    marginLeft: '10px',
    marginRight: '-10px',
  },
  label: {
    backgroundColor: praxis.themePrimary,
    color: 'white',
  },
  personDropdownContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    height: 'auto',
  },
  displayName: {
    fontWeight: 'bold',
    fontSize: '16px',
  },
  subline: {
    fontWeight: 'light',
    fontSize: '12px',
  },
  personChips: {
    paddingTop: '10px',
    display: 'flex',
    width: '130%',
    gap: '5px',
    flexWrap: 'wrap',
  },
}

export const PeopleSearchFormField = ({
  form = {},
  field = {},
  label = '',
  iconLabel = '',
  required = false,
  classes = {},
}) => {
  const [inputValue, setInputValue] = useState('')
  const [suggestions, setSuggestions] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const { value = [], name: fieldName = '' } = field
  const getSuggestions = (inputValue = '') => {
    if (!inputValue) return setSuggestions([])
    setIsLoading(true)
    const TM_COMMON_URL = `${apiConfig.userDetails.usersList}?name_like=${inputValue}&key=${KEY}`
    const TM_URL =
      fieldName === 'project_admins'
        ? TM_COMMON_URL
        : `${TM_COMMON_URL}&fetch_type=uploader`
    return axios.get(TM_URL).then((res) => {
      const users = get(res, 'data.users', [])
      setSuggestions(users)
      return setIsLoading(false)
    })
  }

  const debouncedGetSuggestions = useRef(debounce(getSuggestions, 1000)).current

  const handleChange = (item) => {
    const { name = '', value = [] } = field
    const newSelectedItem = [...value]
    const { group_id = '', login_id = '' } = item

    // check if trying to add a group or user, then check for duplicates based on that.
    if (group_id) {
      if (findIndex(value, (x = {}) => x.group_id === group_id) === -1) {
        newSelectedItem.push(item)
      }
    } else {
      if (findIndex(value, (x = {}) => x.login_id === login_id) === -1) {
        newSelectedItem.push(item)
      }
    }

    setInputValue('')
    return form.setFieldValue(name, newSelectedItem)
  }

  const handleKeyDown = (event) => {
    const { name = '', value = [] } = field
    if (value.length && !inputValue.length && keycode(event) === 'backspace') {
      form.setFieldValue(name, value.slice(0, value.length - 1))
    }
  }

  const handleEmails = async (emails = []) => {
    setIsLoading(true)
    const { name = '', value = [] } = field
    const newSelectedItem = [...value]
    if (emails.length) {
      for (let i = 0; i < emails.length; i++) {
        const email = emails[i]
        const displayName = email.split('@')[0]
        const TM_URL =
          fieldName === FIELD_PROJECT_ADMINS
            ? `${apiConfig.userDetails.usersList}?name_like=${displayName}&key=${KEY}`
            : `${apiConfig.userDetails.usersList}?name_like=${displayName}&key=${KEY}&fetch_type=uploader`
        const res = await axios.get(TM_URL)
        const users = get(res, 'data.users', [])
        const user = users.find(
          (x) => x.email_address.toLowerCase() === email.toLowerCase(),
        )
        if (user) {
          if (
            findIndex(value, (x = {}) => x.login_id === user.login_id) === -1
          ) {
            newSelectedItem.push(user)
          }
        } else {
          newSelectedItem.push({ display_name: email, isBadEmail: true })
        }
      }
    }
    form.setFieldValue(name, newSelectedItem)
    setIsLoading(false)
    return setInputValue('')
  }

  const handleOnPaste = async (event) => {
    const emails = extractEmails(event.clipboardData.getData('Text')) || []
    if (emails.length) {
      handleEmails(emails)
    }
  }

  const handleInputChange = (event) => {
    setInputValue(event.target.value)
    debouncedGetSuggestions(event.target.value)
  }

  const handleDelete = (item) => () => {
    const { name = '', value = [] } = field
    const newSelectedItem = [...value]
    const itemIndex = findIndex(value, (x) => isEqual(x, item))
    newSelectedItem.splice(itemIndex, 1)
    form.setFieldValue(name, newSelectedItem)
  }

  return (
    <IconWrapper iconTag={iconLabel}>
      <Downshift
        inputValue={inputValue}
        onChange={handleChange}
        selectedItem={value}
      >
        {({
          getInputProps,
          getItemProps,
          isOpen,
          selectedItem: selectedItem2,
          highlightedIndex,
        }) => (
          <div>
            <TextField
              variant="outlined"
              fullWidth
              required={required}
              InputLabelProps={{ shrink: true }}
              error={!!form.errors[field.name]}
              label={label}
              placeholder={`Enter ${label}`}
              style={{ marginLeft: '10px' }}
              helperText={
                fieldName === FIELD_PROJECT_ADMINS && !isOpen
                  ? 'Include Agency PMs'
                  : !!form.errors[field.name]
                    ? 'Required field'
                    : ''
              }
              InputProps={getInputProps({
                onPaste: handleOnPaste,
                onChange: handleInputChange,
                onKeyDown: handleKeyDown,
              })}
              data-cy="peopleFormFieldText"
            />
            <div className={classes.personChips}>
              {
                // render list of selected people as Chips
                (value || []).map((item) => (
                  <Chip
                    deleteIcon={
                      <Cancel
                        style={{
                          color: item.isBadEmail ? 'white' : 'black',
                          opacity: '70%',
                        }}
                      />
                    }
                    className={classNames({
                      [classes.label]: item.isBadEmail,
                    })}
                    key={item.display_name}
                    tabIndex={-1}
                    label={item.display_name}
                    onDelete={handleDelete(item)}
                  />
                ))
              }
            </div>
            {isLoading && <LinearProgress className={classes.fixMargins} />}
            <br />
            {isOpen ? (
              <Paper
                square
                className={classes.fixMargins}
                style={{
                  zIndex: 1000,
                  maxHeight: '250px',
                  overflowY: 'scroll',
                }}
              >
                {suggestions.map((suggestion, index) => (
                  <MenuItem
                    className={classes.personDropdownContainer}
                    {...getItemProps({ item: suggestion })}
                    key={suggestion['login_id']}
                    selected={highlightedIndex === index}
                    component="div"
                    style={{
                      fontWeight: findIndex(selectedItem2, (item) =>
                        isEqual(item, suggestion),
                      )
                        ? 400
                        : 500,
                    }}
                    selectedItem={selectedItem2}
                  >
                    <span
                      data-cy="peopleFormFieldOption"
                      className={classes.displayName}
                    >
                      {suggestion['display_name']}
                    </span>
                    <span className={classes.subline}>
                      {suggestion['email_address'] || ''}
                    </span>
                    <span className={classes.subline}>
                      {suggestion['title'] || ''}
                    </span>
                  </MenuItem>
                ))}
              </Paper>
            ) : null}
          </div>
        )}
      </Downshift>
    </IconWrapper>
  )
}

PeopleSearchFormField.propTypes = {
  classes: PropTypes.object,
  ...FormikFieldPropTypes,
}

export default withStyles(styles)(PeopleSearchFormField)
