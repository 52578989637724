import React from 'react'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import createGenerateClassName from '@mui/styles/createGenerateClassName'
import StylesProvider from '@mui/styles/StylesProvider'
import { AuthProvider } from '@praxis/component-auth'
import { useEnv } from '@praxis/component-runtime-env'
import Layout from './Layout/Layout'
import muiTheme from '../config/themeConfig'
import config from '../config/apiConfig'
import Interceptor from './Interceptor'
import {
  isAssethubFastlyDomain,
  isPOLDomain,
} from '../../src/helpers/UrlHelper'
import MaintenancePage from './MaintenancePage'

const generateClassName = createGenerateClassName({
  seed: 'ahb',
  // productionPrefix: 'ahb',
  // disableGlobal: true,
})

const App = (props) => {
  const { DISPLAY_MAINTENANCE_PAGE = false } = useEnv() || {}
  const currentHost = window.location.hostname
  let configAuth = Object.assign({}, config?.auth)
  if (isPOLDomain(currentHost)) {
    configAuth = Object.assign({}, config?.polAuth)
  }

  const isMaintenancePage =
    DISPLAY_MAINTENANCE_PAGE &&
    (isAssethubFastlyDomain(currentHost) || isPOLDomain(currentHost))

  return isMaintenancePage ? (
    <MaintenancePage />
  ) : (
    <StylesProvider generateClassName={generateClassName}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={muiTheme}>
          <AuthProvider {...configAuth}>
            <Interceptor>
              <Layout />
            </Interceptor>
          </AuthProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </StylesProvider>
  )
}

export default App
