import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import Fab from '@mui/material/Fab'
import Grid from '@mui/material/Grid'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import TableSortLabel from '@mui/material/TableSortLabel'
import Switch from '@mui/material/Switch'
import { getDisplayName } from '../../mappers/userMapper'
import { ADMIN_ROLE_ID } from '../../constants/userRoles'
import { praxis } from '../../config/themeConfig'
import imageFallback from '../../images/ic_account_circle.png'

const styles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: useTheme().spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 400,
    position: 'relative',
  },
  fab: {
    backgroundColor: '#FC3',
    color: '#FFF',
    boxShadow: 'none',
    height: 40,
    width: 40,
  },
  participantIcon: {
    marginRight: 6,
    height: 40,
    width: 40,
    borderRadius: 40,
    position: 'relative',
    display: 'inline-block',
  },
  rowImage: {
    minWidth: 60,
    height: 65,
  },
  name: {
    position: 'absolute',
    marginTop: 20,
  },
  nameWidth: {
    width: '75%',
  },
  userEmail: {
    paddingTop: '10px',
  },
  rightAlign: {
    textAlign: 'right',
  },
  colorSwitchBase: {
    color: 'grey',
    '& + $colorBar': {
      backgroundColor: 'grey',
    },
    '&.Mui-colorChecked': {
      color: praxis.lighterBlue,
      '& + $colorBar': {
        backgroundColor: 'grey',
      },
    },
  },
  colorBar: {},
  colorChecked: {},
}))

class UserListTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      orderBy: 'first_name',
      order: 'asc',
    }
  }

  desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }

  getSorting() {
    return this.state.order === 'desc'
      ? (a, b) => this.desc(a, b, this.state.orderBy)
      : (a, b) => -this.desc(a, b, this.state.orderBy)
  }

  handleRequestSort(property) {
    const orderBy = property
    let order = 'desc'

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc'
    }

    this.setState({ order, orderBy })
  }

  render() {
    const { classes, userList, onUserRoleChange } = this.props
    const { order, orderBy } = this.state
    const refinedUserList = userList.filter((userList) => !userList.error)
    return (
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.nameWidth}>
              <TableSortLabel
                active={orderBy === 'first_name'}
                direction={order}
                onClick={() => this.handleRequestSort('first_name')}
              >
                Name
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.rightAlign}>
              Admin Privileges
            </TableCell>
            <TableCell style={{ textAlign: 'right' }}>Remove</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {refinedUserList.sort(this.getSorting()).map((user = {}, index) => {
            const {
              display_name = '',
              group_id = '',
              list_id = '',
              login_id = '',
              list_name = '',
              email_address = '',
              organization = '',
              role_id = '',
            } = user
            return (
              <TableRow data-cy="editMembersList" key={index}>
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.rowImage}
                >
                  <Grid container>
                    <Grid item xs={2} md={2}>
                      <Fab className={classes.fab}>
                        {display_name
                          ? display_name.split('.').length > 1
                            ? display_name.split('.')[0].substring(0, 1) +
                              display_name.split('.')[1].substring(0, 1)
                            : display_name.split('.')[0].substring(0, 1)
                          : group_id
                          ? display_name.substring(0, 2)
                          : list_id
                          ? list_name.substring(0, 2)
                          : login_id
                          ? display_name.substring(0, 2)
                          : ''}
                      </Fab>
                    </Grid>
                    {list_id
                      ? list_name
                      : getDisplayName(email_address, organization, classes)}
                  </Grid>
                </TableCell>
                <TableCell className={classes.rightAlign}>
                  <Switch
                    classes={{
                      switchBase: classes.colorSwitchBase,
                      checked: classes.colorChecked,
                      track: classes.colorBar,
                    }}
                    checked={role_id === ADMIN_ROLE_ID ? true : false}
                    onChange={() => onUserRoleChange(user)}
                    data-cy="editMembersAdmin"
                  />
                </TableCell>
                <TableCell align="right">
                  <IconButton
                    data-cy="editMembersRemove"
                    className={classes.button}
                    aria-label="Delete"
                    onClick={() => {
                      this.props.onDelete(user)
                    }}
                    size="large"
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    )
  }
}

UserListTable.propTypes = {
  classes: PropTypes.object.isRequired,
  userList: PropTypes.array.isRequired,
  onDelete: PropTypes.func.isRequired,
  onUserRoleChange: PropTypes.func.isRequired,
}

const MyComponent = (props) => {
  const classes = styles()
  return <UserListTable {...props} classes={classes} />
}

export default MyComponent
