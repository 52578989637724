import React from 'react'
import PropTypes from 'prop-types'
import { withStyles, makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import ButtonBase from '@mui/material/ButtonBase'
import newFileNoArrow from '../../images/revision/new-no-arrow.svg?url'
import revisionFile from '../../images/revision/Revision.svg?url'

export class ToggleNewRevision extends React.Component {
  render() {
    const { classes, findex, toggleType } = this.props
    return (
      <div className={classes.toggleContainer}>
        <ButtonBase
          className={
            classes.togButton +
            ' ' +
            (toggleType && toggleType === 'new'
              ? classes.activeBut
              : classes.inactiveBut) +
            ' ' +
            classes.newBorderRadius
          }
          onClick={(event) => {
            this.props.onToggle(event, findex, 'new')
          }}
        >
          <img
            className={classes.toggleImg}
            alt="version"
            src={newFileNoArrow}
          />
        </ButtonBase>
        <ButtonBase
          className={
            classes.togButton +
            ' ' +
            (toggleType && toggleType === 'revision'
              ? classes.activeBut
              : classes.inactiveBut) +
            ' ' +
            classes.revBorderRadius
          }
          onClick={(event) => {
            this.props.onToggle(event, findex, 'revision')
          }}
        >
          <img
            className={classes.toggleImg}
            alt="revision"
            src={revisionFile}
          />
        </ButtonBase>
      </div>
    )
  }
}
ToggleNewRevision.propTypes = {
  toggleType: PropTypes.string,
  findex: PropTypes.number,
  classes: PropTypes.object.isRequired,
  onToggle: PropTypes.func,
}

const styles = makeStyles((theme) => ({
  toggleContainer: {
    height: 56,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    margin: `${useTheme().spacing()}px 0`,
    background: 'transparent',
  },
  toggleImg: {
    height: '25px',
    width: '50px',
  },
  togButton: {
    padding: `${useTheme().spacing() - 2}px ${useTheme().spacing(2.5)}`,
    border: '1px solid #0D46A0',
  },
  activeBut: {
    backgroundColor: '#0D46A0',
  },
  newBorderRadius: {
    borderRadius: '4px 0px 0px 4px',
  },
  revBorderRadius: {
    borderRadius: '0px 4px 4px 0px',
  },
  inactiveBut: {
    backgroundColor: 'white',
  },
}))

const MyComponent = (props) => {
  const classes = styles()
  return <ToggleNewRevision {...props} classes={classes} />
}

export default MyComponent
