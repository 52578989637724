import React, { createContext, useReducer, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@mui/styles'
import Table from '@mui/material/Table'
import TableContainer from '@mui/material/TableContainer'
import TablePagination from '@mui/material/TablePagination'
import Paper from '@mui/material/Paper'
import CustomTableHead from './CustomTableHead'
import CustomTableBody from './CustomTableBody'
import CustomTableSearch from './CustomTableSearch'
import { updateTableContext } from '../../store/tableContext/actionCreator'

const useStyles = makeStyles({
  tableContainer: {
    maxWidth: '100vw',
    minHeight: '70vh',
    maxHeight: '85vh',
    height: 'fit-content',
    top: '10vh',
  },
  tablePagination: {
    position: 'inherit',
  },
  fixedTableLayout: {
    minWidth: '80vw',
    tableLayout: 'fixed',
  },
  horizontalScrollableLayout: {
    minWidth: '80vw',
  },
})

export const CustomTableContext = createContext({
  tableState: {},
  tableMethods: {},
})

const reducer = (state, action) => {
  const { payload, type } = action
  switch (type) {
    case 'CHECKBOX_HANDLER':
      return { ...state, selected: payload }
    case 'ORDER_BY_HANDLER':
      return { ...state, orderBy: payload }
    case 'ORDER_HANDLER':
      return { ...state, order: payload }
    case 'COLUMNS_HANDLER':
      return { ...state, colMap: payload }
    case 'DATA_HANDLER':
      return { ...state, data: payload }
    case 'IDENTIFIER_HANDLER':
      return { ...state, identifiers: payload }
    case 'ORIGINAL_DATA_HANDLER':
      return { ...state, originalData: payload }
    case 'PAGE_HANDLER':
      return { ...state, page: payload }
    case 'ROWS_PER_PAGE_HANDLER':
      return { ...state, rowsPerPage: payload }
    case 'TABLE_CONFIG':
      return { ...state, tableConfig: payload }
    default:
      return state
  }
}

function CustomTable(props) {
  const initState = React.useMemo(
    () => ({
      tableConfig: {},
      selected: [],
      orderBy: '',
      order: 'desc',
      colMap: [],
      identifiers: [],
      data: [],
      originalData: [],
      customizeCol: [],
      page: 0,
      rowsPerPage: 25,
    }),
    []
  )
  const { columns = [], data = [], tableConfig = {} } = props
  const {
    rowsPerPageOptionsList = [],
    showPagination = false,
    enableHorizontalScroll = false,
    defaultRowsPerPage = '',
    defaultOrderBy = '',
    defaultOrder = '',
    enableTableSearch = false,
    rowIdentifier = 'id',
  } = tableConfig
  const classes = useStyles()
  const reducerDispatch = useDispatch()
  const [state, dispatch] = useReducer(reducer, initState)

  const handleChangePage = (event, newPage) => {
    methods.pageHandler(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    const { target = {} } = event
    const { value = '' } = target
    methods.rowsPerPageHandler(+value)
    methods.pageHandler(0)
  }

  const methods = React.useMemo(
    () => ({
      tableConfigHandler: (data) =>
        dispatch({
          type: 'TABLE_CONFIG',
          payload: data,
        }),
      checkboxHandler: (data) =>
        dispatch({
          type: 'CHECKBOX_HANDLER',
          payload: data,
        }),

      orderByHandler: (orderBy) =>
        dispatch({
          type: 'ORDER_BY_HANDLER',
          payload: orderBy,
        }),

      orderHandler: (dir) =>
        dispatch({
          type: 'ORDER_HANDLER',
          payload: dir,
        }),

      colMapHandler: (data) =>
        dispatch({
          type: 'COLUMNS_HANDLER',
          payload: data,
        }),

      identifierHandler: (data) =>
        dispatch({
          type: 'IDENTIFIER_HANDLER',
          payload: data,
        }),

      dataHandler: (data) =>
        dispatch({
          type: 'DATA_HANDLER',
          payload: data,
        }),

      originalDataHandler: (data) =>
        dispatch({
          type: 'ORIGINAL_DATA_HANDLER',
          payload: data,
        }),

      customizeColHandler: (data) =>
        dispatch({
          type: 'CUSTOMIZE_COLUMN_HANDLER',
          payload: data,
        }),

      pageHandler: (data) =>
        dispatch({
          type: 'PAGE_HANDLER',
          payload: data,
        }),
      rowsPerPageHandler: (data) =>
        dispatch({
          type: 'ROWS_PER_PAGE_HANDLER',
          payload: data,
        }),
    }),
    []
  )

  useEffect(() => {
    methods.colMapHandler(columns)
    methods.tableConfigHandler(tableConfig)
    methods.dataHandler(data)
    methods.originalDataHandler(data)
    const identifierEntries = getIdentifiersFromData(data) || []
    methods.identifierHandler(identifierEntries)
    if (defaultRowsPerPage) {
      methods.rowsPerPageHandler(defaultRowsPerPage)
    }
    if (defaultOrderBy) {
      methods.orderByHandler(defaultOrderBy)
    }
    if (defaultOrder) {
      methods.orderHandler(defaultOrder)
    }
  }, [])

  const getIdentifiersFromData = (data) => {
    let identifierEntires = []
    if (data.length) {
      data.forEach((dataRecord = {}) => {
        const identifier = dataRecord[rowIdentifier]
        identifierEntires.push(identifier)
      })
    }
    return identifierEntires
  }

  useEffect(() => {
    reducerDispatch(updateTableContext(state))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.selected, state.orderBy, state.order])

  return (
    <CustomTableContext.Provider
      value={{ tableState: state, tableMethods: methods }}
    >
      {enableTableSearch && (
        <TableContainer>
          <CustomTableSearch />
        </TableContainer>
      )}
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table
          aria-label="custom table"
          stickyHeader
          className={
            enableHorizontalScroll
              ? classes.horizontalScrollableLayout
              : classes.fixedTableLayout
          }
        >
          <CustomTableHead />
          <CustomTableBody />
        </Table>
      </TableContainer>
      <TableContainer component={Paper}>
        {showPagination && (
          <TablePagination
            className={classes.tablePagination}
            rowsPerPageOptions={rowsPerPageOptionsList}
            component="div"
            count={data.length}
            rowsPerPage={state.rowsPerPage}
            page={state.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </TableContainer>
    </CustomTableContext.Provider>
  )
}

export default CustomTable
