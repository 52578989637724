import * as React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@mui/material'
import { Delete, DeleteSweep } from '@mui/icons-material'

const styles = makeStyles((theme) => ({
  button: {
    fontSize: '12px',
    color: 'white',
    backgroundColor: useTheme().palette.primary.bostonRed,
  },
  cancelButton: {
    fontSize: '12px',
    color: 'blue',
    backgroundColor: useTheme().palette.primary.lightestGrey,
  },
  paddingLeft: {
    paddingLeft: '5px',
  },
  warningText: {
    color: '#cc3300',
  },
}))

const DeleteAssetsDailog = ({
  classes = {},
  dialogOpen,
  deleteAssets,
  toggleConfirmationModal,
}) => (
  <div>
    <div>
      <Tooltip title="Delete">
        <IconButton
          aria-label="Delete"
          onClick={() => toggleConfirmationModal(false)}
          size="large"
        >
          <DeleteSweep />
        </IconButton>
      </Tooltip>
      <Dialog
        open={dialogOpen}
        onClose={() => toggleConfirmationModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete Asset(s)</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <span className={classes.warningText}>Warning:</span> Deleting
            assets will remove all revisions of this asset and impact review
            tasks (previous or current). This deletion is final and cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            // className="delete-assets"
            title="Delete Asset(s)"
            onClick={deleteAssets}
            className={classes.button}
            data-cy="deleteAssetDialogButton"
          >
            <Delete />
            <span style={styles.paddingLeft}>Delete Asset(s)</span>
          </Button>
          <Button
            onClick={() => toggleConfirmationModal(false)}
            color="primary"
            className={classes.cancelButton}
            data-cy="deleteAssetCancelButton"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  </div>
)

DeleteAssetsDailog.propTypes = {
  classes: PropTypes.object,
  deleteAssets: PropTypes.func,
  dialogOpen: PropTypes.func,
  toggleConfirmationModal: PropTypes.func,
}

const MyComponent = (props) => {
  const classes = styles()
  return <DeleteAssetsDailog {...props} classes={classes} />
}

export default MyComponent
