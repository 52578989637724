import idx from 'idx'
import { isEmpty, get } from 'lodash'
import {
  parenthesesPattern,
  emailPattern,
  DEFAULT_SCALE,
} from '../constants/annotation'

export const getHighestIdNumber = (annotations = []) => {
  const ids = annotations.reduce((agg = [], item = {}) => {
    const id = parseInt(idx(item, (_) => _.data.id))
    if (id) {
      agg.push(id)
    }
    return agg
  }, [])
  return ids.sort((a, b) => a - b).pop()
}

export const getEmailsFromText = (textBlock = '') => {
  const pattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  const emails = textBlock.match(emailPattern)
  let finalArr = []
  if (emails && emails.length > 0) {
    emails.forEach((email) => {
      const item = email.replace(parenthesesPattern, '').toLowerCase()
      if (pattern.test(String(item))) {
        finalArr.push(item)
      }
    })
  }
  return finalArr
}

export const isUserAdmin = (email = '', arrayOfAdmins = []) =>
  !!arrayOfAdmins.find((x) => x.email_address === email)

export const getUniqueViewedUsers = (arrayOfUsers = []) =>
  arrayOfUsers.reduce((aggregate, user) => {
    const displayName = idx(user, (_) => _.user_details.display_name)
    if (displayName && !aggregate.includes(displayName)) {
      aggregate.push(displayName)
    }
    return aggregate
  }, [])

export const getAnnotationFromComment = (comment = {}) => {
  const { geometry = '{}', comment_number = 0 } = comment
  const annotation = JSON.parse(geometry)
  if (isEmpty(comment) || isEmpty(annotation) || !comment_number) {
    return {}
  }
  annotation.data.id = comment_number
  return annotation
}

export function setScale(
  angle = 0,
  text = '',
  containerHeight = 0,
  containerWidth = 0,
  naturalHeight = 0,
  naturalWidth = 0,
  assetHeight = 0
) {
  if (text !== DEFAULT_SCALE) {
    return text
  }
  if (angle === 0 || angle === 180) {
    if (naturalWidth <= naturalHeight) {
      let ratio = naturalWidth / naturalHeight
      let reqWidth = (containerHeight - 20) * ratio
      if (containerWidth < reqWidth) {
        return containerWidth - 20
      }
      return reqWidth
    } else {
      return getWidthCalculation(containerHeight, containerHeight + 200)
    }
  } else {
    return getWidthCalculation(containerHeight, assetHeight)
  }
}

export const getWidthCalculation = (containerHeight, assetHeight) => {
  if (containerHeight > assetHeight) {
    return '100%'
  } else {
    if (containerHeight !== assetHeight) {
      const targetWidth = Math.floor((containerHeight / assetHeight) * 100)
      return `${targetWidth}%`
    }
    return '100%'
  }
}

export const getPageCommentCount = (asset = {}, pageNumber = 0) => {
  const allPageInfo = get(asset, 'asset_page_comments', [])
  const targetPageInfo = allPageInfo.find(
    (x) => x['page_number'] === pageNumber
  )
  if (!isEmpty(targetPageInfo)) {
    return targetPageInfo['comment_count']
  }
  return 0
}

export const getAssetVersions = (asset = {}) => {
  const assetVersions = get(asset, 'asset_version_numbers', [1])
  if (isEmpty(asset) || assetVersions.length === 1) return assetVersions

  // new Set() removes duplicates, sort returns asc array
  return Array.from(new Set(assetVersions)).sort((a, b) => a - b)
}

// Build dictionary
export const buildCommentNumberDictionary = (data = {}) => {
  if (isEmpty(data)) return {}

  return Object.keys(data).reduce((dict, assetId = {}) => {
    dict[assetId] = {
      totalNumberOfComments: data[assetId].total_comments || 0,
    }
    if (data[assetId].asset_comments.length > 0) {
      dict[assetId]['pageComments'] = data[assetId].asset_comments.reduce(
        (obj, pageData) => {
          obj[pageData.page_number] = pageData.comment_count
          return obj
        },
        {}
      )
    }
    return dict
  }, {})
}

// Pasrse 'First.M.Last' and 'First.Last' names Return { firstName, lastName, initials }
export const parseNameString = (nameString = null) => {
  if (typeof nameString !== typeof 'string') throw Error('Must be a string')

  const splitName = nameString.includes('.')
    ? nameString.split('.')
    : nameString
  const displayName = Array.isArray(splitName) ? splitName.join(' ') : splitName
  const firstName = splitName[0] || ''
  const lastName = splitName[splitName.length - 1] || ''
  const firstInitial = firstName.length ? firstName[0] : firstName
  const lastInitial = lastName.length ? lastName[0] : lastName
  const initials = `${firstInitial}${lastInitial}`

  return {
    displayName,
    firstName,
    lastName,
    initials,
  }
}
