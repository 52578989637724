import React from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment'
import { isEmpty, orderBy } from 'lodash'
import { lighten, useTheme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Paper,
  Checkbox,
  Tooltip,
  Select,
  MenuItem,
  ListItemText,
  Avatar,
  Grid,
  InputLabel,
} from '@mui/material'
import FormControl from '@mui/material/FormControl'
import Input from '@mui/material/Input'

import RemoteImageLoader from '../RemoteImageLoader'
import fileTypeImage from '../../images/filetypes/image-logo.svg?url'
import { getTaskAssetList, returnTaskTypeId } from '../../helpers/TaskHelper'
import { Mimetypes } from '../../helpers/MimetypeHelper'
import { REVIEW_TASK_TYPE_ID } from '../../constants/projectTask'

const rows = [
  {
    id: 'assetThumbnail',
    numeric: false,
    disablePadding: false,
    label: 'Thumbnail',
  },
  { id: 'assetName', numeric: false, disablePadding: false, label: 'Filename' },
  {
    id: 'assetRevision',
    numeric: false,
    disablePadding: false,
    label: 'Revision Number',
  },
  {
    id: 'assetImportDate',
    numeric: false,
    disablePadding: false,
    label: 'Upload Date',
  },
  {
    id: 'assetImportBy',
    numeric: false,
    disablePadding: false,
    label: 'Uploaded By',
  },
  {
    id: 'assetType',
    numeric: false,
    disablePadding: false,
    label: 'File Type',
  },
]

class EnhancedTableHead extends React.Component {
  createSortHandler = (property) => (event) => {
    this.props.onRequestSort(event, property)
  }

  render() {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount } =
      this.props
    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              data-cy="selectAllAssets"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
          {rows.map(
            (row) => (
              <TableCell
                key={row.id}
                numeric={row.numeric}
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === row.id ? order : false}
              >
                <Tooltip
                  title="Sort"
                  placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            ),
            this
          )}
        </TableRow>
      </TableHead>
    )
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}

const toolbarStyles = makeStyles((theme) => ({
  root: {
    paddingRight: useTheme().spacing(),
  },
  highlight:
    useTheme().palette.mode === 'light'
      ? {
          color: useTheme().palette.secondary.main,
          backgroundColor: lighten(useTheme().palette.secondary.light, 0.85),
        }
      : {
          color: useTheme().palette.text.primary,
          backgroundColor: useTheme().palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: useTheme().palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
    color: '#8b8b8b',
  },
}))

let EnhancedTableToolbar = (props) => {
  const { numSelected, classes } = props
  return (
    <Toolbar>
      <div className={classes.title}>
        {numSelected} assets selected for this task.
      </div>
    </Toolbar>
  )
}

EnhancedTableToolbar.propTypes = {
  assetType: PropTypes.string,
  classes: PropTypes.object.isRequired,
  handleSearch: PropTypes.func,
  handleSelectSearch: PropTypes.func,
  numSelected: PropTypes.number.isRequired,
  selectValue: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.string,
}

const EnhancedTableToolbarMok = (props) => {
  const classes = toolbarStyles()
  return <EnhancedTableToolbar {...props} classes={classes} />
}

const filterBarStyles = makeStyles((theme) => ({
  root: {
    paddingRight: useTheme().spacing(),
  },
  formControl: {
    width: '80%',
    marginLeft: 10,
  },
  filetypeFilter: {
    color: useTheme().palette.primary.graphite + ' !important',
  },
}))
let EnhancedTableFilterbar = (props) => {
  const {
    value,
    handleSearch,
    classes,
    assetType,
    handleSelectSearch,
    selectValue,
  } = props
  let stateVal = selectValue || []
  return (
    <div>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={12} sm={6}>
          <FormControl variant="standard" className={classes.formControl}>
            <InputLabel htmlFor="groups-name-filter">
              Filter by Asset Name
            </InputLabel>
            <Input
              id="groups-name-filter"
              onChange={handleSearch}
              value={value}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl variant="standard" className={classes.formControl}>
            <InputLabel htmlFor="select-multiple-checkbox">
              Filter by Filetype
            </InputLabel>
            <Select
              variant="standard"
              multiple
              value={stateVal}
              onChange={handleSelectSearch}
              input={<Input id="select-multiple-checkbox" />}
              renderValue={(selected) => selected.join(', ')}
            >
              {assetType.map((name) => (
                <MenuItem key={name} value={name}>
                  <Checkbox checked={stateVal.indexOf(name) > -1} />
                  <ListItemText
                    primary={name}
                    classes={{
                      primary: classes.filetypeFilter,
                    }}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </div>
  )
}

EnhancedTableFilterbar.propTypes = {
  assetType: PropTypes.string,
  classes: PropTypes.object.isRequired,
  handleSearch: PropTypes.func,
  handleSelectSearch: PropTypes.func,
  numSelected: PropTypes.number.isRequired,
  selectValue: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.string,
}

const EnhancedTableFilterbarMok = (props) => {
  const classes = filterBarStyles()
  return <EnhancedTableFilterbar {...props} classes={classes} />
}

const styles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: useTheme().spacing(3),
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  cardHeader: {
    backgroundColor: 'rgba(239, 239, 239, 0.5)',
    paddingBottom: 20,
  },
}))

class EnhancedTable extends React.Component {
  constructor(props) {
    super(props)
    this.fileTypes = Mimetypes
    let data = []
    if (Object.keys(this.props.info.task.default.assets).length > 0) {
      if (Object.keys(this.props.info.task.current.assets).length > 0) {
        data = this.props.info.task.current.assets
      } else {
        data = this.props.info.task.default.assets
      }
    }
    const { queryParamsTaskType = '' } = this.props
    const taskTypeId = returnTaskTypeId(queryParamsTaskType)
    const {
      assetList = [],
      assetTypeList = [],
      selectedAssetCounterList = [],
    } = getTaskAssetList(data)
    const filterData =
      taskTypeId === REVIEW_TASK_TYPE_ID
        ? assetList
        : assetList.filter(
            (data) =>
              !isEmpty(data.assetMasterUOIID) && !isEmpty(data.assetUOIID)
          )
    this.state = {
      order: 'asc',
      orderBy: 'calories',
      selected: selectedAssetCounterList,
      data: assetList,
      filterData: filterData,
      assetType: assetTypeList,
      page: 0,
      rowsPerPage: 10,
      seleactSearchValue: [],
    }
    this.handleSelectSearch = this.handleSelectSearch.bind(this)
  }
  handleRequestSort = (event, property) => {
    const orderBy = property
    let order = 'desc'

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc'
    }

    this.setState({ order, orderBy })
  }
  stableSort = (
    listData = {},
    order = 'asc',
    orderByValue = 'assetImportDate'
  ) => {
    let updatedOrderByProps = {}
    updatedOrderByProps = { [orderByValue]: order }
    if (orderByValue === 'assetType') {
      updatedOrderByProps['assetName'] = 'asc'
    }
    return orderBy(
      listData,
      Object.keys(updatedOrderByProps),
      Object.values(updatedOrderByProps)
    )
  }
  handleSelectAllClick = (event) => {
    if (event.target.checked) {
      // this.setState(state => ({selected: state.filterData.map(n => n.id)}))
      // return
      this.setState(
        (state) => ({ selected: state.filterData.map((n) => n.id) }),
        () => {
          this.checkedAssets()
        }
      )
      return
    }
    // this.setState({selected: []})
    this.setState(
      {
        selected: [],
      },
      () => {
        this.checkedAssets()
      }
    )
  }

  handleClick = (event, id) => {
    const { selected } = this.state
    const selectedIndex = selected.indexOf(id)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }
    this.setState(
      {
        selected: newSelected,
      },
      () => {
        this.checkedAssets()
      }
    )
  }
  checkedAssets() {
    let sel = this.state.selected
    let result = []
    this.state.filterData.forEach(function (filterData) {
      if (sel.indexOf(filterData.id) !== -1) {
        filterData.assetChecked = true
        result.push(filterData)
      }
    })
    this.props.handleStepper(result, 'assets')
  }
  handleChangePage = (event, page) => {
    this.setState({ page })
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value })
  }
  handleSearch = (event) => {
    const { data } = this.state
    let searchText = event.target.value
    let result = data.filter((item) =>
      Object.keys(item).some(
        (k) =>
          item[k] != null &&
          item[k].toString().toLowerCase().includes(searchText.toLowerCase())
      )
    )
    this.setState({ filterData: result, searchValue: event.target.value })
  }
  handleSelectSearch = (event) => {
    const { data } = this.state
    let searchSelect = event.target.value
    let resultSelect = data.filter((addedItem) =>
      searchSelect.find((cartItem) => cartItem === addedItem.assetType)
    )
    let resultSelected = searchSelect.length >= 1 ? resultSelect : data
    this.setState({
      filterData: resultSelected,
      seleactSearchValue: event.target.value,
    })
  }
  isSelected = (id) => this.state.selected.indexOf(id) !== -1
  render() {
    const { classes } = this.props
    const {
      filterData,
      order,
      orderBy,
      selected,
      rowsPerPage,
      page,
      assetType,
      seleactSearchValue,
    } = this.state
    const emptyRows =
      rowsPerPage -
      Math.min(rowsPerPage, filterData.length - page * rowsPerPage)
    // let selectedCheck = filterData.filter(a => a.assetChecked === true)
    return (
      <Paper className={classes.root}>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
          className={classes.cardHeader}
        >
          <Grid item xs={12} md={6}>
            <EnhancedTableFilterbarMok
              numSelected={selected.length}
              handleSearch={this.handleSearch}
              value={this.searchValue}
              assetType={assetType}
              handleSelectSearch={this.handleSelectSearch}
              selectValue={seleactSearchValue}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TablePagination
              component="div"
              count={filterData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                'aria-label': 'Previous Page',
              }}
              nextIconButtonProps={{
                'aria-label': 'Next Page',
              }}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
        <EnhancedTableToolbarMok numSelected={selected.length} />
        <div className={classes.tableWrapper}>
          {filterData.length ? (
            <Table className={classes.table} aria-labelledby="tableTitle">
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={this.handleSelectAllClick}
                onRequestSort={this.handleRequestSort}
                rowCount={filterData.length}
              />
              <TableBody>
                {this.stableSort(filterData, order, orderBy)
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((n) => {
                    const isSelected = this.isSelected(n.id)
                    const { assetMimeType = '' } = n
                    return (
                      <TableRow
                        hover
                        onClick={(event) => this.handleClick(event, n.id)}
                        role="checkbox"
                        aria-checked={isSelected}
                        tabIndex={-1}
                        key={n.id}
                        selected={isSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isSelected}
                            value="checkedB"
                            color="primary"
                          />
                        </TableCell>
                        <TableCell>
                          <RemoteImageLoader
                            mimeType={assetMimeType}
                            Src={n.assetThumbnail}
                            AltText={'Test'}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          {n.assetName}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {n.assetRevision}
                        </TableCell>
                        <TableCell>
                          {Moment.utc(n.assetImportDate).format(
                            'MMM D, YYYY h:mm a'
                          )}
                        </TableCell>
                        <TableCell>{n.assetImportBy}</TableCell>
                        <TableCell>
                          <Tooltip title={n.assetMimeType}>
                            <Avatar
                              alt="Type"
                              src={
                                Mimetypes[n.assetMimeType]
                                  ? Mimetypes[n.assetMimeType]
                                  : fileTypeImage
                              }
                              className={this.props.classes.avatar}
                            />
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 49 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          ) : (
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={12}>
                <h3> There are no assets associated with this project.</h3>
              </Grid>
            </Grid>
          )}
        </div>
      </Paper>
    )
  }
}

EnhancedTable.propTypes = {
  assetType: PropTypes.arrayOf(PropTypes.string),
  classes: PropTypes.object.isRequired,
  handleSearch: PropTypes.func,
  handleSelectSearch: PropTypes.func,
  handleStepper: PropTypes.func,
  info: PropTypes.shape({
    task: PropTypes.shape({
      current: PropTypes.shape({
        assets: PropTypes.object,
      }),
      default: PropTypes.shape({
        assets: PropTypes.object,
      }),
    }),
  }),
  queryParamsTaskType: PropTypes.string,
  selectValue: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.string,
}

const MyComponent = (props) => {
  const classes = styles()
  return <EnhancedTable {...props} classes={classes} />
}
export default MyComponent
