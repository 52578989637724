import React from 'react'
import PropTypes from 'prop-types'
import {
  FormControl,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
} from '@mui/material'
import {
  ASSET_ADVANCE_REVIEW_COMPLETED,
  ASSET_BASIC_REVIEW_COMPLETED,
  ASSET_REVIEW_FLAGGED,
  ASSET_REVIEW_PENDING,
} from '../../constants/metadata'

export const LibrarianStatusCard = ({
  editMode = true,
  classes = {},
  librarianReviewStatus = '',
  onLibrarianReviewStatusChange,
}) => (
  <Paper>
    <div className={classes.librarianCardSubHeading}>
      Librarian Review Status
    </div>
    <div className={classes.metadataWrapMedium}>
      <FormControl
        variant="standard"
        component="fieldset"
        className={classes.formControl}
      >
        <RadioGroup
          aria-label="Librarian Review Status Option"
          name="librarain_review_status"
          className={classes.group}
          value={librarianReviewStatus}
          onChange={onLibrarianReviewStatusChange}
        >
          <FormControlLabel
            value={ASSET_REVIEW_PENDING}
            control={
              <Radio
                classes={{ root: classes.radio, checked: classes.checked }}
              />
            }
            label={ASSET_REVIEW_PENDING}
            disabled={!editMode}
          />
          <FormControlLabel
            value={ASSET_BASIC_REVIEW_COMPLETED}
            control={
              <Radio
                classes={{ root: classes.radio, checked: classes.checked }}
              />
            }
            label={ASSET_BASIC_REVIEW_COMPLETED}
            disabled={!editMode}
          />
          <FormControlLabel
            value={ASSET_ADVANCE_REVIEW_COMPLETED}
            control={
              <Radio
                classes={{ root: classes.radio, checked: classes.checked }}
              />
            }
            label={ASSET_ADVANCE_REVIEW_COMPLETED}
            disabled={!editMode}
          />
          <FormControlLabel
            value={ASSET_REVIEW_FLAGGED}
            control={
              <Radio
                classes={{ root: classes.radio, checked: classes.checked }}
              />
            }
            label={ASSET_REVIEW_FLAGGED}
            disabled={!editMode}
          />
        </RadioGroup>
      </FormControl>
    </div>
  </Paper>
)

LibrarianStatusCard.propTypes = {
  classes: PropTypes.object,
  librarianReviewStatus: PropTypes.string,
  onLibrarianReviewStatusChange: PropTypes.func,
}

export default LibrarianStatusCard
