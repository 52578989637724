import * as React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import { withEnv } from '@praxis/component-runtime-env'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Helmet } from 'react-helmet'
/**Material components */
import { Grid, Fab, Tooltip, CircularProgress } from '@mui/material'
/**Material Icons */
import AddIcon from '@mui/icons-material/Add'
import { ViewAgenda } from '@mui/icons-material'
/**Components */
import withRouter from '../../containers/Router/WithRouter'
import HeaderTitle from '../../components/Header/HeaderTitle'
import BoardsList from './BoardsList'
import Filters from './Filters'
import CreateBoardForm from './CreateBoardForm'
/**Action creators */
import {
  fetchBoards,
  boardAddAssets,
  openModal,
  closeModal,
  toggleRedirectBoardList,
  setBoardDownloadAssets,
} from './../../store/boards/actions'
/**Selectors */
import { makeSelectAuthData, selectMemberOf } from './../../store/auth/selector'
import { selectHeaderTitle } from './../../store/layout/selector'
import { makeSelectBoards } from './../../store/boards/selector'
/**Constants/Utilities */
import {
  BOARDS_DISPLAY_NOTE,
  BOARDS_NO_RESULTS_NOTE,
  CREATED_BOARD_TYPE,
} from '../../constants/boards'
import { isAuthorizedToPage } from '../../helpers/UtilityHelper'

/**Styles */
const styles = makeStyles((theme) => ({
  button: {
    margin: useTheme().spacing(),
    float: 'right',
    backgroundColor: '#188295',
    '&:hover': {
      backgroundColor: '#002171',
    },
  },
  buttonProgress: {
    color: '#2196f3',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  root: {
    flexGrow: 1,
  },
  resultsText: {
    color: '#c0c0c0',
    margin: useTheme().spacing(),
  },
}))

export class BoardsPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isModalOpen: false,
      activeTab: CREATED_BOARD_TYPE,
    }
  }
  componentDidMount() {
    const {
      fetchBoards = () => {},
      toggleRedirectBoardList = () => {},
      setBoardDownloadAssets = () => {},
    } = this.props
    fetchBoards()
    toggleRedirectBoardList(false)
    setBoardDownloadAssets({})
  }

  openCreateBoardsForm = () => {
    const { openModal = () => {} } = this.props
    openModal('isCreateBoardModalOpen')
  }

  closeCreateBoardsForm = () => {
    const { closeModal = () => {} } = this.props
    closeModal('isCreateBoardModalOpen')
  }

  displayNoResultsMessage = () => {
    const { classes = {} } = this.props
    return (
      <Grid container justifyContent="center" spacing={1}>
        <Grid item className={classes.resultsText}>
          {BOARDS_NO_RESULTS_NOTE}
        </Grid>
      </Grid>
    )
  }

  renderCreateBoardsForm = () => {
    const { boards = {} } = this.props
    const { isCreateBoardModalOpen = false } = boards
    return isCreateBoardModalOpen ? (
      <CreateBoardForm
        className="createBoardForm"
        open={isCreateBoardModalOpen}
        onClose={this.closeCreateBoardsForm}
      />
    ) : (
      ''
    )
  }

  renderBoardsList = () => {
    const { boards = {}, router = {} } = this.props
    const { boardCount = Number(0), boardList = [] } = boards
    return boardCount > 0 ? (
      <BoardsList boardList={boardList} router={router} history={history} />
    ) : (
      this.displayNoResultsMessage()
    )
  }

  renderCreateBoardBtn = () => {
    const { memberOf = [], env = {}, classes = {} } = this.props
    const { REACT_APP_ROLES_CONFIG = {} } = env
    const isAuthorizedUser = isAuthorizedToPage(
      'boards',
      memberOf,
      REACT_APP_ROLES_CONFIG
    )
    return isAuthorizedUser ? (
      <Tooltip
        data-cy="createBoardButton"
        id="tooltip-icon"
        title="Create New Board"
      >
        <Fab
          onClick={() => this.openCreateBoardsForm()}
          color="primary"
          aria-label="add"
          className={classes.button}
        >
          <div className="icon-default">
            <AddIcon />
          </div>
          <div className="icon-hover">
            <ViewAgenda />
          </div>
        </Fab>
      </Tooltip>
    ) : (
      ''
    )
  }

  renderBoardsDisplayNote = () => {
    const { boards = {}, classes = {} } = this.props
    const { boardCount = Number(0) } = boards
    return boardCount >= 1 ? (
      <Grid item className={classes.resultsText}>
        {BOARDS_DISPLAY_NOTE}
      </Grid>
    ) : (
      ''
    )
  }

  render() {
    const { classes = {}, headerTitle = '', boards = {} } = this.props
    const { isFetching = false } = boards
    const { activeTab = CREATED_BOARD_TYPE } = this.state

    return (
      <div className={classes.BoardsPage}>
        <HeaderTitle title="Boards" icon="Boards" />
        <Helmet>
          <title>{headerTitle}</title>
        </Helmet>
        <div className={classes.root}>
          <Grid container justifyContent="center">
            <Grid item xs={false} sm={10}>
              {}
            </Grid>
            <Grid item xs={2}>
              {
                /**Create Boards Icon */
                this.renderCreateBoardBtn()
              }
            </Grid>
            <Grid container justifyContent="center" spacing={1}>
              {
                /**Boards Note */
                this.renderBoardsDisplayNote()
              }
            </Grid>
            <Grid item xs={8}>
              <Filters boardsType={activeTab} />
            </Grid>
          </Grid>
          <div>
            {
              /**Boards List */
              this.renderBoardsList()
            }
            {isFetching && (
              <CircularProgress size={50} className={classes.buttonProgress} />
            )}
          </div>
          {
            /**Create Board */
            this.renderCreateBoardsForm()
          }
        </div>
      </div>
    )
  }
}

BoardsPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  auth: PropTypes.shape({
    isProvisionedUser: PropTypes.bool,
  }),
  fetchBoards: PropTypes.func,
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  classes: PropTypes.object.isRequired,
  headerTitle: PropTypes.string,
  boards: PropTypes.object,
  toggleRedirectBoardList: PropTypes.func,
}

const mapStateToProps = (state) => ({
  auth: makeSelectAuthData()(state),
  boards: makeSelectBoards()(state),
  headerTitle: selectHeaderTitle()(state),
  memberOf: selectMemberOf()(state),
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchBoards,
      boardAddAssets,
      openModal,
      closeModal,
      toggleRedirectBoardList,
      setBoardDownloadAssets,
    },
    dispatch
  )

const MyComponent = (props) => {
  const classes = styles()
  return <BoardsPage {...props} classes={classes} />
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withEnv()(withRouter(MyComponent)))
