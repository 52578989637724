import { makeStyles } from '@mui/styles'
import UpdatesAndMaintenanceAnimation from '../images/UpdatesAndMaintenance.gif'

const useStyles = makeStyles({
  maintenanceContainer: {
    padding: '10%',
  },
  displayImg: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageDiv: {
    height: '300px',
  },
  displayContent: {
    textAlign: 'center',
    padding: '10px',
  },
  contentTitle: {
    fontSize: '46px',
    color: '#1B7E7E',
    paddingBottom: '25px',
  },
  contentText: {
    fontSize: '24px',
    color: '#2B2D31',
    fontWeight: '400',
  },
})

function MaintenancePage() {
  const classes = useStyles()
  return (
    <div className={classes.maintenanceContainer}>
      <div className={classes.displayImg}>
        <img
          className={classes.imageDiv}
          src={UpdatesAndMaintenanceAnimation}
          alt="Maintenance_gif_loading..."
        />
      </div>
      <div className={classes.displayContent}>
        <div className={classes.contentTitle}>Under Maintenance</div>
        <div className={classes.contentText}>
          Our system is currently undergoing essential updates ensure optimal
          performance and security. During this time, the system will be
          temporarily unavailable. We apologize for any inconvenience this may
          cause and appreciate your understanding.
        </div>
      </div>
    </div>
  )
}

export default MaintenancePage
