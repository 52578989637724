import Cookies from 'js-cookie'
import { isPOLDomain } from './UrlHelper'
import apiConfig from '../config/apiConfig'

export const isSessionActive = (loginId = '', hostName = '') => {
  //Check Single Sign On only for POL
  if (isPOLDomain(hostName)) {
    // This cookie drop by POL
    let logOutUrl = '/'
    const ssoSessionInfo = Cookies.get('SSOSESSIONINFO')
    if (ssoSessionInfo) {
      //Decode session and parse to json object
      const ssoSessionObj = JSON.parse(window.atob(ssoSessionInfo))
      if (
        ssoSessionObj.user &&
        ssoSessionObj.login_status &&
        loginId &&
        ssoSessionObj.user === loginId
      ) {
        //Valid only when user mathces and login status is true
        return true
      }
    } else {
      logOutUrl = encodeURI(
        apiConfig.auth.logoutUrl + '?TARGET=' + window.location.href
      )
    }
    //Invalid case: Remove token from LS will trigger the login route
    localStorage.removeItem('access_token')
    localStorage.removeItem('id_token')
    setTimeout(() => {
      if (logOutUrl) {
        window.location.href = logOutUrl
      }
    }, 3000)
    return false
  } else {
    return true
  }
}
