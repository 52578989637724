import * as React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import FormatListBulleted from '@mui/icons-material/FormatListBulleted'
import Dashboard from '@mui/icons-material/Dashboard'
import ImageList from '@mui/icons-material/Image'

import withRouter from '../../../../containers/Router/WithRouter'
import { praxis } from '../../../../config/themeConfig'

const styles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gap: useTheme().spacing(3),
  },
  buttonProgress: {
    color: '#2196f3',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  button: {
    margin: useTheme().spacing(),
    float: 'right',
    backgroundColor: '#188295',
    '&:hover': {
      backgroundColor: '#002171',
    },
  },
  root: {
    flexGrow: 1,
  },
  resultsText: {
    color: '#c0c0c0',
    margin: useTheme().spacing(),
  },
  TabsContainer: {
    backgroundColor: '#fafafa',
    fontWeight: 400,
    color: praxis.themePrimary,
    borderBottom: '1px solid rgba(0,0,0,0.12)',
  },
  avatar: {
    backgroundColor: '#FFF',
  },
  avatarIcon: {
    color: '#8D8D8D',
  },
  cardHeader: {
    backgroundColor: '#efefef',
  },
  colorTextHeaader: {
    color: '#8D8D8D',
    fontSize: '20px',
    fontWeight: '300',
  },
  cardCont: {
    padding: '0 16px',
  },
  colorTextSecondary: {
    color: '#CAB350',
    fontWeight: '300',
  },
  card: {
    marginTop: 10,
  },
}))

class ProjectTabPage extends React.Component {
  state = {
    isModalOpen: false,
    loading: true,
    activeTabIndex: 0,
  }

  handleTabChange = (event, value) => {
    const {
      router: { params = {} },
    } = this.props
    let url = ''
    if (value === 0 && params.projectId) {
      url = '/project/' + params.projectId + '/dashboard'
    } else if (value === 1 && params.projectId) {
      url = '/project/' + params.projectId + '/assets'
    } else {
      url = '/project/' + params.projectId + '/tasks/list'
    }
    this.props.router.navigate(url)
  }
  render() {
    const { classes } = this.props
    const { activeTabIndex, groupId } = this.props

    return (
      <div className={classes.root}>
        <Tabs
          value={activeTabIndex}
          groupid={groupId}
          onChange={this.handleTabChange}
          centered
          className={classes.TabsContainer}
          indicatorColor="primary"
          textColor="inherit"
        >
          <Tab data-cy="dashboardTab" label="DASHBOARD" icon={<Dashboard />} />
          <Tab data-cy="assetTab" label="ASSETS" icon={<ImageList />} />
          <Tab data-cy="tasksTab" label="TASKS" icon={<FormatListBulleted />} />
        </Tabs>
      </div>
    )
  }
}

ProjectTabPage.contextTypes = {
  router: PropTypes.object,
}

ProjectTabPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  activeTabIndex: PropTypes.number,
  classes: PropTypes.object,
  groupId: PropTypes.string,
  id: PropTypes.string,
}

const MyComponent = (props) => {
  const classes = styles()
  return <ProjectTabPage {...props} classes={classes} />
}

export default withRouter(MyComponent)
