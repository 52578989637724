import { withStyles } from '@mui/styles'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import AddIcon from '@mui/icons-material/Add'
import HeaderTitle from '../Header/HeaderTitle'
import UsageRightsList from './UsageRightsList'
import withRouter from '../../containers/Router/WithRouter'

const styles = () => ({
  table: {
    margin: '16px',
    position: 'relative',
  },
  progress: {
    margin: '16px',
    marginLeft: '50%',
    marginTop: '20%',
    color: 'blue',
  },
  button: {
    float: 'right',
    backgroundColor: '#188295',
    top: '-48px',
    cursor: 'pointer',
    width: '140px',
    height: '30px',
    color: 'white',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: '#1B6DC5',
    },
  },
})

function UsageRights({ ...props }) {
  const { classes = {}, router = {} } = props

  function handleClickCreate() {
    router.navigate('/usageRights/create')
  }

  return (
    <div className={classes.table}>
      <HeaderTitle title="Usage Rights" icon="Usage Rights" />
      <div style={{ marginBottom: '63px' }}>
        <Tooltip id="tooltip-icon" title="Create">
          <Button
            data-cy="createUsageRights"
            variant="contained"
            color="primary"
            className={classes.button}
            startIcon={<AddIcon />}
            onClick={() => {
              handleClickCreate()
            }}
          >
            New Record
          </Button>
        </Tooltip>
      </div>
      <UsageRightsList />
    </div>
  )
}

UsageRights.defaultProps = {
  classes: {},
}

export default withStyles(styles)(withRouter(UsageRights))
