import { Button, Chip, Dialog, Grid, Slide } from '@mui/material'
import React, { useState, useEffect } from 'react'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import DoneIcon from '@mui/icons-material/Done'
import BulkReviewAssetTable from './BulkReviewAssetTable'
import {
  AI_ASSET_STATUS_NOT_PROCESSED,
  filterChips,
  updateCountForFilters,
} from '../helpers/genaiTestHelper'
import BulkReviewLargeView from './BulkReviewLargeView'
import { ASSET_STATUS_SUCCESS } from '../constants/genai'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />
})

function BulkReviewAssetView({
  parentClasses = {},
  bulkTestReviewData = {},
  selectedAssets = [],
  setSelectedAssets = () => {},
  onReviewSuccess = () => {},
}) {
  let { assets: reviewAssets = [] } = bulkTestReviewData
  const [selectedFilter, setSelectedFilter] = useState('')
  const [viewLargeMode, setViewLargeMode] = useState(false)

  const updatedFilterChipsWithCount = updateCountForFilters(
    reviewAssets,
    filterChips,
  )

  const filteredAssets = selectedFilter
    ? selectedFilter === AI_ASSET_STATUS_NOT_PROCESSED
      ? reviewAssets.filter(
          (asset) => asset.asset_status !== ASSET_STATUS_SUCCESS,
        )
      : reviewAssets.filter(
          (assetDetails) => assetDetails.quality_status === selectedFilter,
        )
    : reviewAssets

  const processedAssets = filteredAssets.filter(
    (assetDetails) => assetDetails.asset_status === ASSET_STATUS_SUCCESS,
  )

  useEffect(() => {
    if (!viewLargeMode) {
      onReviewSuccess()
    }
  }, [viewLargeMode])

  return (
    <Grid container>
      <Grid container className={parentClasses.assetFilterContainerClass}>
        <Grid item className={parentClasses.chipDetailClass}>
          <Grid item>Filter by</Grid>
          {updatedFilterChipsWithCount.map((chipDetails) => {
            const {
              label = '',
              count = '',
              color = '',
              identifier = '',
            } = chipDetails
            return (
              <Grid item>
                <Chip
                  icon={selectedFilter === identifier ? <DoneIcon /> : ''}
                  label={`${label} (${count})`}
                  variant="outlined"
                  color={color}
                  clickable
                  onClick={() => setSelectedFilter(identifier)}
                />
              </Grid>
            )
          })}
        </Grid>

        <Grid item>
          <Button
            variant="contained"
            className={parentClasses.transparentButton}
            onClick={() => setViewLargeMode(true)}
            size="small"
            disabled={!processedAssets.length}
          >
            View Large <ArrowForwardIcon />
          </Button>
        </Grid>
      </Grid>
      <Grid container>
        <BulkReviewAssetTable
          parentClasses={parentClasses}
          filteredAssetList={filteredAssets}
          setSelectedAssets={setSelectedAssets}
          selectedAssets={selectedAssets}
        />
      </Grid>
      {viewLargeMode && (
        <Dialog
          fullScreen
          open={viewLargeMode}
          onClose={() => setViewLargeMode(false)}
          TransitionComponent={Transition}
        >
          <BulkReviewLargeView
            setViewLargeMode={setViewLargeMode}
            bulkTestReviewData={bulkTestReviewData}
            filteredAssetList={processedAssets}
          />
        </Dialog>
      )}
    </Grid>
  )
}

export default BulkReviewAssetView
