import React from 'react'

import Point from './Point'
import Editor from './Editor'
import FancyRectangle from './FancyRectangle'
import Rectangle from './Rectangle'
import Oval from './Oval'
import Content from './Content'
import Overlay from './Overlay'
import Drawing from './Drawing'
import Highlighter from './Highlighter'

import {
  RectangleSelector,
  PointSelector,
  OvalSelector,
  DrawingSelector,
  HighlighterSelector,
} from '../selectors'

const RenderOverlayComp = ({ type, annotation }) => {
  switch (type) {
    case PointSelector.TYPE:
      return <Overlay>Click to Annotate</Overlay>
    default:
      return <Overlay>Click and Drag to Annotate</Overlay>
  }
}
RenderOverlayComp.displayName = 'RenderOverlayComp'

const RenderContentComp = ({
  key,
  annotation,
  mouse,
  positionX,
  positionY,
  scale,
}) => (
  <Content
    key={key}
    annotation={annotation}
    mouse={mouse}
    positionX={positionX}
    positionY={positionY}
    scale={scale}
  />
)
RenderContentComp.displayName = 'RenderContentComp'

const RenderHighlightComp = ({ key, annotation, active }) => {
  switch (annotation.geometry.type) {
    case RectangleSelector.TYPE:
      return <Rectangle key={key} annotation={annotation} active={active} />
    case PointSelector.TYPE:
      return <Point key={key} annotation={annotation} active={active} />
    case OvalSelector.TYPE:
      return <Oval key={key} annotation={annotation} active={active} />
    case DrawingSelector.TYPE:
      return <Drawing key={key} annotation={annotation} active={active} />
    case HighlighterSelector.TYPE:
      return <Highlighter key={key} annotation={annotation} active={active} />
    default:
      return null
  }
}
RenderHighlightComp.displayName = 'RenderHighlightComp'

const RenderEditorComp = ({ annotation, onChange, onSubmit }) => (
  <Editor annotation={annotation} onChange={onChange} onSubmit={onSubmit} />
)

RenderEditorComp.displayName = 'RenderEditorComp'

const RenderSelectorComp = ({ annotation, options }) => {
  switch (annotation.geometry.type) {
    case RectangleSelector.TYPE:
      return <FancyRectangle annotation={annotation} />
    case PointSelector.TYPE:
      return <Point annotation={annotation} />
    case OvalSelector.TYPE:
      return <Oval annotation={annotation} />
    case DrawingSelector.TYPE:
      return <Drawing annotation={annotation} />
    case HighlighterSelector.TYPE:
      return <Highlighter annotation={annotation} />
    default:
      return null
  }
}

RenderSelectorComp.displayName = 'RenderSelectorComp'

const DefaultPropsComp = {
  ref: () => {},
  onChange: () => {},
  onSubmit: () => {},
  type: RectangleSelector.TYPE,
  selectors: [
    RectangleSelector,
    PointSelector,
    OvalSelector,
    DrawingSelector,
    HighlighterSelector,
  ],
  disableAnnotation: false,
  disableSelector: false,
  disableEditor: false,
  disableOverlay: false,
  activeAnnotationComparator: (a, b) => a === b,
  renderSelector: <RenderSelectorComp />,
  renderEditor: <RenderEditorComp />,
  renderHighlight: <RenderHighlightComp />,
  renderContent: <RenderContentComp />,
  renderOverlay: <RenderOverlayComp />,
}
export default DefaultPropsComp
