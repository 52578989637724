import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@mui/styles'
import axios from 'axios'
import get from 'lodash/get'
import moment from 'moment'
import ProjectTypeForm from './ProjectTypeForm'
import NewProjectForm from './NewProjectForm'
import ModalDialog from '../../ModalDialog/ModalDialog'
import apiConfig from '../../../config/apiConfig'
import {
  memoizeProjectType,
  memoizeFormData,
  isExtantProject,
  createProjectTypeDictionary,
  convertFormToAPIData,
} from '../../../helpers/ProjectsHelper'
import { makeSelectAuthData } from '../../../store/auth/selector'
import firefly from '../../../analytics/firefly'

// CONSTANTS
const KEY = apiConfig.key
const BUILD_FIELDS_URL = apiConfig.projects.buildProjectFieldsUrl
const PROJECT_TYPE_SELECTION = 'Select Project Type'
const CREATE_NEW_PROJECT = 'Create New Project'

const styles = {
  projectTypeContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  projectField: {
    width: '40%',
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    borderTop: '1px solid #6f7b6f57',
    marginTop: '20px',
  },
}

export class ProjectFormContainer extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      projectTypes: [],
      formData: {},
      projectInitiator: {},
      currentStep: PROJECT_TYPE_SELECTION,
      projectTypeDictionary: {},
    }
  }

  componentDidMount() {
    const url = `${apiConfig.projects.projecTypeUrl}?key=${KEY}`
    axios.get(url).then((res) => {
      const projectTypes = memoizeProjectType(res.data)
      const projectTypeDictionary = createProjectTypeDictionary(res.data)
      this.setState({ projectTypes, projectTypeDictionary })
    })
  }

  resetForm = () => {
    this.setState({
      currentStep: PROJECT_TYPE_SELECTION,
      formData: {},
      projectInitiator: {},
    })
  }

  fetchBrands = async (name = '') => {
    const brandsUrl = `${apiConfig.projects.brandsUrl}?brand_name=${name}&key=${apiConfig.key}`
    const result = await axios.get(brandsUrl)
    const data = await result.data
    return data
  }

  onSubmitProjectType = (values = {}) => {
    const { projectId = '', projectType = '', projectUUID = '' } = values
    const validProjectId = projectId
      ? [
          'Beauty Boxes',
          'Target.com Editorial Refresh',
          'Weekly Ad',
          'Target.com Editorial',
        ].includes(projectType)
        ? new Date(moment(projectId).format('YYYY-MM-DD')).toISOString()
        : projectId
      : ''
    const requestData = {
      project_id: validProjectId,
      project_type: projectType,
      project_uuid: projectUUID,
    }
    axios
      .post(`${BUILD_FIELDS_URL}?key=${KEY}`, requestData)
      .then((result = {}) => {
        const { data = [] } = result
        const memoizedFormFieldData = memoizeFormData(data[0])
        const { projectInitiator = {} } = memoizedFormFieldData
        //Check if project exists
        isExtantProject(memoizedFormFieldData)
          ? this.setState({
              projectInitiator,
            })
          : this.setState({
              formData: memoizedFormFieldData,
              currentStep: CREATE_NEW_PROJECT,
            })
      })
      .catch((e = {}) => {
        throw new Error(e.message)
      })
  }

  handleNewProjectFormSubmit = (values = {}) => {
    const { history = {}, auth = {}, router = {} } = this.props
    const { lanId = '' } = auth
    const { projectTypeDictionary } = this.state
    const url = apiConfig.projects.createProjectUrl
    const data = convertFormToAPIData({
      ...values,
      projectTypeDictionary,
    })

    axios
      .post(`${url}?key=${KEY}`, data)
      .then((res) => {
        const projectUUID = get(res, 'data.project_uuid', '')
        if (projectUUID) {
          router.navigate(`/project/${projectUUID}/dashboard`)
        }
        firefly.trackProjectCreate(res.data, lanId)
      })
      .catch((e) => {
        throw new Error(e.message)
      })
  }

  render() {
    const {
      open = false,
      onClose = () => {},
      auth = {},
      classes = {},
    } = this.props
    const { firstName = '', lastName = '', lanId = '', email = '' } = auth
    const displayName = `${firstName}.${lastName}`
    const newProjectInitiator = {
      display_name: displayName,
      login_id: lanId,
      email_address: email,
      first_name: firstName,
      last_name: lastName,
    }

    const {
      projectTypes,
      formData,
      projectInitiator,
      currentStep,
      projectTypeDictionary,
    } = this.state

    return (
      <ModalDialog
        open={open}
        onClose={onClose}
        title="Create A Project"
        aria-labelledby="form-dialog-title"
      >
        {currentStep === PROJECT_TYPE_SELECTION && (
          <ProjectTypeForm
            onSubmit={this.onSubmitProjectType}
            projectTypes={projectTypes}
            projectInitiator={projectInitiator}
            classes={classes}
          />
        )}
        {currentStep === CREATE_NEW_PROJECT && (
          <NewProjectForm
            projectTypes={projectTypes}
            formData={formData}
            onSubmit={this.handleNewProjectFormSubmit}
            projectTypeDictionary={projectTypeDictionary}
            resetForm={this.resetForm}
            projectInitiator={newProjectInitiator}
          />
        )}
      </ModalDialog>
    )
  }
}

ProjectFormContainer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  auth: PropTypes.shape({
    lanId: PropTypes.string,
    firstName: PropTypes.string,
    LastName: PropTypes.string,
    email: PropTypes.string,
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
}

const mapStateToProps = (state = {}) => ({ auth: makeSelectAuthData()(state) })

export default connect(mapStateToProps)(
  withStyles(styles)(ProjectFormContainer),
)
