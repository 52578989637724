import { createSelector } from 'reselect'
import {
  AD_INTERNAL_RIGHTS,
  AD_GROPS,
  AD_PUBLISHING_SEARCH,
  AD_PUBLISHING_PUBLISH,
} from '../../constants/adGroups'
import {
  isFunctionalTestAdminUser,
  fetchFunctionalTestUserEmail,
  fetchFunctionalTestUserData,
  fetchFunctionalTestUserLoginId,
} from '../../helpers/UtilityHelper'

const selectAuthData =
  () =>
  ({ auth = {} } = {}) =>
    auth
const selectUserEmail =
  () =>
  ({ auth = {} } = {}) =>
    auth.email || fetchFunctionalTestUserEmail() || ''
const selectUserId =
  () =>
  ({ auth = {} } = {}) =>
    auth.lanId || fetchFunctionalTestUserLoginId() || ''
const selectIsAuthorized =
  () =>
  ({ auth = {} } = {}) =>
    auth.isAuthorized
// const selectAccessibleModules = () => ({ auth = {} }) => auth.accessibleModules
// const selectLoginUserAdGroupData = () => ({ auth = {} }) =>
//   auth.loginUserAdGroupData
// const selectIsProvisionedUser = () => ({ auth = {} }) => auth.isProvisionedUser
const selectIsAdGroupSuperAdmin =
  () =>
  ({ auth = {} }) =>
    auth.isAdGroupSuperAdmin || !!isFunctionalTestAdminUser()
const selectMemberOf =
  () =>
  ({ auth = {} }) =>
    auth.memberOf
// const selectIsInternalUser = () => ({ auth = {} }) => auth.isInternalUser
const selectUserCompany =
  () =>
  ({ auth = {} }) =>
    auth.company

const selectIsLibrarian =
  () =>
  ({ auth = {} }) =>
    auth.isLibrarian

// const makeSelectIsMetadataRightslUser = () =>
//   createSelector(selectMemberOf(), (userGroups = []) =>
//     userGroups.includes(AD_INTERNAL_RIGHTS)
//   )

// const makeSelectIsPublishSearchUser = () =>
//   createSelector(selectMemberOf(), (userGroups = []) =>
//     userGroups.includes(AD_PUBLISHING_SEARCH)
//   )

// const makeSelectIsPublishUser = () =>
//   createSelector(selectMemberOf(), (userGroups = []) =>
//     userGroups.includes(AD_PUBLISHING_PUBLISH)
//   )

const makeSelectAuthData = () =>
  createSelector(selectAuthData(), (authData = {}) => {
    if (authData.lanId || authData.email) return authData
    return fetchFunctionalTestUserData()
  })

const selectSearchSelectedItems =
  () =>
  ({ newSearch = {} } = {}) =>
    newSearch?.selectedItems

export {
  makeSelectAuthData,
  selectAuthData,
  selectUserEmail,
  selectUserId,
  selectIsAuthorized,
  selectIsAdGroupSuperAdmin,
  selectMemberOf,
  selectUserCompany,
  selectIsLibrarian,
  selectSearchSelectedItems,
}
