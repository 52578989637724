import {
  REQUEST_CHANNEL,
  RECEIVE_CHANNEL,
  CHANGE_SELECTED_TEAM,
  SET_MEMBERS_COUNT,
  SET_ASSETS_COUNT,
  SET_CHANNEL_MEMBERS,
  SET_CHANNEL_ASSETS,
  SET_CHANNEL_SORT_FIELD,
  SET_CHANNEL_ASSET_FILTER,
  SELECT_CHANNEL_ASSET_EVENT,
  SET_CHANNEL_SORT_DIRECTION,
  CHANGE_CHANNEL_ASSETS_PAGE,
  CHANGE_CHANNEL_ASSET_PAGE_SIZE,
  RESET_CHANNEL_ASSET_PARAMS,
  REMOVE_FROM_CHANNEL_PENDING_LIST,
  UPDATE_IMAGE_TO_CHANNEL_PENDING_LIST,
  ADD_IMAGE_TO_CHANNEL_PENDING_LIST,
} from './channelsActionType'
import {
  ACTIVATE_ADD_CHANNEL,
  DEACTIVATE_ADD_CHANNEL,
  SAVE_CHANNEL,
  SAVE_CHANNEL_SUCCESS,
  SAVE_CHANNEL_FAILURE,
} from './addChannelActionType'

export const initialState = {
  channels: [],
  isFetching: false,
  errorMessage: undefined,
  isErrorMessageShown: false,
  membersCount: 0,
  assetsCount: 0,
  selectedChannel: undefined,
  channelAssets: [],
  members: [],
  channelPendingFiles: [],
  selectedAssets: [],
  isAddChannelActive: false,
  sortField: 'CREATED_AT',
  sortDirection: 'DESC',
  assetFilter: '',
  currentAssetPage: 0,
  defaultAssetPageSize: 10,
}
export default function channelPublish(state = initialState, action = {}) {
  const { channelPendingFiles = [] } = state
  const { payload = {} } = action
  const {
    membersCount = Number(0),
    assetsCount = Number(0),
    members = [],
    assets = [],
    channels = [],
  } = payload
  switch (action.type) {
    case REQUEST_CHANNEL: {
      const newState = {
        ...state,
        isFetching: true,
        channels: [],
      }
      return newState
    }
    case RECEIVE_CHANNEL: {
      const newState = {
        ...state,
        isFetching: false,
        channels: channels,
      }
      return newState
    }
    case CHANGE_SELECTED_TEAM: {
      const { payload = {} } = action
      const { selectedChannel = {} } = payload
      const newState = {
        ...state,
        selectedChannel: selectedChannel,
      }
      return newState
    }
    case ACTIVATE_ADD_CHANNEL: {
      const newState = {
        ...state,
        isAddChannelActive: true,
      }
      return newState
    }
    case DEACTIVATE_ADD_CHANNEL: {
      const newState = {
        ...state,
        isAddChannelActive: false,
      }
      return newState
    }
    case SAVE_CHANNEL: {
      const newState = {
        ...state,
        isFetching: true,
      }
      return newState
    }
    case SAVE_CHANNEL_SUCCESS: {
      const { payload } = action
      const { channel = {} } = payload
      const { user_count = Number(0), asset_count = Number(0) } = channel
      const newState = {
        ...state,
        isFetching: false,
        channels: [...state.channels, channel],
        selectedChannel: channel,
        membersCount: user_count,
        assetsCount: asset_count,
      }
      return newState
    }
    case SAVE_CHANNEL_FAILURE: {
      const { payload } = action
      const newState = {
        ...state,
        isFetching: false,
        errorMessage: payload.errorMessage,
        isErrorMessageShown: true,
      }
      return newState
    }
    case SET_MEMBERS_COUNT: {
      return {
        ...state,
        membersCount: membersCount,
      }
    }
    case SET_ASSETS_COUNT: {
      return {
        ...state,
        assetsCount: assetsCount,
      }
    }
    case SET_CHANNEL_MEMBERS: {
      return {
        ...state,
        members: members,
      }
    }
    case SET_CHANNEL_ASSETS: {
      return {
        ...state,
        channelAssets: assets,
      }
    }
    case SET_CHANNEL_SORT_FIELD: {
      const { payload = {} } = action
      const newState = {
        ...state,
        sortField: payload,
      }
      return newState
    }
    case SET_CHANNEL_SORT_DIRECTION: {
      const { payload = {} } = action
      const newState = {
        ...state,
        sortDirection: payload,
      }
      return newState
    }
    case CHANGE_CHANNEL_ASSET_PAGE_SIZE: {
      const { payload = {} } = action
      const { defaultAssetPageSize = Number(0) } = payload
      const newState = {
        ...state,
        defaultAssetPageSize: defaultAssetPageSize,
      }
      return newState
    }
    case CHANGE_CHANNEL_ASSETS_PAGE: {
      const { payload = {} } = action
      const { currentPage = Number(0) } = payload
      const newState = {
        ...state,
        currentAssetPage: currentPage,
      }
      return newState
    }
    case SET_CHANNEL_ASSET_FILTER: {
      const { payload = {} } = action
      const newState = {
        ...state,
        assetFilter: payload,
      }
      return newState
    }
    case SELECT_CHANNEL_ASSET_EVENT: {
      const { payload = {} } = action
      const { selectedAssets = [] } = payload
      const newState = {
        ...state,
        selectedAssets: selectedAssets,
      }
      return newState
    }
    case RESET_CHANNEL_ASSET_PARAMS: {
      const newState = {
        ...state,
        selectedAssets: [],
        channelAssets: [],
        members: [],
        sortField: 'CREATED_AT',
        sortDirection: 'DESC',
        assetFilter: '',
        currentAssetPage: 0,
        defaultAssetPageSize: 10,
      }
      return newState
    }
    case REMOVE_FROM_CHANNEL_PENDING_LIST: {
      const { payload = {} } = action
      const { files = [] } = payload
      let pendingFilesList = [...channelPendingFiles]
      files.forEach((asset) => {
        const { job_id = '' } = asset
        const found = pendingFilesList.some(
          ({ job_id: currentFileId = '' }) => currentFileId === job_id
        )
        if (found) {
          pendingFilesList = pendingFilesList.filter(
            ({ job_id: pendingFileId = '' }) => pendingFileId !== job_id
          )
        }
      })
      const newState = {
        ...state,
        channelPendingFiles: pendingFilesList,
      }
      return newState
    }
    case UPDATE_IMAGE_TO_CHANNEL_PENDING_LIST: {
      const { payload = {} } = action
      const { pendingFile = {} } = payload
      const { id: pendingFileId = '', job_id = '' } = pendingFile
      const newState = {
        ...state,
        channelPendingFiles: channelPendingFiles.map((item) => {
          const { id: fileId = '' } = item
          if (fileId === pendingFileId) {
            return { ...pendingFile, id: job_id }
          } else {
            return item
          }
        }),
      }
      return newState
    }
    case ADD_IMAGE_TO_CHANNEL_PENDING_LIST: {
      const { payload = {} } = action
      const { pendingFile = {} } = payload
      const newState = {
        ...state,
        channelPendingFiles: [...channelPendingFiles, pendingFile],
      }
      return newState
    }
    default:
      return state
  }
}
