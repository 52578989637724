import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { isEqual } from 'lodash'
import { withStyles } from '@mui/styles'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  IconButton,
  Button,
  Radio,
} from '@mui/material'
import { Clear } from '@mui/icons-material'

import { praxis } from '../../config/themeConfig'
import {
  TASK_STATUS_COMPLETE,
  TASK_STATUS_IN_PROGRESS,
  TASK_STATUS_NEW,
} from '../../constants/tasks'
import { setTaskStatus } from '../../store/annotation/actions'
import { formatCSTDate } from '../../helpers/dateHelper'
import {
  selectDueDate,
  selectTaskStatus,
} from '../../store/annotation/selectors'

const styles = {
  buttonGroup: {
    display: 'flex',
  },
  title: {
    backgroundColor: praxis.darkBlue,
    padding: '16px',
  },
  titleSpan: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: praxis.lightestGrey,
  },
  confirmButton: {
    backgroundColor: praxis.darkBlue,
    color: praxis.lightestGrey,
  },
  dialogActions: {
    backgroundColor: praxis.lightestGrey,
    padding: '10px',
    margin: 0,
  },
  radioPadding: {
    alignItems: 'start',
    paddingTop: '16px',
  },
  paraGraphMargin: {
    marginTop: '10px',
  },
  headerMargin: {
    marginBottom: '0px',
  },
}

export class SetStatusModal extends React.PureComponent {
  constructor(props) {
    super(props)
    const { taskStatus = TASK_STATUS_NEW } = props
    this.state = {
      currentSelection: taskStatus,
    }
  }

  componentDidUpdate(prevProps) {
    const { taskStatus } = this.props
    if (!isEqual(prevProps.taskStatus, taskStatus)) {
      this.setState({ currentSelection: taskStatus })
    }
  }

  handleChange = (event) =>
    this.setState({ currentSelection: event.target.value })

  onConfirm = () => {
    const {
      onConfirm = () => {},
      setTaskStatus = () => {},
      taskStatus = TASK_STATUS_NEW,
    } = this.props
    const { currentSelection } = this.state
    if (currentSelection !== taskStatus) {
      setTaskStatus(currentSelection)
    }
    onConfirm()
  }

  render() {
    const {
      open = false,
      closeModal = () => {},
      classes = {},
      dueDate = new Date(),
    } = this.props
    const { currentSelection } = this.state
    const date = formatCSTDate(dueDate, 'L')
    const time = formatCSTDate(dueDate, 'LT')
    return (
      <Dialog open={open}>
        <DialogTitle className={classes.title}>
          <span className={classes.titleSpan}>
            Set Review Status
            <IconButton onClick={() => closeModal(() => {})} size="large">
              <Clear style={{ color: praxis.lightestGrey }} />
            </IconButton>
          </span>
        </DialogTitle>
        <DialogContent>
          <div className={classes.buttonGroup}>
            <Radio
              checked={currentSelection === TASK_STATUS_NEW}
              onChange={this.handleChange}
              value={TASK_STATUS_NEW}
              className={classes.radioPadding}
            />
            <div>
              <h3 className={classes.headerMargin}>No Status</h3>
              <p className={classes.paraGraphMargin}>
                You have not yet started to review these assets.
              </p>
            </div>
          </div>
          <div className={classes.buttonGroup}>
            <Radio
              checked={currentSelection === TASK_STATUS_IN_PROGRESS}
              onChange={this.handleChange}
              value={TASK_STATUS_IN_PROGRESS}
              className={classes.radioPadding}
            />
            <div>
              <h3 className={classes.headerMargin}>In Progress</h3>
              <p className={classes.paraGraphMargin}>
                You have started the review, but you have more changes to make.
                You can continue to make changes until{' '}
                <strong>
                  {date} at {time}
                </strong>
                .
              </p>
            </div>
          </div>
          <div className={classes.buttonGroup}>
            <Radio
              checked={currentSelection === TASK_STATUS_COMPLETE}
              onChange={this.handleChange}
              value={TASK_STATUS_COMPLETE}
              className={classes.radioPadding}
            />
            <div>
              <h3 className={classes.headerMargin}>Completed</h3>
              <p className={classes.paraGraphMargin}>
                You have started the review, and you are likely done making
                changes. You can still continue to make changes until{' '}
                <strong>
                  {date} at {time}
                </strong>
                .
              </p>
            </div>
          </div>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            data-cy="saveCloseButton"
            className={classes.confirmButton}
            onClick={this.onConfirm}
          >
            Save & Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

SetStatusModal.propTypes = {
  classes: PropTypes.object,
  open: PropTypes.bool,
  closeModal: PropTypes.func,
  onConfirm: PropTypes.func,
  setTaskStatus: PropTypes.func,
  dueDate: PropTypes.any,
  taskStatus: PropTypes.number,
}

const mapStateToProps = (state = {}) => {
  const dueDate = selectDueDate(state)
  const taskStatus = selectTaskStatus()(state)
  return {
    dueDate,
    taskStatus,
  }
}

export default connect(mapStateToProps, { setTaskStatus })(
  withStyles(styles)(SetStatusModal)
)
