import { Grid, Typography } from '@mui/material'
import AssetCard from './AssetCard'
import { useSearchContext } from '../SearchContext'
import AssetListContext from './AssetListContext'
import { isVideoAsset } from '../../../utils/utils'

const AssetList = () => {
  const {
    assetList = [],
    onSelection = () => {},
    selectedItems = [],
    selectAll,
  } = useSearchContext()

  const renderCardMedia = (asset = {}) => {
    const { fileType = '' } = asset
    const isVideoAssetCard = isVideoAsset(fileType)
    return !isVideoAssetCard ? (
      <AssetCard.AssetImageCard />
    ) : (
      <AssetCard.AssetVideoCard />
    )
  }

  const renderAssetCard = () => (
    <Grid container>
      {assetList?.map((asset) => {
        const isCardChecked = selectedItems?.some(
          (item) => item?.assetId === asset?.assetId,
        )
        return (
          <Grid
            item
            style={{
              display: 'flex',
              gap: '20px',
              overflow: 'wrap',
              padding: '10px',
            }}
          >
            <AssetCard
              asset={asset}
              isCardChecked={isCardChecked}
              mediaComponent={renderCardMedia(asset)}
            />
          </Grid>
        )
      })}
    </Grid>
  )

  const renderNoRecords = () => (
    <Typography variant="h5" gutterBottom>
      {'No Results Found'}
    </Typography>
  )

  return (
    <AssetListContext.Provider
      value={{
        onSelection: onSelection,
        selectedItems: selectedItems,
        selectAll: selectAll,
      }}
    >
      <Grid container direction={'column'}>
        {assetList?.length ? (
          <Grid item>{renderAssetCard()}</Grid>
        ) : (
          <Grid item>{renderNoRecords()}</Grid>
        )}
      </Grid>
    </AssetListContext.Provider>
  )
}

export default AssetList
