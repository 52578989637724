import React, { useContext } from 'react'
import { makeStyles } from '@mui/styles'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableSortLabel from '@mui/material/TableSortLabel'
import Checkbox from '@mui/material/Checkbox'
import Box from '@mui/material/Box'
import { visuallyHidden } from '@mui/utils'
import { CustomTableContext } from './CustomTable'

const useStyles = makeStyles((theme) => ({
  stickyColumns: {
    position: 'sticky',
    left: 0,
  },
  tableHeaderCell: {
    background: 'darkgrey',
    padding: '5px',
  },
  tableHeaderRow: {
    height: ({ tableHeaderRowHeight }) => tableHeaderRowHeight,
  },
}))

function CustomTableHead() {
  const { tableState = {}, tableMethods = {} } = useContext(CustomTableContext)
  const {
    order = '',
    orderBy = '',
    colMap = [],
    tableConfig = {},
    data = [],
    selected = [],
    identifiers = [],
  } = tableState
  const {
    orderByHandler = () => {},
    orderHandler = () => {},
    checkboxHandler = () => {},
  } = tableMethods
  const { headerCheckBoxRequired = false, tableHeaderRowHeight = '' } =
    tableConfig
  const classes = useStyles({ tableHeaderRowHeight })
  const onSelectAllClick = (event) => {
    if (event.target.checked) {
      checkboxHandler(identifiers)
    } else {
      checkboxHandler([])
    }
  }

  const onSortClick = (property) => (event) => {
    const isAsc = orderBy === property && order === 'asc'
    orderHandler(isAsc ? 'desc' : 'asc')
    orderByHandler(property)
  }
  return (
    <TableHead>
      <TableRow className={classes.tableHeaderRow}>
        {headerCheckBoxRequired && (
          <TableCell
            padding="checkbox"
            className={classes.tableHeaderCell}
            key="checkbox"
          >
            <Checkbox
              color="primary"
              indeterminate={
                selected.length > 0 && selected.length < data.length
              }
              checked={data.length > 0 && selected.length === data.length}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all items' }}
            />
          </TableCell>
        )}

        {colMap.map((colHeader, colIndex) => {
          const {
            isSortEnabled = false,
            label = '',
            id = '',
            disablePadding = false,
            numeric = false,
          } = colHeader
          return isSortEnabled ? (
            <TableCell
              className={classes.tableHeaderCell}
              key={`tableHeadMap_${id}-${colIndex}`}
              align={numeric ? 'right' : 'left'}
              padding={disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === id ? order : false}
            >
              <TableSortLabel
                active={orderBy === id}
                direction={orderBy === id ? order : 'asc'}
                onClick={onSortClick(id)}
              >
                {label}
                {orderBy === id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ) : (
            <TableCell className={classes.tableHeaderCell} key={label}>
              {label}
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  )
}

export default CustomTableHead
