import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import {
  Grid,
  Button,
  IconButton,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
  FormControl,
  InputLabel,
} from '@mui/material'
import { Rotate90DegreesCcw, ArrowBack, Compare } from '@mui/icons-material'
import { praxis } from '../../config/themeConfig'
import { DEFAULT_SCALE } from '../../constants/annotation'
import {
  TASK_STATUS_COMPLETE,
  TASK_STATUS_IN_PROGRESS,
  TASK_STATUS_NEW,
} from '../../constants/tasks'

const scales = {
  'Fit to screen': DEFAULT_SCALE,
  '50%': '50',
  '100%': '100',
  '150%': '150',
  '200%': '200',
  '250%': '250',
  '300%': '300',
  '350%': '350',
  '400%': '400',
  '600%': '600',
  '800%': '800',
}

const taskStatuses = {
  [TASK_STATUS_NEW]: 'No Status',
  [TASK_STATUS_IN_PROGRESS]: 'In Progress',
  [TASK_STATUS_COMPLETE]: 'Complete',
}

const styles = {
  colorSwitchBase: {
    color: 'grey',
    '& + $colorBar': {
      backgroundColor: praxis.contrastText,
    },
    '&.Mui-colorChecked': {
      color: praxis.lighterBlue,
      '& + $colorBar': {
        backgroundColor: praxis.contrastText,
      },
    },
  },
  colorBar: {},
  colorChecked: {},
  root: {
    height: '50px',
    backgroundColor: praxis.graphite,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 0,
  },
  whiteText: {
    color: praxis.contrastText,
  },
  darkText: {
    color: 'black',
  },
  rotateButtonClass: {
    color: praxis.contrastText,
    width: '35px',
  },
  zoomSelector: {
    color: praxis.contrastText,
    borderBottom: '1px solid ' + praxis.contrastText,
    width: '120px',
    '&:focus': {
      backgroundColor: 'rgba(0,0,0,0.05) !important',
    },
  },
  zoomSelectorUnderline: {
    '&:after': {
      borderBottom: '1px solid ' + praxis.contrastText + ' !important',
    },
  },
  statusSelector: {
    color: 'black',
    width: '180px',
    fontSize: '14px',
  },
  selectLabelPadding: {
    fontSize: '15px',
    fontWeight: '400',
  },
  formControl: {
    background: 'white',
    height: '46px',
    borderRadius: '6px',
    flexDirection: 'row !important',
  },
  completeStatusSupportText: {
    padding: '0 0 4px 16px',
    fontSize: '10px',
    color: praxis.lightGrey,
  },
  completeStatusSupportTextSelected: {
    padding: '0 0 4px 16px',
    fontSize: '10px',
    color: praxis.lightGrey,
    background: 'rgba(0, 0, 0, 0.08)',
  },
  backButton: {
    color: praxis.contrastText,
    paddingTop: '10px',
  },
}

const StyledFormControlLabel = withStyles({
  label: {
    color: praxis.contrastText,
  },
  root: {
    padding: '8px',
    margin: 'unset',
  },
})(FormControlLabel)

export const Toolbar = ({
  scale = DEFAULT_SCALE,
  onChangeAngle = () => {},
  onChangeScale = () => {},
  toggleHideAllAnnotation = () => {},
  toggleSplitView = () => {},
  hideAnnotationIds = [],
  classes = {},
  backToTasksPage = () => {},
  onChangeTaskStatus = () => {},
  taskStatus = TASK_STATUS_NEW,
}) => (
  <Grid item xs={12} className={classes.root}>
    <div>
      <Button
        data-cy="backButton"
        className={classes.backButton}
        onClick={backToTasksPage}
      >
        <ArrowBack />
      </Button>
      <FormControl variant="filled" className={classes.formControl}>
        <InputLabel
          id="demo-simple-select-filled-label"
          style={{ color: '#484848' }}
          className={classes.selectLabelPadding}
        >
          My Review Task Status
        </InputLabel>
        <Select
          variant="standard"
          data-cy="taskStatus"
          value={taskStatus}
          onChange={onChangeTaskStatus}
          classes={{
            icon: classes.darkText,
            root: classes.statusSelector,
          }}
        >
          {Object.keys(taskStatuses).map((key) => (
            <MenuItem data-cy={taskStatuses[key]} key={key} value={key}>
              {taskStatuses[key]}
            </MenuItem>
          ))}
          <div
            className={
              taskStatus === TASK_STATUS_COMPLETE
                ? classes.completeStatusSupportTextSelected
                : classes.completeStatusSupportText
            }
          >
            You can still make changes untill due date.
          </div>
        </Select>
      </FormControl>
    </div>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Button
        data-cy="sideBySideButton"
        className={classes.whiteText}
        onClick={toggleSplitView}
      >
        <Compare /> Side by Side
      </Button>
      {/* <IconButton data-cy="rotate90DegreesLeft" className={classes.rotateButtonClass} onClick={() => onChangeAngle('left')}><Rotate90DegreesCcw/></IconButton>
         <IconButton data-cy="rotate90DegreesRight" className={classes.rotateButtonClass} onClick={() => onChangeAngle('right')}><Rotate90DegreesCcw style={{ transform: 'scaleX(-1)' }} /></IconButton> */}
      {/* <Select
            data-cy="scales"
	          value={scale}
            onChange={onChangeScale}
            classes={{
              icon: classes.whiteText,
              root: classes.zoomSelector,
            }}
            className={classes.zoomSelectorUnderline}
          >
          {Object.keys(scales).map(key =>
 	            <MenuItem data-cy={key} key={scales[key]} value={scales[key]} >
 	              {key}
 	            </MenuItem>
 	          )}
        </Select> */}
      <StyledFormControlLabel
        control={
          <Switch
            data-cy="commentsSwitch"
            checked={hideAnnotationIds.length === 0}
            onChange={toggleHideAllAnnotation}
            classes={{
              switchBase: classes.colorSwitchBase,
              checked: classes.colorChecked,
              track: classes.colorBar,
            }}
          />
        }
        label="All Comments"
      />
    </div>
  </Grid>
)

Toolbar.propTypes = {
  scale: PropTypes.string,
  onChangeAngle: PropTypes.func,
  onChangeScale: PropTypes.func,
  hideAnnotationIds: PropTypes.arrayOf(PropTypes.number),
  toggleHideAllAnnotation: PropTypes.func,
  classes: PropTypes.object,
  toggleSplitView: PropTypes.func,
  backToTasksPage: PropTypes.func,
  onChangeStatus: PropTypes.func,
  onChangeTaskStatus: PropTypes.func,
  taskStatus: PropTypes.number,
}

export default withStyles(styles)(Toolbar)
