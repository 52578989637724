import * as React from 'react'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import { debounce } from '@mui/material/utils'
import apiConfig from '../../config/apiConfig'
import { isMetadataFormEdited } from '../../store/metadata/actionCreator'
import { getNonProductBrand } from '../../helpers/MetadataHelper'

export default function BrandAutoComplete({
  productInformation = [],
  autoCompleteLabel = '',
  updateSelectedValue = () => {},
}) {
  const dispatch = useDispatch()
  const nonProductInfo = getNonProductBrand(productInformation || [])
  const { brandName: nonProductBrandName = null } = nonProductInfo || {}
  const [selectedValue, setSelectedValue] = React.useState(nonProductBrandName)
  const [inputValue, setInputValue] = React.useState('')
  const [options, setOptions] = React.useState([])
  const [loading, setLoading] = React.useState(false)

  const fetchBrands = async (name = '') => {
    const brandsUrl = `${
      apiConfig.projects.brandsUrl
    }?brand_name=${encodeURIComponent(name)}&key=${apiConfig.key}`
    const result = await axios.get(brandsUrl)
    const data = await result.data
    return data
  }

  const fetch = React.useMemo(
    () =>
      debounce(async ({ input }, callback) => {
        if (input.length > 2) {
          setLoading(true)
          const { brand = [] } = await fetchBrands(input)
          const brandNames = brand.map((brandItem) => brandItem.brand_name)
          callback(brandNames)
        } else {
          callback([])
        }
      }, 400),
    []
  )

  React.useEffect(() => {
    let active = true

    if (inputValue === '') {
      setOptions(selectedValue ? [selectedValue] : [])
      return undefined
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = []

        if (selectedValue) {
          newOptions = [selectedValue]
        }

        if (results?.length) {
          newOptions = [...newOptions, ...results]
        }

        React.startTransition(() => {
          setOptions(newOptions)
          setLoading(false)
        })
      }
    })

    return () => {
      active = false
    }
  }, [selectedValue, inputValue, fetch])

  React.useEffect(() => {
    if (selectedValue !== nonProductBrandName) {
      updateSelectedValue(selectedValue)
    } else {
      dispatch(isMetadataFormEdited(false))
    }
  }, [selectedValue])

  return (
    <Autocomplete
      sx={{ width: 300 }}
      filterOptions={(x) => x}
      options={options}
      loading={loading}
      autoComplete
      includeInputInList
      filterSelectedOptions
      noOptionsText={
        inputValue.length > 2
          ? 'No Brand Names Found'
          : 'Type atleast 3 characters'
      }
      value={selectedValue}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options)
        setSelectedValue(newValue)
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={autoCompleteLabel}
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
              </>
            ),
          }}
        />
      )}
    />
  )
}
