import { remove, first, isEmpty } from 'lodash'
import {
  SEARCH_DEFAULT_FACETS,
  SEARCH_FILTER_FACET_MAP,
  UPLOAD_DATE_FILTER_MAP,
  FACET_UPLOAD_DATE_NAME,
  TODAY,
  YESTERDAY,
  LAST_SEVEN_DAYS,
  LAST_THIRTY_DAYS,
  FACET_UPLOAD_DATE_VALUE,
  FACET_COLOR_KEY_VALUE,
  FACET_CUSTOM_COLOR_KEY_VALUE,
  FACET_CUSTOM_HSV_COLOR_KEY_VALUE,
} from '../../constants/search'
import { convertShortDate } from '../../helpers/dateHelper'
import { hexToHSV } from '../../helpers/SearchHelper'

const getUploadDateFilterValue = (filterValue = TODAY) => {
  const todayDate = new Date()
  if (filterValue === YESTERDAY) {
    return convertShortDate(new Date().setDate(todayDate.getDate() - 1))
  } else if (filterValue === LAST_SEVEN_DAYS) {
    return convertShortDate(new Date().setDate(todayDate.getDate() - 7))
  } else if (filterValue === LAST_THIRTY_DAYS) {
    return convertShortDate(new Date().setDate(todayDate.getDate() - 30))
  }

  return convertShortDate(todayDate)
}

const getModifiedFilters = ({
  actionType,
  appliedFilters,
  filterName,
  filterValue,
}) => {
  const filterNameKey = SEARCH_DEFAULT_FACETS[filterName]
  let modifiedFilters = { ...appliedFilters }
  let appliedFilterValue = filterValue
  const selectedFilter = modifiedFilters[filterNameKey] || []
  delete modifiedFilters[FACET_CUSTOM_HSV_COLOR_KEY_VALUE]

  if (actionType === 'UPDATE') {
    if (Array.isArray(appliedFilterValue)) {
      modifiedFilters[filterNameKey] = appliedFilterValue
    } else {
      modifiedFilters[filterNameKey] = [appliedFilterValue]
    }
  } else if (actionType === 'UPDATECOLOR') {
    delete modifiedFilters[FACET_CUSTOM_COLOR_KEY_VALUE]
    delete modifiedFilters[FACET_COLOR_KEY_VALUE]
    delete modifiedFilters[FACET_CUSTOM_HSV_COLOR_KEY_VALUE]
    if (Array.isArray(appliedFilterValue)) {
      modifiedFilters[filterNameKey] = appliedFilterValue
    } else {
      modifiedFilters[filterNameKey] = [appliedFilterValue]
    }
    if (filterNameKey === FACET_CUSTOM_COLOR_KEY_VALUE) {
      modifiedFilters[FACET_CUSTOM_HSV_COLOR_KEY_VALUE] =
        hexToHSV(appliedFilterValue)
    }
  } else if (selectedFilter.length > 0) {
    let updatedFilters = selectedFilter.slice()
    if (actionType === 'REMOVE') {
      if (filterNameKey === FACET_UPLOAD_DATE_VALUE) {
        updatedFilters = []
      } else {
        remove(updatedFilters, (value) => value === appliedFilterValue)
        modifiedFilters[filterNameKey] = updatedFilters
        if (filterNameKey === FACET_CUSTOM_COLOR_KEY_VALUE) {
          delete modifiedFilters[FACET_CUSTOM_HSV_COLOR_KEY_VALUE]
        }
      }
    } else {
      updatedFilters.push(appliedFilterValue)
    }
    if (updatedFilters.length > 0) {
      modifiedFilters[filterNameKey] = updatedFilters
    } else {
      delete modifiedFilters[filterNameKey]
    }
  } else if (actionType === 'ADD') {
    if (Array.isArray(appliedFilterValue)) {
      modifiedFilters[filterNameKey] = appliedFilterValue
    } else {
      modifiedFilters[filterNameKey] = [appliedFilterValue]
    }
  }
  if (
    Object.entries(modifiedFilters).length > 0 &&
    Object.keys(modifiedFilters).indexOf(FACET_CUSTOM_COLOR_KEY_VALUE) !== ''
  ) {
    modifiedFilters[FACET_CUSTOM_HSV_COLOR_KEY_VALUE] = hexToHSV(
      first(modifiedFilters[FACET_CUSTOM_COLOR_KEY_VALUE] || [])
    )
  }
  return modifiedFilters
}

const getModifiedFacets = ({
  appliedFilters = {},
  newFacets = {},
  prevFacets = {},
}) => {
  const modifiedFacets = {
    ...newFacets,
    [FACET_UPLOAD_DATE_NAME]: UPLOAD_DATE_FILTER_MAP,
  }
  const appliedFilterKeys = Object.keys(appliedFilters)
  if (appliedFilterKeys.length) {
    appliedFilterKeys.map((filterKey) => {
      const filterValue = SEARCH_FILTER_FACET_MAP[filterKey]
      modifiedFacets[filterValue] = prevFacets[filterValue]
      return true
    })
  }
  return modifiedFacets
}

const getObjectKeyCount = (targetObject = {}) =>
  Object.keys(targetObject).length

const makeUserDetails = (auth = {}) => {
  const { email, firstName, lanId, lastName } = auth
  return {
    email,
    firstName,
    loginId: lanId,
    lastName,
  }
}

const getModifiedAssets = (
  downloadAssets = {},
  actionType = 'ADD',
  downloadAsset = {}
) => {
  if (actionType === 'ADD') {
    return { ...downloadAssets, ...downloadAsset }
  } else {
    const removeAssetKeys = Object.keys(downloadAsset)
    if (removeAssetKeys.length > 0) {
      delete downloadAssets[removeAssetKeys[0]]
    }
    return downloadAssets
  }
}

const formatAssetMetadata = (assets) => {
  const {
    metadata = {},
    asset_id = '',
    render_urls: { preview_asset_url = '' },
  } = assets
  const formattedAsset = {}
  if (metadata.file_metadata) {
    formattedAsset.fileName = metadata.file_metadata.file_name
  }
  formattedAsset.assetId = asset_id || ''
  formattedAsset.fileUrl = preview_asset_url
  return formattedAsset
}

const formatSearchResults = (searchResults = []) => {
  const searchResultsMap = {}

  if (searchResults.length === 0) {
    return {}
  }

  // add each asset to assetMap with id as key
  searchResults.forEach((asset = {}) => {
    const { assets = '' } = asset
    const { metadata = {}, asset_id = '' } = assets
    if (!isEmpty(metadata)) {
      searchResultsMap[asset_id] = formatAssetMetadata(assets)
    }
  })

  return searchResultsMap
}

export {
  getUploadDateFilterValue,
  getModifiedAssets,
  getModifiedFacets,
  getModifiedFilters,
  getObjectKeyCount,
  makeUserDetails,
  formatSearchResults,
}
