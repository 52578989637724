export default {
  light: '#f05545',
  main: '#930000',
  dark: '#1a6ec5',
  contrastText: '#FFF',
  lightGrey: '#8d8d8d',
  lighterGrey: '#BDBDBD',
  lightestGrey: '#EFEFEF',
  mediumGrey: '#666',
  midGrey: '#484848',
  darkGrey: '#333',
  lightBlue: '#1a6ec5',
  lighterBlue: '#5471d2',
  darkBlue: '#0d469f',
  darkerBlue: '#002171',
  sideNavHover: 'rgba(158,158,158,.2)',
  lighterYellow: '#FFFFB0',
  lightYellow: '#FFE57F',
  darkYellow: '#CAB350',
  bostonRed: '#CC0000',
  paleYellow: '#FFFFDA',
  graphite: '#212121',
  primaryBlue: '#0D46A0',
  primaryDarkBlue: '#002071',
  primaryLightBlue: '#5471d2',
  primaryGreen: '#087F23',
  dodgerBlue: '#2196f3',
  whiteSmoke: '#F7F7F7',
}
