import idx from 'idx'

export const makeSelectBoards =
  () =>
  ({ boards = {} }) =>
    boards
export const selectAssetsByBoard =
  (boardId) =>
  ({ boards = {} }) =>
    idx(boards, (_) => _.boardAssets[boardId]) || {}
export const selectIsAssetAdded =
  () =>
  ({ boards = {} }) =>
    boards.isAssetAdded
export const selectBoard =
  () =>
  ({ boards = {} }) =>
    boards.board
export const selectBoards =
  () =>
  ({ boards = {} }) =>
    boards.boardList
export const selectBoardAssets =
  () =>
  ({ boards = {} }) =>
    boards.boardAssets
export const selectBoardCollaborators =
  () =>
  ({ boards = {} }) =>
    boards.boardCollaborators
export const boardsIsFetching =
  () =>
  ({ boards = {} }) =>
    boards.isFetching
export const selectBoardDownloadAssets =
  () =>
  ({ boards = {} }) =>
    boards.boardDownloadAssets
export const selectIsRedirectToBoardList =
  () =>
  ({ boards = {} }) =>
    boards.isRedirectToBoardList
export const selectIsCreateBoardModalOpen =
  () =>
  ({ boards = {} }) =>
    boards.isCreateBoardModalOpen
