//

import * as React from 'react'

class BodyClass extends React.Component {
  static defaultProps = {
    children: null,
  }

  componentDidMount() {
    addOrRemoveClassNames(this.props.classNames, true)
  }

  componentWillUnmount() {
    addOrRemoveClassNames(this.props.classNames)
  }

  render() {
    return this.props.children
  }
}

/**
 * Add or remove classes to the body element's classList.
 *
 * @param  {String|Array} classNames - Class or classes to add or remove.
 * @param  {Boolean} add - If true, adds classNames. Otherwise, removes them.
 */
function addOrRemoveClassNames(classNames, add) {
  if (document && document.body) {
    const body = document.body

    if (typeof classNames === 'string') {
      classNames = classNames.split(' ')
    }

    classNames.forEach((className) => {
      if (add) {
        body.classList.add(className)
      } else {
        body.classList.remove(className)
      }
    })
  }
}

export default BodyClass
