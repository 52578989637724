import React from 'react'
import {
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  Tooltip,
  Button,
  IconButton,
} from '@mui/material'
import { withStyles } from '@mui/styles'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import SearchIcon from '@mui/icons-material/Search'
import Clear from '@mui/icons-material/Clear'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'

const styles = (theme) => ({
  sort: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    width: '326px',
  },
  filterInput: {
    width: '50%',
  },
  numberSelected: {
    padding: '10px',
    marginRight: '24px',
    fontSize: '1rem',
    fontFamily: 'NewTargetica, Helvetica, Arial, sans-serif',
    fontWeight: 400,
    lineHeight: 1.75,
    color: 'rgba(0, 0, 0, 0.54)',
  },
  downloadButton: {
    marginLeft: '24px',
    maxHeight: '40px',
    marginTop: '4px',
    backgroundColor: '#188295',
    '&:hover': {
      backgroundColor: '#115E6C',
    },
  },
})

function DetailAssetHeader(props) {
  const {
    onSelectAllClick = () => {},
    numSelected = Number(0),
    rowCount = Number(0),
    classes = {},
    assetFilter = '',
    sortDirection = '',
    sortField = '',
    requestAssetsEvent = () => {},
    setSortDirection = () => {},
    setSortField = () => {},
    publishAssetsLength = Number(0),
    downloadSelectedAssets = () => {},
    changeAssetFilter = () => {},
    searchFilter = () => {},
    assetsCount = Number(0),
  } = props

  function handleSetSortDirection(sortDirection) {
    if (sortDirection === 'ASC') {
      return 'DESC'
    }
    if (sortDirection === 'DESC') {
      return 'ASC'
    }
  }

  function sortColumn(
    rowCount = Number(0),
    sortField = '',
    sortDirection = 'ASC',
    assetFilter = ''
  ) {
    setSortDirection(handleSetSortDirection(sortDirection))
    setSortField(sortField)
    requestAssetsEvent({
      pageSize: rowCount,
      sortDirection: handleSetSortDirection(sortDirection),
      sortBy: sortField,
      assetFilter,
    })
  }

  const onKeyUpInTextField = (e = {}) => {
    const { key: eventKey = '' } = e
    if (eventKey) {
      if (eventKey === 'Enter') {
        requestAssetsEvent({
          pageSize: rowCount,
          sortDirection: sortDirection,
          sortBy: sortField,
          assetFilter,
        })
      }
      if (eventKey === 'Backspace') {
        if (assetFilter === '') {
          requestAssetsEvent({
            pageSize: rowCount,
            sortDirection: sortDirection,
            sortBy: sortField,
            assetFilter,
          })
        }
      }
    }
  }

  const onClickClearFilter = () => {
    changeAssetFilter('')
    requestAssetsEvent({
      pageSize: rowCount,
      sortDirection,
      sortBy: sortField,
    })
  }

  return (
    <>
      <TableHead>
        <TableRow>
          <TableCell
            colSpan={2}
            style={{
              fontSize: '18px',
              paddingLeft: '18px',
            }}
          >
            Total Assets {assetsCount}
          </TableCell>
          <TableCell colSpan={6}>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <div className={classes.numberSelected}>
                {numSelected} Selected Assets
              </div>
              <TextField
                id="asset_filter"
                name="asset_filter"
                placeholder="File Name Filter"
                rows="1"
                value={assetFilter}
                onChange={(e) => changeAssetFilter(e.target.value)}
                variant="outlined"
                size={'small'}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon onClick={searchFilter} />
                    </InputAdornment>
                  ),
                  endAdornment:
                    assetFilter === '' ? null : (
                      <Tooltip title="Clear Filter">
                        <IconButton
                          onClick={() => {
                            onClickClearFilter()
                          }}
                          style={{ padding: 0 }}
                          size="large"
                        >
                          <Clear />
                        </IconButton>
                      </Tooltip>
                    ),
                }}
                style={{
                  width: '50%',
                  paddingTop: '4px',
                }}
                fullWidth
                onKeyUp={(e) => {
                  onKeyUpInTextField(e)
                }}
              />
              <Button
                onClick={downloadSelectedAssets}
                variant="contained"
                color="secondary"
                type="submit"
                disabled={numSelected < 1}
                className={classes.downloadButton}
              >
                Download Data (.xls)
              </Button>
            </div>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={
                numSelected > 0 &&
                (numSelected < rowCount || numSelected < publishAssetsLength)
              }
              checked={
                publishAssetsLength > 0 &&
                (numSelected === rowCount ||
                  numSelected === publishAssetsLength)
              }
              onChange={onSelectAllClick}
              color="primary"
            />
          </TableCell>
          <TableCell colSpan={1} style={{ width: '200px' }}>
            THUMBNAIL
          </TableCell>
          <TableCell colSpan={3}>
            <Tooltip title="Click to sort">
              <span
                className={classes.sort}
                role="presentation"
                onClick={() => {
                  sortColumn(
                    rowCount,
                    'ORIGINAL_FILE_NAME',
                    sortDirection,
                    assetFilter
                  )
                }}
              >
                {`File Name`.toUpperCase()}
                {sortField === 'ORIGINAL_FILE_NAME' &&
                  sortDirection === 'DESC' && <KeyboardArrowDownIcon />}
                {sortField === 'ORIGINAL_FILE_NAME' &&
                  sortDirection === 'ASC' && <KeyboardArrowUpIcon />}
              </span>
            </Tooltip>
          </TableCell>
          <TableCell>PUBLISH URL</TableCell>
          <TableCell style={{ width: '100px' }}>
            <Tooltip title="Click to sort">
              <span
                className={classes.sort}
                role="presentation"
                onClick={() => {
                  sortColumn(rowCount, 'STATUS', sortDirection, assetFilter)
                }}
              >
                {`Status`.toUpperCase()}
                {sortField === 'STATUS' && sortDirection === 'DESC' && (
                  <KeyboardArrowDownIcon />
                )}
                {sortField === 'STATUS' && sortDirection === 'ASC' && (
                  <KeyboardArrowUpIcon />
                )}
              </span>
            </Tooltip>
          </TableCell>
          <TableCell>
            <Tooltip title="Click to sort">
              <span
                className={classes.sort}
                role="presentation"
                onClick={() => {
                  sortColumn(rowCount, 'CREATED_AT', sortDirection, assetFilter)
                }}
              >
                {`Uploaded On`.toUpperCase()}
                {sortField === 'CREATED_AT' && sortDirection === 'DESC' && (
                  <KeyboardArrowDownIcon />
                )}
                {sortField === 'CREATED_AT' && sortDirection === 'ASC' && (
                  <KeyboardArrowUpIcon />
                )}
              </span>
            </Tooltip>
          </TableCell>
        </TableRow>
      </TableHead>
    </>
  )
}

export default withStyles(styles)(DetailAssetHeader)
