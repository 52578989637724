import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { isEqual } from 'lodash'
import { withStyles } from '@mui/styles'
import { Button } from '@mui/material'
import { Add, KeyboardArrowRight } from '@mui/icons-material'
import NewCommentBox from './NewCommentBox'
import CommentBox from './CommentBox'
import { clearCurrentMarkup } from '../../store/annotation/actions'
import { sortMyArray } from '../../helpers/UtilityHelper'
import { praxis } from '../../config/themeConfig'
import { commentPropType } from '../../constants/annotation'

const styles = {
  root: {
    backgroundColor: '#EFEFEF',
    padding: '16px',
  },
  card: {
    textAlign: 'left',
    marginBottom: '16px',
    padding: '16px',
  },
  blueTextButton: {
    color: praxis.darkBlue,
  },
  selectionBar: {
    display: 'flex',
    height: '40px',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 0,
  },
  pdfPages: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',
    maxHeight: '180px',
    overflowY: 'scroll',
  },
  pdfPage: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  pdfPageImage: {
    height: '50px',
    width: '50px',
    padding: '10px',
  },
  assetCard: {
    height: '50px',
  },
  commentsAndViewedBy: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}

export class CommentSidebar extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      showNewComment: false,
      showPdfPages: false,
    }
  }

  closeNewComment = () => {
    const { clearCurrentMarkup = () => {} } = this.props
    clearCurrentMarkup()
    this.setState({ showNewComment: false })
  }

  render() {
    const {
      classes = {},
      comments = [],
      secondaryComments = [],
      isFetchingComments = false,
      numberOfMarkups = 0,
      toggleHideAnnotation = () => {},
      hideAnnotationIds = [],
      isSecondary = false,
      isSelected = false,
      currentAsset = {},
      isProjectAdmin = false,
      isActiveReview = false,
      isReviewer = false,
      primaryPageNumber = 1,
      secondaryPageNumber = 1,
      toggleShowViewedBy = () => {},
      currentMarkup = {},
    } = this.props
    const { showNewComment } = this.state
    const pageNumber = !isSecondary ? primaryPageNumber : secondaryPageNumber
    const currentComments = (
      !isSecondary ? comments : secondaryComments
    ).filter(
      (comment) => comment.page_number === pageNumber && !comment.resolved
    )
    const resolvedComments = (
      !isSecondary ? comments : secondaryComments
    ).filter(
      (comment) => comment.page_number === pageNumber && comment.resolved
    )
    const totalComments = currentComments.length + resolvedComments.length

    return (
      <div className={classes.root}>
        <div className={classes.commentsAndViewedBy}>
          <h3>File</h3>
          <Button color="primary" onClick={toggleShowViewedBy}>
            Viewed By <KeyboardArrowRight />
          </Button>
        </div>
        <div style={{ wordBreak: 'break-all' }}>{currentAsset.asset_name}</div>
        {!isFetchingComments ? (
          <div>
            <h3>{totalComments} Comments</h3>
          </div>
        ) : (
          <h3>Loading...</h3>
        )}
        {(isProjectAdmin || (isReviewer && isActiveReview)) && (
          <Button
            data-cy="generalComment"
            variant="contained"
            onClick={() => this.setState({ showNewComment: true })}
            className={classes.blueTextButton}
          >
            <Add style={{ marginRight: '15px' }} /> Add General Comment
          </Button>
        )}
        {showNewComment && (isProjectAdmin || isActiveReview) && (
          <NewCommentBox
            assetInfo={currentAsset}
            closeNewComment={this.closeNewComment}
            isSecondary={isSecondary}
          />
        )}
        {!isEqual({}, currentMarkup) &&
          isSelected &&
          (isProjectAdmin || isActiveReview) && (
            <NewCommentBox
              assetInfo={currentAsset}
              closeNewComment={this.closeNewComment}
              isMarkupComment
              numberOfMarkups={numberOfMarkups}
              isSecondary={isSecondary}
            />
          )}
        {sortMyArray([...currentComments], 'created_date').map(
          (comment, key) => (
            <CommentBox
              assetInfo={currentAsset}
              isProjectAdmin={isProjectAdmin}
              isActiveReview={isActiveReview}
              isReviewer={isReviewer}
              key={key}
              comment={comment}
              toggleHideAnnotation={toggleHideAnnotation}
              hideAnnotationIds={hideAnnotationIds}
              isSecondary={isSecondary}
            />
          )
        )}
        {!isFetchingComments && resolvedComments.length > 0 && (
          <div style={{ marginTop: '35px', fontWeight: 600, fontSize: '18px' }}>
            {resolvedComments.length} Resolved
          </div>
        )}
        {sortMyArray([...resolvedComments], 'created_date').map(
          (comment, key) => (
            <CommentBox
              assetInfo={currentAsset}
              isProjectAdmin={isProjectAdmin}
              isActiveReview={isActiveReview}
              isReviewer={isReviewer}
              key={key}
              comment={comment}
              toggleHideAnnotation={toggleHideAnnotation}
              hideAnnotationIds={hideAnnotationIds}
              isSecondary={isSecondary}
            />
          )
        )}
      </div>
    )
  }
}

CommentSidebar.propTypes = {
  classes: PropTypes.object,
  currentAsset: PropTypes.object,
  currentMarkup: PropTypes.object,
  isFetchingComments: PropTypes.bool,
  isSecondary: PropTypes.bool,
  isSelected: PropTypes.bool,
  isReviewer: PropTypes.bool,
  isActiveReview: PropTypes.bool,
  isProjectAdmin: PropTypes.bool,
  toggleShowViewedBy: PropTypes.func,
  clearCurrentMarkup: PropTypes.func,
  toggleHideAnnotation: PropTypes.func,
  numberOfMarkups: PropTypes.number,
  primaryPageNumber: PropTypes.number,
  secondaryPageNumber: PropTypes.number,
  comments: PropTypes.arrayOf(commentPropType),
  hideAnnotationIds: PropTypes.arrayOf(PropTypes.number),
  secondaryComments: PropTypes.arrayOf(commentPropType),
}

const mapStateToProps = (state) => {
  const { annotation } = state
  const {
    comments,
    isFetchingComments,
    currentMarkup,
    secondaryComments,
    primaryPageNumber,
    secondaryPageNumber,
  } = annotation

  return {
    comments,
    isFetchingComments,
    currentMarkup,
    secondaryComments,
    primaryPageNumber,
    secondaryPageNumber,
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearCurrentMarkup,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(CommentSidebar))
