import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import InputLabel from '@mui/material/InputLabel'
import Grid from '@mui/material/Grid'
import FilterList from '@mui/icons-material/FilterList'
import SortIcon from '@mui/icons-material/Sort'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { groupsFilter, groupsSort } from '../../store/groups/actionCreator'

const styles = makeStyles((theme) => ({
  root: {
    direction: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  formControl: {
    margin: useTheme().spacing(),
    width: '90%',
  },
  selectEmpty: {
    marginTop: useTheme().spacing(2),
  },
  filerSec: {
    color: '#8d8d8d',
    top: '28px',
    position: 'relative',
  },
  filterGrid: {
    display: 'flex',
  },
}))

export class Filters extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.state = { value: 'groups_name' }
    this.handleSort = this.handleSort.bind(this)
    this.handleFilter = this.handleFilter.bind(this)
  }
  handleFilter(event) {
    if (event.currentTarget) {
      if (this.props.tabActive === 1) {
        this.props.shareFilter(event.currentTarget.value)
      } else {
        this.props.groupsFilter(event.currentTarget.value)
      }
    }
  }
  handleSort(event) {
    this.setState({ value: event.target.value })
    if (event.currentTarget) {
      if (this.props.tabActive === 1) {
        this.props.shareSort(event.currentTarget.value)
      } else {
        this.props.groupsSort(event.currentTarget.value)
      }
    }
  }
  render() {
    const { classes } = this.props
    return (
      <div className={classes.root}>
        <Grid container spacing={1} justifyContent="center" alignItems="center">
          <Grid item xs={12} md={6} className={classes.filterGrid}>
            <span>
              {' '}
              <SortIcon
                className={classes.filerSec}
                sx={{ display: { xs: 'none', sm: 'block' } }}
              />{' '}
            </span>
            <FormControl variant="standard" className={classes.formControl}>
              <InputLabel htmlFor="age-native-simple">
                Select Sort Option
              </InputLabel>
              <Select
                variant="standard"
                native
                value={this.state.age}
                onChange={this.handleSort}
                inputProps={{
                  name: 'age',
                  id: 'age-simple',
                  'data-cy': 'groupsSortBy',
                }}
              >
                <option value="" />
                <option value={'-create_date'}>Date Created</option>
                <option value={'create_date'}>Last Modified</option>
                <option value={'list_count'}>Number of People in Group</option>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} className={classes.filterGrid}>
            <span>
              <FilterList
                style={{ top: '48px' }}
                className={classes.filerSec}
                sx={{ display: { xs: 'none', sm: 'block' } }}
              />
            </span>
            <FormControl variant="outlined" className={classes.formControl}>
              <TextField
                variant="outlined"
                id="searchFilterText"
                label={
                  this.props.tabActive === 1
                    ? 'Filter Shared Groups'
                    : 'Filter Groups'
                }
                onChange={this.handleFilter.bind(this)}
                className={classes.textField}
                helperText="Filter within these results"
                margin="normal"
                type="search"
                inputProps={{ 'data-cy': 'groupsFilterTextField' }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </div>
    )
  }
}

Filters.propTypes = {
  classes: PropTypes.object.isRequired,
  tabActive: PropTypes.number,
  shareFilter: PropTypes.func.isRequired,
  groupsFilter: PropTypes.func.isRequired,
  shareSort: PropTypes.func.isRequired,
  groupsSort: PropTypes.func.isRequired,
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ groupsFilter, groupsSort }, dispatch)

const mapStateToProps = (state) => {
  const { groups } = state
  return {
    groups: groups,
  }
}
const MyComponent = (props) => {
  const classes = styles()
  return <Filters {...props} classes={classes} />
}
export default connect(mapStateToProps, mapDispatchToProps)(MyComponent)
