import {
  TOGGLE_PROJECT,
  TOGGLE_UPLOAD_MANAGER,
  UPLOAD_INITIATED,
  UPLOAD_START_CALL,
  UPLOAD_START_CALL_SUCCESS,
  UPLOAD_START_CALL_FAIL,
  CHUNK,
  CHUNK_SUCCESS,
  CHUNK_FAIL,
  UPLOAD_END_CALL,
  UPLOAD_END_CALL_FAIL,
  UPLOAD_END_CALL_SUCCESS,
  UPLOAD_JOB_STATUS_CALL,
  UPLOAD_JOB_STATUS_CALL_SUCCESS,
  UPLOAD_JOB_STATUS_CALL_FAIL,
} from './actionType'
import { getUpdatedFailedFiles } from './helper'

export const uploadState = {
  projects: {},
  uploadingChunksCount: 0,
  showUploadManager: false,
  uploadingFilesCount: 0,
  completedFilesCount: 0,
  failedFilesCount: 0,
}

export default function uploads(state = uploadState, action = {}) {
  let file
  let initialFiles = {}
  let uploadingFiles = {}
  let project = {}
  let failedFiles = {}
  let successFiles = {}
  let {
    completedFilesCount = 0,
    failedFilesCount = 0,
    uploadingFilesCount = 0,
    projects = {},
  } = state
  projects = { ...projects }
  switch (action.type) {
    case TOGGLE_UPLOAD_MANAGER:
      return {
        ...state,
        showUploadManager: !state.showUploadManager,
      }
    case UPLOAD_INITIATED: {
      let selectedProject = projects[action.project.project_uuid]
      if (selectedProject) {
        initialFiles = { ...selectedProject.initialFiles, ...action.files }
        uploadingFiles = { ...selectedProject.uploadingFiles }
        successFiles = { ...selectedProject.successFiles }
        failedFiles = { ...selectedProject.failedFiles }
      } else {
        initialFiles = action.files
      }
      projects[action.project.project_uuid] = {
        project_name: action.project.project_name,
        project_uuid: action.project.project_uuid,
        initialFiles,
        uploadingFiles,
        successFiles,
        failedFiles,
        isExpanded: true,
      }
      return {
        ...state,
        projects,
        showUploadManager: true,
      }
    }
    case TOGGLE_PROJECT:
      projects[action.projectUUID] = {
        ...projects[action.projectUUID],
        isExpanded: !projects[action.projectUUID].isExpanded,
      }
      return {
        ...state,
        projects,
      }
    case UPLOAD_START_CALL:
      file = {
        ...projects[action.projectUUID].initialFiles[action.fileName],
        uploadStatus: 'Not Started',
      }
      initialFiles = { ...projects[action.projectUUID].initialFiles }
      delete initialFiles[action.fileName]
      uploadingFiles = {
        ...projects[action.projectUUID].uploadingFiles,
        [action.fileName]: file,
      }
      project = {
        ...projects[action.projectUUID],
        initialFiles,
        uploadingFiles,
      }
      projects[action.projectUUID] = project
      uploadingFilesCount = uploadingFilesCount + 1
      return {
        ...state,
        projects,
        uploadingFilesCount,
      }
    case UPLOAD_START_CALL_SUCCESS:
      file = {
        ...projects[action.projectUUID].uploadingFiles[action.fileName],
        uploadStatus: 'Started',
      }
      uploadingFiles = {
        ...projects[action.projectUUID].uploadingFiles,
        [action.fileName]: file,
      }
      project = { ...projects[action.projectUUID], uploadingFiles }
      projects[action.projectUUID] = project
      return {
        ...state,
        projects,
      }
    case UPLOAD_START_CALL_FAIL:
      file = {
        ...projects[action.projectUUID].uploadingFiles[action.fileName],
        uploadStatus: 'Failed',
      }
      uploadingFiles = { ...projects[action.projectUUID].uploadingFiles }
      delete uploadingFiles[action.fileName]
      failedFiles = {
        ...projects[action.projectUUID].failedFiles,
        [action.fileName]: file,
      }
      project = { ...projects[action.projectUUID], uploadingFiles, failedFiles }
      projects[action.projectUUID] = project
      uploadingFilesCount = Object.keys(uploadingFiles || {}).length
      failedFilesCount = Object.keys(failedFiles || {}).length
      return {
        ...state,
        projects,
        uploadingFilesCount,
        failedFilesCount,
      }
    case CHUNK:
      return {
        ...state,
      }
    case CHUNK_SUCCESS:
      file = {
        ...projects[action.projectUUID].uploadingFiles[action.fileName],
        completedChunks: action.completedChunks,
      }
      uploadingFiles = {
        ...projects[action.projectUUID].uploadingFiles,
        [action.fileName]: file,
      }
      project = { ...projects[action.projectUUID], uploadingFiles }
      projects[action.projectUUID] = project
      return {
        ...state,
        projects,
      }
    case CHUNK_FAIL:
      file = {
        ...projects[action.projectUUID].uploadingFiles[action.fileName],
        uploadStatus: 'Failed',
      }
      uploadingFiles = { ...projects[action.projectUUID].uploadingFiles }
      delete uploadingFiles[action.fileName]
      failedFiles = {
        ...projects[action.projectUUID].failedFiles,
        [action.fileName]: file,
      }
      project = { ...projects[action.projectUUID], uploadingFiles, failedFiles }
      projects[action.projectUUID] = project
      uploadingFilesCount = Object.keys(uploadingFiles || {}).length
      failedFilesCount = Object.keys(failedFiles || {}).length
      return {
        ...state,
        projects,
        uploadingFilesCount,
        failedFilesCount,
        failedFiles,
      }
    case UPLOAD_END_CALL:
      return {
        ...state,
      }
    case UPLOAD_END_CALL_SUCCESS: {
      let { updatedFailedFiles, updatedFailedFilesCount } =
        getUpdatedFailedFiles(
          projects[action.projectUUID].failedFiles,
          action.fileName
        )
      project = {
        ...projects[action.projectUUID],
        failedFiles: updatedFailedFiles,
      }
      projects[action.projectUUID] = project
      return {
        ...state,
        failedFilesCount: updatedFailedFilesCount,
        projects,
      }
    }
    case UPLOAD_END_CALL_FAIL:
      file = {
        ...projects[action.projectUUID].uploadingFiles[action.fileName],
        uploadStatus: 'Failed',
      }
      uploadingFiles = { ...projects[action.projectUUID].uploadingFiles }
      delete uploadingFiles[action.fileName]
      failedFiles = {
        ...projects[action.projectUUID].failedFiles,
        [action.fileName]: file,
      }
      project = { ...projects[action.projectUUID], uploadingFiles, failedFiles }
      projects[action.projectUUID] = project
      failedFilesCount = Object.keys(failedFiles || {}).length
      return {
        ...state,
        projects,
        failedFilesCount,
      }
    case UPLOAD_JOB_STATUS_CALL:
      return {
        ...state,
      }
    case UPLOAD_JOB_STATUS_CALL_SUCCESS:
      file = {
        ...projects[action.projectUUID].uploadingFiles[action.fileName],
        uploadStatus: 'Success',
      }
      uploadingFiles = { ...projects[action.projectUUID].uploadingFiles }
      delete uploadingFiles[action.fileName]
      project = { ...projects[action.projectUUID], uploadingFiles }
      if (action.vaultUploadStatus !== 'ERROR') {
        successFiles = {
          ...projects[action.projectUUID].successFiles,
          [action.fileName]: file,
        }
        let {
          updatedFailedFiles: modifiedFailedFiles,
          updatedFailedFilesCount: modifiedFailedFilesCount,
        } = getUpdatedFailedFiles(
          projects[action.projectUUID].failedFiles,
          action.fileName
        )
        project = { ...project, failedFiles: modifiedFailedFiles, successFiles }
        failedFilesCount = modifiedFailedFilesCount
        completedFilesCount = completedFilesCount + 1
      }
      projects[action.projectUUID] = project
      uploadingFilesCount =
        uploadingFilesCount > 0 ? uploadingFilesCount - 1 : 0
      return {
        ...state,
        projects,
        uploadingFilesCount,
        completedFilesCount,
        failedFilesCount,
      }
    case UPLOAD_JOB_STATUS_CALL_FAIL:
      file = {
        ...projects[action.projectUUID].uploadingFiles[action.fileName],
        uploadStatus: 'Failed',
      }
      uploadingFiles = { ...projects[action.projectUUID].uploadingFiles }
      delete uploadingFiles[action.fileName]
      failedFiles = {
        ...projects[action.projectUUID].failedFiles,
        [action.fileName]: file,
      }
      project = { ...projects[action.projectUUID], uploadingFiles, failedFiles }
      projects[action.projectUUID] = project
      uploadingFilesCount = Object.keys(uploadingFiles || {}).length
      failedFilesCount = Object.keys(failedFiles || {}).length
      return {
        ...state,
        projects,
        uploadingFilesCount,
        failedFilesCount,
      }
    default:
      return state
  }
}
