import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import Paper from '@mui/material/Paper'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import SearchIcon from '@mui/icons-material/Search'
import PublishIcon from '@mui/icons-material/Publish'
import withRouter from '../../../containers/Router/WithRouter'

const styles = {
  root: {
    flexGrow: 1,
  },
  tabs: {
    color: '#43425D',
    fontWeight: 400,
    backgroundColor: '#fafafa',
  },
}

function HeaderTabs({
  classes = {},
  router = {},
  activeTabIndex = 'Search',
  id = '',
}) {
  const handleChange = (event, value) => {
    let url = ''
    switch (value) {
      case 'Search':
        url = '/publishing'
        break
      case 'Publish':
        url = '/publishing/publishUpload'
        break
      case 'ChannelPublish':
        url = '/publishing/channelPublish'
        break
      default:
        break
    }
    router.navigate(url)
  }

  return (
    <Paper className={classes.root}>
      <Tabs
        value={activeTabIndex}
        onChange={handleChange}
        indicatorColor="secondary"
        textColor="secondary"
        centered
        className={classes.tabs}
        groupid={id}
      >
        <Tab icon={<SearchIcon />} value="Search" label="Search" />
        <Tab icon={<PublishIcon />} value="Publish" label="Publish" />
        <Tab
          icon={<PublishIcon />}
          value="ChannelPublish"
          label="Channel Publish"
        />
      </Tabs>
    </Paper>
  )
}

HeaderTabs.propTypes = {
  classes: PropTypes.object.isRequired,
  handleTabs: PropTypes.func,
}

export default withStyles(styles)(withRouter(HeaderTabs))
