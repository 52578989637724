import {
  MIME_TYPE_PDF,
  REVIEW_TASK_TYPE_ID,
  REVIEW_TASK_TYPE_VALUE,
  TASK_TYPE_ID_VALUE_MAP,
  UPLOAD_TASK_TYPE_ID,
  UPLOAD_TASK_TYPE_VALUE,
  WEEKLY_AD_ECMPR_MARKETING_TASK_NAME,
  WEEKLY_AD_ECMPR_MERCHANTS_TASK_NAME,
  WEEKLY_AD_FR_TASK_NAME,
  WEEKLY_AD_IP_TASK_NAME,
  WEEKLY_AD_MPR_MARKETING_TASK_NAME,
  WEEKLY_AD_MPR_MERCHANTS_TASK_NAME,
  WEEKLY_AD_OPV_COPYEDIT_TASK_NAME,
  WEEKLY_AD_OPV_PROMOSIGN_TASK_NAME,
  WEEKLY_AD_PCR_TASK_NAME,
  WEEKLY_AD_WBMPR_MARKETING_TASK_NAME,
  WEEKLY_AD_WBMPR_MERCHANTS_TASK_NAME,
  REPORT_IN_PROCESS,
} from '../constants/projectTask'
import { sortMyArray } from './UtilityHelper'
import {
  ADMIN_ROLE_NAME,
  OBSERVER_ROLE_NAME,
  REVIEWER_ROLE_NAME,
} from '../constants/userRoles'
import { momentHelper } from './dateHelper'
import apiConfig from '../config/apiConfig'

const { assethub = {} } = apiConfig

const getActiveStepName = (stepNumber = 0, uploadTaskType) => {
  if (
    (stepNumber === 1 && !uploadTaskType) ||
    (stepNumber === 0 && uploadTaskType)
  ) {
    return 'people'
  } else if (stepNumber === 2 || (stepNumber === 1 && uploadTaskType)) {
    return 'notification'
  }
  return 'assets'
}

const getEditTaskDefaultInfo = (editTaskInfo = {}, projectTasks = {}) => {
  let participants = []
  let assetDetails = []

  const {
    assets: taskAssets = [],
    participants: taskParticipants = {},
    notification = {},
  } = editTaskInfo
  const taskUsers = taskParticipants.user_review_tasks || []
  const { taskList = {} } = projectTasks
  const { userAssets = {} } = taskList
  const { assets: allProjectAssets = [] } = userAssets
  const projectAssets = allProjectAssets || []

  assetDetails = projectAssets.map((projectAsset = {}) => {
    const {
      asset_id: project_asset_id = '',
      asset_master_uoi_id: project_asset_master_uoi_id = '',
    } = projectAsset
    let isChecked = false
    taskAssets.some((taskAsset = {}) => {
      const { asset_id = '', asset_master_uoi_id = '' } = taskAsset
      // TODO once API change pushed to PROD remoe asset_id check from here keep asset master id check.
      isChecked =
        (project_asset_master_uoi_id !== null &&
          asset_master_uoi_id !== '' &&
          asset_master_uoi_id === project_asset_master_uoi_id) ||
        asset_id === project_asset_id
      return isChecked
    })
    return { ...projectAsset, isChecked }
  })

  participants = taskUsers.map((user = {}) => {
    const tempUser = { ...user }
    const { role_name = OBSERVER_ROLE_NAME } = tempUser
    tempUser.role = role_name.toLowerCase()
    return tempUser
  })

  return {
    assets: assetDetails,
    participants,
    notification,
  }
}

const getCreateTaskDefaultInfo = (taskList = {}) => {
  const { userAssets = {} } = taskList
  const { assets = [], project_admins = [] } = userAssets || {}
  const projectAssets = assets || []

  const assetDetails = projectAssets.map((asset = {}) => {
    const modifiedAsset = { ...asset }
    modifiedAsset.isChecked = modifiedAsset.mime_type === MIME_TYPE_PDF
    return modifiedAsset
  })

  const participants = project_admins.map((user = {}) => {
    const participant = { ...user }
    participant.role = ADMIN_ROLE_NAME
    return participant
  })

  return {
    assets: assetDetails,
    participants,
  }
}

const getTaskAssetList = (assetList = []) => {
  // Added check here to avoid when API returning assets array as null.
  const taskAssetList = [...assetList] || []
  const assetTypeList = []
  const selectedAssetCounterList = []
  let counter = 0
  const updatedAssetList = taskAssetList.reduce(
    (filteredAssets = [], assetData = {}) => {
      const {
        asset_content_type = '',
        asset_id = '',
        asset_import_date = '',
        asset_imported_by = '',
        asset_name = '',
        render_urls: { thumbnail_asset_url: asset_thumbnail_path = '' } = {},
        asset_version = '',
        isChecked,
        mime_type = '',
        status = '',
        asset_master_uoi_id = '',
        asset_uoi_id = '',
      } = assetData

      if (status !== 'ERROR') {
        counter += 1

        if (!assetTypeList.includes(asset_content_type)) {
          assetTypeList.push(asset_content_type)
        }

        if (isChecked) {
          selectedAssetCounterList.push(counter)
        }

        filteredAssets.push({
          id: counter,
          assetChecked: !!isChecked,
          assetId: asset_id,
          assetImportDate: asset_import_date,
          assetImportBy: asset_imported_by,
          assetName: asset_name,
          assetRevision: asset_version,
          assetThumbnail: asset_thumbnail_path,
          assetType: asset_content_type,
          assetMimeType: mime_type,
          assetMasterUOIID: asset_master_uoi_id,
          assetUOIID: asset_uoi_id,
        })
      }

      return filteredAssets
    },
    []
  )

  const sortedAssetList = sortMyArray(updatedAssetList, 'assetImportDate')
  return {
    assetList: sortedAssetList,
    assetTypeList,
    selectedAssetCounterList,
  }
}

export const returnTaskType = (selectedTaskType = '') => {
  switch (selectedTaskType) {
    case UPLOAD_TASK_TYPE_VALUE:
      return 'upload'
    case REVIEW_TASK_TYPE_VALUE:
      return 'review'
    default:
      return 'review'
  }
}

export const returnTaskTypeId = (taskType = '') => {
  switch (taskType) {
    case 'upload':
      return UPLOAD_TASK_TYPE_ID
    case 'review':
      return REVIEW_TASK_TYPE_ID
    default:
      return REVIEW_TASK_TYPE_ID
  }
}

const getUsersByRole = (users = []) => {
  const admins = []
  const observers = []
  const reviewers = []
  users.forEach((user = {}) => {
    const { role = '' } = user
    const roleName = role.toLowerCase()
    if (roleName === ADMIN_ROLE_NAME) {
      admins.push(user)
    } else if (roleName === REVIEWER_ROLE_NAME) {
      reviewers.push(user)
    } else if (roleName === OBSERVER_ROLE_NAME) {
      observers.push(user)
    }
  })

  return {
    admins,
    observers,
    reviewers,
  }
}

const getTaskFormChanges = ({
  emailProps = {},
  fromLocation = '',
  stateData = {},
}) => {
  const {
    accType = '',
    autoReminder = false,
    emailScheduledDate,
    emailScheduledTime,
    from = '',
    initiator_address = 'true',
    isExpanded = false,
    send = '',
    selectedUsers = [],
    subject = '',
    text = '',
    type = '',
  } = stateData

  let scheduleDate = ''
  let taskFormAutoReminder = false
  let updatedAccType = accType || 'new'

  if (emailScheduledDate && emailScheduledTime && send !== 'Now') {
    const date = new Date(emailScheduledDate + ' ' + emailScheduledTime)
    scheduleDate = momentHelper(date).format('MM/DD/YYYY HH:mm')
  }

  if (fromLocation === 'initial') {
    updatedAccType = accType || 'auto'
    taskFormAutoReminder = autoReminder
  }

  const displayCC = initiator_address === 'true'
  const sentTo = selectedUsers[0] || {}

  const defaultVal = {
    isExpanded,
    send,
    showCC: displayCC,
    cc: displayCC,
    from,
    sentTo,
    changed: '',
    type,
    subject: emailProps['subject'] || subject,
    scheduleDate,
    accType: updatedAccType,
    taskType: emailProps['taskType'] || type,
    text: emailProps['text'] || text,
  }

  return {
    defaultVal,
    taskFormAutoReminder,
  }
}

const getTaskEmailRememberNote = (taskName = '') => {
  let bodyTxt = ''
  switch (taskName) {
    case WEEKLY_AD_MPR_MERCHANTS_TASK_NAME:
    case WEEKLY_AD_ECMPR_MERCHANTS_TASK_NAME:
    case WEEKLY_AD_WBMPR_MERCHANTS_TASK_NAME:
      bodyTxt =
        '<p><span>&nbsp;&bull; Find your pages by division in the filename.</span></p>'
      bodyTxt +=
        '<p><span>&nbsp;&bull; Change annotation default color from orange to yellow.</span></p>'
      bodyTxt +=
        '<p><span>&nbsp;&bull; Validate copy/price is accurate and matches the IVY setups.</span></p>'
      bodyTxt +=
        '<p><span>&nbsp;&bull; Annotate offer, copy and price changes – no JIRA form needed at this stage of the process.</span></p>'
      bodyTxt +=
        '<p><span>&nbsp;&bull; Annotate any product/art changes as “Product/Art Change”, insert link to JIRA change form where details are listed.</span></p>'
      bodyTxt +=
        '<p><span>&nbsp;&bull; Annotations save automatically.</span></p>'
      break

    case WEEKLY_AD_MPR_MARKETING_TASK_NAME:
    case WEEKLY_AD_ECMPR_MARKETING_TASK_NAME:
    case WEEKLY_AD_WBMPR_MARKETING_TASK_NAME:
      bodyTxt =
        '<p><span>&nbsp;&bull; Merchant deadline is 10AM Monday.</span></p>'
      bodyTxt +=
        '<p><span>&nbsp;&bull; Find your pages by division in the filename.</span></p>'
      bodyTxt +=
        '<p><span>&nbsp;&bull; Reply to any comment that’s not approved.</span></p>'
      bodyTxt +=
        '<p><span>&nbsp;&bull; Annotations save automatically.</span></p>'
      break

    case WEEKLY_AD_IP_TASK_NAME:
      bodyTxt =
        '<p><span>&nbsp;&bull; This is a view-only proof for Merchants/Partners–the Copyediting Team can ONLY add comments.</span></p>'
      bodyTxt +=
        '<p><span>&nbsp;&bull; This proof has final photography in place–the Copyediting Team edits have not been executed.</span></p>'
      bodyTxt +=
        '<p><span>&nbsp;&bull; Validate final photography and Merchant Partner Review corrections are correct.</span></p>'
      bodyTxt +=
        '<p><span>&nbsp;&bull; Send a JIRA change form ASAP if there are any needed corrections–all JIRA forms submitted by 3pm Monday will be reflected in the PCR proof.</span></p>'
      break

    case WEEKLY_AD_PCR_TASK_NAME:
      bodyTxt =
        '<p><span>&nbsp;&bull; This is a view-only proof for Merchants/Partners–the Copyediting Team can ONLY add comments.</span></p>'
      bodyTxt +=
        '<p><span>&nbsp;&bull; This proof has final photography and the Copyediting Team edits.</span></p>'
      bodyTxt +=
        '<p><span>&nbsp;&bull; Validate final photography and copyediting changes are correct.</span></p>'
      bodyTxt +=
        '<p><span>&nbsp;&bull; Send a JIRA change form ASAP if there are any needed corrections–all JIRA Final Price changes submitted by 12 NOON.</span></p>'
      break

    case WEEKLY_AD_OPV_PROMOSIGN_TASK_NAME:
      bodyTxt =
        '<p><span>&nbsp;&bull; This is a view-only proof for Merchants/Partners–the Copyediting Team can ONLY add comments.</span></p>'
      bodyTxt +=
        '<p><span>&nbsp;&bull; This proof has final photography and final price changes in place and used for OPV process to identify WA Issues.</span></p>'
      bodyTxt +=
        '<p><span>&nbsp;&bull; The hard deadline above only applies to Promo OPS/Support Team comments.</span></p>'
      bodyTxt +=
        '<p><span>&nbsp;&bull; The Copyediting Team will review Promo Ops/Support comments and add their own on Friday after 8:30AM.</span></p>'
      break

    case WEEKLY_AD_OPV_COPYEDIT_TASK_NAME:
      bodyTxt =
        '<p><span>&nbsp;&bull; This is a view-only proof for Merchants/Partners–the Copyediting Team can ONLY add comments.</span></p>'
      bodyTxt +=
        '<p><span>&nbsp;&bull; Promo Ops/Support Team deadline is Friday at 8:30AM.</span></p>'
      break

    case WEEKLY_AD_FR_TASK_NAME:
      bodyTxt = '<p><span>&nbsp;&bull; This is a view-only proof.</span></p>'
      bodyTxt +=
        '<p><span>&nbsp;&bull; The Pages released to the Printer, no changes can occur to the printed ad.</span></p>'
      bodyTxt +=
        '<p><span>&nbsp;&bull; The deadline above is required by the system, but does not impact view-only access.</span></p>'
      bodyTxt +=
        '<p><span>&nbsp;&bull; If an error is found on the proof, please contact your Promo Channel Alignment Manager for next steps.</span></p>'
      break

    default:
      bodyTxt =
        '<p><span>&nbsp;&bull; Resolve questions offline and input final direction into the system.</span></p>'
      bodyTxt +=
        '<p><span>&nbsp;&bull; After you have finished reviewing all files, be sure to “Complete” your review task.</span></p>'
      bodyTxt +=
        '<p><span>&nbsp;&bull; No feedback will be treated as an approval.</span></p>'
      bodyTxt +=
        '<p><span>&nbsp;&bull; Feedback provided after the deadline will not be considered.</span></p>'
  }
  return bodyTxt
}

const getTaskTypes = () => [REVIEW_TASK_TYPE_VALUE, UPLOAD_TASK_TYPE_VALUE]

const getDefaultTaskType = () => REVIEW_TASK_TYPE_VALUE

const getSelectedTaskType = (taskTypeId = '') => {
  const taskTypeIdValueMap = TASK_TYPE_ID_VALUE_MAP
  const selectedTaskType = taskTypeIdValueMap[taskTypeId]
  return selectedTaskType
}

const getTaskReviewURL = (taskDetails = {}, projectId = '') => {
  const { job_id: jobId = '' } = taskDetails
  return `${assethub.host}/annotator/${projectId}/${jobId}`
}

const isTaskReportGenerationInProgress = (taskList = []) =>
  taskList
    .map((tasks) => tasks.report_job)
    .filter((jobReport) => jobReport)
    .map((jobReport) => jobReport.status)
    .some((status) => status === REPORT_IN_PROCESS)

export {
  getActiveStepName,
  getCreateTaskDefaultInfo,
  getDefaultTaskType,
  getEditTaskDefaultInfo,
  getSelectedTaskType,
  getTaskAssetList,
  getTaskEmailRememberNote,
  getTaskFormChanges,
  getTaskReviewURL,
  getTaskTypes,
  getUsersByRole,
  isTaskReportGenerationInProgress,
}
