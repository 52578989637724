import axios from 'axios'
import apiConfig from '../../config/apiConfig'
import { ASSET_CATALOG_FIELDS } from '../constants/metadata'

const {
  search: {
    searchAdminTemplateUrl = '',
    contentReverseImageSearchUrl: reverseImageSearchUrl = '',
  } = {},
  betaSearch: { url = '' } = {},
  userDetails: { userPreferences = '' } = {},
  metadata: {
    assetMetadataUrl = '',
    drmUrl = '',
    assetDeleteUrl = '',
    catalogUrl = '',
    productUrl = '',
    updateAssetMetadataUrl = '',
  } = {},
} = apiConfig

export async function searchAllAssets(payloadProps) {
  const {
    payload = {},
    page = Number(0),
    rowsPerPage = Number(21),
  } = payloadProps
  return axios.post(url + '?page=' + page + '&per_page=' + rowsPerPage, payload)
}

export async function callReviewTemplatesApi() {
  return axios.get(searchAdminTemplateUrl)
}

export async function fetchUserPreferencesApi() {
  return axios.get(userPreferences)
}

export async function updateUserPreferencesApi(payload = {}) {
  return axios.put(userPreferences, payload)
}

export async function fetchMetadataApi(assetId = '') {
  return axios.get(`${assetMetadataUrl}${assetId}`)
}

export async function updateMetadataApi(payload = {}) {
  return axios.put(`${updateAssetMetadataUrl}`, payload)
}

export async function fetchRevisionsApi(assetId = '') {
  return axios.get(`${assetMetadataUrl}${assetId}/revisions`)
}

export async function fetchAssetCatalogApi() {
  return axios.get(
    `${catalogUrl}?catalogType=${ASSET_CATALOG_FIELDS.join(',')}`,
  )
}

export async function deleteRevisionsApi(payload = '') {
  return axios.delete(`${assetDeleteUrl}`, {
    data: payload,
  })
}

export async function fetchDrmDetailsApi(drmId = '') {
  return axios.get(`${drmUrl}/${drmId}`)
}

export async function fetchProductDetailsApi(fieldName = '', keyword = '') {
  return axios.get(`${productUrl}?${fieldName}=${keyword}`)
}

export function searchAllAssetsByImage(payloadProps) {
  const formData = new FormData()
  const {
    payload: { file },
  } = payloadProps
  formData.append(
    'assetSearchFiltersRequest',
    new window.Blob([JSON.stringify({})], {
      type: 'application/json',
    }),
  )
  formData.append('file', file)
  return axios.post(reverseImageSearchUrl, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}
