import axios from 'axios'
import {
  ACTIVATE_ADD_CHANNEL,
  DEACTIVATE_ADD_CHANNEL,
  SAVE_CHANNEL,
  SAVE_CHANNEL_SUCCESS,
  SAVE_CHANNEL_FAILURE,
} from './addChannelActionType'
import apiConfig from '../../config/apiConfig'
import { showNotification } from '../notification/actionCreator'
import {
  getSelectedChannelDetails,
  requestChannelsEvent,
  changeSelectedChannel,
  handleChannelAssetSelect,
} from './channelsActionCreator'

const { channelPublish = {} } = apiConfig
const { baseUrl = '' } = channelPublish

export function activateAddChannel() {
  return {
    type: ACTIVATE_ADD_CHANNEL,
  }
}
export function deactivateAddChannel() {
  return {
    type: DEACTIVATE_ADD_CHANNEL,
  }
}

function saveChannel() {
  return {
    type: SAVE_CHANNEL,
  }
}

function saveChannelSuccess(channel) {
  return {
    type: SAVE_CHANNEL_SUCCESS,
    payload: { channel: channel },
  }
}
function saveChannelFailure(errorMessage) {
  return {
    type: SAVE_CHANNEL_FAILURE,
    payload: { errorMessage: errorMessage },
  }
}

export function saveChannelHandler(formValues = {}, auth = {}) {
  const {
    owner_distribution_list = '',
    owner_email = '',
    channel_name = '',
    publish_location = 'scene7',
  } = formValues
  const { lanId = '' } = auth
  let requestBody = {
    owner_distribution_list: owner_distribution_list,
    owner_email: owner_email,
    channel_name: channel_name,
    publish_location: publish_location,
    owner_id: lanId,
  }
  return (dispatch) => {
    dispatch(saveChannel())
    axios
      .post(`${baseUrl}`, requestBody, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        const { data = {} } = res
        const { channel_id = '' } = data
        dispatch(saveChannelSuccess(res))
        dispatch(deactivateAddChannel())
        dispatch(requestChannelsEvent())
        dispatch(changeSelectedChannel(data))
        dispatch(
          getSelectedChannelDetails({
            channelId: channel_id,
          })
        )
        dispatch(handleChannelAssetSelect({ selectedAssets: [] }))
        dispatch(
          showNotification(
            true,
            `Channel ${channel_name} has been added successfully`,
            'success'
          )
        )
      })
      .catch((error, data) => {
        dispatch(
          saveChannelFailure(
            'Error while saving Data. Please contact administrator'
          )
        )
      })
  }
}
