import { useState, useEffect } from 'react'
import { Grid, Modal, CircularProgress, IconButton } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import cx from 'classnames'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { Player, BigPlayButton } from 'video-react'
import { isEmpty } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchMetadataForAsset,
  fetchRevisionsForAsset,
} from '../../store/metadata/metadataSlice'
import {
  separateFilenameExtension,
  setInBrowserStorage,
} from '../../utils/utils'
import { appendQueryParams } from '../../mappers/searchMapper'
import NoThumbnail from '../../../images/NoThumbnail.svg?url'
import ImageContainerHeader from './ImageContainerHeader'
import ImageSidebar from './ImageSidebar'
import SaveAlertDialog from './SaveAlertDialog'
import MetadataInfoPage from './MetadataInfoPage'
import ImageCarousel from '../../components/ImageCarousel'
import apiConfig from '../../../config/apiConfig'
import { searchAssetsList } from '../../store/search/searchSlice'
import { BetaAnalytics } from '../../../analytics/betaSearch'
import { DOWNLOAD_ORIGINAL_VERSION } from '../../../constants/search'

const sidebarWidth = 650

const useStyles = makeStyles((theme) => ({
  lightBoxWrapper: {
    height: '100vh',
    overflow: 'auto',
    backgroundColor: '#484848',
    boxShadow: useTheme().shadows[5],
    padding: 0,
    textAlign: 'left',
    position: 'relative',
    transition: useTheme().transitions.create(['margin', 'width'], {
      easing: useTheme().transitions.easing.sharp,
      duration: useTheme().transitions.duration.leavingScreen,
    }),
    display: 'block',
    marginLeft: 0,
  },
  lightBoxWrapperShift: {
    width: `calc(100% - ${sidebarWidth}px)`,
    marginRight: sidebarWidth,
    transition: useTheme().transitions.create(['margin', 'width'], {
      easing: useTheme().transitions.easing.easeOut,
      duration: useTheme().transitions.duration.enteringScreen,
    }),
  },
  sidebarStyles: {
    width: sidebarWidth - 10,
  },
  arrowButton: {
    color: '#FFFFFF',
    background: 'rgba(0, 0, 0, 0.5)',
    backgroundImage: 'none',
    height: '60px',
    width: '60px',
    margin: '10px',
    fontSize: '36px',
  },
  progress: {
    margin: '10% auto',
  },
}))

function Metadata(props) {
  const classes = useStyles()
  const windowState = window.history.state
  const { assetId: useParamsAssetId = '' } = useParams()
  const { assetId: windowAssetId = '' } = windowState
  const assetId = useParamsAssetId || windowAssetId || ''
  const {
    state: locationState = {},
    pathname: locationPath = '',
    search = '',
  } = useLocation()
  const DEFAULT_RETURN_URL =
    window?.location?.pathname?.indexOf('AHSearch') !== -1 ? '/' : 'betaSearch'
  const {
    isArrowShow = false,
    consumer = 'Search',
    returnUrl = DEFAULT_RETURN_URL,
  } = locationState || windowState || {}
  const navigate = useNavigate()
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false)
  const [showPreview, setShowPreview] = useState(false)
  const [sidebarOpen, setSidebarOpen] = useState(true)
  const [curAssetList, setCurAssetList] = useState([])
  const [curAssetId, setCurAssetId] = useState(assetId)
  const [scale, setScale] = useState(0)
  const dispatch = useDispatch()

  const {
    assetList = [],
    assetMetadata = {},
    isMetadataLoading,
    page = Number(0),
    rowsPerPage = Number(21),
    totalResults = Number(0),
    filters = {},
    searchTerm = '',
    searchType = '',
    sortFields = {},
  } = useSelector((state) => ({
    assetList: state?.newSearch?.assetList || [],
    page: state?.newSearch?.page || Number(0),
    rowsPerPage: state?.newSearch?.rowsPerPage || Number(21),
    totalResults: state?.newSearch?.totalResults || Number(0),
    filters: state?.newSearch?.filters || {},
    searchTerm: state?.newSearch?.searchTerm || '',
    searchType: state?.newSearch?.searchType || '',
    sortFields: state?.newSearch?.sortFields || {},
    assetMetadata: state?.newMetadata?.assetMetadata || {},
    isMetadataLoading: state?.newMetadata?.isMetadataLoading,
  }))

  const { metadata = {}, render_urls = {} } = assetMetadata
  const { file_metadata = {} } = metadata || {}
  const { file_type = '', file_name = '' } = file_metadata || {}
  const {
    optimized_asset_url = '',
    thumbnail_asset_url = '',
    original_asset_url = '',
    preview_asset_url = '',
  } = render_urls
  let imageUrl = appendQueryParams(`${optimized_asset_url}`, {
    key: apiConfig.apiKey,
  })

  if (isEmpty(imageUrl)) {
    imageUrl = NoThumbnail
  }
  const { fileExt = '' } = separateFilenameExtension(file_name ?? '')
  const isVideo =
    ['mp4', 'mov', 'gif'].indexOf(file_type?.toLowerCase()) !== -1 ||
    ['.mp4', '.mov', '.gif'].indexOf(fileExt?.toLowerCase()) !== -1
  const videoRenderURL =
    fileExt?.toLowerCase() === '.gif' ? preview_asset_url : original_asset_url
  const videoURL = appendQueryParams(videoRenderURL, {})
  const videoThumbnailURL = appendQueryParams(thumbnail_asset_url, {
    height: 1000,
    width: 1000,
  })

  const downloadQueryParts = {
    file_name,
    is_download: true,
  }
  const downloadURL = appendQueryParams(original_asset_url, downloadQueryParts)

  useEffect(() => {
    if (curAssetId) {
      setShowPreview(true)
      dispatch(fetchMetadataForAsset(curAssetId)).then((response) => {
        const { payload = {} } = response
        const { original_asset_id = '' } = payload
        if (original_asset_id) {
          dispatch(fetchRevisionsForAsset(original_asset_id))
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curAssetId])

  useEffect(() => {
    if (assetList?.length > 0) {
      setCurAssetList(assetList)
    }
  }, [assetList])

  const onButtonClickAction = (buttonClickAction = '', searchList = []) => {
    if (buttonClickAction === 'next') {
      const nextAssetId = searchList[0]?.asset_details?.asset_id || ''
      setCurAssetId(nextAssetId)
      nextAssetId && navigateToMetadata(nextAssetId)
    } else if (buttonClickAction === 'prev') {
      const prevAssetId =
        searchList[rowsPerPage - 1]?.asset_details?.asset_id || ''
      setCurAssetId(prevAssetId)
      prevAssetId && navigateToMetadata(prevAssetId)
    }
  }

  const onPreviewClose = () => {
    // setIsConfirmDialogOpen(true) conditional when metadata is editted
    setShowPreview(false)
  }

  const handleSidebarOpen = () => {
    setSidebarOpen(true)
    setInBrowserStorage('ah_metadata_sidebar_open', 'true')
  }

  const handleSidebarClose = () => {
    setSidebarOpen(false)
    setInBrowserStorage('ah_metadata_sidebar_open', 'false')
  }

  const downloadAsset = () => {
    props?.trackAnalyticsData &&
      props.trackAnalyticsData({
        eventType: BetaAnalytics.event.ASSET_USAGE,
        eventName: BetaAnalytics.event.DOWNLOAD_ASSET,
        eventData: {
          asset: assetMetadata,
          downloadResolution: DOWNLOAD_ORIGINAL_VERSION,
          downloadStyle: BetaAnalytics.event.SINGLE_ASSET_DETAIL_PAGE,
        },
      })
  }

  const onDontSave = () => {
    setShowPreview(true)
    setIsConfirmDialogOpen(false)
  }

  const onSave = () => {
    setShowPreview(false)
    setIsConfirmDialogOpen(false)
  }

  const handleBackToSearch = () => {
    setShowPreview(false)
    navigate(returnUrl ?? -1, { replace: true })
    props?.setRenderPage && props?.setRenderPage('BACKTOASSETMETADATA')
  }

  const onClickNextAsset = () => {
    fetchNextAssetId(curAssetId)
  }

  const onClickPrevAsset = () => {
    fetchPrevAssetId(curAssetId)
  }

  const getNumberOfPages = () => {
    const results = totalResults
    return Math.ceil(results / rowsPerPage)
  }

  const navigateToMetadata = (newAssetId) => {
    window.history.pushState(
      {
        consumer: 'Search',
        returnUrl: search ? `${locationPath}${search}` : `${locationPath}`,
        isArrowShow: true,
        assetId: newAssetId,
        location: locationPath,
      },
      'metadata',
      `${locationPath}/${assetId}/metadata`,
    )
  }

  const fetchNextAssetId = (existingAssetId = '') => {
    const existingAssetIndex = (curAssetList || []).findIndex(
      ({ assetId }) => assetId === existingAssetId,
    )
    if (existingAssetIndex !== -1) {
      if (existingAssetIndex < curAssetList.length - 1) {
        const nextAssetId = curAssetList[existingAssetIndex + 1]?.assetId
        setCurAssetId(nextAssetId)
        navigateToMetadata(nextAssetId)
      } else if (
        existingAssetIndex >= curAssetList.length - 1 &&
        page < getNumberOfPages() - 1
      ) {
        const searchPayload = {
          payload: {
            filters,
            search_term: searchTerm,
            search_type: searchType,
            sort_fields: sortFields,
            strict: true,
          },
          page: page + 1,
          rowsPerPage: rowsPerPage,
        }
        dispatch(searchAssetsList(searchPayload)).then((res) => {
          const { payload = {} } = res
          const { search = [] } = payload
          onButtonClickAction('next', search)
        })
      }
    }
  }

  const fetchPrevAssetId = (existingAssetId) => {
    const existingAssetIndex = (curAssetList || []).findIndex(
      ({ assetId }) => assetId === existingAssetId,
    )
    if (existingAssetIndex !== -1) {
      if (existingAssetIndex > 0) {
        const prevAssetId = curAssetList[existingAssetIndex - 1]?.assetId
        setCurAssetId(prevAssetId)
        navigateToMetadata(prevAssetId)
      } else if (existingAssetIndex === 0 && page > 0) {
        const searchPayload = {
          payload: {
            filters,
            search_term: searchTerm,
            search_type: searchType,
            sort_fields: sortFields,
            strict: true,
          },
          page: page - 1,
          rowsPerPage: rowsPerPage,
        }
        dispatch(searchAssetsList(searchPayload)).then((res) => {
          const { payload = {} } = res
          const { search = [] } = payload
          onButtonClickAction('prev', search)
        })
      }
    }
  }

  return (
    <Modal open={showPreview} onClose={onPreviewClose}>
      <div style={{ display: 'flex' }}>
        <Grid
          container
          spacing={2}
          className={cx(classes.lightBoxWrapper, {
            [classes.lightBoxWrapperShift]: sidebarOpen,
          })}
        >
          <ImageContainerHeader
            closeButtonText={`BACK TO ${consumer}`}
            closeButtonHandler={() => handleBackToSearch()}
            downloadUrl={downloadURL}
            downloadButtonText="DOWNLOAD"
            downloadImageHandler={() => {
              downloadAsset()
            }}
            fileName={file_name}
            openDrawerText="SHOW METADATA"
            openDrawerHandler={handleSidebarOpen}
            isSidebarOpen={sidebarOpen}
            sidebarWidth={sidebarWidth}
            onChangeScale={(event) => setScale(event?.target?.value)}
            scale={scale}
            isVideo={isVideo}
          />
          {isVideo ? (
            <div
              style={{
                height: `calc(100vh-50px)`,
                width: '100%',
                backgroundColor: 'grey',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <IconButton
                className={classes.arrowButton}
                onClick={onClickPrevAsset}
                size="large"
              >
                <ChevronLeft fontSize="inherit" />
              </IconButton>
              <div
                style={{
                  height: `calc(100vh-50px)`,
                  width: '85%',
                  backgroundColor: 'grey',
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                  marginTop: '50px',
                }}
              >
                {/*Video Player does not support dynamic resizing. Due to this, Video player is not resposive to window changes. User will need to resize and refresh to apply changes. */}
                <Player
                  fluid={false}
                  height={window.innerHeight - 50}
                  src={videoURL}
                  poster={videoThumbnailURL}
                >
                  <BigPlayButton position="center" />
                </Player>
              </div>
              <IconButton
                className={classes.arrowButton}
                onClick={onClickNextAsset}
                size="large"
              >
                <ChevronRight fontSize="inherit" />
              </IconButton>
            </div>
          ) : (
            // ''
            <ImageCarousel
              imageUrl={imageUrl}
              IsFetching={isMetadataLoading}
              NextAsset={onClickNextAsset}
              PrevAsset={onClickPrevAsset}
              scale={scale}
              isSidebarOpen={sidebarOpen}
              sidebarWidth={sidebarWidth}
              isArrowShow={isArrowShow}
            />
          )}
        </Grid>
        <ImageSidebar
          closeButtonText="HIDE METADATA"
          closeHandler={handleSidebarClose}
          isOpen={sidebarOpen}
          customStyles={classes.sidebarStyles}
        >
          {!isMetadataLoading ? (
            <MetadataInfoPage assetMetadata={assetMetadata} />
          ) : (
            <Grid
              container
              spacing={4}
              direction={'row'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <CircularProgress className={classes.progress} />
              </Grid>
            </Grid>
          )}
        </ImageSidebar>
        <SaveAlertDialog
          isConfirmDialogOpen={isConfirmDialogOpen}
          onDontSave={onDontSave}
          onSave={onSave}
        />
      </div>
    </Modal>
  )
}

export default Metadata
