import React from 'react'
import { withStyles } from '@mui/styles'
import { Card, Grid, Typography, Container } from '@mui/material'
import CloudUpload from '@mui/icons-material/CloudUpload'
import { useDropzone } from 'react-dropzone'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { publishAsset } from '../../../store/publishUpload/action'
import { showNotification } from '../../../store/notification/actionCreator'

const styles = {
  card: {
    marginTop: '16px',
    boxShadow: 'none',
  },
  containerStyle: {
    margin: '16px',
    minheight: '30vh',
    cursor: 'pointer',
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#8D8D8D',
  },
  droppingContainer: {
    display: 'flex',
    justifyContent: 'center',
    color: '#8D8D8D',
    fontSize: '14px',
  },
  droppingContainerAccepted: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: 14,
  },
  paddingBottom: {
    paddingBottom: '5px',
    paddingLeft: '5px',
  },
  titleContainer: {
    paddingLeft: '10px',
    paddingTop: '10px',
  },
  fontSize100: {
    fontSize: 35,
  },
}

const ImageUploader = ({
  classes = {},
  selectedChannelId = '',
  showNotification = () => {},
  publishAsset = () => {},
}) => {
  const onDrop = (acceptedFiles = [], rejectedFiles = []) => {
    if (acceptedFiles.length > 100) {
      showNotification(true, 'Only 100 files allowed at a time', 'error')
    } else {
      if (rejectedFiles.length < 1) {
        acceptedFiles.forEach((currentFile) => {
          publishAsset(currentFile, selectedChannelId)
        })
      }
    }
  }

  const onDropRejected = (rejectedFiles = []) => {
    showNotification(true, 'One or more files are not a valid', 'error')
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: '.vtt, image/jpeg, image/png, application/pdf, video/mp4, .gif',
    onDropRejected: onDropRejected,
  })

  return (
    <Card className={classes.card}>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Grid container spacing={2}>
          <Container {...getRootProps()} className={classes.containerStyle}>
            <div className={classes.iconContainer}>
              <CloudUpload className={classes.fontSize100} />
            </div>
            <Typography className={classes.droppingContainer}>
              Click Here to Upload Files.
            </Typography>
            <input {...getInputProps()} />
          </Container>
        </Grid>
      </Grid>
    </Card>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showNotification,
      publishAsset,
    },
    dispatch
  )
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ImageUploader))
