import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import { Modal, Grid, IconButton, CircularProgress } from '@mui/material'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { Player, BigPlayButton } from 'video-react'
import { isEmpty } from 'lodash'

import ImageCarousel from '../../components/ImagePreview/ImageCarousel'
import ImageContainerHeader from '../../components/ImagePreview/ImageContainerHeader'
import ImageSidebar from '../../components/ImagePreview/ImageSidebar'
import SaveAlertDialog from '../../components/Custom/SaveAlertDialog'
import MetadataBar from './MetadataBar'
import {
  getFromBrowserStorage,
  setInBrowserStorage,
  separateFilenameExtension,
} from '../../helpers/UtilityHelper'
import { appendQueryParams } from '../../helpers/UrlHelper'

import NoThumbnail from '../../images/NoThumbnail.svg?url'
import withRouter from '../Router/WithRouter'
import {
  fetchAssetMetadata,
  setMetadataLoading,
} from '../../store/metadata/actionCreator'

const sidebarWidth = 650
const styles = makeStyles((theme) => ({
  lightBoxWrapper: {
    height: '100vh',
    overflow: 'auto',
    backgroundColor: '#484848',
    boxShadow: useTheme().shadows[5],
    padding: 0,
    textAlign: 'left',
    position: 'relative',
    transition: useTheme().transitions.create(['margin', 'width'], {
      easing: useTheme().transitions.easing.sharp,
      duration: useTheme().transitions.duration.leavingScreen,
    }),
    display: 'block',
    marginLeft: 0,
  },
  lightBoxWrapperShift: {
    width: `calc(100% - ${sidebarWidth}px)`,
    marginRight: sidebarWidth,
    transition: useTheme().transitions.create(['margin', 'width'], {
      easing: useTheme().transitions.easing.easeOut,
      duration: useTheme().transitions.duration.enteringScreen,
    }),
  },
  sidebarStyles: {
    width: sidebarWidth - 10,
  },
  arrowButton: {
    color: '#FFFFFF',
    background: 'rgba(0, 0, 0, 0.5)',
    backgroundImage: 'none',
    height: '60px',
    width: '60px',
    margin: '10px',
    fontSize: '36px',
  },
  progress: {
    margin: '10% auto',
  },
}))

export class MetadataPreview extends React.Component {
  constructor(props) {
    super(props)
    const existingState = getFromBrowserStorage('ah_metadata_sidebar_open')
    this.metadataRef = React.createRef()
    this.state = {
      sidebarOpen: existingState === 'true',
      isConfirmDialoagOpen: false,
      scale: 0,
      optimizedImage: true,
    }
  }

  componentDidMount() {
    // Bind keydown event to class function
    document.addEventListener('keydown', this.onArrowKeyPress, false)

    const {
      asset = {},
      selectedAssetId = '',
      fetchAssetMetadata = () => {},
      setMetadataLoading = () => {},
    } = this.props
    if(selectedAssetId) {
      setMetadataLoading(true)
      fetchAssetMetadata(selectedAssetId, (resp) => {
        setMetadataLoading(false)
      }, ()=> {
        setMetadataLoading(false)
      })
    }
  }

  componentWillUnmount() {
    // Unbind keydown event to class function
    document.removeEventListener('keydown', this.onArrowKeyPress, false)
  }

  componentDidUpdate(prevProps) {
    const {
      asset = {},
      selectedAssetId = '',
      originalAssetId = '',
      handleOriginalAssetId = () => {},
      isSearchComponent = false,
      consumer = '',
      projectId = '',
      showPreview = false,
      fetchAssetMetadata = () => {},
      setMetadataLoading = () => {},
      assetMetadata = {}
    } = this.props

    const { selectedAssetId: prevSelectedAssetId = '',  assetMetadata: prevAssetMetadata = {} } = prevProps

    if(prevSelectedAssetId !== selectedAssetId) {
      setMetadataLoading(true)
      fetchAssetMetadata(selectedAssetId, (resp) => {
        setMetadataLoading(false)
      }, ()=> {
        setMetadataLoading(false)
      })
    }
    const { asset_id: preAssetId = '' } = prevAssetMetadata
    const { original_asset_id: assetOrgId = '', asset_id: assetId = '' } =
      assetMetadata
    if (
      isSearchComponent &&
      (originalAssetId !== assetOrgId || preAssetId !== assetId)
    ) {
      handleOriginalAssetId(assetOrgId, assetId)
    }
    if (
      consumer === 'project' &&
      assetId !== preAssetId &&
      showPreview
    ) {
      const newUrl = '/project/' + projectId + '/metaPage/' + assetId
      window.history.pushState({}, null, newUrl)
    }
  }

  handleSidebarOpen = () => {
    this.setState({ sidebarOpen: true })
    setInBrowserStorage('ah_metadata_sidebar_open', 'true')
  }

  handleSidebarClose = () => {
    this.setState({ sidebarOpen: false })
    setInBrowserStorage('ah_metadata_sidebar_open', 'false')
  }

  onArrowKeyPress = (e) => {
    const {
      nextAsset = () => {},
      prevAsset = () => {},
      showPreview = false,
    } = this.props
    if (showPreview) {
      const leftArrow = 37
      const rightArrow = 39
      const event = e || window.event
      const canNavigate =
        (event.target || {}).type !== 'text' &&
        (event.target || {}).type !== 'textarea'
      if (canNavigate) {
        if (event.keyCode === leftArrow) {
          prevAsset(e)
        } else if (event.keyCode === rightArrow) {
          nextAsset(e)
        }
      }
    }
  }

  onDontSave = () => {
    const { closePreview = () => {} } = this.props
    this.setState({ isConfirmDialoagOpen: false })
    closePreview(true)
  }

  onSave = () => {
    const { setSaveAlertAction = () => {} } = this.props
    this.setState({ isConfirmDialoagOpen: false })
    setSaveAlertAction('save_single_edit')
  }

  onPreviewClose = () => {
    const {
      isMetadataFormEdited,
      closePreview = () => {},
      isArrowShow = true,
    } = this.props
    if (isMetadataFormEdited) {
      this.setState({ isConfirmDialoagOpen: true })
    } else {
      closePreview(isArrowShow)
      this.setState({ scale: 0 })
    }
  }

  onChangeScale = (event) => {
    this.setState({ scale: event.target.value })
  }

  onPreviewUrl = () => {
    this.setState({ optimizedImage: false })
  }

  render() {
    const {
      classes = {},
      asset = {},
      isFetching = false,
      consumer = 'search',
      closePreview = () => {},
      downloadAsset = () => {},
      nextAsset = () => {},
      prevAsset = () => {},
      setSaveAlertAction = () => {},
      isArrowShow = true,
      showPreview = false,
      router = {},
      isMetadataLoading = false,
      assetMetadata = {}
    } = this.props
    const { sidebarOpen, isConfirmDialoagOpen, scale, optimizedImage } =
      this.state
    const { metadata = {}, render_urls = {}, asset_id = '' } = assetMetadata
    const { file_metadata = {} } = metadata || {}
    const { file_type = '',  file_name = ''} = file_metadata || {}
    const {
      optimized_asset_url = '',
      thumbnail_asset_url = '',
      original_asset_url = '',
      preview_asset_url = '',
    } = render_urls
    let fileUrl = `${optimized_asset_url}`

    if (isEmpty(fileUrl)) {
      fileUrl = NoThumbnail
    }
    const { fileExt = '' } = separateFilenameExtension(file_name  ?? '')
    const isVideo =
      ['mp4', 'mov', 'gif'].indexOf(file_type?.toLowerCase()) !== -1 ||
      ['.mp4', '.mov', '.gif'].indexOf(fileExt?.toLowerCase()) !== -1
    const videoRenderURL =
      fileExt?.toLowerCase() === '.gif' ? preview_asset_url : original_asset_url
    const videoURL = appendQueryParams(videoRenderURL, {})
    const videoThumbnailURL = appendQueryParams(thumbnail_asset_url, {
      height: 1000,
      width: 1000,
    })

    const imageQueryParts = {}

    if (!optimizedImage) {
      imageQueryParts.width = 1000
      imageQueryParts.height = 1000
    }
    const imageUrl = appendQueryParams(fileUrl, imageQueryParts)

    const downloadQueryParts = {
      file_name,
      is_download: true,
    }
    const downloadURL = appendQueryParams(original_asset_url, downloadQueryParts)

    return (
      <Modal open={showPreview} onClose={this.onPreviewClose}>
        <div style={{ display: 'flex' }}>
          <Grid
            container
            spacing={2}
            className={cx(classes.lightBoxWrapper, {
              [classes.lightBoxWrapperShift]: sidebarOpen,
            })}
          >
            <ImageContainerHeader
              closeButtonText={`BACK TO ${consumer}`}
              closeButtonHandler={this.onPreviewClose}
              downloadUrl={downloadURL}
              downloadButtonText="DOWNLOAD"
              downloadImageHandler={() => {
                downloadAsset(assetMetadata)
              }}
              fileName={file_name}
              openDrawerText="SHOW METADATA"
              openDrawerHandler={this.handleSidebarOpen}
              isSidebarOpen={sidebarOpen}
              sidebarWidth={sidebarWidth}
              onChangeScale={this.onChangeScale}
              scale={scale}
              isVideo={isVideo}
            />
            {isVideo ? (
              <div
                style={{
                  height: `calc(100vh-50px)`,
                  width: '100%',
                  backgroundColor: 'grey',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <IconButton
                  className={classes.arrowButton}
                  onClick={() => prevAsset(router)}
                  size="large"
                >
                  <ChevronLeft fontSize="inherit" />
                </IconButton>
                <div
                  style={{
                    height: `calc(100vh-50px)`,
                    width: '85%',
                    backgroundColor: 'grey',
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    marginTop: '50px',
                  }}
                >
                  {/*Video Player does not support dynamic resizing. Due to this, Video player is not resposive to window changes. User will need to resize and refresh to apply changes. */}
                  <Player
                    fluid={false}
                    height={window.innerHeight - 50}
                    src={videoURL}
                    poster={videoThumbnailURL}
                  >
                    <BigPlayButton position="center" />
                  </Player>
                </div>
                <IconButton
                  className={classes.arrowButton}
                  onClick={() => nextAsset(router)}
                  size="large"
                >
                  <ChevronRight fontSize="inherit" />
                </IconButton>
              </div>
            ) : (
              <ImageCarousel
                imageUrl={imageUrl}
                IsFetching={isFetching || isMetadataLoading}
                NextAsset={nextAsset}
                PrevAsset={prevAsset}
                scale={scale}
                isSidebarOpen={sidebarOpen}
                sidebarWidth={sidebarWidth}
                isArrowShow={isArrowShow}
                onPreviewUrl={this.onPreviewUrl}
              />
            )}
          </Grid>
          <ImageSidebar
            closeButtonText="HIDE METADATA"
            closeHandler={this.handleSidebarClose}
            isOpen={sidebarOpen}
            customStyles={classes.sidebarStyles}
          >
            {!isMetadataLoading ? (
              <MetadataBar
                ref={this.metadataRef}
                assetData={assetMetadata} // DONOT DO: PASS EMPTY OBJECT TO GET DATA FROM API FOR BOARDS
                assetId={asset_id}
                consumer={consumer}
                closePreview={closePreview}
                setSaveAlertAction={setSaveAlertAction}
                isMetadataLoading={isMetadataLoading}
              />
            ) : (
              <Grid
                container
                spacing={4}
                direction={'row'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                  <CircularProgress className={classes.progress} />
                </Grid>
              </Grid>
            )}
          </ImageSidebar>
          <SaveAlertDialog
            isConfirmDialoagOpen={isConfirmDialoagOpen}
            onDontSave={this.onDontSave}
            onSave={this.onSave}
          />
        </div>
      </Modal>
    )
  }
}

MetadataPreview.propTypes = {
  classes: PropTypes.object.isRequired,
  asset: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  showPreview: PropTypes.bool.isRequired,
  consumer: PropTypes.string,
  closePreview: PropTypes.func.isRequired,
  downloadAsset: PropTypes.func.isRequired,
  nextAsset: PropTypes.func.isRequired,
  prevAsset: PropTypes.func.isRequired,
  setSaveAlertAction: PropTypes.func,
  isMetadataFormEdited: PropTypes.bool,
  originalAssetId: PropTypes.string,
  handleOriginalAssetId: PropTypes.func,
  isArrowShow: PropTypes.bool,
  history: PropTypes.object,
  isSearchComponent: PropTypes.bool,
  projectId: PropTypes.string,
}

const mapStateToProps = (state = {}) => {
  const { metadata = {} } = state
  const { isMetadataLoading = false, assetMetadata = {} } = metadata
  return {
    isMetadataLoading,
    assetMetadata
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchAssetMetadata,
      setMetadataLoading
    },
    dispatch
  )

const MyComponent = (props) => {
  const classes = styles()
  return <MetadataPreview {...props} classes={classes} />
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MyComponent))
