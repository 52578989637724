import React from 'react'
import { Player, BigPlayButton } from 'video-react'

import { useAssetCardContext } from './AssetCardContext'
import '../../../../../../node_modules/video-react/dist/video-react.css'

const AssetVideoCard = (props) => {
  const { asset = [] } = useAssetCardContext()
  const { fileType = '', renderUrls = {} } = asset

  const videoURL =
    fileType === 'gif' ? renderUrls?.previewAssetUrl : renderUrls?.videoUrl

  const videoPosterURL =
    fileType === 'gif'
      ? renderUrls.thumbnailSquareUrl
      : renderUrls.previewThumbnail

  return (
    <Player
      fluid={false}
      width={285}
      height={285}
      playsInline
      src={videoURL}
      preload={'none'}
      poster={videoPosterURL}
    >
      <BigPlayButton position="center" />
    </Player>
  )
}

export default AssetVideoCard
