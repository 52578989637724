import React, { useState, useEffect, startTransition } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import CopyToClipboard from 'react-copy-to-clipboard'
import { isEmpty } from 'lodash'
import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Tooltip,
} from '@mui/material'
import Link from '@mui/icons-material/Link'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import Grid from '@mui/material/Grid'
import { TextareaAutosize } from '@mui/base'
import { makeStyles } from '@mui/styles'
import Markdown from 'markdown-to-jsx'
import apiConfig from '../../config/apiConfig'
import { appendQueryParams } from '../../helpers/UrlHelper'
import HeaderTitle from '../../components/Header/HeaderTitle'
import { showNotification } from '../../store/notification/actionCreator'
import { promptCategoryList } from '../constants/genai'
import {
  fetchAiEnabledAssets,
  fetchAssetDetails,
  generatePrompt,
} from '../services/genaiService'

const useStyles = makeStyles({
  aiContainer: {
    display: 'flex',
    height: '90vh',
    flexDirection: 'row',
  },
  imageContainer: {
    width: '20%',
    padding: '10px 15px',
    border: '1px solid #6153536b',
    overflow: 'scroll',
  },
  promptImages: {
    display: 'flex',
    flexWrap: 'wrap',
    height: '85vh',
    overflow: 'scroll',
    background: '#a396a30d',
  },
  promptContainer: {
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
  },
  imageDiv: {
    width: '120px',
    minHeight: '90px',
    padding: '10px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  imageStyles: {
    width: '100px',
    height: '100px',
    objectFit: 'contain',
    border: '1px solid #5e959280',
  },
  imageStylesClicked: {
    width: '105px',
    height: '105px',
    objectFit: 'contain',
    border: '1px solid #13605c',
    boxShadow:
      '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
  },
  imageStylesSelected: {
    maxWidth: '150px',
    maxHeight: '150px',
    objectFit: 'contain',
    border: '1px solid #13605c',
    boxShadow:
      '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
  },
  submitButton: {
    backgroundColor: '#329eb7',
    color: 'white',
    '&:disabled': {
      color: 'grey',
      backgroundColor: '#7f889994',
    },
    '&:hover': {
      backgroundColor: '#417c8a',
      color: 'white',
    },
  },
  promptTextArea: {
    width: '100%',
    minWidth: '60vw',
  },
  contextTextArea: {
    width: '100%',
    minWidth: '45vw',
  },
  assetInfo: {
    marginTop: '-10px',
  },
  promptHistory: {
    borderTop: '1px dashed #333333',
    padding: '15px',
    display: 'flex',
    flexDirection: 'column',
  },
  sessionHistroyLabel: {
    padding: '0px 0px 10px 10px',
  },
  description: {
    fontWeight: '300',
    letterSpacing: '0.15px',
  },
  promptCategoryTabs: {
    backgroundColor: '#c0c6cd82',
  },
  historyContainer: {
    height: '50vh',
    overflow: 'scroll',
  },
  historyItem: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    padding: '15px 10px',
    backgroundColor: '#e6d6d61a',
    borderBottom: '1px dotted #81727278',
  },
  clipBoardLink: {
    verticalAlign: 'middle',
    paddingLeft: '5px',
  },
})

function PromptPlayground() {
  const classes = useStyles()
  const { displayName = '' } = useSelector((state = {}) => state.auth) || {}
  const dispatch = useDispatch()
  const [gptAllowedAssets, setGptAllowedAssets] = useState([])
  const [selectedAssetId, setSelectedAssetId] = useState('')
  const [promptQueryValue, setPromptQueryValue] = useState('')
  const [context, setContext] = useState('')
  const [selectedTabValue, setSelectedTabValue] = useState('All')
  const [responseData, setResponseData] = useState({})
  const [category, setCategory] = useState('')
  const [promptHistory, setPromptHistory] = useState([])
  const [loader, setLoader] = useState(false)

  useEffect(() => {
    fetchAiEnabledAssets().then((response) => {
      setGptAllowedAssets(response.data)
    })
    const savedContext = localStorage.getItem('ai_context')
    savedContext && setContext(savedContext)
  }, [])

  useEffect(() => {
    const existingContext = localStorage.getItem('ai_context')
    existingContext !== context && localStorage.setItem('ai_context', context)
  }, [context])

  const selectedAssetDetails = gptAllowedAssets.find(
    (asset) => asset.asset_id === selectedAssetId,
  )

  const resetPageState = () => {
    startTransition(() => {
      setResponseData({})
      setSelectedTabValue('All')
      setCategory('')
      setPromptQueryValue('')
      setPromptHistory([])
    })
  }

  const onSelectedAsset = (assetId) => {
    setSelectedAssetId(assetId)
    resetPageState()
    fetchAssetDetails(assetId).then((response) => {
      setPromptHistory(response?.data)
    })
  }

  const onClickPrompt = async () => {
    const promptPayload = {
      category: category,
      query: promptQueryValue,
      context: context,
    }
    setLoader(true)
    const response = await generatePrompt({
      assetId: selectedAssetId,
      displayName,
      promptPayload,
    })
    if (response) {
      const allResponseData = response?.data
      setLoader(false)
      setResponseData(allResponseData?.[0] || {})
      setPromptHistory(allResponseData)
      setPromptQueryValue('')
    }
  }

  const copyAssetUrl = () => {
    dispatch(
      showNotification(true, 'Asset URL copied to clipboard ', 'success'),
    )
  }

  const promptHistoryMapper =
    selectedTabValue === 'All'
      ? promptHistory
      : (promptHistory || []).filter(
          (prompt) => prompt.category === selectedTabValue,
        )

  return (
    <Grid container className={classes.aiContainer}>
      <HeaderTitle title="AI - Image to Text : Playground" />
      <Grid item className={classes.imageContainer}>
        <div style={{ padding: '5px 20px' }}>Select an Image for Testing</div>
        <div className={classes.promptImages}>
          {gptAllowedAssets.map((assetObj) => {
            const { asset_url = '', asset_id = '' } = assetObj
            const imageQueryParams = { key: apiConfig.key }
            const imageUrl = asset_url?.includes('key')
              ? asset_url
              : appendQueryParams(asset_url, imageQueryParams)
            return (
              <div className={classes.imageDiv}>
                <img
                  src={`${imageUrl}`}
                  alt={asset_id}
                  className={
                    selectedAssetId === asset_id
                      ? classes.imageStylesClicked
                      : classes.imageStyles
                  }
                  onClick={() => onSelectedAsset(asset_id)}
                />
              </div>
            )
          })}
        </div>
      </Grid>
      {selectedAssetId ? (
        <Grid item container className={classes.promptContainer}>
          <Grid
            container
            style={{ padding: '20px', display: 'flex', gap: '15px' }}
          >
            <Grid
              container
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '20px',
              }}
            >
              <Grid item>
                <img
                  src={selectedAssetDetails?.asset_url || ''}
                  alt={selectedAssetDetails?.asset_id}
                  className={classes.imageStylesSelected}
                />
              </Grid>
              <Grid item>
                <FormControl size="small">
                  <InputLabel id="category-label">Category</InputLabel>
                  <Select
                    autoWidth
                    style={{ width: '150px', height: '40px' }}
                    labelId="category-label"
                    id="category-label"
                    value={category}
                    input={<OutlinedInput label="Category" />}
                    onChange={(event) => setCategory(event.target.value)}
                  >
                    {promptCategoryList.map((category) => (
                      <MenuItem value={category}>{category}</MenuItem>
                    ))}
                  </Select>
                  {!category && (
                    <FormHelperText
                      style={{ margin: '0px', paddingTop: '3px', color: 'red' }}
                    >
                      * Select Category for your prompt
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item>
                <InputLabel id="context-label" className={classes.description}>
                  Context
                </InputLabel>
                <TextareaAutosize
                  className={classes.contextTextArea}
                  aria-label="context-text-area"
                  minRows={4}
                  placeholder="Think about the context of your prompt and what you would like AI to consider when generating a response. If you’re looking for a specific style or an output, mention it here to guide the AI effectively and consistently..."
                  value={context}
                  onChange={(event) => setContext(event.target.value)}
                />
              </Grid>
            </Grid>
            <Grid item className={classes.assetInfo}>
              <span>
                {'File Name: '}
                <span className={classes.description}>
                  {selectedAssetDetails?.file_name}
                </span>
              </span>
              <div>
                <span>{`Asset URL:`}</span>
                <Tooltip title={selectedAssetDetails?.asset_url}>
                  <span className={classes.clipBoardLink}>
                    <CopyToClipboard
                      text={selectedAssetDetails?.asset_url}
                      onCopy={() => copyAssetUrl()}
                    >
                      <Link style={{ cursor: 'pointer' }} />
                    </CopyToClipboard>
                  </span>
                </Tooltip>
              </div>
            </Grid>
            <Grid
              container
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '20px',
              }}
            >
              <Grid item>
                <InputLabel id="prompt-label" className={classes.description}>
                  Prompt
                </InputLabel>
                <TextareaAutosize
                  className={classes.promptTextArea}
                  aria-label="text-area"
                  minRows={4}
                  placeholder="Enter your prompt..."
                  value={promptQueryValue}
                  onChange={(event) => setPromptQueryValue(event.target.value)}
                />
              </Grid>
              <Grid item className={classes.promptButton}>
                <Button
                  variant="outlined"
                  className={classes.submitButton}
                  disabled={!(promptQueryValue && category)}
                  onClick={onClickPrompt}
                >
                  Generate Text
                </Button>
              </Grid>
            </Grid>
            {loader ? (
              <CircularProgress />
            ) : isEmpty(responseData) ? (
              ''
            ) : (
              <Grid
                container
                style={{ backgroundColor: '#e6d6d61a', padding: '10px' }}
              >
                <Grid
                  container
                  style={{ display: 'flex', flexDirection: 'row', gap: '20px' }}
                >
                  <Grid
                    item
                    container
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '20px',
                    }}
                  >
                    <Grid item>
                      <b>Category:</b>{' '}
                      <span className={classes.description}>
                        {responseData?.category}
                      </span>
                    </Grid>
                    {responseData?.context && (
                      <Grid item>
                        <b>Context:</b>{' '}
                        <span className={classes.description}>
                          {responseData?.context}
                        </span>
                      </Grid>
                    )}
                    <Grid item>
                      <b>Prompt:</b>{' '}
                      <span className={classes.description}>
                        {responseData?.query}
                      </span>
                    </Grid>
                    <Grid item>
                      <div>
                        <b>Response:</b>
                      </div>{' '}
                      <Markdown className={classes.description}>
                        {responseData?.response}
                      </Markdown>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid container className={classes.promptHistory}>
            <Grid item className={classes.sessionHistroyLabel}>
              Prompt History
            </Grid>
            <Grid item>
              <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={selectedTabValue}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList
                      onChange={(event, newValue) =>
                        setSelectedTabValue(newValue)
                      }
                      aria-label="TABS"
                      className={classes.promptCategoryTabs}
                    >
                      <Tab label="All" value="All" />
                      {promptCategoryList.map((category) => (
                        <Tab label={category} value={category} />
                      ))}
                    </TabList>
                  </Box>
                </TabContext>
              </Box>
            </Grid>
            {promptHistoryMapper?.length ? (
              <Grid item className={classes.historyContainer}>
                {(promptHistoryMapper || []).map((promptObj) => {
                  const {
                    id = '',
                    query = '',
                    response = '',
                    category = '',
                    context = '',
                  } = promptObj
                  return (
                    <Grid container className={classes.historyItem}>
                      <Grid item>
                        <b>Category:</b>{' '}
                        <span className={classes.description}>{category}</span>
                      </Grid>
                      {context && (
                        <Grid item>
                          <b>Context:</b>{' '}
                          <span className={classes.description}>{context}</span>
                        </Grid>
                      )}
                      <Grid item>
                        <b>Prompt:</b>{' '}
                        <span className={classes.description}>{query}</span>
                      </Grid>
                      <Grid item>
                        <b>Response:</b>{' '}
                        <Markdown className={classes.description}>
                          {response}
                        </Markdown>
                      </Grid>
                    </Grid>
                  )
                })}
              </Grid>
            ) : (
              <span style={{ color: '#867d82', padding: '10px' }}>
                No Prompt History Found
              </span>
            )}
          </Grid>
        </Grid>
      ) : (
        ''
      )}
    </Grid>
  )
}

export default PromptPlayground
