import React from 'react'
import PropTypes from 'prop-types'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import Clear from '@mui/icons-material/Clear'

export const ModalDialog = ({
  onClose = () => {},
  open = false,
  title = '',
  contentText = '',
  children = [],
  renderActions = () => {},
}) => (
  <div>
    <Dialog
      open={open}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <AppBar className="dialogTitleBar">
        <Toolbar className="dialogTitleHeight">
          <Typography color="inherit" className="dialogTitle">
            {title}
          </Typography>
          <IconButton
            className="dialogClose"
            aria-label="Delete"
            onClick={onClose}
            size="large"
          >
            <Clear />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <DialogContentText>{contentText}</DialogContentText>
        {children}
      </DialogContent>
      <DialogActions>{renderActions()}</DialogActions>
    </Dialog>
  </div>
)

ModalDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  contentText: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.any,
  renderActions: PropTypes.func,
}

export default ModalDialog
