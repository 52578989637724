import React from 'react'
import PropTypes from 'prop-types'
import { get, isEmpty, filter } from 'lodash'

import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepButton from '@mui/material/StepButton'
import Button from '@mui/material/Button'
import Moment from 'moment-timezone'
import { connect } from 'react-redux'
import ChevronRight from '@mui/icons-material/ChevronRight'
import ChevronLeft from '@mui/icons-material/ChevronLeft'
import SendIcon from '@mui/icons-material/Send'
import Grid from '@mui/material/Grid'
import CircularProgress from '@mui/material/CircularProgress'
import {
  createProjectTask,
  editProjectTask,
} from './../../store/task/actionCreator'
import { TASK_TYPE_PARAM_ID_MAP } from '../../constants/projectTask'
import withRouter from '../../containers/Router/WithRouter'

const styles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: useTheme().spacing(),
  },
  completed: {
    display: 'inline-block',
  },
  buttonStep: {
    boxShadow: '0 2px 5px 0 rgba(0,0,0,.26)',
    color: '#212121',
    backgroundColor: '#fafafa',
  },
  createTaskButton: {
    boxShadow: '0 2px 5px 0 rgba(0,0,0,.26)',
    color: '#FFFFFF',
    letterSpacing: 'unset',
  },
  rightIcon: {
    marginLeft: 4,
  },
  leftIcon: {
    marginRight: 4,
  },
  head: {
    textAlign: 'center',
    color: '#8D8D8D',
  },
  buttonProgress: {
    color: '#2196f3',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  unSavedWarning: {
    color: '#CDB962',
    paddingTop: 10,
  },
}))

export class HorizontalNonLinearAlternativeLabelStepper extends React.Component {
  constructor(props) {
    super(props)
    const { queryParams = {} } = props
    this.state = {
      activeStep: 0,
      completed: new Set(),
      skipped: new Set(),
      loadingCreate: false,
      taskType: queryParams.taskType || '',
    }
    this.handleSubmitTask = this.handleSubmitTask.bind(this)
    this.handleReset = this.handleReset.bind(this)
    this.totalSteps = this.getSteps().length
  }

  isStepOptional = () => {
    // return step === 1
  }

  getSteps() {
    const { activeStep = 0, taskType } = this.state
    const { info = {} } = this.props
    //this.props.location.pathname
    const { task = {} } = info
    const { current = {}, default: defaultData } = task
    const { assets = [], participants = [] } = current
    const { assets: defaultAssets, participants: defaultParticipants } =
      defaultData
    let assetsCount = assets.length
      ? filter(assets, ['isChecked', true]).length
      : filter(defaultAssets, ['isChecked', true]).length
    let participantsCount = participants.length || defaultParticipants.length
    const stepsLabels = [
      {
        label: participantsCount + ' People Added',
        completed: !!participantsCount && activeStep >= 1,
      },
      {
        label: 'Add Notifications',
        completed: !!assetsCount && !!participantsCount && activeStep === 2,
      },
    ]
    if (taskType !== 'upload') {
      stepsLabels.unshift({
        label: assetsCount + ' Assets Added',
        completed: !!assetsCount && activeStep >= 0,
      })
    }

    return stepsLabels
  }

  handleNext = () => {
    let activeStep

    if (this.isLastStep() && !this.allStepsCompleted()) {
      // It's the last step, but not all steps have been completed
      // find the first step that has been completed
      const steps = this.getSteps()
      activeStep = steps.findIndex((step, i) => !this.state.completed.has(i))
    } else {
      activeStep = this.state.activeStep + 1
    }
    this.setState({
      activeStep,
    })
    this.props.onChangeValue(activeStep)
    this.handleCompleteStep(activeStep)
  }

  handleBack = () => {
    const { activeStep } = this.state
    this.setState({
      activeStep: activeStep - 1,
    })
    this.props.onChangeValue(activeStep - 1)
  }

  handleStep = (step) => () => {
    this.setState({
      activeStep: step,
    })
    this.props.onChangeValue(step)
  }
  handleSubmitTask() {
    let dataAssets = []
    let dataParticipants = []
    let dataNotification = []
    const { taskType } = this.state
    const isUploadTask = taskType === 'upload'
    const { info = {} } = this.props
    const { editTaskInfo = {}, isEdit, task = {} } = info
    const { current = {}, taskFormData = {} } = task
    const { scheduledDate, scheduledTime, taskName = '' } = taskFormData
    const task_type = TASK_TYPE_PARAM_ID_MAP[taskType]
    if (Object.keys(task.default.assets).length > 0) {
      if (Object.keys(current.assets).length > 0) {
        dataAssets = current.assets
      } else {
        dataAssets = task.default.assets
      }
    }
    // TODO i need to understand more about why do we need default check for assets & participants
    if (Object.keys(task.default.participants).length > 0 || isUploadTask) {
      if (Object.keys(current.participants).length > 0) {
        dataParticipants = current.participants
      } else {
        dataParticipants = task.default.participants
      }
    }
    if (Object.keys(current.notification).length > 0) {
      dataNotification = current.notification
    } else {
      dataNotification = task.default.notification
    }
    let notification_details = []
    if (dataNotification && dataNotification.notifications.length) {
      dataNotification.notifications.map((notification = {}) => {
        let sentTo = notification.sentTo.name.replace(
          new RegExp(' ', 'gi'),
          '_'
        )
        let notificationObj = {
          send_to: sentTo,
          notification_type: notification.taskType || notification.type,
          email_from_initator:
            notification.from !== 'assethub-donotreply@target.com',
          cc: notification.showCC,
          email_schedule_date: notification.scheduleDate
            ? new Date(
                Moment.tz(notification.scheduleDate, 'America/chicago').format()
              )
            : '',
          email_body: notification.text,
          email_subject: notification.subject,
        }
        notification_details.push(notificationObj)
      })
    }

    let postData = {
      remove_participants: [],
      assets: isUploadTask ? [] : dataAssets,
      participants: dataParticipants,
      notifications: dataNotification,
      dueDate: scheduledDate + ' ' + scheduledTime,
      name: taskName,
      project: {
        review_job_id:
          get(this.props, 'task.taskList.userAssets.review_job_id', '') ||
          get(this.props, 'info.project.reviewJobId', ''),
        job_due_date:
          get(this.props, 'task.taskList.userAssets.due_date', '') ||
          get(this.props, 'info.project.dueDate', ''),
        folder_id:
          get(this.props, 'task.taskList.userAssets.uoi_id', '') ||
          get(this.props, 'info.project.uoiId', ''),
        project_uuid:
          get(this.props, 'projectId', '') ||
          get(this.props, 'task.taskList.userAssets.project_uuid', '') ||
          get(this.props, 'info.project.projectUuid', ''),
        folder_name:
          get(this.props, 'task.taskList.userAssets.project_name', '') ||
          get(this.props, 'info.project.name', ''),
      },
      notification_details: notification_details,
      task_type,
    }
    if (isEdit) {
      this.props.editProjectTask(
        postData.project,
        postData,
        editTaskInfo.job_id
      )
    } else {
      this.props.createProjectTask(postData.project, postData)
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.task.loading) {
      this.setState({
        loadingCreate: nextProps.task.loading.createTaskLoading,
      })
      if (Object.keys(nextProps.task.taskCreate).length > 0) {
        let projectId =
          get(nextProps, 'projectId', '') ||
          get(nextProps, 'task.taskList.userAssets.project_uuid', '') ||
          get(nextProps, 'info.project.projectUuid', '')
        let url = '/project/' + projectId + '/tasks/list'
        this.props.router.navigate(url)
      }
    }
  }
  handleCompleteStep(step) {
    const completed = new Set(this.state.completed)
    completed.add(step - 1)
    this.setState({
      completed,
    })
  }

  handleComplete = () => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const completed = new Set(this.state.completed)
    completed.add(this.state.activeStep)
    this.setState({
      completed,
    })

    /**
     * Sigh... it would be much nicer to replace the following if conditional with
     * `if (!this.allStepsComplete())` however state is not set when we do this,
     * thus we have to resort to not being very DRY.
     */
    if (completed.size !== this.totalSteps) {
      this.handleNext()
    }
  }

  handleReset() {
    let projectId =
      get(this.props, 'projectId', '') ||
      get(this.props, 'task.taskList.userAssets.project_uuid', '') ||
      get(this.props, 'info.project.projectUuid', '')
    let url = '/project/' + projectId + '/tasks/list'
    this.props.router.navigate(url)
  }

  isStepComplete(step) {
    return this.state.completed.has(step)
  }

  completedSteps() {
    return this.state.completed.size
  }

  allStepsCompleted() {
    return this.completedSteps() === this.totalSteps
  }

  isLastStep() {
    return this.state.activeStep === this.totalSteps - 1
  }

  render() {
    const { classes } = this.props
    const steps = this.getSteps()
    const { activeStep, taskType } = this.state
    let buttonActive =
      steps[0].completed && (steps[1].completed || taskType === 'upload')
    let buttonText = ''
    if (this.props.info.isEdit) {
      buttonText = 'SAVE TASK WITH NOTIFICATION'
      if (
        !isEmpty(this.props.info.task.current.notification) &&
        !this.props.info.task.current.notification.notifications.length
      ) {
        buttonText = 'SAVE TASK WITHOUT A NOTIFICATION'
      }
    } else {
      buttonText = 'CREATE TASK WITH NOTIFICATION'
      if (
        !isEmpty(this.props.info.task.current.notification) &&
        !this.props.info.task.current.notification.notifications.length
      ) {
        buttonText = 'CREATE TASK WITHOUT A NOTIFICATION'
      }
    }
    return (
      <div className={classes.root}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12} flex className={classes.head}>
            <h2>
              {this.props.info.isEdit ? (
                <span>Editing Task: </span>
              ) : (
                <span>Creating Task: </span>
              )}
              {this.props.info.task.taskFormData.taskName}
            </h2>
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'right' }}>
            {activeStep === 0 ? (
              <Button
                data-cy="taskCancelButton"
                onClick={this.handleReset}
                className={classes.buttonStep}
              >
                <ChevronLeft className={classes.leftIcon} /> Cancel Task
              </Button>
            ) : (
              <Button
                data-cy="taskPreviousButton"
                onClick={this.handleBack}
                className={classes.buttonStep}
              >
                <ChevronLeft className={classes.leftIcon} /> Previous Step
              </Button>
            )}
          </Grid>
          <Grid item xs={8}>
            <Stepper
              alternativeLabel
              nonLinear
              activeStep={activeStep}
              style={{ margin: '24px', padding: 'unset !important' }}
            >
              {steps.map((step, index) => (
                <Step key={step}>
                  <StepButton
                    onClick={this.handleStep(index)}
                    completed={step.completed}
                  >
                    {step.label}
                  </StepButton>
                </Step>
              ))}
            </Stepper>
          </Grid>
          <Grid item xs={2}>
            {this.totalSteps - 1 !== activeStep ? (
              <Button
                data-cy="taskNextButton"
                onClick={this.handleNext}
                className={classes.buttonStep}
              >
                Next Step <ChevronRight className={classes.rightIcon} />
              </Button>
            ) : (
              <div>
                <Button
                  data-cy="taskCreateButton"
                  onClick={this.handleSubmitTask}
                  className={classes.createTaskButton}
                  variant="contained"
                  color="secondary"
                  disabled={!buttonActive}
                >
                  {buttonText}
                  <SendIcon className={classes.rightIcon} />
                  {this.state.loadingCreate ? (
                    <CircularProgress size={24} style={styles.buttonProgress} />
                  ) : (
                    ''
                  )}
                </Button>
                {!buttonActive ? (
                  <div className={classes.unSavedWarning}>
                    Unsaved Changes - Save to Continue
                  </div>
                ) : (
                  ''
                )}
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    )
  }
}

HorizontalNonLinearAlternativeLabelStepper.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  classes: PropTypes.object,
  projectId: PropTypes.string,
  task: PropTypes.shape({
    loading: PropTypes.object,
    taskCreate: PropTypes.object,
    selectedTaskName: PropTypes.string,
    taskList: PropTypes.shape({
      userAssets: PropTypes.shape({
        review_job_id: PropTypes.string,
        uoi_id: PropTypes.string,
        due_date: PropTypes.string,
        project_uuid: PropTypes.string,
        project_name: PropTypes.string,
      }),
    }),
  }),
  info: PropTypes.shape({
    step: PropTypes.number, // 0 -> step1 Default step
    isEdit: PropTypes.bool,
    editTaskInfo: PropTypes.bool,
    createTaskLoading: PropTypes.bool,
    project: PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string,
      projectUuid: PropTypes.string,
      id: PropTypes.string,
      projectId: PropTypes.string,
      runDate: PropTypes.string,
      dueDate: PropTypes.string,
      uoiId: PropTypes.string,
      reviewJobId: PropTypes.string,
    }),
    task: PropTypes.shape({
      taskFormData: PropTypes.shape({
        taskName: PropTypes.string,
        scheduledDate: PropTypes.string,
        scheduledTime: PropTypes.string,
        taskType: PropTypes.string,
      }),
      default: PropTypes.shape({
        assets: PropTypes.array,
        participants: PropTypes.array,
        notification: PropTypes.array,
      }),
      current: PropTypes.shape({
        assets: PropTypes.array,
        participants: PropTypes.array,
        notification: PropTypes.array,
        defaultNotification: PropTypes.object,
      }),
    }),
  }),
  onChangeValue: PropTypes.func,
  createProjectTask: PropTypes.func,
  editProjectTask: PropTypes.func,
  queryParams: PropTypes.shape({
    taskType: PropTypes.string,
  }),
}
HorizontalNonLinearAlternativeLabelStepper.contextTypes = {
  router: PropTypes.object.isRequired,
}
const mapStateToProps = (state = {}) => {
  let { task } = state
  return {
    task: task,
  }
}

const MyComponent = (props) => {
  const classes = styles()
  return (
    <HorizontalNonLinearAlternativeLabelStepper {...props} classes={classes} />
  )
}

export default connect(mapStateToProps, { createProjectTask, editProjectTask })(
  withRouter(MyComponent)
)
