import { FILE_CONVERSION_CONST } from '../constants/projects'

const bytesToSize = (bytes, withDecimalPrecision = false) => {
  let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (bytes === 0) return '0 Byte'
  let i = parseInt(
    Math.floor(Math.log(bytes) / Math.log(FILE_CONVERSION_CONST))
  )
  return withDecimalPrecision
    ? (bytes / Math.pow(FILE_CONVERSION_CONST, i)).toFixed(1) + ' ' + sizes[i]
    : Math.round(bytes / Math.pow(FILE_CONVERSION_CONST, i), 2) + ' ' + sizes[i]
}

const parseMissingChunkExceptionMessage = (message) => {
  if (message && message.includes('Missing chunks')) {
    const listStartIndex = message.indexOf('[') + 1
    const listEndIndex = message.indexOf(']')

    if (listStartIndex > 0 && listEndIndex > 0) {
      return message
        .substring(listStartIndex, listEndIndex)
        .split(',')
        .map((chunk) => parseInt(chunk, 10))
    }
  }
  return null
}

export { bytesToSize, parseMissingChunkExceptionMessage }
