import React, { useEffect } from 'react'
import { withStyles } from '@mui/styles'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { bindActionCreators } from 'redux'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Avatar from '@mui/material/Avatar'
import { FilterHdr } from '@mui/icons-material'
import GroupIcon from '@mui/icons-material/Group'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { withAuth } from '@praxis/component-auth'
import { withEnv } from '@praxis/component-runtime-env'
import HeaderTitle from '../../Header/HeaderTitle'
import HeaderTabs from '../Search/HeaderTabs'
import { requestChannelsEvent } from '../../../store/channelUpload/channelsActionCreator'
import ChannelDetails from './ChannelDetails'
import MemberDetails from './MemberDetails'
import PublishAssetDetailContainer from './PublishAssetDetailContainer'
import ImageUploader from '../Upload/ImageUploader'
import CreateNewChannel from './CreateNewChannel'
import InfoPage from '../InfoPage'

const styles = (theme) => ({
  partnerGrid: {
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
    padding: '25px 20px',
  },
  cardContent: {
    fontSize: '12px',
    paddingLeft: '0px',
    paddingRight: '0px',
    marginBottom: '-20px',
  },
  assetDetailsCard: {
    padding: '5px',
  },
  assetGrid: {
    paddingTop: '20px !important',
  },
  cardHeader: {
    padding: '10px 20px 5px !important',
  },
  avatar: {
    height: '42px',
    width: '42px',
  },
  cardHeaderContent: {
    marginTop: '-5px',
  },
})
function ChannelPublish({
  classes = {},
  history = {},
  router = {},
  assetsCount = Number(0),
  selectedChannel = {},
  membersCount = Number(0),
  members = [],
  channelAssets = [],
  requestChannelsEvent = () => {},
  auth: { isAuthorized = () => {} } = {},
  env: { REACT_APP_ROLES_CONFIG = {} } = {},
}) {
  const { channel_id: selectedChannelId = '' } = selectedChannel

  const { publishAssets = [] } = REACT_APP_ROLES_CONFIG

  useEffect(() => {
    requestChannelsEvent()
  }, [])

  return (
    <>
      <HeaderTitle title="Channel Publish Upload" icon="channelUpload" />
      <Helmet>
        <title>{'Channel Publish Upload'}</title>
      </Helmet>
      <HeaderTabs
        activeTabIndex={'ChannelPublish'}
        id={'PublishHeader'}
        history={history}
        router={router}
      />
      <CreateNewChannel />

      {isAuthorized(publishAssets) ? (
        <div>
          <Grid container>
            <Grid item md={4} xs={12} sm={6}>
              <Grid container className={classes.partnerGrid}>
                <Grid item>
                  <Card>
                    <CardHeader title="Channels" />
                    <CardContent>
                      <ChannelDetails />
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item>
                  {selectedChannelId && (
                    <Card>
                      <CardHeader
                        avatar={
                          <Avatar
                            aria-label="Cloud Upload"
                            className={classes.avatar}
                          >
                            <CloudUploadIcon />
                          </Avatar>
                        }
                        title="Upload"
                        classes={{
                          root: classes.cardHeader,
                          content: classes.cardHeaderContent,
                        }}
                      />
                      <CardContent>
                        <ImageUploader selectedChannelId={selectedChannelId} />
                      </CardContent>
                    </Card>
                  )}
                </Grid>
                <Grid item>
                  {selectedChannelId && (
                    <Card>
                      <CardHeader
                        avatar={
                          <Avatar
                            aria-label="Group Icon"
                            className={classes.avatar}
                          >
                            <GroupIcon />
                          </Avatar>
                        }
                        classes={{
                          root: classes.cardHeader,
                          content: classes.cardHeaderContent,
                        }}
                        title={`${membersCount} Members`}
                      />
                      <CardContent>
                        <MemberDetails members={members} />
                      </CardContent>
                    </Card>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={8} xs={12} sm={6} className={classes.assetGrid}>
              {selectedChannelId && (
                <Card className={classes.assetDetailsCard}>
                  <CardHeader
                    avatar={
                      <Avatar aria-label="Assets" className={classes.avatar}>
                        <FilterHdr />
                      </Avatar>
                    }
                    classes={{
                      root: classes.cardHeader,
                      content: classes.cardHeaderContent,
                    }}
                    title={`${
                      assetsCount ? assetsCount : channelAssets.length
                    } Assets`}
                  />
                  <CardContent className={classes.cardContent}>
                    <PublishAssetDetailContainer />
                  </CardContent>
                </Card>
              )}
            </Grid>
          </Grid>
        </div>
      ) : (
        <InfoPage />
      )}
    </>
  )
}

const mapStateToProps = (state = {}) => {
  const { channelPublish = {} } = state
  const {
    assetsCount = Number(0),
    membersCount = Number(0),
    members = [],
    selectedChannel = {},
    channelAssets = [],
  } = channelPublish
  return {
    selectedChannel,
    assetsCount,
    membersCount,
    members,
    channelAssets,
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ requestChannelsEvent }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withEnv()(withAuth()(withStyles(styles)(ChannelPublish))))
