import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@mui/styles'
import HeaderTitle from '../Header/HeaderTitle'
import CustomTable from '../CustomTable/CustomTable'
import {
  classificationBasicColumns,
  notesColumn,
  classificationTableConfig,
} from '../../constants/classification'
import {
  modifyClassificationData,
  modifyMetadataColumns,
} from '../../helpers/classificationHelper'
import {
  getClassificationMetadataHeaders,
  getAllClassificationData,
} from '../../store/classification/actionCreator'

import ClassificationUploadDialog from './ClassificationUploadDialog'

const useStyles = makeStyles({
  classificationTable: {
    padding: '20px',
    maxWidth: 'fit-content',
    maxHeight: 'fit-content',
  },
})

function AssetClassification() {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [classificationTableData, setClassificationTableData] = useState([])
  const metadataTypes =
    useSelector(
      ({ assetClassificationReducer: { metadataTypes = [] } }) => metadataTypes
    ) || []
  const classificationData =
    useSelector(
      ({ assetClassificationReducer: { classificationData = [] } }) =>
        classificationData
    ) || []

  let metaDataColumns = modifyMetadataColumns(metadataTypes)

  const tableHeaderColumns = classificationBasicColumns.concat(
    metaDataColumns,
    notesColumn
  )

  useEffect(() => {
    if (classificationData?.length) {
      const updatedData = modifyClassificationData(classificationData)
      setClassificationTableData(updatedData)
    }
  }, [classificationData])

  useEffect(() => {
    dispatch(getClassificationMetadataHeaders())
    dispatch(getAllClassificationData())
  }, [])

  return (
    <div>
      <HeaderTitle title="Asset Classification" icon="Classification" />
      {classificationTableData && classificationTableData.length ? (
        <div className={classes.classificationTable}>
          <CustomTable
            columns={tableHeaderColumns}
            data={classificationTableData}
            tableConfig={classificationTableConfig}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

export default AssetClassification
