// User
export const ADMIN_GROUP = 'APP-DAPS-PUBLISH-ADMIN'
export const WRITE_GROUP = 'APP-DAPS-PUBLISH-USER'

export const PERMISSION_SETS = {
  admin: [ADMIN_GROUP],
  user: [ADMIN_GROUP, WRITE_GROUP],
}

// Publish
export const calendarStrings = {
  lastDay: '[Yesterday at] LT',
  sameDay: '[Today at] LT',
  nextDay: '[Tomorrow at] LT',
  lastWeek: '[Last] dddd [at] LT',
  nextWeek: 'dddd [at] LT',
  sameElse: 'L',
}
