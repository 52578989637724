import axios from 'axios'

import {
  BOARD_ADD_ASSETS,
  BOARD_REMOVE_ASSETS,
  BOARD_FETCH_ASSETS,
  BOARDS_FILTER,
  BOARDS_SORT,
  CREATE_BOARD,
  DELETE_BOARD,
  FETCH_USER_BOARDS,
  FETCH_BOARD,
  OPEN_MODAL,
  CLOSE_MODAL,
  TOGGLE_BOARDS_DIALOG,
  GET_SELECTED_ASSETS,
  EXPAND_BOARD_GROUP,
  EXPAND_BOARD_GROUP_FULFILLED,
  EXPAND_BOARD_GROUP_REJECTED,
  COLLAPSE_GROUP,
  UPDATE_BOARD,
  SET_BOARD_DOWNLOAD_ASSETS,
  TOGGLE_REDIRECT_BOARD_LIST,
  FETCH_USER_LIST_BOARD,
  ADD_USERS_TO_BOARD,
  DELETE_SPECIFIC_USER_FROM_BOARD,
  UPDATE_ACCESS_TO_BOARD_MEMBER,
} from './actionTypes'
import apiConfig from '../../config/apiConfig'
import firefly from '../../analytics/firefly'
import {
  BetaAnalytics,
  trackBetaSearchAnalytics,
} from '../../analytics/betaSearch'
import { setDownloadAssetInProgress } from '../search/actions'
import { showNotification } from '../notification/actionCreator'

const KEY = apiConfig.key

export function toggleBoardsRigthNav() {
  return {
    type: TOGGLE_BOARDS_DIALOG,
  }
}

export function setBoardDownloadAssets(downloadAssets = {}) {
  return {
    type: SET_BOARD_DOWNLOAD_ASSETS,
    downloadAssets,
  }
}

export function getSelectedAssets(selectedAssets = []) {
  return {
    type: GET_SELECTED_ASSETS,
    selectedAssets,
  }
}

export const openModal = (modalName = '', modalData = {}) => ({
  type: OPEN_MODAL,
  modalName,
  modalData,
})

export function closeModal(modalName) {
  return {
    type: CLOSE_MODAL,
    modalName,
  }
}

export function boardsFilter(payload) {
  return {
    type: BOARDS_FILTER,
    payload,
  }
}

export function boardsSort(payload) {
  return {
    type: BOARDS_SORT,
    payload,
  }
}

const fetchBoards =
  (sortType = {}) =>
  (dispatch) => {
    const boardsUrl = `${apiConfig.boards.boardListUrl}?key=${KEY}`
    const promise = axios.get(boardsUrl)
    return dispatch({
      type: FETCH_USER_BOARDS,
      payload: promise,
    })
  }

const fetchBoard =
  (boardId = '', isAssetFetchRequired = true) =>
  (dispatch) => {
    const boardUrl = `${apiConfig.boards.boardsRootUrl}/${boardId}?key=${KEY}`
    const promise = axios.get(boardUrl)
    return dispatch({
      type: FETCH_BOARD,
      payload: promise,
    }).then(({ value = {} }) => {
      const { data: board = {} } = value
      if (board.board_id && isAssetFetchRequired) {
        dispatch(fetchBoardAssets([board.board_id]))
      }
    })
  }

const createBoard =
  (board_name = '', assets = [], lanId = '') =>
  (dispatch) => {
    const boardsUrl = `${apiConfig.boards.boardsRootUrl}?key=${KEY}`
    const promise = axios.post(boardsUrl, {
      board_name: board_name,
      assets: assets,
    })
    return dispatch({
      type: CREATE_BOARD,
      payload: promise,
    }).then((response) => {
      const { value = {} } = response
      const { data = {} } = value
      firefly.trackBoardCreated(data.board_id, lanId)
      dispatch(fetchBoards())
    })
  }

const updateBoard =
  ({ board_id = '', board_name = '', lanId = '', ai_eligible = false }) =>
  (dispatch) => {
    const boardsUrl = `${apiConfig.boards.boardsRootUrl}/${board_id}?key=${KEY}`
    const promise = axios.put(boardsUrl, { board_name, ai_eligible })
    return dispatch({
      type: UPDATE_BOARD,
      payload: promise,
    }).then((response) => {
      const { value = {} } = response
      const { data = {} } = value
      firefly.trackBoardUpdated(data.board_id, lanId)
      dispatch(fetchBoard(board_id, false))
    })
  }

const deleteBoard =
  ({ boardId = '', lanId = '' }) =>
  (dispatch) => {
    const boardsUrl = `${apiConfig.boards.boardsRootUrl}/${boardId}?key=${KEY}`
    const promise = axios.delete(boardsUrl)
    return dispatch({
      type: DELETE_BOARD,
      payload: promise,
    }).then(() => {
      firefly.trackBoardDeleted(boardId, lanId)
      //dispatch(fetchBoards())
    })
  }

const fetchBoardAssets =
  (boardIdList = []) =>
  (dispatch) => {
    const boardAssetsUrl = `${apiConfig.boards.boardAssetsUrl}?key=${KEY}`
    const promise = axios.post(boardAssetsUrl, boardIdList)
    return dispatch({
      type: BOARD_FETCH_ASSETS,
      payload: promise,
    })
  }

const boardAddAssets =
  (
    payload = {},
    lanId = '',
    location = '',
    sortType = {},
    successCallBack = () => {},
  ) =>
  (dispatch, getState) => {
    const boardAssetsUrl = `${apiConfig.boards.addAssetsToBoardUrl}?key=${KEY}`
    const promise = axios.post(boardAssetsUrl, payload)

    const { auth = {} } = getState() || {}
    return dispatch({
      type: BOARD_ADD_ASSETS,
      payload: promise,
    }).then(() => {
      firefly.trackBoardAddAsset(payload, lanId, location)
      trackBetaSearchAnalytics({
        auth,
        trackingData: {
          eventType: BetaAnalytics.event.ASSET_USAGE,
          eventName: BetaAnalytics.event.ADD_ASSETS_TO_BOARDS,
          eventData: { payload },
        },
      })
      successCallBack()
      setTimeout(() => {
        //dispatch(fetchBoardAssets(Object.keys(payload)))
        //dispatch(setDownloadAssetInProgress(false))
        //dispatch(boardsSort({ sortType }))
        dispatch(fetchBoards({}))
      }, 1000)
    })
  }

const boardRemoveAssets =
  (assetIds = [], boardId = '', lanId = '') =>
  (dispatch) => {
    const payload = [...assetIds]
    const trackingPayload = {
      board_id: boardId,
      assets: assetIds,
    }

    const boardsUrl = `${apiConfig.boards.boardsRootUrl}/${boardId}/assets?key=${KEY}`
    const promise = axios.delete(boardsUrl, { data: payload })
    return dispatch({
      type: BOARD_REMOVE_ASSETS,
      payload: promise,
    })
      .then((res) => {
        dispatch(fetchBoardAssets([boardId]))
        firefly.trackBoardRemoveAsset(trackingPayload, lanId)
      })
      .catch(showNotification(true, 'Error removing asset(s)', 'error', 5000))
  }

const expandGroup =
  (groupId = '') =>
  (dispatch) => {
    const groupsUrl = `${
      apiConfig.groups.groupUserDetails + groupId
    }?key=${KEY}`
    const promise = axios.get(groupsUrl)
    dispatch(expandGroupInnitiated(groupId))
    return promise
      .then((response) => {
        dispatch(expandGroupFulfilled(response.data, groupId))
      })
      .catch((error) => {
        dispatch(expandGroupRejected(error, groupId))
      })
  }

const expandGroupInnitiated = (groupId) => ({
  type: EXPAND_BOARD_GROUP,
  payload: groupId,
})

const expandGroupFulfilled = (data, groupId) => ({
  type: EXPAND_BOARD_GROUP_FULFILLED,
  payload: {
    data,
    groupId,
  },
})

const expandGroupRejected = (data, groupId) => ({
  type: EXPAND_BOARD_GROUP_REJECTED,
  payload: {
    data,
    groupId,
  },
})

const collapseGroup = (groupId) => ({
  type: COLLAPSE_GROUP,
  payload: groupId,
})

const toggleRedirectBoardList = (isRedirectToBoardList = false) => ({
  type: TOGGLE_REDIRECT_BOARD_LIST,
  payload: { isRedirectToBoardList },
})

const fetchBoardUsersDetails =
  (boardId = '', callBack = () => {}) =>
  (dispatch) => {
    const boardUrl = `${apiConfig.boards.boardUsersDetailsUrl}/${boardId}`
    const promise = axios.get(boardUrl)
    return dispatch({
      type: FETCH_USER_LIST_BOARD,
      payload: promise,
    })
      .then(({ value = {} }) => {
        const { data = [] } = value
        callBack(data)
      })
      .catch(() => {})
  }

const addUsersToBoard =
  (payload = {}, lanId = '', callBack = () => {}) =>
  (dispatch) => {
    const boardUrl = `${apiConfig.boards.boardUsersDetailsUrl}`
    const promise = axios.post(boardUrl, payload)
    return dispatch({
      type: ADD_USERS_TO_BOARD,
      payload: promise,
    })
      .then(() => {
        firefly.trackBoardUpdated(payload.board_id, lanId)
        callBack()
      })
      .catch(() => {
        callBack()
      })
  }

const deleteSpecificUserFromBoard =
  (
    boardUserId = '',
    listId = '',
    lanId = '',
    boardId = '',
    callBack = () => {},
  ) =>
  (dispatch) => {
    const boardsUrl = `${apiConfig.boards.boardUsersDetailsUrl}/${boardUserId}`
    const payload = listId ? { listId } : {}
    const promise = axios.delete(boardsUrl, payload)
    return dispatch({
      type: DELETE_SPECIFIC_USER_FROM_BOARD,
      payload: promise,
    })
      .then(() => {
        firefly.trackBoardUpdated(boardId, lanId)
        callBack()
      })
      .catch(() => {})
  }

const updateAccessToBoardMember =
  (boardUserId = '', roleId = '', listId = '', callBack = () => {}) =>
  (dispatch) => {
    const boardsUrl = `${apiConfig.boards.boardUsersDetailsUrl}/${boardUserId}/${roleId}`
    const payload = listId ? { listId } : {}
    const promise = axios.put(boardsUrl, payload)
    return dispatch({
      type: UPDATE_ACCESS_TO_BOARD_MEMBER,
      payload: promise,
    })
      .then(() => {
        callBack()
      })
      .catch(() => {})
  }

export {
  boardAddAssets,
  boardRemoveAssets,
  createBoard,
  deleteBoard,
  fetchBoardAssets,
  fetchBoards,
  fetchBoard,
  expandGroup,
  expandGroupInnitiated,
  expandGroupFulfilled,
  expandGroupRejected,
  collapseGroup,
  updateBoard,
  toggleRedirectBoardList,
  fetchBoardUsersDetails,
  addUsersToBoard,
  deleteSpecificUserFromBoard,
  updateAccessToBoardMember,
}
