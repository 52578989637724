import {
  SET_USER_NAME,
  SET_PERMISSIONS,
  SET_USER_EMAIL,
  SET_USER_DEPARTMENT,
} from './userActionType'
import {
  WRITE_GROUP,
  ADMIN_GROUP,
} from '../../../components/Publish/helpers/Constants'

export const initialUserState = {
  userName: '',
  canWrite: false,
  isAdmin: false,
  userEmail: '',
  userDepartment: '',
}

export default function userReducer(state = initialUserState, action = {}) {
  const { payload = {} } = action
  switch (action.type) {
    case SET_USER_NAME:
      return {
        ...state,
        userName: payload,
      }
    case SET_PERMISSIONS: {
      return {
        ...state,
        canWrite:
          payload.includes(ADMIN_GROUP) || payload.includes(WRITE_GROUP),
        isAdmin: payload.includes(ADMIN_GROUP),
      }
    }
    case SET_USER_EMAIL:
      return {
        ...state,
        userEmail: payload,
      }
    case SET_USER_DEPARTMENT:
      return {
        ...state,
        userDepartment: payload,
      }
    default:
      return state
  }
}
