import React from 'react'
import { get, isEqual } from 'lodash'
import { TextField } from '@mui/material'
import IconWrapper from '../IconWrapper/IconWrapper'
import { FormikFieldPropTypes } from '../../constants/projects'

export class InfluencerProjectNameField extends React.PureComponent {
  componentDidUpdate(prevProps) {
    const { form: prevForm = {} } = prevProps
    const prevInfluencerName = get(prevForm, 'values.influencer_name', '')
    const prevCampaign = get(prevForm, 'values.campaign', '')

    const { form = {} } = this.props
    const influencerName = get(form, 'values.influencer_name', '')
    const campaign = get(form, 'values.campaign', '')

    if (
      !isEqual(influencerName, prevInfluencerName) ||
      !isEqual(campaign, prevCampaign)
    ) {
      const projectName = `${influencerName} ${campaign} Influencer Content`
      form.setFieldValue('project_name', projectName)
    }
  }

  render() {
    const {
      field = {},
      form = {},
      label = '',
      iconLabel = '',
      isRequired = false,
      ...props
    } = this.props

    return (
      <IconWrapper iconTag={iconLabel}>
        <TextField
          variant="outlined"
          isRequired={isRequired}
          label={label}
          placeholder={`Enter ${label}`}
          InputLabelProps={{ shrink: true }}
          error={!!form.errors[field.name]}
          helperText={form.errors[field.name]}
          fullWidth
          style={{ marginLeft: '10px' }}
          {...field}
          {...props}
        />
      </IconWrapper>
    )
  }
}

InfluencerProjectNameField.propTypes = FormikFieldPropTypes
export default InfluencerProjectNameField
