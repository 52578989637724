import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import * as axios from 'axios'

export class RemoteImageLoader extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      url: '',
      error: '',
      prevSrc: '',
    }
  }

  componentDidMount() {
    const { src = '', renditionUrl = '' } = this.props
    axios
      .get(`${renditionUrl}${src}`, {
        data: {},
        responseType: 'blob',
      })
      .then((response) => {
        let reader = new window.FileReader()
        reader.readAsDataURL(response.data)
        reader.onload = () => {
          this.setState({ url: reader.result, prevSrc: src })
        }
      })
      .catch((error) => {
        this.setState({ error })
      })
  }

  componentDidUpdate(_, prevState) {
    const { src = '', renditionUrl = '' } = this.props

    if (prevState.prevSrc !== src) {
      axios
        .get(`${renditionUrl}${src}`, {
          data: {},
          responseType: 'blob',
        })
        .then((response) => {
          let reader = new window.FileReader()
          reader.readAsDataURL(response.data)
          reader.onload = () => {
            this.setState({ url: reader.result, prevSrc: src })
          }
        })
        .catch((error) => {
          this.setState({ error, prevSrc: src })
        })
    }
  }

  render() {
    const { url, error } = this.state
    return (
      <Fragment>
        {error === '' && <img src={url} alt={'Alt Text'} />}
        {!error === '' && <h3>No Image!</h3>}
      </Fragment>
    )
  }
}

RemoteImageLoader.propTypes = {
  src: PropTypes.string,
  renditionUrl: PropTypes.string,
}

export default RemoteImageLoader
