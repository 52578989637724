import { createTheme, adaptV4Theme } from '@mui/material/styles'

export const praxis = {
  light: '#f05545',
  main: '#43425D',
  dark: '#1a6ec5',
  contrastText: '#FFF',
  lightGrey: '#8d8d8d',
  lighterGrey: '#BDBDBD',
  lightestGrey: '#EFEFEF',
  mediumGrey: '#666',
  midGrey: '#484848',
  darkGrey: '#333',
  lightBlue: '#1a6ec5',
  lighterBlue: '#5471d2',
  darkBlue: '#188295',
  darkerBlue: '#002171',
  sideNavHover: 'rgba(158,158,158,.2)',
  lighterYellow: '#FFFFB0',
  lightYellow: '#FFE57F',
  darkYellow: '#CAB350',
  bostonRed: '#CC0000',
  paleYellow: '#FFFFDA',
  graphite: '#212121',
  primaryBlue: '#0D46A0',
  primaryDarkBlue: '#002071',
  primaryLightBlue: '#5471d2',
  primaryGreen: '#087F23',
  dodgerBlue: '#2196f3',
  whiteSmoke: '#F7F7F7',
  themePrimary: '#43425D',
  darkGreen: '#188295',
}
const praxisTheme = createTheme({
  palette: {
    primary: praxis,
    secondary: praxis,
    mode: 'light'
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '4px 56px 4px 0',
          fontSize: '0.8125rem',
        },
        head: {
          lineHeight: 'unset',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        head: {
          height: '3rem',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          '&.dialogTitleBar': {
            position: 'relative',
            color: '#ffffff',
            backgroundColor: '#188295',
            boxShadow: 'none',
            '& > .dialogTitleHeight': {
              minHeight: 75,
              '& > .dialogTitle': {
                fontSize: 24,
                flex: 1,
              },
              '& > .dialogClose': {
                color: '#ffffff',
                marginRight: -12,
              },
            },
          },
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          '&.UserBar-root-306': {
            color: praxis.mediumGrey,
            backgroundColor: praxis.darkGrey,
          },
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: praxis.lightestGrey,
        },
        barColorPrimary: {
          backgroundColor: praxis.darkerBlue,
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '&.Mui-checkedSecondary': {
            color: '#2196f3',
          },
          '&.Mui-checked': {
            color: '#2196f3 !important',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          '&.pContainer:hover': {
            boxShadow:
              '0px 0px 0px 1px #8ba5d2, 0px 1px 1px 0px #8ba5d2, 0px 2px 1px -1px rgba(0, 0, 0, 0.12)',
          },
          '&:hover .pHeader, &:hover .pFooter': {
            backgroundColor: '#e6ecf5 !important',
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        avatar: {
          color: praxis.contrastText,
          backgroundColor: praxis.lightYellow,
          width: '26px !important',
          height: '26px !important',
          fontSize: '0.8rem !important',
          marginLeft: 3,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: '#188295',
          },
          '&.Mui-colorPrimary': {
            color: '#188295',
          },
          '&.Mui-checkedSecondary': {
            color: praxis.lighterBlue,
          },
        },
        colorSecondary: {
          '&.Mui-checked': {
            color: '#188295',
          },
        },
        colorPrimary: {
          '&.Mui-checked': {
            color: '#188295',
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        button: {
          '&:hover': {
            backgroundColor: praxis.sideNavHover,
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: praxis.darkGrey,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: '',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        subtitle1: {
          color: praxis.themePrimary,
          '& a:visited, a:link': {
            color: praxis.themePrimary,
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          color: praxis.lightGrey,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: praxis.lightGrey,
          '&.Mui-shrink': {
            color: praxis.lightBlue,
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          '&:after, & :before': {
            borderBottom: `0px solid ${praxis.lightBlue} !important`,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        backgroundColor: 'none',
        '&:focus': {
          backgroundColor: 'none !important',
        },
        underline: {
          '&:after, & :before': {
            borderBottom: `0px solid ${praxis.lightBlue} !important`,
          },
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        colorPrimary: {
          color: '#2196f3',
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          backgroundColor: '#efefef',
          padding: '10px 23px !important',
        },
        title: {
          fontSize: 20,
          color: '#484848',
          fontWeight: 'initial',
        },
        subheader: {
          fontSize: 15,
          fontWeight: 'initial',
        },
        action: {
          marginTop: 0,
          height: 24,
          color: '#484848',
        },
        avatar: {
          height: 48,
          width: 48,
          '& > div': {
            backgroundColor: '#FFFFFF',
            '& > svg': {
              color: '#484848',
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          lineHeight: 1.4,
          fontSize: '12px',
          fontWeight: '400',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
          },
        },
        textPrimary: {
          color: '#188295',
          '&:hover': 'rgba(29, 63, 142, 0.12)',
        },
        containedSecondary: {
          backgroundColor: praxis.darkBlue,
          '&:hover': {
            backgroundColor: praxis.darkerBlue,
          },
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          backgroundColor: praxis.darkBlue,
          '& > span div': {
            position: 'absolute',
            top: '28%',
            right: '28%',
            display: 'block',
          },
          '.icon-hover': {
            transition: 'opacity .3s, transform .3s',
            transform: 'rotate(-360deg) scale(.1)',
            opacity: '0',
          },
          '.icon-default': {
            transition: 'opacity .3s, transform .3s',
            paddingLeft: '25px',
          },
          '&:hover': {
            backgroundColor: praxis.darkerBlue,
            '.icon-hover': {
              transform: 'rotate(0deg) scale(1.2)',
              opacity: '1',
              paddingRight: '20px',
            },
            '.icon-default': {
              transform: 'rotate(360deg) scale(.5)',
              opacity: '0',
              paddingLeft: '0px',
            },
          },
        },
        primary: {
          backgroundColor: praxis.darkBlue,
          '&:hover': {
            backgroundColor: praxis.darkBlue,
          },
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          '&.Mui-completed': {
            color: 'green !important',
          },
        },
      },
    },
    MuiPickersToolbar: {
      styleOverrides: {
        toolbar: {
          backgroundColor: praxis.lighterBlue,
        },
      },
    },
    MuiPickersToolbarButton: {
      styleOverrides: {
        toolbarBtn: {
          fontSize: '3.75rem',
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        day: {
          '&:hover': {
            color: praxis.whiteSmoke,
            backgroundColor: praxis.lighterBlue,
          },
        },
        daySelected: {
          '&:hover': {
            color: praxis.whiteSmoke,
            backgroundColor: praxis.lighterBlue,
          },
          backgroundColor: praxis.lighterBlue,
        },
        isSelected: {
          backgroundColor: praxis.lighterBlue,
          '&:hover': {
            color: praxis.whiteSmoke,
            backgroundColor: praxis.lighterBlue,
          },
        },
        current: {
          color: praxis.lightBlue,
        },
      },
    },
    MuiPickersClock: {
      styleOverrides: {
        pin: {
          backgroundColor: praxis.lighterBlue,
        },
      },
    },
    MuiPickersClockPointer: {
      styleOverrides: {
        pointer: {
          backgroundColor: praxis.lighterBlue,
        },
        thumb: {
          backgroundColor: praxis.contrastText,
          borderColor: praxis.lighterBlue,
        },
      },
    },
    MuiPickersClockNumber: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: praxis.lighterBlue,
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: praxis.lightBlue,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: praxis.lightBlue,
          },
        },
      },
    },
  },
})
export default praxisTheme
