import {
  DISPLAY_ERROR_EVENT,
  RECEIVE_ASSETS_SUCCESS,
  CHANGE_CURRENT_ASSETS_PAGE,
  CHANGE_DEFAULT_ASSET_PAGE_SIZE,
  REMOVE_FROM_PENDING_LIST,
  SELECT_ASSET_EVENT,
  SET_SORT_DIRECTION,
  SET_SORT_FIELD,
  DOWNLOAD_START_ASSETS,
  DOWNLOAD_FINISH_ASSETS,
  CHANGE_ASSET_FILTER,
  ADD_IMAGE_TO_PENDING_LIST,
  UPDATE_IMAGE_TO_PENDING_LIST,
} from './actionType'

export const initialState = {
  isLoading: true,
  isFetching: false,
  errorMessage: '',
  isErrorMessageShown: false,
  assetsCount: 0,
  selectedAssets: [],
  sortDirection: 'DESC',
  sortField: 'CREATED_AT',
  assetFilter: '',
  /* Publish Assets Default Properties */
  publishAssets: [],
  currentAssetPage: 0,
  defaultAssetPageSize: 10,
  totalAssetsPages: 0,
  /* Users Default Properties */
  currentMemberDetailPage: 0,
  defaultMemberDetailSize: 50,
  /* File Upload  */
  pendingFiles: [],
  /* show notification */
  isShown: false,
  message: '',
  variant: '',
}
export default function assetPublishUploadReducer(
  state = initialState,
  action = {}
) {
  const { pendingFiles = [] } = state
  switch (action.type) {
    case REMOVE_FROM_PENDING_LIST: {
      const { payload = {} } = action
      const { files = [] } = payload
      let pendingFilesList = [...pendingFiles]
      files.forEach((asset) => {
        const { job_id } = asset
        const found = pendingFilesList.some(
          ({ job_id: currentFileId = '' }) => currentFileId === job_id
        )
        if (found) {
          pendingFilesList = pendingFilesList.filter(
            ({ job_id: pendingFileId = '' }) => pendingFileId !== job_id
          )
        }
      })
      const newState = {
        ...state,
        pendingFiles: pendingFilesList,
      }
      return newState
    }
    case UPDATE_IMAGE_TO_PENDING_LIST: {
      const { payload = {} } = action
      const { pendingFile = {} } = payload
      const { id: pendingFileId = '', job_id = '' } = pendingFile
      const newState = {
        ...state,
        pendingFiles: pendingFiles.map((item) => {
          const { id: fileId = '' } = item
          if (fileId === pendingFileId) {
            return { ...pendingFile, id: job_id }
          } else {
            return item
          }
        }),
      }
      return newState
    }
    case ADD_IMAGE_TO_PENDING_LIST: {
      const { payload = {} } = action
      const { pendingFile = {} } = payload
      const newState = {
        ...state,
        pendingFiles: [...pendingFiles, pendingFile],
      }
      return newState
    }
    case CHANGE_DEFAULT_ASSET_PAGE_SIZE: {
      const { payload = {} } = action
      const { defaultAssetPageSize = Number(0) } = payload
      const newState = {
        ...state,
        defaultAssetPageSize: defaultAssetPageSize,
      }
      return newState
    }
    case CHANGE_CURRENT_ASSETS_PAGE: {
      const { payload = {} } = action
      const { currentPage = Number(0) } = payload
      const newState = {
        ...state,
        currentAssetPage: currentPage,
      }
      return newState
    }
    case DISPLAY_ERROR_EVENT: {
      const { payload = {} } = action
      const { errorMessage = '' } = payload
      const newState = {
        ...state,
        isFetching: false,
        isErrorMessageShown: true,
        errorMessage: errorMessage,
      }
      return newState
    }
    case RECEIVE_ASSETS_SUCCESS: {
      const { payload = {} } = action
      const {
        assetsCount = Number(0),
        publishAssets = {},
        totalAssetsPages = Number(0),
      } = payload
      const newState = {
        ...state,
        isFetching: false,
        isLoading: false,
        assetsCount: assetsCount,
        publishAssets: publishAssets,
        totalAssetsPages: totalAssetsPages,
      }
      return newState
    }
    case SELECT_ASSET_EVENT: {
      const { payload = {} } = action
      const { selectedAssets = {} } = payload
      const newState = {
        ...state,
        selectedAssets: selectedAssets,
      }
      return newState
    }
    case SET_SORT_DIRECTION: {
      const { payload = {} } = action
      const newState = {
        ...state,
        sortDirection: payload,
      }
      return newState
    }
    case SET_SORT_FIELD: {
      const { payload = {} } = action
      const newState = {
        ...state,
        sortField: payload,
      }
      return newState
    }
    case DOWNLOAD_START_ASSETS: {
      const newState = {
        ...state,
        isFetching: true,
      }
      return newState
    }
    case DOWNLOAD_FINISH_ASSETS: {
      const newState = {
        ...state,
        isFetching: false,
      }
      return newState
    }
    case CHANGE_ASSET_FILTER: {
      const { payload = {} } = action
      const newState = {
        ...state,
        assetFilter: payload,
      }
      return newState
    }
    default:
      return state
  }
}
