import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TablePagination,
  TableRow,
  TableContainer,
  Typography,
  Paper,
  Fab,
  CircularProgress,
  Tooltip,
  Checkbox,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import { green } from '@mui/material/colors'
import Link from '@mui/icons-material/Link'
import CheckIcon from '@mui/icons-material/Check'
import Moment from 'react-moment'
import CopyToClipboard from 'react-copy-to-clipboard'
import generatingThumbnail from '../../../images/GeneratingThumbnail.svg?url'
import DetailAssetHeader from './DetailAssetHeader'
import {
  changeCurrentAssetsPage,
  changeDefaultAssetPageSize,
  handleSelectAsset,
  setSortDirection,
  setSortField,
  changeAssetFilter,
  requestAssetsEvent,
  downloadAssets,
} from '../../../store/publishUpload/action'
import { showNotification } from '../../../store/notification/actionCreator'
import { wrapWord, addDefaultSrc } from '../helpers/AssetPublishHelpers'
import { calendarStrings } from '../helpers/Constants'
import firefly from '../../../analytics/firefly'
import { selectUserId } from '../../../store/auth/selector'

const styles = makeStyles((theme) => ({
  root: {
    marginLeft: '8px',
    marginTop: '8px',
    minHeight: '72vh',
  },
  progress: {
    margin: '16px',
    marginLeft: '50%',
    marginTop: '14%',
    color: '#2196f3',
  },
  table: {
    minWidth: '100%',
    height: '65vh',
  },
  iconWrapper: {
    marginRight: '10px',
    marginLeft: '5px',
    height: 24,
    width: 24,
  },
  tableWrapper: {
    maxWidth: '100%',
    overflowX: 'auto',
    minHeight: 580,
    maxHeight: 580,
  },
  title: {
    flex: '10 0 auto',
  },
  paper: {
    padding: useTheme().spacing(2),
    textAlign: 'center',
    height: '100%',
    color: useTheme().palette.text.secondary,
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    color: green[500],
  },
  fileName: {
    width: '326px',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  footerBorder: {
    border: '1px solid #e4e7e7',
  },
}))

function PublishTable(props) {
  const {
    classes = {},
    changeCurrentAssetsPage = () => {},
    changeDefaultAssetPageSize = () => {},
    requestAssetsEvent = () => {},
    downloadAssets = () => {},
    defaultAssetPageSize = 10,
    sortDirection = 'DESC',
    sortField = 'CREATED_AT',
    assetFilter = '',
    publishAssets = [],
    pendingFiles = [],
    currentAssetPage = Number(0),
    assetsCount = Number(0),
    showNotification = () => {},
    selectedAssets = [],
    setSortDirection = () => {},
    setSortField = () => {},
    changeAssetFilter = () => {},
    handleSelectAsset = () => {},
    isLoading = true,
    lanId = '',
  } = props
  const changePageHandler = (event, newpage) => {
    changeCurrentAssetsPage(newpage)
    requestAssetsEvent({
      pageNumber: newpage,
      pageSize: defaultAssetPageSize,
      sortDirection,
      sortBy: sortField,
      assetFilter,
    })
  }

  const changeRowsPerPageHandler = (event) => {
    if (event && event.target) {
      changeDefaultAssetPageSize(event.target.value)
      requestAssetsEvent({
        pageSize: event.target.value,
        sortDirection,
        sortBy: sortField,
        assetFilter,
      })
    }
  }

  const handleClick = (event, checkedAsset) => {
    const selectedIndex = selectedAssets.indexOf(checkedAsset)
    let newSelected = []
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedAssets, checkedAsset)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedAssets.slice(1))
    } else if (selectedIndex === selectedAssets.length - 1) {
      newSelected = newSelected.concat(selectedAssets.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedAssets.slice(0, selectedIndex),
        selectedAssets.slice(selectedIndex + 1)
      )
    }
    handleSelectAsset({
      selectedAssets: newSelected,
    })
  }

  const isSelected = (checkedAsset) => {
    const { job_id: checkedAssetId = '' } = checkedAsset
    return (
      selectedAssets
        .map((item) => {
          const { job_id = '' } = item
          return job_id
        })
        .filter((item) => item === checkedAssetId).length > 0
    )
  }

  const handleSelectAllClick = (event, checked) => {
    let newSelected = []
    if (checked) {
      newSelected = publishAssets.concat(pendingFiles)
    }
    handleSelectAsset({
      selectedAssets: newSelected,
    })
  }

  const downloadSelectedAssets = () => {
    firefly.trackPublishAssetCardDownloads(
      'publish_upload_files_download',
      selectedAssets,
      lanId
    )
    downloadAssets(selectedAssets)
  }

  const searchFilter = () => {
    requestAssetsEvent({
      pageSize: defaultAssetPageSize,
      sortDirection,
      sortBy: sortField,
      assetFilter,
    })
  }

  const copyAssetUrl = (data = {}) => {
    firefly.trackPublishAssetCopy(data, lanId)
    showNotification(true, 'Publish asset URL copied to clipboard ', 'success')
  }

  const emptyRows =
    defaultAssetPageSize -
    Math.min(
      defaultAssetPageSize,
      assetsCount - currentAssetPage * defaultAssetPageSize
    )

  return (
    <div className={classes.root}>
      {isLoading ? (
        <div>
          <CircularProgress className={classes.progress} />
        </div>
      ) : (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer
            sx={{ maxHeight: 460 }}
            className={classes.tableWrapper}
          >
            <Table stickyHeader className={classes.table}>
              <DetailAssetHeader
                numSelected={selectedAssets ? selectedAssets.length : Number(0)}
                onSelectAllClick={handleSelectAllClick}
                rowCount={defaultAssetPageSize}
                sortDirection={sortDirection}
                sortField={sortField}
                requestAssetsEvent={requestAssetsEvent}
                setSortDirection={setSortDirection}
                setSortField={setSortField}
                publishAssetsLength={
                  publishAssets ? publishAssets.length : Number(0)
                }
                downloadSelectedAssets={downloadSelectedAssets}
                assetFilter={assetFilter}
                changeAssetFilter={changeAssetFilter}
                searchFilter={searchFilter}
                assetsCount={assetsCount}
              />
              <TableBody>
                {pendingFiles &&
                  pendingFiles.map((row = {}) => {
                    const {
                      id: rowId = '',
                      status = '',
                      original_file_name = '',
                      created_at = '',
                    } = row
                    return (
                      <TableRow key={rowId}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isSelected(row)}
                            color="primary"
                            onClick={(event) => handleClick(event, row)}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {['NEW', 'UPLOADING'].indexOf(status) !== -1 ? (
                            <CircularProgress
                              size={56}
                              className={classes.fabProgress}
                            />
                          ) : (
                            <Fab
                              color="primary"
                              className={classes.buttonSuccess}
                            >
                              <CheckIcon />
                            </Fab>
                          )}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          // size="small"
                          colSpan={3}
                        >
                          {original_file_name && (
                            <Tooltip title={original_file_name}>
                              <Typography className={classes.fileName}>
                                {original_file_name}
                              </Typography>
                            </Tooltip>
                          )}
                        </TableCell>
                        <TableCell>
                          <Link />
                        </TableCell>
                        <TableCell>{status}</TableCell>
                        <TableCell>
                          {created_at && (
                            <Moment calendar={calendarStrings}>
                              {created_at}
                            </Moment>
                          )}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                {publishAssets.length === 0 ? (
                  <TableRow>
                    <TableCell align="center" colSpan={7}>
                      <Typography className={classes.label}>
                        <strong>No Results</strong>
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  publishAssets &&
                  publishAssets.map((row = {}) => {
                    const findIsSelected = isSelected(row)
                    const {
                      job_id = '',
                      status = '',
                      projected_url = '',
                      original_file_name = '',
                      created_at = '',
                      events: rowEvents = [],
                    } = row
                    return (
                      <TableRow key={job_id}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={findIsSelected}
                            color="primary"
                            onClick={(event) => handleClick(event, row)}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {status === 'COMPLETE' ? (
                            <img
                              alt="Thumbnail"
                              onError={addDefaultSrc}
                              src={`${projected_url.replace(
                                'is/content',
                                'is/image'
                              )}?hei=150&wid=150`}
                              height="56px"
                              width="56px"
                            />
                          ) : (
                            <img
                              alt="Thumbnail"
                              src={`${generatingThumbnail}?hei=150&wid=150`}
                              height="56px"
                              width="56px"
                            />
                          )}
                        </TableCell>
                        <TableCell
                          component="th"
                          // size="small"
                          scope="row"
                          colSpan={3}
                          style={{ padding: '16px 0' }}
                        >
                          <Tooltip title={original_file_name}>
                            <Typography className={classes.fileName}>
                              {original_file_name}
                            </Typography>
                          </Tooltip>
                        </TableCell>
                        <TableCell>
                          <Tooltip title={`${projected_url}`}>
                            <div>
                              <CopyToClipboard
                                text={`${projected_url}`}
                                onCopy={() => copyAssetUrl(row)}
                              >
                                <Link style={{ cursor: 'pointer' }} />
                              </CopyToClipboard>
                            </div>
                          </Tooltip>
                        </TableCell>
                        <TableCell>
                          {status === 'ERROR' ? (
                            <Tooltip
                              title={
                                (
                                  rowEvents.find(
                                    ({ reason = '' }) => reason
                                  ) || {
                                    reason: '',
                                  }
                                ).reason
                              }
                            >
                              <Typography>{status}</Typography>
                            </Tooltip>
                          ) : (
                            status
                          )}
                        </TableCell>
                        <TableCell>
                          {created_at && (
                            <Moment calendar={calendarStrings}>
                              {created_at}
                            </Moment>
                          )}
                        </TableCell>
                      </TableRow>
                    )
                  })
                )}

                {/* {emptyRows > 0 && (
                                    <TableRow style={{ height: 48 * emptyRows }}>
                                        <TableCell colSpan={7} />
                                    </TableRow>
                                )} */}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 75, 200]}
            component="div"
            count={assetsCount}
            rowsPerPage={parseInt(defaultAssetPageSize)}
            page={currentAssetPage}
            SelectProps={{
              native: true,
            }}
            onPageChange={(event, newpage) => changePageHandler(event, newpage)}
            onRowsPerPageChange={(event) => changeRowsPerPageHandler(event)}
          />
        </Paper>
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  const { assetPublishUpload = {}, auth = {} } = state
  const { email = '' } = auth
  const {
    publishAssets = [],
    pendingFiles = [],
    defaultAssetPageSize = Number(10),
    currentAssetPage = Number(0),
    assetsCount = Number(0),
    selectedAssets = [],
    sortDirection = '',
    sortField = '',
    assetFilter = '',
    isLoading = false,
  } = assetPublishUpload
  return {
    email,
    publishAssets: publishAssets,
    pendingFiles: pendingFiles,
    defaultAssetPageSize: defaultAssetPageSize,
    currentAssetPage: currentAssetPage,
    assetsCount: assetsCount,
    selectedAssets: selectedAssets,
    sortDirection: sortDirection,
    sortField: sortField,
    assetFilter: assetFilter,
    isLoading: isLoading,
    lanId: selectUserId()(state),
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestAssetsEvent,
      changeCurrentAssetsPage,
      changeDefaultAssetPageSize,
      showNotification,
      handleSelectAsset,
      setSortDirection,
      setSortField,
      downloadAssets,
      changeAssetFilter,
    },
    dispatch
  )
const MyComponent = (props) => {
  const classes = styles()
  return <PublishTable {...props} classes={classes} />
}

export default connect(mapStateToProps, mapDispatchToProps)(MyComponent)
