import queryString from 'query-string'
import { fieldValidRegex } from './Regexes'
import apiConfig from '../config/apiConfig'

export const capitalizeString = (string = null) =>
  typeof string === 'string'
    ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
    : string

export const splitByUnderscore = (string = null) =>
  typeof string === 'string'
    ? string
      .split('_')
      .map((string) => capitalizeString(string))
      .join(' ')
    : string

export const validateStringField = (fieldValue = '') =>
  fieldValue &&
  typeof fieldValue === 'string' &&
  (fieldValue.match(fieldValidRegex) || []).length > 0

export const validateStringMinMax =
  (min = 0, max = Infinity) =>
    (string = '') =>
      string.length !== 0 &&
      string.length >= min &&
      string.length <= max &&
      typeof string === 'string'

export const createImageURL = (url = '') => {
  const { key = '' } = apiConfig
  const imageQueryParts = {
    key,
    make_square: true,
  }
  const modifiedUrl = getThumbnailToPreviewUrl(url)
  const delimiter = url?.includes('?') ? '&' : '?'
  const imageUrlWithParams = modifiedUrl
    ? `${modifiedUrl}${delimiter}${queryString.stringify(imageQueryParts)}`
    : ''
  return imageUrlWithParams
}

export const getThumbnailToPreviewUrl = (imgSrc) => {
  let previewUrl = imgSrc
  if (previewUrl?.includes('THUMBNAIL')) {
    if (previewUrl?.includes('vault_digital_assets')) {
      previewUrl = imgSrc?.replace('THUMBNAIL', 'PREVIEW&hei=500&wid=500')
    } else {
      previewUrl = imgSrc?.replace('THUMBNAIL', 'PREVIEW&height=500&width=500')
    }
  }
  return previewUrl
}
export const caseInsensitiveIncludes = (searchIn = '', searchFor = '') => {
  if (searchIn === '') return false
  return searchIn.toLowerCase().includes(searchFor.toLowerCase())
}

export const generateBreadcrumb = (breadcrumbs = [], title = false) => {
  const stringArr = breadcrumbs.reduce((header, breadcrumb) => {
    if (title || !breadcrumb.path || breadcrumb.path === '') {
      header.push(breadcrumb.title)
    }
    if (breadcrumb.path) {
      header.push(
        `<a style="text-decoration: none" href="${breadcrumb.path}">${breadcrumb.title}</a>`
      )
    }

    return header
  }, [])

  return stringArr.join(!title ? ' <span style="color: #ccc">/</span> ' : ' / ')
}

export const cleanString = (input = '') => {
  let output = ''
  for (let i = 0; i < input.length; i++) {
    const charCode = input.charCodeAt(i)
    if (
      (charCode <= 254 || [8226, 8482, 8224].includes(charCode)) &&
      ![7].includes(charCode)
    ) {
      output += input.charAt(i)
    }
  }
  return output
}

export const validateEmail = (email = '') => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export function extractEmails(text = '') {
  return text.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi)
}

export function formatNumberToString(num = 0) {
  if (typeof num !== typeof 1) {
    return NaN
  }

  return num.toLocaleString('en')
}

export const userDisplayName = (company = '', emailAddress = '') => {
  const displayCompanyName = emailAddress.includes('@target.com')
    ? ''
    : `(${company})`
  return `${emailAddress}${displayCompanyName}`
}

export const handleStringBreak = (text) => {
  const strArr = text.split(' ')
  let textArray = []
  strArr.map((option) => {
    const isTrigger = handleHighlight(option)
    if (isTrigger) {
      const index = option.indexOf(']')
      const subText = option.substr(0, index + 1)
      textArray.push(subText)
      const lIndex = option.indexOf(')')
      if (option.length > lIndex + 1) {
        const substringTxt = option.substr(lIndex + 1, option.length)
        textArray.push(substringTxt)
      }
    } else {
      textArray.push(option)
    }
  })
  return textArray
}

export const convertTextToMentions = (text) => {
  const isTrigger = handleHighlight(text)
  if (isTrigger) {
    const index = text.indexOf(']')
    const subText = text.substr(2, index - 2)
    return subText
  } else {
    return text
  }
}

export const handleHighlight = (text) => {
  const isTrigger =
    text.length > 1 &&
    text.startsWith('@') &&
    text.substr(1, 1) === '[' &&
    text.indexOf(']')
  return isTrigger
}
