import { get } from 'lodash'

export const getIsFetching = (state) =>
  get(state, 'assetPublish.isFetching', false)

export const getAssetsCount = (state) =>
  get(state, 'assetPublish.assetsCount', 0)

export const getPublishAssets = (state) =>
  get(state, 'assetPublish.publishAssets', 0)

export const getPendingFiles = (state) =>
  get(state, 'assetPublish.pendingFiles', 0)

export const getDefaultAssetPageSize = (state) =>
  get(state, 'assetPublish.defaultAssetPageSize', 0)

export const getCurrentAssetPage = (state) =>
  get(state, 'assetPublish.currentAssetPage', 0)

export const getSelectedAssets = (state) =>
  get(state, 'assetPublish.selectedAssets', 0)

export const getSortDirection = (state) =>
  get(state, 'assetPublish.sortDirection', 0)

export const getSortField = (state) => get(state, 'assetPublish.sortField', 0)

export const getAssetFilter = (state) =>
  get(state, 'assetPublish.assetFilter', '')

export const makePublishUploadFileData = (file = {}) => {
  const {
    name = '',
    size = Number(0),
    type = '',
    lastModifiedDate = new Date(),
  } = file
  const uploadFileData = `fileName:${name}, size:${size}, type:${type} , lastModifiedData: ${lastModifiedDate}`
  return uploadFileData
}
