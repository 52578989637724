import queryString from 'query-string'
import { mapKeys, camelCase } from 'lodash'
import apiConfig from '../../config/apiConfig'
import { convertUTCtoLocal } from '../utils/utils'
import {
  FILTER_CHANNEL_KEY,
  FILTER_CLASS_KEY,
  FILTER_DEPARTMENT_KEY,
  FILTER_SUBCLASS_KEY,
  FILTER_BRAND_KEY,
  SEARCH_TEXT,
  UPLOAD_DATE_TEXT,
  FILTER_UPLOAD_DATE_KEY,
  FILTER_COLOR_HSV_KEY,
  FILTER_IS_ARCHIVED_KEY,
  FILTER_CLASSIFICATION_KEY,
} from '../constants/search'
import { SEARCH_TERM_FILTER_MAP } from '../../constants/search'

export const assetsPayloadFromSearch = (assets = []) =>
  assets?.map((asset = {}) => assetToDetailedAsset(asset))
export const assetToDetailedAsset = (asset = {}) => {
  const { asset_details = {} } = asset
  const assetDetails = recursiveToCamel(asset_details)
  const assetCardDetails = {}
  const {
    renderUrls = {},
    assetId = '',
    originalAssetId = '',
    isDerivative = '',
    masterChannel = '',
    metadata: {
      fileMetadata: { fileName = '', fileSizeMb = Number(0), fileType = '' },
      usageInfo = [],
      creativeUsageInstructions = {},
      projectInfo = {},
      itemInfo = [],
      assetClassification = {},
    },
    uploadedDate = null,
  } = assetDetails
  const userInfobyExpDate = sortUsageInfoByExpDate(usageInfo)
  const usageInfoLatest = usageInfoFormatted(userInfobyExpDate)
  assetCardDetails['render_urls'] = getDetailedRenderUrls(renderUrls, fileName)
  assetCardDetails['renderUrls'] = getDetailedRenderUrls(renderUrls, fileName)
  assetCardDetails['uploadedDate'] = convertUTCtoLocal(uploadedDate)
  assetCardDetails['originalUploadedDate'] = uploadedDate
  assetCardDetails['usageInfo'] = usageInfoLatest
  assetCardDetails['itemInfo'] = itemInfo ?? []
  assetCardDetails['creativeUsageInstructions'] =
    creativeUsageInstructions ?? {}
  assetCardDetails['projectInfo'] = projectInfo ?? {}
  assetCardDetails['assetClassification'] = assetClassification ?? {}
  assetCardDetails['fileName'] = fileName
  assetCardDetails['fileSizeMb'] = fileSizeMb
  assetCardDetails['fileType'] = fileType
  assetCardDetails['assetId'] = assetId
  assetCardDetails['isDerivative'] = isDerivative
  assetCardDetails['masterChannel'] = masterChannel
  assetCardDetails['originalAssetId'] = originalAssetId
  return assetCardDetails
}

export const usageInfoFormatted = (usageInfo = []) =>
  usageInfo?.map((obj) => {
    const { expirationDate } = obj
    const newObj = {
      expirationDateFormatted: convertUTCtoLocal(expirationDate, 'YYYY-MM-DD'),
    }
    return Object.assign({}, obj, newObj)
  })

export const getDetailedRenderUrls = (renderUrls = {}, fileName = '') => {
  const {
    originalAssetUrl = '',
    previewAssetUrl = '',
    thumbnailAssetUrl = '',
    optimizedAssetUrl = '',
  } = renderUrls
  const previewFilename = fileName?.split('.')[0] + '.jpeg'
  const previewFromThumbnailUrl = getThumbnailToPreviewUrl(thumbnailAssetUrl)
  renderUrls['thumbnailSquareUrl'] = appendQueryParams(thumbnailAssetUrl, {
    make_square: true,
  })
  renderUrls['previewSquareUrl'] = appendQueryParams(previewAssetUrl, {
    make_square: true,
  })
  renderUrls['videoUrl'] = appendQueryParams(originalAssetUrl)
  renderUrls['downloadUrl'] =
    appendQueryParams(originalAssetUrl, {
      file_name: fileName,
      is_download: true,
    }) ?? originalAssetUrl
  renderUrls['downloadPreviewUrl'] =
    appendQueryParams(optimizedAssetUrl, {
      file_name: previewFilename,
      is_download: true,
    }) ?? optimizedAssetUrl
  renderUrls['previewThumbnail'] = appendQueryParams(previewFromThumbnailUrl, {
    make_square: true,
  })
  renderUrls['previewHoverThumbnail'] = appendQueryParams(optimizedAssetUrl, {
    make_square: true,
  })
  return renderUrls
}

export const getThumbnailToPreviewUrl = (imgSrc) => {
  let previewUrl = imgSrc
  if (previewUrl?.includes('THUMBNAIL')) {
    if (previewUrl?.includes('vault_digital_assets')) {
      previewUrl = imgSrc?.replace('THUMBNAIL', 'PREVIEW&hei=500&wid=500')
    } else {
      previewUrl = imgSrc?.replace('THUMBNAIL', 'PREVIEW&height=500&width=500')
    }
  }
  return previewUrl
}

export const appendQueryParams = (url = '', params = {}) => {
  if (url === null) {
    return ''
  }
  const { key = '' } = apiConfig
  const paramsObj = Object.assign({}, params, { key: key })
  const delimiter = url.indexOf('?') === -1 ? '?' : '&'
  return `${url}${delimiter}${queryString.stringify(paramsObj)}`
}
//utils
export const recursiveToCamel = (item) => {
  if (Array.isArray(item)) {
    return item.map((el) => recursiveToCamel(el))
  } else if (typeof item === 'function' || item !== Object(item)) {
    return item
  }
  return Object.fromEntries(
    Object.entries(item).map(([key, value]) => [
      key.replace(/([-_][a-z])/gi, (c) => c.toUpperCase().replace(/[-_]/g, '')),
      recursiveToCamel(value),
    ]),
  )
}
export const snakeCaseToCamelCase = (data = {}) =>
  mapKeys(data, (value, key) => camelCase(key))

export const sortUsageInfoByExpDate = (usage_info) => {
  const usageInfo = usage_info ? [...usage_info] : []
  usageInfo.sort(
    (item1, item2) =>
      new Date(item1.expirationDate) - new Date(item2.expirationDate),
  )
  return usageInfo
}

export const convertResponseToAutoCompleteOptions = (
  autoCompleteSuggestions = {},
  facetName = '',
) => {
  switch (facetName) {
    case 'ALL': {
      let ACOptions = []
      autoCompleteSuggestions['Brand']?.map((brandName = '') => {
        ACOptions.push({
          id: brandName,
          value: brandName,
          label: brandName,
          group: 'Brand',
        })
      })
      autoCompleteSuggestions['Department']?.map((brandName = '') => {
        ACOptions.push({
          id: brandName,
          value: brandName,
          label: brandName,
          group: 'Department',
        })
      })
      autoCompleteSuggestions['Channel']?.map((brandName = '') => {
        ACOptions.push({
          id: brandName,
          value: brandName,
          label: brandName,
          group: 'Channel',
        })
      })
      autoCompleteSuggestions['Sub Class']?.map((brandName = '') => {
        ACOptions.push({
          id: brandName,
          value: brandName,
          label: brandName,
          group: 'Sub Class',
        })
      })
      return ACOptions
    }
  }
}

export const transformFilterNameToKey = (filterName = '') => {
  switch (filterName) {
    case 'Brand':
      return FILTER_BRAND_KEY
    case 'Department':
      return FILTER_DEPARTMENT_KEY
    case 'Class':
      return FILTER_CLASS_KEY
    case 'Sub Class':
      return FILTER_SUBCLASS_KEY
    case 'Channel':
      return FILTER_CHANNEL_KEY
    default:
      return ''
  }
}

export const getFiltersPayload = (filtersList = []) => {
  let newFilters = {}
  filtersList.forEach((filter) => {
    const { group = '', value = '' } = filter
    const filterKey = transformFilterNameToKey(group)
    if (newFilters[filterKey]) {
      newFilters[filterKey].push(value)
    } else {
      newFilters[filterKey] = [value]
    }
  })
  return newFilters
}

export const ignoreFilterKeyList = [
  'isConfidential',
  'isLatestRevision',
  'creativeAssetStatus',
  'status',
  'uploadedDate',
  FILTER_COLOR_HSV_KEY,
  ...Object.keys(SEARCH_TERM_FILTER_MAP || {}),
]

export const getClassificationCode = (selectedValue, facetDetails) => {
  const filteredClassification = facetDetails['records'].find(
    (facetRecords) => facetRecords.id === selectedValue,
  )
  const { category = '', value = '' } = filteredClassification
  return `${category}_${value}`
}

export const getClassificationLabels = (
  selectedValues = [],
  classificationData = [],
) => {
  const filteredData = classificationData.filter((classification) =>
    selectedValues.includes(classification.id),
  )
  let mappedData = filteredData.map((classification) => {
    const { category = '', value = '' } = classification
    return `${category}_${value}`
  })
  return mappedData
}

const getAppliedFiltersLabel = (key = '', value = '', facets = {}) => {
  switch (key) {
    case FILTER_CLASSIFICATION_KEY: {
      const relevantFacets = (facets['facetResponse'] || []).find(
        (facets) => facets.path === key,
      )
      return getClassificationCode(value, relevantFacets)
    }
    default:
      return value
  }
}

export const convertFiltersAndTextSearchToConfig = (
  filters = {},
  facets = {},
) => {
  let newFiltersConfig = []
  Object.keys(filters).length &&
    Object.keys(filters).map((key) => {
      if (!ignoreFilterKeyList.includes(key)) {
        const filterValue = filters[key]
        if (filterValue.length) {
          filterValue.forEach((value) => {
            newFiltersConfig.push({
              id: value,
              value: value,
              label: getAppliedFiltersLabel(key, value, facets),
              group: key,
            })
          })
        }
      } else if (key === 'uploadedDate') {
        const filterValue = filters[key]
        if (filterValue.length) {
          const uploadDateFilter = filterValue.join(' to ')
          newFiltersConfig.push({
            id: uploadDateFilter,
            value: uploadDateFilter,
            label: uploadDateFilter,
            group: FILTER_UPLOAD_DATE_KEY,
          })
        }
      }
    })
  return newFiltersConfig
}

export const removeFilterValueFromExistingFilters = (
  existingFilters = {},
  filterToRemove = {},
) => {
  const filterGroup = filterToRemove.group
  const filterValue = filterToRemove.value
  if (filterGroup in existingFilters) {
    let existingFilterValue = existingFilters[filterGroup]
    existingFilterValue = existingFilterValue.filter(
      (item) => item != filterValue,
    )
    if (existingFilterValue.length) {
      if (
        filterGroup === FILTER_UPLOAD_DATE_KEY ||
        filterGroup === FILTER_IS_ARCHIVED_KEY
      ) {
        delete existingFilters[filterGroup]
      } else {
        existingFilters[filterGroup] = existingFilterValue
      }
    } else {
      delete existingFilters[filterGroup]
      if (filterGroup === FILTER_DEPARTMENT_KEY) {
        delete existingFilters[FILTER_CLASS_KEY]
      }
    }
  }

  return existingFilters
}

export const mapFacetDataToCheckboxConfig = (fileTypeList = []) => {
  const result = Object.groupBy(fileTypeList, ({ category }) => category)
  return result
}
