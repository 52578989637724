import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import MomentUtils from '@date-io/moment'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Clear from '@mui/icons-material/Clear'
import DateRange from '@mui/icons-material/DateRange'
import NotificationsIcon from '@mui/icons-material/Notifications'
import AddAlertIcon from '@mui/icons-material/AddAlert'
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import PeopleIcon from '@mui/icons-material/People'
import GroupIcon from '@mui/icons-material/Group'
import IconButton from '@mui/material/IconButton'
import Grid from '@mui/material/Grid'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import FormLabel from '@mui/material/FormLabel'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import PersonAdd from '@mui/icons-material/PersonAdd'
import EmailIcon from '@mui/icons-material/Email'
import Button from '@mui/material/Button'
import Moment from 'moment'
import Input from '@mui/material/Input'
import Checkbox from '@mui/material/Checkbox'
import ListItemText from '@mui/material/ListItemText'
import momentTime from 'moment-timezone'
import { ListItemIcon } from '@mui/material'
import UserListTable from './UserListTable'
import { REVIEW_TASK_TYPE_ID } from '../../constants/projectTask'
import { getTaskFormChanges } from '../../helpers/TaskHelper'

MomentUtils.prototype.getStartOfMonth = MomentUtils.prototype.startOfMonth

const styles = makeStyles((theme) => ({
  margin: {
    margin: useTheme().spacing(),
  },
  leftIcon: {
    top: 5,
    position: 'relative',
    marginRight: 10,
  },
  textField: {
    flexBasis: 200,
  },
  emptyGroup: {
    border: '2px dashed #c5c5c5',
    textAlign: 'center',
    paddingBottom: 20,
    margin: -5,
    marginTop: 20,
  },
  groupListWrapper: {
    minHeight: 100,
    marginTop: 10,
  },
  wrapper: {
    margin: useTheme().spacing(),
    position: 'relative',
  },
  button: {
    backgroundColor: '#0D46A0',
    '&:hover': {
      backgroundColor: '#0D46A0',
    },
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  AutoCompleteWarpper: {
    backgroundColor: '#f7f7f7',
    border: '1px solid #EFEFEF',
    paddingBottom: 8,
  },
  userTxt: {
    wordWrap: 'break-word',
    whiteSpace: 'normal !important',
    color: '#8D8D8D',
    fontSize: '12',
  },
  pplCnt: {
    color: '#8D8D8D',
    fontSize: 12,
    display: 'block',
  },
  pplName: {
    fontSize: 14,
    color: '#212121',
  },
  IconGey: {
    color: '#8D8D8D',
  },
  TCOptionsColor: {
    '& span': {
      color: 'rgba(0, 0, 0, 0.87) !important',
    },
  },
  greyHeader: {
    backgroundColor: '#EFEFEF',
    padding: '10px !important',
    display: 'flex',
    width: '98%',
    margin: '10px 0',
  },
  notifications: {
    '& div[class^="MuiGrid-item"]': {
      marginBottom: '20px',
    },
  },
  datePickerWidth: {
    width: '150px',
  },
}))

export class TaskNotificationsForm extends React.Component {
  constructor(props) {
    super(props)
    let myDate = ''
    let myTime = ''
    let changedValues = [
      { value: 'Reviewers Added', name: 'New Reviewers Were Added' },
      { value: 'Assets Added', name: 'New Assets Were Added' },
      { value: 'Date Changed', name: 'Due Date Has Changed' },
    ]
    this.datePickerDate = new Date().setHours(17, 0, 0, 0)
    this.state = {
      type: 'New Task',
      changed: '',
      initiator_address: 'true',
      send: 'Now',
      from: props.auth.email || 'assethub-donotreply@target.com',
      sentUsers: props.sentToUsers,
      selectedUsers:
        (props.notifications && [props.notifications[0].sentTo]) || [],
      emailAddress: props.auth.email,
      emailScheduledTime: myTime,
      emailScheduledDate: myDate,
      text: props.buildEmailBody()['text'] || '',
      subject: props.buildEmailBody()['subject'] || '',
      taskType: props.buildEmailBody()['taskType'] || '',
      changedValues: changedValues,
      name: (props.notifications && props.notifications[0].changed) || [],
      autoReminder: props.autoReminder,
      isExpanded: true,
    }
    if (props.projectType === 'Weekly Ad') {
      this.state.from = 'assethub-donotreply@target.com'
    }
    // this.peopleList = this.peopleList.bind(this)
    this.selectedUser = this.selectedUser.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.removeUser = this.removeUser.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
  }
  componentDidMount() {
    this.datePickerDate = new Date().setHours(0, 0, 0, 0)
    let pendingReviewers = this.state.sentUsers.filter(
      (a) => a.name === 'Reviewers Not Completed' && a.count !== 0
    )
    if (
      this.state.autoReminder &&
      !this.props.info.isEdit &&
      this.props.projectType !== 'Weekly Ad' &&
      pendingReviewers &&
      pendingReviewers.length
    ) {
      let scheduleTime = momentTime(
        this.props.scheduledDate + ' ' + this.props.scheduledTime
      )
      let duration = Moment.duration(
        Moment(scheduleTime).diff(new Date().setHours(0, 0, 0, 0))
      )
      let diffHours = duration.asHours()
      scheduleTime =
        diffHours >= 24
          ? Moment(scheduleTime).subtract(24, 'hours')
          : Moment(scheduleTime).subtract(2, 'hours')
      let emailProps = this.props.buildEmailBody('Reminder')
      this.setState({
        send: 'Schedule Future Day & Time',
        type: 'Reminder',
        emailScheduledDate: momentTime(scheduleTime).format('MM/DD/YYYY'),
        emailScheduledTime: momentTime(scheduleTime).format('HH:mm'),
        from: 'assethub-donotreply@target.com',
        selectedUsers:
          pendingReviewers || this.state.selectedUsers || this.state.sentUsers,
        isExpanded: false,
        text: emailProps['text'] || '',
        subject: emailProps['subject'] || '',
        accType: 'auto',
        taskType: emailProps['taskType'] || 'Reminder',
      })
      this.submitForm('initial')
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.openNotificationForm) {
      let data = Object.assign({}, this.state, nextProps.editFormNotification)
      data.initiator_address = String(data.showCC || data.cc || true)
      data.selectedUsers = [data.sentTo]
      data.sentUsers = [...data.sentUsers]
      data.emailScheduledDate = data.scheduleDate
        ? Moment(data.scheduleDate).format('MM/DD/YYYY')
        : Moment(new Date().setHours(17, 0, 0, 0)).format('MM/DD/YYYY')
      data.emailScheduledTime = data.scheduleDate
        ? Moment(data.scheduleDate).format('HH:mm')
        : Moment(new Date().setHours(17, 0, 0, 0)).format('HH:mm')
      this.setState({ ...data })
      this.datePickerDate = data.scheduleDate
        ? new Date(data.scheduleDate).setHours(17, 0, 0, 0)
        : new Date().setHours(17, 0, 0, 0)
    }
  }
  selectedUser = (event) => {
    this.setState({ selectedUsers: [event.target.value] })
  }

  handleClick = () => () => {}

  handleChangeSelect = (event) => {
    this.setState({ name: event.target.value })
  }
  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    })
  }
  handleDateChange(date) {
    this.datePickerDate = new Date(date)
    const selectedDate = new Date(date)
    this.setState({
      emailScheduledDate: momentTime(selectedDate).format('MM/DD/YYYY'),
      emailScheduledTime: momentTime(selectedDate).format('HH:mm'),
    })
  }

  handleTimeChange = (e) => {
    const time = e.target.value

    if (time) {
      this.setState({
        emailScheduledTime: time,
      })
    }
  }

  removeUser(user) {
    this.setState({
      selectedUsers: this.state.selectedUsers.filter((el) => el !== user),
    })
  }

  submitForm(fromLocation) {
    const { taskTypeId = REVIEW_TASK_TYPE_ID } = this.props
    const { name = '', type = '' } = this.state
    const emailProps = this.props.buildEmailBody(type, name, taskTypeId)
    const { defaultVal = {}, taskFormAutoReminder } = getTaskFormChanges({
      emailProps,
      fromLocation,
      stateData: this.state,
    })
    if (fromLocation !== 'initial') {
      this.props.handleTaskForm([defaultVal], taskFormAutoReminder)
    }
  }

  renderLabel = (date) => {
    if (date.isValid()) {
      return date.format('MM/DD/YYYY')
    } else {
      return ''
    }
  }
  render() {
    const { classes = {}, open = false, onClose = () => {} } = this.props
    const {
      accType = '',
      changed,
      changedValues = [],
      emailAddress = '',
      emailScheduledTime = '',
      from = '',
      initiator_address = '',
      name = '',
      send = '',
      selectedUsers = [],
      sentUsers = [],
      showCC,
      type = '',
    } = this.state
    return (
      <Dialog
        open={open}
        onClose={onClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        fullWidth
      >
        <AppBar className="dialogTitleBar">
          <Toolbar className="dialogTitleHeight">
            <Typography color="inherit" className="dialogTitle">
              <AddAlertIcon className={classes.leftIcon} />
              Configure Notification
            </Typography>
            <IconButton
              className="dialogClose"
              aria-label="Delete"
              onClick={onClose}
              size="large"
            >
              <Clear />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ width: 520 }}>
          <form style={{ paddingTop: 20 }}>
            <Grid
              container
              className={classes.notifications}
              direction="row"
              justifyContent="flex-start"
              spacing={1}
            >
              <Grid item className={classes.greyHeader} xs={12}>
                Settings
              </Grid>
              <Grid item>
                <NotificationsIcon />
              </Grid>
              <Grid item xs={11} style={{ marginLeft: 10 }}>
                <InputLabel htmlFor="changed">Notification Type</InputLabel>
                <Select
                  variant="standard"
                  data-cy="notificationType"
                  value={type}
                  onChange={this.handleChange('type')}
                  inputProps={{
                    name: 'type',
                    id: 'type',
                  }}
                  fullWidth
                  disabled={accType === 'auto'}
                >
                  <MenuItem data-cy="newTask" value="New Task">
                    New Task
                  </MenuItem>
                  <MenuItem value="Task Has Changed">Task Has Changed</MenuItem>
                  <MenuItem value="Reminder">Reminder</MenuItem>
                  <MenuItem value="Custom">Custom</MenuItem>
                </Select>
              </Grid>
              {type === 'Task Has Changed' && (
                <Grid container spacing={1}>
                  <Grid item>
                    <ChangeHistoryIcon />
                  </Grid>
                  <Grid item xs={11} style={{ marginLeft: 10 }}>
                    <InputLabel htmlFor="select-multiple-checkbox">
                      What Has Changed?
                    </InputLabel>
                    <Select
                      variant="standard"
                      multiple
                      value={name}
                      onChange={this.handleChangeSelect}
                      input={<Input id="select-multiple-checkbox" />}
                      renderValue={(selected) => selected.join(', ')}
                      fullWidth
                    >
                      {changedValues.map((option) => (
                        <MenuItem key={option.name} value={option.name}>
                          <ListItemIcon>
                            <Checkbox
                              checked={name.indexOf(option.name) > -1}
                            />
                          </ListItemIcon>
                          <ListItemText
                            className={classes.TCOptionsColor}
                            primary={option.name}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
              )}
              <Grid item>
                <AccessTimeIcon />
              </Grid>
              <Grid item xs={11} style={{ marginLeft: 10 }}>
                <InputLabel htmlFor="changed">When to send</InputLabel>
                <Select
                  variant="standard"
                  data-cy="notificationSendTime"
                  value={send}
                  onChange={this.handleChange('send')}
                  inputProps={{
                    name: 'send',
                    id: 'send',
                  }}
                  fullWidth
                  disabled={accType && accType === 'auto'}
                >
                  <MenuItem data-cy="sendNow" value="Now">
                    Send Now
                  </MenuItem>
                  <MenuItem
                    data-cy="futureTime"
                    value="Schedule Future Day & Time"
                  >
                    Schedule Future Day & Time
                  </MenuItem>
                </Select>
              </Grid>
              {send === 'Schedule Future Day & Time' && (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Grid container direction="row" justifyContent="flex-start">
                    <Grid item xs={6}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                      >
                        <Grid item style={{ alignSelf: 'flex-end' }}>
                          <DateRange style={{ marginTop: 20 }} />
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          style={{
                            marginLeft: 18,
                            marginTop: 10,
                            minWidth: '150px',
                          }}
                        >
                          <DesktopDatePicker
                            data-cy="notificationDate"
                            disableToolbar
                            label="Enter Due Date & Time"
                            value={this.datePickerDate}
                            onChange={this.handleDateChange}
                            leftArrowIcon={<KeyboardArrowLeft />}
                            rightArrowIcon={<KeyboardArrowRight />}
                            inputFormat="MM/dd/yyyy"
                            minDate={new Date()}
                            labelFunc={this.renderLabel}
                            disabled={accType && accType === 'auto'}
                            autoOk={true}
                            renderInput={(params) => (
                              <TextField
                                className={classes.datePickerWidth}
                                {...params}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <AccessTimeIcon />
                      <form noValidate>
                        <TextField
                          variant="standard"
                          data-cy="notificationTime"
                          onChange={this.handleTimeChange}
                          style={{ padding: '0 10px' }}
                          type="time"
                          defaultValue={emailScheduledTime}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            step: 300, // 5 min
                          }}
                        />
                      </form>
                      <span>CST</span>
                    </Grid>
                  </Grid>
                </LocalizationProvider>
              )}
              <Grid item className={classes.greyHeader} xs={12}>
                People
              </Grid>
              <Grid item xs={11} style={{ marginLeft: 10 }}>
                <FormLabel component="legend">CC yourself?</FormLabel>
                <RadioGroup
                  aria-label="Gender"
                  name="initiator_address"
                  value={initiator_address || showCC}
                  onChange={this.handleChange('initiator_address')}
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
                <FormLabel component="legend">Show as "From:" whom?</FormLabel>
                <RadioGroup
                  name="initiator_from"
                  value={from}
                  onChange={this.handleChange('from')}
                >
                  <FormControlLabel
                    value={emailAddress}
                    control={<Radio />}
                    label={emailAddress}
                  />
                  <FormControlLabel
                    value="assethub-donotreply@target.com"
                    control={<Radio />}
                    label="assethub-donotreply@target.com"
                  />
                </RadioGroup>
              </Grid>
              <Grid item xs={11} style={{ marginLeft: 10 }}>
                <FormLabel component="legend">
                  Who should receive this notification?
                </FormLabel>
              </Grid>
              <Grid
                className={classes.AutoCompleteWarpper}
                container
                spacing={1}
                alignItems="flex-end"
              >
                <Grid item>
                  <PersonAdd style={{ fontSize: 30, marginBottom: 8 }} />
                </Grid>
                <Grid item sm style={{ marginRight: 12 }}>
                  <InputLabel htmlFor="age-helper">Add People</InputLabel>
                  <Select
                    variant="standard"
                    value={changed}
                    onChange={this.selectedUser}
                    inputProps={{
                      name: 'changed',
                      id: 'changed',
                    }}
                    fullWidth
                  >
                    {sentUsers &&
                      sentUsers.length >= 1 &&
                      sentUsers.map((option, index) => (
                        <MenuItem key={index} value={option}>
                          <Grid container>
                            <Grid item xs={1}>
                              <PeopleIcon className={classes.IconGey} />
                            </Grid>
                            <Grid item xs={4}>
                              <span className={classes.pplName}>
                                {option.name}
                              </span>
                              <span className={classes.pplCnt}>
                                {' '}
                                {option.count} People
                              </span>
                            </Grid>
                            <Grid item xs={7}>
                              <span className={classes.userTxt}>
                                {option.text}
                              </span>
                            </Grid>
                          </Grid>
                        </MenuItem>
                      ))}
                  </Select>
                </Grid>
              </Grid>
            </Grid>
          </form>
          <div className={classes.groupListWrapper}>
            {selectedUsers.length >= 1 ? (
              <UserListTable
                userList={selectedUsers}
                onDelete={this.removeUser}
              />
            ) : (
              <div className={classes.emptyGroup}>
                <div>
                  <GroupIcon style={{ fontSize: 77 }} />
                </div>
                Nobody is selected to receive this notification!!
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            data-cy="previewButton"
            onClick={this.submitForm}
            className={classes.button}
            color="primary"
            variant="contained"
            disabled={selectedUsers.length === 0 || !type || !send}
          >
            <EmailIcon style={{ marginRight: 10 }} /> PREVIEW NOTIFICATION
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

TaskNotificationsForm.propTypes = {
  auth: PropTypes.shape({
    email: PropTypes.string,
  }),
  info: PropTypes.shape({
    isEdit: PropTypes.bool,
  }),
  openNotificationForm: PropTypes.bool,
  editFormNotification: PropTypes.shape({
    type: PropTypes.string,
    send: PropTypes.string,
    changed: PropTypes.string,
    scheduleDate: PropTypes.string,
    showCC: PropTypes.bool,
    from: PropTypes.string,
    sentTo: PropTypes.object,
  }),
  projectType: PropTypes.string,
  classes: PropTypes.object.isRequired,
  handleTaskForm: PropTypes.func,
  buildEmailBody: PropTypes.func,
  notifications: PropTypes.arrayOf(PropTypes.object),
  onClose: PropTypes.func,
  open: PropTypes.bool,
  autoReminder: PropTypes.bool,
  sentToUsers: PropTypes.arrayOf(
    PropTypes.shape({
      count: PropTypes.number,
      name: PropTypes.string,
      text: PropTypes.string,
    })
  ),
  scheduledDate: PropTypes.string,
  scheduledTime: PropTypes.string,
  taskTypeId: PropTypes.number,
}

const mapStateToProps = (state = {}) => {
  const { layout = {} } = state
  const { headerTitle } = layout
  const auth = state.auth
  let { task } = state
  return {
    headerTitle: headerTitle,
    auth: auth,
    task: task,
  }
}

const MyComponent = (props) => {
  const classes = styles()
  return <TaskNotificationsForm {...props} classes={classes} />
}

export default connect(mapStateToProps)(MyComponent)
