import * as React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import Typography from '@mui/material/Typography'
import ExpandIcon from '@mui/icons-material/ExpandMore'

import Tooltip from '@mui/material/Tooltip'
import CircularProgress from '@mui/material/CircularProgress'
import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import { withEnv } from '@praxis/component-runtime-env'
import withRouter from '../../containers/Router/WithRouter'

import HeaderTitle from '../Header/HeaderTitle'
import ProjectsGrid from './ProjectsGrid'
import ProjectsList from './ProjectsList'
import Filters from './Filters'
import {
  searchProjects,
  searchProjectsPage,
} from './../../store/projects/actionCreator'
import { showNotification } from '../../store/notification/actionCreator'
import { updateUserPreferences } from '../../store/preferences/actionCreator'
import {
  TEXT_ERROR_DEFAULT,
  TEXT_SUCCESS_PROJECT_UPDATE,
} from '../../constants/notifications'
import { selectUserId, selectMemberOf } from '../../store/auth/selector'
import { isAuthorizedToPage } from '../../helpers/UtilityHelper'
import { ProjectFormContainer } from './ProjectForms'
import firefly from '../../analytics/firefly'

const styles = makeStyles((theme) => ({
  header: {
    marginTop: '50px',
    marginBottom: '40px',
    marginLeft: '8px',
    justifyContent: 'center',
  },
  headerText: {
    fontSize: 20,
    color: '#333',
    fontWeight: 'initial',
  },
  buttonProgress: {
    color: '#2196f3',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  button: {
    color: 'white',
    marginRight: '20px',
    backgroundColor: '#188295',
    '&:hover': {
      backgroundColor: '#002171',
    },
  },
  root: {
    flexGrow: 1,
  },
  addProjectButtonBottom: {
    fontSize: '11px',
    fontWeight: 'normal',
    marginTop: '30px',
  },
  noResult: {
    textAlign: 'center',
    marginTop: '30px',
  },
  resultsText: {
    color: '#c0c0c0',
    margin: useTheme().spacing(),
  },
  filters: {
    marginBottom: '32px',
    justifyContent: 'center',
  },
}))

export class ProjectsPage extends React.PureComponent {
  constructor(props) {
    super(props)
    const { view = '' } = this.props
    this.state = {
      checkModal: false,
      isModalOpen: false,
      loading: true,
      isList: view,
    }
  }

  componentWillReceiveProps(nextProps) {
    const { checkModal } = this.state
    if (checkModal && !nextProps.projects.submitStatus.loading) {
      if (nextProps.projects.submitStatus.status === 'failure') {
        let msg = nextProps.projects.submitStatus.message
          ? nextProps.projects.submitStatus.message
          : TEXT_ERROR_DEFAULT
        this.props.showNotification(true, msg, 'error')
      } else {
        this.setState({ isModalOpen: false })
        let msg = nextProps.projects.submitStatus.message
          ? nextProps.projects.submitStatus.message
          : TEXT_SUCCESS_PROJECT_UPDATE
        this.props.showNotification(true, msg, 'success')
      }
    }
    if (nextProps.projects.submitStatus !== undefined) {
      this.setState({ checkModal: nextProps.projects.submitStatus.loading })
    }
    if (nextProps.projects.projectListloading !== undefined) {
      this.setState({ loading: false })
    }
  }

  componentDidMount() {
    const {
      searchProjects = () => {},
      projectListPagination = {},
      lanId = '',
    } = this.props
    searchProjects(projectListPagination, lanId)
  }

  addProject = () => this.setState({ isModalOpen: true })

  onClose = () => this.setState({ isModalOpen: false })

  updatePreferences = (selectedView) => {
    const {
      userPreferences = {},
      view = '',
      annotation_zoom = 'fit-to-screen',
      updateUserPreferences = () => {},
    } = this.props

    if (selectedView !== view) {
      const { preferences = {} } = userPreferences

      const updatedPreferences = {
        ...preferences,
      }
      updatedPreferences.view = selectedView
      updatedPreferences.annotation_zoom = annotation_zoom

      updateUserPreferences({
        ...userPreferences,
        preferences: updatedPreferences,
      })
    }
    this.setState({ isList: selectedView })
  }

  loadMoreProjects = () => {
    const {
      projectListPagination = {},
      lanId = '',
      searchProjectsPage = () => {},
    } = this.props
    const { page = 1, size = 0 } = projectListPagination

    const newPagination = {
      ...projectListPagination,
      page: page + 1,
    }

    searchProjectsPage(newPagination, lanId)
    firefly.trackProjectListPage(page, size, lanId)
  }

  render() {
    const {
      classes = {},
      headerTitle = '',
      projects = {},
      history = {},
      router = {},
      view = 'list',
      projectListPagination = {},
      memberOf: memberOfCopy = [],
      env = {},
    } = this.props
    const { REACT_APP_ROLES_CONFIG = {} } = env
    const {
      projectCount = 0,
      projectListloading = true,
      projectList = [],
    } = projects
    const { isModalOpen = '', isList = 'list' } = this.state
    let listUserPreference = isList
    const memberOf = [...memberOfCopy] || []
    if (view === 'grid') {
      listUserPreference = view
    }

    return (
      <div className={classes.projectsPage}>
        <HeaderTitle title="Projects" icon="Project" />
        <Helmet>
          <title>{headerTitle}</title>
        </Helmet>
        <div className={classes.root}>
          <Grid container spacing={1} item xs={12} className={classes.header}>
            <Grid item xs={6} sm={6} md={5} lg={5}>
              <Grid container spacing={1} justifyContent="flex-start">
                <div className={classes.headerText}>My Projects</div>
              </Grid>
            </Grid>
            <Grid item xs={0} sm={1} md={1} lg={1} />
            <Grid item xs={0} sm={1} md={2} lg={1} />
            <Grid item xs={5} sm={3} md={3} lg={3}>
              <Grid container spacing={2} justifyContent="flex-end">
                {isAuthorizedToPage(
                  'projects',
                  memberOf,
                  REACT_APP_ROLES_CONFIG,
                ) &&
                  !isAuthorizedToPage(
                    'externalVendors',
                    memberOf,
                    REACT_APP_ROLES_CONFIG,
                  ) && (
                    <Tooltip id="tooltip-icon" title="New Project">
                      <Button
                        onClick={this.addProject}
                        data-cy="newProjectButton"
                        color="primary"
                        aria-label="add"
                        className={classes.button}
                      >
                        <div className="icon-default">
                          <AddIcon />
                        </div>
                        New Project
                      </Button>
                    </Tooltip>
                  )}
              </Grid>
            </Grid>
          </Grid>
          <Grid container className={classes.filters} spacing={2}>
            <Grid item xs={12}>
              <Filters view={view} updatePreferences={this.updatePreferences} />
            </Grid>
          </Grid>
          {!projectListloading && listUserPreference === 'list' && (
            <ProjectsList history={history} router={router} />
          )}
          {!projectListloading && listUserPreference === 'grid' && (
            <ProjectsGrid history={history} router={router} />
          )}

          {projectCount > projectList.length && (
            <Grid
              container
              justifyContent="center"
              spacing={1}
              direction="row"
              style={{ margin: '10px' }}
            >
              <Button
                aria-label="Delete"
                onClick={this.loadMoreProjects}
                data-cy="loadMore"
              >
                <ExpandIcon />
                Load More
              </Button>
            </Grid>
          )}

          {projectListloading && (
            <div>
              <br />
              <br />
              <CircularProgress size={50} className={classes.buttonProgress} />
            </div>
          )}
          {projectCount === 0 && !projectListloading && (
            <div>
              <Grid
                container
                justifyContent="center"
                spacing={1}
                className={classes.noResult}
              >
                <Grid item id="noProjectDiv">
                  Sorry, no projects found.
                </Grid>
              </Grid>
              <Typography gutterBottom noWrap className={classes.noResult}>
                {isAuthorizedToPage(
                  'projects',
                  memberOf,
                  REACT_APP_ROLES_CONFIG,
                ) &&
                  !isAuthorizedToPage(
                    'externalVendors',
                    memberOf,
                    REACT_APP_ROLES_CONFIG,
                  ) && (
                    <Tooltip id="tooltip-icon" title="New Project">
                      <Button
                        onClick={this.addProject}
                        color="primary"
                        aria-label="add"
                        className={classes.button}
                      >
                        <div className="icon-default">
                          <AddIcon />
                        </div>
                        New Project
                      </Button>
                    </Tooltip>
                  )}
              </Typography>
            </div>
          )}
        </div>
        {isModalOpen && (
          <ProjectFormContainer
            open={isModalOpen}
            onClose={this.onClose}
            history={history}
            router={router}
          />
        )}
      </div>
    )
  }
}
ProjectsPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  classes: PropTypes.object,
  headerTitle: PropTypes.string,
  showNotification: PropTypes.func,
  lanId: PropTypes.string,
  projects: PropTypes.object,
  updateUserPreferences: PropTypes.func,
  view: PropTypes.string,
  userPreferences: PropTypes.object,
  searchProjects: PropTypes.func,
  searchProjectsPage: PropTypes.func,
}

ProjectsPage.defaultProps = {
  classes: {},
  headerTitle: undefined,
  lanId: '',
  memberOf: [],
  projects: {},
}

const mapStateToProps = (state = {}) => {
  const { layout = {}, auth = {}, projects = {}, userPreferences = {} } = state
  const { preferences = {} } = userPreferences
  const { view = 'list', annotation_zoom = 'fit-to-screen' } = preferences
  const { headerTitle = '' } = layout
  const { projectListPagination = {} } = projects
  return {
    projects,
    headerTitle,
    lanId: selectUserId()(state),
    projectListPagination,
    userPreferences,
    view,
    annotation_zoom,
    memberOf: selectMemberOf()(state),
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showNotification,
      updateUserPreferences,
      searchProjects,
      searchProjectsPage,
    },
    dispatch,
  )

const MyComponent = (props) => {
  const classes = styles()
  return <ProjectsPage {...props} classes={classes} />
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withEnv()(withRouter(MyComponent)))
