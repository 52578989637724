import React from 'react'
import { makeStyles } from '@mui/styles'
import { Grid } from '@mui/material'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import AssethubVisionPage from './AssethubVisionPage'
import AssethubContentPage from './AssethubContentPage'
import AHFeatureUpdatesPage from './AHFeatureUpdatesPage'
import HeaderTitle from '../../components/Header/HeaderTitle'

const useStyles = makeStyles({
  homeContainer: {
    width: '100%',
    height: '90vh',
    overflowY: 'scroll',
    scrollSnapType: 'y mandatory',
    gap: '10%',
  },
  homePages: {
    paddingTop: '2%',
    fontFamily: 'Helvetica',
    width: '100%',
    scrollSnapAlign: 'start',
  },
  contentPage: {
    paddingTop: '5%',
    fontFamily: 'Helvetica',
    width: '100%',
    scrollSnapAlign: 'start',
    background: '#fcf9fb',
  },
  iconGroup: {
    cursor: 'pointer',
    float: 'right',
    padding: '20px',
  },
})

function HomePageContainer() {
  const classes = useStyles()
  const handleScrollBehavior = (elementId) => {
    const section = document.getElementById(elementId)
    section.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <>
      <HeaderTitle title="Home" icon="Home" />
      <Grid container className={classes.homeContainer}>
        <Grid item className={classes.contentPage}>
          <div id="content">
            <AssethubContentPage />
          </div>
          <div className={classes.iconGroup}>
            <ArrowDownwardIcon onClick={() => handleScrollBehavior('vision')} />
          </div>
        </Grid>
        <Grid item className={classes.homePages}>
          <div className={classes.iconGroup}>
            <ArrowUpwardIcon onClick={() => handleScrollBehavior('content')} />
          </div>
          <div id="vision">
            <AssethubVisionPage />
          </div>
          <div className={classes.iconGroup}>
            <ArrowDownwardIcon
              onClick={() => handleScrollBehavior('features')}
            />
          </div>
        </Grid>
        <Grid item className={classes.homePages}>
          <div id="features">
            <AHFeatureUpdatesPage />
          </div>
          <div className={classes.iconGroup}>
            <ArrowUpwardIcon onClick={() => handleScrollBehavior('vision')} />
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default HomePageContainer
