import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty, each } from 'lodash'

import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import Avatar from '@mui/material/Avatar'
import Paper from '@mui/material/Paper'
import CardHeader from '@mui/material/CardHeader'
import TodayIcon from '@mui/icons-material/Today'
import CardContent from '@mui/material/CardContent'
import Card from '@mui/material/Card'
import MailIcon from '@mui/icons-material/Mail'
import TaskNotificationsCurrent from './TaskNotificationsCurrent'
import TaskNotificationsInfo from './TaskNotificationsInfo'
import { getUsersByRole } from '../../helpers/TaskHelper'

const styles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: useTheme().spacing(3),
    overflowX: 'auto',
  },
  avatar: {
    backgroundColor: '#FFF',
  },
  avatarIcon: {
    color: '#8D8D8D',
  },
  button: {
    marginRight: 20,
  },
}))

class TaskPeopleList extends React.Component {
  constructor(props) {
    super(props)
    const { info: propsInfo = {} } = this.props
    const { task = {} } = propsInfo
    const { default: defaultTask = {} } = task
    const { notification = {} } = defaultTask
    const { schedule_notification: scheduleNotification = [] } = notification
    const { sent_notification: sentNotification = [] } = notification

    this.state = {
      scheduleNotification: scheduleNotification,
      sentNotification: sentNotification,
      openForm: true,
    }
    this.handleStepsData = this.handleStepsData.bind(this)
  }
  openTaskFrom() {
    // this.setState({openForm: true})
  }
  handleStepsData() {
    let sentToUsers = []
    let sentList = [
      {
        id: 1,
        name: 'All Participants',
        count: 0,
        text: 'All Reviewers & Observers on this task.',
        showCnt: false,
      },
    ]
    let assetsSelectedCnt = 0
    const { info = {} } = this.props
    if (!isEmpty(info)) {
      const { task = {}, project: projectInfo = {} } = info
      const {
        current: currentList = {},
        default: defaultList = {},
        taskFormData = {},
      } = task
      const {
        participants: currentParticipants = [],
        assets: currentListAssets = [],
      } = currentList
      const {
        participants: defaultParticipants = [],
        assets: defaultListAssets = [],
      } = defaultList

      let selectedPeople = currentParticipants.length
        ? currentParticipants
        : defaultParticipants
      let selectedAssets = currentListAssets.length
        ? currentListAssets
        : defaultListAssets
      const newParticipants = (selectedPeople || []).filter(
        (obj) => !obj.task_status
      )
      if (selectedPeople.length) {
        let cntAll = selectedPeople.length // All Participants

        const {
          admins = [],
          observers = [],
          reviewers = [],
        } = getUsersByRole(selectedPeople)
        const { reviewers: newReviewers = [] } = getUsersByRole(newParticipants)

        let newReviewersCnt = this.getCntUser(newReviewers) // New Reviewers

        let cntReviewers = this.getCntUser(reviewers) // All Reviewers

        let cntAdmins = this.getCntUser(admins) // All Admins

        let cntObservers = this.getCntUser(observers) // All Observers

        let cntNotStartedReviewers = this.getCntUser(reviewers, 'Not Started')

        let InProgress = reviewers.filter(
          (a) => a.task_status === 'In Progress'
        )
        let cntProgressReviewers = this.getCntUser(InProgress)

        let cntNotCompletedReviewers =
          cntProgressReviewers + cntNotStartedReviewers

        let cntJustNow = 0
        if (selectedPeople.length > defaultParticipants.length) {
          cntJustNow = selectedPeople.length - defaultParticipants.length // New Participants
        }
        sentToUsers = [
          {
            id: 1,
            name: 'All Participants',
            count: cntAll,
            text: 'All Admins, Reviewers & Observers on this task.',
            showCnt: true,
          },
          {
            id: 2,
            name: 'All Reviewers And Observers',
            count: cntReviewers + cntObservers,
            text: 'All Reviewers & Observers on this task.',
            showCnt: true,
          },
          {
            id: 3,
            name: 'All Admins',
            count: cntAdmins,
            text: 'All Admins on this task.',
            showCnt: true,
          },
          {
            id: 4,
            name: 'All Reviewers',
            count: cntReviewers,
            text: 'All Reviewers on this task, regardless of their task completion status.',
            showCnt: true,
          },
          {
            id: 5,
            name: 'All Observers',
            count: cntObservers,
            text: 'All Observers on this task.',
            showCnt: true,
          },
          {
            id: 6,
            name: 'New Participants',
            count: cntJustNow,
            text: 'All Reviewers & Observers who you just added to the review.',
            showCnt: true,
          },
          {
            id: 7,
            name: 'Reviewers Not Completed',
            count: cntNotCompletedReviewers,
            text: 'Reviewers who have not finished review task.',
            showCnt: false,
          },
          {
            id: 8,
            name: 'New Reviewers',
            count: newReviewersCnt,
            text: 'All Reviewers who you just added to the review.',
            showCnt: true,
          },
        ]
        sentList = sentToUsers.filter((a) => a.count > 0)
        if (selectedAssets) {
          assetsSelectedCnt = selectedAssets.filter(
            (a) => a.isChecked === true
          ).length
        }
      }
      let data = {
        allSentList: sentToUsers,
        sentList: sentList,
        assetsCnt: assetsSelectedCnt,
        project: projectInfo,
        taskFormData: taskFormData,
      }
      return data
    }
  }
  getCntUser = function (userObj, taskStaus) {
    let totalUsers = 0
    let personCount
    each(userObj, function (person) {
      if (
        taskStaus &&
        (person.task_status === taskStaus || !person.task_status)
      ) {
        personCount = !person.group_id
          ? 1
          : person.total_tasks
          ? person.total_tasks
          : person.num_of_users
          ? person.num_of_users
          : 0
        totalUsers = totalUsers + personCount
      } else if (!taskStaus) {
        personCount = !person.group_id
          ? 1
          : person.total_tasks
          ? person.total_tasks
          : person.num_of_users
          ? person.num_of_users
          : 0
        totalUsers = totalUsers + personCount
      }
    })
    return totalUsers
  }
  handleStepper(data) {
    this.props.handleStepper(data, 'notification')
  }
  render() {
    const { classes, info: propsTaskInfo = {} } = this.props
    const { isEdit = false } = propsTaskInfo
    const {
      scheduleNotification = [],
      sentNotification = [],
      openForm = true,
    } = this.state
    return (
      <Paper className={classes.tableWrapper}>
        <Card>
          <CardHeader
            avatar={
              <Avatar aria-label="Recipe" className={classes.avatar}>
                <TodayIcon className={classes.avatarIcon} />
              </Avatar>
            }
            title="Scheduled Notifications"
            subheader={`${
              scheduleNotification.length + 1
            } Notification have been sent for this task.`}
          />
          <CardContent>
            <TaskNotificationsCurrent
              openNotificationForm={openForm}
              data={this.handleStepsData()}
              stepper={this.handleStepper.bind(this)}
              info={propsTaskInfo}
            />
            {scheduleNotification.map((nf, index) => (
              <TaskNotificationsInfo
                key={index}
                notificationIndex={index}
                data={nf}
                notificationType="schedule_notification"
                {...this.props}
              />
            ))}
          </CardContent>
        </Card>
        {isEdit && (
          <Card style={{ marginTop: 20 }}>
            <CardHeader
              avatar={
                <Avatar aria-label="Recipe" className={classes.avatar}>
                  <MailIcon className={classes.avatarIcon} />
                </Avatar>
              }
              title="Sent Notifications"
              subheader={`${sentNotification.length} Notification have been sent for this task.`}
            />
            <CardContent>
              {sentNotification.map((nf, index) => (
                <TaskNotificationsInfo
                  key={index}
                  notificationIndex={index}
                  data={nf}
                  notificationType="sent_notification"
                  {...this.props}
                />
              ))}
            </CardContent>
          </Card>
        )}
      </Paper>
    )
  }
}

TaskPeopleList.propTypes = {
  classes: PropTypes.object,
  handleStepper: PropTypes.func,
  info: PropTypes.shape({
    project: PropTypes.object,
    isEdit: PropTypes.bool,
    task: PropTypes.shape({
      current: PropTypes.shape({
        assets: PropTypes.arrayOf(PropTypes.object),
        participants: PropTypes.arrayOf({
          addedTime: PropTypes.instanceOf(Date),
        }),
        notification: PropTypes.arrayOf({
          sent_notification: PropTypes.arrayOf(PropTypes.object),
          schedule_notification: PropTypes.arrayOf(PropTypes.object),
        }),
      }),
      default: PropTypes.shape({
        assets: PropTypes.arrayOf(PropTypes.object),
        participants: PropTypes.arrayOf({
          addedTime: PropTypes.instanceOf(Date),
        }),
        notification: PropTypes.arrayOf({
          sent_notification: PropTypes.arrayOf(PropTypes.object),
          schedule_notification: PropTypes.arrayOf(PropTypes.object),
        }),
      }),
      taskFormData: PropTypes.object,
    }),
  }),
}

const MyComponent = (props) => {
  const classes = styles()
  return <TaskPeopleList {...props} classes={classes} />
}

export default MyComponent
