import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import { Button, Paper } from '@mui/material'
import { praxis } from '../../config/themeConfig'

const styles = makeStyles((theme) => ({
  cardWrapperTopPadding: {
    paddingTop: useTheme().spacing(2),
  },
  cardWrapperBottomPadding: {
    paddingBottom: useTheme().spacing(2),
  },
  buttonPosition: {
    position: 'absolute',
    right: '20px',
  },
  buttonStyle: {
    color: praxis.primaryBlue,
    padding: '10px',
    marginLeft: '10px',
  },
  buttonSecondary: {
    backgroundColor: praxis.lightestGrey,
  },
  buttonPrimary: {
    backgroundColor: praxis.primaryBlue,
    color: praxis.contrastText,
  },
  cardHeader: {
    position: 'relative',
    paddingLeft: useTheme().spacing(2),
    paddingRight: useTheme().spacing(2),
    [useTheme().breakpoints.up('sm')]: {
      paddingLeft: useTheme().spacing(3),
      paddingRight: useTheme().spacing(3),
    },
  },
  cardTitle: {
    fontSize: '2em',
    display: 'inline',
  },
  cardContent: {
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  cardContentNoPadding: {
    padding: 0,
  },
}))

export class CollapsibleCard extends React.Component {
  constructor(props) {
    super(props)
    const { defaultCollapse } = props
    this.state = {
      isCollapsed: defaultCollapse,
    }
  }

  collapseHandler = () => {
    const { cardTitle = 'DETAILS', collapseCallback = () => {} } = this.props
    const { isCollapsed } = this.state
    this.setState({ isCollapsed: !isCollapsed })
    collapseCallback(cardTitle, !isCollapsed)
  }

  render() {
    const {
      classes = {},
      cardTitle = 'DETAILS',
      hideButtonText = 'HIDE',
      showButtonText = 'SHOW',
      fullWidthContent = false,
      isActionMode = false,
      disablePrimaryButton = false,
      primaryButtonText = 'PRIMARY',
      secondaryButtonText = 'SECONDARY',
      primaryButtonHandler = () => {},
      secondaryButtonHandler = () => {},
      children,
    } = this.props
    const { isCollapsed } = this.state
    const buttonText = isCollapsed ? showButtonText : hideButtonText
    const showBottomPadding =
      !fullWidthContent || (fullWidthContent && isCollapsed)
    return (
      <Paper
        className={cx(classes.cardWrapperTopPadding, {
          [classes.cardWrapperBottomPadding]: showBottomPadding,
        })}
        elevation={1}
      >
        <div className={classes.cardHeader}>
          <span className={classes.cardTitle}>{cardTitle}</span>
          {isActionMode && (
            <span className={classes.buttonPosition}>
              <Button
                className={cx(classes.buttonStyle, classes.buttonSecondary)}
                onClick={secondaryButtonHandler}
              >
                {secondaryButtonText}
              </Button>
              <Button
                className={cx(classes.buttonStyle, classes.buttonPrimary)}
                variant="contained"
                onClick={primaryButtonHandler}
                disabled={disablePrimaryButton}
              >
                {primaryButtonText}
              </Button>
            </span>
          )}
          {!isActionMode && (
            <Button
              className={cx(classes.buttonPosition, classes.buttonStyle)}
              onClick={this.collapseHandler}
              data-testid={'toggleButton'}
            >
              {buttonText}
            </Button>
          )}
        </div>
        {!isCollapsed && (
          <div
            className={
              fullWidthContent
                ? classes.cardContentNoPadding
                : classes.cardContent
            }
          >
            {children}
          </div>
        )}
      </Paper>
    )
  }
}

CollapsibleCard.propTypes = {
  classes: PropTypes.object,
  cardTitle: PropTypes.string,
  hideButtonText: PropTypes.string,
  showButtonText: PropTypes.string,
  defaultCollapse: PropTypes.bool,
  fullWidthContent: PropTypes.bool,
  isActionMode: PropTypes.bool,
  disablePrimaryButton: PropTypes.bool,
  primaryButtonText: PropTypes.string,
  secondaryButtonText: PropTypes.string,
  primaryButtonHandler: PropTypes.func,
  secondaryButtonHandler: PropTypes.func,
  collapseCallback: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

const MyComponent = (props) => {
  const classes = styles()
  return <CollapsibleCard {...props} classes={classes} />
}

export default MyComponent
