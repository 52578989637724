import React, { Fragment, useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { withStyles } from '@mui/styles'
import { Grid, CircularProgress, IconButton, Tooltip } from '@mui/material'
import {
  ChevronLeft,
  ChevronRight,
  ZoomIn,
  ZoomOut,
  RotateLeft,
} from '@mui/icons-material'

import ImageZoom from './ImageZoom'

const styles = {
  imageBox: {
    height: '95vh',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#484848',
    maxWidth: 'none',
  },
  loadingImage: {
    justifyContent: 'center',
    padding: 0,
  },
  showImage: {
    margin: '50px auto auto auto',
  },
  ArrowButton: {
    color: '#FFFFFF',
    background: 'rgba(0, 0, 0, 0.5)',
    backgroundImage: 'none',
    height: '60px',
    width: '60px',
    margin: '10px',
    fontSize: '36px',
    position: 'fixed',
  },
  leftNextArrow: {
    left: 20,
    top: '50%',
  },
  rightNextArrow: {
    right: 20,
    top: '50%',
  },
  root: {
    position: 'absolute',
    right: '50%',
    top: '50%',
    marginTop: -20,
    marginLeft: -20,
  },
  zoomTextDiv: {
    display: 'flex',
    zIndex: 5,
    backgroundColor: '#EFEFEF',
    color: 'black',
    height: '40px',
    width: '45px',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid rgb(141, 141, 141)',
    fontSize: '14px',
    fontWeight: '500',
  },
  zoomControlsDiv: {
    display: 'flex',
    zIndex: 5,
    position: 'absolute',
    flexDirection: 'column',
    backgroundColor: 'rgb(141, 141, 141)',
    border: '1px solid rgb(141, 141, 141)',
  },
  zoomControls: {
    height: '40px',
    width: '45px',
    padding: 0,
    borderRadius: 0,
    minWidth: '45px !important',
    color: 'black',
  },
}

const ImageAnnotatorComp = (props, ref) => (
  <ImageZoom {...props} zoomPerRef={ref} />
)
const ImageAnnotatorNew = React.forwardRef(ImageAnnotatorComp)
const angles = [0, 90, 180, 270]

const ImageCarousel = ({
  classes = {},
  imageUrl = '',
  IsFetching = false,
  PrevAsset = () => {},
  NextAsset = () => {},
  scale = '100vh',
  isSidebarOpen = false,
  sidebarWidth = 650,
  isArrowShow = true,
}) => {
  const zoomPerRef = useRef()

  const [width, setWidth] = useState(Number(0))
  const [height, setHeight] = useState(Number(0))
  const [angle, setAngle] = useState(Number(0))
  const [zoomAnnotatorProps, setZoomAnnotatorProps] = useState({})

  useEffect(() => {
    updateWindowDimensions()
    window.addEventListener('resize', updateWindowDimensions)
    window.addEventListener('beforeunload', beforeUnload)
    return () => {
      window.removeEventListener('resize', updateWindowDimensions)
      window.removeEventListener('beforeunload', beforeUnload)
    }
  }, [])
  const updateWindowDimensions = () => {
    setWidth(window.innerWidth)
    setHeight(window.innerHeight)
  }
  const beforeUnload = (e) => {
    e.preventDefault()
    return (e.returnValue = 'new thing')
  }

  const getImageControlHandlers = (handlersList = {}) => {
    setZoomAnnotatorProps(Object.assign({}, handlersList))
  }
  let loadedImage = new Image()
  loadedImage.src = imageUrl
  let imageWidth = '100vw',
    imageHeight = '100vh'
  imageHeight =
    scale === Number(0)
      ? 'calc(100vh - 50px)'
      : `${scale * loadedImage.height}px`
  imageWidth =
    scale === Number(0)
      ? isSidebarOpen
        ? `calc(100vw - ${sidebarWidth}px)`
        : '100vw'
      : `${scale * loadedImage.width}px`
  let imageTopMargin =
    scale !== 0 &&
    Number(imageHeight.replace('px', '')) + 50 < window.innerHeight
      ? `calc((100vh - ${imageHeight.replace('px', '')}px)/2)`
      : '50px'
  const {
    resetTransform = () => {},
    zoomIn = () => {},
    zoomOut = () => {},
  } = zoomAnnotatorProps
  return (
    <Fragment>
      {IsFetching ? (
        <Grid
          className={cx(classes.imageBox, classes.loadingImage)}
          item
          xs={12}
        >
          <CircularProgress size={40} classes={{ root: classes.root }} />
        </Grid>
      ) : (
        <Grid
          className={cx(classes.imageBox, classes.showImage)}
          style={{
            height: imageHeight,
            width: imageWidth,
            marginTop: imageTopMargin,
            textAlign: 'center',
          }}
          item
          xs={12}
        >
          <div className={classes.zoomControlsDiv}>
            <div className={classes.zoomTextDiv} ref={zoomPerRef}>
              zoom text
            </div>
            <Tooltip title="ZoomIn" placement="right">
              <IconButton
                className={classes.zoomControls}
                onClick={(e) => zoomIn(e)}
              >
                <ZoomIn fontSize="medium" />
              </IconButton>
            </Tooltip>
            <Tooltip title="ZoomOut" placement="right">
              <IconButton
                className={classes.zoomControls}
                onClick={(e) => zoomOut(e)}
              >
                <ZoomOut fontSize="medium" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Reset" placement="right">
              <IconButton
                className={classes.zoomControls}
                onClick={() => resetTransform()}
              >
                <RotateLeft fontSize="medium" />
              </IconButton>
            </Tooltip>
          </div>
          <ImageAnnotatorNew
            onSubmitAnnotation={() => {}}
            annotations={[]}
            currentTool={'square'}
            numberOfMarkups={Number(0)}
            angle={angles[angle]}
            isSelected={false}
            containerHeight={height - 114}
            containerWidth={width - 320}
            ref={zoomPerRef}
            getImageControlHandlers={getImageControlHandlers}
            disableOverlay={true}
            nonReviewPage={true}
            previewImageUrl={imageUrl}
          />
          <IconButton
            className={cx(classes.ArrowButton, classes.leftNextArrow)}
            onClick={PrevAsset}
            disabled={!isArrowShow}
          >
            <ChevronLeft fontSize="inherit" />
          </IconButton>
          <IconButton
            className={cx(classes.ArrowButton, classes.rightNextArrow)}
            style={{ right: isSidebarOpen ? sidebarWidth + 20 : 20 }}
            onClick={NextAsset}
            disabled={!isArrowShow}
          >
            <ChevronRight fontSize="inherit" />
          </IconButton>
        </Grid>
      )}
    </Fragment>
  )
}

ImageCarousel.propTypes = {
  classes: PropTypes.object,
  imageUrl: PropTypes.string,
  IsFetching: PropTypes.bool.isRequired,
  NextAsset: PropTypes.func.isRequired,
  PrevAsset: PropTypes.func.isRequired,
  scale: PropTypes.number,
  isSidebarOpen: PropTypes.bool,
  sidebarWidth: PropTypes.number,
  isArrowShow: PropTypes.bool,
  key: PropTypes.string,
  usePreviewUrl: PropTypes.func,
}

export default withStyles(styles)(ImageCarousel)
