import * as React from 'react'
import PropTypes from 'prop-types'
import { FormControlLabel } from '@mui/material'
import { withStyles } from '@mui/styles'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import Checkbox from '@mui/material/Checkbox'

const styles = () => ({
  liStyle: {
    position: 'relative',
    listStyleType: 'none',
  },
  ulStyle: {
    listStyleType: 'none',
  },
  addIconStyle: {
    position: 'absolute',
    left: '-7%',
    marginTop: '10px',
    cursor: 'pointer',
    display: 'inline',
  },
})

export class MultiLevelCheckBox extends React.Component {
  constructor(props) {
    super(props)
  }

  renderCheckBoxMultiLevelTree = (nodes) => {
    const { classes = {} } = this.props
    if (nodes && nodes.subchannel) {
      return (
        <ul className={classes.ulStyle}>
          {nodes.subchannel.map((node) => this.renderSingleCheckBox(node))}
        </ul>
      )
    } else {
      return null
    }
  }

  renderSingleCheckBox = (channel) => {
    const {
      classes = {},
      isDetailsPage = false,
      handleCheckBoxEvent = () => {},
      handleExpensionCheckBox = () => {},
      handleParentCheckBox = () => {},
      setSelectedChannel = () => {},
      isInternalUsages = false,
      selectedTerm = '',
    } = this.props
    return (
      <li className={classes.liStyle}>
        {channel &&
          channel.subchannel &&
          channel.subchannel.length > 0 &&
          (!channel.isOpen ? (
            <AddIcon
              data-cy={'addIcon' + channel.label}
              className={classes.addIconStyle}
              onClick={
                isInternalUsages ||
                selectedTerm === 'Work for Hire/Total Buy Out'
                  ? () => {}
                  : (event) => {
                      handleExpensionCheckBox(event, channel)
                    }
              }
            />
          ) : (
            <RemoveIcon
              data-cy={'removeIcon' + channel.label}
              className={classes.addIconStyle}
              onClick={
                isInternalUsages ||
                selectedTerm === 'Work for Hire/Total Buy Out'
                  ? () => {}
                  : (event) => {
                      handleExpensionCheckBox(event, channel)
                    }
              }
            />
          ))}
        {channel.checkStatus !== 'INDETERMINATE' ? (
          <FormControlLabel
            control={
              <Checkbox
                id={channel.label}
                checked={channel.isChecked}
                onChange={(event) => {
                  handleCheckBoxEvent(event, channel)
                  handleParentCheckBox(channel.parentNode)
                  setSelectedChannel()
                }}
                name={channel.label}
                disabled={
                  isDetailsPage ||
                  isInternalUsages ||
                  selectedTerm === 'Work for Hire/Total Buy Out'
                }
              />
            }
            label={channel.label}
          />
        ) : (
          <FormControlLabel
            control={
              <Checkbox
                checked={true}
                onChange={(event) => {
                  handleCheckBoxEvent(event, channel)
                  handleParentCheckBox(channel.parentNode)
                  setSelectedChannel()
                }}
                name="checkedIndeterminate"
                indeterminate
                disabled={
                  isDetailsPage ||
                  isInternalUsages ||
                  selectedTerm === 'Work for Hire/Total Buy Out'
                }
              />
            }
            label={channel.label}
          />
        )}
        {channel &&
          channel.isOpen &&
          channel.subchannel &&
          this.renderCheckBoxMultiLevelTree(channel)}
      </li>
    )
  }

  render() {
    const {
      classes,
      channels = [],
      isInternalUsages,
      selectedTerm,
      isDetailsPage,
      handleCheckBoxEvent = () => {},
      handleExpensionCheckBox = () => {},
      handleParentCheckBox = () => {},
      setSelectedChannel = () => {},
    } = this.props
    return (
      <React.Fragment>
        {channels &&
          channels.map((channel, index) => (
            <li id={channel.label + '-' + index} className={classes.liStyle}>
              {channel &&
                channel.subchannel &&
                channel.subchannel.length > 0 &&
                (!channel.isOpen ? (
                  <AddIcon
                    data-cy={'addIcon' + channel.label}
                    onClick={
                      isInternalUsages ||
                      selectedTerm === 'Work for Hire/Total Buy Out'
                        ? () => {}
                        : (event) => {
                            handleExpensionCheckBox(event, channel)
                          }
                    }
                    className={classes.addIconStyle}
                  />
                ) : (
                  <RemoveIcon
                    data-cy={'removeIcon' + channel.label}
                    onClick={
                      isInternalUsages ||
                      selectedTerm === 'Work for Hire/Total Buy Out'
                        ? () => {}
                        : (event) => {
                            handleExpensionCheckBox(event, channel)
                          }
                    }
                    className={classes.addIconStyle}
                  />
                ))}
              {channel.checkStatus !== 'INDETERMINATE' ? (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={channel.isChecked}
                      onChange={(event) => {
                        handleCheckBoxEvent(event, channel)
                        handleParentCheckBox(channel.parentNode)
                        setSelectedChannel()
                      }}
                      name={channel.label}
                      disabled={
                        isDetailsPage ||
                        isInternalUsages ||
                        selectedTerm === 'Work for Hire/Total Buy Out'
                      }
                    />
                  }
                  label={channel.label}
                />
              ) : (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={true}
                      onChange={(event) => {
                        handleCheckBoxEvent(event, channel)
                        handleParentCheckBox(channel.parentNode)
                        setSelectedChannel()
                      }}
                      name="checkedIndeterminate"
                      indeterminate
                      disabled={
                        isDetailsPage ||
                        isInternalUsages ||
                        selectedTerm === 'Work for Hire/Total Buy Out'
                      }
                    />
                  }
                  label={channel.label}
                />
              )}
              {channel &&
                channel.isOpen &&
                channel.subchannel &&
                selectedTerm !== 'Work for Hire/Total Buy Out' &&
                this.renderCheckBoxMultiLevelTree(channel)}
            </li>
          ))}
      </React.Fragment>
    )
  }
}

MultiLevelCheckBox.propTypes = {
  classes: PropTypes.object.isRequired,
  channels: PropTypes.array,
  isInternalUsages: PropTypes.bool,
  selectedTerm: PropTypes.string,
  isDetailsPage: PropTypes.bool,
  handleCheckBoxEvent: PropTypes.func,
  handleExpensionCheckBox: PropTypes.func,
}

export default withStyles(styles)(MultiLevelCheckBox)
