import React from 'react'
import PropTypes from 'prop-types'

function A({ ...props }) {
  const { children, ...rest } = props
  return <a {...rest}>{children}</a>
}

A.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.any,
}

export default A
