import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import axios from 'axios'
import { toLower, uniqBy } from 'lodash'

import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import FaceIcon from '@mui/icons-material/Person'
import DeleteIcon from '@mui/icons-material/Close'
import TextField from '@mui/material/TextField'
import Chip from '@mui/material/Chip'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import LinearProgress from '@mui/material/LinearProgress'
import Divider from '@mui/material/Divider'

import Fab from '@mui/material/Fab'
import Avatar from './Avatar'
import apiConfig from '../../config/apiConfig'
import ListItemText from '../Custom/ListItemText'
import NoFileLogo from '../../images/round-group-default.svg'
import { fieldValidRegex, emailInAStringRegex } from '../../helpers/Regexes'
import { TEXT_SPECIAL_CHARS_FORM_VALID } from '../../constants/notifications'

const WAIT_INTERVAL = 1000

export class AutocompleteChip extends Component {
  static defaultProps = {}
  state = {
    chipData: [],
    chipValues: [],
    name: '',
    tmList: [],
    isLoading: false,
    minLength: 2,
  }
  constructor(props) {
    super(props)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.triggerChange = this.triggerChange.bind(this)
    this.handlePaste = this.handlePaste.bind(this)
    this.renderTmList = this.renderTmList.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.fieldName = props.fieldName || 'project_owners'
    this.state.chipData = props.values || []
  }
  componentDidMount() {
    this.timer = null
    this.setState({ chipData: this.props.values || [] })
  }
  componentWillReceiveProps(nextProps) {
    if (this.state.name && nextProps.tmList) {
      this.setState({ tmList: nextProps.tmList.tmList })
      this.setState({ isLoading: false })
    }
  }
  handleChange(event) {
    clearTimeout(this.timer)
    const currentTargetVal =
      typeof event === 'object' ? event.target.value : event
    this.setState({ name: currentTargetVal }, () => {
      if (this.state.name && this.state.name.length > this.state.minLength) {
        this.setState({ isLoading: true })
      } else {
        this.setState({ isLoading: false, tmList: '' })
      }
    })
    this.timer = setTimeout(this.triggerChange, WAIT_INTERVAL)
  }

  triggerChange() {
    const { name = '' } = this.state
    let isError = (name.match(fieldValidRegex) || []).length > 0
    if (!isError) this.props.onNameChange(name.trim())
  }

  handlePaste(e) {
    e.preventDefault()
    // copy the values from the clipboard
    const {
      onChipPasteCallBack,
      onChange: propsOnChange,
      values: usersList = [],
      accessToken = '',
    } = this.props
    const { chipData = [] } = this.state
    const { userDetails = {}, apiConfigKey = '' } = apiConfig
    const { usersFromEmailid = '' } = userDetails
    const pastedStr = e.clipboardData.getData('text/plain') || ''
    const chipHistory = onChipPasteCallBack ? usersList : chipData
    let chipLogs = []
    let latestChipArr = []
    let url = `${usersFromEmailid}?key=${apiConfigKey}`
    switch (this.fieldName) {
      case 'task_uploaders':
        url = `${url}&fetch_type=uploader`
        break
      case 'task_reviewers':
        url = `${url}&fetch_type=reviewer`
        break
      case 'task_observers':
        url = `${url}&fetch_type=observer`
        break
    }
    let newChips = pastedStr.match(emailInAStringRegex)
    // generate array with display name to display the values while ajax call is happening
    if (newChips) {
      newChips.map((value) => {
        if (chipHistory.filter((a) => a.email_address === value).length === 0) {
          chipLogs.push({ display_name: value })
          latestChipArr.push(value)
        }
      })
      if (onChipPasteCallBack) {
        this.setState({
          chipData: uniqBy(chipHistory.concat(chipLogs) || [], 'display_name'),
        })
      }
    }

    if (pastedStr.indexOf('@') === -1) {
      this.setState({ isLoading: true })
      this.handleChange(pastedStr)
    } else {
      if ((latestChipArr || []).length) {
        this.setState({ isLoading: true })
        // fetch the valid email address
        axios
          .post(url, {
            headers: {
              Authorization: accessToken,
              'Content-Type': 'application/json',
            },
            emailidlist: latestChipArr,
          })
          .then((response) => {
            const { data = {} } = response
            const { users: responseChips = [] } = data
            this.setState({ isLoading: false })
            latestChipArr.map((value) => {
              // indentify the invalid TCINS
              if (
                responseChips.filter(
                  (c) => toLower(c.email_address) === toLower(value)
                ).length === 0 &&
                (chipHistory.filter(
                  (b) => toLower(b.email_address) === toLower(value)
                ).length === 0 ||
                  chipHistory.filter(
                    (a) => toLower(a.display_name) === toLower(value)
                  ).length === 0)
              ) {
                responseChips.push({ display_name: value, error: true })
              }
            })
            this.setState(
              {
                chipData: uniqBy(
                  chipHistory.concat(responseChips) || [],
                  'display_name'
                ),
              },
              () => {
                onChipPasteCallBack
                  ? onChipPasteCallBack(chipData)
                  : propsOnChange(this.fieldName, chipData)
              }
            )
          })
          .catch(() => {
            this.setState({ isLoading: false })
          })
      }
    }
  }
  disableClose(index) {
    let isCloseDisabled = !this.props.hideClose || false
    isCloseDisabled = index > 0 ? false : isCloseDisabled
    return isCloseDisabled
  }
  handleClick(data) {
    if (this.props.isChipRequired) {
      let searchedObject = this.state.chipData.find(
        (chipInfo) => chipInfo.display_name === data.display_name
      )
      if (!searchedObject) {
        this.setState({ chipData: [...this.state.chipData, data] }, () => {
          this.props.onChange(this.fieldName, this.state.chipData)
        })
      }
    } else {
      this.props.onChange(this.fieldName, data)
    }
    let $this = this
    setTimeout(function () {
      $this.setState({ name: '' })
      $this.setState({ tmList: [] })
    }, 100)
  }
  handleDelete(data) {
    const { fieldName = '' } = this.props
    let chipData = [...this.state.chipData]
    const chipToDelete = chipData.indexOf(data)
    chipData.splice(chipToDelete, 1)
    this.setState({ chipData }, () => {
      this.props.onChange('delete', this.state.chipData, fieldName)
    })
  }
  renderTmList(tmList) {
    let tmReturnList = ''
    if (tmList && tmList.length) {
      const { classes } = this.props
      tmReturnList = (
        <div className={classes.userList}>
          <List>
            {tmList.map((value, index) => (
              <ListItem
                onClick={() => this.handleClick(value)}
                data-cy="editIndividualAddList"
                key={index}
                dense
                button
                className={classes.listItem}
              >
                {/* {value.group_id ? <Avatar src="no" className={classes.avatar}><FaceIcon /></Avatar>
                : <Avatar src={value.user_image} style={{width: '50px', height: '50px', fontSize: '1.1rem'}} alt={value.group_id ? value.display_name.charAt(0) : value.first_name.charAt(0) + '' + value.last_name.charAt(0)} />
              } */}
                <ListItemAvatar>
                  <Fab className={classes.fab}>
                    {value.display_name.split('.').length > 1
                      ? value.display_name.split('.')[0].substring(0, 1) +
                        value.display_name.split('.')[1].substring(0, 1)
                      : value.display_name.split('.')[0].substring(0, 1)}
                  </Fab>
                </ListItemAvatar>
                <ListItemText
                  primary={value.display_name}
                  secondary={
                    value.group_id
                      ? value.num_of_users + ' members'
                      : value.email_address
                  }
                  tertiary={value.group_id ? '' : value.title}
                  primaryTypographyProps={{ className: classes.primary }}
                  secondaryTypographyProps={{ className: classes.secondary }}
                  tertiaryTypographyProps={{ className: classes.secondary }}
                />
                <Divider />
              </ListItem>
            ))}
          </List>
        </div>
      )
    }
    return tmReturnList
  }
  render() {
    const { classes } = this.props
    let IsError = false
    let isNameError = false
    const chipRender = this.props.onChipPasteCallBack
      ? this.state.chipData.filter((dataObj) => {
          if (dataObj.error === true) {
            IsError = true
            return dataObj.error
          }
          return false
        })
      : this.state.chipData
    isNameError = (this.state.name.match(fieldValidRegex) || []).length > 0
    return (
      <div>
        <div
          className={classes.root}
          style={{ display: 'flex', flexWrap: 'wrap', background: 'none' }}
        >
          {chipRender.map((data, index) => {
            let avatarImage
            if (data.group_id !== null) {
              avatarImage = (
                <Avatar
                  style={
                    data.error !== undefined && data.error
                      ? { backgroundColor: '#bdbdbd', color: '#484848' }
                      : {}
                  }
                >
                  <FaceIcon />
                </Avatar>
              )
            } else {
              avatarImage = (
                <Fab className={classes.fab}>
                  {data.display_name.split('.').length > 1
                    ? data.display_name.split('.')[0].substring(0, 1) +
                      data.display_name.split('.')[1].substring(0, 1)
                    : data.display_name.split('.')[0].substring(0, 1)}
                </Fab>
              )
            }
            const customClass =
              data.error !== undefined && data.error
                ? { backgroundColor: '#CC0000', color: '#FFFFFF' }
                : {}
            return (
              <Chip
                key={index}
                avatar={avatarImage}
                label={data.display_name}
                onDelete={
                  this.disableClose(index) ? '' : () => this.handleDelete(data)
                }
                className={classes.chip}
                deleteIcon={
                  <DeleteIcon
                    className={
                      data.error !== undefined && data.error
                        ? classes.deleteIcon
                        : classes.deleteIconErr
                    }
                  />
                }
                style={customClass}
              />
            )
          })}
        </div>
        <TextField
          variant="standard"
          id="tm"
          label={this.props.Label ? this.props.Label : 'Project Owners'}
          className={this.props.className ? this.props.className : classes.root}
          value={this.state.name}
          error={isNameError}
          helperText={isNameError && TEXT_SPECIAL_CHARS_FORM_VALID}
          onChange={(e) => this.handleChange(e)}
          onPaste={(e) => this.handlePaste(e)}
          margin="normal"
          inputProps={{
            autoComplete: 'off',
            'data-cy': 'editIndividualAddChip',
          }}
          fullWidth
        />
        {!isNameError && this.state.isLoading && <LinearProgress />}
        {IsError && (
          <div className={classes.errorText}>
            These e-mail addresses do not have access to AssetHub.
          </div>
        )}
        {!isNameError && this.renderTmList(this.state.tmList)}
      </div>
    )
  }
}

AutocompleteChip.propTypes = {
  accessToken: PropTypes.string,
  className: PropTypes.string,
  classes: PropTypes.object,
  fieldName: PropTypes.string,
  hideClose: PropTypes.bool,
  isChipRequired: PropTypes.bool,
  Label: PropTypes.string,
  onNameChange: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onChipPasteCallBack: PropTypes.func,
  tmList: PropTypes.shape(
    PropTypes.arrayOf(
      PropTypes.shape({
        display_name: PropTypes.string,
        email_address: PropTypes.string,
        first_name: PropTypes.string,
        group_id: PropTypes.string,
        last_name: PropTypes.string,
        num_of_users: PropTypes.string,
        title: PropTypes.string,
        user_image: PropTypes.string,
      })
    )
  ),
  values: PropTypes.arrayOf(
    PropTypes.shape({
      display_name: PropTypes.string,
      email_address: PropTypes.string,
    })
  ),
}

const styles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 530,
    maxHeight: 300,
    backgroundColor: useTheme().palette.background.paper,
    overflow: 'auto',
    marginLeft: 8,
  },
  deleteIcon: {
    color: '#FFF',
    fontSize: 18,
    marginRight: 8,
  },
  deleteIconErr: {
    color: '#666',
    fontSize: 18,
    marginRight: 8,
  },
  userList: {
    width: '100%',
    maxWidth: 500,
    maxHeight: 300,
    backgroundColor: useTheme().palette.background.paper,
    overflow: 'auto',
    position: 'absolute',
    boxShadow:
      '0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)',
    zIndex: 999,
  },
  chip: {
    margin: useTheme().spacing(1 / 2),
  },
  textField: {
    width: 200,
  },
  primary: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#212121',
  },
  secondary: {
    fontSize: 12,
    color: '#484848',
  },
  avatar: {
    width: 50,
    height: 50,
  },
  listItem: {
    height: 80,
  },
  errorText: {
    color: '#cc0000',
  },
  fab: {
    backgroundColor: '#FC3',
    color: '#FFF',
    boxShadow: 'none',
  },
}))

const mapStateToProps = (state = {}) => {
  const { auth = {} } = state
  const { accessToken } = auth
  return {
    accessToken,
  }
}

const MyComponent = (props) => {
  const classes = styles()
  return <AutocompleteChip {...props} classes={classes} />
}
export default connect(mapStateToProps)(MyComponent)
