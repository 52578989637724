import React from 'react'
import { makeStyles } from '@mui/styles'
import { Button, Grid } from '@mui/material'
import { useEnv } from '@praxis/component-runtime-env'
import HomeCarousel from './HomeCarousel'

const useStyles = makeStyles({
  contentContainer: {
    padding: '20px',
    display: 'flex',
    background: '#fcf9fb',
  },
  contentSection: {
    width: '50%',
    padding: '10px',
  },
  carouselSection: {
    width: '50%',
    padding: '15px 5px 0 15px',
  },
  assethubContent: {
    paddingLeft: '20%',
  },
  contentHeading: {
    fontSize: '30px',
  },
  contentDescription: {
    fontSize: '20px',
    fontWeight: 300,
  },
  contentHelp: {
    paddingTop: '10%',
  },
  helpButton: {
    width: 'fit-content',
    height: '50px',
    backgroundColor: '#CC0000',
    borderRadius: '6px',
    '&:hover': {
      background: '#CC0000',
      border: 'solid',
    },
  },
})

function AssethubContentPage() {
  const classes = useStyles()
  const envConfig = useEnv() || {}
  const { ASSETHUB_HELP_PAGE = '' } = envConfig
  return (
    <Grid item container className={classes.contentContainer}>
      <Grid item className={classes.contentSection} xs={12} md={4}>
        <Grid item className={classes.assethubContent}>
          <Grid item className={classes.contentHeading}>
            <h1>Creative Story Empowerment and Enterprise Enablement</h1>
          </Grid>
          <Grid item className={classes.contentDescription}>
            AssetHub is a platform that allows Target Team Members, internal
            tools, contractors, agencies, and vendors, to manage, collaborate,
            and connect programmatically to 2D creative and marketing digital
            assets.
          </Grid>
          <Grid item className={classes.contentHelp}>
            <Button
              variant="contained"
              className={classes.helpButton}
              href={ASSETHUB_HELP_PAGE}
              target={'_blank'}
            >
              {' '}
              Click for Training &amp; Help
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={classes.carouselSection} xs={12} md={8}>
        <HomeCarousel />
      </Grid>
    </Grid>
  )
}

export default AssethubContentPage
