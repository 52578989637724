import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'
import Grid from '@mui/material/Grid'
import FilterList from '@mui/icons-material/FilterList'
import SortIcon from '@mui/icons-material/Sort'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import Select from '@mui/material/Select'
// handshake
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { boardsFilter, boardsSort } from '../../store/boards/actions'
import { BOARD_SORT_OPTIONS, CREATED_BOARD_TYPE } from '../../constants/boards'

const styles = makeStyles((theme) => ({
  root: {
    direction: 'row',
    justify: 'center',
    alignItems: 'center',
  },
  formControl: {
    margin: useTheme().spacing(),
    minWidth: 320,
  },
  iconAlign: {
    paddingTop: useTheme().spacing(3),
  },
}))

export class Filters extends React.PureComponent {
  constructor(props, context) {
    super(props, context)
    this.state = {
      value: 'created_at',
    }
  }
  handleFilter = (event) => {
    if (event.currentTarget) {
      const { boardsType = CREATED_BOARD_TYPE, boardsFilter = () => {} } =
        this.props
      boardsFilter({
        filterValue: event.currentTarget.value,
        boardsType,
      })
    }
  }
  handleSort = (event) => {
    const { boardsSort = () => {} } = this.props
    this.setState({ value: event.target.value })
    if (event.currentTarget) {
      const { boardsType = CREATED_BOARD_TYPE } = this.props
      boardsSort({ sortType: event.currentTarget.value, boardsType })
    }
  }
  renderSortOptions = () => {
    const { classes = {} } = this.props
    const { value: sortOptionsValue = '' } = this.state
    return (
      <FormControl
        variant="standard"
        style={{ width: '40%' }}
        className={classes.formControl}
      >
        <InputLabel htmlFor="age-native-simple">Select Sort Option</InputLabel>
        <Select
          variant="standard"
          native
          inputProps={{ 'data-cy': 'boardsSortOptions' }}
          onChange={this.handleSort}
          value={sortOptionsValue}
        >
          {Object.keys(BOARD_SORT_OPTIONS).map((key) => (
            <option value={key} key={key}>
              {BOARD_SORT_OPTIONS[key]}
            </option>
          ))}
        </Select>
      </FormControl>
    )
  }
  renderFiltersList = () => {
    const { classes = {} } = this.props
    return (
      <FilterList className={classes.iconAlign} style={{ color: '#8d8d8d' }} />
    )
  }
  renderFilters = () => {
    const { classes = {} } = this.props
    return (
      <FormControl
        variant="standard"
        style={{ width: '40%' }}
        className={classes.formControl}
      >
        <InputLabel htmlFor="board-name-filter">Filter Boards</InputLabel>
        <Input
          inputProps={{ 'data-cy': 'filterBoardsText' }}
          id="board-name-filter"
          ref={this.searchFilter}
          onChange={this.handleFilter}
        />
        <FormHelperText>Filter within these results</FormHelperText>
      </FormControl>
    )
  }
  render() {
    const { classes = {} } = this.props
    return (
      <div className={classes.root}>
        <Grid container spacing={1} justifyContent="center">
          <Grid item>
            <SortIcon
              className={classes.iconAlign}
              style={{ color: '#8d8d8d' }}
            />
          </Grid>
          <Grid item>{this.renderSortOptions()}</Grid>
          <Grid item>{this.renderFiltersList()}</Grid>
          <Grid item style={{ marginTop: '-2px' }}>
            {this.renderFilters()}
          </Grid>
        </Grid>
      </div>
    )
  }
}

Filters.propTypes = {
  classes: PropTypes.object.isRequired,
  boardsFilter: PropTypes.func,
  boardsSort: PropTypes.func,
  boardsType: PropTypes.string,
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ boardsFilter, boardsSort }, dispatch)

const MyComponent = (props) => {
  const classes = styles()
  return <Filters {...props} classes={classes} />
}

export default connect(null, mapDispatchToProps)(MyComponent)
