import * as React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material'
import { withStyles } from '@mui/styles'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'

const filter = createFilterOptions()
const styles = () => ({})

export class AutoCompleteHolderName extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: null,
      open: false,
      holderName: '',
      isApiCall: false,
    }
  }

  componentDidUpdate = () => {
    if (
      this.props.selectedRightsHolderObj.title !== '' &&
      this.state.value === null
    ) {
      this.setState({ value: this.props.selectedRightsHolderObj })
    }
  }

  handleClose = () => {
    this.setState({ holderName: '', open: false, isApiCall: false })
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.setState({ isApiCall: true })
    // call API here
    this.props.callApiToSaveHolderName(this.state.holderName, () => {
      this.handleClose()
    })
  }

  handleOnchangeAutoComplete = (newValue) => {
    if (typeof newValue === 'string') {
      // timeout to avoid instant validation of the dialog's form.
      setTimeout(() => {
        this.setState({ open: true, holderName: newValue })
      })
    } else if (newValue && newValue.inputValue) {
      this.setState({ open: true, holderName: newValue.inputValue })
    } else {
      this.setState({ value: newValue })
      if (newValue === null) {
        this.props.setSelectedHolderName('')
      } else {
        this.props.setSelectedHolderName(newValue.title)
      }
    }
  }

  onChangeDialogText = (event) => {
    this.setState({ holderName: event.target.value })
  }

  render() {
    const { optionsArray = [], disabled = false } = this.props
    const {
      holderName = '',
      value = null,
      open = false,
      isApiCall = false,
    } = this.state
    return (
      <React.Fragment>
        <Autocomplete
          value={value}
          onChange={(event, newValue) => {
            this.handleOnchangeAutoComplete(newValue)
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params)
            if (params.inputValue !== '') {
              filtered.push({
                inputValue: params.inputValue,
                title: `+ Create New Option "${params.inputValue}"`,
              })
            }
            return filtered
          }}
          id="free-solo-dialog-demo"
          options={optionsArray}
          getOptionLabel={(option) => {
            // e.g value selected with enter, right from the input
            if (typeof option === 'string') {
              return option
            }
            if (option.inputValue) {
              return option.inputValue
            }
            return option.title
          }}
          disabled={disabled}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          renderOption={(props, option, { selected }) => (
            <li {...props}> {option.title} </li>
          )}
          freeSolo
          renderInput={(params) => (
            <TextField
              {...params}
              data-cy="rightsHolderName"
              label="Rights Holder Name"
              variant="outlined"
            />
          )}
        />
        <Dialog
          open={open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <form onSubmit={this.handleSubmit}>
            <DialogTitle id="form-dialog-title">
              Add a New Holder Name
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Did you miss Any Holder Name in our list? Please, add it!
              </DialogContentText>
              <TextField
                variant="standard"
                autoFocus
                margin="dense"
                id="name"
                value={holderName}
                onChange={this.onChangeDialogText}
                label="New Holder Name"
                type="text"
                style={{ width: '100%' }}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.handleClose}
                color="primary"
                disabled={isApiCall}
              >
                Cancel
              </Button>
              <Button type="submit" color="primary" disabled={isApiCall}>
                {!isApiCall ? 'Add' : 'Adding'}
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </React.Fragment>
    )
  }
}

AutoCompleteHolderName.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedRightsHolderObj: PropTypes.object,
  callApiToSaveHolderName: PropTypes.func,
  setSelectedHolderName: PropTypes.func,
  optionsArray: PropTypes.array,
  disabled: PropTypes.bool,
}

export default withStyles(styles)(AutoCompleteHolderName)
