import Firefly from 'firefly-sdk-ts'
import * as log from 'loglevel'
import moment from 'moment-timezone'
import apiConfig from '../config/apiConfig'
import * as fireflyInternalAppConfig from '../config/fireflyConfig'

export let sessionId = ''
export let taskId = ''
let previousEventTimeStamp = ''

const HALF_HOUR = 30

export function initializeFirefly() {
  const analyticsEndpoint = apiConfig.analytics.url
  fireflyInternalAppConfig.default.url = analyticsEndpoint
  fireflyInternalAppConfig.default.apiKey = apiConfig.key

  Firefly.setLoggingLevel(log.levels.TRACE)
  log.debug(`Initializing Firefly to post to: ${analyticsEndpoint}.`)
  Firefly.registerEventManagerWithConfig(fireflyInternalAppConfig.default)
  Firefly.registerEventManager(
    fireflyInternalAppConfig.default.eventManagerKey,
    fireflyInternalAppConfig.default.apiKey,
    fireflyInternalAppConfig.default.persistMethod,
    fireflyInternalAppConfig.default.retries,
    fireflyInternalAppConfig.default.schemaId,
    fireflyInternalAppConfig.default.url,
  )
}

export function generateSessionId(loginId = '') {
  sessionId = loginId + '+' + new Date().toISOString()
}

export function resetTaskId() {
  taskId = ''
}

export function generateTaskId(loginId = '') {
  taskId = loginId + '+beta_search+' + new Date().toISOString()
}

export function trackSession(loginId = '', currentEventTimeStamp = '') {
  if (previousEventTimeStamp && currentEventTimeStamp) {
    let eventDifference = moment(currentEventTimeStamp).diff(
      moment(previousEventTimeStamp),
    )
    if (moment.duration(eventDifference).minutes() >= HALF_HOUR) {
      sessionId = loginId + '+' + new Date().toISOString()
    }
  }
  previousEventTimeStamp = currentEventTimeStamp
}
