import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import idx from 'idx'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { isEmpty } from 'lodash'
import { withStyles } from '@mui/styles'
import {
  Card,
  CardContent,
  CardActions,
  Avatar,
  Button,
  Tooltip,
  ClickAwayListener,
  Checkbox,
} from '@mui/material'
import { RemoveRedEye, Delete, Edit, Comment, Forum } from '@mui/icons-material'

import MentionBox from './MentionBox'
import CardButtonRow from './CardButtonRow'
import ReplyBox from './ReplyBox'
import { praxis } from '../../config/themeConfig'
import { sortMyArray } from '../../helpers/UtilityHelper'
import { formatAnnotationDate } from '../../helpers/dateHelper'
import { commentPropType, TaskRoles } from '../../constants/annotation'
import {
  createNewComment,
  updateComment,
  deleteComment,
  resolveComment,
} from '../../store/annotation/actions'
import {
  selectIsActiveReview,
  selectIsCurrentTaskRole,
} from '../../store/annotation/selectors'
import { makeSelectAuthData } from '../../store/auth/selector'

import { validateStringMinMax } from '../../helpers/stringHelper'
import {
  getEmailsFromText,
  getAnnotationFromComment,
  parseNameString,
} from '../../helpers/annotationHelper'

const styles = {
  root: {
    padding: '8px',
    marginTop: '35px',
  },
  rootMarkup: {
    padding: '8px',
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '8px 16px',
  },
  button: {
    '&:hover': {
      color: praxis.lighterBlue,
    },
    float: 'left',
    cursor: 'pointer',
  },
  buttonEdit: {
    width: '22px',
    margin: '4px 14px 0 4px',
    color: praxis.lightGrey,
    cursor: 'pointer',
    '&:hover': {
      color: praxis.lighterBlue,
    },
  },
  emptySpace: {
    width: '22px',
    margin: '4px 14px 0 4px',
  },
  buttonDelete: {
    color: praxis.lightGrey,
    cursor: 'pointer',
    '&:hover': {
      color: praxis.lighterBlue,
    },
  },
  buttonDeleteConf: {
    color: praxis.bostonRed,
    cursor: 'pointer',
  },
  avatar: {
    backgroundColor: praxis.themePrimary,
    height: '30px',
    width: '30px',
  },
  initials: {
    fontSize: '12px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  name: {
    marginLeft: '10px',
    fontSize: '12px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  content: {
    padding: '8px 15px',
  },
  textField: {
    border: `1px solid ${praxis.darkGrey}`,
    padding: '10px',
    width: '200px',
    marginTop: '10px',
    resize: 'none',
    fontFamily: 'Roboto',
    fontSize: '12px',
    height: '60px',
  },
  spacing: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 0,
  },
  marker: {
    width: '35px',
    height: '35px',
    color: 'white',
    top: '20px',
    left: '40%',
    display: 'flex',
    position: 'relative',
    borderRadius: '50%',
    backgroundColor: praxis.lighterBlue,
    alignItems: 'center',
    justifyContent: 'center',
    border: '2px solid white',
  },
  charCounter: {
    margin: '5px',
    textAlign: 'end',
    fontSize: '10px',
  },
  innerIcon: {
    height: '18px',
    width: '18px',
  },
  fadedGrey: {
    color: '#888888',
  },
  fadedDate: {
    color: '#888888',
    fontSize: '12px',
    paddingLeft: '25px',
  },
  blueText: {
    color: praxis.darkBlue,
  },
  checkbox: {
    position: 'relative',
    marginTop: '-8px',
    width: '10px',
    height: '20px',
    marginLeft: '16px',
  },
  checkboxLabel: {
    marginLeft: '-2px',
    fontSize: '12px',
    position: 'relative',
    top: '-4px',
  },
  checkboxLeft: {
    float: 'left',
    marginTop: '-5px',
    width: '10px',
    height: '20px',
  },
  checkboxLabelLeft: {
    marginLeft: '-2px',
    fontSize: '12px',
  },
  resolvedCheckbox: {
    display: 'flex',
    gap: '10px',
  },
}

const GreyCheckBox = withStyles({
  root: {
    color: '#888888',
    '&.Mui-checked': {
      color: '#888888',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />)
export class CommentBox extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isEditing: false,
      textField: props.comment.comment_text || '',
      showClassifyButtons: false,
      showReplies: false,
      currentlyEditingReply: {},
      isConfDelete: false,
      isResolved: props.comment.resolved || false,
    }
  }

  toggleEditing = () => {
    const { comment = {} } = this.props

    this.setState((state) => {
      if (state.isEditing) {
        return {
          isEditing: false,
          textField: '',
        }
      } else {
        return {
          isEditing: true,
          textField: comment.comment_text,
        }
      }
    })
  }

  onDeleteClicked = () => {
    const {
      comment = {},
      deleteComment = () => {},
      isSecondary = false,
    } = this.props
    deleteComment({ commentId: comment.comment_id, isSecondary })
  }

  editTextField = (event) => {
    this.setState({ textField: event.target.value })
  }

  editReplyTextField = (event) =>
    this.setState({ replyTextField: event.target.value })

  submitComment = () => {
    const {
      comment = {},
      updateComment = () => {},
      isSecondary = false,
      assetInfo = {},
    } = this.props
    const { textField } = this.state

    updateComment({
      ...comment,
      isSecondary,
      comment_text: textField,
      toAddress: getEmailsFromText(textField),
      asset_name: assetInfo.asset_name,
    })
    this.setState({ isEditing: false, textField: '' })
  }

  handleChangeEditingReply = (reply = {}) =>
    this.setState({
      currentlyEditingReply: reply,
      replyTextField: reply.comment_text || '',
    })

  onSaveReply = () => {
    const {
      isSecondary = false,
      updateComment = () => {},
      createNewComment = () => {},
      comment = {},
      assetInfo = {},
    } = this.props
    const { currentlyEditingReply = {}, replyTextField = '' } = this.state
    const parentCommentId = comment.comment_id
    // Handle if create new reply or edit exisiting
    if (!isEmpty(currentlyEditingReply)) {
      updateComment({
        ...currentlyEditingReply,
        comment_text: replyTextField,
        toAddress: getEmailsFromText(replyTextField),
        parentCommentId,
        isSecondary,
        asset_name: assetInfo.asset_name,
      })
      this.handleChangeEditingReply()
    } else {
      createNewComment({
        comment_text: replyTextField,
        toAddress: getEmailsFromText(replyTextField),
        isSecondary,
        parentCommentId,
        asset_name: assetInfo.asset_name,
      })
      this.handleChangeEditingReply()
    }
  }

  getReplyButtonText = (repliesArray = []) => {
    const { showReplies } = this.state
    const arrLength = repliesArray.length

    if (showReplies) return 'Hide Replies'
    if (arrLength > 1) return `${arrLength} Replies`
    if (arrLength === 1) return '1 Reply'
    return 'Reply'
  }

  onResolveClicked = (event) => {
    const {
      comment = {},
      resolveComment = () => {},
      isSecondary = false,
      auth = {},
    } = this.props
    const { lanId = '' } = auth
    const { comment_id = '' } = comment
    const isChecked = event.target.checked
    resolveComment({
      commentId: comment_id,
      isResolved: isChecked,
      isSecondary,
      userId: lanId,
    })
  }

  render() {
    const {
      classes = {},
      comment = {},
      hideAnnotationIds = [],
      toggleHideAnnotation = () => {},
      isSecondary = false,
      deleteComment = () => {},
      isProjectAdmin = false,
      isActiveReview = false,
      isReviewer = false,
      auth = {},
    } = this.props
    const {
      isEditing,
      textField,
      showReplies,
      currentlyEditingReply,
      replyTextField,
      isConfDelete,
      isResolved,
    } = this.state
    const {
      updated_date = '',
      created_by = '',
      created_by_name = '.',
      comment_text = '',
      comment_id = '',
      comment_resolved_date = '',
      comment_resolved_by_name = '',
    } = comment
    const { displayName = '', initials = '' } = parseNameString(created_by_name)
    const formattedCommentUpdateDate = formatAnnotationDate(updated_date)
    const parsedMarkup = getAnnotationFromComment(comment)
    const commentResolvedDate = comment_resolved_date
      ? formatAnnotationDate(comment_resolved_date)
      : ''
    const isHidden = hideAnnotationIds.includes(
      idx(parsedMarkup, (x) => x.data.id)
    )
    const replies = comment.replied_comments || []
    const { lanId = '' } = auth
    const isSameUser = lanId === created_by
    return (
      <div>
        {!isEmpty(parsedMarkup) && (
          <div className={classes.marker}>
            {idx(parsedMarkup, (x) => x.data.id)}
          </div>
        )}
        <Card
          data-cy="commentCard"
          className={!isEmpty(parsedMarkup) ? classes.rootMarkup : classes.root}
          style={{
            border: isHidden ? '2px solid #EFEFEF' : '2px solid #5471D2',
          }}
        >
          <div
            style={
              isEmpty(parsedMarkup)
                ? { marginBottom: '24px' }
                : { marginBottom: '24px', marginTop: '18px' }
            }
          >
            <div style={{ display: 'inline-block' }}>
              {!isEmpty(parsedMarkup) && (
                <Tooltip title="Show/Hide Comment" placement="top">
                  <RemoveRedEye
                    data-cy="showHideComment"
                    className={classes.button}
                    style={{
                      color: isHidden ? 'black' : praxis.lighterBlue,
                    }}
                    onClick={() =>
                      toggleHideAnnotation(parsedMarkup.data.id, isSecondary)
                    }
                  />
                </Tooltip>
              )}
              <div className={classes.resolvedCheckbox}>
                <GreyCheckBox
                  data-cy={
                    isResolved ? 'checkedResolvedCheckbox' : 'resolvedCheckbox'
                  }
                  checked={isResolved}
                  onChange={this.onResolveClicked}
                  className={
                    isEmpty(parsedMarkup)
                      ? classes.checkboxLeft
                      : classes.checkbox
                  }
                />
                <span
                  className={
                    isEmpty(parsedMarkup)
                      ? classes.checkboxLabelLeft
                      : classes.checkboxLabel
                  }
                >
                  RESOLVED
                </span>
              </div>
              {isResolved && (
                <>
                  <div className={classes.fadedDate}>
                    {' '}
                    {commentResolvedDate}{' '}
                  </div>
                  <div className={classes.fadedDate}>
                    Resolved By: {comment_resolved_by_name}
                  </div>
                </>
              )}
            </div>
            {isSameUser && (isActiveReview || isProjectAdmin) && (
              <div style={{ float: 'right' }}>
                <Tooltip title="Delete Comment" placement="top">
                  <ClickAwayListener
                    onClickAway={() => this.setState({ isConfDelete: false })}
                  >
                    <div
                      style={{ cursor: 'pointer' }}
                      data-cy="deleteComment"
                      onClick={
                        isConfDelete
                          ? this.onDeleteClicked
                          : () => {
                              this.setState({ isConfDelete: true })
                              setTimeout(() => {
                                this.setState({ isConfDelete: false })
                              }, 6000)
                            }
                      }
                    >
                      <Delete
                        className={
                          isConfDelete
                            ? classes.buttonDeleteConf
                            : classes.buttonDelete
                        }
                      />
                      {isConfDelete && (
                        <span
                          style={{
                            color: '#CC0000',
                            fontSize: '12px',
                            float: 'right',
                            marginTop: '6px',
                          }}
                        >
                          DELETE ?
                        </span>
                      )}
                    </div>
                  </ClickAwayListener>
                </Tooltip>
              </div>
            )}
          </div>
          <CardContent className={classes.content}>
            <div className={classes.header}>
              <Avatar aria-label="Avatar Image" className={classes.avatar}>
                <span className={classes.initials}>{initials}</span>
              </Avatar>
              <div className={classes.name}>
                <span>{displayName}</span>
                <span className={classes.fadedGrey}>
                  {formattedCommentUpdateDate}
                </span>
              </div>
            </div>
            {!isEditing ? (
              <React.Fragment>
                <div style={{ display: 'inline-flex' }}>
                  {isSameUser && (isActiveReview || isProjectAdmin) ? (
                    <Tooltip title="Edit Comment" placement="top">
                      <Edit
                        data-cy="editComment"
                        className={classes.buttonEdit}
                        onClick={this.toggleEditing}
                      />
                    </Tooltip>
                  ) : (
                    <div className={classes.emptySpace}></div>
                  )}
                  <MentionBox
                    disabled
                    text={comment_text}
                    onTextChanged={() => {}}
                  />
                </div>
                {showReplies && (
                  <React.Fragment>
                    {sortMyArray([...replies], 'created_date', 'ascend').map(
                      (reply, idx) => (
                        <ReplyBox
                          key={idx}
                          reply={reply}
                          isEditing={
                            currentlyEditingReply.comment_id ===
                            reply.comment_id
                          }
                          handleChangeEditingReply={
                            this.handleChangeEditingReply
                          }
                          editTextField={this.editReplyTextField}
                          replyTextField={replyTextField}
                          deleteComment={deleteComment}
                        />
                      )
                    )}
                    {isEmpty(currentlyEditingReply) &&
                      (isProjectAdmin || (isReviewer && isActiveReview)) && (
                        <ReplyBox
                          isSecondary={isSecondary}
                          parentCommentId={comment_id}
                          isEditing={true}
                          replyTextField={replyTextField}
                          editTextField={this.editReplyTextField}
                        />
                      )}
                  </React.Fragment>
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <MentionBox
                  text={textField}
                  onTextChanged={this.editTextField}
                />
                <div className={classes.charCounter}>
                  Chars: {textField.length}/999
                </div>
              </React.Fragment>
            )}
          </CardContent>
          <CardActions className={classes.spacing}>
            {!isEditing && (
              <React.Fragment>
                <Button
                  data-cy="replyButton"
                  onClick={() =>
                    this.setState((state) => ({
                      showReplies: !state.showReplies,
                    }))
                  }
                  className={classes.blueText}
                  size="small"
                  style={{ marginTop: showReplies ? '35px' : 0 }}
                >
                  {this.getReplyButtonText(replies)}
                </Button>
                <div>
                  {showReplies &&
                    (isProjectAdmin || (isReviewer && isActiveReview)) && (
                      <Button
                        data-cy="addReplyButton"
                        disabled={!validateStringMinMax(1, 999)(replyTextField)}
                        onClick={this.onSaveReply}
                        variant="contained"
                        color="secondary"
                        type="submit"
                        style={{ marginRight: '18px', width: '130px' }}
                      >
                        <Forum />
                        <span style={{ marginLeft: '8px', fontSize: '11px' }}>
                          ADD REPLY
                        </span>
                      </Button>
                    )}
                  <CardButtonRow
                    toggleEditing={this.toggleEditing}
                    onDeleteClicked={this.onDeleteClicked}
                    createdBy={created_by}
                    toggleHideAnnotation={toggleHideAnnotation}
                    parsedMarkup={parsedMarkup}
                    isEditing={isEditing}
                    isHidden={isHidden}
                    comment={comment}
                    isSecondary={isSecondary}
                  />
                </div>
              </React.Fragment>
            )}
            {isEditing && (
              <Fragment>
                <Button onClick={() => this.setState({ isEditing: false })}>
                  CANCEL
                </Button>
                <Button
                  data-cy="updateComment"
                  variant="contained"
                  color="secondary"
                  type="submit"
                  onClick={this.submitComment}
                  disabled={!validateStringMinMax(1, 999)(textField)}
                >
                  <Comment />
                  <span style={{ marginLeft: '8px', fontSize: '11px' }}>
                    UPDATE COMMENT
                  </span>
                </Button>
              </Fragment>
            )}
          </CardActions>
        </Card>
      </div>
    )
  }
}

CommentBox.propTypes = {
  classes: PropTypes.object,
  comment: commentPropType,
  assetInfo: PropTypes.object,
  deleteComment: PropTypes.func,
  updateComment: PropTypes.func,
  createNewComment: PropTypes.func,
  hideAnnotationIds: PropTypes.arrayOf(PropTypes.number),
  toggleHideAnnotation: PropTypes.func,
  isSecondary: PropTypes.bool,
  isReviewer: PropTypes.bool,
  isActiveReview: PropTypes.bool,
  isProjectAdmin: PropTypes.bool,
  auth: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    lanId: PropTypes.string,
  }),
  resolveComment: PropTypes.func,
  isResolved: PropTypes.bool,
}

const mapStateToProps = (state = {}) => ({
  auth: makeSelectAuthData()(state),
  isActiveReview: selectIsActiveReview()(state),
  isProjectAdmin: selectIsCurrentTaskRole(TaskRoles.admin)(state),
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createNewComment,
      updateComment,
      deleteComment,
      resolveComment,
    },
    dispatch
  )

export default connect(
  () => mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(CommentBox))
