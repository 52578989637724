import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { debounce } from 'lodash'
import { MentionsInput, Mention } from 'react-mentions'
import {
  handleStringBreak,
  convertTextToMentions,
  handleHighlight,
} from '../../helpers/stringHelper'
import { modifyDisplayName } from '../../mappers/userMapper'

const defaultStyles = {
  marginTop: '10px',
  textAlign: 'start',
  control: {
    backgroundColor: '#fff',
    fontSize: 12,
    fontWeight: 'normal',
  },
  highlighter: {
    overflow: 'hidden',
  },
  input: {
    margin: 0,
  },
  '&multiLine': {
    control: {
      fontFamily: 'Roboto',
      border: '1px solid #333333',
    },
    highlighter: {
      padding: 9,
    },
    input: {
      padding: 9,
      minHeight: 63,
      outline: 0,
      border: 0,
    },
  },
  suggestions: {
    list: {
      backgroundColor: 'white',
      border: '1px solid rgba(0,0,0,0.15)',
      fontSize: 10,
      maxHeight: '75px',
      overflowY: 'scroll',
    },
    item: {
      padding: '5px 15px',
      borderBottom: '1px solid rgba(0,0,0,0.15)',
      '&focused': {
        backgroundColor: '#cee4e5',
      },
    },
  },
}

const disabledDiv = {
  marginTop: '10px',
  textAlign: 'start',
  fontSize: 12,
  fontWeight: 'normal',
  fontFamily: 'Roboto',
  wordBreak: 'break-word',
  whiteSpace: 'pre-wrap',
}

const disabledStyles = {
  marginTop: '10px',
  textAlign: 'start',
  control: {
    backgroundColor: '#fff',
    fontSize: 12,
    fontWeight: 'normal',
  },
  highlighter: {
    overflow: 'hidden',
  },
  input: {
    margin: '0',
    color: 'black',
  },
  '&multiLine': {
    control: {
      fontFamily: 'Roboto',
      border: 'none',
    },
    highlighter: {
      // Leaving this empty to avoid a null reference error
      //we do need this property in the enabled styles above
    },
    input: {
      padding: 0,
      outline: 0,
      border: 0,
    },
  },
  suggestions: {
    list: {
      backgroundColor: 'white',
      border: '1px solid rgba(0,0,0,0.15)',
      fontSize: 10,
    },
    item: {
      padding: '5px 15px',
      borderBottom: '1px solid rgba(0,0,0,0.15)',
      '&focused': {
        backgroundColor: '#cee4e5',
      },
    },
  },
}

const defaultMentionStyle = {
  backgroundColor: '#cee4e5',
}

export class MentionBox extends React.PureComponent {
  constructor(props) {
    super(props)

    this.textInput = React.createRef()
  }

  componentDidMount() {
    if (!this.props.disabled && this.textInput.current) {
      this.textInput.current.focus()
    }
  }

  componentDidUpdate() {
    if (!this.props.disabled && this.textInput.current) {
      this.textInput.current.focus()
    }
  }

  loadOptions = debounce(async (searchKeyWord, cb) => {
    if ((searchKeyWord || '').length > 1) {
      let response = this.props.taskUsers.map((user = {}) => {
        const { email_address = '', organization = '' } = user
        return {
          display: modifyDisplayName(email_address, organization),
          id: email_address,
        }
      })
      let filterData = response.filter((user) =>
        user.display.toLowerCase().includes(searchKeyWord.toLowerCase())
      )
      cb(filterData)
    }
  }, 200)

  search = (key, callback) => {
    this.loadOptions(key, (data) => {
      callback(data)
    })
  }

  render() {
    const {
      text = '',
      onTextChanged = () => {},
      disabled = false,
      styles = {},
      taskUsers = [],
      placeholder = 'Comment Here...',
    } = this.props

    return !disabled ? (
      <MentionsInput
        data-cy="commentBox"
        value={text}
        onChange={onTextChanged}
        placeholder={placeholder}
        style={
          disabled
            ? { ...disabledStyles, ...styles }
            : { ...defaultStyles, ...styles }
        }
        inputRef={this.textInput}
        markup="@[__display__](__id__)"
      >
        <Mention
          trigger="@"
          data={(key, callback) => this.search(key, (data) => callback(data))}
          style={defaultMentionStyle}
        />
      </MentionsInput>
    ) : (
      <div style={disabledDiv}>
        {handleStringBreak(text).map((substr, index) => (
          <span id={substr + '' + index}>
            <span style={handleHighlight(substr) ? defaultMentionStyle : null}>
              {convertTextToMentions(substr)}
            </span>
            <span> </span>
          </span>
        ))}
      </div>
    )
  }
}

MentionBox.propTypes = {
  text: PropTypes.string,
  onTextChanged: PropTypes.func,
  disabled: PropTypes.bool,
  classes: PropTypes.object,
  styles: PropTypes.object,
  placeholder: PropTypes.string,
  taskUsers: PropTypes.arrayOf(
    PropTypes.shape({
      display_name: PropTypes.string,
      id: PropTypes.string,
    })
  ),
}

const mapStateToProps = (state = {}) => {
  const { annotation = {} } = state
  const { taskUsers } = annotation
  return {
    taskUsers,
  }
}

export default connect(mapStateToProps)(MentionBox)
