import axios from 'axios'
import apiConfig from '../config/apiConfig'

const {
  audit: { assetStatusUrl = '', retryAssetUrl = '' },
  key = '',
} = apiConfig

export function getAssetStatus(assetStatusPayload = {}) {
  return axios.post(assetStatusUrl, assetStatusPayload, {
    params: {
      key: key,
    },
  })
}

export function retryProjectAssets(retryPayload = []) {
  return axios.post(retryAssetUrl, retryPayload, {
    params: {
      key: key,
    },
  })
}
