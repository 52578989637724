import { createContext, useContext } from 'react'

const AssetCardContext = createContext(null)

export function useAssetCardContext() {
  const context = useContext(AssetCardContext)
  if (!context) {
    throw new Error('Empty context')
  }
  return context
}

export default AssetCardContext
