import React from 'react'
import cx from 'classnames'
import IconButton from '@mui/material/IconButton'
import CardActions from '@mui/material/CardActions'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Collapse from '@mui/material/Collapse'
import CardContent from '@mui/material/CardContent'
import { ExpandMore, renderCardActions } from './CustomCardHOC'

function CustomCardFooter({
  cardConfig = {},
  classes = {},
  dispatchClickAction = () => {},
  expansionPanelContent = '',
  footerContent = '',
}) {
  const [expanded, setExpanded] = React.useState(false)
  const { footer = {}, renderCustomFooterContent = false } = cardConfig
  const { cardActions = [], showExpandAction = false } = footer

  const actionClickHandler = (actionName = '') => {
    dispatchClickAction('footerActions', actionName)
  }
  return (
    <>
      <>
        {renderCustomFooterContent ? (
          <div
            className={cx(
              classes.customFooterContent,
              classes.cardContentBottom
            )}
          >
            {footerContent}
          </div>
        ) : cardActions?.length || showExpandAction ? (
          <>
            <CardActions disableSpacing className={classes.footerActions}>
              <div className={classes.leftActions}>
                {renderCardActions(cardActions, 'left', actionClickHandler)}
              </div>
              <div>
                {renderCardActions(cardActions, 'right', actionClickHandler)}
              </div>
              {
                <div className={classes.rightActionsWithExpand}>
                  {showExpandAction ? (
                    <ExpandMore
                      expand={expanded}
                      onClick={() => {
                        setExpanded(!expanded)
                      }}
                      aria-expanded={expanded}
                      aria-label="show more"
                    >
                      <ExpandMoreIcon />
                    </ExpandMore>
                  ) : (
                    ''
                  )}
                </div>
              }
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              {<CardContent>{expansionPanelContent}</CardContent>}
            </Collapse>
          </>
        ) : (
          ''
        )}
      </>
    </>
  )
}

export default CustomCardFooter
