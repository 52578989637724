import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@mui/material/styles'
import deburr from 'lodash/deburr'
import Downshift from 'downshift'
import { TextField, Paper, MenuItem } from '@mui/material'
import { makeStyles } from '@mui/styles'

function renderInput(inputProps) {
  const { InputProps, classes, ref, ...other } = inputProps

  return (
    <TextField
      variant="standard"
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput,
        },
        ...InputProps,
      }}
      {...other}
    />
  )
}

function renderSuggestion({
  suggestion,
  index,
  itemProps,
  highlightedIndex,
  selectedItem,
}) {
  const isHighlighted = highlightedIndex === index
  const isSelected = (selectedItem || '').indexOf(suggestion) > -1

  return (
    <MenuItem
      {...itemProps}
      key={suggestion}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
    >
      {suggestion}
    </MenuItem>
  )
}
renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.number,
  index: PropTypes.number,
  itemProps: PropTypes.object,
  selectedItem: PropTypes.string,
  suggestion: PropTypes.string,
}

const styles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: 50,
  },
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: useTheme().spacing(),
    left: 0,
  },
  chip: {
    margin: `${useTheme().spacing(1 / 2)} ${useTheme().spacing(1 / 4)}`,
  },
  inputRoot: {
    flexWrap: 'wrap',
  },
  inputInput: {
    width: 'auto',
    flexGrow: 1,
  },
  divider: {
    height: useTheme().spacing(2),
  },
}))

export class SimpleTypeahead extends React.PureComponent {
  state = {
    inputValue: '',
  }
  getSuggestions = (value = '', suggestions = [], maxSuggestions = 5) => {
    const inputValue = deburr(value.trim()).toLowerCase()
    return inputValue.length > 0
      ? suggestions
          .filter((suggestion) => suggestion.toLowerCase().includes(inputValue))
          .slice(0, maxSuggestions)
      : []
  }
  handleInputChange = (event) => {
    this.setState({ inputValue: event.target.value })
  }

  handleInputKeydown = (event) => {
    const { suggestions = [], handleKeyDown = () => {} } = this.props
    const keyDownInputValue = event.target.value || ''
    if (
      !suggestions.filter((suggestion) =>
        suggestion.toLowerCase().includes(keyDownInputValue)
      ).length
    ) {
      handleKeyDown(event)
    }
  }

  handleDownShiftStateChange = (event) => {
    const { handleChange = () => {} } = this.props
    const { type } = event
    const stateChangeTypes = [
      '__autocomplete_change_input__',
      '__autocomplete_keydown_enter__',
      '__autocomplete_click_item__',
    ]
    if (stateChangeTypes.indexOf(type) !== -1) {
      handleChange(event)
    }
  }

  render() {
    const {
      classes,
      placeholder = '',
      maxSuggestions = 5,
      suggestions = [],
      handleChange = () => {},
    } = this.props

    const { inputValue = '' } = this.state

    return (
      <div className={classes.root}>
        <Downshift
          id="downshift-simple"
          onChange={handleChange}
          selectedItem={inputValue}
          onStateChange={this.handleDownShiftStateChange}
          onOuterClick={handleChange}
          inputValue={inputValue}
          onSelect={(selection = '') => {
            this.setState({ inputValue: selection })
          }}
        >
          {({
            getInputProps,
            getItemProps,
            getMenuProps,
            highlightedIndex,
            inputValue: inputValue2,
            isOpen,
            selectedItem: selectedItem2,
          }) => (
            <div className={classes.container}>
              {renderInput({
                fullWidth: true,
                classes,
                InputProps: getInputProps({
                  placeholder: placeholder,
                  onKeyDown: (event, type) =>
                    this.handleInputKeydown(event, type),
                  onChange: this.handleInputChange,
                }),
              })}
              <div {...getMenuProps()}>
                {isOpen ? (
                  <Paper className={classes.paper} square>
                    {this.getSuggestions(
                      inputValue2,
                      suggestions,
                      maxSuggestions
                    ).map((suggestion, index) =>
                      renderSuggestion({
                        suggestion,
                        index,
                        itemProps: getItemProps({ item: suggestion }),
                        highlightedIndex,
                        selectedItem2,
                      })
                    )}
                  </Paper>
                ) : null}
              </div>
            </div>
          )}
        </Downshift>
      </div>
    )
  }
}

SimpleTypeahead.propTypes = {
  classes: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  suggestions: PropTypes.array,
  maxSuggestions: PropTypes.number,
  handleChange: PropTypes.func,
  handleKeyDown: PropTypes.func,
}

const MyComponent = (props) => {
  const classes = styles()
  return <SimpleTypeahead {...props} classes={classes} />
}

export default MyComponent
