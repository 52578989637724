import axios from 'axios'
import apiConfig from '../../config/apiConfig'

const {
  genai: {
    bulkTestsUrl = '',
    bulkTestReviewUrl = '',
    assetList = '',
    assetDetails = '',
  },
  boards: { boardListUrl = '', boardAssetsUrl = '' },
  key = '',
} = apiConfig

export const fetchAiEnabledAssets = () => axios.get(`${assetList}`)

export const fetchAssetDetails = (assetId = '') =>
  axios.get(`${assetDetails}/${assetId}`)

export const generatePrompt = ({
  assetId = '',
  displayName = '',
  promptPayload = {},
}) =>
  axios.post(`${assetDetails}/${assetId}?user_id=${displayName}`, promptPayload)

export const fetchBulkTests = () => axios.get(bulkTestsUrl)

export const deleteBulkTests = (testId = '') =>
  axios.delete(`${bulkTestsUrl}/${testId}`)

export const createBulkTest = (createPayload = {}) =>
  axios.post(`${bulkTestsUrl}`, createPayload)

export const fetchBulkTestDetails = (testId = '') =>
  axios.get(`${bulkTestsUrl}/${testId}`)

export const updateTestNotes = (testId = '', testUpdatePayload = {}) =>
  axios.patch(`${bulkTestsUrl}/${testId}`, testUpdatePayload)

export const reviewTestAssets = (reviewPayload = {}) =>
  axios.patch(`${bulkTestReviewUrl}`, reviewPayload)

export const fetchAIAllowedBoards = () =>
  axios.get(`${boardListUrl}?is_ai_eligible=true&key=${key}`)

export const fetchBoardAssets = (selectedBoardIds = []) =>
  axios.post(`${boardAssetsUrl}?key=${key}`, selectedBoardIds)

export const retryBulkTest = (testId = '') =>
  axios.post(`${bulkTestsUrl}/retry/${testId}`)
