export const DISPLAY_ERROR_EVENT = 'DISPLAY_ERROR_EVENT'
export const RECEIVE_ASSETS_SUCCESS = 'RECEIVE_ASSETS_SUCCESS'
export const CHANGE_CURRENT_ASSETS_PAGE = 'CHANGE_CURRENT_ASSETS_PAGE'
export const CHANGE_DEFAULT_ASSET_PAGE_SIZE = 'CHANGE_DEFAULT_ASSET_PAGE_SIZE'
export const REMOVE_FROM_PENDING_LIST = 'REMOVE_FROM_PENDING_LIST'
export const SELECT_ASSET_EVENT = 'SELECT_ASSET_EVENT'
export const SET_SORT_DIRECTION = 'SET_SORT_DIRECTION'
export const SET_SORT_FIELD = 'SET_SORT_FIELD'
export const DOWNLOAD_START_ASSETS = 'DOWNLOAD_START_ASSETS'
export const DOWNLOAD_FINISH_ASSETS = 'DOWNLOAD_FINISH_ASSETS'
export const CHANGE_ASSET_FILTER = 'CHANGE_ASSET_FILTER'
export const ADD_IMAGE_TO_PENDING_LIST = 'ADD_IMAGE_TO_PENDING_LIST'
export const UPDATE_IMAGE_TO_PENDING_LIST = 'UPDATE_IMAGE_TO_PENDING_LIST'
