import React, { useEffect } from 'react'
import { useSearchContext } from '../SearchContext'
import Metadata from '../../Metadata'

const AssetMetadata = (props = {}) => {
  const {
    renderPage = '',
    setRenderPage = () => {},
    trackAnalyticsData = () => {},
  } = useSearchContext()
  const [showMetadata, setShowMetadata] = React.useState(false)

  useEffect(() => {
    if (renderPage === 'ASSETMETADATA') {
      setShowMetadata(true)
    }
    return function cleanup() {
      setShowMetadata(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renderPage])

  return (
    <>
      {showMetadata && (
        <Metadata
          setRenderPage={setRenderPage}
          trackAnalyticsData={trackAnalyticsData}
        />
      )}
    </>
  )
}

export default AssetMetadata
