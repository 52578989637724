import AHFeatureUpdatesPage from '../containers/Home/AHFeatureUpdatesPage'
import AssetHomeImg from '../images/Assethome.png'
import SearchImg from '../images/Search.svg?url'
import CollectionImg from '../images/collection.svg?url'
import InfoImg from '../images/Info.svg?url'
import NetworkImg from '../images/network.svg?url'
import NotebookImg from '../images/notebook.svg?url'
import ReportingImg from '../images/chart-wave-square.svg?url'

export const carouselPageList = [
  {
    renderImage: true,
    imageName: `${AssetHomeImg}`,
  },
  {
    renderCustomComponent: true,
    component: AHFeatureUpdatesPage,
    featureType: 'recent',
  },
  {
    renderCustomComponent: true,
    component: AHFeatureUpdatesPage,
    featureType: 'planned',
  },
]

export const visionFocusAreas = [
  {
    image: <img src={SearchImg} alt={'Search'} />,
    title: 'Search',
    content:
      'One location to find and share creative and marketing assets to tell your story.',
  },
  {
    image: <img src={CollectionImg} alt={'Asset Management'} />,
    title: 'Asset Management',
    content:
      'From metadata enrichment to lifecycle management, storage, and usage rights.',
  },
  {
    image: <img src={InfoImg} alt={'Standards & Guidelines'} />,
    title: 'Standards & Guidelines',
    content:
      'Ensure assets are being utilized legally and as intended as creators.',
  },
  {
    image: <img src={NetworkImg} alt={'Collaboration & Workflow'} />,
    title: 'Collaboration & Workflow',
    content:
      'Collaborate, review, approve, and share on-brand and up-to-date assets.',
  },
  {
    image: <img src={NotebookImg} alt={'Enterprise Services'} />,
    title: 'Enterprise Content Services',
    content:
      'Upload and manage assets and projects to make available across creation tools.',
  },
  {
    image: <img src={ReportingImg} alt={'Reporting & Analytics'} />,
    title: 'Reporting & Analytics',
    content:
      'Understand how, why and by whom assets are being found and utilized.',
  },
]
