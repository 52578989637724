import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import * as preferenceApi from '../apiService'

const initialState = {
  view: 'list',
  annotationZoom: 'fit-to-screen',
  colors: [],
  searchPreference: {},
  userPreferences: {},
}

export const fetchUserPreference = createAsyncThunk(
  'userPreference/fetchUserPreference',
  async () => {
    try {
      const response = await preferenceApi.fetchUserPreferencesApi()
      const { data: userPreferenceData = {} } = response
      return userPreferenceData
    } catch (error) {
      return Promise.reject(error)
    }
  },
)

export const updateUserPreference = createAsyncThunk(
  'userPreference/updateUserPreference',
  async (payload) => {
    try {
      const response = await preferenceApi.updateUserPreferencesApi(payload)
      const { data: userPreferenceData = {} } = response
      return userPreferenceData
    } catch (error) {
      return Promise.reject(error)
    }
  },
)

export const userPreferenceSlice = createSlice({
  name: 'userPreference',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserPreference.fulfilled, (state, action) => {
        const {
          payload: {
            colors = [],
            login_id = '',
            preferences: {
              view = '',
              annotation_zoom = '',
              search_preference = {},
            } = {},
          } = {},
        } = action
        state.colors = colors
        state.loginId = login_id
        state.view = view
        state.annotationZoom = annotation_zoom
        state.searchPreference = search_preference
        state.userPreferences = action.payload
      })
      .addCase(updateUserPreference.fulfilled, (state, action) => {
        const {
          payload: {
            colors = [],
            login_id = '',
            preferences: {
              view = '',
              annotation_zoom = '',
              search_preference = {},
            } = {},
          } = {},
        } = action
        state.colors = colors
        state.loginId = login_id
        state.view = view
        state.annotationZoom = annotation_zoom
        state.searchPreference = search_preference
        state.userPreferences = action.payload
      })
  },
})

export default userPreferenceSlice.reducer
export const { saveUserPreferences } = userPreferenceSlice.actions
