import { FormControl, Input, InputLabel } from '@mui/material'

import CollapsibleCard from '../../components/Custom/CollapsibleCard'
import {
  METADATA_CARD_HIDE_BUTTON_TEXT,
  METADATA_CARD_SHOW_BUTTON_TEXT,
} from '../../constants/metadata'
import { useMetadataContext } from './MetadataContext'

function MetadataTags({ containerClasses = {} }) {
  const { assetMetadata = {} } = useMetadataContext()

  const { metadata: { user_tags = {} } = {} } = assetMetadata

  return (
    <CollapsibleCard
      cardTitle={'Generated Tags'}
      hideButtonText={METADATA_CARD_HIDE_BUTTON_TEXT}
      showButtonText={METADATA_CARD_SHOW_BUTTON_TEXT}
      defaultCollapse={true}
      containerClasses={containerClasses}
    >
      {Object.keys(user_tags)?.length ? (
        Object.keys(user_tags).map((tag) => (
          <FormControl
            variant="standard"
            className={containerClasses.formControl}
          >
            <InputLabel htmlFor={tag}>{tag}</InputLabel>
            <Input id={tag} value={user_tags[tag]} disableUnderline />
          </FormControl>
        ))
      ) : (
        <div className={containerClasses.noResults}>No Details Available</div>
      )}
    </CollapsibleCard>
  )
}

export default MetadataTags
