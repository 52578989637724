import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Helmet } from 'react-helmet'
import HeaderTitle from '../Header/HeaderTitle'
import HeaderTabs from './Search/HeaderTabs'
import PublishUpload from './Upload/PublishUpload'
import PublishSearch from './Search/index'
import ChannelPublish from './ChannelUpload/ChannelPublish'
import { toggleRightSidenav } from '../../store/layout/actionCreator'

function Publish({
  history = {},
  router = {},
  headerTitle = '',
  toggleRightSidenav = () => {},
}) {
  const [tab, setTab] = useState(0)

  useEffect(() => {
    toggleRightSidenav('', false)
  }, [])

  return (
    <div>
      <HeaderTitle title="Publish" icon="Publish" />
      <Helmet>
        <title>{headerTitle}</title>
      </Helmet>
      <HeaderTabs
        activeTabIndex={'Search'}
        id={'PublishHeader'}
        history={history}
        router={router}
      />
      {tab === Number(0) ? (
        <PublishSearch history={history} router={router} />
      ) : tab === Number(1) ? (
        <PublishUpload history={history} router={router} />
      ) : (
        <ChannelPublish history={history} router={router} />
      )}
    </div>
  )
}

Publish.propTypes = {
  history: PropTypes.object,
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      toggleRightSidenav,
    },
    dispatch
  )

export default connect(null, mapDispatchToProps)(Publish)
