import { BULK_TESTS_VIEW_AI } from '../../constants/routes'
import { ASSET_STATUS_SUCCESS } from '../constants/genai'

export const BULK_TEST = 'BULK_TEST'
export const NEW_TEST = 'NEW_TEST'
export const REVIEW_TEST = 'REVIEW_TEST'
export const bulkTestsBreadCrumbs = [
  { path: BULK_TESTS_VIEW_AI, title: 'Bulk Tests' },
]
const newTestBreadCrumbs = [
  ...bulkTestsBreadCrumbs,
  { path: `${BULK_TESTS_VIEW_AI}/new`, title: 'New Test' },
]
const reviewTestBreadCrumbs = [
  ...bulkTestsBreadCrumbs,
  { path: `${BULK_TESTS_VIEW_AI}/{id}`, title: '{id}' },
]
export const getGenAiBreadCrumbList = (pageName = '') => {
  switch (pageName) {
    case BULK_TEST:
      return bulkTestsBreadCrumbs
    case NEW_TEST:
      return newTestBreadCrumbs
    case REVIEW_TEST:
      return reviewTestBreadCrumbs
    default:
      return bulkTestsBreadCrumbs
  }
}

export const AI_ASSET_STATUS_TO_REVIEW = 'TO_REVIEW'
export const AI_ASSET_STATUS_TO_REVIEW_LABEL = 'TO REVIEW'
export const AI_ASSET_STATUS_PASS = 'PASS'
export const AI_ASSET_STATUS_FAIL = 'FAIL'
export const AI_ASSET_STATUS_NOT_PROCESSED = 'NOT PROCESSED'

export const filterChips = [
  { label: 'ALL', identifier: '', count: Number(0), color: 'secondary' },
  {
    label: AI_ASSET_STATUS_PASS,
    identifier: AI_ASSET_STATUS_PASS,
    count: Number(0),
    color: 'success',
  },
  {
    label: AI_ASSET_STATUS_FAIL,
    identifier: AI_ASSET_STATUS_FAIL,
    count: Number(0),
    color: 'warning',
  },
  {
    label: AI_ASSET_STATUS_TO_REVIEW_LABEL,
    identifier: AI_ASSET_STATUS_TO_REVIEW,
    count: Number(0),
    color: 'info',
  },
  {
    label: AI_ASSET_STATUS_NOT_PROCESSED,
    identifier: AI_ASSET_STATUS_NOT_PROCESSED,
    count: Number(0),
    color: 'error',
  },
]

export const updateCountForFilters = (
  reviewAssetList = [],
  filters = filterChips,
) => {
  let updatedFilterChips = [...filters]
  if (reviewAssetList.length) {
    updatedFilterChips = filters.map((chipDetails) => {
      const { label = '', color = '', identifier = '' } = chipDetails
      const filteredCount = identifier
        ? identifier === AI_ASSET_STATUS_NOT_PROCESSED
          ? reviewAssetList.filter(
              (asset) => asset.asset_status !== ASSET_STATUS_SUCCESS,
            ).length
          : reviewAssetList.filter(
              (asset) => asset.quality_status === identifier,
            ).length
        : reviewAssetList?.length
      return {
        label,
        color,
        identifier,
        count: filteredCount,
      }
    })
  }
  return updatedFilterChips
}

export const getSequenceNumFromAssetTestId = (assetTestId = '') => {
  const splitArr = assetTestId.split('-')
  const lastEntry = splitArr[splitArr.length - 1]
  return lastEntry.includes('A') ? lastEntry.slice(1) : lastEntry
}
