import axios from 'axios'
import { getAllProjectAssets } from '../projects/actionCreator'

import {
  FETCH_ASSET_METADATA,
  RESET_ASSET_METADATA,
  FETCH_ASSET_REVISIONS,
  DELETE_ASSET_REVISIONS,
  FETCH_CATALOG,
  SET_UNSAVEDCHANGES_FLAG,
  CLEAR_ASSET_REVISIONS,
  SET_METADATA_LOADING,
} from './actionTypes'
import { showNotification } from '../notification/actionCreator'

import apiConfig from '../../config/apiConfig'
import { NOTIFICATION_SUCCESS_REVISION_DELETE } from '../../constants/metadata'
import { setDeleteAssetInProgress } from '../search/actions'
import firefly from '../../analytics/firefly'

const { key: KEY = '', metadata = {} } = apiConfig
const {
  locationKey: LOCATION_KEY = '',
  assetMetadataUrl,
  updateAssetMetadataUrl,
  assetRevisionsUrl,
  assetDeleteUrl,
  drmUrl,
  productUrl,
  locationUrl,
  catalogUrl,
  bulkUpdateUrl,
} = metadata

export const fetchAssetMetadata =
  (assetId, callbackSuccess = () => {}, callbackFailure = () => {}) =>
  (dispatch) => {
    const promise = axios.get(`${assetMetadataUrl}${assetId}?key=${KEY}`)
    dispatch(clearAssetRevisions())
    dispatch(dispatchAssetMetadata(promise))
    promise
      .then((response) => {
        callbackSuccess(response.data)
      })
      .catch((error) => {
        callbackFailure(error)
      })
  }

export const setMetadataLoading = (payload) => ({
  type: SET_METADATA_LOADING,
  payload,
})

export const dispatchAssetMetadata = (payload) => ({
  type: FETCH_ASSET_METADATA,
  payload: payload,
})

export const resetAssetMetadata = () => (dispatch) =>
  dispatch({
    type: RESET_ASSET_METADATA,
    payload: [],
  })

export const fetchRevisions = (assetMasterId) => (dispatch) => {
  const promise = axios.get(
    `${assetMetadataUrl}${assetMasterId}/revisions?key=${KEY}`,
  )
  dispatch(dispatchRevisions(promise))
}

export const dispatchRevisions = (payload) => ({
  type: FETCH_ASSET_REVISIONS,
  payload: payload,
})

export function deleteRevisionsService(payload = {}) {
  return axios.delete(`${assetDeleteUrl}?key=${KEY}`, {
    data: payload,
  })
}

export function deleteRevisions(
  payload = {},
  notificationMessage = NOTIFICATION_SUCCESS_REVISION_DELETE,
  projectId,
  pagination = {},
  callBack = () => {},
) {
  return (dispatch) => {
    deleteRevisionsService(payload)
      .then(() => {
        dispatch(setDeleteAssetInProgress(false))
        dispatch(showNotification(true, notificationMessage, 'success'))
        callBack()
        if (projectId) {
          dispatch(getAllProjectAssets(projectId, pagination))
        }
      })
      .catch((err) => {})
  }
}

export const dispatchDeleteRevisions = (payload) => ({
  type: DELETE_ASSET_REVISIONS,
  payload: payload,
})

export const fetchUsageRights = (drmIds, identifier, callback) => {
  axios
    .get(`${drmUrl}/${drmIds}?key=${KEY}`)
    .then((response) => {
      callback({ drmIds, identifier }, response.data, 'success')
    })
    .catch((response) => {
      callback({ drmIds, identifier }, response, 'failure')
    })
}

export const fetchProductData = (fieldName, keyword, identifier, callback) => {
  axios
    .get(`${productUrl}?${fieldName}=${keyword}&key=${KEY}`)
    .then((response) => {
      callback({ keyword, identifier }, response.data, 'success')
    })
    .catch((response) => {
      callback({ keyword, identifier }, response, 'failure')
    })
}

export const fetchStoreLocationData = (storeNo, identifier, callback) => {
  axios
    .get(`${locationUrl}${storeNo}?key=${LOCATION_KEY}`, {
      headers: {
        'x-api-key': LOCATION_KEY,
        Authorization: false,
      },
    })
    .then((response) => {
      callback({ storeNo, identifier }, response.data, 'success')
    })
    .catch((response) => {
      callback({ storeNo, identifier }, response, 'failure')
    })
}

export const fetchCatalogData =
  (catalog = []) =>
  (dispatch) => {
    if (catalog.length) {
      const catalogType = catalog.join(',')
      const promise = axios.get(
        `${catalogUrl}?catalogType=${catalogType}&key=${KEY}`,
      )
      dispatch(dispatchCatalog(promise))
    }
  }

export const dispatchCatalog = (payload) => ({
  type: FETCH_CATALOG,
  payload: payload,
})

export const updateMetadata = (
  payload,
  callback,
  updateMetadata,
  lanId = '',
  consumer = '',
) => {
  const saveMetadataUrl = `${updateAssetMetadataUrl}?key=${KEY}`
  axios.put(saveMetadataUrl, payload).then((response) => {
    firefly.trackMetadataUpdate(updateMetadata, lanId, consumer)
    callback(updateMetadata, response.data, 'success')
  })
}

export const updateBulkMetadata =
  (payload, lanId = '', callback, consumer = '', searchView = 'betaSearch') =>
  () => {
    const saveBulkMetadataUrl = `${updateAssetMetadataUrl}?key=${KEY}`
    axios.put(saveBulkMetadataUrl, payload).then((response) => {
      firefly.trackBulkMetadataUpdate(payload, lanId, consumer)
      callback(response.data, 'success', searchView, payload)
    })
  }

export const isMetadataFormEdited = (unsavedChanges = false) => ({
  type: SET_UNSAVEDCHANGES_FLAG,
  unsavedChanges,
})

export const clearAssetRevisions = () => ({
  type: CLEAR_ASSET_REVISIONS,
  payload: { assetRevisions: [] },
})
