import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { Grid } from '@mui/material'
import AssetCard from './AssetCard'
import VideoAssetCard from './VideoAssetCard'

export const AssetList = ({
  checkAll = false,
  searchAssets = [],
  selectedItems = [],
  openModal = () => {},
  onSelection = () => {},
  onClickDownloadAsset = () => {},
  isToggleRightSidenav = false,
  rightSideNavComponent = '',
}) => (
  <Grid container spacing={2} justifyContent={'left'}>
    {searchAssets.map((result, i) => {
      const fileType = get(
        result,
        'asset_details.metadata.file_metadata.file_type',
        ''
      )
      const asset = get(result, 'asset_details', {})
      const hightLights = get(result, 'highlights', {})
      if (['mp4', 'mov', 'gif'].includes(fileType?.toLowerCase())) {
        return (
          <Grid item style={{ paddingBottom: '20px' }}>
            <VideoAssetCard
              asset={asset}
              highlights={hightLights}
              onSelection={onSelection}
              selectedItems={selectedItems}
              onClickDownloadAsset={onClickDownloadAsset}
              openModal={() => openModal(i, result)}
              checkAll={checkAll}
              isToggleRightSidenav={isToggleRightSidenav}
              rightSideNavComponent={rightSideNavComponent}
              fileType={fileType}
            />
          </Grid>
        )
      } else {
        return (
          <Grid item style={{ paddingBottom: '20px' }}>
            <AssetCard
              asset={asset}
              highlights={hightLights}
              onSelection={onSelection}
              selectedItems={selectedItems}
              onClickDownloadAsset={onClickDownloadAsset}
              openModal={() => openModal(i, result)}
              checkAll={checkAll}
              isToggleRightSidenav={isToggleRightSidenav}
              rightSideNavComponent={rightSideNavComponent}
            />
          </Grid>
        )
      }
    })}
  </Grid>
)

AssetList.propTypes = {
  classes: PropTypes.object,
  onClickDownloadAsset: PropTypes.func,
  onSelection: PropTypes.func,
  searchAssets: PropTypes.arrayOf(
    PropTypes.shape({
      asset_details: PropTypes.shape({
        asset_id: PropTypes.string,
      }),
      highlights: PropTypes.object,
    })
  ).isRequired,
  openModal: PropTypes.func.isRequired,
  checkAll: PropTypes.bool,
  isToggleRightSidenav: PropTypes.bool,
  rightSideNavComponent: PropTypes.string,
}

export default AssetList
