export const AD_EXTERNAL_BASIC = 'OAUTH2-ASSETHUB-EXTERNAL'
export const AD_EXTERNAL_ADVANCED = 'OAUTH2-ASSETHUB-EXTERNAL-ADVANCED'
export const AD_INTERNAL_BASIC = 'APP-OAUTH2-ASSETHUB'
export const AD_INTERNAL_RIGHTS = 'APP-OAUTH2-ASSETHUB-RIGHTS'
export const AD_PUBLISHING_SEARCH = 'APP-ASSETHUB-PUBLISH-SEARCH'
export const AD_PUBLISHING_PUBLISH = 'APP-ASSETHUB-PUBLISHER'
export const AD_SUPER_ADMIN = 'APP-OAUTH2-ASSETHUB-ADMIN'
export const AD_LIBRARIAN = 'APP-OAUTH2-ASSETHUB-LIBRARIAN'
export const AD_ROUNDEL_PARTNER = "OAUTH2-ASSETHUB-ROUNDEL-PARTNER"

export const AD_GROPS = [
  AD_EXTERNAL_BASIC,
  AD_EXTERNAL_ADVANCED,
  AD_INTERNAL_BASIC,
  AD_INTERNAL_RIGHTS,
  AD_PUBLISHING_SEARCH,
  AD_PUBLISHING_PUBLISH,
  AD_SUPER_ADMIN,
]

export const INTERNAL_AD_GROPS = [
  AD_INTERNAL_BASIC,
  AD_INTERNAL_RIGHTS,
  AD_PUBLISHING_SEARCH,
  AD_PUBLISHING_PUBLISH,
  AD_SUPER_ADMIN,
]
