import * as React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Moment from 'moment-timezone'

import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
  useTheme,
} from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Avatar from '@mui/material/Avatar'
import Fab from '@mui/material/Fab'
import GroupIcon from '@mui/icons-material/Group'
import IconButton from '@mui/material/IconButton'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp'
import CircularProgress from '@mui/material/CircularProgress'

import imageFallback from '../../images/ic_account_circle.png'
import { UserRoleIcon } from './components/UserRoleIcon'
import RemoteImageLoader from '../RemoteImageLoader'
import {
  collapseTaskGroup,
  expandTaskGroups,
} from './../../store/task/actionCreator'
import { getDisplayName } from '../../mappers/userMapper'
import { UPLOAD_TASK_TYPE_ID } from '../../constants/projectTask'

const themeRow = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: 5,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:last-child > td ': {
            borderBottom: 0,
          },
        },
      },
    },
  },
})
const styles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gap: useTheme().spacing(3),
  },
  buttonProgress: {
    color: '#2196f3',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  button: {
    margin: useTheme().spacing(),
    float: 'right',
    backgroundColor: '#188295',
    '&:hover': {
      backgroundColor: '#002171',
    },
  },
  root: {
    flexGrow: 1,
  },
  resultsText: {
    color: '#c0c0c0',
    margin: useTheme().spacing(),
  },
  TabsContainer: {
    backgroundColor: '#fafafa',
    fontWeight: 400,
    color: '#c00',
    borderBottom: '1px solid rgba(0,0,0,0.12)',
  },
  avatarIcon: {
    color: '#8D8D8D',
  },
  cardHeader: {
    backgroundColor: '#efefef',
  },
  colorTextHeaader: {
    color: '#8D8D8D',
    fontSize: '20px',
    fontWeight: '300',
  },
  cardCont: {
    padding: '0 16px',
  },
  colorTextSecondary: {
    color: '#CAB350',
    fontWeight: '300',
  },
  card: {
    marginTop: 10,
  },
  participantIcon: {
    marginRight: 10,
    height: 48,
    width: 48,
    borderRadius: 40,
    position: 'relative',
    fontSize: 14,
  },
  fab: {
    position: 'relative',
    marginRight: '10px',
    backgroundColor: '#FC3',
    color: '#FFF',
    boxShadow: 'none',
    height: '48px',
    width: '48px',
  },
  leftIcon: {
    top: 5,
    position: 'relative',
    color: '#8B8B8B',
  },
  userEmail: {
    padding: '15px 0px',
  },
  groupUserName: {
    padding: '20px 0px',
  },
  groupUsers: {
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
  },
  userDisplayName: {
    position: 'absolute',
    marginTop: 15,
  },
  row: {
    display: 'flex',
    marginLeft: -50,
    position: 'relative',
  },
  roleEl: {
    display: 'flex',
    gap: '5px',
    alignItems: 'end',
  },
  avatar: {
    backgroundColor: '#EFEFEF',
    height: 56,
    width: 56,
    marginRight: 5,
  },
}))

class TaskTab extends React.Component {
  state = {
    activeTab: 0,
  }
  handleTabChange = (event, value) => {
    this.setState({ activeTab: value })
  }

  expandTaskGroup(groupId, expanded, taskId) {
    if (expanded) {
      this.props.collapseTaskGroup(taskId)
    } else {
      this.props.expandTaskGroups(groupId, taskId)
    }
  }

  render() {
    const {
      classes = {},
      expandInfo = {},
      id = '',
      loading = {},
      taskData = {},
      taskType = 3,
    } = this.props
    const { assets = [], users = {} } = taskData
    const { user_review_tasks = [] } = users
    const { expandTaskGroupLoading = {} } = loading
    const { activeTab = '' } = this.state
    const isUploadTask = taskType === UPLOAD_TASK_TYPE_ID
    return (
      <div className={classes.root}>
        <Tabs
          value={activeTab}
          onChange={this.handleTabChange}
          centered
          indicatorColor="primary"
          textColor="inherit"
        >
          {!isUploadTask && <Tab data-cy="assetTab" label="Assets" />}
          <Tab data-cy="peopleTab" label="People" />
        </Tabs>
        {!isUploadTask && activeTab === 0 && (
          <div>
            {assets.length} assets are assigned to this task.
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Thumbnail</TableCell>
                  <TableCell>Filename</TableCell>
                  <TableCell>File Type</TableCell>
                  <TableCell>Upload Date</TableCell>
                  <TableCell>Uploaded By</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {assets.map((asset = {}, assetsIndex) => {
                  const { render_urls: { thumbnail_asset_url = '' } = {}, mime_type = '' } =
                    asset || {}
                  return (
                    <StyledEngineProvider injectFirst>
                      <ThemeProvider theme={themeRow} key={assetsIndex}>
                        <TableRow data-cy="assetRow">
                          <TableCell>
                            <RemoteImageLoader
                              Src={thumbnail_asset_url}
                              AltText={'Test'}
                              mimeType={mime_type}
                            />
                          </TableCell>
                          <TableCell data-cy="assetName">
                            {asset.asset_name}
                          </TableCell>
                          <TableCell data-cy="assetContentType">
                            {asset.asset_content_type}
                          </TableCell>
                          <TableCell data-cy="assetDate">
                            {Moment.utc(asset.asset_import_date).format(
                              'MMM D, YYYY h:mm a'
                            )}
                          </TableCell>
                          <TableCell data-cy="assetUploader">
                            {asset.asset_imported_by}
                          </TableCell>
                        </TableRow>
                      </ThemeProvider>
                    </StyledEngineProvider>
                  )
                })}
              </TableBody>
            </Table>
          </div>
        )}
        {(isUploadTask || activeTab === 1) && (
          <div>
            {users.people_count} people assigned to this task.
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: 1 }} />
                  <TableCell>Name</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Completion Date</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {user_review_tasks.map((groupUser, groupIndex) => {
                  let rowExpand = !!(
                    expandInfo[groupUser.group_id] &&
                    expandInfo[groupUser.group_id].expanded
                  )
                  const {
                    role_name = '',
                    email = '',
                    organization = '',
                    display_name = '',
                    group_id = '',
                  } = groupUser
                  return (
                    <StyledEngineProvider injectFirst>
                      <ThemeProvider theme={themeRow} key={groupIndex}>
                        <TableRow data-cy="peopleRow">
                          <TableCell />
                          <TableCell>
                            {group_id ? (
                              <div className={classes.row}>
                                <Avatar className={classes.avatar}>
                                  <IconButton
                                    aria-label="Access type"
                                    size="large"
                                  >
                                    <GroupIcon />
                                  </IconButton>
                                </Avatar>
                                <span className={classes.groupUserName}>
                                  {display_name}
                                </span>
                              </div>
                            ) : (
                              <div className={classes.row}>
                                <Fab className={classes.fab}>
                                  {groupUser.first_name && groupUser.last_name
                                    ? groupUser.first_name.substring(0, 1) +
                                      groupUser.last_name.substring(0, 1)
                                    : ''}
                                </Fab>
                                {getDisplayName(email, organization, classes)}
                              </div>
                            )}
                          </TableCell>
                          <TableCell>
                            <UserRoleIcon
                              roleName={role_name}
                              classes={classes}
                            />
                          </TableCell>
                          <TableCell data-cy="taskStatus">
                            {groupUser.task_status}
                          </TableCell>
                          <TableCell data-cy="taskCompletionDate">
                            {groupUser.completion_date &&
                            Moment(groupUser.completion_date).isValid()
                              ? Moment(groupUser.completion_date)
                                  .utc()
                                  .format('MMM D, YYYY h:mm a')
                              : 'N/A'}
                          </TableCell>
                          <TableCell>
                            {groupUser.group_id ? (
                              expandTaskGroupLoading[groupUser.group_id] ? (
                                <CircularProgress />
                              ) : (
                                <IconButton
                                  aria-label="Expand"
                                  onClick={() => {
                                    this.expandTaskGroup(
                                      groupUser.group_id,
                                      rowExpand,
                                      id
                                    )
                                  }}
                                  size="large"
                                >
                                  {rowExpand ? (
                                    <KeyboardArrowDown />
                                  ) : (
                                    <KeyboardArrowUp />
                                  )}
                                </IconButton>
                              )
                            ) : (
                              ''
                            )}
                          </TableCell>
                        </TableRow>
                        {expandInfo[groupUser.group_id] &&
                          expandInfo[groupUser.group_id].users &&
                          expandInfo[groupUser.group_id].users.map(
                            (expanGroupUser = {}, expanGroupIndex) => {
                              const {
                                first_name: groupUserFirstname = '',
                                last_name: groupUserLastname = '',
                                display_name: groupUserDisplayName = '',
                                task_status: groupUserTaskStatus = '',
                                completion_date: groupUserCompletionDate = '',
                              } = expanGroupUser
                              return (
                                <TableRow
                                  key={groupIndex + '/' + expanGroupIndex}
                                >
                                  <TableCell style={{ border: 0 }} />
                                  <TableCell className={classes.groupUsers}>
                                    <Fab className={classes.fab}>
                                      {groupUserFirstname && groupUserLastname
                                        ? groupUserFirstname.substring(0, 1) +
                                          groupUserLastname.substring(0, 1)
                                        : ''}
                                    </Fab>
                                    <span className={classes.userDisplayName}>
                                      {groupUserDisplayName}
                                    </span>
                                  </TableCell>
                                  <TableCell>
                                    <IconButton
                                      aria-label="Access type"
                                      size="large"
                                    >
                                      <GroupIcon />
                                    </IconButton>
                                    {groupUser.access_type}
                                  </TableCell>
                                  <TableCell>{groupUserTaskStatus}</TableCell>
                                  <TableCell>
                                    {Moment(groupUserCompletionDate).isValid()
                                      ? Moment(groupUserCompletionDate)
                                          .utc()
                                          .format('MMM D, YYYY h:mm a')
                                      : 'N/A'}
                                  </TableCell>
                                </TableRow>
                              )
                            }
                          )}
                      </ThemeProvider>
                    </StyledEngineProvider>
                  )
                })}
              </TableBody>
            </Table>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { tabs } = state
  return {
    tabs: tabs,
  }
}

TaskTab.contextTypes = {
  router: PropTypes.object.isRequired,
}

TaskTab.propTypes = {
  classes: PropTypes.object.isRequired,
  loading: PropTypes.shape({
    expandTaskGroupLoading: PropTypes.object,
  }),
  taskData: PropTypes.shape({
    assets: PropTypes.arrayOf({
      asset_content_type: PropTypes.string,
      asset_import_date: PropTypes.instanceOf(Date),
      asset_imported_by: PropTypes.string,
      asset_name: PropTypes.string,
      asset_thumbnail_path: PropTypes.string,
    }),
    users: PropTypes.shape({
      user_review_tasks: PropTypes.arrayOf({
        display_name: PropTypes.string,
        due_date: PropTypes.instanceOf(Date),
        group_id: PropTypes.string,
        first_name: PropTypes.string,
        user_image: PropTypes.string,
        last_name: PropTypes.string,
        role_name: PropTypes.string,
        task_status: PropTypes.string,
      }),
    }),
  }),
  collapseTaskGroup: PropTypes.func,
  expandTaskGroups: PropTypes.func,
  id: PropTypes.string,
  expandInfo: PropTypes.string,
  taskType: PropTypes.number,
}

const MyComponent = (props) => {
  const classes = styles()
  return <TaskTab {...props} classes={classes} />
}

export default connect(mapStateToProps, {
  collapseTaskGroup,
  expandTaskGroups,
})(MyComponent)
