import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { CirclePicker } from 'react-color'
import { withEnv } from '@praxis/component-runtime-env'
import { debounce, remove, isEmpty, isEqual } from 'lodash'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import SearchIcon from '@mui/icons-material/Search'
import {
  List,
  ListItem,
  ListItemIcon,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  FormGroup,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Chip,
} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { ExpandMore } from '@mui/icons-material'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

import { capitalizeString } from '../../helpers/stringHelper'
import {
  capitalizeFilterValues,
  getFilteredFacetValues,
  getAppliedFilterValues,
  getDateLabelByType,
  isFilterApplied,
  isHexValue,
  createColorChipContent,
} from '../../helpers/SearchHelper'
import {
  FACET_UPLOAD_DATE_NAME,
  FACET_SUB_CLASS_KEY_NAME,
  FACET_BRAND_KEY_NAME,
  FACET_BRAND_KEY_VALUE,
  FACET_SUB_CLASS_KEY_VALUE,
  FACET_COLOR_KEY_NAME,
  FACET_CUSTOM_COLOR_KEY_NAME,
  SEARCH_DEFAULT_FACETS,
} from '../../constants/search'
import {
  selectAppliedFilters,
  selectTemplateId,
  selectSortFields,
} from '../../store/search/selector'
import {
  fetchACSuggestions,
  saveAppliedFilters,
  fetchAssets,
} from '../../store/search/actions'
import { updateUserPreferences } from '../../store/preferences/actionCreator'

const styles = makeStyles((theme) => ({
  rangeselector: {
    padding: '4px 4px 16px 4px',
  },
  inputUnderline: {
    '&:after': {
      border: '0 !important',
    },
    '&.Mui-focused:after': {
      border: '0 !important',
    },
    '&.Mui-error:after': {
      border: '0 !important',
    },
    '&:before': {
      border: '0 !important',
    },
    '&:hover:not($disabled):not($focused):not($error):before': {
      border: '0 !important',
    },
    '&.Mui-disabled': {
      border: '0 !important',
    },
    '&.Mui-focused': {
      border: '0 !important',
    },
    '&.Mui-error': {
      border: '0 !important',
    },
    '&.Mui-disabled:before': {
      border: '0 !important',
    },
  },
  focused: {},
  error: {},
  disabled: {},
  filterInput: {
    // width: 'auto',
    border: '1px solid ' + useTheme().palette.primary.lighterGrey,
    textIndent: '15px',
    minHeight: '21px',
    borderRadius: '3px',
    backgroundColor: 'white',
  },
  filterInputRoot: {
    padding: '5px 0px',
    width: 'auto',
    marginLeft: '6px',
  },
  expansionPanelRoot: {
    backgroundColor: 'unset',
    boxShadow: 'unset',
    '&:before': {
      backgroundColor: 'unset',
    },
  },
  expansionPanelSummary: {
    color: '#4D4F5C',
    fontWeight: 500,
    fontSize: '14px',
    margin: '7px',
    borderRadius: 5,
    minHeight: '55px !important',
    padding: 0,
  },
  expansionPanelSummaryExpanded: {
    backgroundColor: '#f5f5f5',
    cursor: 'default',
    margin: '0 0 -5px 5px !important',
  },
  expandMore: {
    color: '#4D4F5C',
    fontWeight: 700,
  },
  expansionPanelDetails: {
    padding: '0 14px',
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    fontSize: 13,
    '& div.circle-picker': {
      width: 'auto !important',
    },
  },
  expansionPanelExpanded: {
    margin: 'unset',
    marginTop: '0 !important',
  },
  filterCheckbox: {
    color: useTheme().palette.primary.primaryBlue,
  },
  filterCheckboxRoot: {
    justifyContent: 'unset',
  },
  filtersListItem: {
    padding: '0',
    color: '#545353',
    fontSize: 14,
  },
  dateRange: {
    width: '100%',
    '& .MuiFormControlLabel-label-505': {
      width: '100%',
    },
  },
  autocompleteInput: {
    backgroundColor: 'white',
  },
  popper: {
    zIndex: '2',
  },
  chip: {
    margin: `${useTheme().spacing(0.5)} ${useTheme().spacing(0.25)}`,
  },
}))

export class FacetFilters extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      searchKeyword: '',
      searchColor: '',
      ACResults: [],
      selectedACFilters: [],
      seeAllFacets: {},
      colorInputValue: '',
    }
    this.handleAutocomplete = debounce(this.handleAutocomplete, 200)
  }

  // handle search filter values
  handleSearchFilter = (event = {}) => {
    if (event.currentTarget) {
      this.setState({ searchKeyword: event.currentTarget.value })
    }
  }

  emitFilterChange(facetName, selection, onFilterChange) {
    if (facetName === FACET_UPLOAD_DATE_NAME) {
      onFilterChange(facetName, selection, {
        value: selection,
        update: true,
        formatter: function () {
          let label = `${this.value[0]} to ${this.value[1]}`
          label = getDateLabelByType(this.value)
          return label
        },
      })
    } else if (
      [FACET_COLOR_KEY_NAME, FACET_CUSTOM_COLOR_KEY_NAME].indexOf(facetName) !==
      -1
    ) {
      onFilterChange(facetName, selection, { value: selection, update: true })
    } else {
      onFilterChange(facetName, selection)
    }
  }

  componentDidMount() {
    const { setFacetHandlers = () => {}, facetName = '' } = this.props
    setFacetHandlers(
      facetName,
      this.handleTagDelete.bind(this),
      this.handleTagsClear.bind(this)
    )
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { appliedFilters = {}, facetName = '' } = this.props
    const { searchColor = '' } = this.state
    const selectedFilterValues = getAppliedFilterValues(appliedFilters)
    const selectedFilterValuesString = selectedFilterValues.toString()

    const { appliedFilters: prevAppliedFilters = {} } = prevProps
    const prevSelectedFilterValues = getAppliedFilterValues(prevAppliedFilters)
    const prevSelectedFilterValuesString = prevSelectedFilterValues.toString()

    if (
      [FACET_COLOR_KEY_NAME, FACET_CUSTOM_COLOR_KEY_NAME].indexOf(facetName) !==
        -1 &&
      !isEmpty(searchColor) &&
      isEmpty(selectedFilterValuesString) &&
      !isEqual(selectedFilterValuesString, prevSelectedFilterValuesString)
    ) {
      this.setState((state, props) => ({
        ...state,
        searchColor: selectedFilterValuesString,
      }))
    }
  }

  handleAutocomplete(event, value, facetName) {
    const { fetchACSuggestions = () => {} } = this.props
    if (value.length > 1) {
      fetchACSuggestions({ searchQuery: value, facetName }).then((response) => {
        const isACFacet =
          facetName === FACET_SUB_CLASS_KEY_NAME ||
          facetName === FACET_BRAND_KEY_NAME
        let ACResults = response
        if (isACFacet) {
          ACResults = capitalizeFilterValues(response)
        }
        this.setState({ ACResults })
      })
    } else {
      this.setState({ ACResults: [] })
    }
  }

  filterSelected(event, values, facetName) {
    const { selectedACFilters = [] } = this.state
    const {
      saveAppliedFilters = () => {},
      appliedFilters = {},
      onFilterChange = () => {},
      pageSize,
      searchTerm,
      templateId,
      sort_fields,
      fetchAssets,
    } = this.props
    let copyAppliedFilters = JSON.parse(JSON.stringify(appliedFilters))

    if (values.length === 0) {
      let filterKey =
        facetName === FACET_BRAND_KEY_NAME
          ? FACET_BRAND_KEY_VALUE
          : FACET_SUB_CLASS_KEY_VALUE
      selectedACFilters.forEach((elem) => {
        remove(copyAppliedFilters[filterKey], (filter) => filter === elem)
      })
      if (copyAppliedFilters[filterKey].length === 0) {
        delete copyAppliedFilters[filterKey]
      }
      saveAppliedFilters(copyAppliedFilters)
      fetchAssets({
        filters: copyAppliedFilters,
        searchTerm,
        size: pageSize,
        templateId,
        sort_fields,
        index: this.props.indexBy,
      })
    } else if (values.length > selectedACFilters.length) {
      onFilterChange(facetName, values[values.length - 1])
    } else if (values.length < selectedACFilters.length) {
      selectedACFilters.forEach((elem) => {
        if (values.indexOf(elem) === -1) {
          onFilterChange(facetName, elem)
        }
      })
    }

    this.setState({ selectedACFilters: values })
  }

  handleTagDelete = (value) => {
    let newSelectedFilters = this.state.selectedACFilters.filter(
      (elem) => elem !== value
    )
    this.setState({ selectedACFilters: newSelectedFilters })
  }

  handleTagsClear = () => {
    this.setState({ selectedACFilters: [] })
  }

  toggleSeeAll = () => {
    const { facetName = '' } = this.props
    const { seeAllFacets = {} } = this.state
    const isFacetToggled = seeAllFacets[facetName] || false
    this.setState((state) => ({
      ...state,
      seeAllFacets: Object.assign({}, seeAllFacets, {
        [facetName]: !isFacetToggled,
      }),
    }))
  }

  handleColorChange = (colorObj = '') => {
    const { facetName = '', onFilterChange = () => {} } = this.props
    const { hex: hexValue = '' } = colorObj
    this.setState((state, props) => {
      this.emitFilterChange(facetName, hexValue, onFilterChange)
      return {
        ...state,
        searchColor: hexValue,
      }
    })
  }

  onColorInputChange = (event, value) => {
    this.setState({
      colorInputValue: event.target.value || '',
    })
  }

  searchCustomColor = () => {
    const { facetName = '', onFilterChange = () => {} } = this.props
    const { colorInputValue = '' } = this.state
    if (!isEmpty(colorInputValue)) {
      this.emitFilterChange(facetName, '#' + colorInputValue, onFilterChange)
    }
  }

  handleDelete = (deletedColor = '') => {
    const { colors = [] } = this.props
    const { updateUserPreferences = () => {}, userPreferences = {} } =
      this.props
    const renderingColors = colors.filter((obj) => obj !== deletedColor)
    updateUserPreferences({ ...userPreferences, colors: renderingColors })
  }

  handleChipClick = (selectedColor = '') => {
    const { facetName = '', onFilterChange = () => {} } = this.props
    this.setState((state, props) => {
      this.emitFilterChange(facetName, `#${selectedColor}`, onFilterChange)
      return {
        ...state,
        searchColor: `#${selectedColor}`,
      }
    })
  }

  createSelctedFilters = () => {
    let selectedFilters = []
    const { classes = {}, colors = [] } = this.props
    for (let i = 0; i < colors.length; i++) {
      const item = createColorChipContent(colors[i], 'color')
      selectedFilters.push(
        <Chip
          key={i}
          tabIndex={-1}
          label={item}
          className={classes.chip}
          onDelete={() => {
            this.handleDelete(colors[i])
          }}
          variant="outlined"
          size="medium"
          clickable={true}
          onClick={() => {
            this.handleChipClick(colors[i])
          }}
        />
      )
    }
    return selectedFilters
  }

  saveColors = (selectedColor = '') => {
    const {
      updateUserPreferences = () => {},
      userPreferences = {},
      colors = [],
    } = this.props
    const updatedColors = [...colors, this.state.colorInputValue]
    updateUserPreferences({ ...userPreferences, colors: updatedColors })
    this.setState((state) => ({
      colorInputValue: '',
    }))
  }

  verifyIsChecked = (selection, facetName) => {
    const { appliedFilters } = this.props
    const facetValue = SEARCH_DEFAULT_FACETS[facetName]
    const selectedFilters = facetValue ? appliedFilters[facetValue] : []
    return (selectedFilters || []).indexOf(selection) !== -1 || false
  }

  render() {
    const {
      appliedFilters = {},
      classes = {},
      facetName = '',
      filters = [],
      onFilterChange,
      env = {},
      colors = [],
      auth: { memberOf = [] } = {},
    } = this.props
    const { ENABLE_COLOR_FILTER = false } = env
    const {
      searchKeyword = '',
      ACResults = [],
      selectedACFilters = [],
      seeAllFacets = {},
      searchColor = '',
      colorInputValue = '',
    } = this.state
    let colorPickerAdor = {
      content: '',
      display: 'block',
      width: '20px',
      height: '21px',
      background: colorInputValue
        ? '#' + colorInputValue
        : 'conic-gradient(red,#ff0,lime,aqua,blue,#f0f,red)',
      borderRadius: '50%',
      marginRight: '0.5rem',
      boxShadow: 'inset 0 0 1px 1px rgb(0 0 0 / 10%)',
      contentVisibility: 'hidden',
    }
    const isSeeAllToggled = seeAllFacets[facetName] || false
    const filterValues = Object.keys(filters)
    const updatedFilterValues = getFilteredFacetValues(
      filterValues,
      searchKeyword
    )
    const selectedFilterValues = getAppliedFilterValues(appliedFilters)
    const uploadDateFilter = facetName === FACET_UPLOAD_DATE_NAME
    const sortedFilterValues = uploadDateFilter
      ? updatedFilterValues
      : updatedFilterValues.sort()
    const filterPlaceholder = capitalizeString(`Enter ${facetName} here`)
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
    const checkedIcon = <CheckBoxIcon fontSize="small" />
    const isACFacet =
      facetName === FACET_SUB_CLASS_KEY_NAME ||
      facetName === FACET_BRAND_KEY_NAME
    const isColorFacet =
      [FACET_COLOR_KEY_NAME, FACET_CUSTOM_COLOR_KEY_NAME].indexOf(facetName) !==
      -1
    const validSelColor = isHexValue(colorInputValue)

    return (
      <Accordion
        expanded={true}
        classes={{
          root: classes.expansionPanelRoot,
          expanded: classes.expansionPanelExpanded,
        }}
      >
        {[FACET_UPLOAD_DATE_NAME, FACET_CUSTOM_COLOR_KEY_NAME].indexOf(
          facetName
        ) === -1 && (
          <AccordionSummary
            data-cy={'filterPanel' + facetName.replace(/\s/g, '')}
            // expandIcon={<ExpandMore classes={{root: classes.expandMore}} />}
            classes={{
              root: classes.expansionPanelSummary,
              expanded: classes.expansionPanelSummaryExpanded,
            }}
          >
            {facetName}
          </AccordionSummary>
        )}
        <AccordionDetails
          classes={{
            root: classes.expansionPanelDetails,
          }}
        >
          {isColorFacet && (
            <Grid container direction="row" alignItems="center" spacing={4}>
              {facetName === FACET_COLOR_KEY_NAME && (
                <Grid item>
                  <CirclePicker
                    width="282px"
                    colors={sortedFilterValues}
                    circleSize={30}
                    circleSpacing={15}
                    color={
                      searchColor ||
                      (selectedFilterValues || []).toString() ||
                      '#111'
                    }
                    onChange={this.handleColorChange}
                  />
                </Grid>
              )}
              {facetName === FACET_CUSTOM_COLOR_KEY_NAME && (
                <Grid item>
                  <Grid container direction={'column'} spacing={1}>
                    <Grid item>
                      <TextField
                        id="color-filter-name"
                        placeholder={'Enter hex'}
                        value={colorInputValue}
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              #<span style={colorPickerAdor}></span>
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                type="button"
                                aria-label="search"
                                onClick={this.searchCustomColor}
                                disabled={validSelColor ? false : true}
                                size="large"
                              >
                                <SearchIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        inputProps={{
                          maxlength: 6,
                        }}
                        error={
                          !isEmpty(colorInputValue)
                            ? validSelColor
                              ? ''
                              : 'Invalid Hex'
                            : ''
                        }
                        variant="outlined"
                        onChange={(event) => this.onColorInputChange(event)}
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        onClick={() => {
                          this.saveColors()
                        }}
                        color="primary"
                      >
                        save color
                      </Button>
                    </Grid>
                    <Grid item>
                      {colors.length > 0 && this.createSelctedFilters()}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          )}
          {filterValues.length > 10 && !isACFacet && !isColorFacet && (
            <TextField
              variant="standard"
              id="filter-name"
              placeholder={filterPlaceholder}
              value={searchKeyword}
              onChange={(event) => this.handleSearchFilter(event, facetName)}
              inputProps={{
                'data-cy': 'filterTextField' + facetName.replace(/\s/g, ''),
              }}
              InputProps={{
                classes: {
                  input: classes.filterInput,
                  underline: classes.inputUnderline,
                  root: classes.filterInputRoot,
                },
              }}
              fullWidth
            />
          )}
          {isACFacet && (
            <Autocomplete
              multiple
              id={facetName}
              options={ACResults}
              getOptionLabel={(option) => option}
              size="small"
              disableCloseOnSelect
              value={selectedACFilters}
              noOptionsText={'No Results'}
              onChange={(event, value) =>
                this.filterSelected(event, value, facetName)
              }
              onInputChange={(event, value) =>
                this.handleAutocomplete(event, value, facetName)
              }
              classes={{
                root: classes.autocompleteInput,
                popper: classes.popper,
              }}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={`Enter ${facetName} here`}
                  placeholder={facetName}
                />
              )}
            />
          )}
          {!isACFacet && !isColorFacet && (
            <List disablePadding className={classes.scrollList} component="div">
              <FormGroup>
                {sortedFilterValues.map((selection = '', filterIndex) => (
                  <ListItem
                    style={{
                      contentVisibility:
                        filterValues.length > 10 && filterIndex >= 10
                          ? isSeeAllToggled
                            ? 'visible'
                            : 'hidden'
                          : 'visible',
                    }}
                    onClick={() =>
                      !uploadDateFilter &&
                      this.emitFilterChange(
                        facetName,
                        selection,
                        onFilterChange
                      )
                    }
                    button
                    data-cy="filterOption"
                    key={`${facetName}${selection}`}
                    className={classes.filtersListItem}
                  >
                    {uploadDateFilter ? null : (
                      <ListItemIcon
                        style={{
                          minWidth: 44,
                          height: 34,
                          cursor: 'pointer',
                        }}
                      >
                        <Checkbox
                          className={classes.filterCheckboxRoot}
                          checked={this.verifyIsChecked(selection, facetName)}
                          key={`${facetName}${selection}`}
                          classes={{
                            checked: classes.filterCheckbox,
                          }}
                        />
                      </ListItemIcon>
                    )}
                    {!uploadDateFilter &&
                      (selection ? capitalizeString(selection) : 'Unknown')}
                  </ListItem>
                ))}
              </FormGroup>
              {filterValues.length > 10 && (
                <Button
                  onClick={() => {
                    this.toggleSeeAll()
                  }}
                  color="primary"
                >
                  {isSeeAllToggled ? 'SEE LESS' : 'SEE ALL'}
                </Button>
              )}
            </List>
          )}
        </AccordionDetails>
      </Accordion>
    )
  }
}

FacetFilters.propTypes = {
  appliedFilters: PropTypes.object,
  classes: PropTypes.object.isRequired,
  facetName: PropTypes.string,
  filters: PropTypes.object,
  onFilterChange: PropTypes.func,
  selectedFilters: PropTypes.object,
  fetchACSuggestions: PropTypes.func,
  setFacetHandlers: PropTypes.func,
  saveAppliedFilters: PropTypes.func,
  pageSize: PropTypes.number,
  searchTerm: PropTypes.string,
  templateId: PropTypes.string,
  sort_fields: PropTypes.object,
  fetchAssets: PropTypes.func,
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchACSuggestions,
      saveAppliedFilters,
      updateUserPreferences,
      fetchAssets,
    },
    dispatch
  )

const mapStateToProps = (state = {}) => {
  const { userPreferences = {} } = state
  const { colors = [] } = userPreferences

  return {
    appliedFilters: selectAppliedFilters()(state),
    templateId: selectTemplateId()(state),
    sort_fields: selectSortFields()(state),
    auth: state.auth,
    userPreferences,
    colors,
  }
}

const MyComponent = (props) => {
  const classes = styles()
  return <FacetFilters {...props} classes={classes} />
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withEnv()(MyComponent))
