import { createSelector } from 'reselect'
import idx from 'idx'

import { isGroupAdminUser } from './helpers'
import { selectUserId } from '../auth/selector'

const selectGroupUsers =
  () =>
  ({ groups = {} }) =>
    idx(groups, (_) => _.groupPeopleList.user) || []

const makeSelectIsGroupAdmin = () =>
  createSelector(selectGroupUsers(), selectUserId(), isGroupAdminUser)

export { makeSelectIsGroupAdmin, selectGroupUsers }
