import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import { Visibility } from '@mui/icons-material'
import apiConfig from '../../../config/apiConfig'
import RemoteImageLoader from '../../RemoteImageLoader'
import { getThumbnailToPreviewUrl } from '../../../helpers/stringHelper'

const styles = {
  thumbnailWrapper: {
    position: 'relative',
    width: '80px',
    height: '80px',
    '&:hover': {
      '& .thumbnailHover': {
        visibility: 'visible',
      },
    },
  },
  thumbnailImage: {
    textAlign: '-webkit-center',
  },
  thumbnailPreview: {
    visibility: 'hidden',
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 1,
    backgroundColor: 'grey',
    opacity: '0.5',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    margin: '0 auto',
  },
}

const { enterpriseUploads = {}, key = '' } = apiConfig

export const ThumbnailBlock = ({
  classes = {},
  imgSrc = '',
  altText = '',
  inProgress = false,
  identifier = '',
  previewHandler = () => {},
  enablePreview,
  val = {},
}) => {
  const { mime_type = '' } = val
  return (
  <div className={classes.thumbnailWrapper}>
    {!inProgress && (
      <div
        data-cy="assetThumbnail"
        className={
          classes.thumbnailPreview + (enablePreview ? ' thumbnailHover' : '')
        }
        onClick={() => {
          previewHandler(identifier, val)
        }}
      >
        <Visibility style={{ fontSize: '30px' }} />
      </div>
    )}
    <div className={classes.thumbnailImage}>
      <RemoteImageLoader
        mimeType={mime_type}
        Src={imgSrc}
        AltText={altText}
        inProgress={inProgress}
      />
    </div>
  </div>
  )
}

ThumbnailBlock.propTypes = {
  classes: PropTypes.object.isRequired,
  imgSrc: PropTypes.string,
  altText: PropTypes.string,
  inProgress: PropTypes.bool,
  identifier: PropTypes.string,
  previewHandler: PropTypes.func,
  enablePreview: PropTypes.bool,
  val: PropTypes.object,
}

export default withStyles(styles)(ThumbnailBlock)
