import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { withStyles } from '@mui/styles'
import Grid from '@mui/material/Grid'
import ListCard from './ListCard'
import withRouter from '../../containers/Router/WithRouter'

const styles = {
  projectListing: {
    textAlign: 'center',
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  gridList: {
    justifyContent: 'center',
  },
  projectGrid: {
    marginBottom: '32px',
    justifyContent: 'center',
  },
  projectLoadMore: {
    marginTop: '10px',
    justifyContent: 'center',
  },
}

export class ImageList extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { classes, history, projects = {}, router = {} } = this.props
    const { projectList } = projects

    return (
      <div>
        <div className={classes.root} data-cy="projectList">
          {projectList.map((list = {}, key) => (
            <Grid item xs={10} lg={10} className={classes.projectGrid}>
              <ListCard
                project={list}
                key={list.project_id + key}
                callBackEditProject={this.props.callBackEditProject}
                history={history}
                router={router}
              />
            </Grid>
          ))}
        </div>
      </div>
    )
  }
}

ImageList.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  callBackEditProject: PropTypes.func,
  classes: PropTypes.object.isRequired,
  projects: PropTypes.shape({
    projectList: PropTypes.arrayOf(
      PropTypes.shape({
        show: PropTypes.bool,
      })
    ),
  }),
}

const mapStateToProps = (state) => {
  const { layout } = state
  const { headerTitle } = layout
  const { projects } = state
  return {
    projects: projects,
    headerTitle: headerTitle,
  }
}
export default connect(mapStateToProps)(
  withStyles(styles)(withRouter(ImageList))
)
