// API request actions
export const CONTENT_SEARCH_API_CALL = 'CONTENT_SEARCH_API_CALL'
export const CONTENT_SEARCH_API_CALL_FULFILLED =
  'CONTENT_SEARCH_API_CALL_FULFILLED'
export const CONTENT_SEARCH_API_CALL_PENDING = 'CONTENT_SEARCH_API_CALL_PENDING'
export const CONTENT_SEARCH_API_CALL_REJECTED =
  'CONTENT_SEARCH_API_CALL_REJECTED'

// API boards metadata request actions
export const BOARDS_CONTENT_SEARCH_API_CALL = 'BOARDS_CONTENT_SEARCH_API_CALL'
export const BOARDS_CONTENT_SEARCH_API_CALL_FULFILLED =
  'BOARDS_CONTENT_SEARCH_API_CALL_FULFILLED'
export const BOARDS_CONTENT_SEARCH_API_CALL_PENDING =
  'BOARDS_CONTENT_SEARCH_API_CALL_PENDING'
export const BOARDS_CONTENT_SEARCH_API_CALL_REJECTED =
  'BOARDS_CONTENT_SEARCH_API_CALL_REJECTED'

// Simple actions
export const UPDATE_FACET_FILTER = 'UPDATE_FACET_FILTER'
export const UPDATE_SEARCH_TERM = 'UPDATE_SEARCH_TERM'
export const CLEAR_SEARCH_RESULTS = 'CLEAR_SEARCH_RESULTS'

//Search Templates actionTypes
export const FETCH_SEARCH_TEMPLATES = 'FETCH_SEARCH_TEMPLATES'
export const FETCH_SEARCH_TEMPLATES_FULFILLED =
  'FETCH_SEARCH_TEMPLATES_FULFILLED'
export const FETCH_SEARCH_TEMPLATES_REJECTED = 'FETCH_SEARCH_TEMPLATES_REJECTED'

// Search Typeahead Suggestions actionTypes
export const CLEAR_TYPEAHEAD_SUGGESTIONS = 'CLEAR_TYPEAHEAD_SUGGESTIONS'
export const FETCH_TYPEAHEAD_SUGGESTIONS = 'FETCH_TYPEAHEAD_SUGGESTIONS'
export const FETCH_TYPEAHEAD_SUGGESTIONS_FULFILLED =
  'FETCH_TYPEAHEAD_SUGGESTIONS_FULFILLED'
export const FETCH_TYPEAHEAD_SUGGESTIONS_PENDING =
  'FETCH_TYPEAHEAD_SUGGESTIONS_PENDING'
export const FETCH_TYPEAHEAD_SUGGESTIONS_REJECTED =
  'FETCH_TYPEAHEAD_SUGGESTIONS_REJECTED'

export const SET_SORT_FIELDS = 'SET_SORT_FIELDS'
export const SET_CURRENT_PAGE_SIZE = 'SET_CURRENT_PAGE_SIZE'

export const UPDATE_DOWNLOAD_ASSETS = 'UPDATE_DOWNLOAD_ASSETS'
export const DOWNLOAD_ASSETS_INPROGRESS = 'DOWNLOAD_ASSETS_INPROGRESS'

export const SET_TEMPLATE_ID = 'SET_TEMPLATE_ID'

export const SET_SEARCH_IMAGE_PREVIEW = 'SET_SEARCH_IMAGE_PREVIEW'

export const DELETE_ASSETS_INPROGRESS = 'DELETE_ASSETS_INPROGRESS'

export const FETCH_METADATA_FOR_ADMIN_INITIATED =
  'FETCH_METADATA_FOR_ADMIN_INITIATED'
export const FETCH_METADATA_FOR_ADMIN_REJECTED =
  'FETCH_METADATA_FOR_ADMIN_REJECTED'
export const FETCH_METADATA_FOR_ADMIN_FULFILLED =
  'FETCH_METADATA_FOR_ADMIN_FULFILLED'

export const FETCH_AC_SUGGESTIONS_INITIATED = 'FETCH_AC_SUGGESTIONS_INITIATED'
