import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import Button from '@mui/material/Button'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import MuiTableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Avatar from '@mui/material/Avatar'
import Edit from '@mui/icons-material/Edit'
import PeopleIcon from '@mui/icons-material/People'
import { ADMIN_ROLE_ID } from '../../../constants/userRoles'
import { isInternalUser } from '../../../mappers/userMapper'
import CustomCard from '../../../components/Common/Card/CustomCard'

const styles = {
  content: {
    display: 'block',
    width: '92.5%',
    color: '#212121',
    fontSize: '14px',
  },
  primary: {
    width: '100px',
  },
  secondary: {
    width: '220px',
  },
}

const TableCellBoard = withStyles({
  root: {
    borderBottom: 'none',
  },
})(MuiTableCell)

const BoardMember = ({
  classes = {},
  userListBoard = [],
  isAdmin = false,
  toggleEditBoardModal = () => {},
}) => {
  const renderCardHeaderActions = () =>
    isAdmin && (
      <Button
        color="primary"
        className={classes.button}
        data-cy="editBoardMembersButton"
        onClick={() => toggleEditBoardModal()}
      >
        <Edit className={classes.rightIcon} />
        Edit Board Members
      </Button>
    )
  const cardConfig = {
    cardHeader: {
      headerAvatar: (
        <Avatar aria-label="People">
          <PeopleIcon />
        </Avatar>
      ),
      headerActionContent: renderCardHeaderActions(),
      title: 'Board Members',
      subheader: '',
      identifier: '',
    },
    displayFooter: false,
  }
  const renderBoardMembers = () => (
    <TableContainer>
      <Table
        data-cy="boardMembersTable"
        className={classes.table}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            <TableCellBoard>Name</TableCellBoard>
            <TableCellBoard align="left">Role</TableCellBoard>
          </TableRow>
        </TableHead>
        <TableBody>
          {userListBoard.map((item, i) => {
            const {
              email_address = '',
              list_name = '',
              organization = '',
            } = item
            return (
              <TableRow key={i}>
                <TableCellBoard component="th" scope="row">
                  {email_address ? email_address : list_name ? list_name : 'NA'}
                  {email_address &&
                  !isInternalUser(email_address) &&
                  organization ? (
                    <div>{organization} </div>
                  ) : (
                    ''
                  )}
                </TableCellBoard>
                <TableCellBoard align="left">
                  {item.role_id === ADMIN_ROLE_ID ? 'Admin' : 'Member'}
                </TableCellBoard>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )

  return (
    <>
      <CustomCard
        cardConfig={cardConfig}
        cardContent={renderBoardMembers()}
        footerContent="Custom Footer Component"
      />
    </>
  )
}

BoardMember.propTypes = {
  classes: PropTypes.object.isRequired,
  userListBoard: PropTypes.object,
  isAdmin: PropTypes.bool,
  toggleEditBoardModal: PropTypes.func,
}

export default withStyles(styles)(BoardMember)
