import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import LabelIcon from '@mui/icons-material/Label'
import PersonIcon from '@mui/icons-material/Person'
import DateRangeIcon from '@mui/icons-material/DateRange'
import DeviceHubIcon from '@mui/icons-material/DeviceHub'
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'
import LockIcon from '@mui/icons-material/Lock'
import LockOpenIcon from '@mui/icons-material/LockOpen'

const MaterialIcon = ({ icon }) => {
  switch (icon) {
    case 'Label':
      return <LabelIcon />
    case 'SupervisorAccount':
      return <SupervisorAccountIcon />
    case 'Supervisor Account':
      return <SupervisorAccountIcon />
    case 'Person':
      return <PersonIcon />
    case 'DateRange':
      return <DateRangeIcon />
    case 'Date':
      return <DateRangeIcon />
    case 'DeviceHub':
      return <DeviceHubIcon />
    case 'Lock':
      return <LockIcon />
    case 'LockOpen':
      return <LockOpenIcon />
    default:
      return <LabelIcon />
  }
}
MaterialIcon.propTypes = {
  icon: PropTypes.string,
}

// TODO: loading icon takes time
// const MaterialIcon = ({ icon }) => {
//   let iconName = (icon) ? icon.replace(/Icon$/, '') : 'Label'
//   let resolved = require(`@mui/icons-material/${iconName}`).default
//   if (!resolved) {
//     throw Error(`Could not find @material-ui-icons/${iconName}`)
//   }
//   return React.createElement(resolved)
// }

const styles = {
  parentList: {
    display: 'flex',
    'flex-direction': 'row',
    'list-style-type': 'none',
    'justify-content': 'center',
    'align-items': 'baseline',
    padding: 0,
  },
  listItem: {
    position: 'relative',
    '&:first-child': {
      top: '30px',
    },
  },
  listInput: {
    position: 'relative',
    width: '100%',
  },
}

export class IconWrapper extends PureComponent {
  render() {
    const { classes = {}, iconClass = '', children, iconTag = '' } = this.props
    return (
      <div>
        <ul className={classes.parentList}>
          <li className={iconClass || classes.listItem}>
            <MaterialIcon icon={iconTag} />
          </li>
          <li className={classes.listInput}>{children}</li>
        </ul>
      </div>
    )
  }
}

IconWrapper.propTypes = {
  iconTag: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  iconClass: PropTypes.string,
  children: PropTypes.any,
}

export default withStyles(styles)(IconWrapper)
