import React, { useState, useEffect, startTransition } from 'react'
import { useDispatch } from 'react-redux'
import { useTheme } from '@mui/material/styles'
import Table from '@mui/material/Table'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TablePagination from '@mui/material/TablePagination'
import Paper from '@mui/material/Paper'
import InputAdornment from '@mui/material/InputAdornment'
import Tooltip from '@mui/material/Tooltip'
import SearchIcon from '@mui/icons-material/Search'
import Clear from '@mui/icons-material/Clear'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import { makeStyles } from '@mui/styles'
import IconButton from '@mui/material/IconButton'
import FirstPageIcon from '@mui/icons-material/FirstPage'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import LastPageIcon from '@mui/icons-material/LastPage'
import { formatNumberToString } from '../../helpers/stringHelper'
import { formatDateOfUsageRights } from '../../helpers/dateHelper'
import withRouter from '../../containers/Router/WithRouter'
import { getUsageRightsDataList } from '../../store/usage_rights/action'

const useStyles = makeStyles((theme) => ({
  createdDateCellWidth: {
    minWidth: '100px',
  },
  updatedDateCellWidth: {
    minWidth: '130px',
  },
  rightHoldersCellWidth: {
    minWidth: '140px',
  },
  tableRowStyle: {
    height: '60px',
  },
  drmIdStyle: {
    paddingLeft: '15px',
  },
  headerTitle: {
    padding: '10px 0px 10px 20px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  searchField: {
    width: '25%',
    float: 'right',
  },
  usageRightsCount: {
    flexGrow: 1,
  },
  noUsageRights: {
    padding: '10px'
  }
}))

function TablePaginationActions(props) {
  const theme = useTheme()
  const { count, page, rowsPerPage, onPageChange } = props

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0)
  }

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1)
  }

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1)
  }

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  )
}

const SearchTextInput = ({ classes = {}, searchVal = '', setSearchVal = () => {}, filterSearchValues = () => {} }) => {

  const onKeyUpInTextField = (e = {}) => {
    const { key: eventKey = '' } = e
    if (eventKey) {
      if (eventKey === 'Enter') {
        filterSearchValues(searchVal)
      }
    }
  }

  return (
    <TextField
      id="usage_rights_filter"
      name="usage_rights_filter"
      placeholder="Search"
      rows="1"
      value={searchVal}
      onChange={(e) => {
        setSearchVal(e.target.value)
      }}
      variant="outlined"
      size={'small'}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment:
          searchVal === '' ? null : (
            <Tooltip title="Clear Filter">
              <IconButton
                onClick={() => {
                  setSearchVal('')
                  filterSearchValues('')
                }}
                style={{ padding: 0 }}
                size="large"
              >
                <Clear />
              </IconButton>
            </Tooltip>
          ),
      }}
      className={classes.searchField}
      fullWidth
      onKeyUp={(e) => {
        onKeyUpInTextField(e)
      }}
    />
  )
}

function UsageRightsList({ router = {} }) {
  const [totalCount, setTotalCount] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const classes = useStyles()
  const dispatch = useDispatch()
  const [usageRightsData, setUsageRightsData] = useState([])
  const [searchVal, setSearchVal] = useState('')
  const [queryText, setQueryText] = useState('')
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(50)

  useEffect(() => {
    const searchPayload = {
      searchText: queryText, 
      page: page, 
      rowsPerPage: rowsPerPage
    }
    getUsageDataList(searchPayload)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, queryText])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleRowClick = (usage_rights_id = '') => {
    router.navigate(`/usageRights/details/${usage_rights_id}`)
  }

  const convertToDesiredList = (list) => {
    let tempList = []
    list.forEach((element) => {
      let tempObj = {
        usage_rights_id: element.usage_rights_id,
        project_description: element.project_description,
        project_id:
          element.project_id && element.project_id !== null
            ? element?.project_id?.join(', ')
            : '-',
        buyer: element.buyer,
        updated_by:
          element.updated_by && element.updated_by !== null
            ? element.updated_by
            : '-',
        holder_name: element.holder_name,
        holder_type: element.holder_type,
        creation_time:
          element.creation_time && element.creation_time !== null
            ? formatDateOfUsageRights(element.creation_time)
            : '-',
        updated_time:
          element.updated_time && element.updated_time !== null
            ? formatDateOfUsageRights(element.updated_time)
            : '-',
      }
      tempList.push(tempObj)
    })
    return tempList
  }

  const getUsageDataList = ({
    searchText = '',
    page = Number(0),
    rowsPerPage = Number(50)
  }) => {
    setIsLoading(true)
    const usageRightsParams = {
      searchText,
      page,
      rowsPerPage
    }
    dispatch(
      getUsageRightsDataList(
        usageRightsParams,
        (usageRightsResponse) => {
          const { total_count = Number(0), usage_rights: itemList = [] } =
            usageRightsResponse
          setTotalCount(total_count)
          if (itemList.length > 0) {
            let listOfData = convertToDesiredList(itemList)
            setUsageRightsData(listOfData)
          }
          setIsLoading(false)
        },
        () => {
          setIsLoading(false)
        }
      )
    )
  }

  const filterSearchValues = (searchText = '') => {
    startTransition(() => {
      setQueryText(searchText)
      setPage(0)
    })
  }

  return (
    <>
      <div className={classes.headerTitle}>
        <div className={classes.usageRightsCount}>
          {totalCount > 0 && formatNumberToString(totalCount)} Records
        </div>
        <SearchTextInput
          classes={classes}
          searchVal = {searchVal}
          setSearchVal={setSearchVal}
          filterSearchValues = {filterSearchValues}
        />
        <TablePagination
          component="div"
          rowsPerPageOptions={[50,100,200]}
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            native: true,
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </div>

      <TableContainer component={Paper} sx={{ maxHeight: 650 }}>
        <Table
          stickyHeader
          sx={{ minWidth: 650 }}
          aria-label="usage rights table"
        >
          <TableHead>
            <TableRow>
              <TableCell align="center" className={classes.drmIdStyle}>
                DRM ID
              </TableCell>
              <TableCell>PROJECT DESCRIPTION</TableCell>
              <TableCell>PROJECT ID</TableCell>
              <TableCell>BUYER</TableCell>
              <TableCell className={classes.rightHoldersCellWidth}>
                RIGHTS HOLDER NAME
              </TableCell>
              <TableCell>HOLDER TYPE</TableCell>
              <TableCell className={classes.createdDateCellWidth}>
                CREATED DATE
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoading ? ( totalCount > 0 ? usageRightsData.map((usageRightsItem = {}, index) => (
              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                className={classes.tableRowStyle}
              >
                <TableCell className={classes.drmIdStyle}>
                  <Link
                    component="button"
                    variant="body2"
                    onClick={() => {
                      handleRowClick(usageRightsItem.usage_rights_id)
                    }}
                  >
                    {usageRightsItem.usage_rights_id}
                  </Link>
                </TableCell>
                <TableCell>{usageRightsItem.project_description}</TableCell>
                <TableCell>{usageRightsItem.project_id}</TableCell>
                <TableCell>{usageRightsItem.buyer}</TableCell>
                <TableCell>{usageRightsItem.holder_name}</TableCell>
                <TableCell>{usageRightsItem.holder_type}</TableCell>
                <TableCell>{usageRightsItem.creation_time}</TableCell>
              </TableRow>
            )): (
              <div className={classes.noUsageRights}>No Usage Rights Found</div>
            )): (
              <CircularProgress className={classes.progress} />
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default withRouter(UsageRightsList)
