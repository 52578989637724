import Moment from 'moment-timezone'

export const formatDate = function (date = '', delimiter = '-') {
  if (date === '') {
    return date
  }
  const d = new Date(date)
  let month = '' + (d.getMonth() + 1)
  let day = '' + d.getDate()
  const year = d.getFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return [year, month, day].join(delimiter)
}

export const formatDateTime = (date = '', format = 'MMM D, YYYY h:mm a') =>
  date && Moment(date).isValid() ? Moment(date).format(format) : ''

export const convertShortDate = (date = new Date()) =>
  Moment.utc(date).format('YYYY-MM-DD')

export const convertToCST = (date = new Date()) =>
  new Date(Moment.tz(convertShortDate(date), 'America/chicago').format())

export const formatCSTDate = (dateTime = new Date(), format = 'lll') => {
  if (dateTime === '') {
    return dateTime
  }
  return Moment(dateTime).utc('Z').format(format)
}

export const convertCSTtoLocal = (dateString = '', format = 'lll') => {
  const timeZoneDiff = getTimeZoneOffset()
  const dateInLocal = dateString?.replace('Z', timeZoneDiff)
  return Moment(dateInLocal).local().format(format)
}

const getTimeZoneOffset = () => {
  const timezoneOffset = Moment.tz('America/chicago').utcOffset()
  const offset = Math.abs(timezoneOffset)
  const offsetOperator = timezoneOffset < 0 ? '-' : '+'
  const offsetHours = Math.floor(offset / 60)
    .toString()
    .padStart(2, '0')
  const offsetMinutes = Math.floor(offset % 60)
    .toString()
    .padStart(2, '0')
  return `${offsetOperator}${offsetHours}:${offsetMinutes}`
}

export const convertUTCToLocalTime = (dateString = '') => (Moment(new Date(dateString)).utc('Z').local())

export const convertUTCtoLocal = (dateString = '', format = 'lll') =>
  Moment(new Date(dateString)).utc('Z').local().format(format)

export const formatAnnotationDate = (dateTime = new Date()) =>
  Moment(dateTime).utc('Z').format('LLL')

export const isBefore = (dateTime = new Date()) => Moment().isBefore(dateTime)

export const isMoment = (data = {}) => Moment.isMoment(data)

export const isSameDate = (date1 = new Date(), date2 = new Date()) =>
  Moment(date1).isSame(date2)

export const momentHelper = Moment

export const formatUsageRightsDate = (dateTime = new Date()) =>
  Moment(dateTime).utc('Z').format('LL')

export const formatExpirationDate = (
  firstUseDate = new Date(),
  durationType = 'years',
  durationCount = 0,
) =>
  Moment(firstUseDate)
    .add(durationCount, durationType.toLowerCase())
    .utc('Z')
    .format('LL')

export const formatDateOfUsageRights = (dateTime = new Date()) =>
  Moment(dateTime).utc('Z').format('MMM D, YYYY')

export const sortUsageInfoByExpDate = (usage_info) => {
  const usageInfo = [...usage_info]
  usageInfo.sort(
    (item1, item2) =>
      new Date(item1.expiration_date) - new Date(item2.expiration_date),
  )
  return usageInfo
}
