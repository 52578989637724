import React, { useState, useEffect, startTransition } from 'react'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import { makeStyles } from '@mui/styles'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { getSelectedClassification } from '../../helpers/classificationHelper'

const useStyles = makeStyles({
  classificationFacet: {
    padding: '10px',
  },
  classificationSelect: {
    margin: '20px 10px',
  },
})

function ClassificationFilter({
  facetName = '',
  facetData = [],
  selectedFilters = {},
  onFilterChange = () => {},
}) {
  const classes = useStyles()
  const { templateId = '' } = useSelector((state) => state?.assetsSearch)
  const [classification, setClassification] = useState('')
  const [classificationId, setClassificationId] = useState('')

  const selectedClassification = getSelectedClassification(
    facetData,
    classification
  )

  const { initClassificationId = null } = selectedFilters

  useEffect(() => {
    if (templateId || !initClassificationId) {
      startTransition(() => {
        setClassification('')
        setClassificationId('')
      })
    }
  }, [templateId, initClassificationId])

  useEffect(() => {
    if (classificationId) {
      onFilterChange(facetName, classificationId, {
        value: classificationId,
        update: true,
      })
    }
  }, [classificationId])

  return (
    <>
      <div className={classes.classificationFacet}>Classification</div>
      <FormControl className={classes.classificationSelect}>
        <InputLabel id="classification-label">Classification</InputLabel>
        <Select
          labelId="classification-select-label"
          id="classification-simple-select"
          value={classification}
          label="Classification"
          onChange={(event) => setClassification(event.target.value)}
        >
          {facetData.map((classificationData) => {
            const { classification_name = '' } = classificationData
            return (
              <MenuItem value={classification_name}>
                {classification_name}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
      {!isEmpty(selectedClassification) && (
        <FormControl className={classes.classificationSelect}>
          <InputLabel id="classification-label">Asset Type</InputLabel>
          <Select
            labelId="asset-select-label"
            id="asset-simple-select"
            value={classificationId}
            label="Asset Type"
            onChange={(event) => setClassificationId(event.target.value)}
          >
            {selectedClassification['asset_types'].map((assetData) => {
              const { name = '', classification_id = '' } = assetData
              return <MenuItem value={classification_id}>{name}</MenuItem>
            })}
          </Select>
        </FormControl>
      )}
    </>
  )
}

export default ClassificationFilter
