import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import { useSelector } from 'react-redux'
/**Material Components */
import {
  Avatar,
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Menu,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  Switch,
} from '@mui/material'
/**card chagnes */
import GroupIcon from '@mui/icons-material/Group'
import MoreVertIcon from '@mui/icons-material/MoreVert'
/**Material Icons */
import { Delete, ViewAgenda, Edit, MoreVert } from '@mui/icons-material'
import CustomCard from '../../../components/Common/Card/CustomCard'

/**Helpers */
import { formatDateTime } from '../../../helpers/dateHelper'

import apiConfig from '../../../config/apiConfig'

/**Styles/Classes */
const styles = {
  content: {
    display: 'block',
    width: '92.5%',
    color: '#212121',
    fontSize: '14px',
  },
  primary: {
    width: '100px',
  },
  secondary: {
    width: '220px',
  },
  boardActionIcon: {
    marginRight: '5px',
    fontSize: '12px',
  },
  editBoard: {
    fontSize: '12px',
  },
  aiSwitch:  {
    width: "260px"
  },
  aiSwitchColor: {
    color: "#178295"
  }
}

export const BoardData = ({
  aiEligible,
  updateBoard = () => {},
  classes = {},
  assetCount = 0,
  boardName = 'Board dashboard',
  createdBy = '',
  createdDate = '',
  updatedBy = '',
  updatedDate = '',
  isBoardAdmin = false,
  toggleDeleteBoardModal,
  openUpdateBoardForm,
  deleteBoardEL = '',
  openBoardMenu,
  closeMenu,
}) => {

  const {
    auth: { isAdGroupSuperAdmin = false } = {},
  } = useSelector((state) => state) || {}

  const [isAiEligible,setAiEligible] = useState(aiEligible)

  useEffect(() =>{
    setAiEligible(aiEligible)
  },[aiEligible])
  
  const renderCardHeaderActions = () =>
    isBoardAdmin ? (
      <div className={classes.editBoard}>
        <span>EDIT OR DELETE BOARD</span>
        <IconButton
          aria-label="More"
          aria-owns={open ? 'long-menu' : null}
          aria-haspopup="true"
          data-cy="editDeleteBoardButton"
          onClick={openBoardMenu}
          size="large"
        >
          <MoreVert />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={deleteBoardEL}
          open={Boolean(deleteBoardEL)}
          onClose={closeMenu}
          PaperProps={{ style: styles.width200 }}
        >
          <MenuItem
            data-cy="deleteBoardOption"
            onClick={() => toggleDeleteBoardModal(true)}
          >
            <Delete className={classes.boardActionIcon} /> Delete Board
          </MenuItem>
          <MenuItem
            data-cy="editBoardOption"
            onClick={() => openUpdateBoardForm()}
          >
            <Edit className={classes.boardActionIcon} /> Edit Name
          </MenuItem>
        </Menu>
      </div>
    ) : (
      ''
    )

  const renderBoardDataContent = () => (
    <List dense>
      <ListItem>
        <ListItemText className={classes.primary} primary="Created By" />
        <ListItemText
          className={classes.secondary}
          secondary={createdBy}
          secondaryTypographyProps={{ 'data-cy': 'boardCreatedBy' }}
        />
      </ListItem>
      <ListItem>
        <ListItemText className={classes.primary} primary="Created On" />
        <ListItemText
          className={classes.secondary}
          secondary={formatDateTime(createdDate)}
          secondaryTypographyProps={{ 'data-cy': 'boardCreateDate' }}
        />
      </ListItem>
      <ListItem>
        <ListItemText className={classes.primary} primary="Assets" />
        <ListItemText
          className={classes.secondary}
          secondary={assetCount}
          secondaryTypographyProps={{ 'data-cy': 'boardAssetCount' }}
        />
      </ListItem>
      <ListItem>
        <ListItemText className={classes.primary} primary="Last Updated By" />
        <ListItemText
          className={classes.secondary}
          secondary={updatedBy}
          secondaryTypographyProps={{ 'data-cy': 'boardUpdatedBy' }}
        />
      </ListItem>
      <ListItem>
        <ListItemText className={classes.primary} primary="Last Updated" />
        <ListItemText
          className={classes.secondary}
          secondary={formatDateTime(updatedDate)}
          secondaryTypographyProps={{ 'data-cy': 'boardUpdateDate' }}
        />
      </ListItem>

      {isAdGroupSuperAdmin && <ListItem className={classes.aiSwitch}>
      <ListItemText primary="Allow use for AI tests" />
        <Switch checked = {isAiEligible}
        onChange={() => updateBoard()}/>
      </ListItem>}
    </List>
  )


  const cardConfig = {
    cardHeader: {
      headerAvatar: (
        <Avatar aria-label="Recipe">
          <ViewAgenda />
        </Avatar>
      ),
      headerActionContent: renderCardHeaderActions(),
      title: boardName,
      subheader: '',
      identifier: '',
    },
    displayFooter: false,
  }
  const onClickHandler = (actionType = '', identifier = '') => {
    switch (actionType) {
      case 'headerActions': {
        if (identifier === 'menu') {
          openBoardMenu(event)
        }
        return true
      }
      case 'footerAction': {
        if (identifier === 'menu') {
          openBoardMenu(event)
        }
        return true
      }
      default:
        break
    }
  }
  return (
    <>
      <CustomCard
        cardConfig={cardConfig}
        dispatchClickAction={onClickHandler}
        cardContent={renderBoardDataContent()}
        footerContent="Custom Footer Component"
      />
    </>
  )
}

BoardData.propTypes = {
  classes: PropTypes.object.isRequired,
  assetCount: PropTypes.number,
  createdBy: PropTypes.string,
  createdDate: PropTypes.string,
  updatedBy: PropTypes.string,
  updatedDate: PropTypes.string,
  boardName: PropTypes.string,
  isBoardAdmin: PropTypes.bool,
  openUpdateBoardForm: PropTypes.bool,
  toggleDeleteBoardModal: PropTypes.func,
  deleteBoardEL: PropTypes.string,
  openBoardMenu: PropTypes.func,
  closeMenu: PropTypes.func,
}

export default withStyles(styles)(BoardData)
