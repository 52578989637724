export const BOARD_DEFAULT_STATUS = 'ACTIVE'
export const BOARD_DEFAULT_VISIBILITY = 'PRIVATE'

export const BOARD_SORT_OPTIONS = {
  board_name: 'Alphabetical | A to Z',
  board_name_asc: 'Alphabetical | Z to A',
  asset_count_high: '# of Assets | High to Low',
  asset_count_low: '# of Assets | Low to High',
  created_at: 'Created Date | New to Old',
  created_at_asc: 'Create Date | Old to New',
  last_updated: 'Last Modified Date | New to Old',
  last_updated_asc: 'Last Modified Date | Old to New',
}

export const SORT_OPTIONS_ASSETS = {
  asset_name: 'Alphabetical | A to Z',
  asset_name_asc: 'Alphabetical | Z to A',
  added_date: 'Date Added to Board | New to Old',
  added_date_asc: 'Date Added to Board | Old to New',
  asset_created: 'Asset Created Date | New to Old',
  asset_created_asc: 'Asset Created Date | Old to New',
}

export const SHARED_BOARD_TYPE = 'SHARED'
export const CREATED_BOARD_TYPE = 'CREATED'

export const BOARDS_DISPLAY_NOTE = 'You created the following boards.'
export const BOARDS_NO_RESULTS_NOTE = 'No boards to show.'
export const BOARDS_SUB_HEAD_NOTE = 'You created the following boards.'
export const BOARDS_COLLABORATOR_USER = 'user'
export const BOARDS_COLLABORATOR_GROUP = 'group'

export const BOARD_USER_REMOVE_ADMIN_MESSAGE =
  'You are removing your admin privileges for this board and will no longer be able to manage users or assets. Would you like to proceed?'
export const BOARD_USER_REMOVE_FINAL_ADMIN_MESSAGE =
  'If you would like to remove your admin privileges for this board please add another admin. You can also delete this board if it is no longer needed'

export const BOARD_USER_SELF_REMOVE_MESSAGE =
  'You are removing yourself for this board and will no longer be able to manage users or assets. Would you like to proceed?'
export const BOARD_USER_SELF_REMOVE_NO_ADMIN_MESSAGE =
  'If you would like to remove yourself for this board please add another admin. You can also delete this board if it is no longer needed'
