import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
/**Material Components */
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  CircularProgress,
  Grid,
  Slide,
} from '@mui/material'
/**Material Icons */
import { Clear, Title, ViewAgenda } from '@mui/icons-material'
/**Action Creators */
import { createBoard, updateBoard } from '../../store/boards/actions'
/**Selectors */
import { selectUserId } from '../../store/auth/selector'
/**helpers/constants */
import { TEXT_SPECIAL_CHARS_FORM_VALID } from '../../constants/notifications'
import { validateStringField } from '../../helpers/stringHelper'
import { isBoardNameExist } from '../../helpers/BoardsHelper'

const styles = makeStyles((theme) => ({
  leftIcon: {
    marginRight: useTheme().spacing(),
  },
  wrapper: {
    margin: useTheme().spacing(),
    position: 'relative',
  },
  button: {
    backgroundColor: '#0D46A0',
    '&:hover': {
      backgroundColor: '#0D46A0',
    },
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}))

function Transition(props) {
  return <Slide direction="left" {...props} />
}

export class CreateBoardForm extends React.Component {
  constructor(props) {
    super(props)
    const {
      boards: { modalData: { board_id = '', board_name = '', isEdit, ai_eligible = false } = {} },
    } = props
    this.state = {
      board_id,
      formData: {
        board_name,
      },
      ai_eligible,
      isEdit,
      isFormChanged: false,
      fieldValidationErrors: {},
    }
  }
  handleChange = (prop) => (event) => {
    const { formData = {}, fieldValidationErrors = {} } = this.state
    let fieldValue = event.target.value || ''
    let isFormChanged = true
    const validationErrors = this.validateField(prop, fieldValue)
    this.setState({
      isFormChanged: isFormChanged,
      formData: { ...formData, [prop]: fieldValue },
      fieldValidationErrors: { ...fieldValidationErrors, ...validationErrors },
    })
  }

  isFormValid = () => {
    const { isFormChanged = false, formData = {} } = this.state
    const { fieldValidationErrors = {} } = this.state
    const formErrorCheck = !Object.keys(fieldValidationErrors)?.length > 0
    const formDataEmptyCheck = !Object.keys(formData)?.some(
      (key) => !formData[key]
    )
    return isFormChanged && formErrorCheck && formDataEmptyCheck
  }

  validateField = (fieldName = '', fieldValue = '') => {
    const { fieldValidationErrors = {} } = this.state
    let ValidationErrors = {}
    const isValidField = !validateStringField(fieldValue)

    if (!isValidField) {
      ValidationErrors[fieldName] = isValidField
    } else {
      delete fieldValidationErrors[fieldName]
    }
    const finalErrorList = { ...fieldValidationErrors, ...ValidationErrors }
    return finalErrorList
  }

  handleSubmit = () => {
    const {
      formData: { board_name = '' },
      board_id = '',
      isEdit,
      ai_eligible
    } = this.state
    const {
      createBoard = () => {},
      updateBoard = () => {},
      lanId = '',
    } = this.props
    if (isEdit && board_id) {
      updateBoard({ board_id, board_name, lanId, ai_eligible })
    } else {
      createBoard(board_name, [], lanId)
    }
  }

  isExistsInErrorList = (fieldName = '') => {
    const { fieldValidationErrors = {} } = this.state
    return (
      Object.keys(fieldValidationErrors)?.indexOf(fieldName) >= 0 &&
      !fieldValidationErrors[fieldName]
    )
  }

  renderAppBar = () => {
    const { onClose = () => {} } = this.props
    const { isEdit } = this.state
    const modalHeadText = !isEdit ? 'Create Board' : 'Edit Board'
    return (
      <AppBar className="dialogTitleBar">
        <Toolbar className="dialogTitleHeight">
          <Typography color="inherit" className="dialogTitle">
            {modalHeadText}
          </Typography>
          <IconButton
            className="dialogClose"
            aria-label="Delete"
            onClick={onClose}
            size="large"
          >
            <Clear />
          </IconButton>
        </Toolbar>
      </AppBar>
    )
  }

  renderForm = () => {
    const {
      formData: { board_name = '' },
    } = this.state
    const {
      boards: { boardList = [], isBoardCreated, modalData = {} },
    } = this.props
    let boardNameError = this.isExistsInErrorList('board_name')
    let helperText = "You'll search for this name to use your board."
    if (boardNameError) {
      helperText = TEXT_SPECIAL_CHARS_FORM_VALID
    } else if (
      modalData.board_name !== board_name &&
      isBoardNameExist(boardList, board_name)
    ) {
      boardNameError = true
      helperText = `${board_name} has already been used, please use a different name.`
    }

    return (
      <form>
        <Grid container spacing={1} alignItems="flex-end">
          <Grid item>
            <Title style={{ fontSize: 30, marginBottom: 8 }} />
          </Grid>
          <Grid item sm>
            <TextField
              variant="standard"
              required
              id="board_name"
              value={board_name}
              onChange={this.handleChange('board_name')}
              label="Board Name"
              error={boardNameError}
              fullWidth
              margin="normal"
              helperText={helperText}
              inputProps={{ 'data-cy': 'createBoardFormTextField' }}
            />
          </Grid>
        </Grid>
      </form>
    )
  }

  renderActions = () => {
    const {
      classes = {},
      boards: { isBoardCreated },
    } = this.props
    const { isEdit } = this.state
    const buttonLabel = !isEdit ? 'Create Board' : 'Update Board'
    let boardNameError = this.isExistsInErrorList('board_name')
    return (
      <div className={classes.wrapper}>
        <Button
          data-cy="createBoardFormButton"
          variant="contained"
          className={classes.button}
          onClick={this.handleSubmit}
          color="primary"
          disabled={boardNameError || !this.isFormValid()}
        >
          {' '}
          <ViewAgenda className={classes.leftIcon} />
          {buttonLabel}
        </Button>
        {isBoardCreated && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
      </div>
    )
  }

  render() {
    const {
      boards: { boardList = [], modalData = {} },
      onClose = () => {},
    } = this.props
    const {
      formData: { board_name = '' },
    } = this.state
    let boardNameError = this.isExistsInErrorList('board_name')

    let helperText = "You'll search for this name to use your board."
    if (boardNameError) {
      helperText = TEXT_SPECIAL_CHARS_FORM_VALID
    } else if (
      modalData.board_name !== board_name &&
      isBoardNameExist(boardList, board_name)
    ) {
      boardNameError = true
      helperText = `${board_name} has already been used, please use a different name.`
    }

    return (
      <Dialog
        open={open}
        onClose={onClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        fullWidth
        TransitionComponent={Transition}
      >
        {this.renderAppBar()}
        <DialogContent style={{ width: 520 }}>
          {this.renderForm()}
        </DialogContent>
        <DialogActions>{this.renderActions()}</DialogActions>
      </Dialog>
    )
  }
}

CreateBoardForm.propTypes = {
  classes: PropTypes.object.isRequired,
  lanId: PropTypes.string,
  boards: PropTypes.shape({
    board_name: PropTypes.string,
    isBoardCreated: PropTypes.bool,
  }),
  onClose: PropTypes.func.isRequired,
  createBoard: PropTypes.func.isRequired,
  updateBoard: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  const lanId = selectUserId()(state)
  const { boards = {} } = state
  return {
    lanId,
    boards,
  }
}

const MyComponent = (props) => {
  const classes = styles()
  return <CreateBoardForm {...props} classes={classes} />
}

export default connect(mapStateToProps, { createBoard, updateBoard })(
  MyComponent
)
