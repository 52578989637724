import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

const styles = makeStyles((theme) => ({
  root: {
    flex: '1 1 auto',
    minWidth: 0,
    padding: '0 16px',
    '&:first-child': {
      paddingLeft: 0,
    },
  },
  inset: {
    '&:first-child': {
      paddingLeft: 56,
    },
  },
  dense: {
    fontSize: useTheme().typography.pxToRem(13),
  },
  primary: {
    '&.Mui-textDense': {
      fontSize: 'inherit',
    },
  },
  secondary: {
    '&.Mui-textDense': {
      fontSize: 'inherit',
    },
  },
  tertiary: {
    '&.Mui-textDense': {
      fontSize: 'inherit',
    },
  },
  textDense: {},
}))

function ListItemText(props, context) {
  const {
    children,
    classes,
    className: classNameProp,
    disableTypography,
    inset,
    primary: primaryProp,
    primaryTypographyProps,
    secondary: secondaryProp,
    secondaryTypographyProps,
    tertiary: tertiaryProp,
    tertiaryTypographyProps,
    ...other
  } = props
  const { dense } = context

  let primary = primaryProp != null ? primaryProp : children
  if (primary != null && primary.type !== Typography && !disableTypography) {
    primary = (
      <Typography
        variant="subtitle1"
        className={classNames(classes.primary, { [classes.textDense]: dense })}
        component="span"
        {...primaryTypographyProps}
      >
        {primary}
      </Typography>
    )
  }

  let secondary = secondaryProp
  if (
    secondary != null &&
    secondary.type !== Typography &&
    !disableTypography
  ) {
    secondary = (
      <Typography
        variant="body1"
        className={classNames(classes.secondary, {
          [classes.textDense]: dense,
        })}
        color="textSecondary"
        {...secondaryTypographyProps}
      >
        {secondary}
      </Typography>
    )
  }

  let tertiary = tertiaryProp
  if (tertiary != null && tertiary.type !== Typography && !disableTypography) {
    tertiary = (
      <Typography
        variant="body1"
        className={classNames(classes.tertiary, {
          [classes.textDense]: dense,
        })}
        color="textSecondary"
        {...tertiaryTypographyProps}
      >
        {tertiary}
      </Typography>
    )
  }

  return (
    <div
      className={classNames(
        classes.root,
        {
          [classes.dense]: dense,
          [classes.inset]: inset,
        },
        classNameProp
      )}
      {...other}
    >
      {primary}
      {secondary}
      {tertiary}
    </div>
  )
}

ListItemText.propTypes = {
  /**
   * Alias for the `primary` property.
   */
  children: PropTypes.node,
  /**
   * Override or extend the styles applied to the component.
   * See [CSS API](#css-api) below for more details.
   */
  classes: PropTypes.object.isRequired,
  /**
   * @ignore
   */
  className: PropTypes.string,
  /**
   * If `true`, the children won't be wrapped by a Typography component.
   * This can be useful to render an alternative Typography variant by wrapping
   * the `children` (or `primary`) text, and optional `secondary` text
   * with the Typography component.
   */
  disableTypography: PropTypes.bool,
  /**
   * If `true`, the children will be indented.
   * This should be used if there is no left avatar or left icon.
   */
  inset: PropTypes.bool,
  /**
   * The main content element.
   */
  primary: PropTypes.node,
  /**
   * These props will be forwarded to the primary typography component
   * (as long as disableTypography is not `true`).
   */
  primaryTypographyProps: PropTypes.object,
  /**
   * The secondary content element.
   */
  secondary: PropTypes.node,
  /**
   * These props will be forwarded to the secondary typography component
   * (as long as disableTypography is not `true`).
   */
  secondaryTypographyProps: PropTypes.object,
  /**
   * The tertiary content element.
   */
  tertiary: PropTypes.node,
  /**
   * These props will be forwarded to the tertiary typography component
   * (as long as disableTypography is not `true`).
   */
  tertiaryTypographyProps: PropTypes.object,
}

ListItemText.defaultProps = {
  disableTypography: false,
  inset: false,
}

ListItemText.contextTypes = {
  dense: PropTypes.bool,
}

const MyComponent = (props) => {
  const classes = styles()
  return <ListItemText {...props} classes={classes} />
}

// export default withStyles(styles, { name: 'MuiListItemText' })(ListItemText)
export default MyComponent
