import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Checkbox,
  TextField,
  FormControl,
  FormGroup,
  FormControlLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material'
import { withStyles } from '@mui/styles'
import { ExpandMore } from '@mui/icons-material'

import { remove } from 'lodash'
import firefly from '../../../../analytics/firefly'

const styles = (theme) => ({
  formControl: {
    maxHeight: '200px',
    overflowY: 'scroll',
    width: '100%',
    paddingLeft: '10px !important',
  },
  listItem: {
    padding: 0,
    height: 40,
    marginTop: 4,
  },
  listItemText: {
    flex: 'none',
  },
  formControlLabel: {
    marginLeft: 0,
  },
  label: {
    fontSize: '14px !important',
    color: '#545353',
  },
  search: {
    backgroundColor: 'white !important',
  },
  moreHorizIcon: {
    backgroundColor: '#E64545',
    color: 'white',
    borderRadius: '50%',
    marginLeft: 15,
  },
  progress: {
    margin: '0 auto',
  },
  expansionPanelRoot: {
    backgroundColor: 'unset !important',
    boxShadow: 'none !important',
    '&:before': {
      backgroundColor: 'unset !important',
      height: '0 !important',
    },
  },
  expansionPanelExpanded: {
    margin: 'unset !important',
    marginTop: '0 !important',
  },
  expansionPanelSummary: {
    color: '#4D4F5C !important',
    fontSize: '14px !important',
    margin: '7px 0px !important',
    backgroundColor: 'white !important',
    borderRadius: '5px !important',
    marginTop: '15px !important',
    textTransform: 'uppercase !important',
    minHeight: '55px !important',
  },
  expansionPanelSummaryExpanded: {
    backgroundColor: '#e5e5e5 !important',
  },
  expansionPanelDetails: {
    padding: '0 0 0 5px !important',
    display: 'flex !important',
    flexDirection: 'column !important',
  },
  filterInputRoot: {
    padding: '5px 0px',
    width: 'auto',
    height: '41px',
  },
})

const MultiFilter = ({
  classes = {},
  facets = [],
  path = '',
  label = '',
  setFilterValue = () => {},
  selectedValues = [],
  resetToggle = false,
  fetchAssets = () => {},
  lanId = '',
}) => {
  const [filterText, setFilterText] = useState('')

  useEffect(() => {
    setFilterText('')
  }, [resetToggle])

  const handleCheck = (e, filter = '') => {
    const newSelectedValues = [...selectedValues]

    if (selectedValues.includes(filter)) {
      remove(newSelectedValues, (item) => item === filter)
    } else {
      newSelectedValues.push(filter)
    }

    setFilterValue({ [path]: newSelectedValues })
    firefly.trackPublishSearchClick(
      'publish_search',
      'filter_click',
      '',
      `${path}: ${newSelectedValues.join(',')}`,
      lanId
    )
    fetchAssets()
  }

  const hasTextMatch = (filterOption = '') =>
    filterOption.toLowerCase().indexOf(filterText.toLowerCase()) !== -1

  return (
    <Accordion
      defaultExpanded={true}
      classes={{
        root: classes.expansionPanelRoot,
        expanded: classes.expansionPanelExpanded,
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        classes={{
          root: classes.expansionPanelSummary,
          expanded: classes.expansionPanelSummaryExpanded,
        }}
      >
        {label}
      </AccordionSummary>
      <AccordionDetails
        classes={{
          root: classes.expansionPanelDetails,
        }}
      >
        <TextField
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
          placeholder={`Find ${label}`}
          variant="outlined"
          size="small"
          className={classes.search}
          InputProps={{
            classes: {
              root: classes.filterInputRoot,
            },
          }}
        />
        <FormControl variant="standard" className={classes.formControl}>
          <FormGroup>
            {Object.keys(facets)
              .filter((filterOption) => hasTextMatch(filterOption))
              .map((filter = '', key) => (
                <FormControlLabel
                  classes={{
                    root: classes.formControlLabel,
                    label: classes.label,
                  }}
                  key={key}
                  label={filter}
                  control={
                    <Checkbox
                      checked={selectedValues.includes(filter)}
                      onClick={(e) => handleCheck(e, filter)}
                    />
                  }
                />
              ))}
          </FormGroup>
        </FormControl>
      </AccordionDetails>
    </Accordion>
  )
}

MultiFilter.propTypes = {
  classes: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  setFilterValue: PropTypes.func.isRequired,
  selectedValues: PropTypes.array,
  resetToggle: PropTypes.bool.isRequired,
  fetchAssets: PropTypes.func.isRequired,
  lanId: PropTypes.string.isRequired,
}

export default withStyles(styles)(MultiFilter)
