import idx from 'idx'
import { createSelector } from 'reselect'
import { isBefore } from '../../helpers/dateHelper'

export const selectCurrentMarkup = ({ annotation = {} }) =>
  annotation.currentMarkup

export const selectDueDate = ({ annotation = {} }) =>
  idx(annotation, (_) => _.task_info.due_date) || new Date()

export const selectJobId = ({ annotation = {} }) =>
  idx(annotation, (_) => _.task_info.job_id)

export const selectTaskName = ({ annotation = {} }) =>
  idx(annotation, (_) => _.task_info.job_name)

export const selectTaskId = ({ annotation = {} }) =>
  idx(annotation, (_) => _.task_info.task_id)

export const selectProjectUUID = ({ annotation = {} }) =>
  idx(annotation, (_) => _.project_uuid)

export const selectCurrentTaskRole =
  () =>
  ({ annotation = {} }) =>
    idx(annotation, (_) => _.task_info.role_name)

export const selectPageNumber =
  (isSecondary = false) =>
  ({ annotation = {} }) =>
    !isSecondary
      ? idx(annotation, (_) => _.primaryPageNumber) || 1
      : idx(annotation, (_) => _.secondaryPageNumber) || 1

export const selectNumberOfPage =
  (isSecondary = false) =>
  ({ annotation = {} }) =>
    !isSecondary
      ? idx(annotation, (_) => _.primaryAsset.page_count) || 1
      : idx(annotation, (_) => _.secondaryAsset.page_count) || 1

export const selectAssetId =
  (isSecondary = false) =>
  ({ annotation = {} }) =>
    !isSecondary
      ? idx(annotation, (_) => _.primaryAsset.asset_id)
      : idx(annotation, (_) => _.secondaryAsset.asset_id)

export const selectMasterId =
  (isSecondary = false) =>
  ({ annotation = {} }) =>
    !isSecondary
      ? idx(annotation, (_) => _.primaryAsset.asset_master_id)
      : idx(annotation, (_) => _.secondaryAsset.asset_master_id)

export const selectRevisionNumber =
  (isSecondary = false) =>
  ({ annotation = {} }) =>
    !isSecondary
      ? idx(annotation, (_) => _.primaryVersion.versionNumber)
      : idx(annotation, (_) => _.secondaryVersion.versionNumber)

export const selectAssetVersions =
  (isSecondary = false) =>
  ({ annotation = {} }) =>
    !isSecondary
      ? idx(annotation, (_) => _.primaryAssetVersions)
      : idx(annotation, (_) => _.secondaryAssetVersions)

export const selectTaskStatus =
  () =>
  ({ annotation = {} }) =>
    idx(annotation, (_) => _.task_info.status)

export const selectCommentsNumberByAssetId =
  (assetId = '') =>
  ({ annotation = {} }) =>
    idx(annotation, (_) => _.allCommentInfo[assetId]) || 0

export const selectTaskUserRole =
  () =>
  ({ annotation = {} }) =>
    idx(annotation, (_) => _.task_info.role_name)

export const selectRevisionHistory =
  (isSecondary = false) =>
  ({ annotation = {} }) =>
    isSecondary
      ? idx(annotation, (_) => _.secondaryAssetRevisionHistory) || {}
      : idx(annotation, (_) => _.primaryAssetRevisionHistory) || {}

export const numberOfPagesSelector =
  (assetId = '') =>
  ({ annotation = {} }) => {
    const asset = annotation.assets.find((x) => x.asset_id === assetId)
    return idx(asset, (_) => _.page_count) || undefined
  }

export const selectAllAssetIds =
  () =>
  ({ annotation = {} }) =>
    annotation.assets.map((asset) => asset.asset_id)

export const selectIsCurrentTaskRole = (role = '') =>
  createSelector(selectCurrentTaskRole(), (currentTaskRole = '') =>
    role ? currentTaskRole === role : false
  )

export const selectIsActiveReview = () =>
  createSelector(selectDueDate, (dueDate = new Date()) => isBefore(dueDate))

export const selectAssetVersion = (isSecondary = false) =>
  createSelector(
    selectRevisionNumber(isSecondary),
    selectAssetVersions(isSecondary),
    (assetVersion = 1, assetVersions = []) =>
      assetVersions.find((x) => x.asset_version === assetVersion) || {}
  )

export const selectAssetVersionId = (isSecondary = false) =>
  createSelector(
    selectRevisionNumber(isSecondary),
    selectAssetVersions(isSecondary),
    (assetVersion = 1, assetVersions = []) => {
      const asset = assetVersions.find((x) => x.asset_version === assetVersion)
      if (asset) {
        return asset.asset_id
      }
      return ''
    }
  )

export const selectNumberOfPagesByAssetId = (assetId = '') =>
  createSelector(
    selectAssetVersion(),
    selectAssetVersion(true),
    numberOfPagesSelector(assetId),
    (
      primaryAsset = {},
      secondaryAsset = {},
      defaultNumberOfPages = undefined
    ) => {
      if (primaryAsset.asset_id === assetId) return primaryAsset.page_count
      if (secondaryAsset.asset_id === assetId) return secondaryAsset.page_count
      return defaultNumberOfPages
    }
  )

export const combineAllAssetIds = () =>
  createSelector(
    selectAllAssetIds(),
    selectAssetVersions(),
    selectAssetVersions(true),
    (assetIds = [], primaryAssetVersions = [], secondaryAssetVersions = []) => {
      let allIds = [...assetIds]
      allIds.push(...primaryAssetVersions.map((x) => x.asset_id))
      allIds.push(...secondaryAssetVersions.map((x) => x.asset_id))
      //Remove all duplicates
      return Array.from(new Set(allIds))
    }
  )

export const selectTaskUserIsObserver = () =>
  createSelector(
    selectTaskUserRole(),
    (roleName = '') => roleName.toLowerCase() === 'observer'
  )
