import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty, isEqual, get } from 'lodash'
import { connect } from 'react-redux'
import { CircularProgress } from '@mui/material'
import { withStyles } from '@mui/styles'
import { withEnv } from '@praxis/component-runtime-env'
import Annotation from '../AnnotationZoom'
import Box from './ImageMarkupBox'
import RectangleSelector from './RectangleSelector'
import { storeCurrentMarkup } from '../../store/annotation/actions'
import apiConfig from '../../config/apiConfig'
import { assetPropType } from '../../constants/annotation'
import { selectAssetVersion } from '../../store/annotation/selectors'
import { setScale } from '../../helpers/annotationHelper'
import NoThumbnail from '../../images/NoThumbnail.svg?url'

export function renderSelector({ annotation = {} }, number = 0, angle = 0) {
  const { geometry } = annotation
  if (!geometry) return null
  return <Box geometry={geometry} number={number} angle={angle} />
}

renderSelector.propTypes = {
  annotation: PropTypes.object,
}

export function renderHighlight({ annotation = {} }, angle = 0) {
  const { geometry, data } = annotation
  if (!geometry) return null
  return (
    <Box key={data.id} geometry={geometry} number={data.id} angle={angle} />
  )
}

renderHighlight.propTypes = {
  annotation: PropTypes.object,
}

export const StyledCicularProgress = withStyles({
  root: {
    position: 'absolute',
    right: '50%',
    top: '50%',
    marginTop: -20,
    marginLeft: -20,
  },
})(CircularProgress)

export class ImageAnnotator extends React.PureComponent {
  constructor(props) {
    super(props)

    this.imgRef = React.createRef()
    this.state = {
      containerClientWidth: 0,
      assetClientHeight: 0,
      assetClientWidth: 0,
      isLoading: true,
      isFirstRender: false,
      assetObj: {},
      naturalHeight: 0,
      naturalWidth: 0,
      zoomAnnotatorProps: {},
      doesExistsZoomAnnotatorProps: false,
      isError: false,
    }
  }

  componentDidMount() {
    this.setCallback()

    const currentFoucsedElem = get(
      this.imgRef,
      'current.el.children[0].children[0]',
      null
    )
    const zoomRefCurElem = get(this.props, 'zoomPerRef.current', null)
    if (currentFoucsedElem) {
      // Setup isScrolling variable
      let isResizing
      currentFoucsedElem.addEventListener('resize', (event) => {
        event.preventDefault()
        const { detail: eventDetailObj = {} } = event
        const { scale = Number(1) } = eventDetailObj
        clearTimeout(isResizing)
        if (zoomRefCurElem) {
          zoomRefCurElem['innerText'] = `${Math.round(scale * 100)}%`
        }
        // Set a timeout to run after scrolling ends
        isResizing = setTimeout(function () {
          // Run the callback
        }, 66)
      })

      // Setup isScrolling variable
      let isScrolling
      // Listen for scroll events
      currentFoucsedElem.addEventListener(
        'wheel',
        (event) => {
          event.preventDefault()
          // Clear our timeout throughout the scroll
          clearTimeout(isScrolling)
          if (event.deltaY < 0) {
            currentFoucsedElem['style']['cursor'] = 'zoom-in'
          } else if (event.deltaY > 0) {
            currentFoucsedElem['style']['cursor'] = 'zoom-out'
          }
          // Set a timeout to run after scrolling ends
          isScrolling = setTimeout(function () {
            // Run the callback
            currentFoucsedElem['style']['cursor'] = 'unset'
          }, 66)
        },
        false
      )

      const checkResize = (mutations) => {
        const el = mutations[0].target
        const w = el.clientWidth
        const h = el.clientHeight
        const style = window.getComputedStyle(el)
        // eslint-disable-next-line no-undef
        const matrix = new WebKitCSSMatrix(style.transform)
        // const elTop = matrix.m41
        // const elLeft = matrix.m42
        const eScale = matrix.d

        const isChange = mutations
          .map((m) => `${m.oldValue}`)
          .some(
            (prev) =>
              prev.indexOf(`width: ${w}px`) === -1 ||
              prev.indexOf(`height: ${h}px`) === -1
          )

        if (!isChange) {
          return
        }
        const event = new CustomEvent('resize', { detail: { scale: eScale } })
        el.dispatchEvent(event)
      }
      const observer = new MutationObserver(checkResize)
      observer.observe(currentFoucsedElem, {
        attributes: true,
        attributeOldValue: true,
        attributeFilter: ['style'],
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { containerHeight = Number(0), previewImageUrl } = this.props
    const {
      containerHeight: prevContainerHeight = Number(0),
      previewImageUrl: prePreviewImageUrl = '',
    } = prevProps
    const { zoomAnnotatorProps: prevZoomAnnotatorProps = {} } = prevState
    const { zoomAnnotatorProps = {} } = this.state

    if (
      containerHeight > Number(0) &&
      !isEqual(containerHeight, prevContainerHeight)
    ) {
      this.setCallback()
    }
    if (previewImageUrl !== prePreviewImageUrl) {
      this.setState({
        isLoading: true,
        isError: false,
        assetClientHeight: 0,
        assetClientWidth: 0,
        isFirstRender: true,
      })
    }
    if (
      !Object.entries(prevZoomAnnotatorProps).length &&
      Object.entries(zoomAnnotatorProps).length
    ) {
      this.setState({
        doesExistsZoomAnnotatorProps: true,
      })
    }
  }

  getAssetId = () => {
    const pathName = window.location.pathname
    const stringArr = pathName.split('/')
    let assetUnqId = ''
    if (stringArr.length > 7) {
      assetUnqId = stringArr[5] // extracting asset master id from URL
    }
    return assetUnqId
  }

  setCallback = () => {
    const currentFoucsedElemImg = get(
      this.imgRef,
      'current.el.children[0].children[0].children[0]',
      null
    )
    const currentFocusedElem = get(this.imgRef, 'current.el.children[0]', null)
    const currentFocusedElemPaper = get(
      this.imgRef,
      'current.el.children[0].children[0]',
      null
    )
    if (currentFocusedElem) {
      currentFocusedElem['style']['width'] = '100%'
      currentFocusedElem['style']['overflow'] = 'unset'
      currentFocusedElemPaper['style']['display'] = 'inline-block'
    }
    if (currentFoucsedElemImg) {
      currentFoucsedElemImg.onload = this.onLoadCallback
      currentFoucsedElemImg.onerror = this.onErrorCallback
    }
  }

  onErrorCallback = (event) => {
    this.setState({
      isLoading: false,
      isError: true,
    })
  }
  onLoadCallback = (data = {}) => {
    const { path = [], currentTarget } = data
    const img = currentTarget ? currentTarget : path.length > 0 ? path[0] : {}
    const {
      clientHeight = 0,
      clientWidth = 0,
      naturalHeight = 0,
      naturalWidth = 0,
    } = img
    this.setState({
      isLoading: false,
      assetClientHeight: clientHeight,
      assetClientWidth: clientWidth,
      isFirstRender: false,
      naturalHeight,
      naturalWidth,
    })
  }

  onChange = (annotation) => {
    const { currentTool, storeCurrentMarkup } = this.props
    if (!isEmpty(annotation) && currentTool !== 'cursor') {
      storeCurrentMarkup(annotation)
    }
  }

  renderParentComponent = (elemProps = {}) => {
    const { getImageControlHandlers = () => {} } = this.props
    this.setState((state) => {
      getImageControlHandlers(elemProps)
      return {
        ...state,
        zoomAnnotatorProps: Object.assign({}, elemProps),
      }
    })
  }

  render() {
    const {
      currentTool = '',
      scale = 'fit-to-screen',
      currentMarkup = {},
      annotations = [],
      onSubmitAnnotation = () => {},
      numberOfMarkups = 0,
      angle = 0,
      currentPageNumber = 1,
      isSelected = false,
      containerHeight = 0,
      containerWidth = 0,
      isSecondary = false,
      asset = {},
      env = {},
      previewImageUrl = '',
    } = this.props
    const {
      isLoading,
      isError,
      assetClientHeight,
      assetObj,
      naturalHeight,
      naturalWidth,
      doesExistsZoomAnnotatorProps,
    } = this.state
    const { ZOOM_CONFIG = {} } = env
    const { render_url: renderUrl = '', asset_name: assetName = '' } = assetObj
    const { render_url = '', asset_name = '' } = asset
    let imageUrl = ''
    let nameOfAsset = !isSecondary ? assetName : asset_name
    const finalRenderUrl = !isSecondary ? renderUrl : render_url
    const { key: apiConfigKey = '' } = apiConfig
    if (!!finalRenderUrl) {
      imageUrl = `${finalRenderUrl}?key=${apiConfigKey}`
      if (currentPageNumber > 0) {
        imageUrl = `${finalRenderUrl}?key=${apiConfigKey}&render_pdf=true&asset_page=${
          currentPageNumber + 1
        }`
      }
    }
    let fixedScale = scale
    fixedScale =
      setScale(
        angle,
        scale,
        containerHeight,
        containerWidth,
        naturalHeight,
        naturalWidth,
        assetClientHeight
      ) || 'fit-content'
    return (
      <div
        data-cy="imageAnnotatorZoom"
        style={{
          width: fixedScale,
          display: 'inline-block',
        }}
      >
        {isLoading && <StyledCicularProgress />}
        <Annotation
          annotations={[]}
          zoomConfig={ZOOM_CONFIG}
          src={isError ? NoThumbnail : previewImageUrl}
          alt={'test url'}
          selectors={[]}
          disableSelector={true}
          disableEditor={true}
          disableOverlay={true}
          renderSelector={() => {}}
          renderHighlight={() => {}}
          renderContent={() => {}}
          movingMode={true}
          disableAnnotation={true}
          ref={this.imgRef}
          style={{ visibility: isLoading ? 'hidden' : 'visible' }}
          renderParentComponent={
            doesExistsZoomAnnotatorProps ? () => {} : this.renderParentComponent
          }
        />
      </div>
    )
  }
}

ImageAnnotator.propTypes = {
  imageUrl: PropTypes.string,
  altText: PropTypes.string,
  currentTool: PropTypes.string,
  scale: PropTypes.string,
  currentMarkup: PropTypes.object,
  annotations: PropTypes.array,
  onSubmitAnnotation: PropTypes.func,
  numberOfMarkups: PropTypes.number,
  classes: PropTypes.object,
  storeCurrentMarkup: PropTypes.func,
  angle: PropTypes.number,
  isSecondary: PropTypes.bool,
  asset: assetPropType,
  currentPageNumber: PropTypes.number,
  isSelected: PropTypes.bool,
  containerHeight: PropTypes.number,
  containerWidth: PropTypes.number,
}

const mapStateToProps = (state = {}, props = {}) => {
  const { isSecondary } = props
  const { annotation = {} } = state
  const { currentMarkup, primaryPageNumber, secondaryPageNumber } = annotation
  const primaryAsset = selectAssetVersion()(state)
  const secondaryAsset = selectAssetVersion(true)(state)

  const currentPageNumber = !isSecondary
    ? primaryPageNumber - 1
    : secondaryPageNumber - 1
  const asset = !isSecondary ? primaryAsset : secondaryAsset

  return {
    currentMarkup,
    currentPageNumber,
    asset,
  }
}

export default connect(mapStateToProps, { storeCurrentMarkup })(
  withEnv()(ImageAnnotator)
)
