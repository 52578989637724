import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import * as metadataApi from '../apiService'
import { sortMyArray } from '../../utils/utils'

const initialState = {
  assetMetadata: {},
  assetRevisions: [],
  assetCatalog: {},
  isMetadataLoading: false,
  isRevisionsLoading: false,
  isCatalogLoading: false,
}

export const fetchMetadataForAsset = createAsyncThunk(
  'metadata/fetchMetadataForAsset',
  async (assetId) => {
    try {
      const response = await metadataApi.fetchMetadataApi(assetId)
      const { data: metadataResponse = {} } = response
      return metadataResponse
    } catch (error) {
      return Promise.reject(error)
    }
  },
)

export const fetchCatalogForAsset = createAsyncThunk(
  'metadata/fetchCatalogForAsset',
  async () => {
    try {
      const response = await metadataApi.fetchAssetCatalogApi()
      const { data: catalogResponse = {} } = response
      return catalogResponse
    } catch (error) {
      return Promise.reject(error)
    }
  },
)

export const updateMetadataForAsset = createAsyncThunk(
  'metadata/updateMetadataForAsset',
  async (payload = {}) => {
    try {
      const response = await metadataApi.updateMetadataApi(payload)
      const { data: updatedMetadataResponse = [] } = response
      return updatedMetadataResponse
    } catch (error) {
      return Promise.reject(error)
    }
  },
)

export const fetchRevisionsForAsset = createAsyncThunk(
  'metadata/fetchRevisionsForAsset',
  async (assetId) => {
    try {
      const response = await metadataApi.fetchRevisionsApi(assetId)
      const { data: revisionsResponse = [] } = response
      return revisionsResponse
    } catch (error) {
      return Promise.reject(error)
    }
  },
)

export const deleteRevisionsForAsset = createAsyncThunk(
  'metadata/deleteRevisionsForAsset',
  async (payload) => {
    try {
      const response = await metadataApi.deleteRevisionsApi(payload)
      const { data: revisionsDeleteResponse = [] } = response
      return revisionsDeleteResponse
    } catch (error) {
      return Promise.reject(error)
    }
  },
)

export const metadataSlice = createSlice({
  name: 'metadata',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMetadataForAsset.pending, (state, action) => {
        state.isMetadataLoading = true
      })
      .addCase(fetchMetadataForAsset.fulfilled, (state, action) => {
        const { payload = {} } = action
        state.isMetadataLoading = false
        state.assetMetadata = payload
        state.assetRevisions = []
      })
      .addCase(fetchMetadataForAsset.rejected, (state, action) => {
        state.isMetadataLoading = false
      })
      .addCase(fetchRevisionsForAsset.pending, (state, action) => {
        state.isRevisionsLoading = true
      })
      .addCase(fetchRevisionsForAsset.fulfilled, (state, action) => {
        const { payload = {} } = action
        state.isRevisionsLoading = false
        state.assetRevisions = sortMyArray(
          payload || [],
          'revision_number',
          'desc',
        )
      })
      .addCase(fetchRevisionsForAsset.rejected, (state, action) => {
        state.isRevisionsLoading = false
      })
      .addCase(fetchCatalogForAsset.pending, (state, action) => {
        state.isCatalogLoading = true
      })
      .addCase(fetchCatalogForAsset.fulfilled, (state, action) => {
        const { payload = {} } = action
        state.isCatalogLoading = false
        state.assetCatalog = payload
      })
      .addCase(fetchCatalogForAsset.rejected, (state, action) => {
        state.isCatalogLoading = false
      })
      .addCase(updateMetadataForAsset.pending, (state, action) => {
        state.isMetadataLoading = true
      })
      .addCase(updateMetadataForAsset.fulfilled, (state, action) => {
        const { payload = [] } = action
        state.isMetadataLoading = false
        state.assetMetadata = payload?.[0] || {}
      })
      .addCase(updateMetadataForAsset.rejected, (state, action) => {
        state.isMetadataLoading = false
      })
  },
})

export default metadataSlice.reducer
