import { sortMyArray } from '../../helpers/UtilityHelper'
import {
  BOARDS_COLLABORATOR_USER,
  BOARDS_COLLABORATOR_GROUP,
} from '../../constants/boards'

const groupAssetsByBoard = (boardAssets = {}, latestAssets = {}) => {
  const modifiedBoardAssets = boardAssets || {}
  const latestAssetsEntries = Object.keys(latestAssets) || []
  if (latestAssetsEntries.length > 0) {
    latestAssetsEntries.forEach((boardId) => {
      const boardAssetsList = latestAssets[boardId]
      modifiedBoardAssets[boardId] = sortMyArray(
        boardAssetsList,
        'created_date'
      )
    })
  }
  return modifiedBoardAssets
}

const noOfAssetsInBoard = (boardAssets = {}, board_id = '') =>
  Object.keys(boardAssets).indexOf(board_id) !== -1
    ? boardAssets[board_id].length
    : 0

const structureCollaboratorList = (collaborators = []) => {
  collaborators.forEach((collaborator = {}) => {
    const { login_id = '', display_name = '', list_name = '' } = collaborator
    // if single user
    if (login_id) {
      collaborator.display_name = display_name.indexOf('.')
        ? display_name.split('.').join(' ')
        : display_name
      let displayNameArr = collaborator.display_name.split(' ')
      collaborator.first_name = collaborator.first_name
        ? collaborator.first_name
        : displayNameArr[0] || ''
      collaborator.last_name = collaborator.last_name
        ? collaborator.last_name
        : displayNameArr[1] || ''
      collaborator.type = BOARDS_COLLABORATOR_USER
      collaborator.name = display_name
    } else {
      // if group
      collaborator.type = BOARDS_COLLABORATOR_GROUP
      collaborator.name = list_name
      collaborator.expanded = false
      collaborator.fetchingUsers = false
    }
  })
  return collaborators
}

export { groupAssetsByBoard, noOfAssetsInBoard, structureCollaboratorList }
