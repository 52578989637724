import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import Grid from '@mui/material/Grid'
import withRouter from '../../containers/Router/WithRouter'
import ListCard from './ListCard'

const styles = {}

export class BoardList extends React.PureComponent {
  render() {
    const { boardList = [], history = {}, router = {} } = this.props
    const generatedList = boardList.filter(
      (board) => board?.show === undefined || board?.show === true
    )

    return (
      <div>
        <Grid container spacing={2} justifyContent="center">
          {generatedList.map((board, idx) => (
            <Grid item xs={11} sm={10} md={9} key={idx}>
              <ListCard
                board={board}
                history={history}
                router={router}
                key={idx}
              />
            </Grid>
          ))}
        </Grid>
      </div>
    )
  }
}

BoardList.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  boardList: PropTypes.arrayOf(
    PropTypes.shape({
      asset_count: PropTypes.number,
      board_name: PropTypes.string,
      created_date: PropTypes.string,
      created_user: PropTypes.string,
      shared_user_count: PropTypes.number,
      updated_date: PropTypes.string,
    })
  ),
}

export default withStyles(styles)(withRouter(BoardList))
