import React from 'react'
import PropTypes from 'prop-types'
import { TextField, MenuItem } from '@mui/material/'
import IconWrapper from '../IconWrapper/IconWrapper'
import { FormikFieldPropTypes } from '../../constants/projects'

export const SelectFormField = ({
  field = {},
  form = {},
  label = '',
  menuItems = [],
  iconLabel = '',
  isRequired = false,
  ...props
}) => (
  <IconWrapper iconTag={iconLabel}>
    <TextField
      variant="outlined"
      required={isRequired}
      select
      InputLabelProps={{ shrink: !!field.value }}
      label={label}
      error={!!form.errors[field.name]}
      helperText={form.errors[field.name]}
      fullWidth
      placeholder={`Please select ${field.name}`}
      style={{ marginLeft: '10px' }}
      {...field}
      {...props}
    >
      {menuItems.map((x = {}) => (
        <MenuItem value={x.value} key={x.value}>
          {x.text}
        </MenuItem>
      ))}
    </TextField>
  </IconWrapper>
)

SelectFormField.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      text: PropTypes.string,
    }),
  ),
  ...FormikFieldPropTypes,
}

export default SelectFormField
