import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import Snackbar from '@mui/material/Snackbar'
import SnackbarContent from '@mui/material/SnackbarContent'
import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'
import CheckIcon from '@mui/icons-material/Check'
import ErrorIcon from '@mui/icons-material/Error'
import InfoIcon from '@mui/icons-material/Info'
import CloseIcon from '@mui/icons-material/Close'
import { showNotification } from '../../store/notification/actionCreator'

const variantIcon = {
  success: CheckIcon,
  error: ErrorIcon,
  info: InfoIcon,
}

const styles = makeStyles((theme) => ({
  success: {
    backgroundColor: '#4CAF50',
  },
  error: {
    backgroundColor: '#CC0000',
  },
  info: {
    backgroundColor: useTheme().palette.primary.dark,
  },
  icon: {
    fontSize: 24,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: useTheme().spacing(2),
  },
  message: {
    fontSize: 15,
    display: 'flex',
    alignItems: 'center',
  },
}))

const Notifications = () => {
  const classes = styles()
  const dispatch = useDispatch()
  const { isShown, message, duration, variant } = useSelector(
    (state) => state?.notification || {},
  )
  const direction = { vertical: 'top', horizontal: 'right' }
  const Icon = variantIcon[variant]
  const notificationDuration =
    !duration && variant === 'error' ? 60000 : duration

  const handleRequestClose = (event, reason) => {
    if (reason === 'clickaway' && variant !== 'error') {
      return
    }
    dispatch(showNotification(false, '', variant))
  }

  return (
    <Snackbar
      anchorOrigin={direction}
      id="globalSnackbar"
      open={isShown}
      onClose={handleRequestClose}
      autoHideDuration={notificationDuration}
    >
      <SnackbarContent
        className={classNames(classes[variant])}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <Icon className={classNames(classes.icon, classes.iconVariant)} />
            <span dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={classes.close}
            onClick={handleRequestClose}
            size="large"
          >
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
      />
    </Snackbar>
  )
}

export default Notifications
