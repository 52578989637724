import React from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment'
import { connect } from 'react-redux'
import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import {
  Card,
  CardHeader,
  CardContent,
  Avatar,
  Grid,
  Grow,
} from '@mui/material'
import { ViewAgenda, Today } from '@mui/icons-material'
import withRouter from '../../containers/Router/WithRouter'
import { selectAssetsByBoard } from '../../store/boards/selector'
import JPGLogo from '../../images/filetypes/image-logo.svg?url'
import NoThumbnail from '../../images/NoThumbnail.svg?url'
import { deleteBoard } from '../../store/boards/actions'
import { createImageURL } from '../../helpers/stringHelper'
import CustomCard from '../../components/Common/Card/CustomCard'
import apiConfig from '../../config/apiConfig'
import { appendQueryParams } from '../../helpers/UrlHelper'

const styles = makeStyles((theme) => ({
  assetBox: {
    height: '100px',
    width: '100px',
    margin: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #EFEFEF',
  },
  avatar: {
    backgroundColor: '#FFF',
  },
  card: {
    margin: 5,
  },
  cardHeaderIcon: {
    // color: useTheme().palette.primary.darkBlue + '!important',
    fontSize: '30px',
  },
  cardContent: {
    padding: '10px',
    justifyContent: 'space-between',
  },
  cardLabel: {
    color: useTheme().palette.primary.lightGrey,
    paddingRight: '4px',
  },
  cardItem: {
    display: 'flex',
    alignItems: 'center',
  },
  itemIcon: {
    paddingRight: '10px',
    color: useTheme().palette.primary.lightGrey,
  },
  media: {
    color: useTheme().palette.primary.darkBlue,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  title: {
    color: useTheme().palette.primary.darkBlue,
    backgroundColor: useTheme().palette.primary.lightestGrey,
  },
  viewBoard: {
    cursor: 'pointer',
  },
  noAssetPadding: {
    paddingLeft: '20px',
  },
}))

export class ListCard extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      deleteBoardEL: null,
    }
  }

  deleteBoardEvent = () => {
    const { board = {}, deleteBoard = () => {} } = this.props
    const { board_id = '', board_name = '' } = board
    this.setState({
      deleteBoardEL: null,
    })
    deleteBoard({ boardName: board_name, boardId: board_id })
  }

  openBoardMenu = (event) => {
    this.setState({ deleteBoardEL: event.currentTarget })
  }

  closeMenu = () => {
    this.setState({ deleteBoardEL: null })
  }

  viewBoard = () => {
    const {
      board: { board_id = '' },
    } = this.props
    let url = '/boards/' + board_id + '/dashboard'
    this.props.router.navigate(url)
  }

  renderCardContent = () => {
    const { classes = {}, board = {} } = this.props
    const { cover_images = [] } = board
    return (
      <div className={classes.media}>
        {cover_images.length ? (
          cover_images.map((coverImage = '') =>
            coverImage ? (
              <div className={classes.assetBox}>
                <img
                  alt={'previewimage'}
                  src={createImageURL(coverImage)}
                  style={{
                    width: 100,
                    height: 100,
                  }}
                  onError={(e) => {
                    e.target.src = NoThumbnail
                  }}
                />
              </div>
            ) : (
              <div className={classes.assetBox}>
                <img
                  alt={'defaultImage'}
                  src={JPGLogo}
                  style={{ height: '70px', width: '70px' }}
                />
              </div>
            )
          )
        ) : (
          <p className={classes.noAssetPadding}>No Assets in this board</p>
        )}
      </div>
    )
  }

  renderFooterContent = () => {
    const { classes = {}, board = {} } = this.props
    const { created_date = '', updated_date = '' } = board

    return (
      <Grid container spacing={2} className={classes.cardContent}>
        <Grid item className={classes.cardItem}>
          <Today className={classes.itemIcon} />
          <span className={classes.cardLabel}>Created On: </span>{' '}
          {created_date ? Moment(created_date).format('ddd, MMM D, YYYY') : ''}
        </Grid>
        <Grid item className={classes.cardItem}>
          <Today className={classes.itemIcon} />
          <span className={classes.cardLabel}>Last Modified:</span>{' '}
          {updated_date ? Moment(updated_date).format('ddd, MMM D, YYYY') : ''}
        </Grid>
      </Grid>
    )
  }

  getCardConfigs = () => {
    const { classes = {}, board = {} } = this.props
    const { board_name: boardName = '', asset_count = Number(0) } = board
    return {
      cardHeader: {
        headerAvatar: (
          <Avatar aria-label="Recipe" className={classes.avatar}>
            <ViewAgenda className={classes.cardHeaderIcon} />
          </Avatar>
        ),
        title: boardName,
        subheader: `${asset_count} assets on board`,
        identifier: '',
      },
      renderCustomFooterContent: true,
      displayFooter: true,
    }
  }

  render() {
    const cardConfig = this.getCardConfigs()
    const { classes = {}, board = {} } = this.props

    return (
      <div className={classes.viewBoard} onClick={this.viewBoard}>
        {!Object.keys(board)?.length ? (
          <Grow in>
            <Card className={classes.card}>No board data found</Card>
          </Grow>
        ) : (
          <CustomCard
            cardConfig={cardConfig}
            cardContent={this.renderCardContent()}
            footerContent={this.renderFooterContent()}
          />
        )}
      </div>
    )
  }
}

ListCard.propTypes = {
  classes: PropTypes.object.isRequired,
  board: PropTypes.shape({
    asset_count: PropTypes.number,
    board_name: PropTypes.string,
    created_date: PropTypes.string,
    updated_date: PropTypes.string,
    board_id: PropTypes.string,
  }).isRequired,
  boardAssets: PropTypes.object.isRequired,
  deleteBoard: PropTypes.func,
}

ListCard.contextTypes = {
  router: PropTypes.object.isRequired,
}

const mapStateToProps = (state, ownProps) => {
  const { board } = ownProps
  const { board_id } = board
  return {
    boardAssets: selectAssetsByBoard(board_id)(state),
  }
}

const MyComponent = (props) => {
  const classes = styles()
  return <ListCard {...props} classes={classes} />
}

export default connect(mapStateToProps, { deleteBoard })(
  withRouter(MyComponent)
)
