import * as React from 'react'
import PropTypes from 'prop-types'
import { TextField, Paper } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import Switch from '@mui/material/Switch'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import Button from '@mui/material/Button'
import FilterNoneOutlinedIcon from '@mui/icons-material/FilterNoneOutlined'
import moment from 'moment'
import { praxis } from '../../config/themeConfig'
import { convertToStringForm } from '../../helpers/usageRightsHelper'
import {
  formatUsageRightsDate,
  formatExpirationDate,
} from '../../helpers/dateHelper'

const styles = makeStyles((theme) => ({
  paper: {
    padding: useTheme().spacing(2),
    textAlign: 'left',
    color: useTheme().palette.text.secondary,
  },
  styleInline: {
    display: 'inline-flex',
    margin: 8,
  },
  marginSpace: {
    marginRight: 4,
    fontWeight: 500,
    color: 'black',
  },
  longText: {
    padding: '16px 0 16px 0',
    fontSize: '15px',
    userSelect: 'none',
  },
  colorSwitchBase: {
    color: 'grey',
    '& + $colorBar': {
      backgroundColor: 'grey',
    },
    '&.Mui-colorChecked': {
      color: praxis.lighterBlue,
      '& + $colorBar': {
        backgroundColor: 'grey',
      },
    },
  },
  colorChecked: {},
  colorBar: {},
}))

export class UsageRightsLanguage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      toggleSwitch: false,
      firstOccur: false,
    }
  }

  componentDidMount = () => {
    const { isCustomUsageRightsLanguage = false } = this.props
    this.setState({
      toggleSwitch: isCustomUsageRightsLanguage ? true : false,
    })
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { isCustomUsageRightsLanguage = false } = this.props
    const { firstOccur = false } = this.state
    if (
      prevProps.isCustomUsageRightsLanguage !== isCustomUsageRightsLanguage &&
      !firstOccur
    ) {
      this.setState({
        toggleSwitch: isCustomUsageRightsLanguage ? true : false,
        firstOccur: true,
      })
    }
  }

  copyToClipboard = (containerid) => {
    const copyText = document.getElementById(containerid).textContent
    this.copyText(copyText)
  }

  copyText = (text) => {
    let el = document.createElement('textarea')
    el.value = text
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
  }

  handleSwitchToggle = () => {
    const { toggleSwitch = false } = this.state
    const { emptyCustomUsageRightsLanguage = () => {} } = this.props
    this.setState({ toggleSwitch: !toggleSwitch })
    emptyCustomUsageRightsLanguage(!toggleSwitch)
  }

  render() {
    const {
      classes = {},
      drmId = '',
      buyer = '',
      isDetailsPage = false,
      recordCreatedDate = '',
      recordUpdatedDate = '',
      updatedBy = '',
      selectedTerm = '',
      isUnlimited = false,
      selectedRightsHolderType = '',
      selectedRightsHolderName = '',
      isWorldwide = false,
      isInternalUsages = false,
      selectedChannels = [],
      isOthers = false,
      otherChannelNotes = '',
      isCheckedFirstUseDate = 'itemlaunchdate',
      durationselectedCount = '',
      durationselected = '',
      selectedDate = moment().format('MMM Do YYYY h:mm:ss a'),
      isOptionForRenewal = false,
      renewalNotes = '',
      customUsageRightsLanguage = '',
      createCustomUsageRightsLanguage = () => {},
    } = this.props
    const { toggleSwitch = false } = this.state
    return (
      <div style={{ display: 'grid' }}>
        <div className={classes.styleInline}>
          <div className={classes.marginSpace}>DRM ID:</div>
          <div>{drmId}</div>
        </div>
        <div className={classes.styleInline}>
          <div className={classes.marginSpace}>Buyer:</div>
          <div>{buyer}</div>
        </div>
        <div className={classes.styleInline}>
          <div className={classes.marginSpace}>Created Date:</div>
          <div>{recordCreatedDate}</div>
        </div>
        <div className={classes.styleInline}>
          <div className={classes.marginSpace}>Last Updated By:</div>
          <div>{updatedBy}</div>
        </div>
        <div className={classes.styleInline}>
          <div className={classes.marginSpace}>Last Updated Date:</div>
          <div>{recordUpdatedDate}</div>
        </div>
        <Paper className={classes.paper}>
          <div className={classes.marginSpace}>Usage Rights Language</div>

          {selectedTerm !== 'Work for Hire/Total Buy Out' && !isUnlimited ? (
            <div className={classes.longText} id="select_txt">
              {drmId ? <span>{drmId} - </span> : null}
              {selectedRightsHolderType !== 'Talent' ? (
                <span>{selectedRightsHolderName}</span>
              ) : (
                <span>Talent</span>
              )}
              - {selectedTerm} for exclusive{' '}
              {isWorldwide ? (
                <span>Worldwide</span>
              ) : (
                <span>North America</span>
              )}{' '}
              usage by Target in {isInternalUsages ? 'Internal Use Only' : null}{' '}
              {!isInternalUsages && selectedChannels.length > 0
                ? isOthers
                  ? convertToStringForm(selectedChannels, '&')
                  : convertToStringForm(selectedChannels)
                : null}{' '}
              {!isInternalUsages && isOthers ? otherChannelNotes : null}{' '}
              {isCheckedFirstUseDate === 'firstusedate' ? (
                <span>
                  through{' '}
                  {formatExpirationDate(
                    selectedDate,
                    durationselected,
                    durationselectedCount
                  )}
                </span>
              ) : (
                <span>
                  for {durationselectedCount} {durationselected} starting with
                  the first use of the asset
                </span>
              )}
              .{' '}
              {!isInternalUsages ? (
                <span>
                  Target retains self-promotion and internal usage rights
                  forever. Any assets licensed and used online must be published
                  prior to{' '}
                  {isCheckedFirstUseDate === 'firstusedate' && selectedDate ? (
                    <span>
                      {formatExpirationDate(
                        selectedDate,
                        durationselected,
                        durationselectedCount
                      )}
                    </span>
                  ) : (
                    <span>expiration date</span>
                  )}
                  , but Target does not have an obligation to remove once
                  published. Target requires first option of reuse upon
                  expiration.
                </span>
              ) : null}{' '}
              {!isUnlimited && isOptionForRenewal ? (
                <span>
                  Target requires an option for renewal: {renewalNotes}
                </span>
              ) : null}
            </div>
          ) : (
            <div className={classes.longText} id="select_txt2">
              {drmId ? <span>{drmId} - </span> : null}
              {selectedRightsHolderType !== 'Talent' ? (
                <span>{selectedRightsHolderName}</span>
              ) : (
                <span>Talent</span>
              )}{' '}
              - {selectedTerm} for exclusive Unlimited{' '}
              {isWorldwide ? (
                <span>Worldwide</span>
              ) : (
                <span>North America</span>
              )}{' '}
              usage by Target in {isInternalUsages ? 'Internal Use Only' : null}{' '}
              {!isInternalUsages && selectedChannels.length > 0
                ? isOthers
                  ? convertToStringForm(selectedChannels, '&')
                  : convertToStringForm(selectedChannels)
                : null}{' '}
              {!isInternalUsages && isOthers ? otherChannelNotes : null}. Target
              retains self-promotion and internal usage rights forever.{' '}
              {!isUnlimited && isOptionForRenewal ? (
                <span>
                  Target requires an option for renewal: {renewalNotes}
                </span>
              ) : null}
            </div>
          )}
          <Button
            data-cy={!isDetailsPage ? 'copyButtonDisabled' : 'copyButton'}
            style={{
              color: isDetailsPage ? '#0D46A0' : 'default',
              border: isDetailsPage ? '1px solid #0D46A0' : '1px solid default',
              left: '44%',
              marginBottom: '100px',
            }}
            variant="outlined"
            color="primary"
            disabled={!isDetailsPage}
            startIcon={<FilterNoneOutlinedIcon />}
            onClick={
              !isDetailsPage
                ? () => {}
                : () => {
                    const containerid =
                      selectedTerm !== 'Work for Hire/Total Buy Out' &&
                      !isUnlimited
                        ? 'select_txt'
                        : 'select_txt2'
                    this.copyToClipboard(containerid)
                  }
            }
          >
            COPY RECORD
          </Button>
          {!isDetailsPage && (
            <div className={classes.styleInline}>
              <div className={classes.marginSpace}>Note:</div>
              <div>
                {' '}
                In order to copy this statement, you must finish creating the
                record.
              </div>
            </div>
          )}
        </Paper>
        <div style={{ marginTop: '10px' }}>
          <FormControl variant="standard" component="fieldset">
            <FormGroup aria-label="position" row>
              <FormControlLabel
                value="start"
                control={
                  <Switch
                    color="primary"
                    classes={{
                      switchBase: classes.colorSwitchBase,
                      checked: classes.colorChecked,
                      track: classes.colorBar,
                    }}
                    checked={toggleSwitch}
                    onChange={this.handleSwitchToggle}
                    disabled={isDetailsPage}
                  />
                }
                label="Use Custom Usage Rights Language"
                labelPlacement="start"
              />
            </FormGroup>
          </FormControl>
          <Button
            style={{
              float: 'right',
              marginTop: '4px',
              visibility: toggleSwitch ? 'visible' : 'hidden',
            }}
            color="primary"
            disabled={customUsageRightsLanguage === ''}
            startIcon={<FilterNoneOutlinedIcon />}
            onClick={() => {
              this.copyToClipboard('customLang')
            }}
          >
            COPY CUSTOM USAGE RIGHTS
          </Button>
        </div>
        <TextField
          id="customLang"
          label="Custom Usage Rights Language"
          multiline
          rows="8"
          value={toggleSwitch ? customUsageRightsLanguage : ''}
          margin="normal"
          variant="outlined"
          placeholder="Max 1000 characters"
          inputProps={{ maxLength: 1000 }}
          onChange={createCustomUsageRightsLanguage}
          disabled={!toggleSwitch || isDetailsPage}
        />
      </div>
    )
  }
}

UsageRightsLanguage.propTypes = {
  classes: PropTypes.object.isRequired,
  userEmail: PropTypes.string,
  drmId: PropTypes.string,
  buyer: PropTypes.string,
  isDetailsPage: PropTypes.bool,
  recordCreatedDate: PropTypes.string,
  selectedTerm: PropTypes.string,
  isUnlimited: PropTypes.bool,
  selectedRightsHolderType: PropTypes.string,
  selectedRightsHolderName: PropTypes.string,
  isWorldwide: PropTypes.bool,
  isInternalUsages: PropTypes.bool,
  selectedChannels: PropTypes.object,
  isOthers: PropTypes.bool,
  otherChannelNotes: PropTypes.string,
  isCheckedFirstUseDate: PropTypes.string,
  durationselectedCount: PropTypes.string,
  durationselected: PropTypes.string,
  selectedDate: PropTypes.object,
  isOptionForRenewal: PropTypes.bool,
  renewalNotes: PropTypes.string,
}

const MyComponent = (props) => {
  const classes = styles()
  return <UsageRightsLanguage {...props} classes={classes} />
}

export default MyComponent
