import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { Select, MenuItem, FormControl, Grid, InputLabel } from '@mui/material'
import { withStyles } from '@mui/styles'
import GroupAdd from '@mui/icons-material/GroupAdd'
import {
  changeSelectedChannel,
  getSelectedChannelDetails,
  resetChannelAssetParams,
} from '../../../store/channelUpload/channelsActionCreator'
import { activateAddChannel } from '../../../store/channelUpload/addChannelActionCreator'

const styles = (theme) => ({
  teamSelector: {
    marginTop: '10px',
    marginLeft: '10px',
  },
  formControl: {
    minWidth: 400,
    borderColor: 'black',
  },
  createNewTeam: {
    color: 'white',
    background: '#1363B2',
    '&:hover': {
      color: 'white',
      background: '#1363B2',
    },
  },
  marginLeft10: {
    marginLeft: '10px',
  },
})

function ChannelDetails({
  classes = {},
  channels = [],
  selectedChannel = {},
  sortField = 'CREATED_AT',
  assetFilter = '',
  sortDirection = 'DESC',
  defaultAssetPageSize = Number(10),
  currentAssetPage = Number(0),
  changeSelectedChannel = () => {},
  getSelectedChannelDetails = () => {},
  activateAddChannel = () => {},
  resetChannelAssetParams = () => {},
}) {
  const { channel_id: selectedChannelId = '' } = selectedChannel

  useEffect(() => {
    if (!isEmpty(selectedChannel)) {
      getSelectedChannelDetails({
        channelId: selectedChannelId,
        pageNumber: currentAssetPage,
        pageSize: defaultAssetPageSize,
        sortDirection,
        sortBy: sortField,
        assetFilter,
      })
    }
  }, [])

  const sortedChannels = [].concat(channels).sort((a, b) => {
    const { channel_name: teamA = '' } = a
    const { channel_name: teamB = '' } = b
    return teamA.localeCompare(teamB)
  })

  const changeSelectedChannelHandler = (event) => {
    const { target = {} } = event
    const { value: eventValue = '' } = target
    const filteredChannel =
      channels.filter((team) => {
        const { channel_id = '' } = team
        return channel_id === eventValue
      })[0] || {}
    if (eventValue === 'new') {
      activateAddChannel()
      return false
    }
    if (!isEmpty(filteredChannel)) {
      const { channel_id = '' } = filteredChannel
      changeSelectedChannel(filteredChannel)
      getSelectedChannelDetails({
        channelId: channel_id,
      })
      resetChannelAssetParams()
    }
  }
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="stretch"
        className={classes.teamSelector}
        title="test"
      >
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
        >
          <FormControl variant="standard" className={classes.formControl}>
            <InputLabel>Channel</InputLabel>
            <Select
              variant="standard"
              value={selectedChannelId}
              onChange={(event) => changeSelectedChannelHandler(event)}
            >
              <MenuItem value="new" className={classes.createNewTeam}>
                <GroupAdd />
                <span className={classes.marginLeft10}>Create New Channel</span>
              </MenuItem>
              {sortedChannels.map((channelList) => {
                const { channel_id = '', channel_name = '' } = channelList
                return (
                  <MenuItem key={channel_id} value={channel_id}>
                    {channel_name}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </>
  )
}

const mapStateToProps = (state = {}) => {
  const { channelPublish = {} } = state
  const {
    channels = [],
    selectedChannel = {},
    sortField = 'CREATED_AT',
    assetFilter = '',
    sortDirection = 'DESC',
    defaultAssetPageSize = Number(10),
    currentAssetPage = Number(0),
  } = channelPublish
  return {
    channels,
    selectedChannel,
    sortField,
    assetFilter,
    sortDirection,
    defaultAssetPageSize,
    currentAssetPage,
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeSelectedChannel,
      getSelectedChannelDetails,
      resetChannelAssetParams,
      activateAddChannel,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ChannelDetails))
