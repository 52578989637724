import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import {
  Card,
  Grow,
  FormHelperText,
  Button,
  CircularProgress,
} from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import CheckIcon from '@mui/icons-material/Check'
import { selectAssetsByBoard } from '../../store/boards/selector'
import JPGLogo from '../../images/filetypes/image-logo.svg?url'
import LightProgress from '../../images/lightprogress.svg?url'
import { createImageURL } from '../../helpers/stringHelper'
import NoThumbnail from '../../images/NoThumbnail.svg?url'

const styles = makeStyles((theme) => ({
  assetBox: {
    height: '40px',
    width: '40px',
    marginRight: 12,
    display: 'flex',
    justifyContent: 'center',
    border: '1px solid ' + useTheme().palette.primary.lightestGrey,
  },
  boardBox: {
    padding: '2px',
    margin: '8px',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#f5f5f5',
    },
  },
  avatar: {
    backgroundColor: '#FFF',
  },
  avatarIcon: {
    color: useTheme().palette.primary.darkBlue,
    fontSize: '30px',
  },
  card: {
    margin: 5,
  },
  cardHeaderIcon: {
    color: useTheme().palette.primary.darkBlue + '!important',
    fontSize: '30px',
  },
  cardContent: {
    backgroundColor: useTheme().palette.primary.lightestGrey,
    padding: '10px',
  },
  cardLabel: {
    color: useTheme().palette.primary.lightGrey,
    paddingRight: '4px',
  },
  cardItem: {
    display: 'flex',
    alignItems: 'center',
  },
  itemIcon: {
    paddingRight: '10px',
    color: useTheme().palette.primary.lightGrey,
  },
  media: {
    color: useTheme().palette.primary.lightGrey,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '10px',
  },
  title: {
    color: useTheme().palette.primary.darkBlue,
    backgroundColor: useTheme().palette.primary.lightestGrey,
    cursor: 'pointer',
  },
  assetImage: {
    height: '40px',
    width: '40px',
  },
  boardTitle: {
    textAlign: 'left',
    fontSize: 15,
    margin: '10px',
    cursor: 'pointer',
    '&:hover': {
      color: '#0D46A0',
      textDecoration: 'underline',
    },
  },
  addButton: {
    maxHeight: '30px',
    backgroundColor: 'white',
    border: '1px solid #188295',
    visibility: 'hidden',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  addButtonHover: {
    maxHeight: '30px',
    backgroundColor: 'white',
    border: '1px solid #188295',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  addButtonHoverDisable: {
    maxHeight: '30px',
    opacity: 0.4,
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  addIcon: {
    color: '#188295',
    marginRight: 5,
    fontSize: 'initial',
  },
  addText: {
    color: '#188295',
  },
  addButtonUndo: {
    maxHeight: '30px',
  },
  undoIcon: {
    width: '13px',
    marginRight: '5px',
    color: '#188295',
  },
  uploadIcon: {
    color: '#188295',
    width: '16px',
    marginRight: '5px',
  },
  uploadText: {
    color: '#188295',
    fontSize: '12px',
    marginTop: '4px',
  },
  extraSpace: { margin: '10px' },
}))

let timer, progressTimer

export class BoardsAssets extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isHover: false,
      isUndo: false,
      progress: 0,
    }
  }

  onHoverEnter = () => {
    this.setState({ isHover: true })
  }
  onHoverLeave = () => {
    this.setState({ isHover: false })
  }
  handleAddToBoard = (ev, boardId = '') => {
    const { onAddToBoard = () => {} } = this.props
    this.setState({ isUndo: true, progress: 10 }, () => {
      progressTimer = setInterval(() => {
        this.setState((state) => ({ progress: state.progress + 30 }))
      }, 800)
      timer = setTimeout(() => {
        this.setState({ isUndo: null, progress: 0 }, () => {
          clearInterval(progressTimer)
          onAddToBoard(boardId)
        })
      }, 3000)
    })
  }
  cancelAddToBoard = () => {
    clearTimeout(timer)
    clearInterval(progressTimer)
    this.setState({ isUndo: false, progress: 0 })
  }

  handleClickBoardName = () => {
    const { router = {}, board = {} } = this.props
    const { board_id = null } = board
    router.navigate(`/boards/${board_id}/dashboard`)
    window.location.reload()
  }

  render() {
    const {
      classes = {},
      board = {},
      selectedBoards = [],
      theme,
      isUploadSuccessfull = false,
      boardID = null,
      isAssetSelected = false,
      history = {},
      router = {},
    } = this.props
    const {
      board_name: boardName = '',
      board_id = '',
      cover_images = [],
      asset_count = Number(0),
    } = board
    const { isHover = false, isUndo = false, progress = 0 } = this.state

    return (
      <div>
        {!Object.keys(board).length ? (
          <Grow in>
            <Card className={classes.card}>No boards found</Card>
          </Grow>
        ) : (
          <Grow in>
            <div
              className={classes.boardBox}
              onMouseEnter={this.onHoverEnter}
              onMouseLeave={this.onHoverLeave}
            >
              <p
                className={classes.boardTitle}
                onClick={this.handleClickBoardName}
              >
                {boardName}
              </p>
              <div className={classes.media}>
                <div>
                  {cover_images.length > 0 ? (
                    <div style={{ display: 'flex' }}>
                      {cover_images.slice(0, 3).map((coverImage = {}) =>
                        coverImage ? (
                          <div className={classes.assetBox}>
                            <img
                              src={createImageURL(coverImage)}
                              onError={(e) => {
                                e.target.src = NoThumbnail
                              }}
                            />
                          </div>
                        ) : (
                          <div className={classes.assetBox}>
                            <img src={JPGLogo} className={classes.assetImage} />
                          </div>
                        )
                      )}
                      {asset_count > 3 ? (
                        <span className={classes.extraSpace}>
                          +{asset_count - 3}
                        </span>
                      ) : null}
                    </div>
                  ) : (
                    <FormHelperText className={classes.extraSpace}>
                      This board is empty.
                    </FormHelperText>
                  )}
                </div>
                {isUploadSuccessfull && boardID === board_id ? (
                  <div style={{ display: 'flex' }}>
                    <CheckIcon className={classes.uploadIcon} />
                    <div className={classes.uploadText}>Added!</div>
                  </div>
                ) : isUndo === null ? null : !isUndo ? (
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    className={
                      isHover
                        ? isAssetSelected
                          ? classes.addButtonHover
                          : classes.addButtonHoverDisable
                        : classes.addButton
                    }
                    onClick={
                      isAssetSelected
                        ? (event) => this.handleAddToBoard(event, board_id)
                        : () => {}
                    }
                  >
                    <AddCircleIcon className={classes.addIcon} />{' '}
                    <span className={classes.addText}>ADD</span>
                  </Button>
                ) : (
                  <div
                    className={classes.addButtonUndo}
                    onClick={this.cancelAddToBoard}
                  >
                    <CircularProgress
                      variant="determinate"
                      value={progress}
                      style={{ width: '13px', height: '13px' }}
                      className={classes.undoIcon}
                    />
                    <span className={classes.addText}>UNDO</span>
                  </div>
                )}
              </div>
            </div>
          </Grow>
        )}
      </div>
    )
  }
}

BoardsAssets.propTypes = {
  classes: PropTypes.object.isRequired,
  board: PropTypes.object.isRequired,
  boardAssets: PropTypes.object.isRequired,
  onChecked: PropTypes.func.isRequired,
  selectedBoards: PropTypes.array,
  theme: PropTypes.object,
}

const mapStateToProps = (state, ownProps) => {
  const { board } = ownProps
  const { board_id } = board
  return {
    boardAssets: selectAssetsByBoard(board_id)(state),
  }
}

const MyComponent = (props) => {
  const classes = styles()
  return <BoardsAssets {...props} classes={classes} />
}

export default connect(mapStateToProps)(MyComponent)
