import React, { useState, useEffect } from 'react'
import { makeStyles } from '@mui/styles'
import { Grid } from '@mui/material'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import StepContent from '@mui/material/StepContent'
import StepConnector from '@mui/material/StepConnector'
import { useEnv } from '@praxis/component-runtime-env'

const useStyles = makeStyles({
  contentContainer: {
    padding: '2% 10%',
    display: 'flex',
  },
  carouselContainer: {
    padding: '0% 5%',
    display: 'flex',
    height: '95%',
  },
  coloredContainer: {
    width: '50% !important',
    background: '#fcf9fb',
    borderRadius: '10px',
    padding: '40px',
  },
  nonColoredContainer: {
    width: '50% !important',
    padding: '40px',
  },
  stepperContent: {
    display: 'block',
    borderColor: '#979797',
    borderLeftStyle: 'dashed',
    borderLeftWidth: '1px',
    minHeight: '24px',
  },
  stepLabel: {
    marginTop: '-30px',
    fontSize: '20px',
  },
  stepLabelRoot: {
    gap: 15,
  },
  stepText: {
    fontWeight: '300',
    fontSize: '16px',
    lineHeight: '26px',
    width: '115%',
  },
  stepIcon: {
    color: '#CC0000 !important',
    width: '60px',
    height: '60px',
  },
  stepIconText: {
    fontSize: 8,
  },
  stepContent: {
    paddingLeft: '52px',
    marginLeft: '30px',
    width: (props) => (props.featureType ? '100%' : 'inherit'),
    marginTop: '-35px',
    borderLeft: '1px dashed #979797 !important',
  },
  stepContentLast: {
    paddingLeft: '52px',
    marginLeft: '30px',
    width: (props) => (props.featureType ? '100%' : 'inherit'),
    marginTop: '-20px',
    borderLeft: 'none !important',
  },
  stepConnectorLine: {
    borderColor: '#979797',
    marginLeft: '18px',
    borderLeftStyle: 'dashed',
  },
  stepContainer: {
    padding: '40px 0px 0px 10px',
  },
  stepItem: {
    marginTop: '-10px',
  },
  featureTitle: {
    fontWeight: '700',
    fontFamily: 'Raleway',
    lineHeight: '52px',
    letterSpacing: '1px',
    fontSize: '40px',
  },
})

function AHFeatureUpdatesPage({ featureType = '' }) {
  const classes = useStyles({ featureType })
  const envConfig = useEnv() || {}
  const { FEATURES_CONFIG = {} } = envConfig
  const [featureUpdatesObj, setFeatureUpdatesObj] = useState(FEATURES_CONFIG)

  useEffect(() => {
    if (featureType) {
      const newFeatures = { ...featureUpdatesObj[featureType] }
      setFeatureUpdatesObj({ [featureType]: newFeatures })
    }
  }, [featureType])

  return (
    <Grid
      container
      spacing={2}
      className={
        featureType ? classes.carouselContainer : classes.contentContainer
      }
    >
      {Object.keys(featureUpdatesObj).map((fKey) => {
        const currentObj = featureUpdatesObj[fKey]
        const { title = '', features = [] } = currentObj
        return (
          <Grid
            item
            className={
              featureType
                ? ''
                : fKey === 'planned'
                ? classes.coloredContainer
                : classes.nonColoredContainer
            }
          >
            <Grid container direction={'column'}>
              <Grid item>
                <Typography variant="h4" className={classes.featureTitle}>
                  {title}
                </Typography>
              </Grid>
              <Grid item>
                <Box sx={{ maxWidth: 494 }}>
                  {
                    <Stepper
                      className={classes.stepContainer}
                      orientation="vertical"
                      connector={
                        <StepConnector
                          classes={{
                            line: classes.stepConnectorLine,
                          }}
                        />
                      }
                    >
                      {features.map((step, index) => (
                        <Step
                          expanded={true}
                          disabled
                          key={step.title}
                          active
                          key={index}
                        >
                          <StepLabel
                            StepIconProps={{
                              classes: {
                                root: classes.stepIcon,
                                text: classes.stepIconText,
                              },
                            }}
                            classes={{
                              active: classes.stepLabel,
                              root: classes.stepLabelRoot,
                            }}
                          >
                            {step.title}
                          </StepLabel>
                          <StepContent
                            TransitionProps={{ unmountOnExit: false }}
                            last={false}
                            classes={{
                              root: classes.stepContent,
                              last: classes.stepContentLast,
                            }}
                          >
                            <Typography className={classes.stepText}>
                              {step.content}
                            </Typography>
                          </StepContent>
                        </Step>
                      ))}
                    </Stepper>
                  }
                </Box>
              </Grid>
            </Grid>
          </Grid>
        )
      })}
    </Grid>
  )
}

export default AHFeatureUpdatesPage
