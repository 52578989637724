import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import { Grid } from '@mui/material'
import { get } from 'lodash'
import { getPage, formatAssetData } from '../../../helpers/BoardsHelper'

import AssetCard from '../../../components/Search/AssetCard'
import VideoAssetCard from '../../../components/Search/VideoAssetCard'

const styles = {
  listWrapper: {
    width: '100%',
  },
  paginationContainer: {
    paddingRight: 30,
  },
}

export const BoardAssetList = ({
  searchAssetMetadata = () => {},
  onSelection = () => {},
  downloadAsset = () => {},
  classes = {},
  assetList = [],
  selectedItems = [],
  page = 0,
  rowsPerPage = 10,
  checkAll,
}) => (
  <div className={classes.listWrapper}>
    <Grid container spacing={2} justifyContent={'space-around'}>
      {formatAssetData(getPage(assetList, page, rowsPerPage)).map(
        (asset = {}, index) => {
          const fileType = get(asset, 'metadata.file_metadata.file_type', '')
          const { assetId = '' } = asset
          const currentKey = assetId || index

          if (['mp4', 'mov', 'gif'].includes(fileType.toLowerCase())) {
            return (
              <Grid item key={currentKey} style={{ position: 'relative' }}>
                <VideoAssetCard
                  asset={asset}
                  highlights={{}}
                  onSelection={onSelection}
                  selectedItems={selectedItems}
                  onClickDownloadAsset={() => {
                    downloadAsset(asset)
                  }}
                  openModal={() => searchAssetMetadata(assetList, index)}
                  checkAll={checkAll}
                  fileType={fileType}
                />
              </Grid>
            )
          } else {
            return (
              <Grid item key={currentKey} style={{ position: 'relative' }}>
                <AssetCard
                  asset={asset}
                  highlights={{}}
                  onSelection={onSelection}
                  selectedItems={selectedItems}
                  checkAll={checkAll}
                  openModal={() => searchAssetMetadata(assetList, index)}
                  onClickDownloadAsset={() => {
                    downloadAsset(asset)
                  }}
                />
              </Grid>
            )
          }
        }
      )}
    </Grid>
  </div>
)

BoardAssetList.propTypes = {
  classes: PropTypes.object.isRequired,
  assetList: PropTypes.array,
  searchAssetMetadata: PropTypes.func,
  onSelection: PropTypes.func,
  selectedItems: PropTypes.array,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  checkAll: PropTypes.bool,
  downloadAsset: PropTypes.func,
}

export default withStyles(styles)(BoardAssetList)
