import { Suspense } from 'react'
import { MicroFrontend } from '@praxis/component-microfrontend'
import { makeStyles } from '@mui/styles'
import { CircularProgress, Grid } from '@mui/material'
import MFEConfig from '../../../config/mfeConfig'
import store from '../../../store/configureStore'

// const store = configureStore
const useStyles = makeStyles({
  circularSpace: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    height: '300px',
  },
})

const MFELoader = () => {
  const classes = useStyles()
  return (
    <Grid className={classes.circularSpace}>
      <CircularProgress />
    </Grid>
  )
}

const SearchMFE = () => (
  <MicroFrontend
    src={MFEConfig.remotes.ahsearch}
    store={store}
    module={'./Search'}
    Loading={MFELoader}
  ></MicroFrontend>
)

export default SearchMFE
