import axios from 'axios'
import apiConfig from '../config/apiConfig'

export function updateUsers(userId, updateRequest = {}) {
  return axios.put(
    `${apiConfig.userDetails.userUpdateUrl}/${userId}`,
    updateRequest,
    {
      headers: { 'Content-type': 'application/json' },
      params: {
        key: apiConfig.key,
      },
      responseType: 'blob',
    }
  )
}
