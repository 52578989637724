import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { withStyles } from '@mui/styles'
import Grid from '@mui/material/Grid'
import ExpandIcon from '@mui/icons-material/ExpandMore'
import Button from '@mui/material/Button'
import withRouter from '../../containers/Router/WithRouter'

import { ListCard } from './ListCard'

const styles = {
  projectListing: {
    textAlign: 'center',
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  gridList: {
    justifyContent: 'center',
    width: '100%',
    maxWidth: '1160px',
  },
  listCard: { flexFlow: 'row wrap', display: 'flex' },
}

class ImageGridList extends React.Component {
  constructor() {
    super()
    this.state = {
      generatedList: [],
      groupsPerPage: 8,
      currentPage: 1,
      allPageNum: 0,
      maxPage: 2,
    }
    this.handleClick = this.handleClick.bind(this)
  }

  // generate pagination
  handleClick() {
    let current = this.state.currentPage + 1
    this.setState({
      currentPage: current,
    })
  }

  render() {
    const { classes, history, groups, router = {} } = this.props
    let list = groups.groupList || []
    if (this.props.activeTabIndex === 1) {
      list = groups.groupShareList
    }
    const generatedList = list.filter(function (obj) {
      return obj.show === undefined || obj.show === true
    })
    // calculate pagination
    const indexOfLastProject = this.state.currentPage * this.state.groupsPerPage
    const indexOfFirstProject =
      this.state.indexOfLastProject - this.state.groupsPerPage
    const currentProject =
      this.state.currentPage <= this.state.maxPage
        ? generatedList.slice(indexOfFirstProject, indexOfLastProject)
        : generatedList
    const totalPageNum = Math.ceil(
      generatedList.length / this.state.groupsPerPage
    )

    return (
      <div>
        <div className={classes.root}>
          <Grid container spacing={2} style={styles.gridList}>
            {currentProject.map((list, key) => (
              <Grid
                item
                md={6}
                xs={12}
                key={list.list_id}
                className={classes.listCard}
              >
                <ListCard
                  history={history}
                  router={router}
                  groups={list}
                  key={key}
                  tabActive={this.props.tabActive}
                />
              </Grid>
            ))}
          </Grid>
        </div>

        {this.state.currentPage < totalPageNum &&
          this.state.currentPage <= this.state.maxPage && (
            <Grid
              container
              justifyContent="center"
              spacing={1}
              direction="row"
              style={{ marginTop: '10px' }}
            >
              <Button
                aria-label="Delete"
                onClick={this.handleClick}
                className={classes.button}
              >
                <ExpandIcon />
                {this.state.currentPage === this.state.maxPage
                  ? 'Show All'
                  : 'Load More'}
              </Button>
            </Grid>
          )}
      </div>
    )
  }
}

ImageGridList.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  classes: PropTypes.object.isRequired,
  groups: PropTypes.object,
  activeTabIndex: PropTypes.number,
  tabActive: PropTypes.bool,
}

const mapStateToProps = (state = {}) => {
  const { groups = {} } = state
  return {
    groups: groups,
  }
}
export default connect(mapStateToProps)(
  withStyles(styles)(withRouter(ImageGridList))
)
