import React from 'react'
import axios from 'axios'
import queryString from 'query-string'
import Moment from 'moment-timezone'

export const downloadAsset = async (
  fileName = '',
  filePath = '',
  fileExtension = 'jpg'
) => {
  const blobResponse = await axios.get(
    `${filePath}?key=${process.env.REACT_APP_DAP_KEY}&${queryString.stringify({
      fileName: fileName,
      is_download: true,
      content_type: 'Preview',
    })}`,
    {
      responseType: 'blob',
    }
  )
  if (blobResponse) {
    const {
      URL: { createObjectURL = () => {} },
    } = window
    const { data = {} } = blobResponse
    const downloadUrl = createObjectURL(new Blob([data]))
    const downloadEl = document.createElement('a')
    downloadEl.href = downloadUrl
    downloadEl.setAttribute('download', `${fileName}.${fileExtension}`)
    document.body.appendChild(downloadEl)
    downloadEl.click()
    document.body.removeChild(downloadEl)
  }
}

export const appendQueryParams = (theUrl = '', theParams = {}) => {
  const delimiter = theUrl.indexOf('?') === -1 ? '?' : '&'
  return `${theUrl}${delimiter}${queryString.stringify(theParams)}`
}

export const mapFiltersToSelectedItems = (filters = {}) => {
  let selectedItems = []
  const { dateFrom = '', dateTo = '' } = filters
  const publisherEmails = filters['publishers.email']
    ? [...filters['publishers.email']]
    : []
  const publisherFileExtensions = filters['publishers.file_extension']
    ? [...filters['publishers.file_extension']]
    : []
  const publisherUserId = filters['publishers.user_id']
    ? [...filters['publishers.user_id']]
    : []
  const metaContentTypes = filters['metadata.content_type']
    ? [...filters['metadata.content_type']]
    : []
  const assetTypes = filters['asset_type'] ? [...filters['asset_type']] : []

  if (publisherEmails && publisherEmails.length) {
    publisherEmails.map((item) => {
      let dataLabel = { label: 'publishers.email', value: item }
      selectedItems.push(
        <div data={dataLabel}>
          <strong>{'Email'}:</strong> {item}
        </div>
      )
    })
  }
  if (publisherFileExtensions && publisherFileExtensions.length) {
    publisherFileExtensions.map((item) => {
      let dataLabel = { label: 'publishers.file_extension', value: item }
      selectedItems.push(
        <div data={dataLabel}>
          <strong>{'File'}:</strong> {item}
        </div>
      )
    })
  }
  if (publisherUserId && publisherUserId.length) {
    publisherUserId.map((item) => {
      let dataLabel = { label: 'publishers.user_id', value: item }
      selectedItems.push(
        <div data={dataLabel}>
          <strong>{'User'}:</strong> {item}
        </div>
      )
    })
  }
  if (metaContentTypes && metaContentTypes.length) {
    metaContentTypes.map((item) => {
      let dataLabel = { label: 'metadata.content_type', value: item }
      selectedItems.push(
        <div data={dataLabel}>
          <strong>{'Content'}:</strong> {item}
        </div>
      )
    })
  }
  if (assetTypes && assetTypes.length) {
    assetTypes.map((item) => {
      let dataLabel = { label: 'asset_type', value: item }
      selectedItems.push(
        <div data={dataLabel}>
          <strong>{'Asset'}:</strong> {item}
        </div>
      )
    })
  }
  if (dateFrom && dateTo) {
    let dataLabel = { label: 'date' }
    let formattedDate =
      Moment(dateFrom).format('MM-DD-YYYY') +
      ' to ' +
      Moment(dateTo).format('MM-DD-YYYY')
    selectedItems.push(
      <div data={dataLabel}>
        <strong>{'Date'}:</strong> {formattedDate}
      </div>
    )
  }
  return selectedItems
}
