import React from 'react'
import PropTypes from 'prop-types'

import Assets from './assets'
import Dashboard from './dashboard'
import ProjectsPage from './../../ProjectsPage'
import TaskPage from './../../TaskPage'
import TaskInfo from './../../TaskPage/Info'
import withRouter from '../../../containers/Router/WithRouter'

export const NestedProjectViews = (props) => (
  <div>
    <SubView {...props} />
  </div>
)

NestedProjectViews.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
  }),
}

const subViewList = {
  tasks: { list: TaskPage, create: TaskInfo, edit: TaskInfo },
  groups: { list: 'component', create: 'component', edit: 'component' },
}
const SubView = (props) => {
  let SubViewPage = ProjectsPage
  let pageName = ''
  let subPageName = ''
  const { router = {} } = props
  const { params = {} } = router
  if (params) {
    pageName = params.pageName || ''
    subPageName = (params['*'] || '').match(/^[^/?]*/)[0] || ''
    switch (params.pageName) {
      case 'dashboard':
        SubViewPage = subPageName
          ? subViewList[pageName][subPageName] || Dashboard
          : Dashboard
        break
      case 'tasks':
        SubViewPage = subPageName
          ? subViewList[pageName][subPageName] || TaskPage
          : TaskPage
        break
      case 'assets':
        SubViewPage = subPageName
          ? subViewList[pageName][subPageName] || Assets
          : Assets
        break
      case 'metaPage':
        SubViewPage = Assets
        break
      default:
        SubViewPage = ProjectsPage
    }
  }
  return (
    <div>
      <SubViewPage
        projectId={params.projectId}
        subPageName={subPageName}
        breadcrumb=""
        {...props}
      />
    </div>
  )
}

SubView.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      pageName: PropTypes.string,
      projectId: PropTypes.string,
    }),
  }),
}

export default withRouter(NestedProjectViews)
