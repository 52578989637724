import React from 'react'
import Moment from 'moment'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
  adaptV4Theme,
  useTheme,
} from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import IconButton from '@mui/material/IconButton'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp'
import CircularProgress from '@mui/material/CircularProgress'
import EventIcon from '@mui/icons-material/Event'
import OpenInNew from '@mui/icons-material/OpenInNew'
import Button from '@mui/material/Button'
import Done from '@mui/icons-material/Done'
import EditIcon from '@mui/icons-material/Edit'
import MoreHoriz from '@mui/icons-material/MoreHoriz'
import CropSquare from '@mui/icons-material/CropSquare'
import LinearProgress from '@mui/material/LinearProgress'
import Grid from '@mui/material/Grid'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { concat, get } from 'lodash'
import TaskTab from './TaskTab'
import TaskReview from './TaskReview'
import { UserRoleIcon } from './components/UserRoleIcon'

import apiConfig from '../../config/apiConfig'
import { isIE } from '../../helpers/navigatorHelper'
import {
  ADMIN_USER_ROLE_NAME,
  REVIEWER_ROLE_NAME,
  OBSERVER_ROLE_NAME,
  UPLOAD_TASK_TYPE_ID,
  REVIEW_TASK_TYPE_ID,
  UPLOADER_ROLE_NAME,
  REPORT_COMPLETE,
  REPORT_IN_PROCESS,
  REPORT_ERROR,
} from '../../constants/projectTask'
import { fetchTaskDetails } from './../../store/task/actionCreator'
import { isTaskReportGenerationInProgress } from '../../helpers/TaskHelper'
import withRouter from '../../containers/Router/WithRouter'

const themeRow = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: 5,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:last-child > td ': {
            borderBottom: 0,
          },
        },
      },
    },
  },
})
const styles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: useTheme().spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 1020,
  },
  fab: {
    position: 'absolute',
    backgroundColor: '#FC3',
    color: '#FFF',
    boxShadow: 'none',
  },
  participantIcon: {
    marginRight: 6,
    height: 56,
    width: 56,
    borderRadius: 40,
    position: 'relative',
  },
  rowImage: {
    minWidth: 60,
    height: 65,
  },
  userDisplayName: {
    position: 'absolute',
    marginTop: 20,
  },
  groupUsers: {
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
  },
  groupRow: {
    backgroundColor: '#FFFFF',
  },
  row: {
    display: 'flex',
    marginLeft: -50,
    position: 'relative',
  },
  actionRow: {
    width: 20,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  done: {
    color: '#4caf50',
  },
  doneDate: {
    position: 'relative',
    left: 25,
  },
  notDone: {
    color: '#cc0000',
  },
  progress: {
    color: '#cab34f',
  },
  progressBar: {
    color: '#000',
    marginTop: 10,
  },
  leftIcon: {
    top: 8,
    position: 'relative',
  },
  leftIconButton: {
    marginRight: 10,
  },
  button: {
    backgroundColor: '#188295',
    '&:hover': {
      backgroundColor: '#188295',
    },
  },
  expandButton: {
    color: '#188295',
  },
  buttonEdit: {
    color: '#188295',
    backgroundColor: '#efefef',
    '&:hover': {
      backgroundColor: '#efefef',
    },
  },
  report: {
    padding: 0,
    color: '#188295',
    '&:hover': {
      backgroundColor: '#FFF',
    },
  },
  generatingReportContainer: {
    position: 'relative',
  },
  downloadingReport: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  generatingReportProgress: {
    marginRight: 10,
  },
  generatingReportText: {
    position: 'relative',
    top: 2,
  },
  reportGeneratedDate: {
    display: 'inline-block',
    paddingTop: 10,
  },
  userIcon: {
    color: '#757575',
    top: 8,
    position: 'relative',
  },
  buttonProgress: {
    margin: 10,
  },
}))

export class TaskList extends React.Component {
  constructor(props) {
    super(props)
    this.setIntervalId = 0
    this.state = {
      orderBy: 'due_date',
      order: 'asc',
      open: false,
      selectedTask: {},
      openReview: false,
      taskStatusList: {
        1002: 'In Progress',
        1003: 'Completed',
      },
      projectId: '',
      startPoll: false,
      taskId: '',
    }
  }

  desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }

  getSorting() {
    return this.state.order === 'desc'
      ? (a, b) => this.desc(a, b, this.state.orderBy)
      : (a, b) => -this.desc(a, b, this.state.orderBy)
  }

  getKeyByValue(object, value) {
    return Object.keys(object).find(function (key) {
      return object[key] === value
    })
  }

  handleRequestSort(property) {
    const orderBy = property
    let order = 'desc'

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc'
    }

    this.setState({ order, orderBy })
  }

  confirmOnRedirect = (event, selectedTask) => {
    this.setState({ selectedTask: selectedTask })
    if (!selectedTask.is_active) {
      this.setState({ open: true })
    } else {
      this.redirectToUrl(selectedTask)
    }
  }

  redirectToUrl(selectedTask) {
    let tsk = selectedTask || this.state.selectedTask,
      iFrameRequired = 'yes'
    const {
      route: { location = {} },
    } = this.context.router
    if (isIE(window.navigator.userAgent)) {
      window.open(tsk.review_url, '_blank')
      return
    }
    if (location.search) {
      iFrameRequired = new URLSearchParams(location.search).get('iframe')
    }
    if (apiConfig.externalUser) {
      let selectedStatus = this.getKeyByValue(
        this.state.taskStatusList,
        tsk.status
      )
      if (
        selectedStatus === undefined ||
        selectedStatus === null ||
        selectedStatus === ''
      ) {
        selectedStatus = 1002
      }
      window.open(
        tsk.review_url +
          '&isIframeRequired=yes&selectedStatus=' +
          selectedStatus +
          '&redirectedUrl=' +
          window.location.href.split('?')[0] +
          '?taskId=' +
          tsk.task_id,
        '_self'
      )
    } else if (iFrameRequired === 'yes') {
      this.openReview(tsk)
    } else {
      window.open(tsk.review_url, '_blank')
    }
  }

  openReview = () => {
    this.setState({ openReview: true })
  }

  closeReview = (reload) => {
    this.setState({ openReview: false })
    if (reload) {
      this.props.getTaskData()
    }
  }

  handleClickOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  openTaskFrom(data) {
    this.props.openTaskFrom(data)
  }

  hasPendingTasks = () => {
    const { List = {} } = this.props
    const allTasks = concat(
      get(List, 'task.taskList.active', []),
      get(List, 'task.taskList.inActive', [])
    )
    return isTaskReportGenerationInProgress(allTasks)
  }

  componentDidMount() {
    const { initializePoll = true, initiatePoll = () => {} } = this.props
    if (this.hasPendingTasks() && initializePoll === false) {
      initiatePoll()
      this.setState({ startPoll: true })
      this.startPolling()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { startPoll = false } = prevState
    this.stopPollOnComplete(startPoll)
  }

  componentWillUnmount() {
    clearInterval(this.setIntervalId)
  }

  stopPollOnComplete = (startPoll) => {
    if (startPoll && !this.hasPendingTasks()) {
      clearInterval(this.setIntervalId)
      this.setState({ startPoll: false })
    }
  }

  generateReport = (task_id) => {
    const { generateTaskReport = () => {}, List = {}, auth = {} } = this.props
    const { projectId = '' } = List
    this.setState({ startPoll: true, taskId: task_id })
    generateTaskReport(projectId, task_id, auth)
    this.startPolling()
  }

  startPolling = () => {
    const { fetchTaskDetails = () => {}, List = {}, auth = {} } = this.props
    const { projectId = '' } = List
    this.setIntervalId = setInterval(() => {
      fetchTaskDetails(auth.lanId, projectId, false)
    }, 60000)
  }

  generateTr() {
    let rows = []
    let data = {}

    const {
      classes = {},
      isActive,
      List = {},
      onExpand = () => {},
      downloadTaskReport = () => {},
      downloadingTaskReport = '',
      router = {},
    } = this.props
    const { task = {}, history = {}, projectId = '' } = List
    const { loading = {}, taskExpandInfo = {}, taskList = {} } = task
    const { active = [], inActive = [], is_admin, userAssets = {} } = taskList
    const { project_name: projectName = '' } = userAssets

    if (active.length > 0 || inActive.length > 0) {
      data = isActive ? active : inActive
      let generatedList = data.filter(function (obj) {
        return obj.show === undefined || obj.show === true
      })

      generatedList
        .sort(this.getSorting())
        .forEach((taskDetails = {}, index) => {
          let cls =
            taskDetails.status === 'Completed'
              ? 'done'
              : taskDetails.status === 'In Progress'
              ? 'progress'
              : 'notDone'
          const {
            due_date,
            is_active,
            job_completed_percent = '',
            job_id = '',
            job_name = '',
            task_type = REVIEW_TASK_TYPE_ID,
            status = '',
            task_id = '',
            report_job = {},
            role_name = '',
          } = taskDetails
          const { start_time: report_job_start_time } = report_job
          const report_start_time_hours = Moment(
            Moment.utc(report_job.start_time).toDate()
          ).format('h:mm a') // incrementing as time moves

          let roleName = ''
          if (task_type === UPLOAD_TASK_TYPE_ID) {
            roleName = UPLOADER_ROLE_NAME
          } else {
            switch (role_name) {
              case 'Reviewer':
                roleName = REVIEWER_ROLE_NAME
                break
              case 'Observer':
                roleName = OBSERVER_ROLE_NAME
                break
              default:
                roleName = is_admin ? ADMIN_USER_ROLE_NAME : ''
                break
            }
          }

          rows.push(
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={themeRow} key={index}>
                <TableRow
                  data-cy="taskListRow"
                  className={job_id ? classes.groupRow : ''}
                >
                  <TableCell data-cy="taskListName" style={{ width: '20%' }}>
                    {job_name}
                  </TableCell>
                  <TableCell style={{ width: '10%' }}>
                    <span>
                      <UserRoleIcon roleName={roleName} />
                    </span>
                  </TableCell>
                  <TableCell style={{ width: '10%' }}>
                    <div>
                      {is_active && (
                        <span data-cy="taskListDueDate">
                          <EventIcon className={classes.userIcon} />
                          {Moment(due_date).format('MMM D, YYYY h:mm a')}
                        </span>
                      )}
                    </div>
                  </TableCell>
                  <TableCell style={{ width: '15%' }}>
                    <span className={classes[cls]}>
                      {!is_admin && (
                        <span data-cy="taskCompletedStatus">
                          {status === 'Completed' ? (
                            <Done className={classes.leftIcon} />
                          ) : status === 'In Progress' ? (
                            <CropSquare className={classes.leftIcon} />
                          ) : (
                            <MoreHoriz className={classes.leftIcon} />
                          )}
                          {status}
                          {status === 'Completed' && (
                            <div className={classes.doneDate}>
                              {Moment(due_date).format('MM/DD/YYYY')}
                            </div>
                          )}
                        </span>
                      )}
                      {is_active && is_admin && task_type !== UPLOAD_TASK_TYPE_ID && (
                        <Grid
                          container
                          direction="row"
                          className={classes.progressBar}
                        >
                          <Grid item xs={9}>
                            <LinearProgress
                              data-cy="taskCompletedBar"
                              variant="determinate"
                              value={
                                job_completed_percent
                                  ? job_completed_percent
                                  : 1
                              }
                              style={{ height: 14 }}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <span style={{ marginLeft: 5 }}>
                              {job_completed_percent} %
                            </span>
                          </Grid>
                        </Grid>
                      )}
                    </span>
                  </TableCell>

                  <TableCell style={{ width: '20%' }}>
                    {task_type !== UPLOAD_TASK_TYPE_ID && (
                      <div>
                        {downloadingTaskReport === task_id ? (
                          <div className={classes.downloadingReport}>
                            <CircularProgress size={35} />
                          </div>
                        ) : (
                          <div>
                            <Button
                              data-cy="generateReport"
                              className={classes.report}
                              color="primary"
                              onClick={() => {
                                this.generateReport(task_id)
                              }}
                            >
                              Generate New Report
                            </Button>

                            {report_job.status === REPORT_IN_PROCESS && (
                              <div
                                className={classes.generatingReportContainer}
                              >
                                <span
                                  data-cy="generateReportText"
                                  className={classes.generatingReportText}
                                >
                                  We are working on generating your report. You
                                  may leave the page while we work. This may
                                  take up to 1 hour. Started{' '}
                                  {report_start_time_hours}
                                </span>
                              </div>
                            )}

                            {report_job.status === REPORT_COMPLETE && (
                              <div>
                                <Button
                                  data-cy="downloadReport"
                                  className={classes.report}
                                  color="primary"
                                  onClick={() => {
                                    downloadTaskReport(
                                      report_job.render_url,
                                      projectName,
                                      job_name,
                                      due_date,
                                      task_id
                                    )
                                  }}
                                >
                                  Download Report
                                </Button>{' '}
                                |{' '}
                                <span className={classes.reportGeneratedDate}>
                                  Created:{' '}
                                  {Moment(report_job_start_time).format(
                                    'MM/DD/YY'
                                  )}
                                </span>
                              </div>
                            )}

                            {report_job.status === REPORT_ERROR && (
                              <div>Error Generating Report</div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </TableCell>
                  <TableCell align="right" style={{ width: '25%' }}>
                    {taskDetails.is_active && is_admin && (
                      <span style={{ marginRight: 10 }}>
                        <Button
                          data-cy="editTaskButton"
                          className={classes.buttonEdit}
                          color="primary"
                          variant="contained"
                          onClick={() => this.openTaskFrom(taskDetails)}
                        >
                          <EditIcon className={classes.leftIconButton} /> Edit
                        </Button>
                      </span>
                    )}
                    {task_type !== UPLOAD_TASK_TYPE_ID && (
                      <span>
                        <Button
                          data-cy="reviewButton"
                          className={classes.button}
                          color="primary"
                          variant="contained"
                          onClick={() =>
                            router.navigate(`/annotator/${projectId}/${job_id}`)
                          }
                        >
                          <OpenInNew className={classes.leftIconButton} /> Open
                          Review
                        </Button>
                      </span>
                    )}
                  </TableCell>
                  <TableCell style={{ width: '2%' }}>
                    {loading.expandTaskLoading[job_id] ? (
                      <CircularProgress
                        className={classes.buttonProgress}
                        size={20}
                      />
                    ) : (
                      <IconButton
                        data-cy="expandTask"
                        className={classes.expandButton}
                        aria-label="Expand"
                        onClick={() => {
                          onExpand(taskDetails.job_id, taskDetails.expanded)
                        }}
                        size="large"
                      >
                        {taskDetails.expanded ? (
                          <KeyboardArrowUp />
                        ) : (
                          <KeyboardArrowDown />
                        )}
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              </ThemeProvider>
            </StyledEngineProvider>
          )
          if (taskDetails.details && taskDetails.expanded) {
            rows.push(
              <TableRow key={index + '/' + job_id}>
                <TableCell colSpan={6}>
                  <TaskTab
                    id={job_id}
                    taskData={taskDetails.details}
                    expandInfo={taskExpandInfo}
                    loading={loading}
                    taskType={task_type}
                  />
                </TableCell>
              </TableRow>
            )
          }
        })
      return rows
    }
  }

  render() {
    const { classes = {} } = this.props
    return (
      <div className={classes.tableWrapper}>
        <Table className={classes.table}>
          <TableBody>{this.generateTr()}</TableBody>
        </Table>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{'Warning'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              The due date for this task has passed. New comments will not be
              considered.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.redirectToUrl()} color="primary">
              Continue Anyway
            </Button>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        {this.state.openReview && (
          <TaskReview
            open={this.state.openReview}
            selectedTask={this.state.selectedTask}
            close={this.closeReview}
            projectName={this.props.List.task.taskList.userAssets.project_name}
          />
        )}
      </div>
    )
  }
}

TaskList.contextTypes = {
  router: PropTypes.object,
}

TaskList.propTypes = {
  classes: PropTypes.object,
  isActive: PropTypes.bool,
  user: PropTypes.object,
  List: PropTypes.shape({
    task: PropTypes.shape({
      loading: PropTypes.shape({
        expandTaskLoading: PropTypes.object,
      }),
      taskList: PropTypes.shape({
        active: PropTypes.arrayOf(PropTypes.object),
        admin_tasks: PropTypes.arrayOf(PropTypes.object),
        inActive: PropTypes.arrayOf(PropTypes.object),
        is_admin: PropTypes.bool,
        userAssets: PropTypes.shape({
          project_name: PropTypes.string,
        }),
      }),
      taskExpandInfo: PropTypes.object,
    }),
  }),
  onExpand: PropTypes.func,
  openTaskFrom: PropTypes.func,
  getTaskData: PropTypes.func,
  generateTaskReport: PropTypes.func,
  downloadTaskReport: PropTypes.func,
  downloadingTaskReport: PropTypes.string,
  auth: PropTypes.object,
  fetchTaskDetails: PropTypes.func,
  initializePoll: PropTypes.bool,
  initiatePoll: PropTypes.func,
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchTaskDetails,
    },
    dispatch
  )

const MyComponent = (props) => {
  const classes = styles()
  return <TaskList {...props} classes={classes} />
}

export default connect(null, mapDispatchToProps)(withRouter(MyComponent))
