import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import { Rnd } from 'react-rnd'
import { praxis } from '../../config/themeConfig'

const styles = {
  root: {
    border: `3px solid ${praxis.lighterBlue}`,
    outline: '2px solid white',
    zIndex: 100,
  },
  numberIcon: {
    width: '25px',
    height: '25px',
    color: 'white',
    top: '-15px',
    left: '-15px',
    display: 'flex',
    position: 'fixed',
    borderRadius: '50%',
    backgroundColor: praxis.lighterBlue,
    alignItems: 'center',
    justifyContent: 'center',
    border: '2px solid white',
  },
}

export class MarkupBox extends React.PureComponent {
  render() {
    const {
      number = 0,
      size = '100%',
      position = {},
      onDragStop = () => {},
      onResize = () => {},
      classes = {},
      disableDragging = true,
      enableResizing = true,
    } = this.props

    return (
      <Rnd
        className={classes.root}
        size={size}
        position={position}
        onDragStop={onDragStop}
        onResize={onResize}
        disableDragging={disableDragging}
        enableResizing={enableResizing}
      >
        <div className={classes.numberIcon}>{number}</div>
      </Rnd>
    )
  }
}

MarkupBox.propTypes = {
  number: PropTypes.number,
  size: PropTypes.shape({
    height: PropTypes.number,
    width: PropTypes.number,
  }),
  position: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
  onDragStop: PropTypes.func,
  onResize: PropTypes.func,
  classes: PropTypes.object,
  disableDragging: PropTypes.func,
  enableResizing: PropTypes.bool,
}

export default withStyles(styles)(MarkupBox)
