import React from 'react'
import { withStyles } from '@mui/styles'
import { Switch, FormControlLabel } from '@mui/material'
import IconWrapper from '../IconWrapper/IconWrapper'
import { FormikFieldPropTypes } from '../../constants/projects'
import { praxis } from '../../config/themeConfig'

const styles = {
  colorSwitchBase: {
    color: 'grey',
    '& + $colorBar': {
      backgroundColor: 'grey',
    },
    '&.Mui-colorChecked': {
      color: praxis.lighterBlue,
      '& + $colorBar': {
        backgroundColor: 'grey',
      },
    },
  },
  colorBar: {},
  colorChecked: {},
}
const openLockIcon = 'LockOpen'
const closedLockIcon = 'Lock'

export const ConfidentialFormField = ({
  field = {},
  classes = {},
  ...props
}) => (
  <IconWrapper iconTag={field.value ? closedLockIcon : openLockIcon}>
    <FormControlLabel
      style={{ marginLeft: '10px' }}
      control={
        <Switch
          classes={{
            switchBase: classes.colorSwitchBase,
            checked: classes.colorChecked,
            track: classes.colorBar,
          }}
          checked={field.value}
          {...field}
          {...props}
        />
      }
      label={field.value ? 'Confidential' : 'Not Confidential'}
    />

    {field.value ? (
      <ul>
        <li>Assets will not be searchable until the internal release date</li>
        <li>
          Assets and metadata will only be seen by users assigned to the project
        </li>
        <li>Both fields are optional. Leave Date blank for indefinite</li>
      </ul>
    ) : (
      <ul>
        <li>Approved assets will be made searchable to all users</li>
      </ul>
    )}
  </IconWrapper>
)

ConfidentialFormField.propTypes = FormikFieldPropTypes

export default withStyles(styles)(ConfidentialFormField)
