import idx from 'idx'
import {
  TM_FAILURE,
  TM_SUCCESS,
  FETCH_TEAM_MEMBERS_CALL_PENDING,
  FETCH_TEAM_MEMBERS_CALL_FULFILLED,
  FETCH_TEAM_MEMBERS_CALL_REJECTED,
} from './actionType'

export const initialState = {
  isFetching: false,
  teamMembers: [],
  tmList: [],
}

export default function teamMemberListReducer(
  state = initialState,
  action = {}
) {
  const { payload = {} } = action
  switch (action.type) {
    case TM_SUCCESS: {
      const teamList = idx(payload, (_) => _.data.users) || []
      return { ...state, tmList: teamList }
    }
    case TM_FAILURE:
      return { ...state }
    case FETCH_TEAM_MEMBERS_CALL_PENDING:
      return {
        ...state,
        isFetching: true,
      }
    case FETCH_TEAM_MEMBERS_CALL_FULFILLED:
      return {
        ...state,
        teamMembers: idx(payload, (_) => _.data.users),
        isFetching: false,
      }
    case FETCH_TEAM_MEMBERS_CALL_REJECTED: {
      const errors = idx(payload, (_) => _.response.data)
      return {
        ...state,
        isFetching: false,
        errors,
      }
    }
    default:
      return initialState
  }
}
