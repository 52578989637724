import * as React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import { ToggleOff, Cancel } from '@mui/icons-material'

const styles = makeStyles((theme) => ({
  button: {
    fontSize: '12px',
    color: 'white',
    backgroundColor: useTheme().palette.primary.darkBlue,
  },
  cancelButton: {
    fontSize: '12px',
    color: 'blue',
    backgroundColor: useTheme().palette.primary.lightestGrey,
  },
  paddingLeft: {
    paddingLeft: '5px',
  },
  warningText: {
    color: '#cc3300',
  },
  processedAsset:{
    color: 'white',
    backgroundColor: 'green',
    position:'relative',
    top:'4px',
    left:'3px',
    marginRight:'10px',
    borderRadius:'5px',
    fontSize:'20px'
  },
  unProcessedAsset: {
    color:'red',
    position:'relative',
    top:'5px',
    marginRight:'7px'
  }
}))

function ApproveAssetsDialog(props){

  const {
  noOfSelectedAssets = Number(0),
  wipProcessedCount = Number(0),
  dialogOpen,
  approveAssets,
  toggleConfirmationModal,
  } = props
  
  const classes=styles()
  const unProcessedWipAssets = noOfSelectedAssets-wipProcessedCount

  return(
    <div>
    <div>
      <Tooltip title="Approve">
        <IconButton
          aria-label="Approve"
          onClick={() => toggleConfirmationModal(false)}
          size="large"
        >
          <ToggleOff className={classes.bulkActionBarToggle} />
        </IconButton>
      </Tooltip>
      <Dialog
        open={dialogOpen}
        onClose={() => toggleConfirmationModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Change the status of {wipProcessedCount} Asset(s) from WIP to Approved</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
        <CheckIcon className={classes.processedAsset}/>{wipProcessedCount} out of {noOfSelectedAssets} Asset(s) will be changed to Approved. <br></br>
        {
            unProcessedWipAssets > 0 && (
                <div>
                    <Cancel className={classes.unProcessedAsset}/>
                    {unProcessedWipAssets} out of {noOfSelectedAssets} will not be changed since they are already approved or <span style={{paddingLeft:'32px'}}>have not completed uploading</span>
                </div>   
            )
        }
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            title="Approve Asset(s)"
            onClick={approveAssets}
            className={classes.button}
            data-cy="approveAssetDialogButton"
          >
            <ToggleOff />
            <span style={styles.paddingLeft}>Approve Asset(s)</span>
          </Button>
          <Button
            onClick={() => toggleConfirmationModal(false)}
            color="primary"
            className={classes.cancelButton}
            data-cy="deleteAssetCancelButton"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  </div>
  )
}
ApproveAssetsDialog.propTypes = {
  classes: PropTypes.object,
  noOfSelectedAssets: PropTypes.number,
  wipCount: PropTypes.number,
  approveAssets: PropTypes.func,
  dialogOpen: PropTypes.func,
  toggleConfirmationModal: PropTypes.func,
}

export default ApproveAssetsDialog
