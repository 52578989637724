export const getUpdatedFailedFiles = (failedFiles = {}, fileName = '') => {
  const updatedFailedFiles = { ...failedFiles }
  if (updatedFailedFiles[fileName]) {
    delete updatedFailedFiles[fileName]
  }
  return {
    updatedFailedFiles,
    updatedFailedFilesCount: Object.keys(updatedFailedFiles).length,
  }
}
