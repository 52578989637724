import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import Card from '@mui/material/Card'
import Avatar from '@mui/material/Avatar'
import Grow from '@mui/material/Grow'
import GroupIcon from '@mui/icons-material/Group'
import ShareIcon from '@mui/icons-material/Share'
import withRouter from '../../containers/Router/WithRouter'
import CustomCard from '../Common/Card/CustomCard'

import UserList from './UserList'

const styles = {
  card: {
    margin: 5,
  },
  avatar: {
    backgroundColor: '#FFF',
  },
  avatarIcon: {
    color: '#0D46A0',
  },
  userList: {
    display: 'inline-flex',
    color: '#212121',
    fontSize: '14px',
  },
}

export class ListCard extends React.Component {
  state = {
    anchorEl: null,
  }
  constructor(props) {
    super(props)
    this.handleHeaderClick = this.handleHeaderClick.bind(this)
    this.handleClick = this.handleClick.bind(this)
  }
  handleHeaderClick = () => {
    let url = '/groups/' + this.props.groups.list_id + '/dashboard'
    this.props.router.navigate(url)
  }
  handleClick = (e) => {
    e.preventDefault()
  }
  handleClickMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget })
  }
  handleClose = () => {
    this.setState({ anchorEl: null })
  }
  renderCardContent = () => {
    const { classes = {}, groups: { user: groupUser = {} } = {} } = this.props
    return (
      <div className={classes.userList}>
        <UserList assets={groupUser} />
      </div>
    )
  }
  getCardConfigs = () => {
    const {
      tabActive,
      groups: { list_name = '', list_count = Number(0) } = {},
    } = this.props
    return {
      cardHeader: {
        headerAvatar: (
          <Avatar aria-label="Recipe" style={styles.avatar}>
            {tabActive === 1 ? (
              <ShareIcon style={styles.avatarIcon} />
            ) : (
              <GroupIcon style={styles.avatarIcon} />
            )}
          </Avatar>
        ),
        title: list_name,
        subheader: `${list_count} People in the group`,
        identifier: '',
      },
      displayFooter: false,
    }
  }
  render() {
    const cardConfig = this.getCardConfigs()
    const { groups = {} } = this.props
    if (!Object.entries(groups).length) {
      return (
        <Grow in>
          <Card style={styles.card}>No groups data found</Card>
        </Grow>
      )
    }
    return (
      <CustomCard
        data-cy="groupsListCard"
        cardConfig={cardConfig}
        cardContent={this.renderCardContent()}
        dispatchClickAction={(props) => {
          this.handleHeaderClick()
        }}
      />
    )
  }
}

ListCard.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  groups: PropTypes.object.isRequired,
  tabActive: PropTypes.number.isRequired,
}

ListCard.contextTypes = {
  router: PropTypes.func,
}
export default withStyles(styles)(withRouter(ListCard))
