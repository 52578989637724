import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { CircularProgress, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import HeaderTitle from '../../components/Header/HeaderTitle'
import { generateBreadcrumb } from '../../helpers/stringHelper'
import {
  REVIEW_TEST,
  bulkTestsBreadCrumbs,
  getGenAiBreadCrumbList,
} from '../helpers/genaiTestHelper'
import BulkTestDetailsView from './BulkTestDetailsView'
import BulkAssetReviewForm from './BulkAssetReviewForm'
import BulkReviewAssetView from './BulkReviewAssetView'
import { fetchBulkTestDetails } from '../services/genaiService'
import { isEmptyObject } from '../../helpers/UtilityHelper'
import { BULK_TESTS_VIEW_AI } from '../../constants/routes'

const useStyles = makeStyles({
  bulkReviewContainerClass: {
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #6153536b',
  },
  bulkTestDetailsContainerClass: {
    padding: '10px',
    border: '1px solid #6153536b',
  },
  bulkReviewAssetContainerClass: {
    display: 'flex',
    flexDirection: 'row',
  },
  bulkReviewAssetLayout: {
    width: '80%',
    height: '82vh',
    display: 'flex',
    flexDirection: 'column',
    borderRight: '1px solid #D6D6D6',
    overflow: 'scroll',
  },
  bulkReviewFieldsClass: {
    width: '20%',
    display: 'flex',
    flexDirection: 'column',
  },
  lightLabelClass: {
    color: '#666666',
    fontWeight: 500,
    fontSize: '14px',
    paddingBottom: '5px',
  },
  promptInfoClass: {
    display: 'flex',
    flexDirection: 'column',
    gap: '25px',
    padding: '15px',
  },
  promptInfoValueClass: {
    width: '18vw',
    wordBreak: 'break-word',
    maxHeight: '150px',
    overflow: 'scroll',
  },
  reviewFieldsContainer: {
    borderTop: '1px solid #D6D6D6',
    display: 'flex',
    flexDirection: 'column',
    gap: '25px',
    padding: '15px',
  },
  submitButton: {
    backgroundColor: '#178295',
    color: 'white',
    '&:disabled': {
      color: 'grey',
      backgroundColor: '#7f889994',
    },
    '&:hover': {
      backgroundColor: '#178295',
      color: 'white',
    },
  },
  transparentButton: {
    backgroundColor: 'white',
    color: '#178295',
    border: '1px solid #178295',
    '&:hover': {
      backgroundColor: 'white',
      color: '#178295',
    },
    '&:disabled': {
      border: 'grey',
    },
  },
  assetFilterContainerClass: {
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '5px 15px',
    borderBottom: '1px solid #D6D6D6',
    maxHeight: '50px',
  },
  chipDetailClass: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '15px',
  },
  imageStyles: {
    width: '100px',
    height: '100px',
    objectFit: 'contain',
    border: '1px solid #5e959280',
    overflow: 'hidden',
  },
  sequenceNumCell: {
    maxWidth: '30px',
  },
  responseCell: {
    overflow: 'scroll',
    border: '1px solid #D6D6D6',
    padding: '10px',
  },
  statusCell: {
    paddingLeft: '10px',
  },
  detailsLoader: {
    padding: '20px',
  },
})

function BulkTestReviewPage() {
  const classes = useStyles()
  const { bulktestid = '' } = useParams()
  const bulkTestReviewBreadCrumbs = getGenAiBreadCrumbList(REVIEW_TEST)
  const [bulkTestReviewData, setBulkTestReviewData] = useState({})
  const [selectedAssets, setSelectedAssets] = useState([])
  const [reviewBreadCrumbs, setReviewBreadCrumbs] = useState(
    bulkTestReviewBreadCrumbs,
  )

  useEffect(() => {
    callFetchBulkTestDetailsApi()
  }, [bulktestid])

  const callFetchBulkTestDetailsApi = async () => {
    const response = await fetchBulkTestDetails(bulktestid)
    if (response) {
      const responseData = response.data
      if (responseData) {
        const { test_name = '' } = responseData
        reviewBreadCrumbs[1].path = `${BULK_TESTS_VIEW_AI}/${bulktestid}`
        reviewBreadCrumbs[1].title = test_name
        setBulkTestReviewData(responseData)
        setReviewBreadCrumbs(reviewBreadCrumbs)
      }
    } else {
      setBulkTestReviewData({})
    }
  }

  const onReviewSuccess = () => {
    callFetchBulkTestDetailsApi()
    setSelectedAssets([])
  }

  return isEmptyObject(bulkTestReviewData) ? (
    <div>
      {' '}
      <HeaderTitle
        title={`AI - Image To Text : ${generateBreadcrumb(bulkTestsBreadCrumbs)}`}
      />{' '}
      <Grid className={classes.detailsLoader}>
        <CircularProgress />
      </Grid>
    </div>
  ) : (
    <div>
      <HeaderTitle
        title={`AI - Image To Text : ${generateBreadcrumb(reviewBreadCrumbs)}`}
      />
      <Grid container className={classes.bulkReviewContainerClass}>
        <Grid item container className={classes.bulkTestDetailsContainerClass}>
          <BulkTestDetailsView
            bulkTestReviewData={bulkTestReviewData}
            onReviewSuccessCallback={onReviewSuccess}
          />
        </Grid>
      </Grid>
      <Grid container className={classes.bulkReviewAssetContainerClass}>
        <Grid item container className={classes.bulkReviewAssetLayout}>
          <BulkReviewAssetView
            parentClasses={classes}
            bulkTestReviewData={bulkTestReviewData}
            selectedAssets={selectedAssets}
            setSelectedAssets={setSelectedAssets}
            onReviewSuccess={onReviewSuccess}
          />
        </Grid>
        <Grid item container className={classes.bulkReviewFieldsClass}>
          <BulkAssetReviewForm
            bulkTestReviewData={bulkTestReviewData}
            parentClasses={classes}
            selectedAssets={selectedAssets}
            onReviewSuccessCallback={onReviewSuccess}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default BulkTestReviewPage
