import {
  FETCH_ASSET_METADATA_FULFILLED,
  FETCH_ASSET_METADATA_REJECTED,
  RESET_ASSET_METADATA,
  FETCH_ASSET_REVISIONS_FULFILLED,
  FETCH_ASSET_REVISIONS_REJECTED,
  DELETE_ASSET_REVISIONS_FULFILLED,
  DELETE_ASSET_REVISIONS_REJECTED,
  FETCH_CATALOG_FULFILLED,
  FETCH_CATALOG_REJECTED,
  SET_UNSAVEDCHANGES_FLAG,
  CLEAR_ASSET_REVISIONS,
  SET_METADATA_LOADING,
} from './actionTypes'
import { sortMyArray } from '../../helpers/UtilityHelper'

export const initialState = {
  assetMetadata: {},
  isMetadataFormEdited: false,
  isMetadataLoading: false,
}

export default function metadata(state = initialState, action = {}) {
  const { payload = {} } = action
  switch (action.type) {
    case SET_METADATA_LOADING:
      return {
        ...state,
        isMetadataLoading: payload,
      }
    case FETCH_ASSET_METADATA_FULFILLED:
      return {
        ...state,
        isError: false,
        assetMetadata: payload.data || {},
      }
    case FETCH_ASSET_METADATA_REJECTED:
    case RESET_ASSET_METADATA:
      return {
        ...initialState,
        isError: true,
      }
    case FETCH_ASSET_REVISIONS_FULFILLED:
      return {
        ...state,
        assetRevisions: sortMyArray(
          payload.data || [],
          'revision_number',
          'desc'
        ),
      }
    case FETCH_ASSET_REVISIONS_REJECTED:
    case CLEAR_ASSET_REVISIONS:
    case DELETE_ASSET_REVISIONS_FULFILLED:
      return {
        ...state,
        assetRevisions: [],
      }
    case FETCH_CATALOG_FULFILLED:
      return {
        ...state,
        assetCatalog: payload.data || {},
      }
    case FETCH_CATALOG_REJECTED:
      return {
        ...state,
        assetCatalog: {},
      }
    case SET_UNSAVEDCHANGES_FLAG:
      return {
        ...state,
        isMetadataFormEdited: action.unsavedChanges,
      }
    case DELETE_ASSET_REVISIONS_REJECTED:
    default:
      return state
  }
}
