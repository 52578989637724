import { useDispatch } from 'react-redux'
import DateRangeSelector from '../../../components/CustomDateRangePicker/DateRangeSelector'
import { FILTER_UPLOAD_DATE_KEY } from '../../../constants/search'
import { updateSearchPayload } from '../../../store/search/searchSlice'
import { useSearchContext } from '../SearchContext'
import {
  BETA_ANALYTICS_DELIMITER,
  BetaAnalytics,
} from '../../../../analytics/betaSearch'

function UploadDateFilter({
  classes,
  closePopOver = () => {},
  selectedFilterValues = [],
}) {
  const dispatch = useDispatch()
  const { trackAnalyticsData = () => {} } = useSearchContext()

  const onApplyFilter = (selectedDateRange = []) => {
    let uploadDateFilters = {}
    uploadDateFilters[FILTER_UPLOAD_DATE_KEY] = selectedDateRange
    dispatch(updateSearchPayload({ filters: uploadDateFilters }))
    trackAnalyticsData({
      eventType: BetaAnalytics.event.ADD_FILTER,
      eventName: BetaAnalytics.event.FILTER_UPLOAD_DATE,
      eventData: {
        key: FILTER_UPLOAD_DATE_KEY,
        value: selectedDateRange.join(`${BETA_ANALYTICS_DELIMITER}`),
      },
    })
    closePopOver()
  }

  return (
    <div>
      <DateRangeSelector
        onApplyFilter={onApplyFilter}
        selectedFilterValues={selectedFilterValues}
      />
    </div>
  )
}

export default UploadDateFilter
