import React, { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import Dropzone from 'react-dropzone'
import { first } from 'lodash'
import {
  Button,
  Container,
  Dialog,
  DialogTitle,
  IconButton,
} from '@mui/material'
import { PhotoCamera } from '@mui/icons-material'
import CloseIcon from '@mui/icons-material/Close'
import { makeStyles } from '@mui/styles'
import { useSearchContext } from '../SearchContext'
import { showNotification } from '../../../../store/notification/actionCreator'

const styles = makeStyles((theme) => ({
  cameraIcon: {
    color: '#188295',
  },
  dailogContainer: {
    border: '1px solid rgb(112, 112, 112)',
    height: '100%',
    marginTop: '8%',
    marginBottom: '8%',
    maxWidth: '1200px',
    maxHeight: '764px',
    padding: '44px',
    display: 'flex',
    flexDirection: 'column',
  },
  dropzone: {
    flexGrow: '1',
    margin: '33px',
    height: '100%',
    background: '#F4F4F4 0% 0% no-repeat padding-box',
    border: '1px dashed #707070',
    opacity: '1',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  uploadButton: {
    backgroundColor: '#188295',
    color: '#FFFFFF',
    padding: '17px 32px',
    letterSpacing: '0px',
    fontSize: '20px',
    '&:hover': {
      backgroundColor: '#002171',
    },
  },
  dropDesc: {
    fontSize: '20px',
    letterSpacing: '0px',
    color: '#43425D',
    opacity: 1,
  },
  titleHeader: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',
    fontSize: '26px',
    letterSpacing: '0px',
    color: '#43425D',
    opacity: '1',
    margin: 0,
    '& $cameraIcon': {
      color: '#4D4F5C',
      marginRight: '20px',
    },
  },
  titleDesc: {
    textAlign: 'left',
    marginLeft: '44px',
    fontSize: '20px',
    letterSpacing: '0px',
    color: '#43425D',
    opacity: '1',
    margin: 0,
  },
  closeIcon: {
    padding: '0',
    position: 'absolute',
    top: '22px',
    right: '-23px',
  },
}))

const FilterByImage = (props = {}) => {
  const classes = styles()
  const {
    openImageSearchDialog = false,
    setImageSearchDialogOpen = () => {},
    triggerSearchImage = () => {},
  } = useSearchContext()
  const [dropZoneActive, setDropZonActive] = React.useState(false)
  const [uploadedImage, setUploadedImage] = React.useState(null)
  const dropzoneRef = useRef()
  const dispatch = useDispatch()

  useEffect(() => {
    if (uploadedImage) {
      triggerSearchImage(uploadedImage, false)
    }
    return function cleanup() {
      setUploadedImage(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedImage])

  const onDrop = (accepted, rejected) => {
    if (Object.keys(rejected)?.length !== 0) {
      rejected?.forEach((file) => {
        file?.errors?.forEach((err) => {
          if (err?.code === 'file-too-large') {
            dispatch(showNotification(true, 'File is larger than 2mb', 'error'))
          }

          if (err?.code === 'file-invalid-type') {
            dispatch(showNotification(true, err?.message, 'error'))
          }
        })
      })
    } else {
      setUploadedImage(first(accepted))
    }
  }

  const onDragEnter = () => {
    setDropZonActive(true)
  }

  const onDragLeave = () => {
    setDropZonActive(false)
  }

  const openSystemDialog = () => {
    if (dropzoneRef.current) {
      dropzoneRef.current.open()
    }
  }

  return (
    <Dialog
      fullScreen
      open={openImageSearchDialog}
      onClose={() => {
        setImageSearchDialogOpen(false)
      }}
    >
      <Container className={classes.dailogContainer}>
        <DialogTitle style={{ position: 'relative' }}>
          <header className={classes.titleHeader}>
            {' '}
            <PhotoCamera className={classes.cameraIcon} />
            Search by image
          </header>
          <p className={classes.titleDesc}>
            Reverse image search to view matching images
          </p>
          <IconButton
            aria-label="close"
            onClick={() => {
              setImageSearchDialogOpen(false)
            }}
            className={classes.closeIcon}
          >
            <CloseIcon style={{ fontSize: 24 }} />
          </IconButton>
        </DialogTitle>
        <Dropzone
          ref={dropzoneRef}
          noClick
          onDrop={onDrop}
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          noKeyboard
          accept=".jpeg,.png,.jpg"
          maxSize={2000000}
          maxFiles={1}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps({ className: classes.dropzone })}>
              <input {...getInputProps()} />
              {dropZoneActive ? (
                <p className={classes.dropDesc}>
                  Release to drop the files here
                </p>
              ) : (
                <>
                  <Button
                    onClick={openSystemDialog}
                    color="primary"
                    aria-label="upload"
                    className={classes.uploadButton}
                  >
                    Upload Image
                  </Button>
                  <p className={classes.dropDesc}>or drag and drop file</p>
                </>
              )}
            </div>
          )}
        </Dropzone>
      </Container>
    </Dialog>
  )
}

export default FilterByImage
