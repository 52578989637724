import React from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@mui/styles'
import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import bundleLoader from './bundle-loader'

let renderImages = []

const useStyles = makeStyles({
  exampleView: {
    backgroundColor: '#FAFAFA',
  },
  imageItem: {
    maxHeight: '280px',
    overflow: 'scroll',
    border: '1px solid #0000001f',
  },
  title: {
    textTransform: 'uppercase',
    padding: '10px',
    fontSize: '16px',
  },
  description: {
    paddingLeft: '10px',
    color: 'gray',
    fontSize: '14px',
  },
  imageList: {
    gap: '10px !important',
  },
  imageView: {
    objectFit: 'contain !important',
  },
})

renderImages = bundleLoader.importFiles()

function ClassificationImageView() {
  const classes = useStyles()
  const {
    selectedFolderPath = '',
    selectedDescription = '',
    selectedClassification = '',
  } = useSelector((state) => state.assetClassificationReducer) || {}
  return (
    <div className={classes.exampleView}>
      {selectedDescription && (
        <>
          <div className={classes.title}>{selectedClassification}</div>
          <div className={classes.description}>{selectedDescription}</div>
        </>
      )}
      {selectedFolderPath && (
        <ImageList cols={5} rowHeight={280} className={classes.imageList}>
          {(renderImages[selectedFolderPath] || []).map((imageName) => (
            <ImageListItem key={imageName} className={classes.imageItem}>
              {imageName.includes('mp4') ? (
                <video
                  className={classes.imageView}
                  height="256"
                  loop="true"
                  autoplay="autoplay"
                  id={`vid_${imageName}`}
                  muted
                >
                  <source type="video/mp4" src={`${imageName}`}></source>
                </video>
              ) : (
                <img
                  className={classes.imageView}
                  src={`${imageName}`}
                  srcSet={`${imageName}`}
                  alt={imageName}
                  loading="lazy"
                />
              )}
            </ImageListItem>
          ))}
        </ImageList>
      )}
    </div>
  )
}

export default ClassificationImageView
