import React from 'react'

const InfoPage = () => (
  <>
    <p style={{ padding: '20px 350px' }}>
      {`This section is restricted by entitlement due to security reason. 
            If you or your team need the ability to publish assets to be made available via external 
            link outside of the company ecosystem, please reach out to `}
      <a href="mailto:david.lahaye@target.com">david.lahaye@target.com</a>
      {` to discuss further.`}
    </p>
  </>
)

export default InfoPage
