import NoThumbnail from '../../../images/NoThumbnail.svg?url'

export const wrapWord = (originalName) => {
  const maxSize = 35
  if (originalName.length > maxSize) {
    return `${originalName.substring(0, maxSize)}...`
  }
  return originalName
}

export const checkJobIdExistinPendingFileList = (
  jobId = '',
  pendingFiles = []
) =>
  pendingFiles.some(({ job_id: pendingFileId = '' }) => pendingFileId === jobId)

export const addDefaultSrc = (ev) => {
  const fallbackUrl = NoThumbnail
  if (ev && ev.target) ev.target.src = fallbackUrl
}

export const handleSetSortDirection = (sortDirection) => {
  if (sortDirection === 'ASC') {
    return 'DESC'
  }
  if (sortDirection === 'DESC') {
    return 'ASC'
  }
}
