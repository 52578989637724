import React, { useContext } from 'react'
import { makeStyles } from '@mui/styles'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Checkbox from '@mui/material/Checkbox'
import { styled } from '@mui/system'
import { get, orderBy as orderByHandler } from 'lodash'
import { CustomTableContext } from './CustomTable'

const useStyles = makeStyles((theme) => ({
  tableBodyCell: {
    padding: '5px',
  },
  tableRow: {
    height: ({ tableRowHeight }) => tableRowHeight,
  },
}))

function CustomTableBody() {
  const { tableState = {}, tableMethods = {} } = useContext(CustomTableContext)
  const {
    data = [],
    tableConfig = {},
    selected = [],
    colMap = [],
    page = Number(0),
    rowsPerPage = 10,
    order = '',
    orderBy = '',
  } = tableState
  const { checkboxHandler = () => {} } = tableMethods
  const {
    checkBoxRequired = false,
    styleTableRows = false,
    tableRowHeight = '',
    rowIdentifier = 'id',
  } = tableConfig
  const classes = useStyles({ tableRowHeight })
  const handleClick = (event, name) => {
    const selectedRow = selected.indexOf(name)
    let newSelected = [...selected]
    if (selectedRow === -1) {
      newSelected.push(name)
    } else {
      newSelected.splice(selectedRow, 1)
    }
    checkboxHandler(newSelected)
  }

  const renderTableBodyCell = (row, type, field) => {
    switch (type) {
      default:
        return get(row, field, '-')
    }
  }

  const StyledTableRow = styled(TableRow)(({ theme }) => {
    if (styleTableRows) {
      return {
        '&:nth-of-type(odd)': {
          backgroundColor: '#FFFFFF',
        },
        '&:nth-of-type(even)': {
          backgroundColor: '#E8E8EB',
        },
      }
    }
    return {}
  })

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0

  return (
    <TableBody key="tablebody">
      {orderByHandler(data, orderBy, order)
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row, index) => {
          const identifier = row?.[rowIdentifier] || ''
          return (
            <StyledTableRow
              hover
              onClick={(event) => handleClick(event, identifier)}
              role="checkbox"
              aria-checked={selected.includes(identifier)}
              tabIndex={-1}
              key={`${identifier}_styledrow`}
              selected={selected.includes(identifier)}
              className={classes.tableRow}
            >
              {checkBoxRequired && (
                <TableCell key={`${index}check`}>
                  <Checkbox
                    onClick={(event) => handleClick(event, identifier)}
                    checked={selected.includes(identifier)}
                    inputProps={{ 'aria-labelledby': 'checkbox' }}
                  />
                </TableCell>
              )}
              {(colMap || []).map((columnHeader = {}, columnHeaderIndex) => {
                const {
                  id: { columnId = '' },
                  field = '',
                  type = '',
                } = columnHeader
                return (
                  <TableCell
                    key={`${columnId}-${columnHeaderIndex}_cell`}
                    className={classes.tableBodyCell}
                  >
                    {renderTableBodyCell(row, type, field)}
                  </TableCell>
                )
              })}
            </StyledTableRow>
          )
        })}
      {emptyRows > 0 && (
        <TableRow
          style={{
            height: 53 * emptyRows,
          }}
          key={`${emptyRows}_row`}
        >
          <TableCell colSpan={6} key={`${emptyRows}_span`} />
        </TableRow>
      )}
    </TableBody>
  )
}

export default CustomTableBody
