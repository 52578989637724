import { useState, useEffect, useMemo } from 'react'
import axios from 'axios'
import { unionBy, isEqual, sortBy } from 'lodash'
import {
  Button,
  Checkbox,
  debounce,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Switch,
  TextField,
} from '@mui/material'
import OutlinedInput from '@mui/material/OutlinedInput'
import { Search as SearchIcon } from '@mui/icons-material'
import ClearIcon from '@mui/icons-material/Clear'
import { makeStyles } from '@mui/styles'
import { useDispatch } from 'react-redux'
import apiConfig from '../../../config/apiConfig'
import { updateSearchPayload } from '../../store/search/searchSlice'
import CustomButtonGroup from '../../components/CustomButtonGroup'
import {
  DEPARTMENT_CLASS_IDENTIFIER_GROUP,
  FILTER_CLASS_KEY,
  FILTER_DEPARTMENT_KEY,
  filterKeyLabels,
} from '../../constants/search'
import { prefixZerosToGetMinThreeChars } from '../../utils/search'
import { useSearchContext } from '../../pages/Search/SearchContext'
import {
  BETA_ANALYTICS_DELIMITER,
  BetaAnalytics,
} from '../../../analytics/betaSearch'

const useStyles = makeStyles({
  filterGrid: {
    display: 'flex',
    flexDirection: 'column',
  },
  filterSearchGrid: {
    padding: '15px 15px 0px 15px',
  },
  filterSwitch: {
    padding: '0px 15px',
  },
  filterValueGrid: {
    paddingLeft: '15px',
  },
  filterControl: {
    margin: 0,
    width: '250px',
  },
  filterList: {
    maxHeight: '50vh',
    minWidth: '18vw',
    overflow: 'scroll',
  },
  filterInput: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRadius: '6px',
    border: '1px solid black',
    maxHeight: '36px',
    background: 'white',
  },
  searchButton: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    backgroundColor: '#188295',
    '&:hover': {
      backgroundColor: '#115E6C',
    },
  },
  listItemButton: {
    padding: '0px',
  },
  listItem: {
    padding: 0,
  },
})

function CustomFilterOptions({
  filterType = '',
  filterKey = '',
  parentClasses = {},
  availableFacets = {},
  selectedFilters = {},
  closePopOver = () => {},
}) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { trackAnalyticsData = () => {} } = useSearchContext()
  const [textSearchValue, setTextSearchValue] = useState('')
  const [isDisplayId, setIsDisplayId] = useState(false)
  const { records = [] } = availableFacets
  const selectedRecords = selectedFilters[filterKey] || []
  let selectedFacets = []
  selectedFacets = selectedRecords.map((selected) => ({
    value: selected,
  }))
  const updatedRecords = unionBy(records, selectedFacets, 'value')
  const [options, setOptions] = useState(updatedRecords)

  const [checked, setChecked] = useState(selectedRecords)

  const handleToggle = (option) => () => {
    const { value = '' } = option
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }

  const fetchAutoCompleteSuggestions = async (searchText = '') => {
    const suggestionsUrl = `${
      apiConfig.betaSearch.suggestionsUrl
    }?searchTerm=${encodeURIComponent(searchText)}&type=${filterType}`
    const result = await axios.get(suggestionsUrl)
    const data = await result.data
    return data
  }

  const fetchSuggestions = useMemo(
    () =>
      debounce(async ({ input }, callback) => {
        if (input.length > 2) {
          const response = await fetchAutoCompleteSuggestions(input)
          const { autocomplete_suggestions = {} } = response
          const { suggestion_response = [] } = autocomplete_suggestions
          const filterData = suggestion_response[0]?.['records'] || []
          callback(filterData)
        } else {
          callback([])
        }
      }, 400),
    [],
  )

  useEffect(() => {
    let active = true

    if (textSearchValue === '') {
      // setOptions(selectedValue ? [selectedValue] : [])
      setOptions(updatedRecords)
      return undefined
    }

    fetchSuggestions({ input: textSearchValue }, (results) => {
      const uniqueRecords = unionBy(results, selectedFacets, 'value')
      if (active) {
        setOptions(uniqueRecords)
      }
    })

    return () => {
      active = false
    }
  }, [textSearchValue])

  const onSearchIconClick = () => {
    fetchSuggestions({ input: textSearchValue }, (results) => {
      const uniqueRecords = unionBy(results, selectedFacets, 'value')
      setOptions(uniqueRecords)
    })
  }

  const onClickApply = () => {
    let customFilters = {}
    customFilters[filterKey] = checked
    dispatch(updateSearchPayload({ filters: customFilters }))
    closePopOver()
    trackAnalyticsData({
      eventType: BetaAnalytics.event.ADD_FILTER,
      eventName: filterKeyLabels[filterKey],
      eventData: {
        key: filterKey,
        value: checked.join(`${BETA_ANALYTICS_DELIMITER}`),
      },
    })
  }

  const onClickClear = () => {
    setChecked([])
  }

  const getFilterValueWithId = ({
    value = '',
    primaryId = '',
    categoryId = '',
    count = Number(0),
    isDisplayId,
    filterKey = '',
  }) => {
    switch (filterKey) {
      case FILTER_DEPARTMENT_KEY:
        return isDisplayId && primaryId
          ? `D${prefixZerosToGetMinThreeChars(primaryId)} : ${value}`
          : `${value}`
      case FILTER_CLASS_KEY:
        return isDisplayId && primaryId
          ? categoryId
            ? `D${prefixZerosToGetMinThreeChars(
                categoryId,
              )} : CL${prefixZerosToGetMinThreeChars(primaryId)} : ${value}`
            : `CL${prefixZerosToGetMinThreeChars(primaryId)} : ${value}`
          : `${value}`
    }
  }

  const disableApplied = isEqual(sortBy(checked), sortBy(selectedRecords))

  return (
    <Grid container className={classes.filterGrid}>
      <Grid item className={classes.filterSearchGrid}>
        <FormControl
          // sx={{ m: 1, width: '25ch' }}
          variant="outlined"
          className={classes.filterControl}
        >
          <OutlinedInput
            id="outlined-adornment-weight"
            value={textSearchValue}
            classes={{
              root: classes.filterInput,
            }}
            onChange={(event) => setTextSearchValue(event.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="clear search"
                  onClick={() => setTextSearchValue('')}
                  edge="end"
                >
                  {textSearchValue ? <ClearIcon /> : ''}
                </IconButton>
              </InputAdornment>
            }
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              'aria-label': 'weight',
            }}
            // placeholder={
            //   isDisplayId
            //     ? `Search ${filterType} ID`
            //     : `Search ${filterType} Name`
            // }
            placeholder={`Enter ${filterType} Name`}
          />
        </FormControl>
        <Button
          variant="contained"
          color="secondary"
          type="submit"
          className={classes.searchButton}
          onClick={() => onSearchIconClick()}
        >
          <SearchIcon className={classes.searchIcon} />
        </Button>
      </Grid>
      <Grid item className={classes.filterSwitch}>
        {DEPARTMENT_CLASS_IDENTIFIER_GROUP.includes(filterKey) ? (
          <FormControlLabel
            control={
              <Switch
                checked={isDisplayId}
                onChange={(event) => setIsDisplayId(event.target.checked)}
              />
            }
            label={`Show ${filterType} ID`}
          />
        ) : (
          ''
        )}
      </Grid>
      <Grid item className={classes.filterValueGrid}>
        <List className={classes.filterList}>
          {options.map((option = {}) => {
            const {
              value = '',
              count = Number(0),
              id = '',
              category = '',
            } = option
            return (
              <ListItem key={value} className={classes.listItemButton}>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  inputProps={{ 'aria-labelledby': 'value' }}
                  onClick={handleToggle(option)}
                />
                {DEPARTMENT_CLASS_IDENTIFIER_GROUP.includes(filterKey) ? (
                  <ListItemText
                    primary={getFilterValueWithId({
                      value,
                      primaryId: id,
                      categoryId: category,
                      count,
                      isDisplayId,
                      filterKey,
                    })}
                  />
                ) : (
                  <ListItemText
                    primary={
                      // count ?
                      // `${value} (${count})` :
                      value
                    }
                  />
                )}
              </ListItem>
            )
          })}
        </List>
      </Grid>
      <Grid item>
        <CustomButtonGroup
          onClickClear={onClickClear}
          onClickApply={onClickApply}
          isApplyDisabled={disableApplied}
        />
      </Grid>
    </Grid>
  )
}

export default CustomFilterOptions
