export const METADATA_CARD_SHOW_BUTTON_TEXT = 'SHOW DETAILS'
export const METADATA_CARD_HIDE_BUTTON_TEXT = 'HIDE DETAILS'
export const TAB_KEY_CODE = 9
export const ENTER_KEY_CODE = 13
export const ALLOWED_ACTION_KEYS = [ENTER_KEY_CODE, TAB_KEY_CODE]

export const METADATA_LOOKUP_PRODUCT_ERROR = 'DPCI or TCIN is not valid'
export const METADATA_LOOKUP_TEXT = 'Click Enter to Validate'

export const METADATA_LOOKUP_DRM_ERROR = 'DRM ID is not valid'
export const METADATA_LOOKUP_ERROR =
  'AssetHub is experiencing a problem. Please try again later.'

export const INTERNAL_USAGE_CHANNEL_LABEL = 'Internal Usage Only'
export const OTHER_CHANNEL_LABEL = 'Others'

//Librarian Status
export const ASSET_BASIC_REVIEW_COMPLETED = 'Basic Review Completed'
export const ASSET_ADVANCE_REVIEW_COMPLETED = 'Advanced Review Complete'
export const ASSET_REVIEW_FLAGGED = 'Flagged'
export const ASSET_REVIEW_PENDING = 'Review Pending'

export const METADATA_REVISION_DELETE_TITLE = 'Delete Revision'
export const METADATA_REVISION_DELETE_TEXT =
  'All review comments will be permanently removed from AssetHub.'

export const ASSET_CATALOG_FIELDS = [
  'subject',
  'location',
  'shottype',
  'assetClassification',
]
export const DISPLAY_BRAND_CLASSIFICATION_TYPES = [
  'Design File',
  'Non-Product Image',
  'Non-Product Motion',
  'Non-Product Video',
]
