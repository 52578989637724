import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'

import apiConfig from '../../config/apiConfig'
import { appendQueryParams } from '../../helpers/UrlHelper'

import NoThumbnail from '../../images/NoThumbnail.svg?url'
import GeneratingThumbnail from '../../images/GeneratingThumbnail.svg?url'
import { getThumbnailToPreviewUrl } from '../../helpers/stringHelper'

const styles = {
  imgStyle: {
    verticalAlign: 'middle',
    maxHeight: '75px',
    maxWidth: '75px',
  },
}

export const RemoteImageLoader = ({
  AltText,
  ClassName,
  imgStyle,
  inProgress,
  Src,
  mimeType = ''
}) => {
  let imgSrc
  if (inProgress) {
    imgSrc = GeneratingThumbnail
  } else {
    imgSrc = appendQueryParams(Src, {
      key: apiConfig.uploadKey,
      make_square: true,
    })
  }
  let updatedImgSrc = imgSrc
  if (mimeType !== "image/gif") {
    updatedImgSrc = getThumbnailToPreviewUrl(imgSrc)
  }
  return (
    <img
      data-cy="imageHolder"
      alt={AltText}
      className={ClassName}
      onError={(e) => {
        e.target.src = NoThumbnail
      }}
      src={updatedImgSrc}
      style={imgStyle ? imgStyle : styles.imgStyle}
    />
  )
}

RemoteImageLoader.propTypes = {
  AltText: PropTypes.string,
  ClassName: PropTypes.string,
  imgStyle: PropTypes.object,
  inProgress: PropTypes.bool,
  Src: PropTypes.string,
}

export default withStyles(styles)(RemoteImageLoader)
