import {
  Grid,
  IconButton,
  Popover,
  Typography,
  Button,
  TextField,
  Chip,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import moment from 'moment'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import ReplayIcon from '@mui/icons-material/Replay'
import EditIcon from '@mui/icons-material/Edit'
import {
  CUSTOM_TIMESTAMP,
  bulkTestReviewFullDetails,
  bulkTestReviewInitDetails,
} from '../constants/genai'
import { showNotification } from '../../store/notification/actionCreator'

import { retryBulkTest, updateTestNotes } from '../services/genaiService'

const useStyles = makeStyles({
  bulkTestDetailsContainerClass: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  bulkTestDetailsInitClass: {
    display: 'flex',
    alignItems: 'center',
  },
  bulkTestDetailsClass: {
    display: 'flex',
    flexDirection: 'row',
    gap: '3%',
    width: '90vw',
    flexWrap: 'wrap',
  },
  detailsItem: {
    minWidth: 'fit-content',
    maxWidth: '120px',
    paddingBottom: '10px',
  },
  detailsInitItem: {
    padding: '0px 30px',
  },
  iconButtonClass: {
    borderRadius: '12px',
    width: '24px',
    height: '24px',
    color: 'white',
    backgroundColor: '#A0A0A0',
    '&:hover': {
      color: 'white',
      backgroundColor: '#A0A0A0',
    },
  },
  retryChip: {
    marginLeft: '10px',
  },
  lightLabelClass: {
    color: '#666666',
    fontWeight: 500,
    fontSize: '14px',
    paddingBottom: '10px',
  },
  lightLabelClassInit: {
    color: '#666666',
    fontWeight: 500,
    fontSize: '14px',
    paddingBottom: '10px',
    paddingRight: '10px',
  },
  editIconClass: {
    cursor: 'pointer',
  },
  testNotesTextClass: {
    paddingBottom: '10px',
  },
  buttonGroupClasses: {
    marginRight: '10px',
  },
})

function BulkTestDetailsView({
  bulkTestReviewData = {},
  onReviewSuccessCallback = () => {},
}) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { test_id = '', test_notes = '' } = bulkTestReviewData
  const [showMoreDetails, setShowMoreDetails] = useState(false)
  const [testNotesInput, setTestNotesInput] = useState(test_notes)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const { displayName = '' } = useSelector((state) => state.auth)

  useEffect(() => {
    setTestNotesInput(test_notes)
    return () => {
      setTestNotesInput('')
    }
  }, [test_notes])

  const getDisplayValueBasedOnType = (displayValue = '', type = '') => {
    switch (type) {
      case 'percentage':
        return `${Number(displayValue)} %`
      case 'boards':
        return `${(displayValue || [])?.map((value) => value?.board_name).join(',')}`
      case 'timestamp':
        return moment(displayValue)?.format(CUSTOM_TIMESTAMP)
      case 'retry':
        return (
          <>
            <span>{displayValue}</span>
            <Chip
              className={classes.retryChip}
              icon={<ReplayIcon />}
              label={`RETRY`}
              variant="outlined"
              color={'error'}
              clickable
              onClick={() => onClickRetryTestAssets()}
            />
          </>
        )
      default:
        return displayValue
    }
  }

  const handleTestNotesSave = () => {
    const testNotesUpdatePayload = {
      test_notes: testNotesInput,
      updated_by: displayName,
    }
    updateTestNotes(test_id, testNotesUpdatePayload).then((response) => {
      const { test_notes = '' } = response.data
      setTestNotesInput(test_notes)
      setAnchorEl(null)
      onReviewSuccessCallback()
    })
  }

  const onClickRetryTestAssets = () => {
    retryBulkTest(test_id)
      .then((res) =>
        dispatch(
          showNotification(
            true,
            'Retrying Asset Response. Please check after sometime',
            'success',
            3000,
          ),
        ),
      )
      .catch((err) =>
        dispatch(
          showNotification(
            true,
            'Retrying Asset Response Failed.',
            'error',
            3000,
          ),
        ),
      )
  }

  const fullDisplayedInfo = bulkTestReviewFullDetails.filter(
    (testReviewDetails) =>
      testReviewDetails.show || bulkTestReviewData[testReviewDetails.value],
  )
  const initDisplayedInfo = bulkTestReviewInitDetails.filter(
    (testReviewDetails) =>
      testReviewDetails.show || bulkTestReviewData[testReviewDetails.value],
  )

  return (
    <Grid container className={classes.bulkTestDetailsContainerClass}>
      {showMoreDetails ? (
        <Grid item className={classes.bulkTestDetailsClass}>
          {fullDisplayedInfo.map((detailsObj) => {
            const { label, value, type, editable } = detailsObj
            const displayValue = bulkTestReviewData[value] || ''
            return (
              <Grid item className={classes.detailsItem}>
                <div className={classes.lightLabelClass}>{label}</div>
                <div>
                  {editable ? (
                    <div style={{ display: 'flex', gap: '5px' }}>
                      {getDisplayValueBasedOnType(displayValue, type)}
                      <Typography
                        onClick={(event) => setAnchorEl(event.currentTarget)}
                        className={classes.editIconClass}
                      >
                        {<EditIcon fontSize="small" />}
                      </Typography>
                      <Popover
                        id={Boolean(anchorEl) ? 'simple-popover' : undefined}
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={() => setAnchorEl(null)}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                      >
                        <Typography sx={{ p: 3 }}>
                          <TextField
                            className={classes.testNotesTextClass}
                            id="test_notes"
                            label="Test Notes"
                            placeholder="Add any notes"
                            InputLabelProps={{ shrink: true }}
                            value={testNotesInput}
                            fullWidth
                            multiline
                            minRows={4}
                            onChange={(event) =>
                              setTestNotesInput(event.target.value)
                            }
                          />
                          <Button
                            variant="outlined"
                            className={classes.buttonGroupClasses}
                            onClick={() => setAnchorEl(null)}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="outlined"
                            className={classes.buttonGroupClasses}
                            onClick={() => handleTestNotesSave()}
                          >
                            Save
                          </Button>
                        </Typography>
                      </Popover>
                    </div>
                  ) : (
                    getDisplayValueBasedOnType(displayValue, type)
                  )}
                </div>
              </Grid>
            )
          })}
        </Grid>
      ) : (
        <Grid item className={classes.bulkTestDetailsInitClass}>
          {initDisplayedInfo.map((detailsObj) => {
            const { label, value, type } = detailsObj
            const displayValue = bulkTestReviewData[value]
            return (
              <Grid item className={classes.detailsInitItem}>
                <span className={classes.lightLabelClassInit}>{label}</span>
                <span>{getDisplayValueBasedOnType(displayValue, type)}</span>
              </Grid>
            )
          })}
        </Grid>
      )}

      <Grid item>
        <IconButton
          onClick={() => setShowMoreDetails(!showMoreDetails)}
          size="small"
          className={classes.iconButtonClass}
        >
          {showMoreDetails ? (
            <KeyboardArrowUpIcon />
          ) : (
            <KeyboardArrowDownIcon />
          )}
        </IconButton>
      </Grid>
    </Grid>
  )
}

export default BulkTestDetailsView
