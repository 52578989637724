import { createSelector } from 'reselect'
import idx from 'idx'
import { getObjectKeyCount, makeUserDetails } from './helpers'

const selectAppliedFilters =
  () =>
  ({ assetsSearch = {} }) =>
    assetsSearch.appliedFilters
const selectSearchTerm =
  () =>
  ({ assetsSearch = {} }) =>
    assetsSearch.searchTerm || ''
const selectAssetSearchResults =
  () =>
  ({ assetsSearch = {} }) =>
    idx(assetsSearch, (_) => _.assetResults.search)
const selectAssetSearchResultFacets =
  () =>
  ({ assetsSearch = {} }) =>
    idx(assetsSearch, (_) => _.globalFacets)
const selectTotalSearchResults =
  () =>
  ({ assetsSearch = {} }) =>
    idx(assetsSearch, (_) => _.assetResults.total_results)
const selectHeaderTitle =
  () =>
  ({ layout = {} }) =>
    layout.headerTitle
const selectSaveAlertActiontype =
  () =>
  ({ layout = {} }) =>
    layout.saveAlertActionType
const selectRightSideNavComponent =
  () =>
  ({ layout = {} }) =>
    layout.rightSideNavComponent
const selectUserEmail =
  () =>
  ({ auth = {} }) =>
    auth.email
const selectIsFetchAssetsInProgress =
  () =>
  ({ assetsSearch = {} }) =>
    assetsSearch.isFetching
const selectIsBoardDialogOpen =
  () =>
  ({ boards = {} }) =>
    boards.isBoardDialogOpen
const selectAuthData =
  () =>
  ({ auth = {} }) =>
    auth
const selectSearchTemplates =
  () =>
  ({ assetsSearch = {} }) =>
    assetsSearch.searchTemplates
const selectSuggestions =
  () =>
  ({ assetsSearch = {} }) =>
    idx(assetsSearch, (_) => _.suggestions.autocomplete_suggestions)
const selectIsReturnStrict =
  () =>
  ({ assetsSearch = {} }) =>
    idx(assetsSearch, (_) => _.assetResults.strict)
const selectSortFields =
  () =>
  ({ assetsSearch = {} }) =>
    assetsSearch.sortFields
const selectPageSize =
  () =>
  ({ assetsSearch = {} }) =>
    assetsSearch.pageSize
const selectPreviewUrl =
  () =>
  ({ assetsSearch = {} }) =>
    assetsSearch.searchImagePreview
const selectDownloadAssets =
  () =>
  ({ assetsSearch = {} }) =>
    assetsSearch.downloadAssets
const selectBSearchSelAssets =
  () =>
  ({ newSearch = {} }) =>
    newSearch.selectedItems
const selectDownloadAssetInProgress =
  () =>
  ({ assetsSearch = {} }) =>
    assetsSearch.downloadInProgress
const selectTemplateId =
  () =>
  ({ assetsSearch = {} }) =>
    assetsSearch.templateId
const selectBoardsAssetMetadata =
  () =>
  ({ assetsSearch = {} }) =>
    assetsSearch.boardsAssetMetadata
const selectDeleteAssetInProgress =
  () =>
  ({ assetsSearch = {} }) =>
    assetsSearch.deleteAssetInProgress

const makeSelectAppliedFiltersCount = () =>
  createSelector(selectAppliedFilters(), getObjectKeyCount)
const makeSelectIsFetchAssetsInProgress = () =>
  createSelector(
    selectAssetSearchResults(),
    selectIsFetchAssetsInProgress(),
    (searhResults = [], isFetching) => isFetching && searhResults.length === 0,
  )
const makeSelectUserDetails = () =>
  createSelector(selectAuthData(), makeUserDetails)
const selectIsMetadataDownloadInProgress =
  () =>
  ({ assetsSearch = {} }) =>
    assetsSearch.isMetadataDownloadInitiated

export {
  makeSelectAppliedFiltersCount,
  makeSelectIsFetchAssetsInProgress,
  makeSelectUserDetails,
  selectAppliedFilters,
  selectAssetSearchResultFacets,
  selectAssetSearchResults,
  selectAuthData,
  selectDeleteAssetInProgress,
  selectDownloadAssetInProgress,
  selectTemplateId,
  selectDownloadAssets,
  selectHeaderTitle,
  selectIsFetchAssetsInProgress,
  selectIsReturnStrict,
  selectSearchTemplates,
  selectSearchTerm,
  selectSortFields,
  selectPageSize,
  selectSuggestions,
  selectTotalSearchResults,
  selectUserEmail,
  selectIsBoardDialogOpen,
  selectBoardsAssetMetadata,
  selectSaveAlertActiontype,
  selectRightSideNavComponent,
  selectIsMetadataDownloadInProgress,
  selectPreviewUrl,
  selectBSearchSelAssets,
}
