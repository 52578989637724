import merge from 'lodash/merge'

const commonConfig = {
  auth: {
    authorizationUrl:
      'https://oauth.iam.perf.target.com/auth/oauth/v2/tgt/authorize/ad-pol/1',
    logoutUrl:
      'https://logonservices.iam.perf.target.com/login/responses/logoff.html',
    logoutRedirect: `${window.location.origin}`,
  },
  publicUrlPath: process.env.PUBLIC_URL,
  apiForRetry: [
    'projects',
    'review_tasks',
    'task_status',
    'boards',
    'board_users',
    'create_group',
    'edit_group',
    'share_group',
    'chunks',
  ], // list of services for retry
  apiToIgnoreErrors: ['chunks'],
  retryLimit: 2,
  uploadRetryLimit: 5,
  embeddedExperienceTimeout: 60000, // 60 seconds
  avatarUrl: 'http://itgtsites.target.com/User%20Photos/Profile%20Pictures/',
  chunkUploadSize: 5242880,
}

//Enterprise Uploads v1
const euDevUrl =
  'https://ahaggregatorapi-dev.dev.target.com/enterprise_uploads/v1'
const euStgUrlExt = 'https://stage-api.target.com/enterprise_uploads/v1'
const euProdUrlExt = 'https://api.target.com/enterprise_uploads/v1'

// MARKETING V1
const baseUrlDev =
  'https://ahaggregatorapi-dev.dev.target.com/marketing_assets/v1'
const baseUrlStgExt = 'https://stage-api.target.com/marketing_assets/v1'
const baseUrlProdExt = 'https://api.target.com/marketing_assets/v1'

//SEARCH v2
const DEV_BASE_V2_URL =
  'https://ahaggregatorapi-dev.dev.target.com/enterprise_uploads/v2'
const NONPROD_BASE_V2_URL = 'https://stage-api.target.com/enterprise_uploads/v2'
const PROD_BASE_V2_URL = 'https://api.target.com/enterprise_uploads/v2'

const envConfigs = {
  dev: {
    auth: {
      clientId: 'ahb_npe_im',
    },
    polAuth: {
      authorizationUrl:
        'https://oauth.iam.perf.partnersonline.com/auth/oauth/v2/tgt/authorize/nla/1',
      logoutUrl:
        'https://logonservices.iam.perf.partnersonline.com/login/responses/logoff.html',
      clientId: 'ahb_npe_im',
    },
    analytics: {
      url: 'https://stage-api.target.com/consumers/v1/ingest/internal/internal_app',
    },
    assethub: {
      host: 'https://assethub.dev.target.com',
      serviceHostMarket: `${baseUrlDev}`,
      serviceHostMarketV2: `${euDevUrl}`,
      projectDetailsUrl: `${euDevUrl}/projects/`,
      uploadsUrl: 'https://stage-api.target.com/assethub_assets/v1/',
      uploadStatusUrl: `${euDevUrl}/`,
      feedBackUrl: `${baseUrlDev}/jira`,
      taskGroupUsersUrl: `${baseUrlDev}/task_group_users`,
      assetVersionsUrl: `${euDevUrl}/assets`,
      assetUrl: `${baseUrlDev}/asset/`,
      reviewTaskReportUrl: `${baseUrlDev}/report/review_task/`,
    },
    annotation: {
      commentsUrl: `${baseUrlDev}/asset_review_comments`,
      taskPreviewUrl: `${euDevUrl}/asset_review_comments`,
    },
    ext: {
      pipelineUrl: 'https://pipeline-stg.us-central-1.core.k8s.tgt/cgi/review/',
      otmmUrl: 'https://opentext-stg.target.com/',
      ahFolderUoID: '559c47099fb38bff215bb6e359764fb8e239fdb1',
    },
    externalUser: true,
    key: 'c0dfddda4fe6535127e9034bef8864067c189a2d',
    uploadKey: 'c0dfddda4fe6535127e9034bef8864067c189a2d',
    search: {
      baseUrl: 'https://stage-api.target.com/digital_assets/v1/',
      contentSearchUrl: `${euDevUrl}/search`,
      contentReverseImageSearchUrl: `${euDevUrl}/search/images`,
      serachTemplateUrl: `${baseUrlDev}/search_templates`,
      searchAdminTemplateUrl: `${baseUrlDev}/search_templates/admin`,
      typeaheadSuggestionsUrl: `${euDevUrl}/typeahead_suggestions`,
      internalContentServerUrl:
        'https://stage-api.target.com/vault_digital_assets/v1',
      internalAHContentServerUrl:
        'https://stage-api.target.com/assethub_assets/v1',
    },
    betaSearch: {
      url: `${DEV_BASE_V2_URL}/search`,
      suggestionsUrl: `${DEV_BASE_V2_URL}/typeahead_suggestions`,
    },
    metadata: {
      assetMetadataUrl: `${euDevUrl}/assets/`,
      updateAssetMetadataUrl: `${euDevUrl}/metadata`,
      assetRevisionsUrl: `${euDevUrl}/asset_revisions/`,
      drmUrl: `${baseUrlDev}/usage_rights`,
      productUrl: `${baseUrlDev}/product`,
      locationUrl: 'https://stage-api.target.com/locations/v3/public/',
      locationKey: 'y7c2jIEfVqR98SUzkE4evDSsgbtOZHU7',
      catalogUrl: `${baseUrlDev}/catalog/metadata`,
      assetDeleteUrl: `${baseUrlDev}/asset`,
      bulkUpdateUrl: `${baseUrlDev}/asset_metadata/bulk_update`,
      assetMetadataUrlForAdmin: `${euDevUrl}/metadata/csv`,
    },
    groups: {
      groupsListUrl: `${baseUrlDev}/user_groups`,
      shareListUrl: `${baseUrlDev}/shared_groups`,
      collaboratorsUrl: `${baseUrlDev}/share_group_details/`,
      collaboratorsDelete: `${baseUrlDev}/delete_share_group/`,
      groupCreate: `${baseUrlDev}/create_group`,
      groupEdit: `${baseUrlDev}/edit_group`,
      groupDelete: `${baseUrlDev}/group/`,
      groupUserDetails: `${baseUrlDev}/group_user_details/`,
      collaboratorsAdd: `${baseUrlDev}/share_group/`,
    },
    boards: {
      boardListUrl: `${euDevUrl}/boards`,
      renditionUrl: `${baseUrlDev}/renditions/`,
      boardsRootUrl: `${baseUrlDev}/boards`,
      boardAssetsUrl: `${euDevUrl}/boards/assets/list`,
      addAssetsToBoardUrl: `${euDevUrl}/boards/assets`,
      sharedBoardListUrl: `${baseUrlDev}/boards/share`,
      boardUsersDetailsUrl: `${baseUrlDev}/boards/user`,
    },
    projects: {
      projectsUrl: `${baseUrlDev}/user_projects`,
      buildProjectFieldsUrl: `${baseUrlDev}/project_details`,
      createProjectUrl: `${baseUrlDev}/projects`,
      createCGI_ProjectUrl: `${baseUrlDev}/cgi_projects`,
      editProjectUrl: `${baseUrlDev}/cgi_projects`,
      trackerUrl: '/project',
      projecTypeUrl: `${baseUrlDev}/project_types`,
      assetsUrl: `${baseUrlDev}/renditions`,
      renditionUrl: `${baseUrlDev}/renditions`,
      projectAssetsUrl: `${baseUrlDev}/assets_and_job_status`,
      brandsUrl: `${baseUrlDev}/brand_details`,
      setCoverImg: `${baseUrlDev}/project/`,
    },
    audit: {
      assetStatusUrl: `https://euaudit.dev.target.com/enterprise_uploads/v1/audit/assets`,
      retryAssetUrl: `https://euaudit.dev.target.com/enterprise_uploads/v1/audit/retry/step`,
    },
    userDetails: {
      usersFromEmailid: `${baseUrlDev}/users_from_emailid`,
      usersList: `${baseUrlDev}/users_teamlist_search`,
      userPreferences: `${baseUrlDev}/user_preferences`,
      userUpdateUrl: 'https://dsutilityapi.dev.target.com/v1/users',
    },
    usageRights: {
      getUsageRightsData: `${baseUrlDev}/usage_rights`,
      getUsageRightsSummary: `${baseUrlDev}/usage_rights/summary`,
      createHolderTypeUrl: `${baseUrlDev}/usage_rights/holder_type`,
      createHolderNameUrl: `${baseUrlDev}/usage_rights/holder_name`,
      addNewBuyer: `${baseUrlDev}/usage_rights/buyer_email`,
    },
    publish: {
      baseUrl:
        'https://digitalassetpublishprocessor-stg.prod.target.com/publish_processor/v1/es/assets/search',
    },
    flags: {
      ENABLE_BIG_SEARCH: true,
      ENABLE_RBA_MODULES: false,
    },
    publishUpload: {
      REACT_APP_DAP_PROCESSOR_BASE_URL:
        'https://digitalassetpublishprocessor-stg.prod.target.com',
      REACT_APP_DAP_API_BASE_URL: 'https://stgapi-internal.target.com',
      REACT_APP_DAP_KEY: 'cc2c7334371b216fdfb7f9119aca15bc81e8b2a5',
      //5 MB
      REACT_APP_CHUNKSIZE: 5242880,
      REACT_APP_NO_CHUNK_MAX: 5,
      REACT_APP_MAXRETRY: 4,
      REACT_APP_TIMEOUT: 10000,
      REACT_APP_ASSET_SEARCH_URL:
        'https://digitalassetpublishprocessor-stg.prod.target.com/publish_processor/v1/es/assets/search',
      publishUploadApi: '/digital_asset_uploads/v1/assets',
      uploadStartApi: '/digital_asset_uploads/v1/chunk_uploads/start',
      uploadChunkApi: '/digital_asset_uploads/v1/chunk_uploads',
      uploadEndApi: '/digital_asset_uploads/v1/chunk_uploads/end',
    },
    channelPublish: {
      baseUrl:
        'https://digitalassetpublishapi-stg.prod.target.com/digital_asset_uploads/v1/channels/',
      channelUrl:
        'https://digitalassetpublishchannelapi-stg.prod.target.com/digital_asset_channel/v1/channels',
    },
    classification: {
      getMetadataTypesUrl: `${baseUrlDev}/asset_classification/v1/all_metadata`,
      getAssetClassificationUrl: `${baseUrlDev}/asset_classification/v1`,
      getAssetClassicationHierarchy: `${baseUrlDev}/asset_classification/v1/hierarchy`,
    },
    enterpriseUploads: {
      euChunkUploadApi: `https://euupload.dev.target.com/enterprise_uploads/v1/files/chunks`,
      euSimpleUploadApi: `https://euupload.dev.target.com/enterprise_uploads/v1/files`,
      streamingServiceApi: `https://eustreaminggoproxy.dev.target.com/enterprise_uploads/streaming/v1`,
      tenantId: 'f4840692-8ce2-4573-8e8d-7d46dc611c3d',
    },
    genai: {
      assetList: `https://eugenai.dev.target.com/enterprise_uploads/v1/generative_ai/assets`,
      assetDetails: `https://eugenai.dev.target.com/enterprise_uploads/v1/generative_ai`,
      addAssetToGenAI: `https://eugenai.dev.target.com/enterprise_uploads/v1/generative_ai/asset`,
      bulkTestsUrl: `https://eugenai.dev.target.com/enterprise_uploads/v1/generative_ai/bulktests`,
      bulkTestReviewUrl: `https://eugenai.dev.target.com/enterprise_uploads/v1/generative_ai/bulktests/review`,
    },
  },
  perf: {
    auth: {
      clientId: 'ahb_npe_im',
    },
    polAuth: {
      authorizationUrl:
        'https://oauth.iam.perf.partnersonline.com/auth/oauth/v2/tgt/authorize/nla/1',
      logoutUrl:
        'https://logonservices.iam.perf.partnersonline.com/login/responses/logoff.html',
      logoutRedirect: `${window.location.origin}/`,
      loginRedirect: `${window.location.origin}/`,
      clientId: 'ahb_npe_im',
    },
    analytics: {
      url: 'https://stage-api.target.com/consumers/v1/ingest/internal/internal_app',
    },
    assethub: {
      host: 'https://assethub.perf.target.com',
      serviceHostMarket: `${baseUrlStgExt}`,
      serviceHostMarketV2: `${euStgUrlExt}`,
      projectDetailsUrl: `${euStgUrlExt}/projects/`,
      uploadsUrl: 'https://stage-api.target.com/assethub_assets/v1/',
      uploadStatusUrl: `${euStgUrlExt}/`,
      feedBackUrl: `${baseUrlStgExt}/jira`,
      taskGroupUsersUrl: `${baseUrlStgExt}/task_group_users`,
      assetVersionsUrl: `${euStgUrlExt}/assets`,
      assetUrl: `${baseUrlStgExt}/asset/`,
      reviewTaskReportUrl: `${baseUrlStgExt}/report/review_task/`,
    },
    annotation: {
      commentsUrl: `${baseUrlStgExt}/asset_review_comments`,
      taskPreviewUrl: `${euStgUrlExt}/asset_review_comments`,
    },
    ext: {
      pipelineUrl: 'https://pipeline-stg.us-central-1.core.k8s.tgt/cgi/review/',
      otmmUrl: 'https://opentext-stg.target.com/',
      ahFolderUoID: '559c47099fb38bff215bb6e359764fb8e239fdb1',
    },
    externalUser: true,
    key: 'c0dfddda4fe6535127e9034bef8864067c189a2d',
    uploadKey: 'c0dfddda4fe6535127e9034bef8864067c189a2d',
    search: {
      baseUrl: 'https://stage-api.target.com/digital_assets/v1/',
      contentSearchUrl: `${euStgUrlExt}/search`,
      contentReverseImageSearchUrl: `${euStgUrlExt}/search/images`,
      serachTemplateUrl: `${baseUrlStgExt}/search_templates`,
      searchAdminTemplateUrl: `${baseUrlStgExt}/search_templates/admin`,
      typeaheadSuggestionsUrl: `${euStgUrlExt}/typeahead_suggestions`,
      internalContentServerUrl:
        'https://stage-api.target.com/vault_digital_assets/v1',
      internalAHContentServerUrl:
        'https://stage-api.target.com/assethub_assets/v1',
    },
    betaSearch: {
      url: `${NONPROD_BASE_V2_URL}/search`,
      suggestionsUrl: `${NONPROD_BASE_V2_URL}/typeahead_suggestions`,
    },
    metadata: {
      assetMetadataUrl: `${euStgUrlExt}/assets/`,
      updateAssetMetadataUrl: `${euStgUrlExt}/metadata`,
      assetRevisionsUrl: `${euStgUrlExt}/asset_revisions/`,
      drmUrl: `${baseUrlStgExt}/usage_rights`,
      productUrl: `${baseUrlStgExt}/product`,
      locationUrl: 'https://stage-api.target.com/locations/v3/public/',
      locationKey: 'y7c2jIEfVqR98SUzkE4evDSsgbtOZHU7',
      catalogUrl: `${baseUrlStgExt}/catalog/metadata`,
      assetDeleteUrl: `${baseUrlStgExt}/asset`,
      bulkUpdateUrl: `${baseUrlStgExt}/asset_metadata/bulk_update`,
      assetMetadataUrlForAdmin: `${euStgUrlExt}/metadata/csv`,
    },
    groups: {
      groupsListUrl: `${baseUrlStgExt}/user_groups`,
      shareListUrl: `${baseUrlStgExt}/shared_groups`,
      collaboratorsUrl: `${baseUrlStgExt}/share_group_details/`,
      collaboratorsDelete: `${baseUrlStgExt}/delete_share_group/`,
      groupCreate: `${baseUrlStgExt}/create_group`,
      groupEdit: `${baseUrlStgExt}/edit_group`,
      groupDelete: `${baseUrlStgExt}/group/`,
      groupUserDetails: `${baseUrlStgExt}/group_user_details/`,
      collaboratorsAdd: `${baseUrlStgExt}/share_group/`,
    },
    boards: {
      boardListUrl: `${euStgUrlExt}/boards`,
      renditionUrl: `${baseUrlStgExt}/renditions/`,
      boardsRootUrl: `${baseUrlStgExt}/boards`,
      boardAssetsUrl: `${euStgUrlExt}/boards/assets/list`,
      addAssetsToBoardUrl: `${euStgUrlExt}/boards/assets`,
      sharedBoardListUrl: `${baseUrlStgExt}/boards/share`,
      boardUsersDetailsUrl: `${baseUrlStgExt}/boards/user`,
    },
    projects: {
      projectsUrl: `${baseUrlStgExt}/user_projects`,
      buildProjectFieldsUrl: `${baseUrlStgExt}/project_details`,
      createProjectUrl: `${baseUrlStgExt}/projects`,
      createCGI_ProjectUrl: `${baseUrlStgExt}/cgi_projects`,
      editProjectUrl: `${baseUrlStgExt}/cgi_projects`,
      trackerUrl: '/project',
      projecTypeUrl: `${baseUrlStgExt}/project_types`,
      assetsUrl: `${baseUrlStgExt}/renditions`,
      renditionUrl: `${baseUrlStgExt}/renditions`,
      projectAssetsUrl: `${baseUrlStgExt}/assets_and_job_status`,
      brandsUrl: `${baseUrlStgExt}/brand_details`,
      setCoverImg: `${baseUrlStgExt}/project/`,
    },
    audit: {
      assetStatusUrl: `https://euaudit-stg.dev.target.com/enterprise_uploads/v1/audit/assets`,
      retryAssetUrl: `https://euaudit-stg.dev.target.com/enterprise_uploads/v1/audit/retry/step`,
    },
    userDetails: {
      usersFromEmailid: `${baseUrlStgExt}/users_from_emailid`,
      usersList: `${baseUrlStgExt}/users_teamlist_search`,
      userPreferences: `${baseUrlStgExt}/user_preferences`,
      userUpdateUrl: 'https://dsutilityapi.dev.target.com/v1/users',
    },
    usageRights: {
      getUsageRightsData: `${baseUrlStgExt}/usage_rights`,
      getUsageRightsSummary: `${baseUrlStgExt}/usage_rights/summary`,
      createHolderTypeUrl: `${baseUrlStgExt}/usage_rights/holder_type`,
      createHolderNameUrl: `${baseUrlStgExt}/usage_rights/holder_name`,
      addNewBuyer: `${baseUrlStgExt}/usage_rights/buyer_email`,
    },
    publish: {
      baseUrl:
        'https://digitalassetpublishprocessor-stg.prod.target.com/publish_processor/v1/es/assets/search',
    },
    flags: {
      ENABLE_BIG_SEARCH: true,
      ENABLE_RBA_MODULES: false,
    },
    publishUpload: {
      REACT_APP_DAP_PROCESSOR_BASE_URL:
        'https://digitalassetpublishprocessor-stg.prod.target.com',
      REACT_APP_DAP_API_BASE_URL: 'https://stgapi-internal.target.com',
      REACT_APP_DAP_KEY: 'cc2c7334371b216fdfb7f9119aca15bc81e8b2a5',
      //5 MB
      REACT_APP_CHUNKSIZE: 5242880,
      REACT_APP_NO_CHUNK_MAX: 5,
      REACT_APP_MAXRETRY: 4,
      REACT_APP_TIMEOUT: 10000,
      REACT_APP_ASSET_SEARCH_URL:
        'https://digitalassetpublishprocessor-stg.prod.target.com/publish_processor/v1/es/assets/search',
      publishUploadApi: '/digital_asset_uploads/v1/assets',
      uploadStartApi: '/digital_asset_uploads/v1/chunk_uploads/start',
      uploadChunkApi: '/digital_asset_uploads/v1/chunk_uploads',
      uploadEndApi: '/digital_asset_uploads/v1/chunk_uploads/end',
    },
    channelPublish: {
      baseUrl:
        'https://digitalassetpublishapi-stg.prod.target.com/digital_asset_uploads/v1/channels/',
      channelUrl:
        'https://digitalassetpublishchannelapi-stg.prod.target.com/digital_asset_channel/v1/channels',
    },
    classification: {
      getMetadataTypesUrl: `${baseUrlStgExt}/asset_classification/v1/all_metadata`,
      getAssetClassificationUrl: `${baseUrlStgExt}/asset_classification/v1`,
      getAssetClassicationHierarchy: `${baseUrlStgExt}/asset_classification/v1/hierarchy`,
    },
    enterpriseUploads: {
      euChunkUploadApi: `${euStgUrlExt}/files/chunks`,
      euSimpleUploadApi: `${euStgUrlExt}/files`,
      streamingServiceApi: `${euStgUrlExt}/streaming`,
      tenantId: 'ff49a9d2-d3c5-4e06-a6f2-2271be952cde',
    },
    genai: {
      assetList: `${euStgUrlExt}/generative_ai/assets`,
      assetDetails: `${euStgUrlExt}/generative_ai`,
      addAssetToGenAI: `${euStgUrlExt}/generative_ai/asset`,
      bulkTestsUrl: `${euStgUrlExt}/generative_ai/bulktests`,
      bulkTestReviewUrl: `${euStgUrlExt}/generative_ai/bulktests/review`,
    },
  },
  prod: {
    auth: {
      authorizationUrl:
        'https://oauth.iam.target.com/auth/oauth/v2/tgt/authorize/ad-pol/1',
      logoutUrl:
        'https://logonservices.iam.target.com/login/responses/logoff.html',
      clientId: 'ahb_prod_im',
    },
    polAuth: {
      authorizationUrl:
        'https://oauth.iam.partnersonline.com/auth/oauth/v2/tgt/authorize/nla/1',
      logoutUrl:
        'https://logonservices.iam.partnersonline.com/login/responses/logoff.html',
      clientId: 'ahb_prod_im',
    },
    analytics: {
      url: 'https://api.target.com/consumers/v1/ingest/internal/internal_app',
    },
    assethub: {
      host: 'https://assethub.target.com',
      serviceHostMarket: `${baseUrlProdExt}`,
      serviceHostMarketV2: `${euProdUrlExt}`,
      projectDetailsUrl: `${euProdUrlExt}/projects/`,
      uploadsUrl: 'https://api.target.com/assethub_assets/v1/',
      uploadStatusUrl: `${euProdUrlExt}/`,
      feedBackUrl: `${baseUrlProdExt}/jira`,
      taskGroupUsersUrl: `${baseUrlProdExt}/task_group_users`,
      assetVersionsUrl: `${euProdUrlExt}/assets`,
      assetUrl: `${baseUrlProdExt}/asset/`,
      reviewTaskReportUrl: `${baseUrlProdExt}/report/review_task/`,
    },
    annotation: {
      commentsUrl: `${baseUrlProdExt}/asset_review_comments`,
      taskPreviewUrl: `${euProdUrlExt}/asset_review_comments`,
    },
    ext: {
      pipelineUrl: 'https://pipeline.target.com/cgi/review/',
      otmmUrl: 'https://opentext.target.com/',
      ahFolderUoID: '495be39c010c58d34c7e149ae428b3aa7a91c1ba',
    },
    externalUser: true,
    key: '8322f3ddb436d78fd9576af8314da67b7ef68377',
    uploadKey: '8322f3ddb436d78fd9576af8314da67b7ef68377',
    search: {
      baseUrl: 'https://api.target.com/digital_assets/v1/',
      contentSearchUrl: `${euProdUrlExt}/search`,
      contentReverseImageSearchUrl: `${euProdUrlExt}/search/images`,
      serachTemplateUrl: `${baseUrlProdExt}/search_templates`,
      searchAdminTemplateUrl: `${baseUrlProdExt}/search_templates/admin`,
      typeaheadSuggestionsUrl: `${euProdUrlExt}/typeahead_suggestions`,
      internalContentServerUrl:
        'https://api.target.com/vault_digital_assets/v1',
      internalAHContentServerUrl: 'https://api.target.com/assethub_assets/v1',
    },
    betaSearch: {
      url: `${PROD_BASE_V2_URL}/search`,
      suggestionsUrl: `${PROD_BASE_V2_URL}/typeahead_suggestions`,
    },
    metadata: {
      assetMetadataUrl: `${euProdUrlExt}/assets/`,
      updateAssetMetadataUrl: `${euProdUrlExt}/metadata`,
      assetRevisionsUrl: `${euProdUrlExt}/asset_revisions/`,
      drmUrl: `${baseUrlProdExt}/usage_rights`,
      productUrl: `${baseUrlProdExt}/product`,
      locationUrl: 'https://api.target.com/locations/v3/public/',
      locationKey: 'y7c2jIEfVqR98SUzkE4evDSsgbtOZHU7',
      catalogUrl: `${baseUrlProdExt}/catalog/metadata`,
      assetDeleteUrl: `${baseUrlProdExt}/asset`,
      bulkUpdateUrl: `${baseUrlProdExt}/asset_metadata/bulk_update`,
      assetMetadataUrlForAdmin: `${euProdUrlExt}/metadata/csv`,
    },
    groups: {
      groupsListUrl: `${baseUrlProdExt}/user_groups`,
      shareListUrl: `${baseUrlProdExt}/shared_groups`,
      collaboratorsUrl: `${baseUrlProdExt}/share_group_details/`,
      collaboratorsDelete: `${baseUrlProdExt}/delete_share_group/`,
      groupCreate: `${baseUrlProdExt}/create_group`,
      groupEdit: `${baseUrlProdExt}/edit_group`,
      groupDelete: `${baseUrlProdExt}/group/`,
      groupUserDetails: `${baseUrlProdExt}/group_user_details/`,
      collaboratorsAdd: `${baseUrlProdExt}/share_group/`,
    },
    boards: {
      boardListUrl: `${euProdUrlExt}/boards`,
      renditionUrl: `${baseUrlProdExt}/renditions/`,
      boardsRootUrl: `${baseUrlProdExt}/boards`,
      boardAssetsUrl: `${euProdUrlExt}/boards/assets/list`,
      addAssetsToBoardUrl: `${euProdUrlExt}/boards/assets`,
      sharedBoardListUrl: `${baseUrlProdExt}/boards/share`,
      boardUsersDetailsUrl: `${baseUrlProdExt}/boards/user`,
    },
    projects: {
      projectsUrl: `${baseUrlProdExt}/user_projects`,
      buildProjectFieldsUrl: `${baseUrlProdExt}/project_details`,
      createProjectUrl: `${baseUrlProdExt}/projects`,
      createCGI_ProjectUrl: `${baseUrlProdExt}/cgi_projects`,
      editProjectUrl: `${baseUrlProdExt}/cgi_projects`,
      trackerUrl: '/project',
      projecTypeUrl: `${baseUrlProdExt}/project_types`,
      assetsUrl: `${baseUrlProdExt}/renditions`,
      renditionUrl: `${baseUrlProdExt}/renditions`,
      projectAssetsUrl: `${baseUrlProdExt}/assets_and_job_status`,
      brandsUrl: `${baseUrlProdExt}/brand_details`,
      setCoverImg: `${baseUrlProdExt}/project/`,
    },
    audit: {
      assetStatusUrl: `https://euaudit.prod.target.com/enterprise_uploads/v1/audit/assets`,
      retryAssetUrl: `https://euaudit.prod.target.com/enterprise_uploads/v1/audit/retry/step`,
    },
    userDetails: {
      usersFromEmailid: `${baseUrlProdExt}/users_from_emailid`,
      usersList: `${baseUrlProdExt}/users_teamlist_search`,
      userPreferences: `${baseUrlProdExt}/user_preferences`,
      userUpdateUrl: 'https://dsutilityapi.prod.target.com/v1/users',
    },
    usageRights: {
      getUsageRightsData: `${baseUrlProdExt}/usage_rights`,
      getUsageRightsSummary: `${baseUrlProdExt}/usage_rights/summary`,
      createHolderTypeUrl: `${baseUrlProdExt}/usage_rights/holder_type`,
      createHolderNameUrl: `${baseUrlProdExt}/usage_rights/holder_name`,
      addNewBuyer: `${baseUrlProdExt}/usage_rights/buyer_email`,
    },
    publishUpload: {
      REACT_APP_DAP_PROCESSOR_BASE_URL:
        'https://digitalassetpublishprocessor.prod.target.com',
      REACT_APP_DAP_API_BASE_URL: 'https://api-internal.target.com',
      REACT_APP_DAP_KEY: '4e066ad7c526e580d2ab4aa40d3f3eaca700e3cb',
      //5 MB
      REACT_APP_CHUNKSIZE: 5242880,
      REACT_APP_NO_CHUNK_MAX: 5,
      REACT_APP_MAXRETRY: 4,
      REACT_APP_TIMEOUT: 10000,
      REACT_APP_ASSET_SEARCH_URL:
        'https://digitalassetpublishprocessor.prod.target.com/publish_processor/v1/es/assets/search',
      publishUploadApi: '/digital_asset_uploads/v1/assets',
      uploadStartApi: '/digital_asset_uploads/v1/chunk_uploads/start',
      uploadChunkApi: '/digital_asset_uploads/v1/chunk_uploads',
      uploadEndApi: '/digital_asset_uploads/v1/chunk_uploads/end',
    },
    channelPublish: {
      baseUrl:
        'https://api-internal.target.com/digital_asset_uploads/v1/channels/',
      channelUrl:
        'https://digitalassetpublishchannelapi.prod.target.com/digital_asset_channel/v1/channels',
    },
    publish: {
      baseUrl:
        'https://digitalassetpublishprocessor.prod.target.com/publish_processor/v1/es/assets/search',
    },
    classification: {
      getMetadataTypesUrl: `${baseUrlProdExt}/asset_classification/v1/all_metadata`,
      getAssetClassificationUrl: `${baseUrlProdExt}/asset_classification/v1`,
      getAssetClassicationHierarchy: `${baseUrlProdExt}/asset_classification/v1/hierarchy`,
    },
    enterpriseUploads: {
      euChunkUploadApi: `${euProdUrlExt}/files/chunks`,
      euSimpleUploadApi: `${euProdUrlExt}/files`,
      streamingServiceApi: `${euProdUrlExt}/streaming`,
      tenantId: '4aaf1c5f-d8b0-4a63-9fd0-b215136b48fe',
    },
    genai: {
      assetList: `${euProdUrlExt}/generative_ai/assets`,
      assetDetails: `${euProdUrlExt}/generative_ai`,
      addAssetToGenAI: `${euProdUrlExt}/generative_ai/asset`,
      bulkTestsUrl: `${euProdUrlExt}/generative_ai/bulktests`,
      bulkTestReviewUrl: `${euProdUrlExt}/generative_ai/bulktests/review`,
    },
    flags: {
      ENABLE_BIG_SEARCH: false,
      ENABLE_RBA_MODULES: false,
    },
  },
}

// env.js sets APP_ENV
const appEnv = process.env.REACT_APP_ENV
const config = envConfigs[appEnv]
const apiConfig = merge(commonConfig, config)

export default apiConfig
