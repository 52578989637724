import { find } from 'lodash'

import { ADMIN_ROLE_ID } from '../../constants/userRoles'
import { isFunctionalTestAdminUser } from '../../helpers/UtilityHelper'

const isGroupAdminUser = (groupUsers = [], userLoginId = '') => {
  const groupUser = find(groupUsers, { login_id: userLoginId }) || {}
  const isGroupAdmin =
    groupUser.role_id === ADMIN_ROLE_ID || isFunctionalTestAdminUser()
      ? true
      : false

  return isGroupAdmin
}

export { isGroupAdminUser }
