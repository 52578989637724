export function updateUserToUserInformation(userInfo = {}) {
  const {
    email = '',
    firstName = '',
    lastName = '',
    title = '',
    company = '',
    memberOf = '',
    displayName = '',
  } = userInfo
  return {
    user_email_address: email,
    first_name: firstName,
    last_name: lastName,
    display_name: displayName,
    title: title,
    organization: company,
    entitlements: getEntitlement(memberOf),
    is_Active: 'Y',
  }
}

export const formatAdGroups = (groups = []) =>
  groups.reduce((result, group) => {
    group.split(',').forEach((attribute) => {
      if (attribute.indexOf('CN=') === 0 || attribute.indexOf('cn=') === 0) {
        result.push(attribute.split(/CN=/gi)[1])
      }
    })
    return result
  }, [])

export function getEntitlement(memberOf = []) {
  const entitlements = []
  memberOf.forEach((member) => {
    const split = member.split(',')[0]
    entitlements.push(split)
  })
  return entitlements
}

export function isInternalUser(email = '') {
  return email?.includes('@target.com')
}

export function getDisplayName(
  email_address = '',
  organization = '',
  classes = {}
) {
  return (
    <span data-cy="userName">
      {isInternalUser(email_address) ? (
        <div className={classes.userEmail}>{email_address}</div>
      ) : (
        <div className={classes.userEmailWithOrg}>
          <span>{email_address}</span>
          <div>{organization}</div>
        </div>
      )}
    </span>
  )
}

export function modifyDisplayName(email = '', organization = '') {
  return !isInternalUser(email) && organization
    ? `${email}(${organization})`
    : email
}
