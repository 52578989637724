import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment/moment'
import { get } from 'lodash'
import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import { Box, Button, Grid, TextField, Chip } from '@mui/material'
import {
  Search as SearchIcon,
  Refresh as RefreshIcon,
} from '@mui/icons-material'

import { mapFiltersToSelectedItems } from '../../../../helpers/publishHelper'
import firefly from '../../../../analytics/firefly'

const styles = makeStyles((theme) => ({
  label: {
    color: 'rgb(88, 88, 88)',
    fontSize: 13,
    marginBottom: 3,
  },
  searchButton: {
    marginLeft: '20px !important',
    maxHeight: '40px !important',
    backgroundColor: '#188295 !important',
    '&:hover': {
      backgroundColor: '#115E6C !important',
    },
  },
  searchIcon: {
    color: 'white',
    marginRight: 5,
  },
  refreshButton: {
    marginLeft: '20px !important',
    maxHeight: '40px !important',
    backgroundColor: 'white',
    color: '#188295',
  },
  refreshIcon: {
    color: '#188295',
    marginRight: 5,
  },
  chip: {
    margin: `${useTheme().spacing(0.5)} ${useTheme().spacing(0.25)}`,
  },
  textField: {
    margin: '0',
    borderRadius: '5px',
  },
  inputInput: {
    width: 'auto',
  },
  inputUnderline: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}))

const TextFilter = ({
  classes = {},
  setPartialFilterValue = () => {},
  fetchAssets = () => {},
  onStartOver = () => {},
  placeholder = '',
  path = '',
  value = '',
  filters = {},
  setFilterValue = () => {},
  lanId = '',
}) => {
  const [selectedItems, setSelectedItems] = useState([])
  const handleChange = (e) => {
    const eventValue = get(e, 'target.value', '')
    // value has to be within an array for elastic search api call
    setPartialFilterValue({ [path]: [eventValue] })
  }

  const submitSearch = (e) => {
    if (e) e.preventDefault()
    firefly.trackPublishSearchClick('publish_search', 'text', value, ``, lanId)
    fetchAssets()
  }

  const handleStartover = (e) => {
    if (e) e.preventDefault()
    firefly.trackPublishSearchClick(
      'publish_search',
      'initial_load',
      '',
      ``,
      lanId
    )
    onStartOver()
    fetchAssets()
  }

  const handleDelete = (filter) => {
    const { props = {} } = filter
    const { data = {} } = props
    const { label = '', value = '' } = data
    if (label === 'date') {
      setFilterValue({ dateFrom: undefined, dateTo: undefined })
      firefly.trackPublishSearchClick(
        'publish_search',
        'filter_change',
        '',
        `fromDate: ${undefined}, toData: ${undefined}`,
        lanId
      )
    } else {
      const newFilters = [...filters[label]]
      const index = newFilters.indexOf(value)
      if (index > -1) {
        newFilters.splice(index, 1)
      }
      setFilterValue({ [label]: newFilters })
      firefly.trackPublishSearchClick(
        'publish_search',
        'filter_change',
        '',
        `${label}: ${newFilters.join(',')}`,
        lanId
      )
    }
    fetchAssets()
  }

  useEffect(() => {
    let newItems = []
    newItems = mapFiltersToSelectedItems(filters)
    setSelectedItems(newItems)
  }, [filters])

  return (
    <Box>
      <form onSubmit={submitSearch} method="GET">
        <Grid container spacing={1}>
          <Grid container item xs={12} md={7} lg={9}>
            <TextField
              variant="outlined"
              value={value}
              fullWidth
              size={'small'}
              placeholder={selectedItems.length ? '' : placeholder}
              onChange={handleChange}
              InputProps={{
                classes: {
                  root: classes.inputUnderline,
                },
                startAdornment: selectedItems.map((item, idx) => (
                  <Chip
                    key={idx}
                    tabIndex={-1}
                    label={item}
                    className={classes.chip}
                    onDelete={() => handleDelete(item)}
                    variant="outlined"
                    size="small"
                  />
                )),
              }}
            />
          </Grid>
          <Grid item container xs={12} md={5} lg={3} alignItems="flex-start">
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              className={classes.searchButton}
            >
              <SearchIcon className={classes.searchIcon} /> Search
            </Button>
            <Button
              color="primary"
              onClick={handleStartover}
              className={classes.refreshButton}
            >
              <RefreshIcon
                className={classes.refreshIcon}
                data-cy="startOverButton"
              />
              START OVER
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  )
}

TextFilter.propTypes = {
  classes: PropTypes.object.isRequired,
  setPartialFilterValue: PropTypes.func.isRequired,
  fetchAssets: PropTypes.func.isRequired,
  onStartOver: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  value: PropTypes.string,
  filters: PropTypes.object.isRequired,
  setFilterValue: PropTypes.func.isRequired,
  lanId: PropTypes.string.isRequired,
}

const MyComponent = (props) => {
  const classes = styles()
  return <TextFilter {...props} classes={classes} />
}

export default MyComponent
