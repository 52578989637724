export const REQUEST_CHANNEL = 'REQUEST_CHANNEL'
export const RECEIVE_CHANNEL = 'RECEIVE_CHANNEL'
export const CHANGE_SELECTED_TEAM = 'CHANGE_SELECTED_TEAM'
export const SET_MEMBERS_COUNT = 'SET_MEMBERS_COUNT'
export const SET_ASSETS_COUNT = 'SET_ASSETS_COUNT'
export const SET_CHANNEL_MEMBERS = 'SET_CHANNEL_MEMBERS'
export const SET_CHANNEL_ASSETS = 'SET_CHANNEL_ASSETS'
export const SET_CHANNEL_SORT_FIELD = 'SET_CHANNEL_SORT_FIELD'
export const SET_CHANNEL_ASSET_FILTER = 'SET_CHANNEL_ASSET_FILTER'
export const SELECT_CHANNEL_ASSET_EVENT = 'SELECT_CHANNEL_ASSET_EVENT'
export const SET_CHANNEL_SORT_DIRECTION = 'SET_CHANNEL_SORT_DIRECTION'
export const CHANGE_CHANNEL_ASSETS_PAGE = 'CHANGE_CHANNEL_ASSETS_PAGE'
export const CHANGE_CHANNEL_ASSET_PAGE_SIZE = 'CHANGE_CHANNEL_ASSET_PAGE_SIZE'
export const RESET_CHANNEL_ASSET_PARAMS = 'RESET_CHANNEL_ASSET_PARAMS'
export const REMOVE_FROM_CHANNEL_PENDING_LIST =
  'REMOVE_FROM_CHANNEL_PENDING_LIST'
export const UPDATE_IMAGE_TO_CHANNEL_PENDING_LIST =
  'UPDATE_IMAGE_TO_CHANNEL_PENDING_LIST'
export const ADD_IMAGE_TO_CHANNEL_PENDING_LIST =
  'ADD_IMAGE_TO_CHANNEL_PENDING_LIST'
