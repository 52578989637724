import idx from 'idx'
import {
  GET_TASK_ASSETS_PENDING,
  GET_TASK_ASSETS_FULFILLED,
  GET_TASK_ASSETS_REJECTED,
  GET_TASK_USERS_FULFILLED,
  GET_TASK_USERS_REJECTED,
  GET_ASSET_COMMENTS_PENDING,
  GET_ASSET_COMMENTS_FULFILLED,
  GET_ASSET_COMMENTS_REJECTED,
  GET_SECONDARY_ASSET_COMMENTS_FULFILLED,
  GET_SECONDARY_ASSET_COMMENTS_PENDING,
  GET_SECONDARY_ASSET_COMMENTS_REJECTED,
  FETCH_ASSET_VERSIONS_PENDING,
  FETCH_ASSET_VERSIONS_FULFILLED,
  FETCH_ASSET_VERSIONS_REJECTED,
  FETCH_SECONDARY_ASSET_VERSIONS_FULFILLED,
  FETCH_SECONDARY_ASSET_VERSIONS_PENDING,
  FETCH_SECONDARY_ASSET_VERSIONS_REJECTED,
  CREATE_COMMENT_REJECTED,
  UPDATE_COMMENT_REJECTED,
  DELETE_COMMENT_REJECTED,
  STORE_CURRENT_MARKUP,
  CLEAR_CURRENT_MARKUP,
  SET_PRIMARY_ASSET,
  SET_SECONDARY_ASSET,
  TOGGLE_COMPARISON_VIEW,
  SET_PRIMARY_VERSION,
  SET_SECONDARY_VERSION,
  SET_PRIMARY_PAGE_NUMBER,
  SET_SECONDARY_PAGE_NUMBER,
  RESET_ASSETS,
  RESET_PRIMARY_COMMENTS,
  RESET_SECONDARY_COMMENTS,
  GET_ALL_COMMENT_INFO_FULFILLED,
  GET_ALL_COMMENT_INFO_REJECTED,
  SET_PRIMARY_ASSET_REVISION_HISTORY,
  SET_SECONDARY_ASSET_REVISION_HISTORY,
  FROM_COMPARISON_TO_SINGLE,
  SET_TASK_STATUS_FULFILLED,
  RESOLVE_COMMENT_REJECTED,
} from './actionTypes'
import { buildCommentNumberDictionary } from '../../helpers/annotationHelper'
import { sortMyArray } from '../../helpers/UtilityHelper'

export const initialState = {
  currentMarkup: {},
  assets: [],
  taskUsers: [],
  comments: [],
  secondaryComments: [],
  primaryAsset: {},
  primaryVersion: 1,
  primaryAssetVersions: [],
  primaryPageNumber: 1,
  primaryViewedUsers: [],
  secondaryAsset: {},
  secondaryVersion: 1,
  secondaryAssetVersions: [],
  secondaryPageNumber: 1,
  secondaryViewedUsers: [],
  errors: null,
  isFetching: false,
  isFetchingComments: false,
  isComparisonView: false,
  primaryAssetRevisionHistory: {},
  secondaryAssetRevisionHistory: {},
  fromComparisonToSingle: false,
}

export default function annotationReducer(state = initialState, action = {}) {
  const { payload = {} } = action
  const { assets = [], status } = payload.data || {}
  let errors = null
  switch (action.type) {
    //GET ASSETS
    case GET_TASK_ASSETS_FULFILLED:
      return {
        ...state,
        isFetching: false,
        ...payload.data,
        assets: sortMyArray(assets, 'asset_name', 'asc'),
      }
    case GET_TASK_ASSETS_PENDING:
      return {
        ...state,
        isFetching: true,
      }
    case GET_TASK_ASSETS_REJECTED:
      errors = idx(payload, (_) => _.response.data)
      return {
        ...state,
        errors,
        isFetching: false,
      }

    // GET ALL COMMENT INFO
    case GET_ALL_COMMENT_INFO_FULFILLED:
      return {
        ...state,
        allCommentInfo: buildCommentNumberDictionary(payload.data),
      }
    case GET_ALL_COMMENT_INFO_REJECTED:
      errors = idx(payload, (_) => _.response.data)
      return {
        ...state,
        errors,
        isFetching: false,
      }
    //GET TASK USERS
    case GET_TASK_USERS_FULFILLED:
      return {
        ...state,
        taskUsers: payload.data,
      }
    case GET_TASK_USERS_REJECTED:
      errors = idx(payload, (_) => _.response.data)
      return {
        ...state,
        errors,
      }
    // GET COMMENTS
    case GET_ASSET_COMMENTS_FULFILLED:
      return {
        ...state,
        comments: payload.data,
        isFetchingComments: false,
      }
    case GET_ASSET_COMMENTS_PENDING:
      return {
        ...state,
        isFetchingComments: true,
      }
    case GET_ASSET_COMMENTS_REJECTED:
      errors = idx(payload, (_) => _.response.data)
      return {
        ...state,
        errors,
        isFetchingComments: false,
      }
    case GET_SECONDARY_ASSET_COMMENTS_FULFILLED:
      return {
        ...state,
        secondaryComments: payload.data,
        isFetchingComments: false,
      }
    case GET_SECONDARY_ASSET_COMMENTS_PENDING:
      return {
        ...state,
        isFetchingComments: true,
      }
    case GET_SECONDARY_ASSET_COMMENTS_REJECTED:
      errors = idx(payload, (_) => _.response.data)
      return {
        ...state,
        errors,
        isFetchingComments: false,
      }
    case RESET_ASSETS:
      return {
        ...state,
        currentMarkup: {},
        assets: [],
        taskUsers: [],
        comments: [],
        secondaryComments: [],
        primaryAsset: {},
        primaryVersion: 1,
        primaryAssetVersions: [],
        primaryPageNumber: 1,
        primaryViewedUsers: [],
        secondaryAsset: {},
        secondaryVersion: 1,
        secondaryAssetVersions: [],
        secondaryPageNumber: 1,
        secondaryViewedUsers: [],
        errors: null,
        isFetching: false,
        isFetchingComments: false,
        isComparisonView: false,
        primaryAssetRevisionHistory: {},
        secondaryAssetRevisionHistory: {},
        fromComparisonToSingle: false,
      }
    // FETCH ASSET VERSIONS
    case FETCH_ASSET_VERSIONS_FULFILLED:
      return {
        ...state,
        primaryAssetVersions: payload.data,
      }
    case FETCH_ASSET_VERSIONS_PENDING:
      return {
        ...state,
      }
    case FETCH_ASSET_VERSIONS_REJECTED:
      errors = idx(payload, (_) => _.response.data)
      return {
        ...state,
        errors,
      }
    case FETCH_SECONDARY_ASSET_VERSIONS_FULFILLED:
      return {
        ...state,
        secondaryAssetVersions: payload.data,
      }
    case FETCH_SECONDARY_ASSET_VERSIONS_PENDING:
      return {
        ...state,
      }
    case FETCH_SECONDARY_ASSET_VERSIONS_REJECTED:
      errors = idx(payload, (_) => _.response.data)
      return {
        ...state,
        errors,
      }
    // HANDLE CREATE COMMENT ERRORS
    case CREATE_COMMENT_REJECTED:
      errors = idx(payload, (_) => _.response.data)
      return {
        ...state,
        errors,
      }
    // HANDLE UPDATE COMMENT ERRORS
    case UPDATE_COMMENT_REJECTED:
      errors = idx(payload, (_) => _.response.data)
      return {
        ...state,
        errors,
      }
    // HANDLE DELETE COMMENT ERRORS
    case DELETE_COMMENT_REJECTED:
      errors = idx(payload, (_) => _.response.data)
      return {
        ...state,
        errors,
      }
    // HANDLE RESOLVE COMMENT ERRORS
    case RESOLVE_COMMENT_REJECTED:
      errors = idx(payload, (_) => _.response.data)
      return {
        ...state,
        errors,
      }
    // CURRENT MARKUP
    case STORE_CURRENT_MARKUP:
      return {
        ...state,
        currentMarkup: payload,
      }
    case CLEAR_CURRENT_MARKUP:
      return {
        ...state,
        currentMarkup: {},
      }
    // Handle local selected Assets
    case SET_PRIMARY_ASSET:
      return {
        ...state,
        primaryAsset: payload,
      }
    case SET_SECONDARY_ASSET:
      return {
        ...state,
        secondaryAsset: payload,
      }
    case TOGGLE_COMPARISON_VIEW:
      return {
        ...state,
        isComparisonView: payload,
      }
    case SET_PRIMARY_VERSION:
      return {
        ...state,
        primaryVersion: payload,
      }
    case SET_SECONDARY_VERSION:
      return {
        ...state,
        secondaryVersion: payload,
      }
    case SET_PRIMARY_PAGE_NUMBER:
      return {
        ...state,
        primaryPageNumber: payload,
      }
    case SET_SECONDARY_PAGE_NUMBER:
      return {
        ...state,
        secondaryPageNumber: payload,
      }
    case RESET_PRIMARY_COMMENTS:
      return {
        ...state,
        comments: [],
      }
    case RESET_SECONDARY_COMMENTS:
      return {
        ...state,
        secondaryComments: [],
      }
    case SET_PRIMARY_ASSET_REVISION_HISTORY: {
      let primaryAssetRevisions = { ...state.primaryAssetRevisionHistory }
      primaryAssetRevisions[payload.assetMasterId] = payload
      return {
        ...state,
        primaryAssetRevisionHistory: primaryAssetRevisions,
      }
    }
    case SET_SECONDARY_ASSET_REVISION_HISTORY: {
      let secondaryAssetRevisions = { ...state.secondaryAssetRevisionHistory }
      secondaryAssetRevisions[payload.assetMasterId] = payload
      return {
        ...state,
        secondaryAssetRevisionHistory: secondaryAssetRevisions,
      }
    }
    case FROM_COMPARISON_TO_SINGLE:
      return {
        ...state,
        fromComparisonToSingle: true,
      }
    case SET_TASK_STATUS_FULFILLED: {
      const task_info = { ...state.task_info, status }
      return {
        ...state,
        task_info,
      }
    }
    default:
      return state
  }
}
