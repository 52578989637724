const selectGetTaskInfo =
  () =>
  ({ task = {} }) =>
    task
const selectIsTaskInProgress =
  () =>
  ({ task = {} }) =>
    task.inProgress || false

export { selectGetTaskInfo, selectIsTaskInProgress }
