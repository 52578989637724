export const GET_USAGE_RIGHTS_DATALIST = 'GET_USAGE_RIGHTS_DATALIST'
export const GET_USAGE_RIGHTS_SUMMARY = 'GET_USAGE_RIGHTS_SUMMARY'
export const CREATE_RIGHTS_HOLDER_TYPE = 'CREATE_RIGHTS_HOLDER_TYPE'
export const CREATE_RIGHTS_HOLDER_TYPE_FULFILLED =
  'CREATE_RIGHTS_HOLDER_TYPE_FULFILLED'
export const CREATE_RIGHTS_HOLDER_TYPE_PENDING =
  'CREATE_RIGHTS_HOLDER_TYPE_PENDING'
export const CREATE_RIGHTS_HOLDER_TYPE_REJECTED =
  'CREATE_RIGHTS_HOLDER_TYPE_REJECTED'
export const CREATE_NEW_RECORD_USAGE_RIGHTS = 'CREATE_NEW_RECORD_USAGE_RIGHTS'
export const GET_USAGE_RIGHTS_DRMID_DATA = 'GET_USAGE_RIGHTS_DRMID_DATA'
export const CREATE_RIGHTS_HOLDER_NAME = 'CREATE_RIGHTS_HOLDER_NAME'
export const CREATE_NEW_BUYER = 'CREATE_NEW_BUYER'
export const CREATE_HOLDER_TYPE_REJECTED = 'CREATE_HOLDER_TYPE_REJECTED'
export const CREATE_HOLDER_TYPE_FULFILLED = 'CREATE_HOLDER_TYPE_FULFILLED'
