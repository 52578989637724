import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { cloneDeep } from 'lodash'

import {
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Switch,
  Chip,
  CircularProgress,
  IconButton,
  FormHelperText,
  Select,
  MenuItem,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Error as ErrorIcon,
} from '@mui/icons-material'

import CollapsibleCard from '../../components/CustomCards/CollapsibleCard'
import MetadataExpansionPanel from '../../components/Custom/ExpansionPanel'
import CatalogTypeahead from '../../components/Custom/SimpleTypeahead'
import {
  METADATA_CARD_SHOW_BUTTON_TEXT,
  METADATA_CARD_HIDE_BUTTON_TEXT,
  METADATA_CARD_PRODUCT_INFO_TITLE,
  METADATA_CARD_STORE_INFO_TITLE,
  METADATA_CARD_ASSET_DESC_TITLE,
  METADATA_CARD_PROJECT_TITLE,
  METADATA_CARD_USER_TAGS,
  METADATA_LOOKUP_ERROR,
  METADATA_LOOKUP_STORE_ERROR,
  METADATA_LOOKUP_PRODUCT_ERROR,
  ALLOWED_ACTION_KEYS,
  METADATA_CARD_ASSET_CLASSIFICATION,
  DISPLAY_BRAND_CLASSIFICATION_TYPES,
  METADATA_LOOKUP_DPCI_ERROR,
  METADATA_LOOKUP_TCIN_ERROR,
} from '../../constants/metadata'
import { convertUTCtoLocal, formatCSTDate } from '../../helpers/dateHelper'
import {
  getFromBrowserStorage,
  filterArrayByInsensitiveField,
  isEmptyObject,
} from '../../helpers/UtilityHelper'
import {
  tcinRegex,
  metadataStoreNoRegex,
  dpciWithHyphenRegex,
  dpciWithoutHyphenRegex,
} from '../../helpers/Regexes'
import { getNonProductBrand } from '../../helpers/MetadataHelper'
import {
  fetchProductData,
  fetchStoreLocationData,
} from '../../store/metadata/actionCreator'
import BrandAutoComplete from './BrandAutoComplete'

const styles = makeStyles((theme) => ({
  panelHeading: {
    fontSize: useTheme().typography.pxToRem(18),
    fontWeight: useTheme().typography.fontWeightRegular,
  },
  panelContents: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '8px 16px 24px',
  },
  panelContentsFullWidth: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '8px 0px 24px',
  },
  panelFormControl: {
    minWidth: '150px !important',
    margin: '8px 50px 8px 8px !important',
  },
  chipFormControl: {
    marginLeft: '40px !important',
    minWidth: '40% !important',
  },
  chipComponent: {
    marginRight: '10px',
  },
  metadataRow: {
    '&:hover': {
      '& .tableCellChip': {
        backgroundColor: useTheme().palette.primary.lighterGrey,
      },
    },
  },
  collapseEntered: {
    overflow: 'unset',
  },
  switchChecked: {
    color: useTheme().palette.primary.dodgerBlue + ' !important',
    '& + $colorBar': {
      backgroundColor: useTheme().palette.primary.dodgerBlue + ' !important',
    },
  },
  colorBar: {},
}))

export class LibrarianInfoCard extends React.Component {
  constructor(props) {
    super(props)
    const { libMetadata = {}, metadataParentTimestamp = 0 } = props
    const metadataPanel = this.getPanelStatus()
    this.state = {
      libMetadata: cloneDeep(libMetadata),
      metadataParentTimestamp,
      assetTypes: [],
      ...metadataPanel,
    }
  }

  componentDidUpdate(prevProps) {
    const {
      libMetadata = {},
      metadataParentTimestamp = 0,
      storedCatalog = {},
    } = this.props
    const { assetClassification: availableClassifications = {} } = storedCatalog
    let assetTypes = []
    if (
      !isEmptyObject(availableClassifications) &&
      this.state.assetTypes.length == 0
    ) {
      const { assetClassifications: stateAssetClassifications = {} } =
        libMetadata
      const { classifications_name: secondaryClassifications = [] } =
        stateAssetClassifications

      availableClassifications.map((classification) => {
        if (classification.classification_name === secondaryClassifications) {
          assetTypes = [...classification.asset_types]
        }
      })
      if (
        secondaryClassifications &&
        prevProps.libMetadata.assetClassifications.secondaryClassifications !==
          secondaryClassifications
      ) {
        this.setState({ assetTypes })
      }
    }

    if (prevProps.metadataParentTimestamp !== metadataParentTimestamp) {
      const metadataPanel = this.getPanelStatus()
      this.setState({
        libMetadata: cloneDeep(libMetadata),
        metadataParentTimestamp,
        ...metadataPanel,
      })
    }
  }

  getPanelStatus = () => ({
    panelProductInformation: this.getCollapseStatus(
      METADATA_CARD_PRODUCT_INFO_TITLE,
    ),
    panelStoreInformation: this.getCollapseStatus(
      METADATA_CARD_STORE_INFO_TITLE,
    ),
    panelAssetDescriptions: this.getCollapseStatus(
      METADATA_CARD_ASSET_DESC_TITLE,
    ),
    panelProject: this.getCollapseStatus(METADATA_CARD_PROJECT_TITLE),
    panelUserTags: this.getCollapseStatus(METADATA_CARD_USER_TAGS),
    panelAssetClassifications: this.getCollapseStatus(
      METADATA_CARD_ASSET_CLASSIFICATION,
    ),
  })

  getCollapseStatus = (title) => {
    const { getStorageIdentifier = () => {} } = this.props
    const identifier = getStorageIdentifier(title)
    const isExpanded = getFromBrowserStorage(identifier) !== 'true'
    return isExpanded
  }

  panelCollapseHandler = (event, expanded) => {
    const { collapseCallback = () => {} } = this.props
    const identifier = event.currentTarget.getAttribute('identifier')
    collapseCallback(identifier, !expanded)
    const panelKey = `panel ${identifier}`.replace(/ /g, '')
    this.setState({ [`${panelKey}`]: expanded })
  }

  discardChanges = () => {
    // discard the changes
    this.setState((state, props) => {
      const { libMetadata = {} } = props
      return {
        libMetadata: cloneDeep(libMetadata),
        unsavedChanges: false,
        disableSave: false,
      }
    })
  }

  saveChanges = () => {
    // save the changes
    const { libMetadata = {} } = this.state
    const { saveMetadata = () => {} } = this.props
    const updatedAssetDetails = {
      ...libMetadata,
    }
    saveMetadata(updatedAssetDetails)
  }

  updateStateCB = () => {
    const { updateParentState = () => {}, isMetadataFormEdited = () => {} } =
      this.props
    const { libMetadata = {}, unsavedChanges } = this.state
    // Update parent state
    const updatedAssetDetails = {
      ...libMetadata,
    }
    updateParentState(updatedAssetDetails)
    isMetadataFormEdited(unsavedChanges)
  }

  addProductRow = () => {
    // add new row
    const emptyRow = {
      dpci: '',
      tcin: '',
      brandName: '',
      productDescription: '',
    }
    this.setState((state) => {
      const { libMetadata = {} } = state
      const { productInformation = [] } = libMetadata
      productInformation.unshift(emptyRow)
      return {
        libMetadata: Object.assign({}, libMetadata, { productInformation }),
        unsavedChanges: true,
        disableSave: true,
      }
    }, this.updateStateCB)
  }

  deleteProductRow = (identifier) => () => {
    // delete a row
    this.setState((state) => {
      const { libMetadata = {} } = state
      const { productInformation = [] } = libMetadata
      if (productInformation?.length) {
        const deletedProductInfo = productInformation.splice(identifier, 1)
        let newLibrarianMetadata = Object.assign({}, libMetadata, {
          productInformation,
        })
        if (deletedProductInfo) {
          const nonProductInfo = getNonProductBrand(deletedProductInfo)
          if (nonProductInfo && !isEmptyObject(nonProductInfo)) {
            newLibrarianMetadata.userDefinedBrandName = ''
          }
        }
        return {
          libMetadata: newLibrarianMetadata,
          unsavedChanges: true,
          disableSave: false,
        }
      }
    }, this.updateStateCB)
  }

  handleChangeProductRow = (fieldName, identifier) => (event) => {
    // handle changes on the DPCI or TCIN input field
    const inputValue = event.target.value || ''
    this.setState((state) => {
      const { libMetadata = {} } = state
      const { productInformation = [] } = libMetadata
      productInformation[identifier][fieldName] = inputValue
      if (inputValue === '') {
        productInformation[identifier]['isError'] = false
        productInformation[identifier]['isSystemError'] = false
      }
      return {
        libMetadata: Object.assign({}, libMetadata, { productInformation }),
        unsavedChanges: true,
        disableSave: true,
      }
    }, this.updateStateCB)
  }

  handleFetchProductData = (fieldName, identifier) => (event) => {
    // fetch from Product API on tab out of DPCI or TCIN input field
    if (ALLOWED_ACTION_KEYS.indexOf(event.keyCode) !== -1) {
      const inputValue = event.target.value || ''
      if (inputValue) {
        let keyword
        if (fieldName === 'tcin') {
          keyword = inputValue.match(tcinRegex)
        } else if (fieldName === 'dpci') {
          keyword =
            inputValue.match(dpciWithHyphenRegex) ||
            inputValue.match(dpciWithoutHyphenRegex)
        }
        if (keyword) {
          this.toggleProductLoader(identifier, true)
          fetchProductData(
            fieldName,
            keyword,
            identifier,
            this.productPromiseHandler,
          )
        } else {
          this.setState((state) => {
            const { libMetadata = {} } = state
            const { productInformation = [] } = libMetadata
            productInformation[identifier]['isError'] = true
            return {
              libMetadata: Object.assign({}, libMetadata, {
                productInformation,
              }),
            }
          })
        }
      }
    }
    return false
  }

  toggleProductLoader = (identifier, value = false) => {
    // show or hide loader in the row
    this.setState((state) => {
      const { libMetadata = {} } = state
      const { productInformation = [] } = libMetadata
      productInformation[identifier]['showProgress'] = value
      return {
        libMetadata: Object.assign({}, libMetadata, { productInformation }),
      }
    })
  }

  productPromiseHandler = (requestPayload, response = {}, type) => {
    const { identifier } = requestPayload
    let unsavedChanges, disableSave, updatedRow
    if (type === 'success') {
      if (Object.keys(response).length) {
        const {
          dpci = '',
          product_brand: productBrand = {},
          product_description: productDescription = {},
          brands = [],
          tcin = '',
        } = response
        const { brand = '' } = productBrand
        const { title: productTitle = '' } = productDescription
        let brandName = brand
        if (!brand && brands && brands.length > 0) {
          const { name = '' } = brands[0]
          brandName = name
        }

        updatedRow = {
          dpci,
          tcin,
          brandName: brandName,
          productDescription: productTitle,
          showProgress: false,
          isError: false,
        }
        unsavedChanges = true
        disableSave = false
      } else {
        updatedRow = {
          showProgress: false,
          isError: true,
          isSystemError: false,
        }
        unsavedChanges = true
        disableSave = true
      }
    } else {
      updatedRow = {
        showProgress: false,
        isError: false,
        isSystemError: true,
      }
      unsavedChanges = true
      disableSave = true
    }
    this.setState((state) => {
      const { libMetadata = {} } = state
      const { productInformation = [] } = libMetadata
      productInformation[identifier] = Object.assign(
        {},
        { ...productInformation[identifier] },
        updatedRow,
      )
      return {
        usageInformation: cloneDeep({
          ...libMetadata,
          ...{ productInformation },
        }),
        unsavedChanges: unsavedChanges,
        disableSave: disableSave,
      }
    }, this.updateStateCB)
  }

  productInfoWidget = () => {
    const {
      containerClasses = {},
      classes = {},
      editPermissions = {},
    } = this.props
    const cardTitle = METADATA_CARD_PRODUCT_INFO_TITLE
    const {
      libMetadata = {},
      panelProductInformation,
      disableSave = false,
    } = this.state
    const { productInformation = [] } = libMetadata
    const { canEdit } = editPermissions
    const isExpanded = panelProductInformation
    return (
      <MetadataExpansionPanel
        containerClasses={classes}
        cardTitle={cardTitle}
        isExpanded={isExpanded}
        fullWidthContent
        collapseHandler={this.panelCollapseHandler}
      >
        <div className={containerClasses.metadataCardActionWrapper}>
          {canEdit && (
            <IconButton
              data-cy="addProduct"
              onClick={this.addProductRow}
              aria-label="Add Row"
              disabled={disableSave}
              size="large"
            >
              <AddIcon />
            </IconButton>
          )}
        </div>
        <Table className={containerClasses.metadataTable}>
          <TableBody>
            {productInformation?.length > 0 &&
              productInformation.map((productInfoItem, key) => (
                <TableRow
                  key={key}
                  className={containerClasses.metadataTableRow}
                >
                  <TableCell className={containerClasses.metadataTableCell}>
                    <div className={containerClasses.metadataTableCellAction}>
                      {canEdit && (
                        <Fragment>
                          {productInfoItem.showProgress && (
                            <CircularProgress
                              className={containerClasses.progressSpinner}
                            />
                          )}
                          {!productInfoItem.showProgress &&
                            !productInfoItem.isError &&
                            !productInfoItem.isSystemError && (
                              <div
                                data-cy="deleteProduct"
                                className={
                                  containerClasses.metadataTableCellActionBtn +
                                  ' actionBtnHover'
                                }
                                aria-label="Delete Row"
                                onClick={this.deleteProductRow(key)}
                              >
                                <DeleteIcon color="inherit" />
                              </div>
                            )}
                          {!productInfoItem.showProgress &&
                            (productInfoItem.isError ||
                              productInfoItem.isSystemError) && (
                              <span className="error">
                                <ErrorIcon />
                              </span>
                            )}
                        </Fragment>
                      )}
                    </div>
                  </TableCell>
                  <TableCell className={containerClasses.metadataTableCell}>
                    <FormControl
                      variant="standard"
                      className={containerClasses.formControl}
                      disabled={!canEdit || productInfoItem.nonProduct}
                      error={
                        productInfoItem.isError || productInfoItem.isSystemError
                      }
                    >
                      <InputLabel htmlFor={`urDpci${key}`}>DPCI</InputLabel>
                      <Input
                        id={`urDpci${key}`}
                        onChange={this.handleChangeProductRow('dpci', key)}
                        onKeyDown={this.handleFetchProductData('dpci', key)}
                        value={productInfoItem.dpci}
                        inputProps={{ 'data-cy': 'dcpiInput' }}
                      />
                      {productInfoItem.dpci && productInfoItem.isError && (
                        <FormHelperText className={containerClasses.errorStyle}>
                          {METADATA_LOOKUP_DPCI_ERROR}
                        </FormHelperText>
                      )}
                      {productInfoItem.isSystemError && (
                        <FormHelperText className={containerClasses.errorStyle}>
                          {METADATA_LOOKUP_ERROR}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </TableCell>
                  <TableCell className={containerClasses.metadataTableCell}>
                    <FormControl
                      variant="standard"
                      className={containerClasses.formControl}
                      disabled={!canEdit || productInfoItem.nonProduct}
                      error={productInfoItem.isError}
                    >
                      <InputLabel htmlFor={`urTcin${key}`}>TCIN</InputLabel>
                      <Input
                        id={`urTcin${key}`}
                        onChange={this.handleChangeProductRow('tcin', key)}
                        onKeyDown={this.handleFetchProductData('tcin', key)}
                        value={productInfoItem.tcin}
                        inputProps={{ 'data-cy': 'tcinInput' }}
                      />
                      {productInfoItem.tcin && productInfoItem.isError && (
                        <FormHelperText className={containerClasses.errorStyle}>
                          {METADATA_LOOKUP_TCIN_ERROR}
                        </FormHelperText>
                      )}
                      {productInfoItem.isSystemError && (
                        <FormHelperText className={containerClasses.errorStyle}>
                          {METADATA_LOOKUP_ERROR}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </TableCell>
                  <TableCell className={containerClasses.metadataTableCell}>
                    <FormControl
                      variant="standard"
                      className={containerClasses.formControl}
                      disabled
                    >
                      <InputLabel htmlFor={`urBrandName${key}`} shrink>
                        Brand Name
                      </InputLabel>
                      <div className={containerClasses.formStaticWrap}>
                        <span
                          data-cy="productBrandName"
                          id={`urBrandName${key}`}
                          className={containerClasses.formStaticText}
                          dangerouslySetInnerHTML={{
                            __html: productInfoItem.brandName,
                          }}
                        />
                      </div>
                    </FormControl>
                  </TableCell>
                  <TableCell className={containerClasses.metadataTableCell}>
                    <FormControl
                      variant="standard"
                      className={containerClasses.formControl}
                      disabled
                    >
                      <InputLabel htmlFor={`urProductDescription${key}`} shrink>
                        Product Description
                      </InputLabel>
                      <div className={containerClasses.formStaticWrap}>
                        <span
                          data-cy="productDescription"
                          id={`urProductDescription${key}`}
                          className={containerClasses.formStaticText}
                          dangerouslySetInnerHTML={{
                            __html: productInfoItem.productDescription,
                          }}
                        />
                      </div>
                    </FormControl>
                    <FormControl
                      variant="standard"
                      className={containerClasses.formControl}
                      disabled
                    >
                      <InputLabel htmlFor={`urBarcode${key}`} shrink>
                        Barcode
                      </InputLabel>
                      <div className={containerClasses.formStaticWrap}>
                        <span
                          data-cy="productBarcode"
                          id={`urBarcode${key}`}
                          className={containerClasses.formStaticText}
                          dangerouslySetInnerHTML={{
                            __html: productInfoItem.barcode,
                          }}
                        />
                      </div>
                    </FormControl>
                  </TableCell>
                </TableRow>
              ))}
            {productInformation?.length === 0 && (
              <TableRow>
                <TableCell>
                  <div className={containerClasses.noResults}>
                    No details available
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </MetadataExpansionPanel>
    )
  }

  addStoreInfoRow = () => {
    // add new row
    const emptyRow = {
      storeNumber: '',
      propertyName: '',
      streetAddress: '',
      city: '',
      storeLocationDescription: [],
    }
    this.setState((state) => {
      const { libMetadata = {} } = state
      const { storeInformation = [] } = libMetadata
      storeInformation.unshift(emptyRow)
      return {
        libMetadata: Object.assign({}, libMetadata, { storeInformation }),
        unsavedChanges: true,
        disableSave: true,
      }
    }, this.updateStateCB)
  }

  deleteStoreInfoRow = (identifier) => () => {
    // delete a row
    this.setState((state) => {
      const { libMetadata = {} } = state
      const { storeInformation = [] } = libMetadata
      storeInformation.splice(identifier, 1)
      return {
        libMetadata: Object.assign({}, libMetadata, { storeInformation }),
        unsavedChanges: true,
        disableSave: false,
      }
    }, this.updateStateCB)
  }

  handleChangeStoreInfoRow = (identifier) => (event) => {
    // handle changes on the Store Number input field
    const inputValue = event.target.value || ''
    this.setState((state) => {
      const { libMetadata = {} } = state
      const { storeInformation = [] } = libMetadata
      storeInformation[identifier]['storeNumber'] = inputValue
      if (inputValue === '') {
        storeInformation[identifier]['isError'] = false
        storeInformation[identifier]['isSystemError'] = false
      }
      return {
        libMetadata: Object.assign({}, libMetadata, { storeInformation }),
        unsavedChanges: true,
        disableSave: true,
      }
    }, this.updateStateCB)
  }

  handleFetchStoreLocationData = (identifier) => (event) => {
    // fetch from Store Location API on tab out of Store Number input field
    if (ALLOWED_ACTION_KEYS.indexOf(event.keyCode) !== -1) {
      const storeId = event.target.value || ''
      if (storeId) {
        const storeIdNo = storeId.match(metadataStoreNoRegex)
        if (storeIdNo) {
          this.toggleStoreLocationLoader(identifier, true)
          fetchStoreLocationData(
            storeIdNo,
            identifier,
            this.storeLocationPromiseHandler,
          )
        } else {
          this.setState((state) => {
            const { libMetadata = {} } = state
            const { storeInformation = [] } = libMetadata
            storeInformation[identifier]['isError'] = true
            return {
              libMetadata: Object.assign({}, libMetadata, { storeInformation }),
            }
          })
        }
      }
    }
    return false
  }

  toggleStoreLocationLoader = (identifier, value = false) => {
    // show or hide loader in the row
    this.setState((state) => {
      const { libMetadata = {} } = state
      const { storeInformation = [] } = libMetadata
      storeInformation[identifier]['showProgress'] = value
      return {
        libMetadata: Object.assign({}, libMetadata, { storeInformation }),
      }
    })
  }

  storeLocationPromiseHandler = (requestPayload, response = {}, type) => {
    const { identifier } = requestPayload
    let unsavedChanges, disableSave, updatedRow
    if (type === 'success') {
      if (Object.keys(response).length) {
        const {
          location_id: storeNumber = '',
          location_names: locationNames = [],
          address = [],
        } = response
        const { name: propertyName = '' } = locationNames[0] || {}
        const { address_line1: streetAddress = '', city = '' } =
          address[0] || {}
        updatedRow = {
          storeNumber,
          propertyName,
          streetAddress,
          city,
          showProgress: false,
          isError: false,
          isSystemError: false,
        }
        unsavedChanges = true
        disableSave = false
      } else {
        updatedRow = {
          showProgress: false,
          isError: true,
          isSystemError: false,
        }
        unsavedChanges = true
        disableSave = true
      }
    } else {
      updatedRow = {
        showProgress: false,
        isError: false,
        isSystemError: true,
      }
      unsavedChanges = true
      disableSave = true
    }
    this.setState((state) => {
      const { libMetadata = {} } = state
      const { storeInformation = [] } = libMetadata
      storeInformation[identifier] = Object.assign(
        {},
        { ...storeInformation[identifier] },
        updatedRow,
      )
      return {
        usageInformation: cloneDeep({
          ...libMetadata,
          ...{ storeInformation },
        }),
        unsavedChanges: unsavedChanges,
        disableSave: disableSave,
      }
    }, this.updateStateCB)
  }

  storeInfoWidget = () => {
    const {
      containerClasses = {},
      classes = {},
      editPermissions = {},
      storedCatalog = {},
    } = this.props
    const cardTitle = METADATA_CARD_STORE_INFO_TITLE
    const {
      libMetadata = {},
      panelStoreInformation,
      disableSave = false,
    } = this.state
    const { storeInformation = [] } = libMetadata
    const { canEdit } = editPermissions
    const isExpanded = panelStoreInformation
    const { location: locationSuggestions = [] } = storedCatalog
    return (
      <MetadataExpansionPanel
        containerClasses={classes}
        cardTitle={cardTitle}
        isExpanded={isExpanded}
        fullWidthContent
        collapseHandler={this.panelCollapseHandler}
      >
        <div className={containerClasses.metadataCardActionWrapper}>
          {canEdit && (
            <IconButton
              data-cy="addStore"
              onClick={this.addStoreInfoRow}
              aria-label="Add Row"
              disabled={disableSave}
              size="large"
            >
              <AddIcon />
            </IconButton>
          )}
        </div>
        <Fragment>
          {storeInformation?.length > 0 &&
            storeInformation.map((storeInfoItem = {}, key) => (
              <div
                key={key}
                className={cx(
                  containerClasses.metadataTableRow,
                  classes.metadataRow,
                )}
              >
                <Table className={containerClasses.metadataTable}>
                  <TableBody>
                    <TableRow>
                      <TableCell className={containerClasses.metadataTableCell}>
                        <div
                          className={containerClasses.metadataTableCellAction}
                        >
                          {canEdit && (
                            <Fragment>
                              {storeInfoItem.showProgress && (
                                <CircularProgress
                                  className={containerClasses.progressSpinner}
                                />
                              )}
                              {!storeInfoItem.showProgress &&
                                !storeInfoItem.isError &&
                                !storeInfoItem.isSystemError && (
                                  <div
                                    data-cy="deleteStore"
                                    className={
                                      containerClasses.metadataTableCellActionBtn +
                                      ' actionBtnHover'
                                    }
                                    aria-label="Delete Row"
                                    onClick={this.deleteStoreInfoRow(key)}
                                  >
                                    <DeleteIcon color="inherit" />
                                  </div>
                                )}
                              {!storeInfoItem.showProgress &&
                                (storeInfoItem.isError ||
                                  storeInfoItem.isSystemError) && (
                                  <span className="error">
                                    <ErrorIcon />
                                  </span>
                                )}
                            </Fragment>
                          )}
                        </div>
                      </TableCell>
                      <TableCell className={containerClasses.metadataTableCell}>
                        <FormControl
                          variant="standard"
                          className={containerClasses.formControl}
                          disabled={!canEdit}
                          error={
                            storeInfoItem.isError || storeInfoItem.isSystemError
                          }
                        >
                          <InputLabel htmlFor={`siStoreNumber${key}`}>
                            Store #
                          </InputLabel>
                          <Input
                            id={`siStoreNumber${key}`}
                            value={storeInfoItem.storeNumber}
                            onChange={this.handleChangeStoreInfoRow(key)}
                            onKeyDown={this.handleFetchStoreLocationData(key)}
                            inputProps={{ 'data-cy': 'storeNumber' }}
                          />
                          {storeInfoItem.isError && (
                            <FormHelperText
                              className={containerClasses.errorStyle}
                            >
                              {METADATA_LOOKUP_STORE_ERROR}
                            </FormHelperText>
                          )}
                          {storeInfoItem.isSystemError && (
                            <FormHelperText
                              className={containerClasses.errorStyle}
                            >
                              {METADATA_LOOKUP_ERROR}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </TableCell>
                      <TableCell className={containerClasses.metadataTableCell}>
                        <FormControl
                          variant="standard"
                          className={containerClasses.formControl}
                        >
                          <InputLabel htmlFor={`siPropertyName${key}`}>
                            Property Name
                          </InputLabel>
                          <Input
                            data-cy="storeName"
                            id={`siPropertyName${key}`}
                            value={storeInfoItem.propertyName}
                            disableUnderline
                          />
                        </FormControl>
                      </TableCell>
                      <TableCell className={containerClasses.metadataTableCell}>
                        <FormControl
                          variant="standard"
                          className={containerClasses.formControl}
                        >
                          <InputLabel htmlFor={`siStreetAddress${key}`}>
                            Street Address
                          </InputLabel>
                          <Input
                            data-cy="storeAddress"
                            id={`siStreetAddress${key}`}
                            value={storeInfoItem.streetAddress}
                            disableUnderline
                          />
                        </FormControl>
                      </TableCell>
                      <TableCell className={containerClasses.metadataTableCell}>
                        <FormControl
                          variant="standard"
                          className={containerClasses.formControl}
                        >
                          <InputLabel htmlFor={`siCity${key}`}>City</InputLabel>
                          <Input
                            data-cy="storeCity"
                            id={`siCity${key}`}
                            value={storeInfoItem.city}
                            disableUnderline
                          />
                        </FormControl>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                {(canEdit ||
                  storeInfoItem.storeLocationDescription?.length > 0) && (
                  <div className={containerClasses.metadataTableCell}>
                    <FormControl
                      variant="standard"
                      className={cx(
                        containerClasses.formControl,
                        classes.chipFormControl,
                      )}
                      disabled={!canEdit}
                    >
                      <InputLabel htmlFor="siLocationDesc" shrink>
                        Store Location Description
                      </InputLabel>
                      <div
                        id="siLocationDesc"
                        className={containerClasses.formStaticWrap}
                      >
                        {canEdit && (
                          <CatalogTypeahead
                            suggestions={locationSuggestions}
                            handleKeyDown={this.handleCatalogKeydown(
                              'storeInformation',
                              key,
                            )}
                            handleChange={this.handleCatalogChange(
                              'storeInformation',
                              key,
                            )}
                          />
                        )}
                        {storeInfoItem.storeLocationDescription.map(
                          (locationDesc, index) =>
                            locationDesc && (
                              <Chip
                                id={`siLocationDesc${index}`}
                                label={locationDesc}
                                key={index}
                                className={
                                  cx(
                                    containerClasses.chipComponent,
                                    classes.chipComponent,
                                  ) + ' tableCellChip'
                                }
                                onDelete={
                                  canEdit
                                    ? this.handleChipDelete(
                                        'storeInformation',
                                        key,
                                        index,
                                      )
                                    : undefined
                                }
                              />
                            ),
                        )}
                      </div>
                    </FormControl>
                  </div>
                )}
              </div>
            ))}
        </Fragment>
        {storeInformation?.length === 0 && (
          <Table className={containerClasses.metadataTable}>
            <TableBody>
              <TableRow>
                <TableCell>
                  <div className={containerClasses.noResults}>
                    No details available
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
      </MetadataExpansionPanel>
    )
  }

  handleCatalogKeydown =
    (widget = '', catalog = '') =>
    (event) => {
      if (ALLOWED_ACTION_KEYS.indexOf(event.keyCode) !== -1) {
        const value = event.target.value || ''
        if (value) {
          this.setSelectedCatalogToState(widget, catalog, value)
        }
      }
    }

  handleShotTypeChange = (event) => {
    const { libMetadata = {} } = this.state
    const { assetDescriptions = {} } = libMetadata

    assetDescriptions.shotType = [event.target.value]

    this.setState(
      {
        libMetadata: Object.assign({}, libMetadata, { assetDescriptions }),
        unsavedChanges: true,
      },
      this.updateStateCB,
    )
  }

  handleAssetClassificationsChange = (event) => {
    const { libMetadata = {}, assetTypes = [] } = this.state
    const { assetClassifications = {} } = libMetadata
    if (event.target.name === 'classifications') {
      const { storedCatalog = {} } = this.props
      const { assetClassification: availableClassifications = {} } =
        storedCatalog
      assetClassifications.classifications_name = event.target.value
      assetClassifications.asset_type.name = ''
      let assetTypes
      availableClassifications.map((classification) => {
        if (classification.classification_name === event.target.value) {
          assetTypes = [...classification.asset_types]
          assetClassifications.asset_type.name = 'No classification added'
          assetTypes.map((asset) => {
            const { name = '' } = asset
            if (name == 'No classification added') {
              assetClassifications.asset_type.id = asset.classification_id
            }
          })
        }
      })
      this.setState({ assetTypes })
    } else {
      assetClassifications.asset_type.name = event.target.value
      assetTypes.map((asset) => {
        if (asset.name == event.target.value) {
          assetClassifications.asset_type.id = asset.classification_id
        }
      })
    }

    this.setState(
      {
        libMetadata: Object.assign({}, libMetadata, { assetClassifications }),
        unsavedChanges: true,
      },
      this.updateStateCB,
    )
  }

  handleBrandNameChange = (brandName = '') => {
    const { libMetadata = {} } = this.state
    this.setState(
      {
        libMetadata: Object.assign({}, libMetadata, {
          userDefinedBrandName: brandName,
        }),
        unsavedChanges: true,
      },
      this.updateStateCB,
    )
  }

  handleCatalogChange =
    (widget = '', catalog = '') =>
    ({ selectedItem = '' }) => {
      if (selectedItem) {
        this.setSelectedCatalogToState(widget, catalog, selectedItem)
      }
    }

  setSelectedCatalogToState = (widget = '', key = '', value = '') => {
    this.setState((state) => {
      const { libMetadata = {} } = state
      if (widget === 'assetDescriptions') {
        const assetDescriptions = libMetadata?.assetDescriptions || {}
        const assetDescriptionsKeyList = assetDescriptions[key] || []
        assetDescriptions[key] = []
        if (
          !filterArrayByInsensitiveField(assetDescriptionsKeyList, value).length
        ) {
          assetDescriptions[key].push(value)
        }
        assetDescriptions[key] = [...new Set(assetDescriptions[key])]
        return {
          libMetadata: Object.assign({}, libMetadata, { assetDescriptions }),
          unsavedChanges: true,
        }
      } else if (widget === 'storeInformation') {
        const { storeInformation = [] } = libMetadata
        const storeInformationKeyList = storeInformation[key] || []
        if (
          !filterArrayByInsensitiveField(
            storeInformationKeyList['storeLocationDescription'] || [],
            value,
          ).length
        ) {
          storeInformation[key]['storeLocationDescription'].push(value)
        }
        storeInformation[key]['storeLocationDescription'] = [
          ...new Set(storeInformation[key]['storeLocationDescription']),
        ]
        return {
          libMetadata: Object.assign({}, libMetadata, { storeInformation }),
          unsavedChanges: true,
        }
      }
    }, this.updateStateCB)
  }

  handleChipDelete =
    (widget = '', catalog = '', identifier) =>
    () => {
      this.setState((state) => {
        const { libMetadata = {} } = state
        if (widget === 'assetDescriptions') {
          const { assetDescriptions = {} } = libMetadata
          assetDescriptions[catalog].splice(identifier, 1)
          return {
            libMetadata: Object.assign({}, libMetadata, { assetDescriptions }),
            unsavedChanges: true,
          }
        } else if (widget === 'storeInformation') {
          const { storeInformation = [] } = libMetadata
          storeInformation[catalog]['storeLocationDescription'].splice(
            identifier,
            1,
          )
          return {
            libMetadata: Object.assign({}, libMetadata, { storeInformation }),
            unsavedChanges: true,
          }
        }
      }, this.updateStateCB)
    }

  assetDescriptionsWidget = () => {
    const {
      containerClasses = {},
      classes = {},
      editPermissions = {},
      storedCatalog = {},
      editMode = false,
    } = this.props
    const { libMetadata = {}, panelAssetDescriptions } = this.state
    const { assetDescriptions = {} } = libMetadata
    const {
      assetSubject = [],
      keywords = [],
      shotType = [],
    } = assetDescriptions
    const cardTitle = METADATA_CARD_ASSET_DESC_TITLE
    const isExpanded = panelAssetDescriptions
    const {
      subject: subjectSuggestions = [],
      location: locationSuggestions = [],
      shottype: shotTypeSuggestions = [],
    } = storedCatalog
    const { canEdit } = editPermissions

    return (
      <MetadataExpansionPanel
        containerClasses={classes}
        cardTitle={cardTitle}
        isExpanded={isExpanded}
        collapseHandler={this.panelCollapseHandler}
      >
        <Fragment>
          {(canEdit || (assetSubject || []).length > 0) && (
            <FormControl
              variant="standard"
              className={cx(
                containerClasses.formControl,
                classes.panelFormControl,
              )}
              disabled={!canEdit}
            >
              <InputLabel htmlFor="assetSubject" shrink>
                Asset Subject
              </InputLabel>
              <div
                data-cy="assetSubject"
                id="assetSubject"
                className={cx(
                  containerClasses.formStaticWrap,
                  containerClasses.chipContainer,
                )}
              >
                {canEdit && (
                  <CatalogTypeahead
                    suggestions={subjectSuggestions}
                    handleKeyDown={this.handleCatalogKeydown(
                      'assetDescriptions',
                      'assetSubject',
                    )}
                    handleChange={this.handleCatalogChange(
                      'assetDescriptions',
                      'assetSubject',
                    )}
                  />
                )}
                {(assetSubject || [])?.map(
                  (subject, key) =>
                    subject && (
                      <Chip
                        data-cy="assetSubjectChip"
                        id={`assetSubject${key}`}
                        className={containerClasses.chipComponent}
                        key={key}
                        label={subject}
                        onDelete={
                          canEdit
                            ? this.handleChipDelete(
                                'assetDescriptions',
                                'assetSubject',
                                key,
                              )
                            : undefined
                        }
                      />
                    ),
                )}
              </div>
            </FormControl>
          )}
          {(canEdit || keywords?.length > 0) && (
            <FormControl
              variant="standard"
              className={cx(
                containerClasses.formControl,
                classes.panelFormControl,
              )}
              disabled={!canEdit}
            >
              <InputLabel htmlFor="assetKeyword" shrink>
                Keywords
              </InputLabel>
              <div
                data-cy="assetKeyword"
                id="assetKeyword"
                className={cx(
                  containerClasses.formStaticWrap,
                  containerClasses.chipContainer,
                )}
              >
                {canEdit && (
                  <CatalogTypeahead
                    data-cy="assetKeywordChip"
                    suggestions={locationSuggestions}
                    handleKeyDown={this.handleCatalogKeydown(
                      'assetDescriptions',
                      'keywords',
                    )}
                    handleChange={this.handleCatalogChange(
                      'assetDescriptions',
                      'keywords',
                    )}
                  />
                )}
                {(keywords || []).map(
                  (keyword, key) =>
                    keyword && (
                      <Chip
                        data-cy="assetKeywordChip"
                        id={`assetKeyword${key}`}
                        className={containerClasses.chipComponent}
                        key={key}
                        label={keyword}
                        onDelete={
                          canEdit
                            ? this.handleChipDelete(
                                'assetDescriptions',
                                'keywords',
                                key,
                              )
                            : undefined
                        }
                      />
                    ),
                )}
              </div>
            </FormControl>
          )}

          {(canEdit || shotType?.length > 0) && (
            <FormControl
              variant="standard"
              className={cx(
                containerClasses.formControl,
                classes.panelFormControl,
              )}
              disabled={!canEdit}
            >
              <InputLabel htmlFor="shot type" shrink>
                Shot Type
              </InputLabel>
              <div
                id="shotType"
                className={cx(
                  containerClasses.formStaticWrap,
                  containerClasses.chipContainer,
                )}
              >
                {canEdit && (
                  <Select
                    variant="standard"
                    data-cy="assetShotType"
                    value={shotType?.[0] || ''}
                    onChange={this.handleShotTypeChange}
                  >
                    {shotTypeSuggestions.map((shotType) => (
                      <MenuItem
                        data-cy={shotType}
                        key={shotType}
                        value={shotType}
                      >
                        {shotType}
                      </MenuItem>
                    ))}
                  </Select>
                )}
                {!editMode && shotType && (
                  <Chip
                    data-cy="assetShotTypeChip"
                    id={shotType?.[0]}
                    className={containerClasses.chipComponent}
                    key={shotType?.[0]}
                    label={shotType?.[0]}
                  />
                )}
              </div>
            </FormControl>
          )}
        </Fragment>
      </MetadataExpansionPanel>
    )
  }

  assetClassifications = () => {
    const {
      containerClasses = {},
      classes = {},
      editPermissions = {},
      editMode = false,
      storedCatalog = {},
    } = this.props
    const {
      libMetadata = {},
      panelAssetClassifications,
      assetTypes = [],
    } = this.state

    const { assetClassification: availableClassifications = {} } = storedCatalog
    const {
      assetClassifications: stateAssetClassifications = {},
      productInformation = [],
    } = libMetadata
    const {
      classifications_name: secondaryClassifications = [],
      asset_type: assetType = '',
    } = stateAssetClassifications
    const cardTitle = METADATA_CARD_ASSET_CLASSIFICATION
    const isExpanded = panelAssetClassifications
    const { canEdit } = editPermissions
    const nonProductInfo = getNonProductBrand(productInformation || [])
    const { brandName: nonProductBrandName = null } = nonProductInfo || {}

    return (
      <MetadataExpansionPanel
        containerClasses={classes}
        cardTitle={cardTitle}
        isExpanded={isExpanded}
        collapseHandler={this.panelCollapseHandler}
      >
        {assetType?.id || canEdit ? (
          <Fragment>
            <FormControl
              variant="standard"
              className={cx(
                containerClasses.formControl,
                classes.panelFormControl,
              )}
              disabled={!canEdit}
            >
              <InputLabel htmlFor="classifications" shrink>
                Classification
              </InputLabel>
              <div
                data-cy="classifications"
                id="classifications"
                className={cx(
                  containerClasses.formStaticWrap,
                  containerClasses.chipContainer,
                )}
              >
                {canEdit && !isEmptyObject(availableClassifications) && (
                  <Select
                    variant="standard"
                    name="classifications"
                    data-cy="classifications"
                    value={secondaryClassifications || ''}
                    onChange={this.handleAssetClassificationsChange}
                  >
                    {availableClassifications.map((classifications) => (
                      <MenuItem
                        data-cy={classifications.classification_name}
                        key={classifications.classification_name}
                        value={classifications.classification_name}
                      >
                        {classifications.classification_name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
                {!canEdit && (
                  <Chip
                    data-cy="classificationsChip"
                    id={secondaryClassifications}
                    className={containerClasses.chipComponent}
                    key={secondaryClassifications}
                    label={secondaryClassifications}
                  />
                )}
              </div>
            </FormControl>

            {secondaryClassifications && (
              <FormControl
                variant="standard"
                className={cx(
                  containerClasses.formControl,
                  classes.panelFormControl,
                )}
                disabled={!canEdit}
              >
                <InputLabel htmlFor="assetType" shrink>
                  Asset Type
                </InputLabel>
                <div
                  data-cy="assetType"
                  id="assetType"
                  className={cx(
                    containerClasses.formStaticWrap,
                    containerClasses.chipContainer,
                  )}
                >
                  {canEdit && (
                    <Select
                      variant="standard"
                      data-cy="assetType"
                      name="assetType"
                      value={assetType.name || ''}
                      onChange={this.handleAssetClassificationsChange}
                    >
                      {assetTypes.map((asset) => (
                        <MenuItem
                          data-cy={asset.name}
                          key={asset.name}
                          value={asset.name}
                          id={asset.classification_id}
                        >
                          {asset.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                  {!canEdit && assetType && (
                    <Chip
                      data-cy="classificationsChip"
                      id={assetType.name}
                      className={containerClasses.chipComponent}
                      key={assetType.name}
                      label={assetType.name}
                    />
                  )}
                </div>
              </FormControl>
            )}

            {canEdit &&
            DISPLAY_BRAND_CLASSIFICATION_TYPES.indexOf(
              secondaryClassifications,
            ) !== -1 &&
            assetType?.id ? (
              <div style={{ padding: 10 }}>
                <BrandAutoComplete
                  autoCompleteLabel="Enter a brand"
                  productInformation={productInformation}
                  updateSelectedValue={(name) =>
                    this.handleBrandNameChange(name)
                  }
                />
              </div>
            ) : (
              ''
            )}
            {!canEdit &&
              DISPLAY_BRAND_CLASSIFICATION_TYPES.indexOf(
                secondaryClassifications,
              ) !== -1 &&
              nonProductBrandName && (
                <div>
                  <InputLabel htmlFor="classifications" shrink>
                    Brand
                  </InputLabel>
                  <Chip
                    data-cy="brandChip"
                    id={nonProductBrandName}
                    className={containerClasses.chipComponent}
                    key={nonProductBrandName}
                    label={nonProductBrandName}
                  />
                </div>
              )}
          </Fragment>
        ) : (
          'No Details Found'
        )}
      </MetadataExpansionPanel>
    )
  }

  userTagsWidget = () => {
    const { containerClasses = {}, classes = {} } = this.props
    const { libMetadata = {}, panelUserTags } = this.state
    const { userTags = {} } = libMetadata
    const cardTitle = METADATA_CARD_USER_TAGS
    const isExpanded = panelUserTags
    return (
      <MetadataExpansionPanel
        containerClasses={classes}
        cardTitle={cardTitle}
        isExpanded={isExpanded}
        collapseHandler={this.panelCollapseHandler}
      >
        {Object.keys(userTags).map((tagName) => (
          <FormControl
            variant="standard"
            className={containerClasses.formControl}
          >
            <InputLabel htmlFor={tagName}>{tagName}</InputLabel>
            <Input id={tagName} value={userTags[tagName]} disableUnderline />
          </FormControl>
        ))}
      </MetadataExpansionPanel>
    )
  }

  projectInfoWidget = () => {
    const { containerClasses = {}, classes = {} } = this.props
    const { libMetadata = {}, panelProject } = this.state
    const { projectDetails = {} } = libMetadata
    const cardTitle = METADATA_CARD_PROJECT_TITLE
    const isExpanded = panelProject
    const { runDate = '' } = projectDetails
    const runDateFormatted = convertUTCtoLocal(runDate, 'lll') || ''
    const {
      project_id = '',
      project_name = '',
      channel = '',
      campaign = '',
      campaign_description = '',
    } = projectDetails
    return (
      <MetadataExpansionPanel
        containerClasses={classes}
        cardTitle={cardTitle}
        isExpanded={isExpanded}
        collapseHandler={this.panelCollapseHandler}
      >
        {project_id && (
          <FormControl
            variant="standard"
            className={containerClasses.formControl}
          >
            <InputLabel htmlFor="projectId">Project ID</InputLabel>
            <Input id="projectId" value={project_id} disableUnderline />
          </FormControl>
        )}
        {project_name && (
          <FormControl
            variant="standard"
            className={containerClasses.formControl}
          >
            <InputLabel htmlFor="projectName">Project Name</InputLabel>
            <Input
              id="projectName"
              fullWidth
              multiline
              value={project_name}
              disableUnderline
            />
          </FormControl>
        )}
        {channel && (
          <FormControl
            variant="standard"
            className={containerClasses.formControl}
          >
            <InputLabel htmlFor="projectChannel">Channel</InputLabel>
            <Input id="projectChannel" value={channel} disableUnderline />
          </FormControl>
        )}
        {runDateFormatted && (
          <FormControl
            variant="standard"
            className={containerClasses.formControl}
          >
            <InputLabel htmlFor="projectLaunchDate">
              Run/Set/Launch Date
            </InputLabel>
            <Input
              id="projectLaunchDate"
              value={runDateFormatted}
              disableUnderline
            />
          </FormControl>
        )}
        {(campaign_description || campaign) && (
          <FormControl
            variant="standard"
            className={containerClasses.formControl}
          >
            <InputLabel htmlFor="projectCampaign">Campaign/Season</InputLabel>
            <Input
              id="projectCampaign"
              value={campaign_description || campaign}
              disableUnderline
            />
          </FormControl>
        )}
      </MetadataExpansionPanel>
    )
  }

  render() {
    const {
      containerClasses = {},
      getStorageIdentifier = () => {},
      collapseCallback = () => {},
      isBulkEdit,
      isAdminOrLibrarian,
    } = this.props
    const { disableSave, libMetadata = {} } = this.state
    const { userTags = {} } = libMetadata
    const cardInfoTitle = 'Metadata'
    const cardIdentifier = getStorageIdentifier(cardInfoTitle)
    const cardCollapse = getFromBrowserStorage(cardIdentifier) === 'true'
    return (
      <CollapsibleCard
        cardTitle={cardInfoTitle}
        hideButtonText={METADATA_CARD_HIDE_BUTTON_TEXT}
        showButtonText={METADATA_CARD_SHOW_BUTTON_TEXT}
        defaultCollapse={cardCollapse}
        collapseCallback={collapseCallback}
        fullWidthContent
        isActionMode={false}
        disablePrimaryButton={disableSave}
        primaryButtonText="SAVE CHANGES"
        secondaryButtonText="CANCEL CHANGES"
        primaryButtonHandler={this.saveChanges}
        secondaryButtonHandler={this.discardChanges}
      >
        <div className={containerClasses.metadataCard}>
          {this.productInfoWidget()}
          {this.storeInfoWidget()}
          {this.assetDescriptionsWidget()}
          {/* {isAdminOrLibrarian && !isEmptyObject(userTags) && this.userTagsWidget()} */}
          {this.assetClassifications()}
          {!isBulkEdit && this.projectInfoWidget()}
        </div>
      </CollapsibleCard>
    )
  }
}

LibrarianInfoCard.propTypes = {
  containerClasses: PropTypes.object,
  classes: PropTypes.object,
  libMetadata: PropTypes.shape({
    projectDetails: PropTypes.shape({
      projectId: PropTypes.string,
      projectName: PropTypes.string,
      channel: PropTypes.string,
      runDate: PropTypes.string,
      campaign: PropTypes.string,
    }),
    productInformation: PropTypes.arrayOf(
      PropTypes.shape({
        dpci: PropTypes.string,
        tcin: PropTypes.string,
        brandName: PropTypes.string,
        productDescription: PropTypes.string,
      }),
    ),
    storeInformation: PropTypes.arrayOf(
      PropTypes.shape({
        storeNumber: PropTypes.string,
        propertyName: PropTypes.string,
        streetAddress: PropTypes.string,
        city: PropTypes.string,
        storeLocationDescription: PropTypes.arrayOf(PropTypes.string),
      }),
    ),
    assetDescriptions: PropTypes.shape({
      assetSubject: PropTypes.arrayOf(PropTypes.string),
      keywords: PropTypes.arrayOf(PropTypes.string),
      shot_types: PropTypes.arrayOf(PropTypes.string),
    }),
  }),
  storedCatalog: PropTypes.object,
  metadataParentTimestamp: PropTypes.number,
  collapseCallback: PropTypes.func,
  getStorageIdentifier: PropTypes.func,
  editPermissions: PropTypes.object,
  saveMetadata: PropTypes.func,
  isBulkEdit: PropTypes.bool,
  updateParentState: PropTypes.func,
  isMetadataFormEdited: PropTypes.func,
  saveAlertActiontype: PropTypes.bool,
  setSaveAlertAction: PropTypes.func,
  editMode: PropTypes.bool,
}

const MyComponent = (props) => {
  const classes = styles()
  return <LibrarianInfoCard {...props} classes={classes} />
}

export default MyComponent
