import { SAVE_ROLE } from './actionType'

export const headerInitialState = {
  role: '',
}

export default function headerReducer(state = headerInitialState, action = {}) {
  switch (action.type) {
    case SAVE_ROLE:
      return {
        ...state,
        role: action.data,
      }
    default:
      return state
  }
}
