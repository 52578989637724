import * as React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import { bindActionCreators } from 'redux'
//core files
import {
  Button,
  Grid,
  TextField,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  CircularProgress,
  Typography,
  Input,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  Avatar,
  ListItemAvatar,
} from '@mui/material'
import { CloudUpload, Send } from '@mui/icons-material'
import ImageIcon from '@mui/icons-material/Image'
import Dropzone from 'react-dropzone'
import { green } from '@mui/material/colors'

import ModalDialog from '../ModalDialog/ModalDialog'
import { showNotification } from '../../store/notification/actionCreator'
import apiConfig from '../../config/apiConfig'
import { praxis } from '../../config/themeConfig'

import {
  TEXT_ERROR_SUPPORT_FORM,
  TEXT_ERROR_SUPPORT_FORM_ATTACHMENT,
  TEXT_SUCCESS_SUPPORT_FORM,
} from '../../constants/notifications'

const styles = {
  largeIcon: {
    width: '60px',
    height: '60px',
    color: praxis.lighterBlue,
  },
  customButton: {
    marginTop: '10px',
    backgroundColor: '#CCC',
    color: '#2A669F',
    '&:hover': {
      backgroundColor: '#CCC',
    },
  },
  root: {
    textAlign: 'right',
    width: '100%',
  },
  wrapper: {
    margin: '10px',
    position: 'relative',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  container: {
    marginTop: '30px',
    '&>*': {
      marginTop: '0px !important',
      marginBottom: '20px !important',
    },
  },
  errorMessage: {
    fontSize: '11px',
    margin: '8px',
    color: '#888',
  },
  textField: {
    width: '100%',
    margin: '5px 0 5px 0',
  },
  subTitle: {
    fontSize: '16px',
    color: '#8D8D8D',
    fontStyle: 'italic',
  },
  dropArea: {
    minWidth: '520px',
    position: 'relative',
    height: '140px',
    border: '2px dashed #666',
    margin: '10px',
    padding: '10px',
  },
  typographyBody1: {
    color: '#000000',
  },
}

const HighPriority = [
  {
    value: 'High Priority',
    label: 'High Priority - Less than 24 hours',
  },
  {
    value: 'Medium Priority',
    label: 'Medium Priority - Less than 3 to 5 days',
  },
  {
    value: 'Low Priority',
    label: 'Low Priority - Less than 21 days',
  },
]

export class Feedback extends React.Component {
  static defaultProps = {}

  constructor(props) {
    super(props)
    this.onModalClose = this.onModalClose.bind(this)
    this.formSubmit = this.formSubmit.bind(this)
    this.state = {
      files: [],
      modalOpen: true,
      open: false,
      vertical: null,
      horizontal: null,
      selectedValue: 'I am experiencing a tool issue',
      contactTeam: 'AssetHub Product',
      priority: 'High Priority',
      description: '',
      descLength: 0,
      loading: false,
    }
  }
  onDrop(files) {
    this.setState({
      files,
    })
  }
  showSnackBar(message, variant) {
    this.props.showNotification(true, message, variant)
    this.setState({
      loading: false,
    })
  }

  formSubmit = () => {
    const { user = {} } = this.props
    const { email = '' } = user
    const { key = '', assethub = {} } = apiConfig
    const { feedBackUrl = '' } = assethub
    const projectKey = 'ASSETHUB'
    const {
      description = '',
      priority = '',
      selectedValue = '',
      files = [],
    } = this.state
    const reqData = {
      email: email,
      issuetype: 'Defect',
      projectkey: projectKey,
      description: description,
      summary: priority + ' | ' + selectedValue,
    }
    this.setState({
      loading: true,
    })
    axios
      .post(feedBackUrl + '?key=' + key, reqData)
      .then((response) => {
        const { data: fbuRespData = {} } = response
        const { issue_id: issueId = '' } = fbuRespData
        if (files.length > 0) {
          const url = feedBackUrl + '/attachment/' + issueId + '?key=' + key
          // populate image attachement to created JIRA
          /* global FormData:false */
          const formData = new FormData()
          files.forEach(function (value) {
            formData.append('file', value)
          })
          formData.append('jiraId', issueId)
          axios
            .post(url, formData, {
              headers: {
                'Content-Type': undefined,
              },
            })
            .then(() => {
              this.showSnackBar(TEXT_SUCCESS_SUPPORT_FORM, 'success')
              this.onModalClose()
            })
            .catch(() => {
              this.showSnackBar(TEXT_ERROR_SUPPORT_FORM_ATTACHMENT, 'error')
              this.onModalClose()
            })
        } else {
          this.showSnackBar(TEXT_SUCCESS_SUPPORT_FORM, 'success')
          this.onModalClose()
        }
      })
      .catch(() => {
        this.showSnackBar(TEXT_ERROR_SUPPORT_FORM, 'error')
      })
  }
  handleRadioChange = (event) => {
    this.setState({ selectedValue: event.target.value })
    let contactTeam =
      event.target.value === 'I am experiencing a tool issue' ||
      event.target.value === 'I have a tool or process question'
        ? 'AssetHub Product'
        : 'Assets'
    this.setState({ contactTeam: contactTeam })
  }
  handlePriorityChange = (event) => {
    this.setState({ priority: event.target.value })
  }
  onModalClose() {
    this.resetState()
    this.props.onClose()
  }
  resetState() {
    this.setState({
      modalOpen: true,
    })
  }
  handleClick = (state) => () => {
    this.setState({ open: true, ...state })
  }

  handleClose = () => {
    this.setState({ open: false })
  }
  renderDialogActions() {}
  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    })
  }
  handleDescriptionChange = (event) => {
    this.setState({
      description: event.target.value,
      descLength: event.target.value.length,
    })
  }

  render() {
    const { classes = {}, user = {} } = this.props
    const { email = '' } = user
    const {
      selectedValue = '',
      contactTeam = '',
      currency,
      multiline,
      descLength = 0,
      loading = false,
      files = [],
    } = this.state
    return (
      <div {...this.props.styles1}>
        <ModalDialog
          title="Need Help?"
          open={this.props.modalOpen}
          onClose={() => this.onModalClose()}
          renderActions={this.renderDialogActions}
          className={classes.createModal}
          dialogClassName={classes.createModal}
        >
          <Grid container justifyContent="center" spacing={1}>
            <Grid item xs={12}>
              <form
                className={classes.container}
                noValidate
                autoComplete="off"
                id="feedbackForm"
              >
                <FormControl
                  variant="standard"
                  className={classes.textField}
                  disabled
                >
                  <InputLabel htmlFor="email-disabled">your email</InputLabel>
                  <Input
                    id="email-disabled"
                    value={email}
                    onChange={this.handleChange}
                  />
                </FormControl>
                <FormControl
                  variant="standard"
                  component="fieldset"
                  required
                  className={classes.formControl}
                >
                  <FormLabel component="legend">Issue Type</FormLabel>
                  <RadioGroup
                    aria-label="issue-type"
                    name="issuetype"
                    className={classes.group}
                    value={selectedValue}
                    onChange={this.handleRadioChange}
                  >
                    <FormControlLabel
                      classes={{ label: classes.typographyBody1 }}
                      value="I am experiencing a tool issue"
                      control={<Radio />}
                      label="I am experiencing a tool issue"
                    />
                    <FormControlLabel
                      classes={{ label: classes.typographyBody1 }}
                      value="I have a tool or process question"
                      control={<Radio />}
                      label="I have a tool or process question"
                    />
                    <FormControlLabel
                      classes={{ label: classes.typographyBody1 }}
                      value="I cannot locate an asset"
                      control={<Radio />}
                      label="I cannot locate an asset"
                    />
                  </RadioGroup>
                </FormControl>
                <Typography variant="h5" className={classes.subTitle}>
                  The <u>{contactTeam}</u> Team will follow up with you.
                </Typography>
                <TextField
                  variant="standard"
                  id="select-currency-native"
                  select
                  label="When do you need an answer/resolution?"
                  className={classes.textField}
                  value={currency}
                  onChange={this.handlePriorityChange}
                  SelectProps={{
                    native: true,
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                  helperText="Select any option"
                  margin="normal"
                >
                  {HighPriority.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </TextField>

                <TextField
                  variant="standard"
                  id="description"
                  label="Description"
                  className={classes.textField}
                  multiline
                  maxRows="27"
                  value={multiline}
                  onChange={this.handleDescriptionChange}
                  margin="normal"
                  helperText={`${descLength} / 2000 `}
                  inputProps={{ maxLength: 2000 }}
                />
              </form>
            </Grid>
            <Grid container justifyContent="center" spacing={1}>
              <section>
                <div className={classes.dropArea}>
                  <Dropzone
                    accept=".jpeg,.png,.jpg"
                    onDrop={this.onDrop.bind(this)}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <div style={{ textAlign: 'center' }}>
                            <CloudUpload className={classes.largeIcon} />
                            <Typography>
                              Have a screenshot? Drag and Drop or click here to
                              upload.
                            </Typography>
                            <Button
                              variant="contained"
                              color="secondary"
                              className={classes.customButton}
                            >
                              BROWSE
                            </Button>
                          </div>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </div>
                <aside>
                  <List>
                    {files.map((f, index) => (
                      <ListItem key={index}>
                        <ListItemAvatar>
                          <Avatar>
                            <ImageIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          id="uploadedFile"
                          primary={f.name}
                          secondary={`${f.size} bytes`}
                        />
                      </ListItem>
                    ))}
                  </List>
                </aside>
              </section>
            </Grid>
            <div className={classes.root}>
              <div className={classes.wrapper}>
                <Button
                  id="btnFeedBackSubmit"
                  variant="contained"
                  color="secondary"
                  disabled={descLength < 3 || loading}
                  className={classes.button}
                  onClick={this.formSubmit}
                >
                  SUBMIT
                  <Send style={{ marginLeft: '2px' }} />
                </Button>
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            </div>
          </Grid>
        </ModalDialog>
      </div>
    )
  }
}

Feedback.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  email: PropTypes.string,
  showNotification: PropTypes.func,
  user: PropTypes.object,
  classes: PropTypes.object,
  styles1: PropTypes.object,
}
const mapStateToProps = (state) => ({
  user: {
    email: state.auth.email,
  },
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showNotification,
    },
    dispatch
  )
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Feedback))
