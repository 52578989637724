export const SIDEBAR_WIDTH = 500
export const SEARCH_PAGE_SIZES = [21, 42, 105, 210]
export const DEFAULT_SORT_BY_OPTION = 'relevance'
export const NO_THUMBNAIL_TYPES = ['PDF', 'VTT']

export const SORT_BY_OPTIONS = {
  uploadDateASC: { 'publishers.published_at': 'asc' },
  uploadDateDESC: { 'publishers.published_at': 'desc' },
  fileNameASC: { 'publishers.file_name': 'asc' },
  fileNameDESC: { 'publishers.file_name': 'desc' },
  relevance: {},
}
export const SORT_OPTIONS = {
  relevance: 'Sort by:  Relevance',
  uploadDateASC: 'Sort by: Upload Date | Old to New',
  uploadDateDESC: 'Sort by: Upload Date | New to Old',
  fileNameASC: 'Sort by: File Name | Ascending',
  fileNameDESC: 'Sort by: File Name | Descending',
}

export const FACET_PATHS = {
  ASSET_TYPE: {
    path: 'asset_type',
    label: 'Asset Type',
  },
  EMAIL: {
    path: 'publishers.email',
    label: 'Email Address',
  },
  FILE_EXTENSION: {
    path: 'publishers.file_extension',
    label: 'File Extension',
  },
  USER_ID: {
    path: 'publishers.user_id',
    label: 'User ID',
  },
  FILE_NAME: {
    path: 'publishers.file_name',
    label: 'File Name',
  },
  CONTENT_TYPE: {
    path: 'metadata.content_type',
    label: 'Content Type',
  },
}
