import {
  OPEN_SIDE_NAV,
  CLOSE_SIDE_NAV,
  TOGGLE_RIGHT_SIDENAV,
  SET_HEADER_TITLE,
  SET_SAVE_ALERT_ACTIONTYPE,
} from './actionType'

export function openSideNav() {
  return {
    type: OPEN_SIDE_NAV,
  }
}

export function closeSideNav() {
  return {
    type: CLOSE_SIDE_NAV,
  }
}

export function toggleRightSidenav(
  rightSideNavComponent,
  contentChanged = false
) {
  return {
    type: TOGGLE_RIGHT_SIDENAV,
    payload: {
      rightSideNavComponent,
      contentChanged,
    },
  }
}

export function setSaveAlertAction(saveAlertActionType) {
  return {
    type: SET_SAVE_ALERT_ACTIONTYPE,
    saveAlertActionType,
  }
}

export function setHeaderTitle(headerTitle) {
  return {
    type: SET_HEADER_TITLE,
    payload: headerTitle,
  }
}
