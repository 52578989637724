import { useState, useEffect } from 'react'
import {
  Autocomplete,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from '@mui/material'
import { useSelector } from 'react-redux'
import { useMetadataContext } from './MetadataContext'
import CollapsibleCard from '../../components/Custom/CollapsibleCard'
import {
  METADATA_CARD_HIDE_BUTTON_TEXT,
  METADATA_CARD_SHOW_BUTTON_TEXT,
} from '../../constants/metadata'

function AssetDescriptionsCard({ containerClasses = {} }) {
  const { assetCatalog = {} } = useSelector((state) => state.newMetadata)
  const { subject = [], shottype = [], location = [] } = assetCatalog

  const {
    assetMetadata = {},
    isEditMode = false,
    setIsMetadataUnsaved = () => {},
    updateMetadata = () => {},
  } = useMetadataContext()

  const { metadata: { asset_descriptions = {} } = {} } = assetMetadata || {}

  const {
    keywords: responseKeywords = [],
    shot_types: responseShotType = [],
    subject: responseSubject = [],
  } = asset_descriptions || {}

  const [shotType, setShotType] = useState(responseShotType?.[0] || '')
  const [assetSubject, setAssetSubject] = useState(responseSubject ?? [])
  const [keywords, setKeywords] = useState(responseKeywords ?? [])

  useEffect(() => {
    if (
      shotType !== responseShotType?.[0] ||
      keywords !== responseKeywords ||
      assetSubject !== responseSubject
    ) {
      setIsMetadataUnsaved(true)
      updateMetadata('asset_descriptions', {
        keywords: keywords,
        shot_types: shotType ? [shotType] : [],
        subject: assetSubject,
      })
    } else {
      setIsMetadataUnsaved(false)
    }
  }, [shotType, assetSubject, keywords])

  return (
    <CollapsibleCard
      cardTitle={'Asset Descriptions'}
      hideButtonText={METADATA_CARD_HIDE_BUTTON_TEXT}
      showButtonText={METADATA_CARD_SHOW_BUTTON_TEXT}
      defaultCollapse={true}
    >
      <Grid container className={containerClasses.assetGrid}>
        <Grid item>
          <Autocomplete
            multiple
            fullWidth
            disableCloseOnSelect
            classes={{
              root: containerClasses.autocompleteRoot,
            }}
            id="subject"
            options={subject}
            value={assetSubject}
            onChange={(event, newValue) => {
              setAssetSubject(newValue)
            }}
            getOptionLabel={(option) => option}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField {...params} label="Asset Subject" />
            )}
            disabled={!isEditMode}
          />
        </Grid>
        <Grid item>
          <Autocomplete
            multiple
            disableCloseOnSelect
            fullWidth
            classes={{
              root: containerClasses.autocompleteRoot,
            }}
            freeSolo
            id="keywords"
            options={location}
            value={keywords}
            onChange={(event, newValue) => {
              setKeywords(newValue)
            }}
            getOptionLabel={(option) => option}
            filterSelectedOptions
            renderInput={(params) => <TextField {...params} label="Keywords" />}
            disabled={!isEditMode}
          />
        </Grid>
        <Grid item>
          <FormControl>
            <InputLabel id="classification">Shot Type</InputLabel>
            <Select
              className={containerClasses.selectWidth}
              labelId="shottype_label"
              id="shottype"
              value={shotType}
              label="Shot Type"
              input={<OutlinedInput label="Shot Type" />}
              onChange={(event) => setShotType(event.target.value)}
              disabled={!isEditMode}
            >
              {shottype?.map((name) => (
                <MenuItem value={name}>{name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </CollapsibleCard>
  )
}

export default AssetDescriptionsCard
