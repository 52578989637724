import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import { isEmpty } from 'lodash'
import {
  deactivateAddChannel,
  saveChannelHandler,
} from '../../../store/channelUpload/addChannelActionCreator'

const styles = {
  textFieldInput: {
    padding: '20px 0pc',
  },
  input: {
    '&:invalid': {
      border: 'red solid 2px',
    },
  },
}

const validateFields = (formErrors, fieldName, fieldValue) => {
  const errors = { ...formErrors }
  errors[fieldName] = ''
  if (!fieldValue) {
    errors[fieldName] = 'Required'
  }
  if (
    fieldName === 'owner_email' &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(fieldValue)
  ) {
    errors[fieldName] = 'Invalid owner email address'
  }
  if (
    fieldName === 'owner_distribution_list' &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(fieldValue)
  ) {
    errors[fieldName] = 'Invalid mailing list'
  }
  return errors
}

const CreateNewChannel = ({
  isAddChannelActive = false,
  email = '',
  auth = {},
  classes = {},
  saveChannelHandler = () => {},
  deactivateAddChannel = () => {},
}) => {
  const [channel_name, setChannelName] = useState('')
  const [owner_email, setOwnerEmail] = useState(email)
  const [owner_distribution_list, setOwnerDistributionList] = useState(email)
  const [formErrors, setFormErrors] = React.useState({})

  const isSubmitDisabled =
    isEmpty(channel_name) ||
    isEmpty(owner_distribution_list) ||
    isEmpty(owner_email) ||
    !isEmpty(formErrors['channel_name']) ||
    !isEmpty(formErrors['owner_email']) ||
    !isEmpty(formErrors['owner_distribution_list'])
      ? true
      : false

  const handleClose = () => {
    setChannelName('')
    deactivateAddChannel()
  }
  const handleSubmit = () => {
    const addChannelFormValues = {
      owner_distribution_list: owner_distribution_list,
      owner_email: owner_email,
      channel_name: channel_name,
      publish_location: 'scene7',
    }
    saveChannelHandler(addChannelFormValues, auth)
    setChannelName('')
  }

  const handleInputChange = (event) => {
    const { target = {} } = event
    const { name = '', value = '' } = target
    switch (name) {
      case 'channel_name':
        setChannelName(value)
        break
      case 'owner_email':
        setOwnerEmail(value)
        break
      case 'owner_distribution_list':
        setOwnerDistributionList(value)
        break
    }
    const fieldErrors = validateFields(formErrors, name, value)
    setFormErrors({ ...fieldErrors })
  }
  return (
    <>
      <Dialog
        open={isAddChannelActive}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">Create New Channel</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please provide the following information:
          </DialogContentText>
          <div className={classes.textFieldInput}>
            <TextField
              {...(formErrors['channel_name'] && {
                error: true,
                helperText: formErrors['channel_name'],
              })}
              fullWidth
              variant="outlined"
              label="Channel Name"
              value={channel_name}
              name="channel_name"
              onChange={handleInputChange}
            />
          </div>
          <div className={classes.textFieldInput}>
            <TextField
              {...(formErrors['owner_email'] && {
                error: true,
                helperText: formErrors['owner_email'],
              })}
              fullWidth
              variant="outlined"
              label="Owner Email"
              value={owner_email}
              name="owner_email"
              onChange={handleInputChange}
              type="email"
              inputProps={{ className: classes.input }}
            />
          </div>
          <div className={classes.textFieldInput}>
            <TextField
              {...(formErrors['owner_distribution_list'] && {
                error: true,
                helperText: formErrors['owner_distribution_list'],
              })}
              variant="outlined"
              fullWidth
              label="Channel Distribution List Email"
              value={owner_distribution_list}
              name="owner_distribution_list"
              onChange={handleInputChange}
              type="email"
              inputProps={{ className: classes.input }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            color="secondary"
            disabled={isSubmitDisabled}
            variant="contained"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
CreateNewChannel.propTypes = {
  auth: PropTypes.object,
  classes: PropTypes.object,
  email: PropTypes.string,
  isAddChannelActive: PropTypes.bool,
  deactivateAddChannel: PropTypes.func,
  saveChannelHandler: PropTypes.func,
}

const mapStateToProps = (state = {}) => {
  const { auth = {}, channelPublish = {} } = state
  const { email = '' } = auth
  const { isAddChannelActive = false } = channelPublish
  return {
    email,
    auth,
    isAddChannelActive,
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      deactivateAddChannel,
      saveChannelHandler,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(CreateNewChannel))
