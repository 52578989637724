import idx from 'idx'
import { createSelector } from 'reselect'

import { selectUserId } from '../auth/selector'
import { isLoggedInUserProjectAdmin } from './helpers'

const selectProjectType =
  () =>
  ({ projects = {} }) =>
    idx(projects, (_) => _.projectData.project_type)
const selectUpdateProjectErrorMessage =
  () =>
  ({ projects = {} }) =>
    idx(projects, (_) => _.modal.updateErrorMessage)
const selectProjectAdminList =
  () =>
  ({ projects = {} }) =>
    idx(projects, (_) => _.projectData.project_admins)
const selectProjectUuid =
  () =>
  ({ projects = {} }) =>
    idx(projects, (_) => _.projectData.project_uuid)

const makeSelectIsLoggedInUserProjectAdmin = () =>
  createSelector(
    selectUserId(),
    selectProjectAdminList(),
    (logInId = '', projectAdminList = []) =>
      isLoggedInUserProjectAdmin(logInId, projectAdminList)
  )

const selectProjectSelectedAssets =
  () =>
  ({ projects = {} }) =>
    projects.selectedProjectAssets

export {
  makeSelectIsLoggedInUserProjectAdmin,
  selectProjectAdminList,
  selectUpdateProjectErrorMessage,
  selectProjectType,
  selectProjectUuid,
  selectProjectSelectedAssets,
}
