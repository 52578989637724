import React from 'react'
import PropTypes from 'prop-types'
import deburr from 'lodash/deburr'
import Downshift from 'downshift'
import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import {
  CircularProgress,
  TextField,
  Paper,
  MenuItem,
  Chip,
} from '@mui/material'
import { AddCircle } from '@mui/icons-material'
import { formatSelectedItem } from '../../helpers/SearchHelper'
import { SEARCH_BOX_PLACEHOLDER } from '../../constants/search'

const styles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    zIndex: 10000,
    left: 0,
    right: 0,
    margin: '0 20px 0 0',
    maxHeight: '480px',
    'overflow-y': 'scroll',
  },
  chip: {
    margin: `${useTheme().spacing(0.5)} ${useTheme().spacing(0.25)}`,
  },
  inputRoot: {
    display: 'inline-block',
    padding: '0px 10px',
  },
  inputInput: {
    width: 'auto',
  },
  inputUnderline: {
    '&:after': {
      border: '0 !important',
    },
    '&.Mui-focused:after': {
      border: '0 !important',
    },
    '&.Mui-error:after': {
      border: '0 !important',
    },
    '&:before': {
      border: '0 !important',
    },
    '&:hover:not($disabled):not($focused):not($error):before': {
      border: '0 !important',
    },
    '&.Mui-disabled': {
      border: '0 !important',
    },
    '&.Mui-focused': {
      border: '0 !important',
    },
    '&.Mui-error': {
      border: '0 !important',
    },
    '&.Mui-disabled:before': {
      border: '0 !important',
    },
  },
  textField: {
    margin: '0',
    borderRadius: '5px',
    // height: '40px',
  },
  container: {
    width: '100%',
    position: 'relative',
    margin: '0',
    border: '1px solid ' + useTheme().palette.primary.lighterGrey,
    borderRadius: '3px',
  },
}))

const renderInput = ({ InputProps, classes, ref, ...other }) => (
  <TextField
    variant="standard"
    className={classes.textField}
    margin="normal"
    style={{ backgroundColor: 'white' }}
    inputProps={{ 'data-cy': 'searchBar' }}
    InputProps={{
      inputRef: ref,
      classes: {
        root: classes.inputRoot,
        underline: classes.inputUnderline,
      },
      ...InputProps,
    }}
    {...other}
  />
)

renderInput.propTypes = {
  InputProps: PropTypes.object,
  classes: PropTypes.object,
  ref: PropTypes.string,
}

function renderSuggestion({ suggestion, index, itemProps, highlightedIndex }) {
  const isHighlighted = highlightedIndex === index
  return (
    <MenuItem
      disableGutters
      {...itemProps}
      key={index}
      selected={isHighlighted}
      component="div"
    >
      <div style={{ padding: 7, display: 'flex', alignItems: 'center' }}>
        <AddCircle style={{ marginRight: '10px', color: '#0D46A0' }} />
        <strong>{suggestion.label}</strong>: {suggestion.detail}
      </div>
    </MenuItem>
  )
}
renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.number,
  index: PropTypes.number,
  itemProps: PropTypes.object,
  selectedItem: PropTypes.string,
  suggestion: PropTypes.shape({
    label: PropTypes.string,
    detail: PropTypes.string,
  }).isRequired,
}

function getSuggestions(value = '', suggestions = {}) {
  const inputValue = deburr(value.trim()).toLowerCase()
  const inputLength = inputValue.length
  const formattedSuggestions = []
  // TODO: Remove filter when Campaign Description is valid backend facet
  Object.keys(suggestions).forEach((key) => {
    suggestions[key].forEach((text) => {
      if (text.toLowerCase().indexOf(inputValue) !== -1) {
        formattedSuggestions.push({ label: key, detail: text })
      }
    })
  })

  return inputLength === 0 ? [] : formattedSuggestions
}

export class DownshiftMultiple extends React.PureComponent {
  render() {
    const {
      classes = {},
      suggestions = {},
      searchTerm = '',
      isFetching = false,
      selectedItems = [],
      onSearchInputChange = () => {},
      handleKeyDown = () => {},
      handleChange = () => {},
      handleDelete = () => {},
      placeholder = SEARCH_BOX_PLACEHOLDER,
    } = this.props
    return (
      <Downshift
        id="downshift-multiple"
        inputValue={searchTerm}
        onChange={handleChange}
        itemToString={formatSelectedItem}
        onBlur={() => this.setState({ isOpen: false })}
      >
        {({
          getInputProps,
          getItemProps,
          isOpen,
          inputValue: inputValue2,
          selectedItem: selectedItem2,
          highlightedIndex,
        }) => (
          <div className={classes.container}>
            {renderInput({
              fullWidth: true,
              classes,
              InputProps: getInputProps({
                startAdornment: selectedItems.map((item, idx) => (
                  <Chip
                    key={idx}
                    tabIndex={-1}
                    label={item}
                    className={classes.chip}
                    onDelete={() => handleDelete(item)}
                    variant="outlined"
                    size="small"
                  />
                )),
                onKeyDown: handleKeyDown,
                onChange: onSearchInputChange,
                placeholder: selectedItems.length > 0 ? '' : placeholder,
              }),
            })}
            {isOpen &&
              (isFetching ? (
                <Paper className={classes.paper} square>
                  <MenuItem disableGutters component="div">
                    <div
                      style={{
                        padding: 7,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <CircularProgress
                        size={20}
                        style={{ marginRight: '10px' }}
                      />{' '}
                      Loading...
                    </div>
                  </MenuItem>
                </Paper>
              ) : (
                <Paper className={classes.paper} square>
                  {getSuggestions(inputValue2, suggestions).map(
                    (suggestion, index) =>
                      renderSuggestion({
                        suggestion,
                        index,
                        itemProps: getItemProps({
                          item: (
                            <div data={suggestion}>
                              <strong>{suggestion.label}:</strong>{' '}
                              {suggestion.detail}
                            </div>
                          ),
                        }),
                        highlightedIndex,
                        selectedItem: selectedItem2,
                      })
                  )}
                </Paper>
              ))}
          </div>
        )}
      </Downshift>
    )
  }
}

DownshiftMultiple.propTypes = {
  classes: PropTypes.object.isRequired,
  onSearchInputChange: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  selectedItems: PropTypes.array,
  suggestions: PropTypes.shape({
    Brand: PropTypes.array,
    'Campaign Description': PropTypes.array,
    Channel: PropTypes.array,
    Department: PropTypes.array,
  }),
  isFetching: PropTypes.bool.isRequired,
  handleKeyDown: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
}

const MyComponent = (props) => {
  const classes = styles()
  return <DownshiftMultiple {...props} classes={classes} />
}

export default MyComponent
