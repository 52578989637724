import * as React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import Moment from 'moment/moment'
import axios from 'axios'
import { Navigate } from 'react-router-dom'

import { withStyles } from '@mui/styles'
import Avatar from '@mui/material/Avatar'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import GroupIcon from '@mui/icons-material/Group'
import Edit from '@mui/icons-material/Edit'
import { Delete } from '@mui/icons-material'
import SearchIcon from '@mui/icons-material/Search'
import FormControl from '@mui/material/FormControl'
import { TextField, Button, CircularProgress, Grid } from '@mui/material'
import { withEnv } from '@praxis/component-runtime-env'
import CustomCard from '../../Common/Card/CustomCard'
import GroupPeopleList from '../GroupPeopleList'
import HeaderTitle from '../../Header/HeaderTitle'
import EditGroupForm from '../EditGroupForm'
import {
  fetchGroupPeople,
  openModal,
  closeModal,
  updateGroups,
} from '../../../store/groups/actionCreator'
import DeletGroupDialog from './DeleteGroupDialog'
import apiConfig from '../../../config/apiConfig'
import { makeSelectIsGroupAdmin } from '../../../store/groups/selector'
import { selectMemberOf } from '../../../store/auth/selector'
import { isAuthorizedToPage } from '../../../helpers/UtilityHelper'
import withRouter from '../../../containers/Router/WithRouter'

const styles = {
  peopleContainer: {
    padding: '0 8px',
  },
  root: {
    marginTop: 7,
  },
  primary: {
    width: '100px',
  },
  secondary: {
    width: '220px',
  },
  buttonProgress: {
    color: '#2196f3',
  },
  formControl: {
    minWidth: 500,
    width: '90%',
  },
  searchIcon: {
    color: '#8d8d8d',
    top: '20px',
    position: 'relative',
  },
  textField: {
    marginTop: 0,
  },
  itemPadding: {
    padding: 10,
  },
  containerPadding: {
    padding: 2,
  },
  cardContent: {
    display: 'block',
    color: '#212121',
    fontSize: '14px',
  },
}

class GroupDashboardPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      filterText: '',
      deleteGroupModalOpen: false,
      redirect: false,
    }
    this.openBoardsFrom = this.openBoardsFrom.bind(this)
    this.closeBoardsFrom = this.closeBoardsFrom.bind(this)
    this.handleFilter = this.handleFilter.bind(this)
    this.getPeopleList = this.getPeopleList.bind(this)
  }

  componentDidMount() {
    this.props.fetchGroupPeople(this.props?.router?.params?.groupId)
  }

  openBoardsFrom() {
    this.props.openModal('groupsForm')
  }

  closeBoardsFrom() {
    this.props.closeModal('groupsForm')
  }

  generateBreadcrumb(arr, title) {
    return arr
      .map((y) =>
        y.path
          ? !title
            ? '<a style="text-decoration: none" href="' +
              y.path +
              '">' +
              y.title +
              '</a>'
            : y.title
          : y.title
      )
      .join(!title ? ' <span style="color: #ccc">/</span> ' : ' / ')
  }

  handleFilter(event) {
    this.setState({ filterText: event.target.value })
  }

  getPeopleList() {
    const {
      groups: { groupPeopleList = {} },
    } = this.props
    const { user = [] } = groupPeopleList
    const userData = [...user]
    if (this.state.filterText) {
      return userData.filter((item) =>
        item['display_name']
          .toString()
          .toLowerCase()
          .includes(this.state.filterText.toLowerCase())
      )
    }
    return userData
  }

  deleteGroup = (groupId = '') => {
    const { updateGroups = () => {} } = this.props
    axios
      .delete(`${apiConfig.groups.groupDelete}${groupId}`)
      .then((response) => {
        updateGroups(response.data)
        this.setState({ redirect: true })
      })
  }

  renderDCardHeaderActions = () => {
    const { classes = {}, isGroupAdmin = false } = this.props
    return (
      isGroupAdmin && (
        <Button
          color="primary"
          className={classes.button}
          data-cy="groupDeleteButton"
          onClick={() => this.setState({ deleteGroupModalOpen: true })}
        >
          <Delete className={classes.rightIcon} />
          Delete Group
        </Button>
      )
    )
  }

  renderMCardHeaderActions = () => {
    const {
      classes = {},
      isGroupAdmin = false,
      memberOf = [],
      env = {},
    } = this.props
    const { REACT_APP_ROLES_CONFIG = {} } = env
    const isAuthorizedUser = isAuthorizedToPage(
      'groups',
      memberOf,
      REACT_APP_ROLES_CONFIG
    )
    return (
      isGroupAdmin &&
      isAuthorizedUser && (
        <Button
          color="primary"
          className={classes.button}
          data-cy="groupEditButton"
          onClick={() => this.openBoardsFrom()}
        >
          <Edit className={classes.rightIcon} />
          Edit Group
        </Button>
      )
    )
  }

  getDetailsCardConfigs = () => {
    const { groups: { list_name = '' } = {} } = this.props
    return {
      cardHeader: {
        headerAvatar: (
          <Avatar aria-label="Recipe">
            <GroupIcon />
          </Avatar>
        ),
        headerActionContent: this.renderDCardHeaderActions(),
        title: list_name ? list_name : 'Group Details',
        subheader: '',
        identifier: '',
      },
      displayFooter: false,
    }
  }
  getMembersCardConfigs = () => ({
    cardHeader: {
      headerAvatar: (
        <Avatar aria-label="Recipe">
          <GroupIcon />
        </Avatar>
      ),
      headerActionContent: this.renderMCardHeaderActions(),
      title: 'Group Members',
      subheader: '',
      identifier: '',
    },
    displayFooter: false,
  })

  renderDetailCardContent = () => {
    const { classes = {}, groups = {} } = this.props
    const { groupPeopleList = {} } = groups
    const {
      create_date = '',
      creator_name = '',
      list_count = 0,
      list_name = '',
    } = groupPeopleList
    const createdDate =
      create_date && Moment(create_date).isValid()
        ? Moment(create_date).format('MMM D, YYYY h:mm a')
        : ''
    return (
      <div className={classes.cardContent}>
        <List dense>
          <ListItem>
            <ListItemText className={classes.primary} primary="Group Name" />
            <ListItemText
              className={classes.secondary}
              secondary={list_name}
              secondaryTypographyProps={{
                'data-cy': 'groupDashName',
              }}
            />
          </ListItem>
          <ListItem>
            <ListItemText className={classes.primary} primary="Created By" />
            <ListItemText
              className={classes.secondary}
              secondary={creator_name}
              secondaryTypographyProps={{
                'data-cy': 'groupCreatorName',
              }}
            />
          </ListItem>
          <ListItem>
            <ListItemText className={classes.primary} primary="Created On" />
            <ListItemText
              className={classes.secondary}
              secondary={createdDate}
              secondaryTypographyProps={{
                'data-cy': 'groupCreatedDate',
              }}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              className={classes.primary}
              primary="Number of People in Group"
            />
            <ListItemText
              className={classes.secondary}
              secondary={list_count}
              secondaryTypographyProps={{
                'data-cy': 'peopleInGroup',
              }}
            />
          </ListItem>
        </List>
      </div>
    )
  }

  renderMembersCardContent = () => {
    const { classes = {}, groups = {} } = this.props
    const { loading = {} } = groups
    const { peopleListLoading = false } = loading

    return peopleListLoading ? (
      <CircularProgress size={50} className={classes.buttonProgress} />
    ) : (
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <SearchIcon className={classes.searchIcon} />
          <FormControl variant="standard" className={classes.formControl}>
            <TextField
              variant="standard"
              id="searchFilterText"
              label="Search for People in Group"
              onChange={this.handleFilter.bind(this)}
              className={classes.textField}
              margin="normal"
              type="search"
              inputProps={{ 'data-cy': 'groupDashboardSearch' }}
              fullWidth
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <GroupPeopleList peopleList={this.getPeopleList() || []} />
        </Grid>
      </Grid>
    )
  }

  render() {
    const { classes = {}, groups = {} } = this.props
    const { groupPeopleList = {}, modal = {} } = groups
    const { groupsForm = false } = modal
    const { list_name = '', user = [] } = groupPeopleList
    const { deleteGroupModalOpen = false, redirect = false } = this.state
    const detailsCardConfig = this.getDetailsCardConfigs()
    const membersCardConfig = this.getMembersCardConfigs()

    if (redirect) {
      return (
        <Navigate
          to={{
            pathname: '/groups',
            state: { referrer: '/group' },
          }}
          replace
        />
      )
    }

    const breadCrumb = list_name
      ? [
          {
            title: 'Groups',
            path: '/groups',
          },
          {
            title: list_name,
          },
        ]
      : [{ title: 'Groups Dashboard' }]

    return (
      <div className={classes.GroupDashboardPage}>
        <HeaderTitle title={this.generateBreadcrumb(breadCrumb)} icon="Group" />
        <Helmet>
          <title>{this.generateBreadcrumb(breadCrumb, true)}</title>
        </Helmet>
        <div className={classes.peopleContainer}>
          <div className={classes.root}>
            <Grid container className={classes.containerPadding}>
              <Grid item sm={4} xs={12} className={classes.itemPadding}>
                <CustomCard
                  cardConfig={detailsCardConfig}
                  cardContent={this.renderDetailCardContent()}
                />
              </Grid>
              <Grid item sm={8} xs={12} className={classes.itemPadding}>
                <CustomCard
                  data-cy="groupsListCard"
                  cardConfig={membersCardConfig}
                  cardContent={this.renderMembersCardContent()}
                />
              </Grid>
            </Grid>
          </div>
        </div>
        {user && groupsForm && (
          <EditGroupForm
            open={groupsForm}
            onClose={this.closeBoardsFrom}
            formData={groupPeopleList}
          />
        )}
        <DeletGroupDialog
          open={deleteGroupModalOpen}
          group={groupPeopleList}
          close={() => this.setState({ deleteGroupModalOpen: false })}
          deleteGroup={this.deleteGroup}
        />
      </div>
    )
  }
}

GroupDashboardPage.propTypes = {
  classes: PropTypes.object,
  groups: PropTypes.object,
  match: PropTypes.object,
  headerTitle: PropTypes.string,
  fetchGroupPeople: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  updateGroups: PropTypes.func.isRequired,
  auth: PropTypes.object,
  isGroupAdmin: PropTypes.bool,
}

const mapStateToProps = (state = {}) => {
  const { layout = {}, groups = {}, auth = {} } = state
  const { headerTitle = '' } = layout
  const { lanId = '' } = auth
  return {
    groups,
    headerTitle,
    lanId,
    auth,
    isGroupAdmin: makeSelectIsGroupAdmin()(state),
    memberOf: selectMemberOf()(state),
  }
}

export default connect(mapStateToProps, {
  fetchGroupPeople,
  openModal,
  closeModal,
  updateGroups,
})(withEnv()(withStyles(styles)(withRouter(GroupDashboardPage))))
