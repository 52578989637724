export const FETCH_ASSET_METADATA = 'FETCH_ASSET_METADATA'
export const FETCH_ASSET_METADATA_FULFILLED = 'FETCH_ASSET_METADATA_FULFILLED'
export const FETCH_ASSET_METADATA_REJECTED = 'FETCH_ASSET_METADATA_REJECTED'
export const RESET_ASSET_METADATA = 'RESET_ASSET_METADATA'

export const FETCH_ASSET_REVISIONS = 'FETCH_ASSET_REVISIONS'
export const FETCH_ASSET_REVISIONS_FULFILLED = 'FETCH_ASSET_REVISIONS_FULFILLED'
export const FETCH_ASSET_REVISIONS_REJECTED = 'FETCH_ASSET_REVISIONS_REJECTED'
export const DELETE_ASSET_REVISIONS = 'DELETE_ASSET_REVISIONS'
export const DELETE_ASSET_REVISIONS_FULFILLED =
  'DELETE_ASSET_REVISIONS_FULFILLED'
export const DELETE_ASSET_REVISIONS_REJECTED = 'DELETE_ASSET_REVISIONS_REJECTED'
export const CLEAR_ASSET_REVISIONS = 'CLEAR_ASSET_REVISIONS'

export const FETCH_CATALOG = 'FETCH_CATALOG'
export const FETCH_CATALOG_FULFILLED = 'FETCH_CATALOG_FULFILLED'
export const FETCH_CATALOG_REJECTED = 'FETCH_CATALOG_REJECTED'
export const SET_UNSAVEDCHANGES_FLAG = 'SET_UNSAVEDCHANGES_FLAG'

export const SET_METADATA_LOADING = 'SET_METADATA_LOADING'
