import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withStyles } from '@mui/styles'
import { FormControl, Select, MenuItem } from '@mui/material'
import PropTypes from 'prop-types'

import { SEARCH_PAGE_SIZES } from '../../../../constants/publishConstants'
import {
  setPageSize,
  setCurrentPage,
  fetchAssets,
} from '../../../../store/publish/actions'
import { getPageSize } from '../../../../store/publish/selectors'

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  selectFilter: {
    height: '40px',
    fontWeight: '400',
    fontSize: '13px',
  },
}

const PageSizeSelector = ({
  classes = {},
  pageSize = Number(0),
  setPageSize = () => {},
  fetchAssets = () => {},
  setCurrentPage = () => {},
}) => {
  const onPageSizeChange = (event) => {
    const { target = {} } = event
    const { value = '' } = target
    const newPageSize = value

    if (newPageSize !== pageSize) {
      setPageSize(newPageSize)
      setCurrentPage(0)
      fetchAssets()
    }
  }

  return (
    <FormControl variant="standard" className={classes.root}>
      <Select
        value={pageSize}
        onChange={onPageSizeChange}
        variant="outlined"
        margin="dense"
        className={classes.selectFilter}
      >
        {SEARCH_PAGE_SIZES.map((sizeValue) => (
          <MenuItem key={sizeValue} value={sizeValue}>
            {`${sizeValue} / page`}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

PageSizeSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  pageSize: PropTypes.number.isRequired,
  setPageSize: PropTypes.func.isRequired,
  fetchAssets: PropTypes.func.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  pageSize: getPageSize(state),
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setPageSize,
      fetchAssets,
      setCurrentPage,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PageSizeSelector))
