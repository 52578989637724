import { isEmpty, trim } from 'lodash'
import {
  PROJECT_FILTER_ALL,
  PROJECT_FILTER_COMPLETED_PROJECTS,
  PROJECT_FILTER_CURRENT_PROJECTS,
  PROJECT_FILTER_WITH_CURRENT_TASKS,
  PROJECT_SORT_RUN_DATE,
} from '../../constants/projects'

const makeSortedProjectList = (
  sortBy = PROJECT_SORT_RUN_DATE,
  projectList = []
) => {
  const sortedProps = sortBy.split(':')
  let sortedPropName = sortBy
  let sortedOrder = ''
  if (sortedProps.length > 1) {
    sortedPropName = sortedProps[0]
    sortedOrder = sortedProps[1]
  }
  let sortedProjectList = projectList.sort(function (a, b) {
    if (sortedOrder) {
      return new Date(a[sortedPropName]) - new Date(b[sortedPropName])
    }
    return new Date(b[sortedPropName]) - new Date(a[sortedPropName])
  })
  return sortedProjectList
}

const getDefaultAssetPagination = () => ({
  size: 21,
  page: 0,
  sort: {
    field: 'asset_import_date',
    direction: 'DESC',
  },
})

const getDefaultProjectListPagination = () => ({
  size: 10,
  page: 1,
  sort_by: {
    feature: 'runDateDesc',
    sort: 'desc',
  },
  filter_by: '',
  search_term: '',
})

const isLoggedInUserProjectAdmin = (
  loginUserId = '',
  projectAdminList = []
) => {
  const projectAdminUser = projectAdminList.find((projectAdmin = {}) => {
    const { login_id = '' } = projectAdmin
    return (
      loginUserId &&
      login_id &&
      login_id.toLowerCase() === loginUserId.toLowerCase()
    )
  })
  return !isEmpty(projectAdminUser)
}

const isProjectHaveCurrentTask = (admin_tasks = [], user_tasks = []) => {
  const projectTasks = [...admin_tasks, ...user_tasks]
  const currentTaskProject = projectTasks.find(
    (projectTask = {}) => projectTask.is_active
  )
  return !isEmpty(currentTaskProject)
}

const makeFilteredProjectList = (filterData = {}, projectList = []) => {
  const { filterBy = PROJECT_FILTER_ALL, searchKeyword = '' } = filterData
  const searchTerm = trim(searchKeyword).toLowerCase()
  let projectCount = 0

  const filteredProjects = projectList.map((project = {}) => {
    let filteredProject = { ...project }
    const {
      project_name = '',
      campaign = '',
      channel = '',
      project_id = '',
      project_uuid = '',
      review_job_response = {},
      run_date,
    } = filteredProject
    const { user_tasks = [], admin_tasks = [] } = review_job_response
    const filterList = [
      project_name,
      campaign,
      channel,
      project_id,
      project_uuid,
    ].map((val) => val.toLowerCase())
    const canShow =
      (filterList.filter((str) => str.includes(searchTerm)) || []).length > 0
    switch (filterBy) {
      case PROJECT_FILTER_CURRENT_PROJECTS:
        if (new Date(run_date) >= new Date() && canShow) {
          filteredProject.show = true
          projectCount++
        } else {
          filteredProject.show = false
        }
        break
      case PROJECT_FILTER_COMPLETED_PROJECTS:
        if (new Date(run_date) < new Date() && canShow) {
          filteredProject.show = true
          projectCount++
        } else {
          filteredProject.show = false
        }
        break
      case PROJECT_FILTER_WITH_CURRENT_TASKS:
        if (isProjectHaveCurrentTask(admin_tasks, user_tasks) && canShow) {
          filteredProject.show = true
          projectCount++
        } else {
          filteredProject.show = false
        }
        break
      default:
        if (canShow) {
          filteredProject.show = true
          projectCount++
        } else {
          filteredProject.show = false
        }
    }
    return filteredProject
  })

  return {
    projectList: filteredProjects,
    projectCount,
  }
}

const updateTaskInfoToProjectList = (projectList = [], taskData = {}) => {
  const { projectId = '', taskInfo = {} } = taskData

  const updateProjectList = projectList.map((project = {}) => {
    const updatedProject = { ...project }
    if (updatedProject.project_uuid === projectId) {
      updatedProject['review_job_response'] = taskInfo
    }
    return updatedProject
  })

  return updateProjectList
}

export {
  getDefaultAssetPagination,
  isLoggedInUserProjectAdmin,
  isProjectHaveCurrentTask,
  makeFilteredProjectList,
  makeSortedProjectList,
  updateTaskInfoToProjectList,
  getDefaultProjectListPagination,
}
