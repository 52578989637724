import { SHOW_NOTIFICATION } from './actionType'

export const dispatchNotification =
  (isShown, message, variant = 'info', duration = 0) =>
  (dispatch) => {
    dispatch({
      type: SHOW_NOTIFICATION,
      payload: {
        isShown,
        message,
        variant,
        duration,
      },
    })
  }

export function showNotification(
  isShown,
  message,
  variant = 'info',
  duration = 0
) {
  return {
    type: SHOW_NOTIFICATION,
    payload: {
      isShown,
      message,
      variant,
      duration,
    },
  }
}
