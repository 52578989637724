import { Backdrop, CircularProgress } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  progressBar: {
    position: 'absolute',
    zIndex: 1000,
    top: '50%',
    left: '45%',
    color: '#333333',
  },
}))

export default function CircularPageLoader({
  handleClose = () => {},
  open = false,
  invisible = false,
}) {
  const classes = useStyles()
  return (
    <div>
      <Backdrop
        className={classes.backdrop}
        open={open}
        onClick={handleClose}
        invisible={invisible}
      >
        <CircularProgress className={classes.progressBar} color="primary" />
      </Backdrop>
    </div>
  )
}
