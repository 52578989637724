import * as React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@mui/material'
import { Delete, DeleteSweep } from '@mui/icons-material'

const styles = makeStyles((theme) => ({
  button: {
    fontSize: '12px',
    color: 'white',
    backgroundColor: useTheme().palette.primary.bostonRed,
  },
  cancelButton: {
    fontSize: '12px',
    color: 'blue',
    backgroundColor: useTheme().palette.primary.lightestGrey,
  },
  paddingLeft: {
    paddingLeft: '5px',
  },
}))

const RemoveAssetsDailog = ({
  classes = {},
  noOfSelectedAssets = 0,
  dialogOpen,
  removeAssets = () => {},
  toggleConfirmationModal = () => {},
}) => (
  <div>
    <div>
      <Tooltip title="Remove Asset">
        <IconButton
          aria-label="Remove Asset"
          onClick={() => toggleConfirmationModal()}
          size="large"
        >
          <DeleteSweep />
        </IconButton>
      </Tooltip>
      <Dialog
        open={dialogOpen}
        onClose={() => toggleConfirmationModal()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Remove Asset(s)</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`${noOfSelectedAssets} asset(s) will be removed from this board.`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            data-cy="removeAssetDialogButton"
            title="Remove Asset(s)"
            onClick={removeAssets}
            className={classes.button}
          >
            <Delete />
            <span style={styles.paddingLeft}>Remove Asset(s)</span>
          </Button>
          <Button
            onClick={() => toggleConfirmationModal()}
            color="primary"
            className={classes.cancelButton}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  </div>
)

RemoveAssetsDailog.propTypes = {
  classes: PropTypes.object,
  removeAssets: PropTypes.func,
  dialogOpen: PropTypes.bool,
  noOfSelectedAssets: PropTypes.number,
  toggleConfirmationModal: PropTypes.func,
}

const MyComponent = (props) => {
  const classes = styles()
  return <RemoveAssetsDailog {...props} classes={classes} />
}

export default MyComponent
