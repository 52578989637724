import CustomPagination from '../../components/CustomPagination'
import CustomDropdown from '../../components/CustomDropdown'
import FiltersLayout from './Filters'
import AssetList from './Assets'

function SearchPage(props) {
  return <></>
}

SearchPage.AssetList = AssetList
SearchPage.Pagination = CustomPagination
SearchPage.SortOptions = CustomDropdown
SearchPage.FiltersLayout = FiltersLayout

export default SearchPage
