export const TOGGLE_UPLOAD_MANAGER = 'TOGGLE_UPLOAD_MANAGER'
export const TOGGLE_PROJECT = 'TOGGLE_PROJECT'

export const UPLOAD_INITIATED = 'UPLOAD_INITIATED'

export const CHUNK = 'START_CHUNK'
export const CHUNK_SUCCESS = 'START_CHUNK_SUCCESS'
export const CHUNK_FAIL = 'START_CHUNK_FAIL'

export const UPLOAD_START_CALL = 'UPLOAD_START_CALL'
export const UPLOAD_START_CALL_SUCCESS = 'UPLOAD_START_CALL_SUCCESS'
export const UPLOAD_START_CALL_FAIL = 'UPLOAD_START_CALL_FAIL'

export const UPLOAD_END_CALL = 'UPLOAD_END_CALL'
export const UPLOAD_END_CALL_SUCCESS = 'UPLOAD_END_CALL_SUCCESS'
export const UPLOAD_END_CALL_FAIL = 'UPLOAD_END_CALL_FAIL'

export const UPLOAD_JOB_STATUS_CALL = 'UPLOAD_JOB_STATUS_CALL'
export const UPLOAD_JOB_STATUS_CALL_SUCCESS = 'UPLOAD_JOB_STATUS_CALL_SUCCESS'
export const UPLOAD_JOB_STATUS_CALL_FAIL = 'UPLOAD_JOB_STATUS_CALL_FAIL'
