import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import {
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import { Apps } from '@mui/icons-material'
import FormatListBulleted from '@mui/icons-material/FormatListBulleted'
import SearchIcon from '@mui/icons-material/Search'

import firefly from '../../analytics/firefly'
import {
  searchProjects,
  projectsFilter,
} from '../../store/projects/actionCreator'
import {
  PROJECT_FILTER_ALL,
  PROJECT_FILTER_COMPLETED_PROJECTS,
  PROJECT_FILTER_CURRENT_PROJECTS,
  PROJECT_FILTER_WITH_CURRENT_TASKS,
  PROJECT_SORT_OPTIONS,
} from '../../constants/projects'
import { projectListFilterEventDebounced } from '../../helpers/ProjectsHelper'

const styles = makeStyles((theme) => ({
  root: {
    direction: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  formControl: {
    margin: '4px',
    display: 'flex',
    wrap: 'nowrap',
    [useTheme().breakpoints.down('md')]: {
      marginBottom: useTheme().spacing(2),
    },
  },
  selectEmpty: {
    marginTop: useTheme().spacing(2),
  },
  iconPadding: {
    paddingTop: 25,
  },
  textField: {
    margin: '0',
    borderRadius: '5px',
  },
  viewButtonActive: {
    color: 'white',
    backgroundColor: '#188295',
    borderRadius: '2px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  viewButtonInactive: {
    color: '#188295',
    border: '1px solid #188295',
    borderRadius: '2px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  gridAlignment: {
    justifyContent: 'center',
  },
  gridViewPadding: {
    paddingRight: '35px',
    paddingBottom: '10px',
  },
  viewGrid: {
    display: 'flex',
  },
}))

export class Filters extends React.Component {
  constructor(props, context) {
    super(props, context)
  }

  handleSearch = (event) => {
    const {
      projectListPagination = {},
      lanId = '',
      searchProjects = () => {},
    } = this.props
    let searchTerm = event.target.value
    searchTerm = searchTerm.trim()
    const newPagination = {
      ...projectListPagination,
      search_term: searchTerm,
      page: 1,
    }

    projectListFilterEventDebounced(newPagination, lanId, searchProjects)
  }

  handleSort = (event) => {
    const {
      projectListPagination = {},
      lanId = '',
      searchProjects = () => {},
    } = this.props
    const sortTerm = event.target.value

    const newPagination = {
      ...projectListPagination,
      sort_by: {
        feature: sortTerm,
        sort: '',
      },
      page: 1,
    }

    searchProjects(newPagination, lanId)
    firefly.trackProjectListSort(sortTerm, lanId)
  }

  handleFilter = (event) => {
    const {
      projectListPagination = {},
      lanId = '',
      searchProjects = () => {},
    } = this.props
    let filterTerm = event.target.value

    const newPagination = {
      ...projectListPagination,
      filter_by: filterTerm,
      page: 1,
    }

    searchProjects(newPagination, lanId)
    firefly.trackProjectListFilter(filterTerm, lanId)
  }

  render() {
    const {
      classes,
      view = 'list',
      updatePreferences,
      projectListPagination = {},
    } = this.props
    const {
      sort_by: sortBy = {},
      filter_by: filterBy = '',
      search_term: searchTerm = '',
    } = projectListPagination
    return (
      <div className={classes.root}>
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          spacing={2}
          className={classes.gridViewPadding}
          sx={{ display: { xl: 'none', lg: 'none', md: 'none', xs: 'block' } }}
        >
          <Grid item>
            <FormatListBulleted
              className={
                view === 'list'
                  ? classes.viewButtonActive
                  : classes.viewButtonInactive
              }
              onClick={() => {
                updatePreferences('list')
              }}
            />
          </Grid>
          <Grid item>
            <Apps
              className={
                view === 'grid'
                  ? classes.viewButtonActive
                  : classes.viewButtonInactive
              }
              onClick={() => {
                updatePreferences('grid')
              }}
            />
          </Grid>
        </Grid>
        <Grid container className={classes.gridAlignment}>
          <Grid item xs={12} sm={5} md={4} lg={3}>
            <FormControl variant="standard" className={classes.formControl}>
              <TextField
                className={classes.textField}
                label="Search Projects"
                type="search"
                variant="outlined"
                size="small"
                defaultValue={searchTerm}
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <IconButton size="large">
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                  'data-cy': 'searchProjects',
                }}
                onChange={this.handleSearch}
              ></TextField>
            </FormControl>
          </Grid>
          <Grid item xs={0} sm={0} md={0} lg={2} />
          <Grid item xs={12} sm={3} md={3} lg={2}>
            <FormControl
              className={classes.formControl}
              variant="outlined"
              size="small"
            >
              <InputLabel>Filter By</InputLabel>
              <Select
                variant="outlined"
                data-cy="filterOptions"
                onChange={this.handleFilter}
                label="Filter By"
                value={filterBy}
              >
                <MenuItem data-cy="none" value={PROJECT_FILTER_ALL}>
                  None
                </MenuItem>
                <MenuItem
                  data-cy="currentTasks"
                  value={PROJECT_FILTER_WITH_CURRENT_TASKS}
                >
                  Projects With Current Tasks
                </MenuItem>
                <MenuItem
                  data-cy="currentProjects"
                  value={PROJECT_FILTER_CURRENT_PROJECTS}
                >
                  Current Projects
                </MenuItem>
                <MenuItem
                  data-cy="completedProjects"
                  value={PROJECT_FILTER_COMPLETED_PROJECTS}
                >
                  Completed Projects
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3} md={3} lg={2}>
            <FormControl
              className={classes.formControl}
              variant="outlined"
              size="small"
            >
              <InputLabel>Sort By</InputLabel>
              <Select
                variant="outlined"
                data-cy="sortOptions"
                onChange={this.handleSort}
                label="Sorty By"
                value={sortBy.feature}
              >
                {PROJECT_SORT_OPTIONS.map((key) => (
                  <MenuItem
                    data-cy={key.value}
                    key={key.value}
                    value={key.value}
                  >
                    {key.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid
            container
            className={classes.viewGrid}
            xs={12}
            sm={2}
            md={1}
            lg={1}
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
            sx={{ display: { md: 'block', xs: 'none', sm: 'none' } }}
          >
            <Grid item>
              <FormatListBulleted
                data-cy={
                  view === 'list'
                    ? 'projectListButtonActive'
                    : 'projectListButtonInactive'
                }
                className={
                  view === 'list'
                    ? classes.viewButtonActive
                    : classes.viewButtonInactive
                }
                onClick={() => {
                  updatePreferences('list')
                }}
              />
            </Grid>
            <Grid item>
              <Apps
                data-cy={
                  view === 'grid'
                    ? 'projectGridButtonActive'
                    : 'projectGridButtonInactive'
                }
                className={
                  view === 'grid'
                    ? classes.viewButtonActive
                    : classes.viewButtonInactive
                }
                onClick={() => {
                  updatePreferences('grid')
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }
}

Filters.propTypes = {
  classes: PropTypes.object.isRequired,
  searchProjects: PropTypes.func,
  lanId: PropTypes.string,
  updatePreferences: PropTypes.func,
  view: PropTypes.string,
  projectListPagination: PropTypes.object,
  projectsFilter: PropTypes.func,
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      searchProjects,
      projectsFilter,
    },
    dispatch
  )

const mapStateToProps = (state = {}) => {
  const { layout = {}, projects = {}, auth = {} } = state
  const { headerTitle } = layout
  const { lanId } = auth
  const { projectListPagination = {} } = projects
  return {
    projects: projects,
    headerTitle: headerTitle,
    lanId,
    projectListPagination,
  }
}

const MyComponent = (props) => {
  const classes = styles()
  return <Filters {...props} classes={classes} />
}

export default connect(mapStateToProps, mapDispatchToProps)(MyComponent)
