import React from 'react'
import PropTypes from 'prop-types'
import { cloneDeep } from 'lodash'
import moment from 'moment'
import Moment from 'moment-timezone'
import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import { Card, CardActions, Checkbox, IconButton, Grid } from '@mui/material'
import ReactImageMagnify from 'react-image-magnify'
import Divider from '@mui/material/Divider'
import Tooltip from '@mui/material/Tooltip'
import CloseIcon from '@mui/icons-material/Close'
import WarningIcon from '@mui/icons-material/Warning'
import ErrorIcon from '@mui/icons-material/Error'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import apiConfig from '../../config/apiConfig'
import { identifyFileType } from '../../helpers/fileTypeHelper'
import NoThumbnail from '../../images/NoThumbnail.svg?url'
import DownloadMenu from './DownloadMenu'
import {
  convertUTCtoLocal,
  formatCSTDate,
  formatUsageRightsDate,
  sortUsageInfoByExpDate,
} from '../../helpers/dateHelper'
import { appendQueryParams } from '../../helpers/UrlHelper'
import { getThumbnailToPreviewUrl } from '../../helpers/stringHelper'
import { checkUsageRightsExpiry } from '../../helpers/usageRightsHelper'

const styles = makeStyles((theme) => ({
  card: {
    width: '285px',
    height: '385px',
    borderRadius: '8px',
    position: 'relative',
    boxShadow: '#ececec 0px 0px 5px',
    border: '1px solid #f4f0f0',
    marginRight: 10,
  },
  media: {
    height: '295px',
    width: '295px',
    backgroundSize: 'contain',
    margin: '0 auto',
  },
  cardActions: {
    justifyContent: 'space-between',
    fontSize: '12px',
  },
  header: {
    textAlign: 'left',
    height: '35px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: useTheme().palette.primary.lightestGrey,
  },
  fileName: {
    paddingBottom: '4px',
    paddingTop: '4px',
    overflowWrap: 'break-word',
    wordBreak: 'break-all',
    hyphens: 'auto',
    fontSize: 12,
    color: useTheme().palette.primary.primaryBlue,
    lineHeight: '16px',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '200px',
    overflowWrap: 'normal',
  },
  fileTypeIcon: {
    height: '40px',
    width: '40px',
    borderRadius: '50%',
    paddingRight: '5px',
    cursor: 'pointer',
  },
  fileIconAndName: {
    display: 'flex',
    alignItems: 'center',
  },
  overlay: {
    position: 'absolute',
    height: '295px',
    width: '295px',
    zIndex: 500,
    backgroundColor: useTheme().palette.primary.darkGrey,
    opacity: '0.5',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    margin: '0 auto',
  },
  assetCheckbox: {
    height: '24px',
    width: '24px',
  },
  viewIcon: {
    fontSize: '80px',
  },
  lockIcon: {
    color: 'red',
    height: '24px',
    width: '24px',
  },
  noMDAccess: {
    color: useTheme().palette.primary.bostonRed,
    textTransform: 'capitalize',
    fontSize: '12px',
  },
  menu: {
    zIndex: '3',
    position: 'relative',
    backgroundColor: 'white',
  },
  download: {
    justifyContent: 'space-between',
    fontSize: '12px',
    padding: '0px',
  },
  cardHeader: {
    paddingTop: '5px',
  },
  drmId: {
    fontSize: '12px',
    paddingBottom: '5px',
  },
  usageInfo: {
    padding: '10px',
  },
  usageText: {
    fontSize: '10px',
    paddingBottom: '3px',
  },
  drmButton: {
    width: '100px',
    height: '22px',
    fontSize: '12px',
    backgroundColor: '#188295',
    color: 'white',
    border: 'none',
  },
  limitedUseButton: {
    width: '100px',
    height: '22px',
    fontSize: '12px',
    backgroundColor: '#FAFAFA',
    border: 'none',
    cursor: 'pointer',
    borderRadius: 5,
    '&:hover': {
      backgroundColor: '#F4F4F4',
    },
  },
  limitedUseText: {
    color: 'rgba(223, 139, 72, 1)',
    display: 'flex',
  },
  useIcon: {
    width: '12px',
    height: '12px',
    paddingRight: '3px',
    paddingLeft: '1px',
  },
  noUseButton: {
    width: '100px',
    height: '22px',
    fontSize: '12px',
    backgroundColor: '#FAFAFA',
    border: 'none',
    cursor: 'pointer',
    borderRadius: 5,
    '&:hover': {
      backgroundColor: '#F4F4F4',
    },
  },
  noUseText: {
    color: 'rgba(204, 0, 0, 1)',
    display: 'flex',
  },
  buttonContainer: {
    paddingLeft: '10px',
  },
  infoBox: {
    paddingLeft: '0px !important',
  },
  checkbox: {
    paddingRight: '0px !important',
  },
  usageContainer: {
    overflow: 'hidden',
    height: '226px',
  },
  usageContainerCreative: {
    overflow: 'auto',
    height: '250px',
  },
  navButton: {
    height: '12px',
    width: '12px',
  },
  radioButton: {
    height: '12px',
    width: '12px',
    paddingLeft: '2px',
    paddingRight: '2px',
  },
  drmNav: {
    bottom: '14px',
    position: 'absolute',
  },
  usageHeader: {
    padding: '10px',
    display: 'flex',
  },
  useCardIcon: {
    height: '20px',
    width: '20px',
    paddingRight: '5px',
  },
  navText: {
    fontSize: '15px',
  },
  urmLangText: {
    fontSize: '10px',
    textAlign: 'justify',
    overflow: 'auto',
    height: '144px',
    marginTop: '3px',
  },
  asset: {
    height: '285px !important',
  },
}))

const inlineStyles = (theme = {}) => ({
  selectedCard: {
    border: '1px solid ' + theme.palette.primary.darkGreen,
  },
})

export class AssetCard extends React.PureComponent {
  constructor(props) {
    super(props)
    this.ref = React.createRef()
    this.state = {
      overlay: false,
      drmOpen: false,
      currentDrm: 0,
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.checkAll !== this.props.checkAll) {
      this.props.onSelection(this.props.checkAll, this.ref, this.props.asset)
    }
  }

  onOverlayChange = () => {
    this.setState((state) => ({ overlay: !state.overlay }))
  }

  fileNameClick = (isCardChecked) => {
    this.props.onSelection(!isCardChecked, this.ref, this.props.asset)
  }

  onDrmClick = () => {
    this.setState((state) => ({ drmOpen: !state.drmOpen }))
  }

  checkIfUlimited = (usage_info) => {
    if (
      Object.keys(usage_info).length === 0 ||
      Object.keys(usage_info[0]).length === 0
    ) {
      return true
    }
    const usageLength = usage_info.length
    let count = 0
    for (let i = 0; i < usage_info.length; i++) {
      const { expiration_date = null } = usage_info[i] || {}
      const formattedExpDate = Moment(new Date(expiration_date).toISOString())
        .utc()
        .local()
        .format('YYYY-MM-DD')
      if (
        usage_info[i].available_channels === 'Any and All Media' &&
        formattedExpDate === '9999-12-31'
      ) {
        count++
      }
    }
    if (count === usageLength) {
      return true
    } else {
      return false
    }
  }

  checkExpired = (usage_info) => {
    const today = new Date()
    if (
      Object.keys(usage_info).length === 0 ||
      Object.keys(usage_info[0]).length === 0
    ) {
      return false
    }
    const usageLength = usage_info.length
    const usageInfo = [...usage_info]
    const filteredUsageInfo = usageInfo
      .sort((a, b) => new Date(b.expiration_date) - new Date(a.expiration_date))
      .filter((urm) => !urm.expiration_date?.includes('9999'))
    let maxExpirationDate = filteredUsageInfo.length
      ? filteredUsageInfo[0]?.expiration_date
      : usageInfo[0]?.expiration_date
    if (maxExpirationDate < today.getTime()) {
      return true
    }
    return false
  }

  nextDrm(current, length) {
    if (current != length - 1) {
      this.setState((state) => ({ currentDrm: state.currentDrm + 1 }))
    } else {
      this.setState({ currentDrm: 0 })
    }
  }

  prevDrm(current, length) {
    if (current != 0) {
      this.setState((state) => ({ currentDrm: state.currentDrm - 1 }))
    } else {
      this.setState({ currentDrm: length - 1 })
    }
  }

  render() {
    const {
      classes = {},
      asset = {},
      onClickDownloadAsset = () => {},
      onSelection,
      selectedItems = [],
      openModal = () => {},
      theme,
    } = this.props
    const { drmOpen, currentDrm } = this.state
    const {
      asset_id = '',
      uploaded_date: import_date = '',
      metadata: metadata_info = {},
      original_asset_id = '',
      render_urls: {
        preview_asset_url = '',
        original_asset_url = '',
        thumbnail_asset_url = '',
        optimized_asset_url = '',
      } = {},
    } = asset
    const {
      file_metadata: { file_name = '', file_size_mb = 0 } = {},
      usage_info: usageInfo = {}, //TODO recheck
      creative_usage_instructions: creativeUsageInstructions = {}, //TODO recheck
    } = metadata_info || {}
    const usage_info = cloneDeep(usageInfo) || {}
    const creative_usage_instructions =
      cloneDeep(creativeUsageInstructions) || {}
    const { key = '' } = apiConfig
    const imageQueryParts = {
      key,
      make_square: true,
    }
    const previewFromThumbnailUrl =
      getThumbnailToPreviewUrl(thumbnail_asset_url)
    const imageQuery = appendQueryParams(
      previewFromThumbnailUrl,
      imageQueryParts,
    )
    const bigImageQueryParts = {
      key,
      make_square: true,
    }
    const bigImageQuery = appendQueryParams(
      optimized_asset_url,
      bigImageQueryParts,
    )
    // Single file download from asset card
    const downloadImageQueryParts = {
      file_name,
      is_download: true,
      key,
    }
    const downloadURL = appendQueryParams(
      original_asset_url,
      downloadImageQueryParts,
    )
    const preview_file_name = file_name?.split('.')[0] + '.jpeg'
    const downloadPreviewImageQueryParts = {
      file_name: preview_file_name,
      is_download: true,
      key,
    }
    const downloadPreviewURL = appendQueryParams(
      optimized_asset_url,
      downloadPreviewImageQueryParts,
    )
    const isCardChecked = !!selectedItems.find((item) => item === asset_id)
    const pageInlineStyles = {}
    const sortedUsageInfo =
      Object.keys(usage_info).length === 0 ||
      Object.keys(usage_info[0]).length === 0
        ? {}
        : sortUsageInfoByExpDate(usage_info)
    const usageRightsInfo = Object.assign({}, sortedUsageInfo[currentDrm])
    const {
      rights_holder_name = '',
      drm_id = '',
      available_channels = '',
      available_territories = '',
      buyer = '',
      creation_date = '',
      custom_usage_rights = false,
      rights_holder_type = '',
      expiration_date = '',
      custom_usage_rights_language = '',
      usage_rights_language = '',
    } = usageRightsInfo
    return (
      <Card
        raised
        className={classes.card}
        data-cy="assetCard"
        style={isCardChecked ? pageInlineStyles['selectedCard'] : {}}
      >
        {asset_id && (
          <CardActions className={classes.cardActions}>
            <Grid container spacing={2} className={classes.cardHeader}>
              <Grid item className={classes.checkbox}>
                {
                  // eslint-disable-next-line jsx-a11y/anchor-has-content
                  <a
                    id={asset_id}
                    ref={this.ref}
                    href={downloadURL}
                    tyle={{ display: 'none' }}
                    title={file_name}
                    data-assetMasterId={original_asset_id}
                    data-fileSizeMb={file_size_mb}
                    data-importDate={import_date}
                  />
                }
                <Checkbox
                  checked={isCardChecked}
                  data-cy={
                    isCardChecked ? 'assetCardChecked' : 'assetCardCheckbox'
                  }
                  onChange={(event) =>
                    onSelection(event.target.checked, this.ref, asset)
                  }
                  classes={{
                    root: classes.assetCheckbox,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm container className={classes.infoBox}>
                <Grid item container direction="column" spacing={2}>
                  <Grid item xs>
                    <div className={classes.fileName}>
                      <Tooltip
                        title={file_name}
                        placement="top-start"
                        disableHoverListener={
                          file_name?.length <= 30 ? true : false
                        }
                      >
                        <a
                          data-cy="assetCardLink"
                          style={{ cursor: 'pointer' }}
                          onClick={openModal}
                          className={classes.fileName}
                        >
                          {file_name}
                        </a>
                      </Tooltip>
                    </div>
                    <div data-cy="assetCardUploadDate">
                      {import_date
                        ? `Upload Date: ${convertUTCtoLocal(import_date)}`
                        : null}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardActions>
        )}
        <Divider />
        {asset_id ? (
          !drmOpen ? (
            <div>
              <ReactImageMagnify
                smallImage={{
                  isFluidWidth: true,
                  alt: file_name,
                  src: thumbnail_asset_url ? imageQuery : NoThumbnail,
                  height: 500,
                  width: 500,
                }}
                largeImage={{
                  alt: file_name,
                  src: bigImageQuery,
                  width: 1000,
                  height: 1000,
                }}
                enlargedImagePosition="over"
                className={classes.asset}
              />
              <div></div>
              <Divider />
              <CardActions className={classes.download}>
                <Grid container direction={'row'} spacing={1}>
                  <Grid item xs={12} lg={12} md={12}>
                    <Grid
                      container
                      direction="row"
                      justifyContent={'space-between'}
                      alignItems={'center'}
                    >
                      <Grid item className={classes.buttonContainer}>
                        {creative_usage_instructions?.do_not_use === true ? (
                          <button
                            onClick={this.onDrmClick}
                            className={classes.noUseButton}
                          >
                            <div className={classes.noUseText}>
                              <ErrorIcon className={classes.useIcon} />
                              Do Not Use
                            </div>
                          </button>
                        ) : checkUsageRightsExpiry(sortedUsageInfo) ? (
                          <button
                            className={classes.limitedUseButton}
                            onClick={this.onDrmClick}
                          >
                            <div className={classes.limitedUseText}>
                              <WarningIcon className={classes.useIcon} />
                              Expired
                            </div>
                          </button>
                        ) : !this.checkIfUlimited(sortedUsageInfo) ? (
                          <button
                            onClick={this.onDrmClick}
                            className={classes.limitedUseButton}
                          >
                            <div className={classes.limitedUseText}>
                              <WarningIcon className={classes.useIcon} />
                              Limited Use
                            </div>
                          </button>
                        ) : (
                          <div></div>
                        )}
                      </Grid>
                      <Grid item>
                        <DownloadMenu
                          downloadLink={downloadURL}
                          downloadPreviewLink={downloadPreviewURL}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardActions>
            </div>
          ) : (sortedUsageInfo.length > 0 &&
              Object.keys(sortedUsageInfo[0]).length > 0) ||
            creative_usage_instructions?.do_not_use === true ? (
            <div>
              <Grid container direction={'row'} spacing={1}>
                <Grid item xs={12} lg={12} md={12}>
                  <Grid
                    container
                    direction="row"
                    justifyContent={'space-between'}
                    alignItems={'center'}
                  >
                    <Grid item>
                      {creative_usage_instructions?.do_not_use === true ? (
                        <div className={classes.usageHeader}>
                          <ErrorIcon
                            className={classes.useCardIcon}
                            style={{ color: 'rgba(204, 0, 0, 1)' }}
                          />
                          Do Not Use
                        </div>
                      ) : checkUsageRightsExpiry(sortedUsageInfo) ? (
                        <div className={classes.usageHeader}>
                          <WarningIcon
                            className={classes.useCardIcon}
                            style={{ color: 'rgba(223, 139, 72, 1)' }}
                          />
                          Expired
                        </div>
                      ) : (
                        <div className={classes.usageHeader}>
                          <WarningIcon
                            className={classes.useCardIcon}
                            style={{ color: 'rgba(223, 139, 72, 1)' }}
                          />
                          Limited Use
                        </div>
                      )}
                    </Grid>
                    <Grid item>
                      <IconButton onClick={this.onDrmClick} size="large">
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {creative_usage_instructions?.do_not_use === true ? (
                <div className={classes.usageContainerCreative}>
                  <div className={classes.usageInfo}>
                    <div className={classes.usageText}>
                      <b>Creative Usage Instructions: </b>
                      {creative_usage_instructions?.instructions
                        ? creative_usage_instructions?.instructions
                        : 'Information not provided'}
                    </div>
                    <div className={classes.usageText}>
                      <b>Additional Directions: </b>
                      {creative_usage_instructions?.additional_directions
                        ? creative_usage_instructions?.additional_directions
                        : 'Information not provided'}
                    </div>
                  </div>
                </div>
              ) : (
                <div className={classes.usageContainer}>
                  <div className={classes.usageInfo}>
                    <div className={classes.drmId}>
                      DRM{drm_id ? drm_id.replace('DRM', '') : ''}
                    </div>
                    <div className={classes.usageText}>
                      <b>Buyer: </b> {buyer}
                    </div>
                    <div className={classes.usageText}>
                      <b>Rights holder name: </b> {rights_holder_name}
                    </div>
                    <div className={classes.usageText}>
                      <b>Rights holder type: </b> {rights_holder_type}
                    </div>
                    <div className={classes.usageText}>
                      <b>Available channels: </b> {available_channels}
                    </div>
                    <div className={classes.usageText}>
                      <b>Available territories: </b> {available_territories}
                    </div>
                    <div className={classes.usageText}>
                      <b>Expiration date: </b>{' '}
                      {formatUsageRightsDate(expiration_date)}
                    </div>
                    <div className={classes.usageText}>
                      <b>Usage Rights Language: </b>
                    </div>
                    <div className={classes.urmLangText}>
                      {custom_usage_rights
                        ? custom_usage_rights_language
                        : usage_rights_language}
                    </div>
                  </div>
                  {sortedUsageInfo?.length > 1 ? (
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems={'center'}
                      className={classes.drmNav}
                    >
                      <IconButton
                        onClick={() =>
                          this.prevDrm(currentDrm, sortedUsageInfo?.length)
                        }
                        className={classes.navButton}
                        size="large"
                      >
                        <ArrowForwardIosIcon
                          style={{ transform: 'scaleX(-1)' }}
                          className={classes.navButton}
                        />
                      </IconButton>
                      <div className={classes.navText}>
                        {currentDrm + 1} of {sortedUsageInfo.length}
                      </div>
                      <IconButton
                        onClick={() =>
                          this.nextDrm(currentDrm, sortedUsageInfo.length)
                        }
                        className={classes.navButton}
                        size="large"
                      >
                        <ArrowForwardIosIcon className={classes.navButton} />
                      </IconButton>
                    </Grid>
                  ) : (
                    <div />
                  )}
                </div>
              )}
            </div>
          ) : (
            <div className={classes.usageInfo}>
              <Grid container direction={'row'} spacing={1}>
                <Grid item xs={12} lg={12} md={12}>
                  <Grid
                    container
                    direction="row"
                    justifyContent={'space-between'}
                    alignItems={'center'}
                  >
                    <Grid item>
                      <div className={classes.drmId}>No Details Available</div>
                    </Grid>
                    <Grid item>
                      <IconButton onClick={this.onDrmClick} size="large">
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          )
        ) : (
          <h1 className={classes.media}>
            No Image <br /> No Download
          </h1>
        )}
      </Card>
    )
  }
}

AssetCard.propTypes = {
  asset: PropTypes.shape({
    asset_data: PropTypes.shape({
      file_name: PropTypes.string,
    }),
    asset_storage_info: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }),
  }),
  classes: PropTypes.object.isRequired,
  onClickDownloadAsset: PropTypes.func,
  onSelection: PropTypes.func,
  selectedItems: PropTypes.arrayOf(PropTypes.string),
  openModal: PropTypes.func.isRequired,
  checkAll: PropTypes.bool,
  theme: PropTypes.object,
}

const MyComponent = (props) => {
  const classes = styles()
  return <AssetCard {...props} classes={classes} />
}

export default MyComponent
