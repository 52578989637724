import { makeStyles } from '@mui/styles'
import NoThumbnail from '../../../images/NoThumbnail.svg?url'
import GeneratingThumbnail from '../../../images/GeneratingThumbnail.svg?url'
import {
  appendQueryParams,
  getThumbnailToPreviewUrl,
} from '../../mappers/searchMapper'
import apiConfig from '../../../config/apiConfig'

const styles = makeStyles(() => ({
  imgStyle: {
    verticalAlign: 'middle',
    maxHeight: '75px',
    maxWidth: '75px',
  },
}))

const ImageLoader = ({
  altText = '',
  inProgress,
  imgSrc = '',
  mimeType = '',
  className = '',
}) => {
  const classes = styles()
  let updatedImgSrc = imgSrc
  if (mimeType !== 'image/gif') {
    updatedImgSrc = getThumbnailToPreviewUrl(imgSrc)
  }
  updatedImgSrc = appendQueryParams(updatedImgSrc, {
    key: apiConfig.key,
    make_square: true,
  })
  if (inProgress) {
    updatedImgSrc = GeneratingThumbnail
  }
  return (
    <img
      data-cy="imageHolder"
      alt={altText}
      src={updatedImgSrc}
      className={className ? className : classes.imgStyle}
      onError={(e) => {
        e.target.src = NoThumbnail
      }}
    />
  )
}

export default ImageLoader
