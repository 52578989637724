import React from 'react'
import PropTypes from 'prop-types'
import { Comment } from '@mui/icons-material'

import { assetPropType } from '../../constants/annotation'

export const AssetListItem = ({
  asset = {},
  setCurrentAsset = () => {},
  primaryAsset = {},
  secondaryAsset = {},
  classes = {},
  isSecondary = false,
}) => {
  const isCurrentAsset = !isSecondary
    ? primaryAsset.asset_id === asset.asset_id
    : secondaryAsset.asset_id === asset.asset_id
  return (
    <div
      className={classes.assetListItem}
      onClick={() => setCurrentAsset(asset)}
      style={{ backgroundColor: isCurrentAsset ? '#DDDDDD' : '#EFEFEF' }}
    >
      <p style={{ maxWidth: '75%', wordBreak: 'break-all' }}>
        {asset.asset_name}
      </p>
      <span style={{ display: 'flex', alignItems: 'center' }}>
        <Comment />
        <span style={{ marginLeft: '5px' }}>{asset.comments_count || 0}</span>
      </span>
    </div>
  )
}

AssetListItem.propTypes = {
  setCurrentAsset: PropTypes.func,
  classes: PropTypes.object,
  idx: PropTypes.number,
  asset: assetPropType,
  primaryAsset: assetPropType,
  secondaryAsset: assetPropType,
  isSecondary: PropTypes.bool,
}

export default AssetListItem
