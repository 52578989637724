import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { isEqual, isEmpty, get } from 'lodash'
import { withStyles } from '@mui/styles'
import {
  Grid,
  Button,
  IconButton,
  CircularProgress,
  Tooltip,
} from '@mui/material'
import {
  Comment,
  InsertPhoto,
  ChevronLeft,
  ChevronRight,
  RotateLeft,
  ZoomIn,
  ZoomOut,
} from '@mui/icons-material'
import idx from 'idx'

import {
  AssetsSidebar,
  CommentSidebar,
  ImageAnnotator,
  RouteLeavingGuard,
  Toolbar,
  ToolSelector,
  ViewedBySidebar,
} from '../../components/Annotation'
import {
  assetPropType,
  TaskRoles,
  DEFAULT_SCALE,
} from '../../constants/annotation'
import { TASK_STATUS_NEW } from '../../constants/tasks'
import {
  getTaskAssets,
  getCommentsByAssetIdAndRevision,
  setPrimaryAsset,
  toggleComparisonView,
  setPrimaryPageNumber,
  setTaskStatus,
  fetchAssetVersions,
} from '../../store/annotation/actions'
import {
  getHighestIdNumber,
  getAnnotationFromComment,
} from '../../helpers/annotationHelper'
import {
  selectAssetVersion,
  selectIsActiveReview,
  selectIsCurrentTaskRole,
  selectTaskStatus,
} from '../../store/annotation/selectors'
import { praxis } from '../../config/themeConfig'
import { updateUserPreferences } from '../../store/preferences/actionCreator'
import withRouter from '../../containers/Router/WithRouter'

const styles = {
  homePage: {
    textAlign: 'center',
    height: '95%',
    //width: '81% !important',
  },
  sideButton: {
    borderRadius: '0',
    width: '50%',
    height: '50px',
    backgroundColor: '#EFEFEF',
    color: praxis.themePrimary,
  },
  innerButton: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  sidebar: {
    position: 'absolute',
    width: '320px',
    right: 0,
  },
  content: {
    width: '100%',
    height: '100%',
    overflowY: 'scroll',
    overflowX: 'hidden',
    backgroundColor: '#EFEFEF',
  },
  arrowButton: {
    color: '#FFFFFF',
    background: 'rgba(0, 0, 0, 0.5)',
    backgroundImage: 'none',
    height: '60px',
    width: '60px',
    margin: '10px',
    fontSize: '36px',
    zIndex: 1000,
    position: 'absolute',
    top: '45%',
  },
  body: {
    backgroundColor: '#484848',
    padding: '0px !important',
    overflow: 'scroll',
  },
  shade: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    height: '100%',
    position: 'absolute',
    zIndex: 1000,
  },
  cacheProgress: {
    color: 'green',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-12',
    marginLeft: '-12',
  },
  zoomTextDiv: {
    top: '130px',
    left: '91px',
    display: 'flex',
    zIndex: 5,
    position: 'absolute',
    backgroundColor: '#EFEFEF',
    height: '40px',
    width: '45px',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid rgb(141, 141, 141)',
    fontSize: '14px',
    fontWeight: '500',
  },
  zoomControlsDiv: {
    top: '171px',
    left: '91px',
    display: 'flex',
    zIndex: 5,
    position: 'absolute',
    flexDirection: 'column',
    backgroundColor: 'rgb(141, 141, 141)',
    border: '1px solid rgb(141, 141, 141)',
  },
  zoomControls: {
    height: '40px',
    width: '45px',
    // borderBottom: '1px solid rgb(141, 141, 141)',
    padding: 0,
    borderRadius: 0,
    minWidth: '45px !important',
    color: 'black',
    // boxShadow: 'rgb(0 0 0 / 17%) 0px -23px 25px 0px inset, rgb(0 0 0 / 15%) 0px -36px 30px 0px inset, rgb(0 0 0 / 10%) 0px -79px 40px 0px inset, rgb(0 0 0 / 6%) 0px 2px 1px, rgb(0 0 0 / 9%) 0px 4px 2px, rgb(0 0 0 / 9%) 0px 8px 4px, rgb(0 0 0 / 9%) 0px 16px 8px, rgb(0 0 0 / 9%) 0px 32px 16px',
  },
  zoomControlsStartIcon: {
    margin: 'unset !important',
  },
}

const angles = [0, 90, 180, 270]

// eslint-disable-next-line react/display-name
const ImageAnnotatorNew = React.forwardRef((props, ref) => (
  <ImageAnnotator {...props} zoomPerRef={ref} />
))

export class SingleAssetContainer extends React.PureComponent {
  constructor(props) {
    super(props)
    const { taskStatus = TASK_STATUS_NEW } = props
    // this.assetRefDictionary
    this.state = {
      showAssets: true,
      showViewedBy: false,
      currentTool: 'cursor',
      angle: 0,
      scale: DEFAULT_SCALE,
      width: 0,
      height: 0,
      annotations: [],
      hideAnnotationIds: [],
      taskStatus,
      zoomAnnotatorProps: {},
    }
    this.zoomPerRef = React.createRef()
  }

  async componentDidMount() {
    const {
      getTaskAssets = () => {},
      router = {},
      comments = [],
      annotation_zoom,
      assets = [],
    } = this.props
    const { params = {} } = router
    this.setState({ scale: annotation_zoom })
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
    window.addEventListener('beforeunload', this.beforeUnload)
    window.addEventListener('keydown', this.onArrowKeyPress, false)
    this.intervalId = setInterval(this.refreshComments, 30000)
    if (!!comments) {
      this.getAnnotationsFromComments([...comments])
    }
    // Required here so that when user switches to single view from SideBySide, we re-establish the ref dictionary
    this.assetRefDictionary = assets.reduce((dict, asset) => {
      dict[asset.asset_master_id] = React.createRef()
      return dict
    }, {})

    // await for Assets so that ref dictionary builder has valid data
    await getTaskAssets(params, () => {
      setTimeout(() => {
        this.getIdAndSetData()
      }, 500) // getting the asset_master_id (assetUnqIdUrl) and revision number from URL
    })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
    window.removeEventListener('beforeunload', this.beforeUnload)
    window.removeEventListener('keydown', this.onArrowKeyPress, false)
    clearInterval(this.intervalId)
  }

  async componentDidUpdate(prevProps, prevState) {
    const {
      getCommentsByAssetIdAndRevision = () => {},
      setPrimaryAsset = () => {},
      primaryAsset = {},
      assets = [],
      comments = [],
      primaryPageNumber = 1,
      primaryVersion,
      taskStatus,
      annotation_zoom,
    } = this.props
    this.updateWindowDimensions()
    if (prevProps.annotation_zoom !== annotation_zoom) {
      this.setState({ scale: annotation_zoom })
    }
    if (!isEqual(prevProps.assets, assets)) {
      if (assets.length > 0) {
        setPrimaryAsset(assets[0])
        this.assetRefDictionary = assets.reduce((dict, asset) => {
          dict[asset.asset_master_id] = React.createRef()
          return dict
        }, {})
      }
      this.setState({
        currentTool: 'cursor',
        angle: 0,
        hideAnnotationIds: [],
      })
    }
    if (prevState.assetUnqIdUrl !== this.state.assetUnqIdUrl) {
      if (assets.length > 0) {
        if (this.state.assetUnqIdUrl !== '') {
          setPrimaryAsset(
            this.state.singleAssetFromUrl,
            true,
            this.state.versionNumUrl,
            this.state.pageNumUrl,
            () => {
              this.setState({ showAssets: false })
            }
          )
        }
        this.assetRefDictionary = assets.reduce((dict, asset) => {
          dict[asset.asset_master_id] = React.createRef()
          return dict
        }, {})
      }
      this.setState({
        currentTool: 'cursor',
        angle: 0,
        hideAnnotationIds: [],
      })
    }

    if (
      !isEqual(prevProps.primaryAsset, primaryAsset) &&
      !isEmpty(primaryAsset) &&
      !isEmpty(prevProps.primaryAsset) &&
      isEqual(
        prevProps.primaryAsset.asset_master_id,
        primaryAsset.asset_master_id
      ) &&
      primaryVersion.revisionChanged
    ) {
      this.setState({
        currentTool: 'cursor',
        hideAnnotationIds: [],
      })
      getCommentsByAssetIdAndRevision()
    }

    if (!isEqual(prevProps.comments, comments)) {
      this.getAnnotationsFromComments([...comments])
    }
    if (prevProps.primaryPageNumber !== primaryPageNumber) {
      getCommentsByAssetIdAndRevision()
    }
    if (!prevState.showAssets && this.state.showAssets) {
      if (
        !!get(
          this.assetRefDictionary,
          `[${primaryAsset.asset_master_id}].current`
        )
      ) {
        this.assetRefDictionary[
          primaryAsset.asset_master_id
        ].current.scrollIntoView({
          behavior: 'instant',
          block: 'start',
        })
      }
    }

    if (!isEqual(prevProps.taskStatus, taskStatus)) {
      this.setState({ taskStatus })
    }
  }

  removeAssetUnqIdUrl = () => {
    this.setState({ assetUnqIdUrl: '' }) // setting assetUnqIdUrl to empty
  }

  getIdAndSetData = () => {
    const { fromComparisonToSingle = false } = this.props
    if (!fromComparisonToSingle) {
      const { assets } = this.props
      const pathName = window.location.pathname
      const stringArr = pathName.split('/')
      let assetUnqId = ''
      let assetVersionNum = 0
      let pageNum = 0
      if (stringArr.length > 7) {
        assetUnqId = stringArr[5] // extracting asset master id from URL
        assetVersionNum = stringArr[6] // extracting version number from URL
        pageNum = stringArr[7] // extracting page number from URL
      }
      if (assetUnqId !== '' && assetVersionNum > 0 && !isEmpty(assets)) {
        const singleAsset = assets.filter(
          (item) => item.asset_master_id === assetUnqId
        )
        const singleAssetObj = !isEmpty(singleAsset[0]) ? singleAsset[0] : {}
        this.setState({
          assetUnqIdUrl: assetUnqId,
          singleAssetFromUrl: singleAssetObj,
          versionNumUrl: parseInt(assetVersionNum),
          pageNumUrl: parseInt(pageNum),
        })
      }
    }
  }

  beforeUnload = (e) => {
    e.preventDefault()
    return (e.returnValue = 'new thing')
  }

  refreshComments = () => {
    const { getCommentsByAssetIdAndRevision = () => {} } = this.props
    getCommentsByAssetIdAndRevision()
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight })
  }

  onShowHideAssets = (bool = true) => {
    this.setState({ showAssets: bool })
  }

  onToolSelect = (name = 'cursor') => {
    this.setState({ currentTool: name })
  }

  onChangeAngle = (action = '') => {
    const { angle } = this.state
    if (action === 'left') {
      if (angle > 0) {
        this.setState((state) => ({
          angle: state.angle - 1,
        }))
      } else {
        this.setState({
          angle: 3,
        })
      }
    } else if (action === 'right') {
      if (angle < 3) {
        this.setState((state) => ({
          angle: state.angle + 1,
        }))
      } else {
        this.setState({
          angle: 0,
        })
      }
    }
  }

  onChangeScale = (eventTargetValue) => {
    const {
      updateUserPreferences = () => {},
      view = '',
      userPreferences = {},
    } = this.props
    const { preferences = {} } = userPreferences
    const updatedPreferences = { ...preferences }
    updatedPreferences.view = view
    updatedPreferences.annotation_zoom = eventTargetValue
    updateUserPreferences({
      ...userPreferences,
      preferences: updatedPreferences,
    })
    // this.setState(() => {
    //   return { scale: eventTargetValue}
    // })
  }

  onChangeTaskStatus = (event) => {
    this.props.setTaskStatus(event.target.value)
    this.setState({ taskStatus: event.target.value })
  }

  onSubmitAnnotation = () => {
    if (this.state.currentTool !== 'cursor') {
      this.setState({ showAssets: false })
    }
  }

  getAnnotationsFromComments = (comments = []) => {
    const annotations = comments.reduce((results, comment) => {
      const annotation = getAnnotationFromComment(comment)
      if (!isEmpty(annotation)) {
        results.push(annotation)
      }
      return results
    }, [])
    this.setState({
      annotations,
    })
  }

  toggleHideAnnotation = (id = 0) => {
    const { hideAnnotationIds } = this.state
    if (hideAnnotationIds.includes(id)) {
      const newArray = hideAnnotationIds.filter((x) => x !== id)
      this.setState({ hideAnnotationIds: newArray })
    } else {
      this.setState((state) => ({
        hideAnnotationIds: [...state.hideAnnotationIds, id],
      }))
    }
  }

  toggleHideAllAnnotation = () => {
    const { hideAnnotationIds, annotations } = this.state
    if (hideAnnotationIds.length !== 0) {
      this.setState({ hideAnnotationIds: [] })
    } else {
      const allAnnotationIds = annotations.map((x) => x.data.id)
      this.setState({ hideAnnotationIds: allAnnotationIds })
    }
  }

  onChangePageNumber = (direction = '') => {
    const {
      setPrimaryPageNumber = () => {},
      primaryPageNumber = 1,
      primaryNoOfPages = 1,
    } = this.props
    if (direction === 'minus') {
      if (primaryPageNumber > 1) {
        return setPrimaryPageNumber(primaryPageNumber - 1)
      }
    } else if (direction === 'plus') {
      if (primaryPageNumber < primaryNoOfPages) {
        return setPrimaryPageNumber(primaryPageNumber + 1)
      }
    }
  }

  onArrowKeyPress = (e) => {
    const event = e || window.event
    const leftArrow = 37
    const rightArrow = 39
    const canNavigate =
      (event.target || {}).type !== 'text' &&
      (event.target || {}).type !== 'textarea'
    if (canNavigate) {
      if (event.keyCode === leftArrow) {
        return this.onChangePageNumber('minus')
      }
      if (event.keyCode === rightArrow) {
        return this.onChangePageNumber('plus')
      }
    }
  }

  getImageControlHandlers = (handlersList = {}) => {
    this.setState({
      zoomAnnotatorProps: Object.assign({}, handlersList),
    })
  }

  render() {
    const {
      showAssets,
      showViewedBy,
      currentTool,
      scale = DEFAULT_SCALE,
      width,
      height,
      annotations,
      hideAnnotationIds,
      angle,
      taskStatus = TASK_STATUS_NEW,
      zoomAnnotatorProps = {},
    } = this.state
    const {
      classes = {},
      assets = [],
      history = {},
      router = {},
      primaryAsset = {},
      match = {},
      toggleComparisonView = () => {},
      isActiveReview = false,
      isProjectAdmin = false,
      isReviewer = false,
      isFetching = false,
      primaryNoOfPages = 1,
      primaryPageNumber = 1,
    } = this.props
    const {
      resetTransform = () => {},
      zoomIn = () => {},
      zoomOut = () => {},
    } = zoomAnnotatorProps
    const { params = {} } = router
    const filteredAnnotations = annotations.filter(
      (x) => !hideAnnotationIds.includes(idx(x, (_) => _.data.id) || '')
    )
    const numberOfMarkups = getHighestIdNumber(annotations)
    let tempScale = scale === DEFAULT_SCALE ? DEFAULT_SCALE : scale
    return isFetching ? (
      <CircularProgress size={24} className={classes.cacheProgress} />
    ) : (
      <div style={{ display: 'flex' }}>
        <Grid
          container
          spacing={2}
          className={classes.homePage}
          style={{ width: width - 383, height: height - 114 }}
        >
          <Toolbar
            onChangeAngle={this.onChangeAngle}
            onChangeScale={this.onChangeScale}
            scale={scale}
            toggleHideAllAnnotation={this.toggleHideAllAnnotation}
            hideAnnotationIds={hideAnnotationIds}
            toggleSplitView={() => toggleComparisonView(true)}
            backToTasksPage={() =>
              router.navigate(`/project/${params.projectId}/tasks/list`)
            }
            taskStatus={taskStatus}
            onChangeTaskStatus={this.onChangeTaskStatus}
          />
          <div className={classes.zoomTextDiv} ref={this.zoomPerRef}>
            zoom text
          </div>
          <div className={classes.zoomControlsDiv}>
            <Tooltip title="ZoomIn" placement="right">
              <IconButton
                className={classes.zoomControls}
                onClick={(e) => zoomIn(e)}
                size="large"
              >
                <ZoomIn fontSize="medium" />
              </IconButton>
            </Tooltip>
            <Tooltip title="ZoomOut" placement="right">
              <IconButton
                className={classes.zoomControls}
                onClick={(e) => zoomOut(e)}
                size="large"
              >
                <ZoomOut fontSize="medium" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Reset" placement="right">
              <IconButton
                className={classes.zoomControls}
                onClick={() => resetTransform()}
                size="large"
              >
                <RotateLeft fontSize="medium" />
              </IconButton>
            </Tooltip>
          </div>
          <Grid
            item
            xs={12}
            className={classes.body}
            style={{
              height: height - 114,
              justifyContent: 'center',
            }}
          >
            {primaryNoOfPages > 1 && (
              <React.Fragment>
                {primaryPageNumber > 1 && (
                  <IconButton
                    className={classes.arrowButton}
                    style={{ left: '80px' }}
                    onClick={() => this.onChangePageNumber('minus')}
                    size="large"
                  >
                    <ChevronLeft />
                  </IconButton>
                )}
                {primaryPageNumber !== primaryNoOfPages && (
                  <IconButton
                    className={classes.arrowButton}
                    style={{ right: '340px' }}
                    onClick={() => this.onChangePageNumber('plus')}
                    size="large"
                  >
                    <ChevronRight />
                  </IconButton>
                )}
              </React.Fragment>
            )}
            <ImageAnnotatorNew
              onSubmitAnnotation={this.onSubmitAnnotation}
              annotations={filteredAnnotations}
              currentTool={currentTool}
              numberOfMarkups={numberOfMarkups}
              angle={angles[angle]}
              isSelected
              containerHeight={height - 114}
              containerWidth={width - 320}
              ref={this.zoomPerRef}
              getImageControlHandlers={this.getImageControlHandlers}
            />
            {(isProjectAdmin || (isReviewer && isActiveReview)) && (
              <ToolSelector
                currentTool={currentTool}
                onToolSelect={this.onToolSelect}
              />
            )}
          </Grid>
        </Grid>
        <div className={classes.sidebar} style={{ height: height - 114 }}>
          <Button
            data-cy="assetsTabButton"
            className={classes.sideButton}
            onClick={() => this.onShowHideAssets(true)}
            style={{
              borderBottom: showAssets
                ? '3px solid ' + praxis.themePrimary
                : null,
            }}
          >
            <div className={classes.innerButton}>
              <InsertPhoto /> Assets
            </div>
          </Button>
          <Button
            data-cy="commentsTabButton"
            className={classes.sideButton}
            onClick={() => this.onShowHideAssets(false)}
            style={{
              borderBottom: !showAssets
                ? '3px solid ' + praxis.themePrimary
                : null,
            }}
          >
            <div className={classes.innerButton}>
              <Comment /> Comments
            </div>
          </Button>
          <div className={classes.content}>
            <div style={{ display: showAssets ? 'block' : 'none' }}>
              <AssetsSidebar
                assets={assets}
                refDict={this.assetRefDictionary}
                history={history}
                match={match}
                params={params}
                assetUnqIdUrl={this.state.assetUnqIdUrl}
                removeAssetUnqIdUrl={this.removeAssetUnqIdUrl}
                router={router}
              />
            </div>

            {!showAssets && !showViewedBy && (
              <CommentSidebar
                currentAsset={primaryAsset}
                onToolSelect={this.onToolSelect}
                toggleShowViewedBy={() => {
                  this.setState({ showViewedBy: true })
                }}
                numberOfMarkups={numberOfMarkups}
                toggleHideAnnotation={this.toggleHideAnnotation}
                hideAnnotationIds={hideAnnotationIds}
                isProjectAdmin={isProjectAdmin}
                isActiveReview={isActiveReview}
                isReviewer={isReviewer}
                isSelected
              />
            )}
            {!showAssets && showViewedBy && (
              <ViewedBySidebar
                onToggleShowViewed={() => {
                  this.setState({ showViewedBy: false })
                }}
                onUnmount={() => {
                  this.setState({ showViewedBy: false })
                }}
              />
            )}
          </div>
        </div>
        <RouteLeavingGuard history={history} router={router} />
      </div>
    )
  }
}

SingleAssetContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  assets: PropTypes.array,
  getTaskAssets: PropTypes.func,
  comments: PropTypes.array,
  getCommentsByAssetIdAndRevision: PropTypes.func,
  updateUserPreferences: PropTypes.func,
  isActiveReview: PropTypes.bool,
  isProjectAdmin: PropTypes.bool,
  isReviewer: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  primaryAsset: assetPropType,
  setPrimaryAsset: PropTypes.func,
  toggleComparisonView: PropTypes.func,
  isFetching: PropTypes.bool,
  setPrimaryPageNumber: PropTypes.func,
  primaryPageNumber: PropTypes.number,
  primaryNoOfPages: PropTypes.number,
  primaryVersion: PropTypes.object,
  setTaskStatus: PropTypes.func,
  taskStatus: PropTypes.number,
  fromComparisonToSingle: PropTypes.bool,
  fetchAssetVersions: PropTypes.func,
}

const mapStateToProps = (state = {}) => {
  const { annotation = {}, userPreferences = {} } = state
  const {
    assets,
    comments,
    isFetching,
    primaryPageNumber,
    primaryVersion,
    isComparisonView,
    fromComparisonToSingle,
  } = annotation
  const isActiveReview = selectIsActiveReview()(state)
  const isProjectAdmin = selectIsCurrentTaskRole(TaskRoles.admin)(state)
  const isReviewer = selectIsCurrentTaskRole(TaskRoles.reviewer)(state)
  const primaryAsset = selectAssetVersion()(state)
  const primaryNoOfPages = primaryAsset.page_count || 1
  const taskStatus = selectTaskStatus()(state)
  const { preferences = {} } = userPreferences
  const { view = '', annotation_zoom = 'fit-to-screen' } = preferences
  return {
    assets,
    comments,
    primaryAsset,
    isActiveReview,
    isProjectAdmin,
    isReviewer,
    isFetching,
    primaryPageNumber,
    primaryNoOfPages,
    primaryVersion,
    isComparisonView,
    fromComparisonToSingle,
    taskStatus,
    userPreferences,
    view,
    annotation_zoom,
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getTaskAssets,
      getCommentsByAssetIdAndRevision,
      setPrimaryAsset,
      toggleComparisonView,
      setPrimaryPageNumber,
      fetchAssetVersions,
      setTaskStatus,
      updateUserPreferences,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(SingleAssetContainer)))
