import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import Fab from '@mui/material/Fab'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import PeopleIcon from '@mui/icons-material/People'
import TableSortLabel from '@mui/material/TableSortLabel'

const styles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: useTheme().spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 400,
    position: 'relative',
  },
  fab: {
    backgroundColor: '#eeeeee',
    color: '#6d6d6d',
    boxShadow: 'none',
    height: 38,
    width: 38,
    marginRight: 10,
  },
  participantIcon: {
    marginRight: 10,
    height: 38,
    width: 38,
    borderRadius: 40,
    position: 'relative',
  },
  rowImage: {
    minWidth: 60,
    height: 65,
  },
  name: {
    marginTop: 20,
    marginRight: 5,
  },
  space: {
    marginRight: 5,
  },
}))

class UserListTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      orderBy: 'first_name',
      order: 'asc',
    }
  }

  desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }

  getSorting() {
    return this.state.order === 'desc'
      ? (a, b) => this.desc(a, b, this.state.orderBy)
      : (a, b) => -this.desc(a, b, this.state.orderBy)
  }

  handleRequestSort(property) {
    const orderBy = property
    let order = 'desc'

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc'
    }

    this.setState({ order, orderBy })
  }

  render() {
    const { classes, userList } = this.props
    const { order, orderBy } = this.state
    return (
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'first_name'}
                direction={order}
                onClick={() => this.handleRequestSort('first_name')}
              >
                Name
              </TableSortLabel>
            </TableCell>
            <TableCell style={{ textAlign: 'right' }}>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {userList &&
            userList.map((participant, index) => (
              <TableRow key={index}>
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.rowImage}
                >
                  <Fab className={classes.fab}>
                    <PeopleIcon className={this.props.classes.IconGey} />
                  </Fab>
                  <span className={classes.name}>{participant.name}</span>
                  {participant.showCnt && (
                    <span>
                      <span className={classes.space}>|</span>
                      {participant.count} people
                    </span>
                  )}
                </TableCell>
                <TableCell align="right">
                  <IconButton
                    className={classes.button}
                    aria-label="Delete"
                    onClick={() => {
                      this.props.onDelete(participant)
                    }}
                    size="large"
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    )
  }
}

UserListTable.propTypes = {
  classes: PropTypes.object.isRequired,
  onDelete: PropTypes.func,
  userList: PropTypes.arrayOf(
    PropTypes.shape({
      count: PropTypes.number,
      name: PropTypes.string,
      showCnt: PropTypes.bool,
    })
  ),
}

const MyComponent = (props) => {
  const classes = styles()
  return <UserListTable {...props} classes={classes} />
}

export default MyComponent
