import {
  FETCH_USER_PREFERENCES_FULFILLED,
  UPDATE_USER_PREFERENCES_FULFILLED,
  SET_USER_PREFERENCES,
  UPDATE_USER_PENDING,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
} from './actionType'

export const initialState = {
  preferences: {
    view: 'list',
    annotation_zoom: 'fit-to-screen',
  },
  userUpdateSuccess: false,
  userUpdatepending: false,
  colors: [],
}

export default function preferencesReducer(state = initialState, action = {}) {
  const { type, payload = {} } = action
  const { data = {} } = payload
  switch (type) {
    case FETCH_USER_PREFERENCES_FULFILLED:
      return {
        ...state,
        ...data,
      }

    case UPDATE_USER_PREFERENCES_FULFILLED:
      return {
        ...state,
        ...data,
      }
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        userUpdateSuccess: action.payload.status,
      }
    case UPDATE_USER_FAILURE:
      return {
        ...state,
        userUpdateSuccess: false,
      }
    case UPDATE_USER_PENDING:
      return {
        ...state,
        userUpdatepending: action.payload.pending,
      }

    default:
      return state
  }
}
