import React, { useState } from 'react'
import InputAdornment from '@mui/material/InputAdornment'
import Tooltip from '@mui/material/Tooltip'
import SearchIcon from '@mui/icons-material/Search'
import Clear from '@mui/icons-material/Clear'
import TextField from '@mui/material/TextField'
import { makeStyles } from '@mui/styles'
import IconButton from '@mui/material/IconButton'

const useStyles = makeStyles((theme) => ({
  searchField: {
    padding: '5px',
  },
}))

function CustomTableSearch() {
  const [searchVal, setSearchVal] = useState('')
  const classes = useStyles()
  const handleSearchClick = () => {}

  return (
    <div>
      <TextField
        id="table_search_input"
        name="table_search_input"
        placeholder="Search"
        rows="1"
        value={searchVal}
        onChange={(e) => {
          setSearchVal(e.target.value)
        }}
        variant="outlined"
        size={'small'}
        InputProps={{
          endAdornment:
            searchVal === '' ? (
              <InputAdornment position="start">
                <SearchIcon
                  onClick={() => {
                    handleSearchClick()
                  }}
                />
              </InputAdornment>
            ) : (
              <Tooltip title="Clear Filter">
                <IconButton
                  onClick={() => {
                    setSearchVal('')
                  }}
                  style={{ padding: 0 }}
                  size="large"
                >
                  <Clear />
                </IconButton>
              </Tooltip>
            ),
        }}
        className={classes.searchField}
      />
    </div>
  )
}

export default CustomTableSearch
