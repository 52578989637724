import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MenuItem from '@mui/material/MenuItem'
import { Button, Grid, Menu } from '@mui/material'
import { makeStyles } from '@mui/styles'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { saveTemplateId } from '../../../store/search/searchSlice'
import { BetaAnalytics } from '../../../../analytics/betaSearch'
import { useSearchContext } from '../SearchContext'

const useStyles = makeStyles({
  templateButton: {
    padding: '5px',
    backgroundColor: '#fff',
  },
  buttonLabel: {
    fontFamily: 'Helvetica',
    fontWeight: '400',
    textTransform: 'none',
  },
  displayIcon: {
    marginLeft: '0px',
  },
  focusedItem: {
    '&:hover': {
      backgroundColor: '#115E6C',
      color: '#fff',
    },
  },
  countDiv: {
    background: '#666666',
    color: '#fff',
    borderRadius: '10px',
    padding: '0px 4px',
    marginLeft: '2px',
  },
})

function ReviewTemplateMenu() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const { trackAnalyticsData = () => {} } = useSearchContext()
  const { reviewTemplates = {} } = useSelector((state) => state?.newSearch)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const onSelectTemplate = (templateId = '') => {
    dispatch(saveTemplateId(templateId))
    handleClose()
    trackAnalyticsData({
      eventType: BetaAnalytics.event.ADD_FILTER,
      eventName: BetaAnalytics.event.TEMPLATE,
      eventData: {
        key: 'TEMPLATE_ID',
        value: templateId,
      },
    })
  }
  return (
    <Grid>
      <Button
        type="secondary"
        variant="outlined"
        className={classes.templateButton}
        endIcon={<KeyboardArrowDownIcon />}
        classes={{
          endIcon: classes.displayIcon,
        }}
        onClick={handleClick}
      >
        <span className={classes.buttonLabel}>Review Templates</span>{' '}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {(Object.keys(reviewTemplates) || []).map((key) => (
          <MenuItem
            data-cy="librarianPanelMenuItem"
            onClick={() => onSelectTemplate(key)}
            divider={true}
            classes={{ root: classes.focusedItem }}
          >
            {reviewTemplates[key]}
          </MenuItem>
        ))}
      </Menu>
    </Grid>
  )
}

export default ReviewTemplateMenu
