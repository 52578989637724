import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withStyles } from '@mui/styles'
import { Grid, Button } from '@mui/material'
import { GetApp, ArrowBack, ChevronLeft } from '@mui/icons-material'

import { getCurrentAssetInfo } from '../../../../store/publish/selectors'
import { closeModal } from '../../../../store/publish/actions'
import { downloadAsset } from '../../../../helpers/publishHelper'

const styles = {
  imageBoxHeader: {
    height: '50px',
    backgroundColor: '#212121',
  },
  rightSideContent: {
    display: 'inline',
    position: 'absolute',
    right: '20px',
  },
  downloadImage: {
    textDecoration: 'none',
  },
  buttonStyle: {
    color: '#FFFFFF',
  },
  buttonTextStyle: {
    paddingLeft: '10px',
    textTransform: 'uppercase',
  },
  buttonExpandStyle: {
    color: '#FFFFFF',
    padding: '4px',
  },
  buttonExpandTextStyle: {
    marginLeft: '-5px',
  },
  buttonExpandIcon: {
    fontSize: '36px',
  },
}

const ImageContainerHeader = ({
  classes = {},
  closeModal = () => {},
  asset = {},
  openDrawerHandler = () => {},
  isSidebarOpen = false,
  history = {},
  router = {},
}) => {
  const { publishers = [], preview_url = '', asset_id = '' } = asset
  const lastPublisher = Object.assign({}, publishers[publishers.length - 1])
  const { file_name = '', file_extension = 'jpg' } = lastPublisher
  const onDownloadClick = (e) => {
    e.preventDefault()
    downloadAsset(file_name, preview_url, file_extension)
  }

  const goBack = () => {
    closeModal()
    router.navigate('/publishing', { replace: true })
  }

  return (
    <Grid item xs={12} className={classes.imageBoxHeader}>
      <Button className={classes.buttonStyle} onClick={goBack}>
        <ArrowBack />
        <span className={classes.buttonTextStyle}>BACK TO SEARCH</span>
      </Button>
      <div className={classes.rightSideContent}>
        {preview_url && (
          <a
            className={classes.downloadImage}
            id={asset_id}
            href={preview_url}
            title={file_name}
          >
            <Button className={classes.buttonStyle} onClick={onDownloadClick}>
              <GetApp />
              <span className={classes.buttonTextStyle}>DOWNLOAD</span>
            </Button>
          </a>
        )}
        {!isSidebarOpen && (
          <Button
            className={classes.buttonExpandStyle}
            onClick={() => openDrawerHandler()}
          >
            <ChevronLeft className={classes.buttonExpandIcon} />
            <span className={classes.buttonExpandTextStyle}>SHOW METADATA</span>
          </Button>
        )}
      </div>
    </Grid>
  )
}

ImageContainerHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  asset: PropTypes.object.isRequired,
  openDrawerHandler: PropTypes.func.isRequired,
  isSidebarOpen: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  asset: getCurrentAssetInfo(state),
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      closeModal,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ImageContainerHeader))
