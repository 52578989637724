import { createStore, applyMiddleware } from 'redux'
import { configureStore } from '@reduxjs/toolkit'
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import reduxThunk from 'redux-thunk'
import { createPromise } from 'redux-promise-middleware'
import createDebounce from 'redux-debounced'

import rootReducer, { staticReducers } from './index'

export const middleware = [reduxThunk, createPromise(), createDebounce()]

if (process.env.NODE_ENV !== 'production') {
  middleware.push(reduxImmutableStateInvariant())
}

const composeEnhancers = composeWithDevTools({ trace: true })

//Commenting for now. will remove after testing
// const configureStore = () => {
// const store = createStore(
//   rootReducer(),
//   composeEnhancers(applyMiddleware(...middleware)),
// )

// return store
// }

const store = configureStore({
  reducer: staticReducers,
  middleware: middleware, //add middlewares other than default(redux thunk) Middleware
  preloadedState: {},
  devTools: process.env.NODE_ENV !== 'production',
})
store.asyncReducers = {}
store.injectAsyncReducer = (key, reducer, selfStore) => {
  store.asyncReducers[key] = reducer
  store.replaceReducer(rootReducer(store.asyncReducers))
}

export default store
