import defaultFileType from '../images/filetypes/image-logo.svg?url'
import pdfFileType from '../images/filetypes/acrobat-logo.svg?url'
import psdFileType from '../images/filetypes/photoshop-logo.svg?url'
import inFileType from '../images/filetypes/indesign-logo.svg?url'
import aiFileType from '../images/filetypes/illustrator-logo.svg?url'
import videoFileType from '../images/filetypes/video_file.svg?url'

export const Mimetypes = {
  default: defaultFileType,
  'image/jpeg': defaultFileType,
  'image/tiff': defaultFileType,
  'image/png': defaultFileType,
  'application/pdf': pdfFileType,
  'application/photoshop': psdFileType,
  'image/vnd.adobe.photoshop': psdFileType,
  'application/vnd.adobe.photoshop': psdFileType,
  'application/vnd.adobe-indesign': inFileType,
  'application/x-indesign': inFileType,
  'application/postscript': aiFileType,
  'application/illustrator': aiFileType,
  'application/vnd.adobe.illustrator': aiFileType,
  'video/mp4': videoFileType,
}

export const FileExtensions = [
  'All',
  'png',
  'jpeg',
  'jpg',
  'xlsx',
  'psd',
  'pdf',
  'tif',
  'ai',
  'indd',
]
