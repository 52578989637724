import { makeStyles } from '@mui/styles'
import { Checkbox, FormControlLabel } from '@mui/material'

const useStyles = makeStyles({
  root: {},
  checked: {},
})

const CheckboxItem = ({
  checkboxValue,
  checkboxLabel,
  checked,
  checkboxChangeCallback,
}) => {
  const classes = useStyles()
  const handleCheckboxChange = (event) => {
    checkboxChangeCallback(event.target.checked)
  }

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          onChange={handleCheckboxChange}
          value={checkboxValue}
          classes={{
            root: classes.root,
            checked: classes.checked,
          }}
          size="small"
        />
      }
      label={checkboxLabel}
    />
  )
}

export default CheckboxItem
