import { filter, map } from 'lodash'
import {
  REVIEW_TASK_TYPE_ID,
  UPLOAD_TASK_TYPE_ID,
} from '../../constants/projectTask'

const getUserDetails = (participants = []) => {
  let adminUsers = []
  let observerGroups = []
  let reviewerGroups = []
  let uploaderGroups = []
  let observers = []
  let reviewers = []
  let uploaders = []

  participants.forEach((participant = {}) => {
    const {
      email_address = '',
      group_id = '',
      first_name = '',
      last_name = '',
      login_id = '',
      role = '',
      user_id = '',
    } = participant
    const hasGroupId = group_id !== null && !!group_id
    let roleName = role !== null ? role.toLowerCase() : ''
    const isReviewer = roleName === 'reviewer'
    const isObserver = roleName === 'observer'
    const isUploader = roleName === 'uploader'
    if (roleName === 'admin') {
      adminUsers.push({
        first_name,
        last_name,
        login_id,
        role_id: 40,
        role_name: 'Admin',
        user_id,
        user_email_address: email_address,
      })
    } else if (hasGroupId && isReviewer) {
      reviewerGroups.push({ id: group_id })
    } else if (hasGroupId && isObserver) {
      observerGroups.push({ id: group_id })
    } else if (hasGroupId && isUploader) {
      uploaderGroups.push({ id: group_id })
    } else if (isReviewer) {
      reviewers.push({
        first_name,
        last_name,
        login_id,
        role_id: 20,
        role_name: role,
        user_id,
        user_email_address: email_address,
      })
    } else if (isObserver) {
      observers.push({
        first_name,
        last_name,
        login_id,
        role_id: 10,
        role_name: role,
        user_id,
        user_email_address: email_address,
      })
    } else if (isUploader) {
      uploaders.push({
        first_name,
        last_name,
        login_id,
        role_id: 50,
        role_name: role,
        user_id,
        user_email_address: email_address,
      })
    }
  })
  return {
    adminUsers,
    observerGroups,
    observers,
    reviewerGroups,
    reviewers,
    uploaderGroups,
    uploaders,
  }
}

const makeTaskPayload = (project = {}, taskData = {}, taskId = '') => {
  const {
    assets = [],
    dueDate,
    name = '',
    notification_details = '',
    participants = [],
    remove_participants = '',
    task_type = REVIEW_TASK_TYPE_ID,
  } = taskData
  const mydate = new Date(dueDate)
  const isoDate = new Date(
    mydate.getTime() - mydate.getTimezoneOffset() * 60000
  ).toISOString()
  const {
    folder_id = '',
    job_due_date,
    project_uuid = '',
    review_job_id = '',
    folder_name = '',
  } = project
  const {
    adminUsers = [],
    observerGroups = [],
    observers = [],
    reviewerGroups = [],
    reviewers = [],
    uploaderGroups = [],
    uploaders = [],
  } = getUserDetails(participants)
  const asset_details =
    task_type !== UPLOAD_TASK_TYPE_ID
      ? map(filter(assets, ['isChecked', true]), (asset = {}) => {
          const {
            asset_id = '',
            asset_content_type = '',
            asset_master_uoi_id = '',
            asset_uoi_id = '',
            asset_master_id = '',
          } = asset
          return {
            asset_id,
            asset_content_type,
            asset_uoid: asset_uoi_id,
            asset_master_uoid: asset_master_uoi_id,
            asset_master_id: asset_master_id,
          }
        })
      : []

  return {
    asset_details,
    email_body: '',
    email_subject: '',
    folder_id,
    folder_name,
    job_due_date,
    job_id: taskId,
    notes: '',
    notification_details,
    project_uuid,
    remove_participants,
    review_job_id,
    task_name: name,
    task_type,
    task_due_date: isoDate,
    user_details: {
      admins: {
        user: adminUsers,
      },
      observers: {
        group: observerGroups,
        user: observers,
      },
      reviewers: {
        group: reviewerGroups,
        user: reviewers,
      },
      uploaders: {
        group: uploaderGroups,
        user: uploaders,
      },
    },
  }
}

export { getUserDetails, makeTaskPayload }
