import idx from 'idx'
import {
  CLEAR_SEARCH_RESULTS,
  CONTENT_SEARCH_API_CALL_FULFILLED,
  CONTENT_SEARCH_API_CALL_PENDING,
  CONTENT_SEARCH_API_CALL_REJECTED,
  DELETE_ASSETS_INPROGRESS,
  DOWNLOAD_ASSETS_INPROGRESS,
  FETCH_SEARCH_TEMPLATES_FULFILLED,
  FETCH_SEARCH_TEMPLATES_REJECTED,
  UPDATE_FACET_FILTER,
  UPDATE_SEARCH_TERM,
  FETCH_TYPEAHEAD_SUGGESTIONS_FULFILLED,
  FETCH_TYPEAHEAD_SUGGESTIONS_REJECTED,
  FETCH_TYPEAHEAD_SUGGESTIONS_PENDING,
  CLEAR_TYPEAHEAD_SUGGESTIONS,
  SET_SORT_FIELDS,
  UPDATE_DOWNLOAD_ASSETS,
  SET_TEMPLATE_ID,
  BOARDS_CONTENT_SEARCH_API_CALL_FULFILLED,
  BOARDS_CONTENT_SEARCH_API_CALL_PENDING,
  BOARDS_CONTENT_SEARCH_API_CALL_REJECTED,
  FETCH_METADATA_FOR_ADMIN_INITIATED,
  FETCH_METADATA_FOR_ADMIN_FULFILLED,
  FETCH_METADATA_FOR_ADMIN_REJECTED,
  FETCH_AC_SUGGESTIONS_INITIATED,
  SET_CURRENT_PAGE_SIZE,
  SET_SEARCH_IMAGE_PREVIEW,
} from './actionTypes'
import { getModifiedFacets, formatSearchResults } from './helpers'
import { DEFAULT_COLORS } from '../../constants/search'

export const initialState = {
  appliedFilters: {},
  assetResults: {},
  deleteAssetInProgress: false,
  downloadAssets: {},
  downloadInProgress: false,
  errors: {},
  isFetching: false,
  searchTerm: '',
  searchTemplates: {},
  suggestions: {},
  typeaheadFetching: false,
  templateId: '',
  boardsAssetMetadata: {},
  isMetadataDownloadInitiated: false,
  searchImagePreview: '',
  pageSize: 21,
}

export default function assetsSearchReducer(state = initialState, action = {}) {
  const { payload = {} } = action
  switch (action.type) {
    case CONTENT_SEARCH_API_CALL_FULFILLED: {
      const prevFacets = state.globalFacets || {}
      const { data: payloadData = {} } = payload
      const { facets = {}, filters = {} } = payloadData
      const facetsWithCustomColor = Object.assign({}, facets, {
        'Selected Color': { '#': 'None' },
      })
      const facetsWithColor = Object.assign({}, facets, facetsWithCustomColor, {
        Color: DEFAULT_COLORS,
      })
      const payloadDataWithColor = Object.assign({}, payloadData, {
        facets: facetsWithColor,
      })
      const globalFacets = getModifiedFacets({
        appliedFilters: filters,
        newFacets: facetsWithColor,
        prevFacets,
      })
      return {
        ...state,
        assetResults: payloadDataWithColor,
        isFetching: false,
        globalFacets,
        appliedFilters: filters,
      }
    }
    case CONTENT_SEARCH_API_CALL_PENDING:
      return {
        ...state,
        isFetching: true,
      }
    case CONTENT_SEARCH_API_CALL_REJECTED: {
      const errors = idx(payload, (_) => _.response.data)
      return {
        ...state,
        errors,
        isFetching: false,
      }
    }
    case BOARDS_CONTENT_SEARCH_API_CALL_FULFILLED:
      return {
        ...state,
        boardsAssetMetadata: formatSearchResults(payload.data.search),
        isFetching: false,
      }
    case BOARDS_CONTENT_SEARCH_API_CALL_PENDING:
      return {
        ...state,
        isFetching: true,
      }
    case BOARDS_CONTENT_SEARCH_API_CALL_REJECTED: {
      const boardsMetadataErrors = idx(payload, (_) => _.response.data)
      return {
        ...state,
        errors: boardsMetadataErrors,
        isFetching: false,
      }
    }
    case FETCH_SEARCH_TEMPLATES_FULFILLED:
      return {
        ...state,
        searchTemplates: payload.data,
      }
    case FETCH_SEARCH_TEMPLATES_REJECTED: {
      const templateErrors = idx(payload, (_) => _.response.data)
      return {
        ...state,
        templateErrors,
      }
    }
    case CLEAR_SEARCH_RESULTS: {
      const { retainPartialState } = payload
      const { sortFields, searchTemplates, templateId } = state
      let existingState = {}
      if (retainPartialState) {
        existingState = {
          sortFields: sortFields,
          templateId: templateId,
        }
      }
      return {
        ...initialState,
        ...existingState,
        searchTemplates: searchTemplates,
      }
    }
    case UPDATE_FACET_FILTER:
      return {
        ...state,
        appliedFilters: payload.appliedFilters,
      }
    case UPDATE_SEARCH_TERM:
      return {
        ...state,
        searchTerm: payload.searchTerm,
      }
    case FETCH_TYPEAHEAD_SUGGESTIONS_FULFILLED:
      return {
        ...state,
        suggestions: payload.data,
        typeaheadFetching: false,
      }
    case FETCH_TYPEAHEAD_SUGGESTIONS_PENDING:
      return {
        ...state,
        typeaheadFetching: true,
      }
    case FETCH_TYPEAHEAD_SUGGESTIONS_REJECTED: {
      const typeaheadErrors = idx(payload, (_) => _.response.data)
      return {
        ...state,
        typeaheadErrors,
        typeaheadFetching: false,
      }
    }
    case CLEAR_TYPEAHEAD_SUGGESTIONS:
      return {
        ...state,
        suggestions: initialState.suggestions,
      }
    case SET_SORT_FIELDS:
      return {
        ...state,
        sortFields: payload.sortFields,
      }
    case SET_CURRENT_PAGE_SIZE:
      return {
        ...state,
        pageSize: payload.pageSize,
      }
    case UPDATE_DOWNLOAD_ASSETS:
      //To avoid mutation
      return Object.assign(
        {},
        {
          ...state,
          downloadAssets: { ...payload.downloadAssets },
        }
      )
    case DOWNLOAD_ASSETS_INPROGRESS:
      //To avoid mutation
      return Object.assign(
        {},
        {
          ...state,
          downloadInProgress: payload.downloadInProgress,
          downloadAssets: {
            ...(payload.downloadInProgress ? state.downloadAssets : {}),
          },
        }
      )
    case DELETE_ASSETS_INPROGRESS:
      //To avoid mutation
      return Object.assign(
        {},
        {
          ...state,
          deleteAssetInProgress: payload.deleteAssetInProgress,
        }
      )
    case SET_TEMPLATE_ID:
      return {
        ...state,
        templateId: payload.templateId,
      }
    case SET_SEARCH_IMAGE_PREVIEW:
      return {
        ...state,
        searchImagePreview: payload.url,
      }
    case FETCH_METADATA_FOR_ADMIN_INITIATED:
      return {
        ...state,
        isMetadataDownloadInitiated: payload.isMetadataDownloadInitiated,
      }
    case FETCH_METADATA_FOR_ADMIN_FULFILLED:
    case FETCH_METADATA_FOR_ADMIN_REJECTED:
      return {
        ...state,
        isMetadataDownloadInitiated: payload.isMetadataDownloadInitiated,
        downloadAssets: {
          ...(payload.isMetadataDownloadInitiated ? state.downloadAssets : {}),
        },
      }
    case FETCH_AC_SUGGESTIONS_INITIATED:
      return {
        ...state,
        isFetchingACSuggestions: payload.isFetchingACSuggestions,
      }
    default:
      return state
  }
}
