import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Button, Drawer } from '@mui/material'
import { ChevronRight } from '@mui/icons-material'
import { withStyles } from '@mui/styles'

import { SIDEBAR_WIDTH } from '../../../../constants/publishConstants'
import {
  getCurrentAssetInfo,
  getIsFetchingAssets,
} from '../../../../store/publish/selectors'

import PublisherDetailCard from './PublisherDetailCard'

const styles = (theme) => ({
  publisherCards: {
    padding: '5px',
  },
  sidebar: {
    padding: '0 8px',
    backgroundColor: '#EFEFEF',
    width: SIDEBAR_WIDTH - 10,
  },
  sidebarHeader: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonStyle: {
    color: '#0D46A0',
    padding: '4px',
  },
  buttonTextStyle: {
    marginRight: '-5px',
  },
  buttonIcon: {
    color: '#484848',
    fontSize: '36px',
  },
})

const MetadataBar = ({
  classes = {},
  close = () => {},
  asset = {},
  isFetchingAssets = false,
}) => (
  <>
    {isFetchingAssets ? null : (
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open
        classes={{
          paper: classes.sidebar,
        }}
      >
        <div className={classes.sidebarHeader}>
          <Button className={classes.buttonStyle} onClick={() => close()}>
            <span className={classes.buttonTextStyle}>HIDE METADATA</span>
            <ChevronRight className={classes.buttonIcon} />
          </Button>
        </div>
        <div className={classes.publisherCards}>
          {asset?.publishers?.map((publisher, index) => (
            <PublisherDetailCard publisher={publisher} key={index} />
          ))}
        </div>
      </Drawer>
    )}
  </>
)

MetadataBar.propTypes = {
  classes: PropTypes.object,
  close: PropTypes.func,
  asset: PropTypes.object,
}

const mapStateToProps = (state) => ({
  asset: getCurrentAssetInfo(state),
  isFetchingAssets: getIsFetchingAssets(state),
})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(MetadataBar))
