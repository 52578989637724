import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { isEmpty } from 'lodash'
import { withStyles } from '@mui/styles'
import Grid from '@mui/material/Grid'
import { withEnv } from '@praxis/component-runtime-env'
import HeaderTitle from '../Header/HeaderTitle'
import { isFunctionalTestUser } from '../../helpers/UtilityHelper'
import AssetHubLogo from '../../images/AssetHubLogo-Red.svg?url'

const drawerWidth = 240

const styles = (theme) => ({
  homePage: {
    textAlign: 'left',
  },
  homePageIntro: {
    fontSize: 'large',
  },
  homeBanner: {
    width: '99%',
    marginLeft: 30,
    color: '#333',
  },
  card: {
    margin: 5,
    height: '100%',
  },
  cardHeader: {
    color: '#0D46A0',
    backgroundColor: 'rgba(239, 239, 239, 0.498039215686275)',
  },
  media: {
    width: 270,
    height: 270,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 16,
    color: '#0D46A0',
  },
  avatar: {
    backgroundColor: '#FFF',
  },
  avatarIcon: {
    color: '#0D46A0',
  },
  cardContent: {},
  cardLabel: {
    color: '#8D8D8D',
  },
  imgClass: {
    // content:'url("//target.scene7.com/is/image/Target/assethub-homepage-image?cropN=0,0.2,0,0.8&sizeN=2000,2000&qlt=100&wid=1615")',
    // width: '100%',
    height: '20vh',
    zoom: 3,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  blurb: {
    textAlign: 'center',
    paddingLeft: 75,
    paddingRight: 75,
  },
  content: {
    padding: '0px 24px 0px 24px',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: 'white',
    marginLeft: 70,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  grid: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  link: {
    textDecoration: 'none',
    color: 'black',
  },
  main: {
    flexGrow: 1,
    dislpay: 'flex',
  },
  root: {
    display: 'flex',
    zIndex: 0,
    position: 'relative',
  },
  sectionTitle: {
    marginTop: '15px',
  },
})

export const HomePage = ({ classes, headerTitle, env = {}, memberOf = [] }) => {
  const { REACT_APP_ROLES_CONFIG = {} } = env
  const { assethubADGroups = [] } = REACT_APP_ROLES_CONFIG
  const makeSelectIsValidAssetHubUser = () => {
    const validUserGroup = memberOf.find((userGroup) =>
      assethubADGroups.includes(userGroup)
    )
    return !isEmpty(validUserGroup) || !!isFunctionalTestUser()
  }
  const isAssetHubUser = makeSelectIsValidAssetHubUser()
  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <div>
          <HeaderTitle title="Home" icon="Home" />
          <Helmet>
            <title>{headerTitle}</title>
          </Helmet>
          {!isAssetHubUser ? (
            <div className={classes.homeBanner}>
              <h4>Looks like you don’t have access to AssetHub!</h4>
              <p>
                Would you like to invite other Team Members, contractors, or
                vendors to collaborate with you?
              </p>
              <h4>For Team Members and Contractors access:</h4>
              <p>
                1. Navigate to{' '}
                <a target="_blank" href="https://myaccess.target.com/">
                  myaccess.target.com
                </a>{' '}
                and request the APP-OAUTH2-ASSETHUB entitlement on their behalf.
                Once approved they will have access within the hour.
              </p>
              <h4>For Agencies (external users) access:</h4>
              <p>
                1. The company will need to be added to{' '}
                <a target="_blank" href="https://partnersonline.com/">
                  Partners Online
                </a>{' '}
                (POL) as a Marketing Partner or Merchant Partner. <br />
                2. A company admin will need to be chosen to manage users for
                their company in{' '}
                <a target="_blank" href="https://vmm.target.com/vmm">
                  Vendor Management and Maintenance
                </a>{' '}
                (VMM). <br />
                3. Each user will need to be added and set as active in VMM by
                the company admin. <br />
                4. The Target partner for the vendor will need to request
                AssetHub access for users on their behalf by reaching out to{' '}
                <a href="mailto: david.lahaye@target.com">
                  david.lahaye@target.com
                </a>
                . <br />
                5. Once approved the users will be able to launch AssetHub from
                the{' '}
                <a target="_blank" href="https://partnersonline.com/">
                  Partners Online
                </a>{' '}
                (POL) portal and begin using.
              </p>
            </div>
          ) : (
            <div className={classes.imgClass}>
              <img src={AssetHubLogo} alt="home" />
            </div>
          )}
        </div>
      </Grid>
    </Grid>
  )
}

HomePage.propTypes = {
  classes: PropTypes.object,
  headerTitle: PropTypes.string,
  isAssetHubUser: PropTypes.bool,
}

const mapStateToProps = (state) => {
  const { layout, auth = {} } = state
  const { headerTitle } = layout
  const { memberOf = [] } = auth
  return {
    headerTitle,
    memberOf,
  }
}

export default connect(mapStateToProps)(withEnv()(withStyles(styles)(HomePage)))
