import * as React from 'react'
import Markdown from 'markdown-to-jsx'
import { makeStyles } from '@mui/styles'
import { Chip } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Checkbox from '@mui/material/Checkbox'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import NoThumbnail from '../../images/NoThumbnail.svg?url'
import {
  AI_ASSET_STATUS_TO_REVIEW,
  AI_ASSET_STATUS_TO_REVIEW_LABEL,
  getSequenceNumFromAssetTestId,
} from '../helpers/genaiTestHelper'
import {
  ASSET_STATUS_FAILURE,
  ASSET_STATUS_SUCCESS,
  bulkReviewAssetTableCells,
} from '../constants/genai'

const useStyles = makeStyles({
  imageStyles: {
    width: '100px',
    height: '100px',
    objectFit: 'contain',
    border: '1px solid #5e959280',
    overflow: 'hidden',
  },
  imageCell: {
    maxWidth: '30px',
  },
  responseCell: {
    overflow: 'scroll',
    maxWidth: '500px',
    borderLeft: '1px solid #D6D6D6',
    borderRight: '1px solid #D6D6D6',
    padding: '10px',
  },
  statusCell: {
    paddingLeft: '10px',
  },
  notesCell: {
    maxWidth: '100px',
    overflow: 'scroll',
  },
  paddingNone: {
    padding: '0px',
  },
  warningMessage: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '5px',
  },
})

function EnhancedTableHead(props) {
  const { onSelectAllClick, numSelected, rowCount, parentClasses } = props
  return (
    <TableHead>
      <TableRow>
        <TableCell
          className={parentClasses.paddingNone}
          align="right"
          padding="none"
        >
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        {bulkReviewAssetTableCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.disablePadding ? 'left' : 'center'}
            className={headCell.disablePadding ? parentClasses.paddingNone : ''}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

function BulkReviewAssetTable({
  filteredAssetList = [],
  selectedAssets = [],
  setSelectedAssets = () => {},
}) {
  const classes = useStyles()

  const filterProcessedAssets = filteredAssetList.filter(
    (assetDetails) => assetDetails.asset_status === ASSET_STATUS_SUCCESS,
  )

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = filterProcessedAssets.map(
        (asset) => asset.asset_test_id,
      )
      setSelectedAssets(newSelected)
      return
    }
    setSelectedAssets([])
  }

  const handleClick = (event, id) => {
    const selectedIndex = selectedAssets.indexOf(id)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedAssets, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedAssets.slice(1))
    } else if (selectedIndex === selectedAssets.length - 1) {
      newSelected = newSelected.concat(selectedAssets.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedAssets.slice(0, selectedIndex),
        selectedAssets.slice(selectedIndex + 1),
      )
    }
    setSelectedAssets(newSelected)
  }

  const isSelected = (id) => selectedAssets.indexOf(id) !== -1

  const renderWarningMessage = (assetStatus, response) => (
    <div className={classes.warningMessage}>
      <WarningAmberIcon
        style={{
          color: assetStatus === ASSET_STATUS_FAILURE ? '#B6410E' : '#FF9900',
          paddingRight: '2px',
        }}
      />
      {response}
    </div>
  )

  return (
    <Paper sx={{ width: '100%', mb: 2 }}>
      <TableContainer sx={{ maxHeight: '80vh' }}>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={'medium'}
          stickyHeader
        >
          <EnhancedTableHead
            numSelected={selectedAssets.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={filterProcessedAssets.length}
            parentClasses={classes}
          />
          <TableBody>
            {(filteredAssetList || []).map((asset, index) => {
              const {
                asset_id = '',
                asset_test_id = '',
                asset_url = '',
                response = '',
                quality_status = '',
                review_note = '',
                asset_status = '',
              } = asset
              const isItemSelected = isSelected(asset_test_id)
              const labelId = `table-checkbox-${index}`
              const sequenceNum = getSequenceNumFromAssetTestId(asset_test_id)
              return (
                <TableRow
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={asset_test_id}
                  selected={isItemSelected}
                >
                  <TableCell
                    padding="checkbox"
                    onClick={(event) => handleClick(event, asset_test_id)}
                    sx={{ cursor: 'pointer' }}
                    align="right"
                    className={classes.paddingNone}
                  >
                    {asset_status === ASSET_STATUS_SUCCESS ? (
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    ) : (
                      ''
                    )}
                  </TableCell>
                  <TableCell className={classes.imageCell} id={labelId}>
                    <img
                      src={asset_url}
                      alt={asset_id}
                      className={classes.imageStyles}
                      onError={(e) => {
                        e.target.src = NoThumbnail
                      }}
                    />
                  </TableCell>
                  <TableCell className={classes.paddingNone}>
                    {sequenceNum}
                  </TableCell>
                  <TableCell className={classes.responseCell}>
                    {response && asset_status === ASSET_STATUS_SUCCESS ? (
                      <Markdown>{response}</Markdown>
                    ) : (
                      renderWarningMessage(asset_status, response)
                    )}
                  </TableCell>
                  <TableCell className={classes.statusCell}>
                    {
                      <Chip
                        label={
                          quality_status === AI_ASSET_STATUS_TO_REVIEW
                            ? AI_ASSET_STATUS_TO_REVIEW_LABEL
                            : quality_status
                        }
                        variant="outlined"
                        color={
                          quality_status === 'PASS'
                            ? 'success'
                            : quality_status === 'FAIL'
                              ? 'warning'
                              : 'info'
                        }
                      />
                    }
                  </TableCell>
                  <TableCell className={classes.notesCell}>
                    {review_note}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

export default BulkReviewAssetTable
