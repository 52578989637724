import { useState, useEffect } from 'react'
import {
  FormControl,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
} from '@mui/material'
import { getLowerCaseValue } from '../../utils/utils'
import CollapsibleCard from '../../components/Custom/CollapsibleCard'
import {
  ASSET_ADVANCE_REVIEW_COMPLETED,
  ASSET_BASIC_REVIEW_COMPLETED,
  ASSET_REVIEW_FLAGGED,
  ASSET_REVIEW_PENDING,
  METADATA_CARD_HIDE_BUTTON_TEXT,
  METADATA_CARD_SHOW_BUTTON_TEXT,
} from '../../constants/metadata'
import { useMetadataContext } from './MetadataContext'

export const LibrarianStatusCard = ({ containerClasses = {} }) => {
  const {
    setIsMetadataUnsaved = () => {},
    updateMetadata = () => {},
    assetMetadata = {},
    isEditMode = false,
  } = useMetadataContext()

  const { librarian_review_status = '' } = assetMetadata

  const [librarianReviewStatus, setLibrarianReviewStatus] = useState(
    getLowerCaseValue(librarian_review_status) ?? ASSET_REVIEW_PENDING,
  )

  useEffect(() => {
    if (
      getLowerCaseValue(librarianReviewStatus) !==
      getLowerCaseValue(librarian_review_status)
    ) {
      setIsMetadataUnsaved(true)
      updateMetadata('librarian_review_status', librarianReviewStatus)
    } else {
      setIsMetadataUnsaved(false)
    }
  }, [librarianReviewStatus])

  return (
    <CollapsibleCard
      cardTitle={'Librarian Review Status'}
      hideButtonText={METADATA_CARD_HIDE_BUTTON_TEXT}
      showButtonText={METADATA_CARD_SHOW_BUTTON_TEXT}
      defaultCollapse={true}
    >
      <div className={containerClasses.metadataWrapMedium}>
        <FormControl
          variant="standard"
          component="fieldset"
          className={containerClasses.formControl}
        >
          <RadioGroup
            aria-label="Librarian Review Status Option"
            name="librarain_review_status"
            className={containerClasses.group}
            value={librarianReviewStatus}
            onChange={(event) => setLibrarianReviewStatus(event.target.value)}
          >
            <FormControlLabel
              value={getLowerCaseValue(ASSET_REVIEW_PENDING)}
              control={
                <Radio
                  containerClasses={{
                    root: containerClasses.radio,
                    checked: containerClasses.checked,
                  }}
                />
              }
              label={ASSET_REVIEW_PENDING}
              disabled={!isEditMode}
            />
            <FormControlLabel
              value={getLowerCaseValue(ASSET_BASIC_REVIEW_COMPLETED)}
              control={
                <Radio
                  containerClasses={{
                    root: containerClasses.radio,
                    checked: containerClasses.checked,
                  }}
                />
              }
              label={ASSET_BASIC_REVIEW_COMPLETED}
              disabled={!isEditMode}
            />
            <FormControlLabel
              value={getLowerCaseValue(ASSET_ADVANCE_REVIEW_COMPLETED)}
              control={
                <Radio
                  containerClasses={{
                    root: containerClasses.radio,
                    checked: containerClasses.checked,
                  }}
                />
              }
              label={ASSET_ADVANCE_REVIEW_COMPLETED}
              disabled={!isEditMode}
            />
            <FormControlLabel
              value={getLowerCaseValue(ASSET_REVIEW_FLAGGED)}
              control={
                <Radio
                  containerClasses={{
                    root: containerClasses.radio,
                    checked: containerClasses.checked,
                  }}
                />
              }
              label={ASSET_REVIEW_FLAGGED}
              disabled={!isEditMode}
            />
          </RadioGroup>
        </FormControl>
      </div>
    </CollapsibleCard>
  )
}

export default LibrarianStatusCard
