export const FETCH_USER_PREFERENCES = 'FETCH_USER_PREFERENCES'
export const FETCH_USER_PREFERENCES_FULFILLED =
  'FETCH_USER_PREFERENCES_FULFILLED'
export const FETCH_USER_PREFERENCES_REJECTED = 'FETCH_USER_PREFERENCES_REJECTED'

export const UPDATE_USER_PREFERENCES = 'UPDATE_USER_PREFERENCES'
export const UPDATE_USER_PREFERENCES_FULFILLED =
  'UPDATE_USER_PREFERENCES_FULFILLED'
export const UPDATE_USER_PREFERENCES_REJECTED =
  'UPDATE_USER_PREFERENCES_REJECTED'

export const UPDATE_USER_PENDING = 'UPDATE_USER_PENDING'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE'
