import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Collapse from '@mui/material/Collapse'
import DeviceHubIcon from '@mui/icons-material/DeviceHub'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import ErrorIcon from '@mui/icons-material/Error'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import PublishIcon from '@mui/icons-material/Publish'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Slide from '@mui/material/Slide'
import withRouter from '../../containers/Router/WithRouter'
import {
  toggleProject,
  toggleUploadManager,
} from '../../store/uploads/actionCreator'
import fileTypeImage from '../../images/filetypes/image-logo.svg?url'
import { getProjectAssetDetails } from '../../store/projects/actionCreator'

const styles = makeStyles((theme) => ({
  uploadManager: {
    position: 'fixed',
    width: 550,
    bottom: 8,
    right: 1,
    boxShadow: '0 2px 5px 0 rgba(0,0,0,.26)',
    backgroundColor: useTheme().palette.background.paper,
    borderRadius: '5px',
    zIndex: '2',
  },
  uploadManagerHeading: {
    height: 60,
    backgroundColor: '#188295',
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    padding: '10px 20px',
    borderRadius: '5px 5px 0 0',
  },
  nested: {
    paddingLeft: useTheme().spacing(2),
    paddingTop: 9,
    paddingBottom: 9,
  },
  uploadManagerContent: {
    width: '100%',
    height: 325,
  },
  tabsRoot: {
    minHeight: 'auto',
  },
  tabRoot: {
    minWidth: 72,
    padding: '0 0 0 6px',
  },
  tabsIndicator: {
    backgroundColor: '#0D46A0',
  },
  tabWrapper: {
    flexDirection: 'row',
    color: '#0D46A0',
  },
  tabLabelIcon1: {
    minHeight: 40,
    '& svg': {
      fill: '#0D46A0',
      marginTop: 6,
      marginRight: 6,
    },
  },
  tabLabelIcon2: {
    minHeight: 40,
    '& svg': {
      fill: '#FFE57F',
      marginTop: 6,
      marginRight: 6,
    },
  },
  tabLabelIcon3: {
    minHeight: 40,
    '& svg': {
      fill: '#4CAF50',
      marginTop: 6,
      marginRight: 6,
    },
  },
  tabLabelIcon4: {
    minHeight: 40,
    '& svg': {
      fill: '#FF4F30',
      marginTop: 6,
      marginRight: 6,
    },
  },
  uploadHeadLine: {
    color: '#ffffff',
  },
  uploadIcon: {
    color: 'white',
  },
  listRoot: {
    backgroundColor: '#EFEFEF',
    paddingLeft: 10,
    paddingTop: 9,
    paddingBottom: 9,
  },
  listChildRoot: {
    paddingLeft: 10,
  },
  listBlue: {
    color: '#0D46A0',
    marginRight: 0,
  },
  listGrey: {
    color: '#484848',
    wordBreak: 'break-all',
  },
  iconButtonRoot: {
    width: 40,
    height: 40,
  },
  noFiles: {
    textAlign: 'center',
    paddingTop: 50,
  },
  fileList: {
    maxHeight: 285,
    overflowY: 'auto',
  },
  progress: {
    margin: '0 10px 0 16px',
  },
  uploadManagerHideBtn: {
    position: 'absolute',
    top: 23,
    right: 6,
    color: 'white',
  },
  hide: {
    display: 'none',
  },
}))

const MyComponent = (props) => {
  const classes = styles()
  return <Upload {...props} classes={classes} />
}

export class Upload extends React.Component {
  constructor() {
    super()
    this.state = {
      selectedTab: 0,
      open: false,
    }
    this.projectList = this.projectList.bind(this)
    this.fileList = this.fileList.bind(this)
    this.onExited = this.onExited.bind(this)
    this.onEnter = this.onEnter.bind(this)
  }

  toggleProject(projectUUID) {
    this.props.toggleProject(projectUUID)
  }

  toggleUploadManager() {
    this.props.toggleUploadManager()
  }

  tabChange = (event, selectedTab) => {
    this.setState({ selectedTab })
  }

  openProject(projectId) {
    let url = '/project/' + projectId + '/dashboard'
    this.props.router.navigate(url)
    this.props.getProjectAssetDetails(projectId)
  }

  fileList(progressFiles = {}, fileArrayList = []) {
    const { classes } = this.props
    Object.keys(progressFiles).forEach((fileName, index) => {
      let status
      const { uploadStatus, completedChunks, totalNumberOfChunks } =
        progressFiles[fileName]
      if (uploadStatus === 'Not Started') {
        status = <CircularProgress className={classes.progress} size={20} />
      } else if (uploadStatus === 'Started') {
        let percentage = (completedChunks / totalNumberOfChunks) * 100
        status = (
          <CircularProgress
            className={classes.progress}
            size={20}
            variant="determinate"
            value={percentage}
          />
        )
      } else if (uploadStatus === 'Success') {
        status = (
          <CheckCircleIcon style={{ color: '#4CAF50', marginRight: 8 }} />
        )
      } else if (uploadStatus === 'Failed') {
        status = <ErrorIcon style={{ color: '#FF4F30', marginRight: 8 }} />
      }
      const listItemKey = `${uploadStatus}-${index}`
      fileArrayList.push(
        <ListItem className={classes.nested} key={listItemKey}>
          <ListItemIcon style={{ minWidth: 36 }}>
            <img
              alt={'file type'}
              src={fileTypeImage}
              style={{ maxWidth: 22, marginRight: 0 }}
            />
          </ListItemIcon>
          <ListItemText
            primary={fileName}
            classes={{
              primary: classes.listGrey,
            }}
          />
          <ListItemSecondaryAction>{status}</ListItemSecondaryAction>
        </ListItem>
      )
    })
  }

  onExited() {
    this.setState({ open: false })
  }

  onEnter() {
    this.setState({ open: true })
  }

  projectList() {
    const { uploads = {}, classes } = this.props
    const { projects: uploadsProjects = {} } = uploads
    let projectList = []
    Object.keys(uploadsProjects).forEach((projectUUID) => {
      let collapseHead = (
        <ListItem
          button
          className={classes.listRoot}
          onClick={() => this.openProject(projectUUID)}
          key={projectUUID}
        >
          <ListItemIcon style={{ minWidth: 36 }}>
            <DeviceHubIcon className={classes.listBlue} />
          </ListItemIcon>
          <ListItemText
            primary={uploads?.projects[projectUUID].project_name}
            classes={{
              primary: classes.listBlue,
            }}
          />
          <ListItemSecondaryAction>
            <IconButton
              aria-label="Comments"
              classes={{ root: classes.iconButtonRoot }}
              onClick={() => {
                this.toggleProject(projectUUID)
              }}
              size="large"
            >
              {uploads?.projects[projectUUID].isExpanded ? (
                <ExpandLess />
              ) : (
                <ExpandMore />
              )}
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      )
      let fileArrayList = []
      if (this.state.selectedTab === 0) {
        this.fileList(
          uploads?.projects[projectUUID]['initialFiles'],
          fileArrayList
        )
      }
      if (this.state.selectedTab === 0 || this.state.selectedTab === 1) {
        this.fileList(
          uploads?.projects[projectUUID]['uploadingFiles'],
          fileArrayList
        )
      }
      if (this.state.selectedTab === 0 || this.state.selectedTab === 2) {
        this.fileList(
          uploads?.projects[projectUUID]['successFiles'],
          fileArrayList
        )
      }
      if (this.state.selectedTab === 0 || this.state.selectedTab === 3) {
        this.fileList(
          uploads?.projects[projectUUID]['failedFiles'],
          fileArrayList
        )
      }
      if (fileArrayList.length) {
        projectList.push(collapseHead)
        projectList.push(
          <Collapse
            in={uploads?.projects[projectUUID].isExpanded}
            timeout="auto"
            unmountOnExit
          >
            <List
              component="div"
              disablePadding
              className={classes.listChildRoot}
            >
              {fileArrayList}
            </List>
          </Collapse>
        )
      }
    })
    if (projectList.length) {
      return <List disablePadding> {projectList} </List>
    } else {
      return <div className={classes.noFiles}> No Files! </div>
    }
  }

  render() {
    const { classes, uploads = {} } = this.props
    return (
      <Slide
        direction="down"
        in={uploads?.showUploadManager}
        onExited={this.onExited}
        onEnter={this.onEnter}
      >
        <div
          className={
            classes.uploadManager + ' ' + (!this.state.open ? classes.hide : '')
          }
        >
          <div className={classes.uploadManagerHeading}>
            <Grid
              container
              wrap="nowrap"
              spacing={2}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <Grid item>
                <CloudUploadIcon style={{ fontSize: 40 }} />
              </Grid>
              <Grid item xs zeroMinWidth>
                <Typography
                  variant="h5"
                  classes={{ h5: classes.uploadHeadLine }}
                >
                  Upload Manager
                </Typography>
                <Button
                  data-cy="hideUploadButton"
                  className={classes.uploadManagerHideBtn}
                  onClick={() => {
                    this.toggleUploadManager()
                  }}
                >
                  hide
                </Button>
              </Grid>
            </Grid>
          </div>
          <div className={classes.uploadManagerContent}>
            <Tabs
              value={this.state.selectedTab}
              onChange={this.tabChange}
              indicatorColor="primary"
              textColor="primary"
              fullwidth
              classes={{
                root: classes.tabsRoot,
                indicator: classes.tabsIndicator,
              }}
            >
              <Tab
                label="All"
                classes={{
                  root: classes.tabRoot,
                  wrapper: classes.tabWrapper,
                  labelIcon: classes.tabLabelIcon1,
                }}
                icon={<RemoveRedEyeIcon />}
              />
              <Tab
                label={uploads?.uploadingFilesCount + ' Uploading'}
                classes={{
                  root: classes.tabRoot,
                  wrapper: classes.tabWrapper,
                  labelIcon: classes.tabLabelIcon2,
                }}
                icon={<PublishIcon />}
              />
              <Tab
                label={uploads?.completedFilesCount + ' Added to Project'}
                classes={{
                  root: classes.tabRoot,
                  wrapper: classes.tabWrapper,
                  labelIcon: classes.tabLabelIcon3,
                }}
                icon={<CheckCircleIcon />}
              />
              <Tab
                label={uploads?.failedFilesCount + ' Failures'}
                classes={{
                  root: classes.tabRoot,
                  wrapper: classes.tabWrapper,
                  labelIcon: classes.tabLabelIcon4,
                }}
                icon={<ErrorIcon />}
              />
            </Tabs>
            <div className={classes.fileList}>{this.projectList()}</div>
          </div>
        </div>
      </Slide>
    )
  }
}

Upload.contextTypes = {
  router: PropTypes.object,
}

Upload.propTypes = {
  classes: PropTypes.object.isRequired,
  toggleProject: PropTypes.func,
  toggleUploadManager: PropTypes.func,
  getProjectAssetDetails: PropTypes.func,
  uploads: PropTypes.object,
  lanId: PropTypes.string,
}

const mapStateToProps = (state = {}) => {
  const { uploads, auth = {} } = state
  const { lanId = '' } = auth
  return {
    uploads,
    lanId,
  }
}

export default connect(mapStateToProps, {
  toggleProject,
  toggleUploadManager,
  getProjectAssetDetails,
})(withRouter(MyComponent))
