import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@mui/styles'
import { IconButton, Hidden } from '@mui/material'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'

const styles = (theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '10px',
    fontSize: '13px',
    fontWeight: '400px',
  },
})

export class PageSelector extends React.Component {
  render() {
    const { classes, currentPage, numberOfPages, prevPage, nextPage } =
      this.props
    return (
      <div className={classes.root}>
        <IconButton
          onClick={prevPage}
          data-cy="prevPage"
          className="prev-page"
          size="large"
        >
          <KeyboardArrowLeft />
        </IconButton>
        <span data-cy="pageSelector">
          {' '}
          {`Page ${currentPage + 1} of ${numberOfPages}`}
        </span>
        <IconButton
          onClick={nextPage}
          data-cy="nextPage"
          className="next-page"
          size="large"
        >
          <KeyboardArrowRight />
        </IconButton>
      </div>
    )
  }
}

PageSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  currentPage: PropTypes.number,
  prevPage: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
  numberOfPages: PropTypes.number,
}

export default withStyles(styles)(PageSelector)
