import {
  BOARD_ADD_ASSETS_FULFILLED,
  BOARD_ADD_ASSETS_PENDING,
  BOARD_ADD_ASSETS_REJECTED,
  BOARD_REMOVE_ASSETS_FULFILLED,
  BOARD_REMOVE_ASSETS_PENDING,
  BOARD_REMOVE_ASSETS_REJECTED,
  BOARD_FETCH_ASSETS_FULFILLED,
  BOARD_FETCH_ASSETS_PENDING,
  BOARD_FETCH_ASSETS_REJECTED,
  BOARDS_FILTER,
  BOARDS_SORT,
  CREATE_BOARD_FULFILLED,
  CREATE_BOARD_PENDING,
  CREATE_BOARD_REJECTED,
  DELETE_BOARD_FULFILLED,
  DELETE_BOARD_PENDING,
  DELETE_BOARD_REJECTED,
  FETCH_USER_BOARDS_FULFILLED,
  FETCH_USER_BOARDS_PENDING,
  FETCH_USER_BOARDS_REJECTED,
  FETCH_BOARD_FULFILLED,
  FETCH_BOARD_PENDING,
  FETCH_BOARD_REJECTED,
  OPEN_MODAL,
  CLOSE_MODAL,
  GET_PROJECT_TYPES_FULFILLED,
  GET_PROJECT_TYPES_PENDING,
  GET_PROJECT_TYPES_REJECTED,
  TOGGLE_BOARDS_DIALOG,
  GET_SELECTED_ASSETS,
  EXPAND_BOARD_GROUP,
  EXPAND_BOARD_GROUP_REJECTED,
  EXPAND_BOARD_GROUP_FULFILLED,
  COLLAPSE_GROUP,
  UPDATE_BOARD_FULFILLED,
  UPDATE_BOARD_PENDING,
  UPDATE_BOARD_REJECTED,
  SET_BOARD_DOWNLOAD_ASSETS,
  TOGGLE_REDIRECT_BOARD_LIST,
} from './actionTypes'
import { groupAssetsByBoard, structureCollaboratorList } from './helpers'
import { sortByDate, sortByAssetCount } from '../../helpers/UtilityHelper'

export const initialState = {
  board: {},
  boardList: [],
  boardCount: 0,
  boardAssets: {},
  isBoardCreated: false,
  isBoardDeleted: false,
  isFetching: false,
  isModifying: false,
  isCreateBoardModalOpen: false,
  projectTypes: [],
  projectTypeCount: 0,
  isBoardDialogOpen: false,
  selectedAssets: [],
  isAssetAdded: false,
  boardCollaborators: [],
  isBoardSharing: false,
  boardDownloadAssets: {},
  isRedirectToBoardList: false,
}

export default function boardsReducer(state = initialState, action = {}) {
  const { type, payload = {} } = action
  const {
    response = {},
    data = [],
    filterValue = '',
    sortType = '',
    isRedirectToBoardList = false,
  } = payload
  let collaborators = []
  const {
    modalData = {},
    modalName = '',
    selectedAssets = '',
    downloadAssets = {},
  } = action
  switch (type) {
    case TOGGLE_BOARDS_DIALOG:
      return {
        ...state,
        isBoardDialogOpen: !state.isBoardDialogOpen,
      }
    case GET_SELECTED_ASSETS:
      return {
        ...state,
        selectedAssets,
      }
    case OPEN_MODAL:
      return {
        ...state,
        [modalName]: true,
        modalData,
      }
    case CLOSE_MODAL:
      return {
        ...state,
        [modalName]: false,
      }
    case GET_PROJECT_TYPES_FULFILLED: {
      const resTypes = [...data].sort(function (a, b) {
        return a.project_type_sequence - b.project_type_sequence
      })
      return {
        ...state,
        projectTypes: resTypes,
        projectTypeCount: resTypes.length,
        isFetching: false,
      }
    }
    case FETCH_BOARD_FULFILLED:
      return {
        ...state,
        board: data,
        isFetching: false,
        isRedirectToBoardList: false,
      }

    case FETCH_BOARD_PENDING:
      return {
        ...state,
        board: {},
        isFetching: true,
        isRedirectToBoardList: false,
      }

    case FETCH_BOARD_REJECTED:
      return {
        ...state,
        errors: response.data,
        isFetching: false,
      }

    case EXPAND_BOARD_GROUP: {
      collaborators = state.boardCollaborators.map((collaborator) => {
        let updatedCollaborator = {}
        if (collaborator.list_id === payload) {
          updatedCollaborator = {
            ...collaborator,
            fetchingUsers: true,
          }
        } else {
          updatedCollaborator = { ...collaborator }
        }
        return updatedCollaborator
      })
      return {
        ...state,
        boardCollaborators: collaborators,
      }
    }
    case EXPAND_BOARD_GROUP_FULFILLED:
      collaborators = state.boardCollaborators.map((collaborator) => {
        let updatedCollaborator = {}
        if (collaborator.list_id === payload.groupId) {
          updatedCollaborator = {
            ...collaborator,
            users: structureCollaboratorList(payload.data.user),
            expanded: true,
            fetchingUsers: false,
          }
        } else {
          updatedCollaborator = { ...collaborator }
        }
        return updatedCollaborator
      })
      return {
        ...state,
        boardCollaborators: collaborators,
      }

    case EXPAND_BOARD_GROUP_REJECTED:
      collaborators = state.boardCollaborators.map((collaborator) => {
        let updatedCollaborator = {}
        if (collaborator.list_id === payload.groupId) {
          updatedCollaborator = {
            ...collaborator,
            fetchingUsers: false,
          }
        } else {
          updatedCollaborator = { ...collaborator }
        }
        return updatedCollaborator
      })
      return {
        ...state,
        errors: response.data,
        boardCollaborators: collaborators,
      }

    case COLLAPSE_GROUP:
      collaborators = state.boardCollaborators.map((collaborator) => {
        let updatedCollaborator = {}
        if (collaborator.list_id === payload) {
          updatedCollaborator = {
            ...collaborator,
            expanded: false,
          }
        } else {
          updatedCollaborator = { ...collaborator }
        }
        return updatedCollaborator
      })
      return {
        ...state,
        errors: response.data,
        boardCollaborators: collaborators,
      }

    case FETCH_USER_BOARDS_FULFILLED:
      return {
        ...state,
        boardList: sortByDate(data, 'created_date'),
        boardCount: data.length,
        isFetching: false,
      }
    case GET_PROJECT_TYPES_PENDING:
    case BOARD_FETCH_ASSETS_PENDING:
    case FETCH_USER_BOARDS_PENDING:
      return {
        ...state,
        isFetching: true,
      }
    case GET_PROJECT_TYPES_REJECTED:
    case BOARD_FETCH_ASSETS_REJECTED:
    case FETCH_USER_BOARDS_REJECTED:
      return {
        ...state,
        errors: response.data,
        isFetching: false,
      }
    case CREATE_BOARD_FULFILLED:
    case UPDATE_BOARD_FULFILLED:
      return {
        ...state,
        isBoardCreated: false,
        isModifying: false,
        isCreateBoardModalOpen: false,
      }
    case BOARD_ADD_ASSETS_PENDING:
    case BOARD_REMOVE_ASSETS_PENDING:
      return {
        ...state,
        isBoardCreated: true,
        isBoardDeleted: false,
        isModifying: true,
        isCreateBoardModalOpen: false,
        isAssetAdded: false,
      }
    case CREATE_BOARD_PENDING:
    case UPDATE_BOARD_PENDING:
      return {
        ...state,
        isBoardCreated: true,
        isBoardDeleted: false,
        isModifying: true,
        isAssetAdded: false,
      }
    case BOARD_ADD_ASSETS_REJECTED:
    case BOARD_REMOVE_ASSETS_REJECTED:
    case CREATE_BOARD_REJECTED:
    case DELETE_BOARD_REJECTED:
    case UPDATE_BOARD_REJECTED:
      return {
        ...state,
        errors: response.data,
        isModifying: false,
        isAssetAdded: false,
      }
    case DELETE_BOARD_FULFILLED:
      return {
        ...state,
        isBoardDeleted: true,
        isModifying: false,
        isRedirectToBoardList: true,
      }
    case DELETE_BOARD_PENDING:
      return {
        ...state,
        isModifying: true,
        isBoardCreated: false,
        isBoardDeleted: false,
        isRedirectToBoardList: false,
      }
    case BOARD_FETCH_ASSETS_FULFILLED: {
      const boardAssets = state.boardAssets
      const modifiedAssets = groupAssetsByBoard({ ...boardAssets }, data)
      return {
        ...state,
        boardAssets: modifiedAssets,
        isFetching: false,
      }
    }
    case BOARD_ADD_ASSETS_FULFILLED:
      return {
        ...state,
        isCreateBoardModalOpen: false,
        isAssetAdded: true,
      }
    case BOARD_REMOVE_ASSETS_FULFILLED:
      return {
        ...state,
        isModifying: false,
        isCreateBoardModalOpen: false,
      }

    case BOARDS_FILTER: {
      let filterResults = []
      let projCount = 0
      let filteredBoardList = [...state.boardList]
      filterResults = filteredBoardList.map(function (obj) {
        let newObj = { ...obj }
        if (
          newObj.board_name &&
          newObj.board_name.toLowerCase().indexOf(filterValue.toLowerCase()) !==
            -1
        ) {
          newObj.show = true
          projCount++
        } else {
          newObj.show = false
        }
        return newObj
      })
      return {
        ...state,
        boardList: filterResults,
        boardCount: projCount,
      }
    }
    case BOARDS_SORT: {
      let sortResults = []
      let sortedBoardList = [...state.boardList]
      switch (sortType) {
        case 'board_name':
          // sort by alphabetical
          sortResults = sortedBoardList.sort(function (a, b) {
            let nameA = a.board_name.toUpperCase() // ignore upper and lowercase
            let nameB = b.board_name.toUpperCase() // ignore upper and lowercase
            return nameA < nameB ? -1 : 1
          })
          break
        case 'board_name_asc':
          sortResults = sortedBoardList.sort(function (a, b) {
            let nameA = a.board_name.toUpperCase() // ignore upper and lowercase
            let nameB = b.board_name.toUpperCase() // ignore upper and lowercase
            return nameA > nameB ? -1 : 1
          })
          break
        case 'asset_count_low':
          sortResults = sortByAssetCount(sortedBoardList, 'asc')
          break
        case 'asset_count_high':
          sortResults = sortByAssetCount(sortedBoardList, 'desc')
          break
        case 'created_at':
          sortResults = sortByDate(sortedBoardList, 'created_date')
          break
        case 'created_at_asc':
          sortResults = sortByDate(sortedBoardList, 'created_date', 'asc')
          break
        case 'last_updated':
          sortResults = sortByDate(sortedBoardList, 'updated_date')
          break
        case 'last_updated_asc':
          sortResults = sortByDate(sortedBoardList, 'updated_date', 'asc')
          break
        default:
          sortResults = sortByDate(sortedBoardList, 'created_date')
          break
      }
      return {
        ...state,
        boardList: sortResults,
      }
    }
    case SET_BOARD_DOWNLOAD_ASSETS:
      return {
        ...state,
        boardDownloadAssets: { ...downloadAssets },
      }
    case TOGGLE_REDIRECT_BOARD_LIST:
      return {
        ...state,
        isRedirectToBoardList,
      }
    default:
      return state
  }
}
