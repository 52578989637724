import {
  SIGN_IN_SUCCESS,
  PROMPT_NO_ACCESS,
  CLOSE_NO_ACCESS,
} from './actionTypes'

export function setAuthData(payload = {}) {
  return {
    type: SIGN_IN_SUCCESS,
    payload,
  }
}

export function promptNoAccessPage(payload = '') {
  return {
    type: PROMPT_NO_ACCESS,
    payload,
  }
}

export function closeNoAccessPopUp() {
  return {
    type: CLOSE_NO_ACCESS,
  }
}
