import React, { Fragment } from 'react'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { isEqual, has } from 'lodash'
import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material'
import { Tune } from '@mui/icons-material'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import {
  updateFacetFilter,
  saveAppliedFilters,
  fetchAssets,
  setSortFields,
  setTemplateId,
} from '../../store/search/actions'
import { selectMemberOf } from '../../store/auth/selector'
import {
  initFiltersInAppliedFilters,
  isSameDay,
} from '../../helpers/SearchHelper'
import {
  SEARCH_TEMPLATE_DEFAULT_VALUE,
  SORT_BY_OPTIONS,
  DEFAULT_SORT_BY_OPTION,
  SEARCH_DEFAULT_FACETS,
  FACET_SUB_CLASS_KEY_NAME,
  FACET_BRAND_KEY_NAME,
  FACET_UPLOAD_DATE_NAME,
  FACET_WITH_PEOPLE_KEY_VALUE,
  FACET_WITH_ANIMALS_KEY_VALUE,
  FACET_WITH_FOOD_KEY_VALUE,
  DEFAULT_COLORS,
  FACET_COLOR_KEY_NAME,
  FACET_CUSTOM_COLOR_KEY_NAME,
  FACET_CLASSIFICATION_ID_KEY_VALUE,
  FACET_CLASSIFICATION_ID_KEY_NAME,
  FACET_CLASSIFICATION_MISSING_KEY_VALUE,
  FACET_CLASSIFICATION_MISSING_KEY_NAME,
} from '../../constants/search'
import { AD_SUPER_ADMIN } from '../../constants/adGroups'
import FacetFilters from './FacetFilters'
import firefly from '../../analytics/firefly'
import ClassificationFilter from './ClassificationFilter'

const styles = makeStyles((theme) => ({
  root: {
    position: 'sticky',
    top: '15px',
    paddingLeft: '10px',
  },
  facetListRoot: {
    marginLeft: '3px',
    // backgroundColor: '#F5F5F5',
    // borderRight: '1px solid #e5e5e5',
    // borderBottom: '1px solid #e5e5e5',
    // paddingBottom: '200px'
  },
  facetListHeader: {
    backgroundColor: '#F5F5F5',
    height: '53px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: useTheme().palette.primary.lightestGrey,
    padding: '10px',
  },
  scrollList: {
    maxHeight: 400,
    overflow: 'auto',
    width: '100%',
    '&::-webkit-scrollbar': {
      width: 9,
    },
    /* Track */
    '&::-webkit-scrollbar-track': {
      background: useTheme().palette.primary.lighterGrey,
      border: '4px solid transparent',
      backgroundClip: 'content-box',
    },
    /* Handle */
    '&::-webkit-scrollbar-thumb': {
      background: useTheme().palette.primary.lighterGrey,
      border: '1px solid ' + useTheme().palette.primary.lighterGrey,
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.25em',
    flexDirection: 'column',
  },
  tuneIcon: {
    backgroundColor: 'white',
    borderRadius: '50%',
    padding: '3px',
    height: '35px',
    width: '35px',
    color: useTheme().palette.primary.mediumGrey,
    margin: '10px',
  },
  contorlsLabel: {
    fontSize: '21px',
    fontWeight: 400,
    color: useTheme().palette.primary.midGrey,
  },
  buttonLabel: {
    fontSize: '11px',
  },
  sortByContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    marginTop: '15px',
  },
  uploadDateText: {
    color: useTheme().palette.primary.primaryBlue,
    fontWeight: 700,
    fontSize: '15px',
    margin: '15px',
  },
  filterInputRoot: {
    padding: '10px 0px',
    width: 'auto',
  },
  expansionPanelRoot: {
    backgroundColor: 'unset',
    boxShadow: 'unset',
    '&:before': {
      backgroundColor: 'unset',
    },
  },
  expansionPanelSummary: {
    color: '#4D4F5C',
    fontSize: '14px',
    margin: '7px',
    backgroundColor: 'white',
    borderRadius: 5,
    minHeight: '55px !important',
  },
  expansionPanelSummaryExpanded: {
    backgroundColor: '#e5e5e5',
  },
  expandMore: {
    color: '#4D4F5C',
    fontWeight: 700,
  },
  expansionPanelDetails: {
    padding: '0 14px',
    display: 'flex',
    flexDirection: 'column',
  },
  expansionPanelDetailsContent: {
    padding: '0 0 14px 14px',
    display: 'flex',
    flexDirection: 'column',
  },
  contentButton: {
    fontSize: '11px',
    background: '#fcfbfb',
    border: '1px solid #c3c3c8',
    padding: '10px',
    textAlign: 'center',
    borderRadius: '4px',
    margin: '0 14px 0px 6px',
    cursor: 'pointer',
    flex: 1,
  },
  contentButtonSelected: {
    fontSize: '10px',
    background: '#8b8b8e',
    border: '1px solid #8b8b8e',
    padding: '10px',
    color: '#fcfbfb',
    textAlign: 'center',
    borderRadius: '4px',
    margin: '0 14px 14px 6px',
    cursor: 'pointer',
    flex: 1,
  },
  contentContainer: {
    display: 'flex',
  },
  contentText: {
    margin: '14px 0 14px 8px',
    fontSize: '14px',
    color: '#4D4F5C',
    fontWeight: 500,
  },
  horizLine: {
    borderTop: '1px solid #dcd4d4',
    margin: '12px 26px 12px 12px',
  },
}))

export class FacetList extends React.Component {
  constructor(props) {
    super(props)

    const initialState = {
      templateId: SEARCH_TEMPLATE_DEFAULT_VALUE,
      sortBy: DEFAULT_SORT_BY_OPTION,
      startDate: null,
      endDate: null,
      focusedInput: null,
      initialStartDate: null,
      initialEndDate: null,
      initClassificationId: null,
      isWithPeople: false,
      isWithOutPeople: false,
      isWithAnimals: false,
      isWithOutAnimals: false,
      isWithFood: false,
      isWithOutFood: false,
    }

    this.initialState = initialState

    this.state = initialState
    this.ACFacets = {
      [FACET_BRAND_KEY_NAME]: {},
      [FACET_SUB_CLASS_KEY_NAME]: {},
    }
  }

  componentDidMount() {
    const { appliedFilters = {} } = this.props
    if (appliedFilters[FACET_WITH_PEOPLE_KEY_VALUE]) {
      if (appliedFilters[FACET_WITH_PEOPLE_KEY_VALUE][0] === 'With People') {
        this.setState({ isWithPeople: true, isWithOutPeople: false })
      } else {
        this.setState({ isWithPeople: false, isWithOutPeople: true })
      }
    }
    if (appliedFilters[FACET_WITH_ANIMALS_KEY_VALUE]) {
      if (appliedFilters[FACET_WITH_ANIMALS_KEY_VALUE][0] === 'With Animals') {
        this.setState({ isWithAnimals: true, isWithOutAnimals: false })
      } else {
        this.setState({ isWithAnimals: false, isWithOutAnimals: true })
      }
    }
    if (appliedFilters[FACET_WITH_FOOD_KEY_VALUE]) {
      if (appliedFilters[FACET_WITH_FOOD_KEY_VALUE][0] === 'With Food') {
        this.setState({ isWithFood: true, isWithOutFood: false })
      } else {
        this.setState({ isWithFood: false, isWithOutFood: true })
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      pageActionType = '',
      pageActionOptions = {},
      isRemove = false,
      appliedFilters = {},
    } = this.props
    const { type } = pageActionOptions
    const { pageActionOptions: prevPageActionOptions = {} } = prevProps
    if (
      pageActionType &&
      (isRemove ||
        JSON.stringify(prevPageActionOptions) !==
          JSON.stringify(pageActionOptions))
    ) {
      this.triggerPageActions(pageActionType, pageActionOptions, type)
    }
    if (prevProps.searchTerm !== this.props.searchTerm) {
      const newState = prevState
      const initialStateKeys = Object.keys(this.initialState)
      Object.keys(newState).forEach((key) => {
        if (initialStateKeys.indexOf(key) === -1) {
          newState[key] = false
        }
      })
      this.setState({ ...newState, sortBy: this.initialState.sortBy })
    }

    if (!isEqual(prevProps.appliedFilters, this.props.appliedFilters)) {
      let selectedFilters = {}
      Object.keys(appliedFilters).forEach((filterKey = '') => {
        const filterValues = appliedFilters[filterKey] || []
        filterValues.forEach((filterValue = '') => {
          selectedFilters[filterValue] = true
        })
      })

      if (
        !!Object.entries(appliedFilters).length &&
        !appliedFilters[FACET_WITH_PEOPLE_KEY_VALUE]
      ) {
        this.setState({ isWithPeople: false, isWithOutPeople: false })
      }
      if (
        !!Object.entries(appliedFilters).length &&
        !appliedFilters[FACET_WITH_ANIMALS_KEY_VALUE]
      ) {
        this.setState({ isWithAnimals: false, isWithOutAnimals: false })
      }
      if (
        !!Object.entries(appliedFilters).length &&
        !appliedFilters[FACET_WITH_FOOD_KEY_VALUE]
      ) {
        this.setState({ isWithFood: false, isWithOutFood: false })
      }
      if (
        Object.entries(appliedFilters).length &&
        !appliedFilters[FACET_CLASSIFICATION_ID_KEY_VALUE]
      ) {
        this.setState({ initClassificationId: '' })
      }

      this.setState((state) => ({
        ...selectedFilters,
        templateId: state.templateId,
      }))
    }
  }

  triggerPageActions = (
    pageActionType = '',
    pageActionOptions = {},
    type = ''
  ) => {
    switch (pageActionType) {
      case 'tabschange':
        {
          const { templateId = '' } = pageActionOptions
          this.onTemplateChange(templateId)
        }
        break
      case 'clear':
        this.onClearAppliedFilters()
        break
      case 'filter':
        {
          const { filterName = '', filterValue = '' } = pageActionOptions
          this.onFilterChange(filterName, filterValue, { type })
        }
        break
      case 'sort':
        {
          const { sortByOption = '' } = pageActionOptions
          this.onSortChange(sortByOption)
        }
        break
      default:
        break
    }
  }

  getColorFacetValue = (filterValue = '') =>
    DEFAULT_COLORS[(filterValue || '').toUpperCase()] || filterValue

  onFilterChange = (
    filterName = '',
    filterValue = '',
    filterOptions = { update: false, type: '', formatter: undefined }
  ) => {
    const {
      searchTerm,
      updateFacetFilter,
      pageSize,
      resetPageNumber,
      currentTemplateId,
      initFilters = {},
      appliedFilters = {},
      history,
      router = {},
      userId = '',
      setIsRemove = () => {},
      onSearchTemplateChange = () => {},
      setTemplateId = () => {},
    } = this.props
    const isFilterApplied = this.state[filterValue]
    const { update, type } = filterOptions
    const { sortBy } = this.state
    const sort_fields = SORT_BY_OPTIONS[sortBy]
    const initFiltersInPageFilters = initFiltersInAppliedFilters(
      initFilters,
      appliedFilters
    )
    if (
      initFiltersInPageFilters.indexOf(SEARCH_DEFAULT_FACETS[filterName]) !==
        -1 &&
      initFiltersInAppliedFilters(initFilters, appliedFilters).length === 0
    ) {
      router.navigate('?')
      return
    }
    if (type === 'REMOVE') {
      if (
        this.ACFacets &&
        this.ACFacets[filterName] &&
        this.ACFacets[filterName].handleTagDelete
      ) {
        this.ACFacets[filterName].handleTagDelete(filterValue, this)
      }
      if (filterName === FACET_UPLOAD_DATE_NAME) {
        this.setState({ startDate: null, endDate: null })
      } else if (filterName === FACET_CLASSIFICATION_ID_KEY_NAME) {
        this.setState({ initClassificationId: null })
      }
      if (
        !has(appliedFilters, FACET_CLASSIFICATION_MISSING_KEY_VALUE) ||
        filterName === FACET_CLASSIFICATION_MISSING_KEY_NAME
      ) {
        onSearchTemplateChange('')
        setTemplateId('')
      }
      setIsRemove()
    }

    this.setState({ [filterValue]: !isFilterApplied })

    let actionType = isFilterApplied ? 'REMOVE' : 'ADD'

    if (update) {
      actionType =
        [FACET_COLOR_KEY_NAME, FACET_CUSTOM_COLOR_KEY_NAME].indexOf(
          filterName
        ) !== -1
          ? 'UPDATECOLOR'
          : 'UPDATE'
    } else if (type) {
      actionType = type
    }

    const payload = {
      actionType,
      filterName,
      filterValue:
        filterName === FACET_COLOR_KEY_NAME
          ? this.getColorFacetValue(filterValue)
          : filterValue,
      searchTerm,
      size: pageSize,
      sort_fields,
      templateId: currentTemplateId,
      index: this.props.indexBy,
    }
    resetPageNumber()
    updateFacetFilter(payload)

    firefly.trackClick(
      'search',
      actionType,
      searchTerm,
      `${filterName}: ${filterValue}`,
      userId,
      currentTemplateId
    )
  }

  onClearAppliedFilters = () => {
    const {
      searchTerm,
      pageSize,
      fetchAssets,
      resetAll,
      initFilters = {},
      appliedFilters = {},
      setTemplateId = () => {},
      history,
      router = {},
    } = this.props
    if (initFiltersInAppliedFilters(initFilters, appliedFilters).length > 0) {
      router.navigate('?')
    } else {
      resetAll()
      setTemplateId('')
      fetchAssets({
        filters: initFilters,
        searchTerm,
        size: pageSize,
        templateId: SEARCH_TEMPLATE_DEFAULT_VALUE,
        sortBy: {},
      })
      this.setState((state) => {
        Object.keys(state).forEach((v) => (state[v] = false))
        state.sortBy = 'relevance'
        return state
      })
    }
    ;[FACET_BRAND_KEY_NAME, FACET_SUB_CLASS_KEY_NAME].forEach((value) => {
      if (
        this.ACFacets[value] &&
        this.ACFacets[value] &&
        this.ACFacets[value].handleTagsClear
      ) {
        this.ACFacets[value].handleTagsClear()
      }
    })
  }

  onTemplateChange = (event) => {
    const { saveAppliedFilters, onSearchTemplateChange } = this.props
    const templateId = typeof event === 'object' ? event.target.value : event
    saveAppliedFilters({})
    onSearchTemplateChange(templateId)
  }

  onSortChange = (event) => {
    const {
      appliedFilters = {},
      currentTemplateId = SEARCH_TEMPLATE_DEFAULT_VALUE,
      searchTerm,
      pageSize,
      fetchAssets,
      setSortFields,
    } = this.props
    const sortByOption = typeof event === 'object' ? event.target.value : event
    const sort_fields = SORT_BY_OPTIONS[sortByOption]
    setSortFields(sort_fields)
    fetchAssets({
      filters: appliedFilters,
      searchTerm,
      size: pageSize,
      templateId: currentTemplateId,
      sort_fields,
    })
    this.setState({ sortBy: sortByOption })
  }

  setFacetHandlers = (facetName, handleTagDelete, handleTagsClear) => {
    if (this.ACFacets && this.ACFacets[facetName]) {
      this.ACFacets[facetName] = { handleTagDelete, handleTagsClear }
    }
  }

  onClickWithPeople = () => {
    this.setState(
      { isWithPeople: !this.state.isWithPeople, isWithOutPeople: false },
      () => {
        if (this.state.isWithPeople === true) {
          this.onFilterChange('People', 'With People', {
            update: false,
            type: 'UPDATE',
            formatter: undefined,
          })
        } else {
          this.onFilterChange('People', 'With People', {
            update: false,
            type: 'REMOVE',
            formatter: undefined,
          })
        }
      }
    )
  }

  onClickWithoutPeople = () => {
    this.setState(
      { isWithOutPeople: !this.state.isWithOutPeople, isWithPeople: false },
      () => {
        if (this.state.isWithOutPeople === true) {
          this.onFilterChange('People', 'Without People', {
            update: false,
            type: 'UPDATE',
            formatter: undefined,
          })
        } else {
          this.onFilterChange('People', 'Without People', {
            update: false,
            type: 'REMOVE',
            formatter: undefined,
          })
        }
      }
    )
  }

  onClickWithAnimals = () => {
    this.setState(
      { isWithAnimals: !this.state.isWithAnimals, isWithOutAnimals: false },
      () => {
        if (this.state.isWithAnimals === true) {
          this.onFilterChange('Animals', 'With Animals', {
            update: false,
            type: 'UPDATE',
            formatter: undefined,
          })
        } else {
          this.onFilterChange('Animals', 'With Animals', {
            update: false,
            type: 'REMOVE',
            formatter: undefined,
          })
        }
      }
    )
  }

  onClickWithoutAnimals = () => {
    this.setState(
      { isWithOutAnimals: !this.state.isWithOutAnimals, isWithAnimals: false },
      () => {
        if (this.state.isWithOutAnimals === true) {
          this.onFilterChange('Animals', 'Without Animals', {
            update: false,
            type: 'UPDATE',
            formatter: undefined,
          })
        } else {
          this.onFilterChange('Animals', 'Without Animals', {
            update: false,
            type: 'REMOVE',
            formatter: undefined,
          })
        }
      }
    )
  }

  onClickWithFood = () => {
    this.setState(
      { isWithFood: !this.state.isWithFood, isWithOutFood: false },
      () => {
        if (this.state.isWithFood === true) {
          this.onFilterChange('Food', 'With Food', {
            update: false,
            type: 'UPDATE',
            formatter: undefined,
          })
        } else {
          this.onFilterChange('Food', 'With Food', {
            update: false,
            type: 'REMOVE',
            formatter: undefined,
          })
        }
      }
    )
  }

  onClickWithoutFood = () => {
    this.setState(
      { isWithOutFood: !this.state.isWithOutFood, isWithFood: false },
      () => {
        if (this.state.isWithOutFood === true) {
          this.onFilterChange('Food', 'Without Food', {
            update: false,
            type: 'UPDATE',
            formatter: undefined,
          })
        } else {
          this.onFilterChange('Food', 'Without Food', {
            update: false,
            type: 'REMOVE',
            formatter: undefined,
          })
        }
      }
    )
  }

  render() {
    const {
      appliedFilters = {},
      classes = {},
      facets = {},
      pageSize,
      searchTerm,
      memberOf = [],
      role = '',
      classificationTypes = [],
    } = this.props
    const member = role ? [role] : memberOf
    const facetKeys = Object.keys(facets) || []
    const {
      isWithOutPeople = false,
      isWithPeople = false,
      isWithOutAnimals = false,
      isWithAnimals = false,
      isWithOutFood = false,
      isWithFood = false,
    } = this.state
    return (
      <Grid
        container
        direction={'row'}
        spacing={0}
        className={classes.facetListRoot}
      >
        <Grid item xs={12} lg={12} md={12}>
          <Accordion
            defaultExpanded={true}
            classes={{
              root: classes.expansionPanelRoot,
              expanded: classes.expansionPanelExpanded,
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              classes={{
                root: classes.expansionPanelSummary,
                expanded: classes.expansionPanelSummaryExpanded,
              }}
            >
              ASSET DESCRIPTION
            </AccordionSummary>
            <AccordionDetails
              classes={{
                root: classes.expansionPanelDetailsContent,
              }}
            >
              <Fragment>
                <div className={classes.contentText}>People</div>
                <div className={classes.contentContainer}>
                  <div
                    className={
                      isWithPeople
                        ? classes.contentButtonSelected
                        : classes.contentButton
                    }
                    onClick={this.onClickWithPeople}
                  >
                    WITH PEOPLE
                  </div>
                  <div
                    className={
                      isWithOutPeople
                        ? classes.contentButtonSelected
                        : classes.contentButton
                    }
                    onClick={this.onClickWithoutPeople}
                  >
                    WITHOUT PEOPLE
                  </div>
                </div>
              </Fragment>
              <Fragment>
                <div className={classes.contentText}>Animals</div>
                <div className={classes.contentContainer}>
                  <div
                    className={
                      isWithAnimals
                        ? classes.contentButtonSelected
                        : classes.contentButton
                    }
                    onClick={this.onClickWithAnimals}
                  >
                    WITH ANIMALS
                  </div>
                  <div
                    className={
                      isWithOutAnimals
                        ? classes.contentButtonSelected
                        : classes.contentButton
                    }
                    onClick={this.onClickWithoutAnimals}
                  >
                    WITHOUT ANIMALS
                  </div>
                </div>
              </Fragment>
              <Fragment>
                <div className={classes.contentText}>Food</div>
                <div className={classes.contentContainer}>
                  <div
                    className={
                      isWithFood
                        ? classes.contentButtonSelected
                        : classes.contentButton
                    }
                    onClick={this.onClickWithFood}
                  >
                    WITH FOOD
                  </div>
                  <div
                    className={
                      isWithOutFood
                        ? classes.contentButtonSelected
                        : classes.contentButton
                    }
                    onClick={this.onClickWithoutFood}
                  >
                    WITHOUT FOOD
                  </div>
                </div>
              </Fragment>
            </AccordionDetails>
            <AccordionDetails
              classes={{
                root: classes.expansionPanelDetails,
              }}
            >
              {facetKeys
                .sort()
                .map((facetName) =>
                  facetName == 'Shot Type' ||
                  [FACET_COLOR_KEY_NAME, FACET_CUSTOM_COLOR_KEY_NAME].indexOf(
                    facetName
                  ) !== -1 ? (
                    <Fragment key={facetName}>
                      {facets[facetName] &&
                        Object.keys(facets[facetName]).length > 0 && (
                          <FacetFilters
                            appliedFilters={appliedFilters}
                            facetName={facetName}
                            filters={facets[facetName]}
                            onFilterChange={this.onFilterChange}
                            selectedFilters={this.state}
                            setFacetHandlers={this.setFacetHandlers}
                            pageSize={pageSize}
                            searchTerm={searchTerm}
                            indexBy={this.props.indexBy}
                          />
                        )}
                    </Fragment>
                  ) : (
                    <div />
                  )
                )}
              {member.length && member.includes(AD_SUPER_ADMIN) ? (
                <ClassificationFilter
                  facetName={FACET_CLASSIFICATION_ID_KEY_NAME}
                  facetData={classificationTypes}
                  onFilterChange={this.onFilterChange}
                  selectedFilters={this.state}
                />
              ) : (
                ''
              )}
            </AccordionDetails>
          </Accordion>

          <Accordion
            defaultExpanded={true}
            classes={{
              root: classes.expansionPanelRoot,
              expanded: classes.expansionPanelExpanded,
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              classes={{
                root: classes.expansionPanelSummary,
                expanded: classes.expansionPanelSummaryExpanded,
              }}
            >
              PRODUCT INFORMATION
            </AccordionSummary>
            <AccordionDetails
              classes={{
                root: classes.expansionPanelDetails,
              }}
            >
              {facetKeys
                .sort()
                .map((facetName) =>
                  facetName === 'Brand' ||
                  facetName === 'Department' ||
                  facetName === 'Class' ||
                  facetName === 'Sub Class' ? (
                    <Fragment key={facetName}>
                      {facets[facetName] &&
                        Object.keys(facets[facetName]).length > 0 && (
                          <FacetFilters
                            appliedFilters={appliedFilters}
                            facetName={facetName}
                            filters={facets[facetName]}
                            onFilterChange={this.onFilterChange}
                            selectedFilters={this.state}
                            setFacetHandlers={this.setFacetHandlers}
                            pageSize={pageSize}
                            searchTerm={searchTerm}
                          />
                        )}
                    </Fragment>
                  ) : (
                    <div />
                  )
                )}
            </AccordionDetails>
          </Accordion>

          <Accordion
            defaultExpanded={true}
            classes={{
              root: classes.expansionPanelRoot,
              expanded: classes.expansionPanelExpanded,
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              classes={{
                root: classes.expansionPanelSummary,
                expanded: classes.expansionPanelSummaryExpanded,
              }}
            >
              PROJECT INFORMATION
            </AccordionSummary>
            <AccordionDetails
              classes={{
                root: classes.expansionPanelDetails,
              }}
            >
              {facetKeys
                .sort()
                .map((facetName) =>
                  facetName === 'Campaign Year' || facetName === 'Channel' ? (
                    <Fragment key={facetName}>
                      {facets[facetName] &&
                        Object.keys(facets[facetName]).length > 0 && (
                          <FacetFilters
                            appliedFilters={appliedFilters}
                            facetName={facetName}
                            filters={facets[facetName]}
                            onFilterChange={this.onFilterChange}
                            selectedFilters={this.state}
                            setFacetHandlers={this.setFacetHandlers}
                            pageSize={pageSize}
                            searchTerm={searchTerm}
                          />
                        )}
                    </Fragment>
                  ) : (
                    <div />
                  )
                )}
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    )
  }
}

FacetList.propTypes = {
  appliedFilters: PropTypes.object,
  classes: PropTypes.object.isRequired,
  facets: PropTypes.object.isRequired,
  searchTerm: PropTypes.string,
  initFilters: PropTypes.object,
  updateFacetFilter: PropTypes.func,
  pageSize: PropTypes.number.isRequired,
  resetPageNumber: PropTypes.func.isRequired,
  saveAppliedFilters: PropTypes.func.isRequired,
  fetchAssets: PropTypes.func.isRequired,
  resetAll: PropTypes.func.isRequired,
  onSearchTemplateChange: PropTypes.func.isRequired,
  pageActionType: PropTypes.string,
  pageActionOptions: PropTypes.object,
  searchTemplates: PropTypes.object.isRequired,
  setSortFields: PropTypes.func,
  currentTemplateId: PropTypes.string.isRequired,
  userId: PropTypes.string,
  isRemove: PropTypes.bool,
  setIsRemove: PropTypes.func,
}

const mapStateToProps = (state = {}) => ({
  role: state?.headerReducer?.role,
  memberOf: selectMemberOf()(state),
  classificationTypes: state?.assetClassificationReducer?.classificationTypes,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateFacetFilter,
      saveAppliedFilters,
      fetchAssets,
      setSortFields,
      setTemplateId,
    },
    dispatch
  )

const MyComponent = (props) => {
  const classes = styles()
  return <FacetList {...props} classes={classes} />
}

export default connect(mapStateToProps, mapDispatchToProps)(MyComponent)
