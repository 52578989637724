import React from 'react'
import Table from '@mui/material/Table'
import { withStyles } from '@mui/styles'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import MuiTableCell from '@mui/material/TableCell'
import PersonIcon from '@mui/icons-material/Person'
import { formatCSTDate } from '../../../helpers/dateHelper'

const styles = {
  tableContainerHeight: {
    maxHeight: '400px',
  },
  tableRowHeight: {
    height: '35px',
  },
  personIcon: {
    padding: '10px',
  },
}

const TableCellBoard = withStyles({
  root: {
    borderBottom: 'none',
  },
})(MuiTableCell)

function MemberDetails({ classes = {}, members = [] }) {
  return (
    <TableContainer className={classes.tableContainerHeight}>
      <Table data-cy="boardMembersTable" stickyHeader aria-label="simple table">
        <TableHead>
          <TableRow className={classes.tableRowHeight}>
            <TableCellBoard></TableCellBoard>
            <TableCellBoard>User</TableCellBoard>
            <TableCellBoard align="left">Joined On</TableCellBoard>
          </TableRow>
        </TableHead>
        <TableBody>
          {(members || []).map((item, i) => {
            const { user = {}, joined_on = '' } = item
            const { email_address = '', display_name = '' } = user
            const joinedDate = formatCSTDate(joined_on, 'll') || ''
            return (
              <TableRow key={i}>
                <TableCellBoard align="left" className={classes.personIcon}>
                  <PersonIcon />
                </TableCellBoard>
                <TableCellBoard component="th" scope="row">
                  <span>{display_name}</span> <br />
                  {email_address}
                </TableCellBoard>
                <TableCellBoard align="left">{joinedDate}</TableCellBoard>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default withStyles(styles)(MemberDetails)
