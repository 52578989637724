import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty, isEqual, get } from 'lodash'
import { connect } from 'react-redux'
import { CircularProgress } from '@mui/material'
import { withStyles } from '@mui/styles'
import { withEnv } from '@praxis/component-runtime-env'
import Annotation from '../AnnotationZoom'
import Box from './ImageMarkupBox'
import RectangleSelector from './RectangleSelector'
import { storeCurrentMarkup } from '../../store/annotation/actions'
import apiConfig from '../../config/apiConfig'
import { assetPropType } from '../../constants/annotation'
import { selectAssetVersion } from '../../store/annotation/selectors'
import { setScale } from '../../helpers/annotationHelper'
import { appendQueryParams } from '../../helpers/UrlHelper'

export function renderSelector({ annotation = {} }, number = 0, angle = 0) {
  const { geometry } = annotation
  if (!geometry) return null
  return <Box geometry={geometry} number={number} angle={angle} />
}

renderSelector.propTypes = {
  annotation: PropTypes.object,
}

export function renderHighlight({ annotation = {} }, angle = 0) {
  const { geometry, data } = annotation
  if (!geometry) return null
  return (
    <Box key={data.id} geometry={geometry} number={data.id} angle={angle} />
  )
}

renderHighlight.propTypes = {
  annotation: PropTypes.object,
}

export const StyledCicularProgress = withStyles({
  root: {
    position: 'absolute',
    zIndex: 1000,
    top: '38%',
    left: '33%',
  },
})(CircularProgress)

export class ImageAnnotator extends React.PureComponent {
  constructor(props) {
    super(props)

    this.imgRef = React.createRef()
    this.state = {
      containerClientWidth: 0,
      assetClientHeight: 0,
      assetClientWidth: 0,
      isLoading: true,
      isFirstRender: false,
      assetObj: {},
      naturalHeight: 0,
      naturalWidth: 0,
      zoomAnnotatorProps: {},
      doesExistsZoomAnnotatorProps: false,
    }
  }

  componentDidMount() {
    this.setCallback()

    const currentFoucsedElem = get(
      this.imgRef,
      'current.el.children[0].children[0]',
      null
    )
    const zoomRefCurElem = get(this.props, 'zoomPerRef.current', null)
    if (currentFoucsedElem) {
      // Setup isScrolling variable
      let isResizing
      currentFoucsedElem.addEventListener('resize', (event) => {
        event.preventDefault()
        const { detail: eventDetailObj = {} } = event
        const { scale = Number(1) } = eventDetailObj
        clearTimeout(isResizing)
        if (zoomRefCurElem) {
          zoomRefCurElem['innerText'] = `${Math.round(scale * 100)}%`
        }
        // Set a timeout to run after scrolling ends
        isResizing = setTimeout(function () {
          // Run the callback
        }, 66)
      })

      // Setup isScrolling variable
      let isScrolling
      // Listen for scroll events
      currentFoucsedElem.addEventListener(
        'wheel',
        (event) => {
          event.preventDefault()
          // Clear our timeout throughout the scroll
          clearTimeout(isScrolling)
          if (event.deltaY < 0) {
            currentFoucsedElem['style']['cursor'] = 'zoom-in'
          } else if (event.deltaY > 0) {
            currentFoucsedElem['style']['cursor'] = 'zoom-out'
          }
          // Set a timeout to run after scrolling ends
          isScrolling = setTimeout(function () {
            // Run the callback
            currentFoucsedElem['style']['cursor'] = 'unset'
          }, 66)
        },
        false
      )

      const observer = new MutationObserver((mutations) => {
        this.checkResize(mutations)
      })
      observer.observe(currentFoucsedElem, {
        attributes: true,
        attributeOldValue: true,
        attributeFilter: ['style'],
      })
    }
  }

  checkResize(mutations) {
    const el = mutations[0].target
    const w = el.clientWidth
    const h = el.clientHeight
    const style = window.getComputedStyle(el)
    // eslint-disable-next-line no-undef
    const matrix = new WebKitCSSMatrix(style.transform)
    // const elTop = matrix.m41
    // const elLeft = matrix.m42
    const eScale = matrix.d

    const isChange = mutations
      .map((m) => `${m.oldValue}`)
      .some(
        (prev) =>
          prev.indexOf(`width: ${w}px`) === -1 ||
          prev.indexOf(`height: ${h}px`) === -1
      )

    if (!isChange) {
      return
    }
    const event = new CustomEvent('resize', { detail: { scale: eScale } })
    el.dispatchEvent(event)
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      asset = {},
      isSecondary = false,
      containerHeight = Number(0),
    } = this.props
    const {
      asset: prevAsset = {},
      containerHeight: prevContainerHeight = Number(0),
    } = prevProps
    const { asset_master_id = '', asset_id = '', render_url = '' } = asset
    const { asset_id: prevAssetId = '', render_url: prevAssetRenderURL = '' } =
      prevAsset
    const { zoomAnnotatorProps: prevZoomAnnotatorProps = {} } = prevState
    const { zoomAnnotatorProps = {} } = this.state

    if (
      containerHeight > Number(0) &&
      !isEqual(containerHeight, prevContainerHeight)
    ) {
      this.setCallback()
    }
    if (!isSecondary) {
      if (
        !isEmpty(asset) &&
        (this.getAssetId() === '' || asset_master_id === this.getAssetId())
      ) {
        this.setState({
          assetObj: asset,
          isLoading: false,
        })
      } else if (
        !isEqual(asset_id, prevAssetId) &&
        !isEqual(render_url, prevAssetRenderURL) &&
        (this.getAssetId() === '' || asset_master_id === this.getAssetId())
      ) {
        this.setState({
          isLoading: true,
          assetClientHeight: 0,
          assetClientWidth: 0,
          isFirstRender: true,
        })
      }
    } else {
      if (
        !isEqual(asset_id, prevAssetId) &&
        !isEqual(render_url, prevAssetRenderURL)
      ) {
        this.setState({
          isLoading: true,
          assetClientHeight: 0,
          assetClientWidth: 0,
          isFirstRender: true,
        })
      }
    }

    if (
      !Object.entries(prevZoomAnnotatorProps).length &&
      Object.entries(zoomAnnotatorProps).length
    ) {
      this.setState({
        doesExistsZoomAnnotatorProps: true,
      })
    }
  }

  getAssetId = () => {
    const pathName = window.location.pathname
    const stringArr = pathName.split('/')
    let assetUnqId = ''
    if (stringArr.length > 7) {
      assetUnqId = stringArr[5] // extracting asset master id from URL
    }
    return assetUnqId
  }

  setCallback = () => {
    const currentFoucsedElemImg = get(
      this.imgRef,
      'current.el.children[0].children[0].children[0]',
      null
    )
    const currentFocusedElem = get(this.imgRef, 'current.el.children[0]', null)
    const currentFocusedElemPaper = get(
      this.imgRef,
      'current.el.children[0].children[0]',
      null
    )
    if (currentFocusedElem) {
      currentFocusedElem['style']['width'] = '100%'
      currentFocusedElem['style']['overflow'] = 'unset'
      currentFocusedElemPaper['style']['display'] = 'inline-block'
    }
    if (currentFoucsedElemImg) {
      currentFoucsedElemImg.onload = this.imgOnLoadCallback
    }
  }

  imgOnLoadCallback = (data = {}) => {
    const { onLoadCallback = () => {} } = this
    return (function () {
      onLoadCallback(data)
    })(data)
  }

  onLoadCallback = (data = {}) => {
    const { path = [], currentTarget } = data
    const img = currentTarget ? currentTarget : path.length > 0 ? path[0] : {}
    const {
      clientHeight = 0,
      clientWidth = 0,
      naturalHeight = 0,
      naturalWidth = 0,
    } = img
    this.setState({
      isLoading: false,
      assetClientHeight: clientHeight,
      assetClientWidth: clientWidth,
      isFirstRender: false,
      naturalHeight,
      naturalWidth,
    })
  }

  onChange = (annotation) => {
    const { currentTool, storeCurrentMarkup } = this.props
    if (!isEmpty(annotation) && currentTool !== 'cursor') {
      storeCurrentMarkup(annotation)
    }
  }

  renderParentComponent = (elemProps = {}) => {
    const { getImageControlHandlers = () => {} } = this.props
    this.setState((state) => {
      getImageControlHandlers(elemProps)
      return {
        ...state,
        zoomAnnotatorProps: Object.assign({}, elemProps),
      }
    })
  }

  render() {
    const {
      currentTool = '',
      scale = 'fit-to-screen',
      currentMarkup = {},
      annotations = [],
      onSubmitAnnotation = () => {},
      numberOfMarkups = 0,
      angle = 0,
      currentPageNumber = 1,
      isSelected = false,
      containerHeight = 0,
      containerWidth = 0,
      isSecondary = false,
      asset = {},
      env = {},
    } = this.props
    const {
      isLoading,
      assetClientHeight,
      assetObj,
      naturalHeight,
      naturalWidth,
      doesExistsZoomAnnotatorProps,
    } = this.state
    const { ZOOM_CONFIG = {} } = env
    const {
      render_urls: { optimized_asset_url = '' } = {},
      asset_name: assetName = '',
    } = assetObj
    const {
      render_urls: { optimized_asset_url: render_url = '' } = {},
      asset_name = '',
    } = asset
    let imageUrl = ''
    let nameOfAsset = !isSecondary ? assetName : asset_name
    const finalRenderUrl = !isSecondary ? optimized_asset_url : render_url
    const { key: apiConfigKey = '' } = apiConfig
    if (!!finalRenderUrl) {
      imageUrl = appendQueryParams(finalRenderUrl, { key: apiConfigKey })
      if (currentPageNumber > 0) {
        imageUrl = appendQueryParams(finalRenderUrl, {
          key: apiConfigKey,
          render_pdf: true,
          asset_page: currentPageNumber + 1,
        })
      }
    }
    let fixedScale = scale
    fixedScale =
      setScale(
        angle,
        scale,
        containerHeight,
        containerWidth,
        naturalHeight,
        naturalWidth,
        assetClientHeight
      ) || 'fit-content'
    return (
      <div
        data-cy="imageAnnotator"
        style={{
          width: fixedScale,
          display: 'inline-block',
        }}
      >
        {isLoading && <StyledCicularProgress size={150} />}
        <Annotation
          zoomConfig={ZOOM_CONFIG}
          src={imageUrl}
          alt={nameOfAsset}
          annotations={annotations}
          value={isSelected ? currentMarkup : {}}
          selectors={[RectangleSelector]}
          onChange={this.onChange}
          onSubmit={() => {}}
          disableSelector={currentTool !== 'square' && !isSelected}
          disableEditor={true}
          disableOverlay={true}
          renderSelector={(data) =>
            renderSelector(data, numberOfMarkups + 1, angle)
          }
          renderHighlight={(data) => renderHighlight(data, angle)}
          renderContent={() => {}}
          onMouseUp={onSubmitAnnotation}
          movingMode={currentTool !== 'square'}
          ref={this.imgRef}
          style={{ visibility: isLoading ? 'hidden' : 'visible' }}
          renderParentComponent={
            doesExistsZoomAnnotatorProps ? () => {} : this.renderParentComponent
          }
        />
      </div>
    )
  }
}

ImageAnnotator.propTypes = {
  imageUrl: PropTypes.string,
  altText: PropTypes.string,
  currentTool: PropTypes.string,
  scale: PropTypes.string,
  currentMarkup: PropTypes.object,
  annotations: PropTypes.array,
  onSubmitAnnotation: PropTypes.func,
  numberOfMarkups: PropTypes.number,
  classes: PropTypes.object,
  storeCurrentMarkup: PropTypes.func,
  angle: PropTypes.number,
  isSecondary: PropTypes.bool,
  asset: assetPropType,
  currentPageNumber: PropTypes.number,
  isSelected: PropTypes.bool,
  containerHeight: PropTypes.number,
  containerWidth: PropTypes.number,
}

const mapStateToProps = (state = {}, props = {}) => {
  const { isSecondary } = props
  const { annotation = {} } = state
  const { currentMarkup, primaryPageNumber, secondaryPageNumber } = annotation
  const primaryAsset = selectAssetVersion()(state)
  const secondaryAsset = selectAssetVersion(true)(state)

  const currentPageNumber = !isSecondary
    ? primaryPageNumber - 1
    : secondaryPageNumber - 1
  const asset = !isSecondary ? primaryAsset : secondaryAsset

  return {
    currentMarkup,
    currentPageNumber,
    asset,
  }
}

export default connect(mapStateToProps, { storeCurrentMarkup })(
  withEnv()(ImageAnnotator)
)
