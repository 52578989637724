import React from 'react'
import PropTypes from 'prop-types'
import {
  Checkbox,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from '@mui/material'

class AHAssetTableHead extends React.Component {
  createSortHandler = (property) => (event) => {
    this.props.onRequestSort(event, property)
  }

  render() {
    const { order, orderBy, headingList, isAllAssetChecked } = this.props

    return (
      <TableHead>
        <TableRow>
          <TableCell>
            <Checkbox
              data-cy="selectAllAssets"
              checked={isAllAssetChecked}
              onChange={(event) =>
                this.props.handleSelectAll(event.target.checked)
              }
              id="select-all-assets"
            />
          </TableCell>
          {headingList.map(
            (column) => (
              <TableCell
                key={column.id}
                numeric={column.numeric}
                padding={column.disablePadding ? 'none' : 'normal'}
                sortDirection={orderBy === column.id ? order : false}
                testid={column.id}
              >
                {column.sortable ? (
                  <Tooltip
                    title="Sort"
                    placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                    enterDelay={300}
                  >
                    <TableSortLabel
                      data-cy={column.id}
                      active={orderBy === column.id}
                      direction={order}
                      onClick={this.createSortHandler(column.id)}
                    >
                      {column.label}
                    </TableSortLabel>
                  </Tooltip>
                ) : (
                  <div>{column.label}</div>
                )}
              </TableCell>
            ),
            this
          )}
        </TableRow>
      </TableHead>
    )
  }
}

AHAssetTableHead.propTypes = {
  isAllAssetChecked: PropTypes.bool,
  handleSelectAll: PropTypes.func,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  headingList: PropTypes.array,
}

export default AHAssetTableHead
