import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import cx from 'classnames'
import { Modal, Grid, CircularProgress } from '@mui/material'

import {
  getFromBrowserStorage,
  setInBrowserStorage,
} from '../../../../helpers/UtilityHelper'
import {
  closeModal,
  prevAsset,
  nextAsset,
} from '../../../../store/publish/actions'

import { SIDEBAR_WIDTH } from '../../../../constants/publishConstants'
import {
  getIsModalOpen,
  getIsFetchingAssets,
} from '../../../../store/publish/selectors'

import ImageCarousel from './ImageCarousel'
import ImageContainerHeader from './ImageContainerHeader'
import MetadataBar from './MetadataBar'

const styles = makeStyles((theme) => ({
  lightBoxWrapper: {
    height: '95%',
    backgroundColor: useTheme().palette.background.paper,
    boxShadow: useTheme().shadows[5],
    padding: 0,
    textAlign: 'left',
    position: 'relative',
    transition: useTheme().transitions.create(['margin', 'width'], {
      easing: useTheme().transitions.easing.sharp,
      duration: useTheme().transitions.duration.leavingScreen,
    }),
  },
  lightBoxWrapperShift: {
    width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
    marginRight: SIDEBAR_WIDTH,
    transition: useTheme().transitions.create(['margin', 'width'], {
      easing: useTheme().transitions.easing.easeOut,
      duration: useTheme().transitions.duration.enteringScreen,
    }),
  },
  progress: {
    margin: '100px auto',
  },
}))

const Loading = ({ classes = {} }) => (
  <Grid
    container
    spacing={4}
    direction={'row'}
    justifyContent={'center'}
    alignItems={'center'}
  >
    <Grid item xs={12} style={{ textAlign: 'center' }}>
      <CircularProgress className={classes.progress} />
    </Grid>
  </Grid>
)

const PreviewModal = ({
  classes = {},
  nextAsset = () => {},
  prevAsset = () => {},
  closeModal = () => {},
  isModalOpen = false,
  history = {},
  router = {},
  isFetchingAssets = false,
}) => {
  const [sidebarOpen, setSidebarOpen] = useState(
    getFromBrowserStorage('ah_metadata_sidebar_open') === 'true'
  )

  useEffect(() => {
    const onArrowKeyPress = (event) => {
      const leftArrow = 37
      const rightArrow = 39
      const { keyCode: eventKeyCode = Number(0) } = event
      if (eventKeyCode === leftArrow) {
        // prevAsset(history)
        prevAsset(router)
      } else if (eventKeyCode === rightArrow) {
        // nextAsset(history)
        nextAsset(router)
      }
    }
    document.addEventListener('keydown', onArrowKeyPress)
    // return function gets called when the component unmounts
    return () => document.removeEventListener('keydown', onArrowKeyPress)
  }, [nextAsset, prevAsset])

  const handleSidebarOpen = () => {
    setSidebarOpen(true)
    setInBrowserStorage('ah_metadata_sidebar_open', 'true')
  }
  const handleSidebarClose = () => {
    setSidebarOpen(false)
    setInBrowserStorage('ah_metadata_sidebar_open', 'false')
  }

  return (
    <>
      {isFetchingAssets ? (
        <Loading classes={classes} />
      ) : (
        <Modal open={isModalOpen} onClose={() => closeModal()}>
          <div style={{ display: 'flex' }}>
            <Grid
              container
              spacing={2}
              className={cx(classes.lightBoxWrapper, {
                [classes.lightBoxWrapperShift]: sidebarOpen,
              })}
            >
              <ImageContainerHeader
                openDrawerHandler={handleSidebarOpen}
                isSidebarOpen={sidebarOpen}
                history={history}
                router={router}
              />
              <ImageCarousel history={history} router={router} />
            </Grid>
            {sidebarOpen && <MetadataBar close={handleSidebarClose} />}
          </div>
        </Modal>
      )}
    </>
  )
}

PreviewModal.propTypes = {
  classes: PropTypes.object.isRequired,
  nextAsset: PropTypes.func.isRequired,
  prevAsset: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  isModalOpen: getIsModalOpen(state),
  isFetchingAssets: getIsFetchingAssets(state),
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      closeModal,
      nextAsset,
      prevAsset,
    },
    dispatch
  )

const MyComponent = (props) => {
  const classes = styles()
  return <PreviewModal {...props} classes={classes} />
}

export default connect(mapStateToProps, mapDispatchToProps)(MyComponent)
