import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { each, get } from 'lodash'

import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import Button from '@mui/material/Button'
import Avatar from '@mui/material/Avatar'
import Paper from '@mui/material/Paper'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Card from '@mui/material/Card'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp'
import IconButton from '@mui/material/IconButton'
import Grid from '@mui/material/Grid'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import Chip from '@mui/material/Chip'
import TextField from '@mui/material/TextField'
import Moment from 'moment'
import momentTime from 'moment-timezone'
import DeleteIcon from '@mui/icons-material/Delete'
import AddBoxIcon from '@mui/icons-material/AddBox'
import PeopleIcon from '@mui/icons-material/People'
import EditIcon from '@mui/icons-material/Edit'
import apiConfig from '../../config/apiConfig'
import TaskNotificationsForm from './TaskNotificationsForm'
import dividerImage from '../../images/divider.png'
import {
  REVIEW_TASK_TYPE_ID,
  TASK_TYPE_PARAM_ID_MAP,
  UPLOAD_TASK_TYPE_ID,
} from '../../constants/projectTask'
import { getTaskEmailRememberNote } from '../../helpers/TaskHelper'

const styles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: useTheme().spacing(3),
    overflowX: 'auto',
  },
  avatar: {
    backgroundColor: '#FFF',
  },
  avatarIcon: {
    color: '#8D8D8D',
  },
  Label: {
    width: 70,
    paddingRight: 10,
    fontSize: 16,
    color: '#8D8D8D',
  },
  EditLabel: {
    width: 70,
    paddingRight: 10,
    fontSize: 16,
    color: '#0D46A0',
  },
  buttonBlue: {
    backgroundColor: '#EFEFEF',
    color: '#0D46A0',
    '&:hover': {
      backgroundColor: '#EFEFEF',
    },
  },
  buttonGey: {
    backgroundColor: '#FAFAFA',
    color: '#212121',
    '&:hover': {
      backgroundColor: '#FAFAFA',
    },
  },
  unSaveNotification: {
    backgroundColor: '#FFE57F',
    border: '1px solid #EFEFEF',
    borderBottom: 0,
    minHeight: 50,
  },
  saveNotification: {
    backgroundColor: '#EFF7EF',
    border: '1px solid #EFEFEF',
    borderBottom: 0,
    minHeight: 50,
  },
  seprate: {
    padding: '0 3px 0 9px;',
  },
  mdSubhead: {
    fontSize: 18,
    fontWeight: 400,
    color: '#212121',
  },
  notificationSave: {
    color: '#4CAF50',
    fontWeight: 400,
  },
  notificationWarning: {
    color: '#CAB350',
    fontWeight: 400,
  },
  statusIcon: {
    marginRight: 5,
    top: 6,
    position: 'relative',
  },
  emptyWrapper: {
    border: '2px dashed #c5c5c5',
    textAlign: 'center',
    margin: -5,
    marginTop: 20,
    minHeight: 200,
    color: '#8D8D8D',
  },
  divider: {
    width: '100%',
    maxWidth: '100%',
    padding: 20,
  },
  IconGey: {
    borderRadius: '50%',
    backgroundColor: '#8D8D8D',
    color: '#D6D6D6',
  },
  IconYellow: {
    borderRadius: '50%',
    backgroundColor: '#FFE57F',
  },
  leftIcon: {
    marginRight: 4,
  },
}))

export class TaskCurrentList extends React.Component {
  constructor(props) {
    super(props)
    const { info = {} } = this.props
    const { queryParams = {} } = info
    const taskTypeId = TASK_TYPE_PARAM_ID_MAP[queryParams.taskType]
    const isUploadTask = taskTypeId === UPLOAD_TASK_TYPE_ID
    let quillConfig = {
      toolbar: [
        [
          'bold',
          'italic',
          'underline',
          'strike',
          { list: 'bullet' },
          { list: 'ordered' },
        ],
        [
          { align: [] },
          { align: 'center' },
          { align: 'right' },
          { align: 'justify' },
          { indent: '-1' },
          { indent: '+1' },
        ],
        [{ color: [] }, { background: [] }],
        ['link'],
        ['clean'],
      ],
    }
    let defaultVal = [
      {
        first_name: props.auth.firstName,
        last_name: props.auth.lastName,
        display_name: props.auth.email.split('@')[0],
        login_id: props.auth.lanId,
        user_id: 0,
        email_address: props.auth.email,
        title: null,
        organization: null,
        user_image: null,
        group_id: null,
        num_of_users: 0,
        public: false,
      },
    ]
    let from =
      props.data.project.type === 'Weekly Ad'
        ? 'assethub-donotreply@target.com'
        : props.auth.email
    let taskCDueDate = momentTime.tz(
      props.data.taskFormData.scheduledDate +
        ' ' +
        props.data.taskFormData.scheduledTime,
      'America/chicago'
    )
    let shortDate = Moment(taskCDueDate).format('MM/DD/YY hh:mm A')
    let sentToUsers = props.data.sentList
    let dummyUser = {
      count: 0,
      id: 1,
      name: 'All Reviewers And Observers',
      showCnt: true,
      text: 'All Reviewers & Observers on this task.',
    }
    let allSentList = this.props.data.allSentList
    let currentParticipants = get(
      this.props,
      'info.task.current.participants',
      []
    )
    let taskPDueDate = momentTime.tz(
      get(this.props, 'info.editTaskInfo.due_date', ''),
      'America/chicago'
    )
    let duration = taskCDueDate.diff(taskPDueDate)
    let allParticipants = allSentList.filter(
      (a) => a.name === 'All Participants' && a.count !== 0
    )
    let onlyReviewersAndObservers = allSentList.filter(
      (a) => a.name === 'All Reviewers And Observers' && a.count !== 0
    )
    let Reviewer =
      currentParticipants &&
      currentParticipants.filter((a) => a.role === 'Reviewer' && a.count !== 0)
    let cntReviewers = this.getCntUser(Reviewer) //All Reviewers
    let isNewReviewerAdded = false
    let isDueDateChanged = false
    let isNewAsset = false
    let filteredUser = {}
    let changedOptions = ''
    let vmType = ''
    let sendTo = ''
    if (this.props.info.isEdit) {
      let PastReviewers = get(
        this.props,
        'info.task.default.participants',
        []
      ).filter((a) => a.role === 'Reviewer')
      let cntPastReviewers = this.getCntUser(PastReviewers)
      // new reviewer added
      if (cntReviewers > cntPastReviewers) isNewReviewerAdded = true
      //Duedate change
      if (duration && duration > 0) isDueDateChanged = true
      //New Asset Added
      if (
        get(this.props, 'info.task.current.assets', []).filter(
          (a) => a.isChecked === true
        ).length >
        get(this.props, 'info.task.default.assets', []).filter(
          (a) => a.isChecked === true
        ).length
      ) {
        isNewAsset = true
      }

      if (isNewAsset || isDueDateChanged || isNewReviewerAdded) {
        vmType = 'Task Has Changed'
        if (isNewReviewerAdded) changedOptions += 'New Reviewers Were Added,'
        if (isNewAsset) changedOptions += 'New Assets Were Added,'
        if (isDueDateChanged) changedOptions += 'Due Date Has Changed,'
        changedOptions = changedOptions.replace(/,\s*$/, '')
      } else {
        vmType = 'New Task'
      }
      if (isNewReviewerAdded) {
        filteredUser = allSentList.filter(
          (a) => a.name === 'New Reviewers' && a.count !== 0
        )
      }
    }
    if (!isUploadTask && this.props.info.project.type === 'Weekly Ad') {
      let weeklySentTo
      let reviewers = allSentList.filter(
        (a) => a.name === 'All Reviewers' && a.count !== 0
      )
      if (
        (isNewAsset && isDueDateChanged && isNewReviewerAdded) ||
        (isNewAsset && isNewReviewerAdded)
      ) {
        weeklySentTo = reviewers
      } else if (isNewReviewerAdded) {
        weeklySentTo = allSentList.filter(
          (a) => a.name === 'New Reviewers' && a.count !== 0
        )
      } else if (reviewers.length) {
        weeklySentTo = reviewers
      } else if (!reviewers.length) {
        weeklySentTo = allSentList.filter(
          (a) => a.name === 'All Admins' && a.count !== 0
        )
      } else {
        weeklySentTo = allParticipants
      }
      sendTo = weeklySentTo
    } else if (filteredUser && filteredUser.length >= 1) {
      sendTo = filteredUser
    } else if (onlyReviewersAndObservers.length) {
      sendTo = onlyReviewersAndObservers
    } else {
      sendTo = allParticipants
    }

    let subject =
      'Feedback due: ' +
      shortDate +
      ' CST - ' +
      props.data.project.name +
      ' - ' +
      props.data.taskFormData.taskName
    sendTo = sendTo[0]
      ? allSentList.filter((a) => a.name === sendTo[0].name && a.count !== 0)
      : []
    this.state = {
      autoReminder: !this.props.info.isEdit || false,
      quillConfig: quillConfig,
      defaultVal: defaultVal,
      text: '',
      subject: props.subject || subject,
      taskType: vmType || 'New Task',
      taskName: props.data.taskFormData.taskName,
      projectName: props.data.project.name,
      projectType: props.data.project.type,
      projectUuid: props.data.project.projectUuid,
      scheduledDate: props.data.taskFormData.scheduledDate,
      scheduledTime: props.data.taskFormData.scheduledTime,
      openNotificationForm: false,
      sentToUsers,
      sendTo,
      taskTypeId,
    }
    let emailProps = this.buildEmailBody(
      vmType,
      (changedOptions && changedOptions.split(',')) || [],
      taskTypeId
    )
    let notification = [
      {
        isExpanded: true,
        send: 'Now',
        cc: true,
        showCC: true,
        from: from,
        sentTo: sendTo[0] ? sendTo[0] : dummyUser,
        changed: (changedOptions && changedOptions.split(',')) || [],
        type: vmType || 'New Task',
        scheduleDate: '',
        text: emailProps['text'] || '',
        subject: emailProps['subject'] || subject || '',
        accType: 'new',
        taskType: emailProps['taskType'] || vmType || 'New Task',
        taskTypeId,
      },
    ]
    this.state.notifications = notification
    this.state.defaultNotification = notification[0]
    this.state.editFormNotification = notification[0]
    this.openTaskFrom = this.openTaskFrom.bind(this)
    this.closeTaskFrom = this.closeTaskFrom.bind(this)
    this.onPanelExpand = this.onPanelExpand.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.handleTaskForm = this.handleTaskForm.bind(this)
  }
  handleTaskForm(data, autoReminder = false) {
    if (data) {
      let notifications =
        this.state.notifications &&
        this.state.notifications.map((el) =>
          this.state.editFormNotification &&
          this.state.editFormNotification === el &&
          data &&
          data[0]
            ? data[0]
            : el
        )
      let notificationsData =
        notifications && notifications.length
          ? notifications
          : Object.assign([], this.state.notifications, data)
      let isReminder =
        typeof autoReminder !== 'undefined' && autoReminder !== null
          ? autoReminder
          : this.state.autoReminder
      if (isReminder) {
        notificationsData = [...this.state.notifications, ...data]
      }
      this.setState(
        {
          ...this.state,
          openNotificationForm: false,
          notifications: notificationsData,
          autoReminder: false,
          taskType: data && data[0].type,
        },
        () => {
          this.props.stepper(this.state)
        }
      )
    }
  }
  getCntUser = function (userObj, taskStaus) {
    let totalUsers = 0
    let personCount
    each(userObj, function (person) {
      if (
        taskStaus &&
        (person.task_status === taskStaus || !person.task_status)
      ) {
        personCount =
          person.group_id === null
            ? 1
            : person.total_tasks
            ? person.total_tasks
            : person.num_of_users
            ? person.num_of_users
            : 0
        totalUsers = totalUsers + personCount
      } else if (!taskStaus) {
        personCount =
          person.group_id === null
            ? 1
            : person.total_tasks
            ? person.total_tasks
            : person.num_of_users
            ? person.num_of_users
            : 0
        totalUsers = totalUsers + personCount
      }
    })
    return totalUsers
  }
  componentDidMount() {
    this.props.stepper(this.state)
  }
  handleChange = (name, element) => (value) => {
    let stateVal = typeof value === 'object' ? value.target.value : value
    let notifications = this.state.notifications || null
    let stateChanged = Number(0)
    if (
      (typeof value === 'string' && name === 'text') ||
      (typeof value === 'object' && notifications && this.state.notifications)
    ) {
      notifications = this.state.notifications.map((el) => {
        if (el === element && el[name] !== stateVal) {
          stateVal = stateVal.replace(/(script(.*?)\/script)/gi, '')
          stateChanged++
          el[name] = stateVal
        }
        return el
      })
      if (stateChanged > Number(0)) {
        this.setState({
          ...this.state,
          notifications: notifications,
        })
      }
    }
  }

  buildEmailBody(
    taskType = 'New Task',
    changed = '',
    taskTypeId = REVIEW_TASK_TYPE_ID
  ) {
    let linkStyle =
      'padding: 10px; color: white; background-color: rgb(143, 28, 32); display: inline-block; font-size: 12px; cursor: pointer; text-decoration: none;'
    let cont = this.dynamicContent(taskType, changed, taskTypeId)
    const { taskName = '' } = this.state
    const isUploadTask = taskTypeId === UPLOAD_TASK_TYPE_ID
    const linkLabel = isUploadTask ? 'Open Project' : 'Launch Review'
    const projectId =
      get(this.props, 'projectId', '') ||
      get(this.props, 'task.taskList.userAssets.project_uuid', '') ||
      get(this.props, 'info.project.projectUuid', '')
    let reviewLink = `/project/${projectId}/tasks/list`

    if (taskTypeId === REVIEW_TASK_TYPE_ID) {
      const jobId =
        get(this.props, 'info.editTaskInfo.job_id', '') || 'New_Task_Id'
      reviewLink = `/annotator/${projectId}/${jobId}`
    } else if (taskTypeId === UPLOAD_TASK_TYPE_ID) {
      reviewLink = `/project/${projectId}/dashboard`
    }

    let projectLink = `${apiConfig.assethub.host}${reviewLink}`
    let headerTxt = '<p>Hello,</p><p>&nbsp;</p>'
    headerTxt += cont.emailHead + '<p><br></p>'
    headerTxt += `<p><a target="_blank" style="${linkStyle}" href="${projectLink}">${linkLabel}</a></p><p><br></p>`
    let bodyTxt = ''
    if (isUploadTask) {
      bodyTxt =
        '<p><b>How to Upload</b></p><p><span>&nbsp;&bull; Open Project</span></p><p><span>&nbsp;&bull; Drag and drop your assets into asset card</span></p>'
    } else {
      bodyTxt += getTaskEmailRememberNote(taskName)

      bodyTxt += '<p><br></p><p><b>Need Help?</b></p>'
      bodyTxt +=
        '<p><span>&nbsp;&bull; Tool questions & issues: Submit a ticket using the help form in AssetHub.</span></p>'
      bodyTxt +=
        '<p><span>&nbsp;&bull; Tool access issues: Email <a hre  f="mailto:AssetHub@Target.com?Subject=Need Help?" target="_top">AssetHub@Target.com</a></span></p>'
      bodyTxt +=
        '<p><span>&nbsp;&bull; Questions or issues with content, timing, or business process: Please contact the appropriate Project Manager for that channel/project.</span></p>'
    }
    let footerTxt =
      '<p><br></p><p><span>Thank you!</span></p><p><span>' +
      this.props.auth.firstName +
      '</span></p><p><br></p>'
    let msg = headerTxt + bodyTxt + footerTxt
    return { text: msg, subject: cont.subject, taskType: cont.taskType }
  }
  getSelectedAssetCount() {
    return this.props.data.assetsCnt
  }
  dynamicContent = function (
    type = 'New Task',
    changed = '',
    taskTypeId = REVIEW_TASK_TYPE_ID
  ) {
    let dynamice = { emailHead: '', subject: '' }
    let scheduled = Moment(
      this.props.data.taskFormData.scheduledDate +
        ' ' +
        this.props.data.taskFormData.scheduledTime
    )
    let shortDate = Moment(scheduled).format('M/D/YY hh:mm A')
    let taskType = taskTypeId === UPLOAD_TASK_TYPE_ID ? 'Upload Task' : type
    if (type === 'Task Has Changed') {
      let frmChanged = changed ? changed.sort().toString() : ''
      switch (frmChanged) {
        case 'New Reviewers Were Added':
          taskType = type
          break
        case 'New Assets Were Added':
        case 'New Assets Were Added,New Reviewers Were Added':
          taskType = 'Edit Task - New Assets'
          break
        case 'Due Date Has Changed':
        case 'Due Date Has Changed,New Reviewers Were Added':
          taskType = 'Edit Task - Due Date'
          break
        case 'Due Date Has Changed,New Assets Were Added':
        case 'Due Date Has Changed,New Assets Were Added,New Reviewers Were Added':
          taskType = 'Edit Task - Due Date and Additional Assets'
          break
        default:
          taskType = type
          break
      }
    }
    switch (taskType) {
      case 'New Task':
        dynamice.emailHead =
          '<p>There are ' +
          this.getSelectedAssetCount() +
          ' ' +
          this.state.taskName +
          '  files for ' +
          this.state.projectName +
          ' ready for you to review.</p> <p>Changes/approvals must be posted electronically on the proofs by ' +
          shortDate +
          ' CST.</p>'
        dynamice.subject =
          'Feedback due: ' +
          shortDate +
          ' CST - ' +
          this.state.projectName +
          ' - ' +
          this.state.taskName
        break
      case 'Edit Task - Due Date':
        dynamice.emailHead =
          '<p>The due date for the  ' +
          this.state.taskName +
          '  task for  ' +
          this.state.projectName +
          '  has been updated to ' +
          shortDate +
          ' CST.</p> <p>Changes/approvals must be posted electronically on the proofs by this date.</p>'
        dynamice.subject =
          'Feedback due: ' +
          shortDate +
          ' CST - ' +
          this.state.projectName +
          ' - ' +
          this.state.taskName
        break
      case 'Edit Task - New Assets':
        dynamice.emailHead =
          '<p>There are ' +
          this.getSelectedAssetCount() +
          ' additional  ' +
          this.state.taskName +
          '  files added for  ' +
          this.state.projectName +
          ' .</p> <p>Changes/approvals must be posted electronically on the proofs by ' +
          shortDate +
          ' CST.</p>'
        dynamice.subject =
          'Feedback due: ' +
          shortDate +
          ' CST - ' +
          this.state.projectName +
          ' - ' +
          this.state.taskName
        break
      case 'Edit Task - Due Date and Additional Assets':
        dynamice.emailHead =
          '<p>' +
          this.getSelectedAssetCount() +
          ' additional  ' +
          this.state.taskName +
          '  files have been added for  ' +
          this.state.projectName +
          '  and the due date has been updated to ' +
          shortDate +
          ' CST.</p>'
        dynamice.subject =
          'Feedback due: ' +
          shortDate +
          ' CST - ' +
          this.state.projectName +
          ' - ' +
          this.state.taskName
        break
      case 'Task Completion':
        dynamice.emailHead =
          '<p>' +
          this.props.auth.firstName +
          ' has completed the  ' +
          this.state.taskName +
          '  task for  ' +
          this.state.projectName +
          ' .</p>'
        dynamice.subject =
          'Task Complete - ' +
          this.state.projectName +
          ' -' +
          this.state.taskName
        break
      case 'Reminder':
        dynamice.emailHead =
          '<p>You have not yet completed the  ' +
          this.state.taskName +
          ' task for ' +
          this.state.projectName +
          ' .</p><p>  Changes/approvals must be posted electronically on the proofs by ' +
          shortDate +
          ' CST.<p>'
        dynamice.subject =
          'Reminder - Feedback due: ' +
          shortDate +
          ' - ' +
          this.state.projectName +
          ' - ' +
          this.state.taskName
        break
      case 'Upload Task':
        dynamice.emailHead = `<p>You have been requested to upload assets to the project ${this.state.projectName} by  ${shortDate} CST.</p>`
        dynamice.subject = `Upload Request: Due ${shortDate} CST -  ${this.state.projectName} - ${this.state.taskName}`
        break
      default:
        dynamice.emailHead =
          '<p>There are ' +
          this.getSelectedAssetCount() +
          ' ' +
          this.state.taskName +
          '  files for  ' +
          this.state.projectName +
          '  ready for you to review.</p><p> Changes/approvals must be posted electronically on the proofs by ' +
          shortDate +
          ' CST.</p>'
        dynamice.subject =
          'Feedback due: ' +
          shortDate +
          ' CST - ' +
          this.state.projectName +
          ' - ' +
          this.state.taskName
        break
    }
    dynamice.taskType = taskType
    return dynamice
  }

  initial(email) {
    const name = email.substring(0, email.lastIndexOf('@'))
    const split = name.toLowerCase().split('.')
    const fname = split[0].charAt(0).toUpperCase()
    const lname = split[1]
      ? split[1].charAt(0).toUpperCase()
      : split[0].charAt(1).toUpperCase()
    return fname + lname
  }
  handleClick(name, notification) {
    switch (name) {
      case 'Save': {
        this.onNotificationSave(notification)
        break
      }
      case 'Discard': {
        this.onNotificationDiscard(notification)
        break
      }
      case 'EDIT': {
        this.onNotificationEdit(notification)
        break
      }
    }
  }
  openTaskFrom(data = {}) {
    this.setState({
      openNotificationForm: true,
      editFormNotification: data || this.state.defaultNotification,
    })
  }

  closeTaskFrom() {
    this.setState({ openNotificationForm: false })
    this.handleTaskForm(false)
  }
  onPanelExpand(notification) {
    this.setState({
      shown: {
        ...this.state.notification,
        isExpanded: (notification.isExpanded = !notification.isExpanded),
      },
    })
  }
  onNotificationDiscard(notification) {
    if (notification && this.state.notifications) {
      this.setState(
        {
          notifications: this.state.notifications.filter(
            (el) => el !== notification
          ),
        },
        () => {
          this.props.stepper(this.state)
        }
      )
    }
  }
  onNotificationSave = function () {
    this.setState(
      {
        shown: {
          ...this.state.notification,
        },
      },
      () => {
        this.props.stepper(this.state)
      }
    )
  }

  onNotificationEdit = function (notification) {
    this.setState(
      {
        shown: {
          ...this.state.notification,
          isExpanded: (notification.isExpanded = true),
        },
      },
      () => {
        this.props.stepper(this.state)
      }
    )
  }
  render() {
    const { classes = {} } = this.props
    const { taskTypeId = REVIEW_TASK_TYPE_ID } = this.state
    const isUploadTask = taskTypeId === UPLOAD_TASK_TYPE_ID
    return (
      <div>
        {this.state.notifications.length >= 1 ? (
          <Paper style={{ boxShadow: 'none' }}>
            {this.state.notifications.map((nf, index) => {
              let accType = nf.accType || 'no'
              return (
                <Card spacing={1} key={index} style={{ marginBottom: 10 }}>
                  <CardHeader
                    action={
                      <div>
                        <Button
                          data-cy="removeNotificationButton"
                          onClick={() => {
                            this.handleClick('Discard', nf)
                          }}
                          className={classes.buttonBlue}
                          color="primary"
                          variant="contained"
                          style={{ marginRight: 10 }}
                        >
                          <DeleteIcon style={{ marginRight: 10 }} /> Remove
                          Notification
                        </Button>
                        {nf.isExpanded ? (
                          <IconButton
                            aria-label="Expand"
                            onClick={() => {
                              this.onPanelExpand(nf)
                            }}
                            size="large"
                          >
                            <KeyboardArrowUp />
                          </IconButton>
                        ) : (
                          <IconButton
                            aria-label="Collapse"
                            onClick={() => {
                              this.onPanelExpand(nf)
                            }}
                            size="large"
                          >
                            {' '}
                            <KeyboardArrowDown />
                          </IconButton>
                        )}
                      </div>
                    }
                    title={
                      <div className={classes.mdSubhead}>
                        {this.state.isEditMode ? (
                          <span> Edit Task </span>
                        ) : nf.type &&
                          nf.type === 'Reminder' &&
                          accType === 'auto' ? (
                          <span> Auto Reminder </span>
                        ) : (
                          <span> Recommended Task Notification </span>
                        )}
                        {/*<span className={classes.seprate}>|</span>*/}
                      </div>
                    }
                    className={classes.saveNotification}
                  />
                  {nf.isExpanded && (
                    <Paper style={{ boxShadow: 'none' }}>
                      <CardContent
                        className={classes.cardCont}
                        style={{ backgroundColor: 'rgba(239,239,239,.25)' }}
                      >
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                          spacing={1}
                        >
                          <Grid item className={classes.Label}>
                            Send :
                          </Grid>
                          <Grid item sm style={{ marginLeft: 10 }}>
                            {nf.send !== 'Schedule Future Day & Time' ? (
                              <Chip
                                label={nf.send}
                                className={classes.chip}
                                variant="outlined"
                              />
                            ) : (
                              <span>
                                <Chip
                                  label={`${momentTime(nf.scheduleDate).format(
                                    'M/D/YYYY hh:mm:A'
                                  )}`}
                                  className={classes.chip}
                                  variant="outlined"
                                />{' '}
                                CST
                              </span>
                            )}
                          </Grid>
                          <Grid item xs={12} />
                          <Grid item className={classes.Label}>
                            To :
                          </Grid>
                          <Grid item sm style={{ marginLeft: 10 }}>
                            <Chip
                              className={classes.chipName}
                              label={`${nf.sentTo.name}| ${nf.sentTo.count} people`}
                              avatar={
                                <PeopleIcon className={classes.IconGey} />
                              }
                            />
                          </Grid>
                          <Grid item xs={12} />
                          {nf.showCC === true && (
                            <Grid item className={classes.Label}>
                              CC :
                            </Grid>
                          )}
                          {nf.showCC === true && (
                            <Grid item sm style={{ marginLeft: 10 }}>
                              <Chip
                                className={classes.chipName}
                                label={this.state.defaultVal[0].email_address}
                                avatar={
                                  <Avatar className={classes.IconYellow}>
                                    {this.initial(
                                      this.state.defaultVal[0].email_address
                                    )}
                                  </Avatar>
                                }
                              />
                            </Grid>
                          )}
                          <Grid item xs={12} />
                          <Grid item className={classes.Label}>
                            From :
                          </Grid>
                          <Grid item sm style={{ marginLeft: 10 }}>
                            <Chip
                              className={classes.chipName}
                              label={nf.from}
                              avatar={
                                <Avatar className={classes.IconYellow}>
                                  {this.initial(nf.from)}
                                </Avatar>
                              }
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                      <CardContent className={classes.cardCont}>
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="flex-start"
                          spacing={1}
                        >
                          {!isUploadTask && (
                            <Grid item xs={12}>
                              <Button
                                onClick={() => this.openTaskFrom(nf)}
                                color="primary"
                                className={classes.buttonBlue}
                                variant="contained"
                              >
                                <EditIcon className={classes.leftIcon} /> EDIT
                              </Button>
                            </Grid>
                          )}

                          <Grid
                            item
                            className={classes.EditLabel}
                            style={{ marginTop: 20 }}
                          >
                            Subject :
                          </Grid>
                          <Grid item sm style={{ marginLeft: 3 }}>
                            <TextField
                              variant="standard"
                              onChange={this.handleChange('subject', nf)}
                              margin="normal"
                              type="search"
                              value={nf.subject}
                              className={classes.formControl}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} />
                          <Grid
                            item
                            className={classes.EditLabel}
                            style={{ marginTop: 7 }}
                          >
                            Body :
                          </Grid>
                          <Grid item sm className="notify-email-body">
                            <ReactQuill
                              value={nf.text}
                              defaultValue={nf.text}
                              onChange={this.handleChange('text', nf)}
                              modules={this.state.quillConfig}
                            />
                          </Grid>
                          <Grid item xs={12} />
                        </Grid>
                      </CardContent>
                    </Paper>
                  )}
                </Card>
              )
            })}
          </Paper>
        ) : (
          <div className={classes.emptyWrapper}>
            <p>No notifications are scheduled to be sent for this task.</p>
            <div className={classes.divider}>
              <img
                className={classes.assetDivider}
                src={dividerImage}
                alt="no tasks"
              />
            </div>
            <Button
              data-cy="scheduleANotification"
              onClick={() => this.openTaskFrom()}
              color="secondary"
              variant="contained"
            >
              <AddBoxIcon style={{ marginRight: 10 }} /> SCHEDULE A NOTIFICATION
            </Button>
          </div>
        )}
        {!isUploadTask && (
          <TaskNotificationsForm
            open={this.state.openNotificationForm}
            editFormNotification={this.state.editFormNotification}
            onClose={this.closeTaskFrom}
            {...this.state}
            {...this.props}
            handleTaskForm={this.handleTaskForm.bind(this)}
            buildEmailBody={this.buildEmailBody.bind(this)}
            taskTypeId={taskTypeId}
          />
        )}
      </div>
    )
  }
}

TaskCurrentList.propTypes = {
  data: PropTypes.object,
  auth: PropTypes.object,
  classes: PropTypes.object,
  info: PropTypes.object,
  subject: PropTypes.object,
  stepper: PropTypes.func,
}

const mapStateToProps = (state) => {
  const auth = state.auth
  return {
    auth,
  }
}

const MyComponent = (props) => {
  const classes = styles()
  return <TaskCurrentList {...props} classes={classes} />
}

export default connect(mapStateToProps)(MyComponent)
