const merge = require('lodash/merge')

const CommonConfig = {
  remotes: {
    // ahsearch: 'assethub_search@//localhost:8018/remoteEntry.js', //child site remote entry - localhost
    ahsearch:
      'assethub_search@https://ahsearchui.dev.target.com/remoteEntry.js', //child site remote entry - tapdev
  },
}

const envConfigs = {
  dev: {
    remotes: {
      ahsearch:
        'assethub_search@https://ahsearchui.dev.target.com/remoteEntry.js',
    },
  },
  perf: {
    remotes: {
      ahsearch:
        'assethub_search@https://ahsearchui-perf.dev.target.com/remoteEntry.js',
    },
  },
  prod: {
    remotes: {
      ahsearch:
        'assethub_search@https://ahsearchui.prod.target.com/remoteEntry.js',
    },
  },
}

const appEnv = process.env.REACT_APP_ENV || 'dev'
const config = envConfigs[appEnv]

// commonConfig and the config for the matching REACT_APP_ENV are combined.
// Values in the environment-specific config will overwrite commonConfig keys
// if they share the same name.
const MFEConfig = merge(CommonConfig, config)

// The following code block is purely for demonstration purposes and can be removed.
if (process.env.NODE_ENV === 'development') {
  console.log(`${appEnv} ENV mfeConfig:`, MFEConfig)
}
export default MFEConfig
