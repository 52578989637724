import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import { Helmet } from 'react-helmet'
import { Card, CardContent } from '@mui/material'
import { useAuth } from '@praxis/component-auth'
import { useEnv } from '@praxis/component-runtime-env'
import HeaderTitle from '../../Header/HeaderTitle'
import HeaderTabs from '../Search/HeaderTabs'
import { requestAssetsEvent } from '../../../store/publishUpload/action'
// Core Files
import PublishTable from './PublishTable'
import ImageUploader from './ImageUploader'
import InfoPage from '../InfoPage'

const styles = makeStyles((theme) => ({
  root: {
    padding: '0 8px',
  },
  LIText: {
    flex: 'none !important',
    minWidth: '50%',
    width: '100px',
    overflowWrap: 'break-word',
  },
  LISecondaryAction: {
    display: 'grid',
    minWidth: '46%',
    gridTemplateColumns: '50px 30px auto auto',
    gridTemplateRows: '0px 0px 15px 0px',
    '-ms-grid-columns': '50px 30px auto auto',
    '-ms-grid-rows': '0px 0px 15px 0px',
    marginLeft: '10px',
  },
  TaskIconButton: {
    height: '22px !important',
    width: 'auto !important',
    padding: 0,
  },
  card: {},
  whiteColor: {
    color: useTheme().palette.common.white,
  },
  media: {
    height: 'auto',
    maxWidth: '200px !important',
    maxHeight: '300px !important',
    margin: 'auto',
  },
  cardContent: {
    fontSize: '12px',
    '& span': {
      display: 'inline',
    },
  },
  assetDivider: {
    width: '100%',
    maxWidth: '100%',
    padding: '20px',
  },
  assetCardContent: {
    height: '100%',
    minHeight: '120px',
    overflow: 'auto',
    padding: '0px',
    '& > *': {
      borderRadius: 'unset',
      border: '0px',
    },
    paddingBottom: '0px !important',
  },
  actions: {
    display: 'flex',
  },
  Typography: {
    fontSize: '12px',
    color: '#8D8D8D',
    paddingRight: '3px',
    fontWeight: 500,
  },
  progress: {
    color: '#cab34f',
  },
  progressBar: {
    color: '#000',
    marginTop: 10,
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: useTheme().transitions.create('transform', {
      duration: useTheme().transitions.duration.shortest,
    }),
    marginLeft: 'auto',
    [useTheme().breakpoints.up('sm')]: {
      marginRight: -8,
    },
  },
  table: {
    backgroundColor: 'red',
  },
  tr: {
    height: '42px !important',
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  uploadOverlay: {
    width: 'auto',
    height: '100%',
    background: 'rgba(13, 70, 160, .1)',
    zIndex: 10,
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    border: '2px dashed #0d46a0',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    paddingTop: '20%',
  },
  largeIcon: {
    fontSize: 300,
    color: 'white',
  },
  ionContainer: {
    position: 'relative',
  },
  iconContent: {
    top: '0',
    left: '0',
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },
  publishIcon: {
    color: '#0d46a0',
    fontSize: 50,
    animationIterationCount: 'infinite',
    animationTimingFunction: 'ease-in-out',
    animationDuration: '1s',
    animationName: 'wobble-vertical',
    transform: 'perspective(1px) translateZ(0)',
  },
  editIcon: {
    marginRight: '10px',
    color: '#717171',
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 500,
  },
  dragInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    flexDirection: 'column',
    top: 10,
    color: '#8D8D8D',
    '& > svg': {
      fontSize: 35,
      zIndex: 1,
    },
  },
  dragInfoPosition: {
    position: 'absolute',
  },
  emptyProject: {
    textAlign: 'center',
    width: '100%',
    paddingTop: '30px',
    height: 400,
    display: 'flex',
    flexDirection: 'column',
    '& > svg': {
      fontSize: 50,
      color: '#8D8D8D',
    },
  },
  width200: {
    width: '200px',
  },
  dropzone: {
    width: '100%',
    position: 'relative',
  },
  textField: {
    marginTop: 0,
  },
}))

function PublishUpload() {
  const classes = styles()
  const dispatch = useDispatch()
  const { REACT_APP_ROLES_CONFIG = {} } = useEnv() || {}
  const { isAuthorized = () => {} } = useAuth() || {}

  useEffect(() => {
    dispatch(requestAssetsEvent({}))
  }, [])

  const { publishAssets = [] } = REACT_APP_ROLES_CONFIG

  return (
    <>
      <HeaderTitle title="Publish Upload" icon="PublishUpload" />
      <Helmet>
        <title>{'Publish Upload'}</title>
      </Helmet>
      <HeaderTabs activeTabIndex={'Publish'} id={'PublishHeader'} />
      {isAuthorized(publishAssets) ? (
        <Card className={classes.card}>
          <CardContent
            className={classes.assetCardContent}
            style={{
              display: 'flex',
              WebkitJustifyContent: 'center',
              justifyContent: 'center',
            }}
          >
            <ImageUploader />
          </CardContent>
          <PublishTable />
        </Card>
      ) : (
        <InfoPage />
      )}
    </>
  )
}

export default PublishUpload
