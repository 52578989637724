import { Button, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { FILTER_CLASSIFICATION_KEY } from '../../../constants/search'
import {
  removeFilterFromPayload,
  updateSearchPayload,
} from '../../../store/search/searchSlice'
import {
  getClassificationLabels,
  mapFacetDataToCheckboxConfig,
} from '../../../mappers/searchMapper'
import CustomButtonGroup from '../../../components/CustomButtonGroup'
import CheckboxGroup from '../../../components/CustomCheckbox/CheckboxGroup'
import {
  BETA_ANALYTICS_DELIMITER,
  BetaAnalytics,
} from '../../../../analytics/betaSearch'
import { useSearchContext } from '../SearchContext'

const useStyles = makeStyles({
  classificationContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  classificationList: {
    padding: '15px',
    gap: '15px',
    width: '70vw',
    height: '60vh',
    overflow: 'scroll',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
})

function ClassificationFilter({
  parentClasses = {},
  closePopOver = () => {},
  availableFacets = {},
  selectedFilterValues = [],
}) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [clearAllItems, setClearAllItems] = useState(false)
  const [selectedFilters, setSelectedFilters] = useState({})
  const [shouldUpdateParent, setShouldUpdateParent] = useState(false)
  const { records: classificationList = [] } = availableFacets
  const { trackAnalyticsData = () => {} } = useSearchContext()

  const classificationConfig = mapFacetDataToCheckboxConfig(classificationList)

  const handleClassificationTrackingEvent = () => {
    const selectedClassificationIds = Object.values(selectedFilters)?.flat(1)
    const selectedClassificationLabels = getClassificationLabels(
      selectedClassificationIds,
      classificationList,
    )
    trackAnalyticsData({
      eventType: BetaAnalytics.event.ADD_FILTER,
      eventName: BetaAnalytics.event.FILTER_CLASSIFICATION,
      eventData: {
        key: FILTER_CLASSIFICATION_KEY,
        value: selectedClassificationLabels.join(`${BETA_ANALYTICS_DELIMITER}`),
      },
    })
  }

  const onClickApply = () => {
    if (Object.values(selectedFilters).flat(1).length) {
      let ClassificationFilters = {}
      ClassificationFilters[FILTER_CLASSIFICATION_KEY] =
        Object.values(selectedFilters).flat(1)
      dispatch(updateSearchPayload({ filters: ClassificationFilters }))
    } else {
      dispatch(removeFilterFromPayload([FILTER_CLASSIFICATION_KEY]))
    }
    closePopOver()
    handleClassificationTrackingEvent()
  }

  const updateParentState = ({ filterKey = '', selectedValues = [] }) => {
    selectedFilters[filterKey] = selectedValues
    setSelectedFilters(selectedFilters)
    setClearAllItems(false)
  }

  const onClickClear = () => {
    setClearAllItems(true)
  }

  return (
    <Grid container className={classes.classificationContainer}>
      <Grid item className={classes.classificationList}>
        {(Object.keys(classificationConfig) || []).map(
          (classificationConfigKey = '') => (
            <CheckboxGroup
              parentCheckboxLabel={classificationConfigKey}
              parentCheckboxValue={classificationConfigKey}
              parentCheckboxSelected={false}
              uniqueIdentifier={'id'}
              childItems={classificationConfig[classificationConfigKey]}
              shouldUpdateParent={shouldUpdateParent}
              selectedFilterValues={selectedFilterValues}
              clearAllItems={clearAllItems}
              updateParentState={updateParentState}
            />
          ),
        )}
      </Grid>

      <Grid item>
        <CustomButtonGroup
          onClickClear={onClickClear}
          onClickApply={onClickApply}
        />
      </Grid>
    </Grid>
  )
}

export default ClassificationFilter
