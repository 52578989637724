import { Button } from '@mui/material'
import { makeStyles } from '@mui/styles'
import RefreshIcon from '@mui/icons-material/Refresh'
import { useSearchContext } from '../SearchContext'

const useStyles = makeStyles({
  searchButton: {
    backgroundColor: '#188295',
    '&:hover': {
      backgroundColor: '#115E6C',
    },
  },
})

function RefreshSearch() {
  const classes = useStyles()
  const { triggerRefreshSearch = () => {} } = useSearchContext()

  return (
    <Button
      variant="contained"
      color="secondary"
      className={classes.searchButton}
      onClick={() => {
        triggerRefreshSearch()
      }}
      startIcon={<RefreshIcon />}
    >
      Refresh Search
    </Button>
  )
}

export default RefreshSearch
