import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { withAuth } from '@praxis/component-auth'
import { withEnv } from '@praxis/component-runtime-env'
import keycode from 'keycode'
import { connect } from 'react-redux'
import { isEqual, isEmpty, differenceWith } from 'lodash'

import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import Dropzone from 'react-dropzone'
import {
  Button,
  Grid,
  Dialog,
  Container,
  AppBar,
  Typography,
  DialogTitle,
  IconButton,
  CardMedia,
  Card,
  CardContent,
  Box,
} from '@mui/material'
import {
  Search as SearchIcon,
  Refresh as RefreshIcon,
  PhotoCamera,
  ChevronLeft,
} from '@mui/icons-material'
import CloseIcon from '@mui/icons-material/Close'
import withRouter from '../../containers/Router/WithRouter'
import Typeahead from './Typeahead'
import {
  clearSearchAssets,
  clearTypeaheadSuggestions,
  setPreviewImage,
} from '../../store/search/actions'
import { SEARCH_TEMPLATE_DEFAULT_VALUE } from '../../constants/search'
import { showNotification } from '../../store/notification/actionCreator'
import {
  selectSuggestions,
  selectAppliedFilters,
  selectPreviewUrl,
} from '../../store/search/selector'
import {
  selectIsAdGroupSuperAdmin,
  selectUserId,
} from '../../store/auth/selector'
import {
  formatSelectedItems,
  mapSelectedItemsToQueryParams,
  mapQueryParamsToSelectedItems,
  mapSelectedSearchItems,
} from '../../helpers/SearchHelper'
import { fileNameRegex } from '../../helpers/Regexes'
import firefly from '../../analytics/firefly'

const styles = makeStyles((theme) => ({
  textField: {
    marginLeft: '10px',
    marginRight: '20px',
    marginTop: '9px',
    paddingLeft: '3px',
    width: '50vw',
    borderRadius: '5px',
  },
  templateSelectField: {
    margin: 'auto 20px',
  },
  logoTitle: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '36',
    color: '#484848',
    margin: 'auto auto 0 auto',
    width: '500px',
  },
  textButtonContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: '20px',
  },
  searchBox: {
    width: 'auto',
    textIndent: '15px',
    minHeight: '21px',
    borderRadius: '3px',
    flexGrow: 1,
    height: '23px',
  },
  searchBoxUnderline: {
    '&:after': {
      border: '0 !important',
    },
    '&.Mui-focused:after': {
      border: '0 !important',
    },
    '&.Mui-error:after': {
      border: '0 !important',
    },
    '&:before': {
      border: '0 !important',
    },
    '&:hover:not($disabled):not($focused):not($error):before': {
      border: '0 !important',
    },
    '&.Mui-disabled': {
      border: '0 !important',
    },
    '&.Mui-focused': {
      border: '0 !important',
    },
    '&.Mui-error': {
      border: '0 !important',
    },
    '&.Mui-disabled:before': {
      border: '0 !important',
    },
  },
  selectProp: {
    backgroundColor: 'white',
    padding: '4px',
    fontSize: '16px',
    borderRadius: '5px',
  },
  chipContainer: {
    display: 'flex',
  },
  chip: {
    marginRight: '5px',
  },
  typeaheadContainer: {
    width: '100%',
    position: 'relative',
    margin: '0',
    border: '1px solid ' + useTheme().palette.primary.lighterGrey,
    borderRadius: '3px',
  },
  searchButton: {
    marginLeft: '20px',
    maxHeight: '40px',
    backgroundColor: '#188295',
    '&:hover': {
      backgroundColor: '#115E6C',
    },
  },
  imageButton: {
    marginLeft: '20px',
    maxHeight: '40px',
    color: '#188295',
    '& $cameraIcon': {
      color: '#188295',
      marginRight: 5,
    },
    '&:hover': {
      color: 'white',
      backgroundColor: '#188295',
      '& $cameraIcon': {
        color: 'white',
      },
    },
  },
  cameraIcon: {
    color: '#188295',
  },
  dropArea: {
    minWidth: '520px',
    position: 'relative',
    height: '140px',
    border: '2px dashed #666',
    margin: '10px',
    padding: '10px',
  },
  dailogContainer: {
    border: '1px solid rgb(112, 112, 112)',
    height: '100%',
    marginTop: '8%',
    marginBottom: '8%',
    maxWidth: '1200px',
    maxHeight: '764px',
    padding: '44px',
    display: 'flex',
    flexDirection: 'column',
  },
  dropzone: {
    flexGrow: '1',
    margin: '33px',
    height: '100%',
    background: '#F4F4F4 0% 0% no-repeat padding-box',
    border: '1px dashed #707070',
    opacity: '1',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  uploadButton: {
    backgroundColor: '#188295',
    color: '#FFFFFF',
    padding: '17px 32px',
    letterSpacing: '0px',
    font: 'normal normal normal 20px/13px Roboto;',
    '&:hover': {
      backgroundColor: '#002171',
    },
  },
  dropDesc: {
    font: 'normal normal normal 20px/26px Roboto',
    letterSpacing: '0px',
    color: '#43425D',
    opacity: 1,
  },
  titleHeader: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',
    font: 'normal normal normal 26px/40px Roboto',
    letterSpacing: '0px',
    color: '#43425D',
    opacity: '1',
    margin: 0,
    '& $cameraIcon': {
      color: '#4D4F5C',
      marginRight: '20px',
    },
  },
  titleDesc: {
    textAlign: 'left',
    marginLeft: '44px',
    font: 'normal normal normal 22px/40px Roboto',
    letterSpacing: '0px',
    color: '#43425D',
    opacity: '1',
    margin: 0,
  },
  closeIcon: {
    padding: '0',
    position: 'absolute',
    top: '22px',
    right: '-23px',
  },
  searchIcon: {
    color: 'white',
    marginRight: 5,
  },
  refreshIcon: {
    color: '#188295',
    marginRight: 5,
  },
  refreshButton: {
    marginLeft: '20px',
    maxHeight: '40px',
    backgroundColor: 'white',
    color: '#188295',
  },
}))
const blankSearch = {
  searchTerm: '',
  chips: [],
}

const initialState = {
  query: blankSearch,
  prevTemplateId: SEARCH_TEMPLATE_DEFAULT_VALUE,
  selectedItems: [],
}

const getParsedSearchQuery = (props) => {
  const searchString = props.router.location.search
  return mapQueryParamsToSelectedItems(searchString)
}

export class SearchForm extends React.Component {
  constructor(props) {
    super(props)
    const { searchTerm = '' } = props
    this.dropzoneRef = React.createRef()
    this.state = {
      ...initialState,
      searchTerm: searchTerm,
      submitedSearch: false,
      imageUpload: false,
    }
    if (!isEmpty(searchTerm)) this.submitSearch()
  }

  componentDidUpdate(prevProps) {
    const {
      fetchData,
      clearSearchAssets,
      saveAppliedFilters,
      currentTemplateId,
      doesSearchExist,
      setInitFilters,
      appliedFilters,
      showModal = false,
      selectedFilters = [],
    } = this.props
    const { currentTemplateId: prevTemplateIdProp = '' } = prevProps
    const { query, prevTemplateId } = this.state
    const parsedQuery = getParsedSearchQuery(this.props)
    const newFilters = formatSelectedItems(parsedQuery.chips)
    const isBlankSearch = isEqual(blankSearch, parsedQuery)
    const isNewSearch = !isEqual(query, parsedQuery)
    const hasTemplateChanged = prevTemplateIdProp !== currentTemplateId
    if (isBlankSearch && isNewSearch && !showModal) {
      const finalAppliedFilters = this.formatAppliedFilters(
        appliedFilters,
        parsedQuery.searchTerm
      )
      let chipFilters = !hasTemplateChanged
        ? { ...finalAppliedFilters, ...newFilters }
        : newFilters
      saveAppliedFilters(chipFilters)
      doesSearchExist(true)
      clearSearchAssets(true)
      const fetchDataObj =
        selectedFilters.length > 0
          ? {
              searchTerm: '',
              currentPage: 0,
              currentTemplateId,
              chipFilters,
              isNewSearch: true,
            }
          : { searchTerm: '', currentPage: 0, isNewSearch }
      this.setState(() => {
        fetchData(fetchDataObj)
        return {
          searchTerm: parsedQuery.searchTerm,
          query: parsedQuery,
          selectedItems: parsedQuery.chips,
          prevTemplateId: currentTemplateId,
          submitedSearch: true,
        }
      })
      setInitFilters({}, parsedQuery.searchTerm)
    } else if (
      (!isBlankSearch && (isNewSearch || hasTemplateChanged)) ||
      (isBlankSearch && !isEmpty(currentTemplateId) && hasTemplateChanged)
    ) {
      doesSearchExist(true)
      clearSearchAssets(true)
      const finalAppliedFilters = this.formatAppliedFilters(
        appliedFilters,
        parsedQuery.searchTerm
      )
      let chipFilters = !hasTemplateChanged
        ? { ...finalAppliedFilters, ...newFilters }
        : newFilters
      saveAppliedFilters(chipFilters)
      setInitFilters(newFilters, parsedQuery.searchTerm)
      this.setState(() => {
        fetchData({
          searchTerm: parsedQuery.searchTerm,
          currentPage: 0,
          currentTemplateId,
          chipFilters,
          isNewSearch: true,
        })
        return {
          searchTerm: parsedQuery.searchTerm,
          query: parsedQuery,
          selectedItems: parsedQuery.chips,
          prevTemplateId: currentTemplateId,
          submitedSearch: true,
        }
      })
    }
  }

  componentWillUnmount = () => {
    const {
      searchImagePreview = '',
      resetAll = () => {},
      setPreviewImage = () => {},
    } = this.props
    if (searchImagePreview) {
      resetAll()
      setPreviewImage('')
    }
  }

  formatAppliedFilters = (appliedFilters = {}, searchTerm = '') => {
    let finalAppliedFilters = Object.assign({}, appliedFilters)
    if (!fileNameRegex.test(searchTerm)) {
      delete finalAppliedFilters['metadata_info.file_name']
    }
    return finalAppliedFilters
  }

  onSearchInputChange = (event) => {
    const { fetchTypeaheadSuggestions, clearTypeaheadSuggestions = () => {} } =
      this.props
    if (event.target.value.length > 0) {
      fetchTypeaheadSuggestions(event.target.value)
    }
    if (event.target.value.length === 0) {
      clearTypeaheadSuggestions()
    }
    this.setState({
      searchTerm: event.target.value,
      submitedSearch: false,
    })
  }

  submitSearch = (e) => {
    if (e) e.preventDefault()
    const {
      router = {},
      userId = '',
      currentTemplateId,
      setSearchParamObject,
    } = this.props
    const { searchTerm = '', selectedItems } = this.state
    const { searchParamObject = {}, appliedFilterValues = '' } =
      mapSelectedSearchItems(searchTerm.trim(), selectedItems)
    setSearchParamObject(searchParamObject)
    firefly.trackClick(
      'search',
      'ADD',
      searchTerm.trim(),
      appliedFilterValues,
      userId,
      currentTemplateId
    )
    const newQueryString = mapSelectedItemsToQueryParams(searchParamObject)
    router.navigate(`?${newQueryString}`)
  }

  handleChange = (item = '') => {
    let { selectedItems } = this.state
    if (selectedItems.indexOf(item) === -1) {
      selectedItems = Array.from(new Set([...selectedItems, item]))
    }
    const finalList =
      selectedItems.length > 0 ? this.getUniqueFilters(selectedItems) : []
    this.setState({
      searchTerm: '',
      selectedItems: finalList,
    })
  }

  handleDelete = (item = '') => {
    const { selectedFilters = [], onFilterChange = () => {} } = this.props
    const selectedFiltersList = selectedFilters.map((itemObj = {}) => {
      const { props: { data: { label = '', detail = '' } = {} } = {} } =
        itemObj || {}
      return Object.assign({}, { label: label, value: detail })
    })
    const {
      props: {
        data: { label: itemLabel = '', detail: itemDetail = '' } = {},
      } = {},
    } = item || {}
    const itemList = { label: itemLabel, value: itemDetail }
    const checkItemAvailability = selectedFiltersList.some(function (arrVal) {
      return isEqual(itemList, arrVal)
    })
    if (!!checkItemAvailability) {
      onFilterChange(itemLabel, itemDetail, true)
    }
    this.setState((state) => {
      const selectedItems = [...state.selectedItems]
      const selectedItemsCopy = selectedItems.filter((item = {}) => {
        const { props: { data: { label = '', detail = '' } = {} } = {} } =
          item || {}
        return !(label === itemLabel && detail === itemDetail)
      })
      return { selectedItems: selectedItemsCopy }
    })
  }

  handleKeyDown = (event) => {
    const { searchTerm, selectedItems } = this.state
    if (
      selectedItems.length &&
      !searchTerm.length &&
      keycode(event) === 'backspace'
    ) {
      this.setState({
        selectedItems: selectedItems.slice(0, selectedItems.length - 1),
        submitedSearch: false,
      })
    }
  }

  onDrop = (accepted, rejected) => {
    if (Object.keys(rejected).length !== 0) {
      rejected.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === 'file-too-large') {
            this.props.showNotification(
              true,
              'File is larger than 2mb',
              'error'
            )
          }

          if (err.code === 'file-invalid-type') {
            this.props.showNotification(true, err.message, 'error')
          }
        })
      })
    } else {
      this.setState({
        files: accepted.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
        imageUpload: false,
      })
      this.props.saveFile(accepted[0])
    }
  }

  onDragEnter = () => {
    this.setState({
      dropZoneActive: true,
    })
  }

  onDragLeave = () => {
    this.setState({
      dropZoneActive: false,
    })
  }

  openUploadDialog = () => {
    this.setState({
      imageUpload: true,
    })
  }

  closeUploadDialog = () => {
    this.setState({
      imageUpload: false,
    })
  }

  openSystemDialog = () => {
    if (this.dropzoneRef.current) {
      this.dropzoneRef.current.open()
    }
  }

  handleStartover = (e) => {
    e.preventDefault()
    const {
      router,
      userId = '',
      clearSearchAssets,
      fetchData,
      resetAll,
      setSearchParamObject,
      onClearAppliedFilters,
    } = this.props
    this.setState({
      ...initialState,
    })
    setSearchParamObject()
    clearSearchAssets()
    resetAll()
    onClearAppliedFilters()
    firefly.trackClick(
      'search',
      'ADD',
      '',
      '',
      userId,
      SEARCH_TEMPLATE_DEFAULT_VALUE
    )
    const newQueryString = mapSelectedItemsToQueryParams({})
    router.navigate(`?${newQueryString}`)
    this.setState(() => {
      fetchData({
        searchTerm: '',
        currentPage: 0,
        ignoreAppliedFilters: true,
        isNewSearch: true,
        templateId: SEARCH_TEMPLATE_DEFAULT_VALUE,
      })
      return {
        searchTerm: '',
      }
    })
  }

  getUniqueFilters = (filters = []) => {
    const filtersMap = filters.map((item = {}) => {
      const { props: { data: { label = '', detail = '' } = {} } = {} } =
        item || {}
      return Object.assign({}, item, { label: label, value: detail })
    })
    const unique = (arr = [], props = []) => [
      ...new Map(
        arr.map((entry) => [props.map((k) => entry[k]).join('|'), entry])
      ).values(),
    ]
    const uniqueFilters = unique(filtersMap, ['label', 'value'])
    return uniqueFilters
  }

  render() {
    const {
      searchTerm = '',
      selectedItems,
      imageUpload,
      dropZoneActive,
      files = [],
    } = this.state

    const {
      classes,
      suggestions = {},
      isFetching = false,
      fullWidth = true,
      placeholder = '',
      selectedFilters = [],
      searchImagePreview = '',
      auth = {},
      env = {},
      isAdmin = false,
    } = this.props
    const { isAuthorized = () => {} } = auth
    const { REACT_APP_ROLES_CONFIG = {}, ENABLE_REVERSE_IMAGE_SEARCH = false } =
      env
    const { search = [] } = REACT_APP_ROLES_CONFIG
    const filtersCopy = !this.state.submitedSearch
      ? [...selectedFilters, ...selectedItems]
      : selectedFilters
    let filters = filtersCopy
    if (filtersCopy.length > 0) {
      filters = this.getUniqueFilters(filtersCopy)
    }
    const searchByImage =
      isAuthorized(search) && (ENABLE_REVERSE_IMAGE_SEARCH || isAdmin)
    return (
      <form onSubmit={this.submitSearch} method="GET">
        <Grid container spacing={1}>
          <Grid
            container
            item
            xs={12}
            md={6}
            lg={searchImagePreview ? 4 : 8}
            justifyContent={'unset'}
          >
            {searchImagePreview ? (
              <Card
                sx={{
                  display: 'flex',
                  border: 'none',
                  boxShadow: 'none',
                  borderRadius: '0',
                }}
              >
                <CardMedia
                  component="img"
                  sx={{ width: 151, height: 100, objectFit: 'contain' }}
                  image={searchImagePreview}
                  alt="Reverse image search"
                />
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <CardContent>
                    <Typography component="div" variant="h5">
                      Reverse Image Search
                    </Typography>
                  </CardContent>
                </Box>
              </Card>
            ) : (
              <Typeahead
                onSearchInputChange={this.onSearchInputChange}
                searchTerm={searchTerm}
                handleChange={this.handleChange}
                handleDelete={this.handleDelete}
                suggestions={suggestions}
                isFetching={isFetching}
                selectedItems={filters}
                handleKeyDown={this.handleKeyDown}
                fullWidth={fullWidth}
                classes={{
                  container: classes.typeaheadContainer,
                  inputInput: classes.searchBox,
                  inputUnderline: classes.searchBoxUnderline,
                }}
                placeholder={placeholder}
              />
            )}
          </Grid>
          <Grid
            item
            container
            xs={12}
            md={6}
            lg={4}
            alignItems={searchImagePreview ? 'center' : 'flex-start'}
          >
            {!searchImagePreview && (
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                className={classes.searchButton}
              >
                <SearchIcon className={classes.searchIcon} /> Search
              </Button>
            )}
            <Button
              color="primary"
              onClick={this.handleStartover}
              className={classes.refreshButton}
            >
              {searchImagePreview ? (
                <ChevronLeft
                  className={classes.refreshIcon}
                  data-cy="startOverButton"
                />
              ) : (
                <RefreshIcon
                  className={classes.refreshIcon}
                  data-cy="startOverButton"
                />
              )}

              {searchImagePreview ? 'Return To Search' : 'START OVER'}
            </Button>
            {searchByImage && (
              <Button
                variant="outlined"
                color="secondary"
                onClick={this.openUploadDialog}
                className={classes.imageButton}
              >
                <PhotoCamera className={classes.cameraIcon} /> Search by image
              </Button>
            )}
          </Grid>
        </Grid>
        <Dialog fullScreen open={imageUpload} onClose={this.closeUploadDialog}>
          <Container className={classes.dailogContainer}>
            <DialogTitle style={{ position: 'relative' }}>
              <header className={classes.titleHeader}>
                {' '}
                <PhotoCamera className={classes.cameraIcon} />
                Search by image
              </header>
              <p className={classes.titleDesc}>
                Reverse image search to view matching images
              </p>
              <IconButton
                aria-label="close"
                onClick={this.closeUploadDialog}
                className={classes.closeIcon}
              >
                <CloseIcon style={{ fontSize: 24 }} />
              </IconButton>
            </DialogTitle>
            <Dropzone
              ref={this.dropzoneRef}
              noClick
              onDrop={this.onDrop}
              onDragEnter={this.onDragEnter}
              onDragLeave={this.onDragLeave}
              noKeyboard
              accept=".jpeg,.png,.jpg"
              maxSize={2000000}
              maxFiles={1}
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps({ className: classes.dropzone })}>
                  <input {...getInputProps()} />
                  {dropZoneActive ? (
                    <p className={classes.dropDesc}>
                      Release to drop the files here
                    </p>
                  ) : (
                    <>
                      <Button
                        onClick={this.openSystemDialog}
                        color="primary"
                        aria-label="upload"
                        className={classes.uploadButton}
                      >
                        Upload Image
                      </Button>
                      <p className={classes.dropDesc}>or drag and drop file</p>
                    </>
                  )}
                </div>
              )}
            </Dropzone>
          </Container>
        </Dialog>
      </form>
    )
  }
}

SearchForm.propTypes = {
  classes: PropTypes.object.isRequired,
  clearSearchAssets: PropTypes.func.isRequired,
  fetchData: PropTypes.func,
  fetchTypeaheadSuggestions: PropTypes.func.isRequired,
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
    }).isRequired,
    push: PropTypes.func.isRequired,
  }).isRequired,
  setInitFilters: PropTypes.func,
  saveAppliedFilters: PropTypes.func,
  suggestions: PropTypes.shape({
    Brand: PropTypes.array.isRequired,
    'Campaign Description': PropTypes.array.isRequired,
    Channel: PropTypes.array.isRequired,
    Department: PropTypes.array.isRequired,
  }),
  isFetching: PropTypes.bool.isRequired,
  currentTemplateId: PropTypes.string.isRequired,
  doesSearchExist: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool.isRequired,
  placeholder: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  appliedFilters: PropTypes.object.isRequired,
  resetAll: PropTypes.func,
  showModal: PropTypes.bool,
  setSearchParamObject: PropTypes.func,
  selectedFilters: PropTypes.array,
  onFilterChange: PropTypes.func,
  onClearAppliedFilters: PropTypes.func,
}

const mapStateToProps = (state = {}) => ({
  suggestions: selectSuggestions()(state),
  isFetching: state?.assetsSearch?.typeaheadFetching,
  userId: selectUserId()(state),
  appliedFilters: selectAppliedFilters()(state),
  searchImagePreview: selectPreviewUrl()(state),
  isAdmin: selectIsAdGroupSuperAdmin()(state),
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearTypeaheadSuggestions,
      clearSearchAssets,
      showNotification,
      setPreviewImage,
    },
    dispatch
  )

const MyComponent = (props) => {
  const classes = styles()
  return <SearchForm {...props} classes={classes} />
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withEnv()(withAuth()(withRouter(MyComponent))))
