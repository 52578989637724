export const modifyClassificationData = (classificationData) => {
  let updatedTableData = []
  classificationData.forEach((data) => {
    const { metadata = [] } = data
    let tableObject = { ...data }
    metadata.map((meta) => {
      const { name = '' } = meta
      tableObject[name] = 'Required'
    })
    updatedTableData.push(tableObject)
  })
  return updatedTableData
}

export const modifyMetadataColumns = (metadataTypes = []) => {
  let metaDataColumns = []
  metadataTypes.map((metadataType) => {
    metaDataColumns.push({
      id: metadataType,
      label: `Metadata - ${metadataType}`,
      field: metadataType,
    })
  })
  return metaDataColumns
}

export const getSelectedClassification = (
  classificationTypes = [],
  classificationName = ''
) =>
  classificationTypes.find(
    (classificationDetail) =>
      classificationDetail.classification_name === classificationName
  )

export const getClassificationId = (
  classificationTypes = [],
  classificationName = '',
  assetType = ''
) => {
  const selectedClassificationObj = getSelectedClassification(
    classificationTypes,
    classificationName
  )
  if (
    selectedClassificationObj &&
    selectedClassificationObj['asset_types'] &&
    selectedClassificationObj['asset_types'].length
  ) {
    const selectedAssetObj = selectedClassificationObj.asset_types.find(
      (assetDetail) => assetDetail.name === assetType
    )
    return selectedAssetObj['classification_id']
  }
  return ''
}

export const getFirstItemOfData = (dataSet = [], fieldName = '') => {
  if (dataSet && dataSet.length) {
    return fieldName ? dataSet[0][fieldName] : dataSet[0]
  }
  return ''
}
