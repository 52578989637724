export const SET_CURRENT_ASSET_ID = 'SET_CURRENT_ASSET_ID'
export const SET_SORT_BY = 'SET_SORT_BY'
export const SET_ASSETS = 'SET_ASSETS'
export const SET_IS_FETCHING_ASSETS = 'SET_IS_FETCHING_ASSETS'
export const SET_PAGE_SIZE = 'SET_PAGE_SIZE'
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE'
export const SET_TOTAL_RESULTS = 'SET_TOTAL_RESULTS'
export const OPEN_MODAL = 'OPEN_MODAL'
export const CLOSE_MODAL = 'CLOSE_MODAL'
export const SET_FACETS = 'SET_FACETS'
export const SET_FILTER_VALUE = 'SET_FILTER_VALUE'
export const SET_PARTIAL_FILTER_VALUE = 'SET_PARTIAL_FILTER_VALUE'
export const RESET_FILTERS = 'RESET_FILTERS'
export const ON_START_OVER = 'ON_START_OVER'
