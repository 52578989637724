import { isEmpty } from 'lodash'
import apiConfig from '../../../config/apiConfig'
import {
  filterByField,
  separateFilenameExtension,
} from '../../../helpers/UtilityHelper'

const { key = '' } = apiConfig

const formatBreadcrumb = (board = {}) => {
  const { board_name = '' } = board
  if (board_name) {
    return [
      {
        title: 'Boards',
        path: '/boards',
      },
      {
        title: board_name,
      },
    ]
  }
  return [{ title: 'Boards Dashboard' }]
}

const getPreviewAssetMetadata = (
  assetList = [],
  assetPreviewIndex = Number(0)
) => {
  let previewMetadata = {
    assetId: '',
    fileName: '',
    fileUrl: '',
    assetDetails: {},
  }
  const asset = assetList[assetPreviewIndex]

  if (asset) {
    const {
      asset_id = '',
      file_name = '',
      asset_preview_path = '',
      render_urls,
    } = asset
    if (previewMetadata) {
      previewMetadata['fileName'] = file_name
      previewMetadata['filePath'] = asset_preview_path
      previewMetadata['assetId'] = asset_id
      previewMetadata['fileType'] = separateFilenameExtension(file_name)
        ?.fileExt?.substring(1)
        ?.toUpperCase()
      previewMetadata['file_metadata'] = {}
      previewMetadata['file_metadata']['file_type'] = separateFilenameExtension(
        file_name
      )
        ?.fileExt?.substring(1)
        ?.toUpperCase()
      previewMetadata['assetDetails'] = {
        render_urls,
      }
    }
  }
  return previewMetadata
}

const isPageSelected = (
  board = {},
  assets = {},
  page = Number(0),
  rowsPerPage = 10,
  filterText = '',
  downloadAssets = {}
) => {
  if (isEmpty(downloadAssets)) {
    return false
  }

  if (!isEmpty(board) && !isEmpty(assets)) {
    const boardAssets = assets[board?.board_id]
    const filteredAssets = filterByField(boardAssets, 'file_name', filterText)
    const filteredAssetPage =
      page === 0
        ? filteredAssets.slice(0, rowsPerPage)
        : filteredAssets.slice(
            rowsPerPage * page,
            rowsPerPage * page + rowsPerPage
          )
    const assetIds = filteredAssetPage?.map((asset) => asset.asset_id)
    for (let i = 0; i < assetIds.length; i++) {
      if (!downloadAssets[assetIds[i]]) {
        return false
      }
    }
  }
  return true
}

const removeAsset = (assetId = '', downloadAssets = {}) => {
  delete downloadAssets[assetId]
  return downloadAssets
}

export {
  formatBreadcrumb,
  getPreviewAssetMetadata,
  removeAsset,
  isPageSelected,
}
