import React from 'react'
import PropTypes from 'prop-types'
import MarkupBox from './MarkupBox'

const getRatio = (a, b) => {
  if (a > b) {
    return a / b
  } else {
    return b / a
  }
}

export class MarkupWrapper extends React.PureComponent {
  state = {
    markups: [],
    lastMarkupNumber: 0,
    currentMarkup: null,
  }

  componentDidUpdate(prevProps) {
    const { scale } = this.props
    const { currentMarkup = {} } = this.state

    if (prevProps.scale !== scale && currentMarkup) {
      const { position, size } = currentMarkup
      const ratio = getRatio(prevProps.scale, scale)
      let newSize
      let newPosition
      if (prevProps.scale < scale) {
        newPosition = {
          x: position.x * ratio,
          y: position.y * ratio,
        }

        newSize = {
          height: size.height * ratio,
          width: size.width * ratio,
        }
      } else {
        newPosition = {
          x: position.x / ratio,
          y: position.y / ratio,
        }

        newSize = {
          height: size.height / ratio,
          width: size.width / ratio,
        }
      }

      const newMarkup = {
        ...currentMarkup,
        size: newSize,
        position: newPosition,
      }

      this.setState({ currentMarkup: newMarkup })
    }
  }

  onPointerDown = (event) => {
    const { currentTool = '' } = this.props
    const { lastMarkupNumber } = this.state
    const { pageX, pageY } = event
    const position = {
      x: pageX,
      y: pageY - 114,
    }
    const newMarkup = {
      position,
      size: {},
      number: lastMarkupNumber + 1,
    }
    if (currentTool !== 'cursor') {
      this.setState({
        currentMarkup: newMarkup,
        lastMarkupNumber: newMarkup.number,
      })
    }
  }

  onPointerMove = (event) => {
    const { pageX, pageY, buttons } = event
    const { currentMarkup = {} } = this.state
    const { currentTool = '' } = this.props
    if (currentTool !== 'cursor' && buttons === 1) {
      const { position = {} } = currentMarkup
      const size = {
        width: Math.abs(position.x - pageX),
        height: Math.abs(position.y - pageY) - 114,
      }
      this.setState({ currentMarkup: { ...currentMarkup, size } })
    }
  }

  onPointerUp = () => {
    const { onToolSelect, currentTool } = this.props
    if (currentTool !== 'cursor') {
      onToolSelect('cursor')
    }
  }

  onDragStop = (e, d) => {
    const position = { x: d.x, y: d.y }
    this.setState((state) => ({
      currentMarkup: { ...state.currentMarkup, position },
    }))
  }

  onResize = (e, direction, ref) => {
    const numberWidth = parseInt(ref.style.width.slice(0, -2))
    const numberHeight = parseInt(ref.style.height.slice(0, -2))
    const size = {
      width: numberWidth,
      height: numberHeight,
    }
    this.setState((state) => ({
      currentMarkup: { ...state.currentMarkup, size },
    }))
  }

  render() {
    const { children = <div />, currentTool = '' } = this.props
    const { currentMarkup, markups } = this.state
    return (
      <div
        onPointerDown={this.onPointerDown}
        onPointerMove={this.onPointerMove}
        onPointerUp={this.onPointerUp}
        style={{
          cursor: currentTool === 'square' ? 'crosshair' : 'default',
        }}
      >
        {currentMarkup && (
          <MarkupBox
            number={currentMarkup.number}
            size={currentMarkup.size}
            position={currentMarkup.position}
            onDragStop={this.onDragStop}
            onResize={this.onResize}
          />
        )}
        {markups.map((markup, idx) => (
          <MarkupBox
            key={idx}
            number={markup.number}
            size={markup.size}
            position={markup.position}
            disableDragging={true}
            enableResizing={false}
          />
        ))}
        {children}
      </div>
    )
  }
}

MarkupWrapper.propTypes = {
  scale: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),
  currentTool: PropTypes.string,
  onToolSelect: PropTypes.func,
}

export default MarkupWrapper
