import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { cloneDeep } from 'lodash'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Checkbox,
  Input,
  InputLabel,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  IconButton,
  CircularProgress,
  Chip,
  Divider,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material'

import {
  Add as AddIcon,
  Delete as DeleteIcon,
  OpenInNew as OpenIcon,
  Error as ErrorIcon,
} from '@mui/icons-material'
import CollapsibleCard from '../../components/CustomCards/CollapsibleCard'
import { formatCSTDate } from '../../helpers/dateHelper'
import { getFromBrowserStorage } from '../../helpers/UtilityHelper'
import {
  getAvailableChannelsInfo,
  getUsageInfoDetailsInfo,
} from '../../helpers/MetadataHelper'
import { metadataDRMRegex } from '../../helpers/Regexes'
import {
  METADATA_CARD_SHOW_BUTTON_TEXT,
  METADATA_CARD_HIDE_BUTTON_TEXT,
  METADATA_LOOKUP_ERROR,
  METADATA_LOOKUP_DRM_ERROR,
  ALLOWED_ACTION_KEYS,
} from '../../constants/metadata'

import { fetchUsageRights } from '../../store/metadata/actionCreator'
import CustomAvatar from '../../components/Custom/CustomAvatar'

export class UsageInfoCard extends React.Component {
  constructor(props) {
    super(props)
    const {
      usageInformation = [],
      usageInstructions = {},
      metadataParentTimestamp = 0,
    } = props
    this.state = {
      usageInformation: cloneDeep(usageInformation),
      usageInstructions: cloneDeep(usageInstructions),
      metadataParentTimestamp,
      unsavedChanges: false,
      disableSave: false,
      expandedUsageRight: '',
    }
  }

  componentDidUpdate(prevProps) {
    const {
      usageInformation = [],
      usageInstructions = {},
      metadataParentTimestamp = 0,
    } = this.props
    if (prevProps.metadataParentTimestamp !== metadataParentTimestamp) {
      this.setState({
        usageInformation: cloneDeep(usageInformation),
        usageInstructions: cloneDeep(usageInstructions),
        metadataParentTimestamp,
        unsavedChanges: false,
        disableSave: false,
        expandedUsageRight: '',
      })
    }
  }

  discardChanges = () => {
    // discard the changes
    this.setState((state, props) => {
      const { usageInformation = [], usageInstructions = {} } = props
      return {
        usageInformation: cloneDeep(usageInformation),
        usageInstructions: cloneDeep(usageInstructions),
        unsavedChanges: false,
        disableSave: false,
        expandedUsageRight: '',
      }
    })
  }

  saveChanges = () => {
    // save the changes
    const { usageInformation, usageInstructions } = this.state
    const { saveMetadata = () => {} } = this.props
    const updatedAssetDetails = {
      usageInformation,
      usageInstructions,
    }
    saveMetadata(updatedAssetDetails)
  }

  updateStateCB = () => {
    const { updateParentState = () => {}, isMetadataFormEdited = () => {} } =
      this.props
    const { usageInformation, usageInstructions, unsavedChanges } = this.state
    // Update parent state
    const updatedAssetDetails = {
      usageInformation,
      usageInstructions,
    }
    updateParentState(updatedAssetDetails)
    isMetadataFormEdited(unsavedChanges)
  }

  handleUsageInstructions = (fieldName) => (event) => {
    // handle the changes on the Usage Instructions fields
    switch (fieldName) {
      case 'doNotUse':
        this.setState((state) => {
          const { usageInstructions = {} } = state
          const { doNotUse } = usageInstructions
          return {
            usageInstructions: Object.assign({}, usageInstructions, {
              doNotUse: !doNotUse,
            }),
            unsavedChanges: true,
          }
        }, this.updateStateCB)
        break
      case 'instructions':
        {
          const instructions = event.target.value || ''
          this.setState((state) => {
            const { usageInstructions = {} } = state
            return {
              usageInstructions: Object.assign({}, usageInstructions, {
                instructions: instructions,
              }),
              unsavedChanges: true,
            }
          }, this.updateStateCB)
        }
        break
      case 'additionalDirections':
        {
          const additionalDirections = event.target.value || ''
          this.setState((state) => {
            const { usageInstructions = {} } = state
            return {
              usageInstructions: Object.assign({}, usageInstructions, {
                additionalDirections: additionalDirections,
              }),
              unsavedChanges: true,
            }
          }, this.updateStateCB)
        }
        break
      default:
        break
    }
  }

  addUsageRight = () => {
    // add new row
    const emptyRow = {
      drmId: '',
      rightsHolder: '',
      rightsHolderType: '',
      expirationDate: '',
      availableChannels: '',
    }
    this.setState((state) => {
      const { usageInformation = [] } = state
      usageInformation.unshift(emptyRow)
      return {
        usageInformation: [...usageInformation],
        unsavedChanges: true,
        disableSave: true,
      }
    }, this.updateStateCB)
  }

  deleteUsageRight = (identifier) => () => {
    // delete a row
    this.setState((state) => {
      const { usageInformation = [] } = state
      usageInformation.splice(identifier, 1)
      return {
        usageInformation: [...usageInformation],
        unsavedChanges: true,
        disableSave: false,
      }
    }, this.updateStateCB)
  }

  handleChangeUsageRight = (identifier) => (event) => {
    // handle changes on the DRM ID input field
    const drmId = event.target.value || ''
    this.setState((state) => {
      const { usageInformation = [] } = state
      usageInformation[identifier]['drmId'] = drmId
      if (drmId === '') {
        usageInformation[identifier]['isError'] = false
        usageInformation[identifier]['isSystemError'] = false
      }
      return {
        usageInformation: [...usageInformation],
        unsavedChanges: true,
        disableSave: true,
      }
    }, this.updateStateCB)
  }

  handleFetchUsageRight = (identifier) => (event) => {
    if (ALLOWED_ACTION_KEYS.indexOf(event.keyCode) !== -1) {
      // fetch from URM API on tab out of DRM ID input field
      const drmId = event.target.value || ''
      if (drmId) {
        const drmIdNo = drmId.match(metadataDRMRegex)
        if (drmIdNo) {
          this.toggleLoader(identifier, true)
          fetchUsageRights(drmIdNo, identifier, this.drmPromiseHandler)
        } else {
          this.setState((state) => {
            const { usageInformation = [] } = state
            usageInformation[identifier]['isError'] = true
            return {
              usageInformation: [...usageInformation],
            }
          })
        }
      }
    }
    return false
  }

  toggleLoader = (identifier, value = false) => {
    // show or hide loader in the row
    this.setState((state) => {
      const { usageInformation = [] } = state
      usageInformation[identifier]['showProgress'] = value
      return {
        usageInformation: [...usageInformation],
      }
    })
  }

  drmPromiseHandler = (requestPayload, response, type) => {
    // callback handler after fetching from URM API
    const { identifier } = requestPayload
    let unsavedChanges, disableSave, updatedRow
    if (type === 'success') {
      if (response.length) {
        const {
          channels = [],
          expiration_time: expirationDate = 0,
          holder_name: rightsHolder = '',
          holder_type: rightsHolderType = '',
          internal: isInternalUsage = false,
          other_channel: isOtherChannelUsage = false,
        } = response[0]
        const availableChannels = getAvailableChannelsInfo(
          channels,
          isInternalUsage,
          isOtherChannelUsage
        )
        updatedRow = {
          rightsHolder,
          rightsHolderType,
          expirationDate,
          availableChannels,
          showProgress: false,
          isError: false,
          isSystemError: false,
        }
        unsavedChanges = true
        disableSave = false
      } else {
        updatedRow = {
          showProgress: false,
          isError: true,
          isSystemError: false,
        }
        unsavedChanges = true
        disableSave = true
      }
    } else {
      updatedRow = {
        showProgress: false,
        isError: false,
        isSystemError: true,
      }
      unsavedChanges = true
      disableSave = true
    }
    this.setState((state) => {
      const { usageInformation = [] } = state
      usageInformation[identifier] = Object.assign(
        {},
        { ...usageInformation[identifier] },
        updatedRow
      )
      return {
        usageInformation: [...usageInformation],
        unsavedChanges: unsavedChanges,
        disableSave: disableSave,
      }
    }, this.updateStateCB)
  }

  handleAccordionToggle =
    (panelName = '') =>
    (event, expanded = false) => {
      this.setState({
        expandedUsageRight: expanded ? panelName : false,
      })
    }

  render() {
    const {
      classes = {},
      getStorageIdentifier = () => {},
      collapseCallback = () => {},
      editPermissions = {},
    } = this.props
    const {
      usageInformation = [],
      usageInstructions = {},
      disableSave,
      expandedUsageRight = false,
    } = this.state
    const usageInfoTitle = 'Usage Information'
    const usageInfoIdentifier = getStorageIdentifier(usageInfoTitle)
    const usageInfoCollapse =
      getFromBrowserStorage(usageInfoIdentifier) === 'true'
    const { canEdit } = editPermissions
    const {
      doNotUse = false,
      instructions = '',
      additionalDirections = '',
      contacts = [],
    } = usageInstructions
    return (
      <CollapsibleCard
        cardTitle={usageInfoTitle}
        hideButtonText={METADATA_CARD_HIDE_BUTTON_TEXT}
        showButtonText={METADATA_CARD_SHOW_BUTTON_TEXT}
        defaultCollapse={usageInfoCollapse}
        collapseCallback={collapseCallback}
        fullWidthContent
        isActionMode={false}
        disablePrimaryButton={disableSave}
        primaryButtonText="SAVE CHANGES"
        secondaryButtonText="CANCEL CHANGES"
        primaryButtonHandler={this.saveChanges}
        secondaryButtonHandler={this.discardChanges}
      >
        <div className={classes.metadataCard}>
          <div className={classes.metadataCardActionWrapper}>
            <Typography className={classes.metadataSubHeading}>
              Creative Usage Instructions
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  data-cy={doNotUse ? 'doNotUseCheck' : 'useCheck'}
                  checked={doNotUse}
                  onChange={this.handleUsageInstructions('doNotUse')}
                  value={`${doNotUse}`}
                  disabled={!canEdit}
                />
              }
              label="Do Not Use"
            />
            {(canEdit || instructions) && (
              <FormControl
                variant="standard"
                className={cx(classes.formControl, classes.fullWidth)}
                disabled={!canEdit}
              >
                <InputLabel htmlFor="urCreativeUI">
                  Creative Usage Instructions
                </InputLabel>
                <Input
                  id="urCreativeUI"
                  value={instructions}
                  onChange={this.handleUsageInstructions('instructions')}
                  fullWidth
                  multiline
                  disableUnderline={!canEdit}
                  inputProps={{ 'data-cy': 'usageInstructions' }}
                />
              </FormControl>
            )}
            {instructions && (
              <Fragment>
                <FormControl
                  variant="standard"
                  className={classes.formControl}
                  disabled={!canEdit}
                >
                  <InputLabel htmlFor="urAdditionalDirections" shrink>
                    Additional Directions
                  </InputLabel>
                  <div
                    id="urAdditionalDirections"
                    className={classes.formStaticWrap}
                  >
                    <IconButton
                      onClick={() => {
                        window.open(additionalDirections, '_blank')
                      }}
                      aria-label="Open in new tab"
                      disabled={!additionalDirections}
                      size="large"
                    >
                      <OpenIcon />
                    </IconButton>
                    <Input
                      value={additionalDirections}
                      onChange={this.handleUsageInstructions(
                        'additionalDirections'
                      )}
                      disableUnderline={!canEdit}
                      inputProps={{ 'data-cy': 'additionalInstructions' }}
                    />
                  </div>
                </FormControl>
                {contacts.length > 0 && (
                  <FormControl
                    variant="standard"
                    className={classes.formControl}
                    disabled={!canEdit}
                  >
                    <InputLabel htmlFor="urCreativeContact" shrink>
                      Contact
                    </InputLabel>
                    <div
                      id="urCreativeContact"
                      className={cx(
                        classes.formStaticWrap,
                        classes.chipContainer
                      )}
                    >
                      {contacts.map((contact, key) => (
                        <Chip
                          avatar={
                            <CustomAvatar
                              userDetails={{ displayName: contact }}
                            />
                          }
                          id={`urCreativeContact${key}`}
                          label={contact}
                          key={key}
                          className={classes.chipComponent}
                        />
                      ))}
                    </div>
                  </FormControl>
                )}
              </Fragment>
            )}
          </div>
          <Divider className={classes.metadataDivider} />
          <div className={classes.metadataCardActionWrapper}>
            <Typography className={classes.metadataSubHeading}>
              Usage Rights{' '}
            </Typography>
            {canEdit && (
              <IconButton
                data-cy="addUsageRights"
                onClick={this.addUsageRight}
                aria-label="Add Row"
                disabled={disableSave}
                size="large"
              >
                <AddIcon />
              </IconButton>
            )}
          </div>
          <Table className={classes.metadataTable}>
            <TableBody>
              {usageInformation.length > 0 &&
                usageInformation.map((usageInfoItem = {}, key) => {
                  const {
                    expirationDate = '',
                    availableChannels = '',
                    customUsageRights = false,
                    customUsageRightsLanguage = '',
                    drmId = '',
                    rightsHolder = '',
                    rightsHolderType = '',
                    usageRightsLanguage = '',
                  } = usageInfoItem
                  const expirationDateFormatted =
                    formatCSTDate(expirationDate, 'll') || ''
                  return (
                    <Accordion
                      className={classes.usageRightsAccordion}
                      expanded={expandedUsageRight === `usage-right_${key}`}
                      onChange={this.handleAccordionToggle(
                        `usage-right_${key}`
                      )}
                      key={'usageInfoItem_' + key}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <TableRow
                          key={key}
                          className={classes.metadataTableRow}
                        >
                          <TableCell className={classes.metadataTableCell}>
                            <div className={classes.metadataTableCellAction}>
                              {canEdit && (
                                <Fragment>
                                  {usageInfoItem.showProgress && (
                                    <CircularProgress
                                      className={classes.progressSpinner}
                                    />
                                  )}
                                  {!usageInfoItem.showProgress &&
                                    !usageInfoItem.isError &&
                                    !usageInfoItem.isSystemError && (
                                      <div
                                        className={
                                          classes.metadataTableCellActionBtn +
                                          ' actionBtnHover'
                                        }
                                        aria-label="Delete Row"
                                        onClick={this.deleteUsageRight(key)}
                                      >
                                        <DeleteIcon
                                          data-cy="deleteUsageRights"
                                          color="inherit"
                                        />
                                      </div>
                                    )}
                                  {!usageInfoItem.showProgress &&
                                    (usageInfoItem.isError ||
                                      usageInfoItem.isSystemError) && (
                                      <span className="error">
                                        <ErrorIcon />
                                      </span>
                                    )}
                                </Fragment>
                              )}
                            </div>
                          </TableCell>
                          <TableCell className={classes.metadataTableCell}>
                            <FormControl
                              variant="standard"
                              className={classes.formControl}
                              disabled={!canEdit}
                              error={
                                usageInfoItem.isError ||
                                usageInfoItem.isSystemError
                              }
                            >
                              <InputLabel htmlFor={`urDrmId${key}`}>
                                DRM ID
                              </InputLabel>
                              <Input
                                data-cy="urDrmId"
                                id={`urDrmId${key}`}
                                idx={key}
                                onChange={this.handleChangeUsageRight(key)}
                                onKeyDown={this.handleFetchUsageRight(key)}
                                value={drmId}
                              />
                              {usageInfoItem.isError && (
                                <FormHelperText className={classes.errorStyle}>
                                  {METADATA_LOOKUP_DRM_ERROR}
                                </FormHelperText>
                              )}
                              {usageInfoItem.isSystemError && (
                                <FormHelperText className={classes.errorStyle}>
                                  {METADATA_LOOKUP_ERROR}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </TableCell>
                          <TableCell className={classes.metadataTableCell}>
                            {rightsHolder && (
                              <FormControl
                                variant="standard"
                                className={classes.formControl}
                                disabled
                              >
                                <InputLabel
                                  className={classes.usageSummaryLabel}
                                  htmlFor={`urRightsHolder${key}`}
                                  shrink
                                >
                                  Rights Holder name
                                </InputLabel>
                                <div className={classes.formStaticWrap}>
                                  <span
                                    data-cy="urRightsHolder"
                                    id={`urRightsHolder${key}`}
                                  >
                                    {rightsHolder}
                                  </span>
                                </div>
                              </FormControl>
                            )}
                            {!rightsHolder && rightsHolderType && (
                              <FormControl
                                variant="standard"
                                className={classes.formControl}
                                disabled
                              >
                                <InputLabel
                                  className={classes.usageSummaryLabel}
                                  htmlFor={`urRightsHolderType${key}`}
                                  shrink
                                >
                                  Rights Holder Type
                                </InputLabel>
                                <div className={classes.formStaticWrap}>
                                  <span
                                    data-cy="urRightsHolderType"
                                    id={`urRightsHolderType${key}`}
                                  >
                                    {rightsHolderType}
                                  </span>
                                </div>
                              </FormControl>
                            )}
                          </TableCell>
                          <TableCell className={classes.metadataTableCell}>
                            <FormControl
                              variant="standard"
                              className={classes.formControl}
                              disabled
                            >
                              <InputLabel
                                className={classes.usageSummaryLabel}
                                htmlFor={`urExpirationDate${key}`}
                                shrink
                              >
                                Expiration Date
                              </InputLabel>
                              <div className={classes.formStaticWrap}>
                                <span
                                  data-cy="urExpirationDate"
                                  id={`urExpirationDate${key}`}
                                >
                                  {expirationDateFormatted}
                                </span>
                              </div>
                            </FormControl>
                          </TableCell>
                          <TableCell className={classes.metadataTableCell}>
                            <FormControl
                              variant="standard"
                              className={classes.formControl}
                              disabled
                            >
                              <InputLabel
                                className={classes.usageSummaryLabel}
                                htmlFor={`urAvailableChannels${key}`}
                                shrink
                              >
                                Available Channels
                              </InputLabel>
                              <div className={classes.formStaticWrap}>
                                <span
                                  data-cy="urAvailableChannels"
                                  id={`urAvailableChannels${key}`}
                                >
                                  {availableChannels}
                                </span>
                              </div>
                            </FormControl>
                          </TableCell>
                        </TableRow>
                      </AccordionSummary>
                      <Divider className={classes.metadataDivider} />
                      <AccordionDetails>
                        <div className={classes.usageDetails}>
                          {getUsageInfoDetailsInfo(usageInfoItem) &&
                            getUsageInfoDetailsInfo(usageInfoItem).length &&
                            getUsageInfoDetailsInfo(usageInfoItem).map(
                              (userInfoRow = {}, index) => {
                                const { label = '', value = '' } = userInfoRow
                                return (
                                  <TableRow key={'usageInfoItem_' + index}>
                                    <TableCell
                                      className={classes.usageDetailsLabel}
                                    >
                                      {label}
                                    </TableCell>
                                    <TableCell
                                      className={classes.noBorderClass}
                                    >
                                      {' '}
                                      {value}
                                    </TableCell>
                                  </TableRow>
                                )
                              }
                            )}
                          <TableRow>
                            <TableCell className={classes.usageDetailsLabel}>
                              Usage Rights Language
                            </TableCell>
                          </TableRow>
                          <div className={classes.usageDetailsText}>
                            {' '}
                            {customUsageRights
                              ? customUsageRightsLanguage
                              : usageRightsLanguage}
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  )
                })}
              {usageInformation.length === 0 && (
                <TableRow>
                  <TableCell>
                    <div className={classes.noResults}>
                      No details available
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </CollapsibleCard>
    )
  }
}

UsageInfoCard.propTypes = {
  classes: PropTypes.object,
  usageInformation: PropTypes.arrayOf(
    PropTypes.shape({
      drmId: PropTypes.string,
      rightsHolder: PropTypes.string,
      rightsHolderType: PropTypes.string,
      expirationDate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      availableChannels: PropTypes.string,
    })
  ),
  usageInstructions: PropTypes.shape({
    doNotUse: PropTypes.bool,
    instructions: PropTypes.string,
    additionalDirections: PropTypes.string,
    contacts: PropTypes.array,
  }),
  metadataParentTimestamp: PropTypes.number,
  collapseCallback: PropTypes.func,
  getStorageIdentifier: PropTypes.func,
  editPermissions: PropTypes.shape({
    canRename: PropTypes.bool,
    canEdit: PropTypes.bool,
    canDelete: PropTypes.bool,
  }),
  saveMetadata: PropTypes.func,
  isBulkEdit: PropTypes.bool,
  updateParentState: PropTypes.func,
  isMetadataFormEdited: PropTypes.func,
  saveAlertActiontype: PropTypes.bool,
  setSaveAlertAction: PropTypes.func,
}
